import { createSlice } from '@reduxjs/toolkit'

/**
 * alert 또는 confirm
 */
export const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState: {
    info: {
      isError: false,
      isAccountError: false,
      errorMessage: '',
    },
  },
  reducers: {
    setGlobalAlert: (state, action) => {
      state.info = action.payload
    },
    removeGlobalAlert: state => {
      state.info = {
        isError: false,
        isAccountError: false,
        errorMessage: '',
      }
    },
  },
})

export const { setGlobalAlert, removeGlobalAlert } = globalErrorSlice.actions
export default globalErrorSlice.reducer
export const selectError = (state: any) => state.globalError.info
