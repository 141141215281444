import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpNeo: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'EXTRNO',
		headerName: '접수번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '접수일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '수진자명',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'PRINTDT',
		headerName: '출력일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'ORDERSTATUS',
		headerName: '상태',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료실',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
