import { useEffect, useState, useRef } from 'react'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	FormControl,
	Typography,
	LinearProgress,
	Select,
	SelectChangeEvent,
	useMediaQuery,
	Menu,
	MenuItem,
	CircularProgress,
	Breadcrumbs,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	ButtonGroup,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridColumnOrderChangeParams,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { alertModal, confirmAlert, hexToRgba, timerAlert } from 'util/util'

import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { useDispatch, useSelector } from 'react-redux'
import {
	receptionList,
	testImageList,
	receptionView,
	apiSBReceptionList,
	apiRptTypeCodeList,
	apiSBList,
	apiSBReserve,
} from '../../api/api'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import { selectGridColumnInfo, setGridColumn } from 'hooks/gridColumn'
import {
	selectGridColumnVisibleInfo,
	setGridColumnVisible,
} from 'hooks/gridColumnVisible'

import moment from 'moment'
import 'moment/locale/ko'

import PrevResult from './PrevResult'
import TestInfo from './TestInfo'

import { compareDate, isValidDateType } from 'util/util'
import {
	getGridColumnSettings,
	getSettings,
	setGridColumnSettings,
	getEnvSettings,
	setEnvSettings,
	setGridColumnVisibleSettings,
	getGridColumnVisibleSettings,
} from 'helpers/storage'
import ReportSlider from 'views/report/ReportSlider'
import React from 'react'
import ArrowUp from '../../assets/img/ArrowUp.svg'
import ArrowDown from '../../assets/img/ArrowDown.svg'
import ArrowBoth from '../../assets/img/ArrowBoth.svg'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import ClientSearchPopup from './clientSearchPopup'
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone'
import FontDownloadTwoToneIcon from '@mui/icons-material/FontDownloadTwoTone'

import SimplePopup from 'components/SimplePopup'

import { valuesInIterator } from 'util/objectUtil'
import { setEnvSetting } from 'hooks/envSetting'
import axios from 'axios'

import LogoGray from '../../assets/img/ready.jpeg'
import LogoGrayActive from '../../assets/img/ready_active.jpeg'
import SlideBlockPopup from 'components/SlideBlockPopup'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import { useNavigate } from 'react-router-dom'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import InsuCodeSearchPopup from 'components/InsuCodeSearchPopup'

const locales = ['ko'] as const

const ReportButtonGroup = styled(ButtonGroup)(({ theme }) => ({}))

const ReportButton = styled(Button)(({ theme }) => ({
	backgroundColor: 'rgb(79, 92, 121)',
	borderRight: '1px solid #fff !important',
	'&:hover': { backgroundColor: 'rgb(79, 92, 121)' },
	color: '#ffffff',
	'&.active.isDone': {
		background: 'rgb(79, 92, 121)',
		color: '#ffffff',
	},
	'&.active:hover.isDone': {
		background: 'rgb(79, 92, 121)',
		color: '#ffffff',
	},
	'&.isDone': {
		backgroundColor: '#98a5a6',
	},
	'&.isDone:hover': {
		backgroundColor: 'rgb(79, 92, 121)',
	},
	'&.isDone.inProgress': {
		backgroundColor: 'rgb(153, 214, 150)',
		color: '#ffffff',
	},
	'&.isDone:hover.inProgress': {
		backgroundColor: 'rgb(153, 214, 150)',
		color: '#ffffff',
	},
	'&.inProgress': { backgroundColor: 'rgb(153, 214, 150)', color: '#000' }, // 검사 진행중 색상
	'&:hover.inProgress': { backgroundColor: '#46a24b', color: '#fff' },
	'&.active.inProgress': { backgroundColor: '#46a24b', color: '#fff' },
}))

const Wrapper = styled('div')(({ theme }) => ({
	positioon: 'relative',
	marginTop: '0.3rem',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	[`@media (max-width: 1023px)`]: {
		display: 'block',
	},
	'.left': {
		width: '570px',
		[`@media (max-width: 1023px)`]: {
			width: 'auto',
		},
	},
	'.right': {
		position: 'relative',
		width: 'calc(100% - 570px)',
		[`@media (max-width: 1023px)`]: { width: '100%' },
	},
}))

const ReportGenerator = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.5)',
	zIndex: 9999,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const IFrameContainer = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 30,
	left: 0,
	right: 0,
	bottom: 0,
	background: `url(${LogoGray}) no-repeat center center, #fff`,
	backgroundSize: '400px',
	zIndex: 9,
	'&.active': {
		background: `url(${LogoGrayActive}) no-repeat center center, #fff`,
		backgroundSize: '400px',
	},
}))

const Iframe = styled('iframe')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	border: 0,
	'.report_menu_print_button': {
		display: 'none !important',
	},
}))

const PDFContainer = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 30,
	left: 0,
	right: 0,
	bottom: 0,
	background: '#F0F0F0',
	zIndex: 10,
}))

const IfrmaeLoading = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 5,
	left: 5,
	right: 5,
	bottom: 5,
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.3)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 9999,
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	padding: '2px 2px 2px 70px',
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(81, 81, 81, 1)'
			: '1px solid rgba(224, 224, 224, 1)',
	// borderBottom: '0',
	'& + &': {
		borderBottom: '0',
	},
	'&.last': {
		borderBottom:
			theme.palette.mode === 'dark'
				? '1px solid rgba(81, 81, 81, 1)'
				: '1px solid rgba(224, 224, 224, 1)',
	},
	'&.noLabel': {
		paddingLeft: '0',
	},
	'&.h27': {
		height: 27,
		overflowX: 'auto',
		overflowY: 'hidden',
	},
	'.labelText': {
		position: 'absolute',
		left: '0',
		top: '0',
		bottom: '0',
		width: '65px',
		fontSize: '13px',
		fontWeight: '500',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f7f1e3',
		borderRight:
			theme.palette.mode === 'dark'
				? '1px solid rgba(81, 81, 81, 1)'
				: '1px solid rgba(224, 224, 224, 1)',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			// position: 'relative',
			// left: '0',
			// top: '0',
			// marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		// paddingLeft: '0',
		height: 'auto',
	},
}))

// main: '#835FED',
/**
 * 그리드 컬럼
 */
const columns: GridColDef[] = [
	{
		field: 'ReceptionID',
		headerName: '접수ID',
		type: 'string',
		width: 80,
		editable: false,
		hide: true,
	},
	{
		field: 'rowNum',
		headerName: '순번',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.rowNum}
				</span>
			)
		},
	},
	{
		field: 'PartCode',
		headerName: 'PartCode',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.PartCode}
				</span>
			)
		},
	},
	{
		field: 'ReceptionDate',
		headerName: '접수일',
		type: 'string',
		width: 100,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ReceptionDate}
				</span>
			)
		},
	},
	{
		field: 'ReceptionRegNum',
		headerName: '접수번호',
		type: 'string',
		width: 75,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ReceptionRegNum}
				</span>
			)
		},
	},
	{
		field: 'PatientName',
		headerName: '수진자명',
		type: 'string',
		width: 70,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.PatientName}
				</span>
			)
		},
	},
	{
		field: 'SocialNumber',
		headerName: '주민번호',
		type: 'string',
		width: 80,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.SocialNumber}
				</span>
			)
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ChartNo}
				</span>
			)
		},
	},
	{
		field: 'ClientInfo',
		headerName: 'S/A',
		type: 'string',
		width: 45,
		editable: false,
		renderCell: (params) => {
			const { isRed, ClientInfo } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{ClientInfo}
				</span>
			)
		},
	},
	{
		field: 'StateCategory',
		headerName: '진행',
		type: 'string',
		width: 60,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.StateCategory === '접수') {
				return (
					<span
						style={{
							background: '#8d8d8d',
							padding: '1px 3px',
							border: '1px solid #8d8d8d',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '10px',
						}}
					>
						접수
					</span>
				)
			} else if (params?.row?.StateCategory === '재검') {
				return (
					<span
						style={{
							background: '#e67e22',
							padding: '1px 3px',
							border: '1px solid #e67e22',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '10px',
						}}
					>
						재검
					</span>
				)
			} else if (params?.row?.StateCategory === '완료' && params?.row?.IsOver) {
				return (
					<span
						style={{
							color: '#000',
							background: '#74acdb',
							padding: '1px 3px',
							border: '1px solid #74acdb',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '10px',
						}}
					>
						완료
					</span>
				)
			} else if (params?.row?.StateCategory === '완료' && !params?.row?.IsOver) {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '10px',
						}}
					>
						진행
					</span>
				)
			} else if (params?.row?.StateCategory === '진행') {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '10px',
						}}
					>
						진행
					</span>
				)
			}
		},
	},
	{
		field: 'Decision',
		headerName: '판정',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.IsOver) {
				if (
					params?.row?.StateCategory === '완료' &&
					params?.row?.Decision === 'L'
				) {
					return (
						<img
							src={ArrowDown}
							alt="L"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (
					params?.row?.StateCategory === '완료' &&
					params?.row?.Decision === 'H'
				) {
					return (
						<img
							src={ArrowUp}
							alt="H"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (
					params?.row?.StateCategory === '완료' &&
					params?.row?.Decision === 'A'
				) {
					return (
						<img
							src={ArrowBoth}
							alt="A"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else {
					return <></>
				}
			} else {
				return <></>
			}
		},
	},
	{
		field: 'PartName',
		headerName: '학부',
		type: 'string',
		width: 80,
		editable: false,
		align: 'center',
		hideable: true,
		renderCell: (params) => {
			const { PartName } = params?.row
			return (
				<>{PartName === 'STD/HPV' || PartName === '미생물학' ? '진단' : PartName}</>
			)
		},
	},
	{
		field: 'DepartName',
		headerName: '진료과 ',
		type: 'string',
		width: 80,
		editable: false,
		align: 'center',
		hideable: true,
	},
	{
		field: 'DoctorName',
		headerName: '의사명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'isView',
		headerName: '읽음',
		type: 'string',
		width: 40,
		editable: false,
		align: 'center',
		renderCell: (params) => {
			const { IsOver } = params?.row
			if (params?.row?.isView === 1 && IsOver) {
				return <>Y</>
			} else {
				return <></>
			}
		},
	},
	{
		field: 'isPrint',
		headerName: '출력여부',
		type: 'string',
		width: 75,
		editable: false,
		align: 'center',
		hideable: true,
		renderCell: (params) => {
			const { isPrint } = params?.row
			if (isPrint === 1) {
				return <>Y</>
			} else {
				return <></>
			}
		},
	},
]

const Result = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const gridColumnOrderInfo = useSelector(selectGridColumnInfo)
	const gridColumnOrder = getGridColumnSettings()
	const gridColumnVisibleInfo = useSelector(selectGridColumnVisibleInfo)
	const gridColumnVisible = getGridColumnVisibleSettings()
	const envSetting = getEnvSettings()
	const settings = getSettings()

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const [disableRowClick, setDisableRowClick] = useState(false)

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		})
	}

	const grid1Ref = useGridApiRef()
	// const grid2Ref = useGridApiRef()
	// const grid3Ref = useGridApiRef()

	// 컬럼 순서 변경
	const defaultColumnOrder = [
		'rowNum',
		'PatientName',
		'ChartNo',
		'DepartName',
		'DoctorName',
		'ReceptionInfo',
		'CollectedDate',
		'TestName',
		'LastStateName',
		'ClientName',
	]

	const [columnOrder, setColumnOrder] = useState(
		gridColumnOrder ? [...gridColumnOrder] : [...defaultColumnOrder]
	)

	const [gridColumnVisibleSetting, setGridColumnVisibleSetting] = useState(
		gridColumnVisible ? { ...gridColumnVisible } : {}
	)

	const [envSettingInfo, setEnvSettingInfo] = useState<any>(
		envSetting ? { ...envSetting } : {}
	)

	const handleColumnReorder = (params: GridColumnOrderChangeParams) => {
		const { field, targetIndex } = params

		// Move field to the target index
		const newColumnOrder = [...columnOrder]
		const targetColumnIndex = newColumnOrder.findIndex((col) => col === field)
		newColumnOrder.splice(targetColumnIndex, 1)
		newColumnOrder.splice(targetIndex, 0, field)

		console.table(newColumnOrder)

		// Update the state using the previous state
		setColumnOrder(newColumnOrder)

		setGridColumnSettings(newColumnOrder)
		dispatch(setGridColumn(newColumnOrder))
	}

	const handleColumnVisibleChange = (params: GridColumnVisibilityModel) => {
		const data = { ...params }
		console.log(data)

		setGridColumnVisibleSettings(data)
		dispatch(setGridColumnVisible(data))
	}

	const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false)

	const [reportIsLoading, setReportIsLoading] = useState<boolean>(false)

	const actionSearchExpand = () => {
		setIsSearchExpanded(!isSearchExpanded)
	}

	// 그리드 판정 색상
	const rederText = (decision: string) => {
		// decision remove empty space
		decision = decision !== undefined ? decision.replace(/\s/g, '') : ''

		if (decision === undefined || decision === '') {
			return theme.palette.mode === 'dark' ? '#ffffff' : '#000000'
		}
		if (decision !== undefined && decision === 'L') {
			return '#0288d1' // blue
		} else {
			return '#FF5722' // red
		}
	}

	const userInfo = useSelector(selectuserInfo)
	const imageRef = useRef<HTMLDivElement>(null)
	const env = getSettings()
	// ui
	const cmmnCdList = useSelector(selectCmmnCode)
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [disabledExcel, setDisabledExcel] = useState(true)
	const [disabledImage, setDisabledImage] = useState(true)
	const [disabledReport, setDisabledReport] = useState(true)
	const [showImageOverlay, setShowImageOverlay] = useState(false)

	const [activeButtonNumber, setActiveButtonNumber] = useState(0)

	const [reportLoading, setReportLoading] = useState(false)

	const [dateRange, setDateRange] = useState('7d')

	const [dateActiveButtonNumber, setDateActiveButtonNumber] = useState(2)

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const [page1, setPage1] = useState(0)

	const [rptTypeCodeList, setRptTypeCodeList] = useState<any>([])

	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	/**
	 * 검체종류 select
	 * @param e
	 */
	const searchTestCdOnChange = (e: SelectChangeEvent<string>): void => {
		setTestSearch({
			...testSearch,
			searchTestCd: e.target.value,
		})
		setEnvSettingInfo({
			...envSettingInfo,
			searchTestCd: e.target.value,
		})
		setEnvSettings({
			...envSettingInfo,
			searchTestCd: e.target.value,
		})
		dispatch(setEnvSetting(envSettingInfo))
	}

	const [selectedReceptionID, setSelectedReceptionID] = useState<string>('')
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [reportCode, setReportCode] = useState<string>('')
	const [imageList, setImageList] = useState<any>([])
	const [fileList, setFileList] = useState<any>([])

	const [radioSearchType, setRadioSearchType] = useState<string>('0')
	const [reportUrl, setReportUrl] = useState<string>('')

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		if (!disabledSearch) getReceptionList()
	}
	const actionReset = () => {
		setTestSearch({
			clientID: '',
			patientName: '',
			chartNo: '',
			receptionRegNum: '',
			lastStateCode: '0',
			searchTestCd: '0',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			departName: userInfo?.departName || '',
			ward: userInfo?.ward || '',
			doctorName: '',
			isPDC: false,
			searchType: 'R',
			socailNumber: '',
			insuranceCode: '',
			testName: '',
			etcType: '',
		})
		setSearchClientName('')
		setDateRange('7d')
		actionSearch()
		setRadioSearchType('0')
		grid1Ref.current?.setQuickFilterValues([])
		grid1Ref.current?.setPage(0)
	}

	const excelDownloadRef = useGridApiRef()
	const [excelDataGridRows, setExcelDataGridRows] = useState<any>([])
	const [disabledExcelDownload, setDisabledExcelDownload] = useState(false)

	const actionExcel = () => {
		const selectedRows = grid1Ref.current?.getSelectedRows()
		// extract ReceptionID in selectedRows
		const values = valuesInIterator(selectedRows)
		const receptionIDs = values.map((row: any) => row.ReceptionID)
		console.log(receptionIDs)
		// receptionIDs to string
		const receptionIDsString = receptionIDs.join(',')
		console.log(receptionIDsString)

		setExcelDataGridRows([])

		setDisabledExcelDownload(true)

		axios
			.get(
				`${
					process.env.REACT_APP_HOST
				}/report/download/excel?receptionIDs=${receptionIDsString}&clientID=${
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? testSearch.clientID
						: userInfo.clientID
				}`
			)
			.then((res) => {
				console.log(res)
				setExcelDataGridRows(res.data)
				setTimeout(() => {
					excelDownloadRef.current?.exportDataAsExcel()
				}, 500)
			})
			.finally(() => {
				setDisabledExcelDownload(false)
			})

		// window.open(
		// 	`${
		// 		process.env.REACT_APP_HOST
		// 	}/report/download/excel?receptionIDs=${receptionIDsString}&clientID=${
		// 		userInfo.infoType === 'S' || userInfo.infoType === 'W'
		// 			? testSearch.clientID
		// 			: userInfo.clientID
		// 	}`,
		// 	'결과보고서'
		// )
	}

	const [imageDataList, setImageDataList] = useState<any>([])
	const [reportDialog, setReportDialog] = useState(false)

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		setIsDateRangeChanged(true)
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		setIsDateRangeChanged(false)
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setTestSearch({
				...testSearch,
				[target]: moment(new Date(e)).format('yyyy-MM-DD'),
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, testSearch.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, testSearch.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setTestSearch((prevState) => {
					return {
						...testSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	const [isA320, setIsA320] = useState('N')

	const [slideBlockBtnActive, setSlideBlockBtnActive] = useState(false)
	const [slideBlickPopupisOpen, setSlideBlickPopupisOpen] = useState(false)
	const [popupData, setPopupData] = useState<any>({})
	const [sbList, setSbList] = useState<any>([])
	const [selectedSbList, setSelectedSbList] = useState<any>([])

	const onSlideBlockPopupClose = () => {
		setSlideBlickPopupisOpen(false)
		setPopupData({})
	}

	const onSlideBlockItemClick = (item: any) => {
		// selectedSbList에 동일한 rowNum이 없으면 추가 있으면 삭제
		const index = selectedSbList.findIndex((x: any) => x.rowNum === item.rowNum)
		if (index !== -1) {
			setSelectedSbList(
				selectedSbList.filter((x: any) => x.rowNum !== item.rowNum)
			)
		} else {
			setSelectedSbList([...selectedSbList, item])
		}
	}

	const onSlideBlockPopupSubmit = () => {
		if (selectedSbList.length === 0) {
			alertModal('Slide/Block을 선택해주세요.', 'error', () => {})
			return
		}
		if (!popupData?.agree) {
			alertModal(
				'"확인했습니다."에 확인 후 신청하실 수 있습니다.',
				'error',
				() => {}
			)
			return
		}
		if (popupData?.agree && selectedSbList.length > 0) {
			confirmAlert(
				'저장하시겠습니까?',
				() => {
					const data: any[] = []
					console.log(selectedSbList)
					// selectedSbList 갯수 만큼 request에 추가
					selectedSbList.forEach((item: any) => {
						data.push({
							ReceptionID: popupData?.ReceptionID,
							ReTestCode: item.TestCode,
							ClientID:
								userInfo?.infoType === 'C' ? userInfo?.clientID : testSearch?.clientID,
							RequestCode: popupData?.PartCode || '',
							RequestNotes: popupData?.RequestNotes || '',
							TRequestType: 'L',
							RItemID: popupData?.RItemID,
							SContainerSNames: popupData?.ContainerSName || '',
							BranchCode: popupData?.BranchCode || '',
							ManagerID: popupData?.ManagerID || '',
							LabCode: popupData?.LabCode || '',
							TeamCodes: popupData?.TeamCode || '',
							TestCode: item?.TestCode || '',
							RowCrud: 'C',
						})
					})

					const request = {
						clientID:
							userInfo?.infoType === 'C' ? userInfo?.clientID : testSearch?.clientID,
						json: [...data],
					}
					console.log(request)

					if (!request.clientID) {
						alertModal('거래처코드가 없습니다.', 'error', () => {})
						return
					}

					if (request.json.length === 0) {
						alertModal('신청할 Slide/Block을 선택해주세요.', 'error', () => {})
						return
					}

					console.log(request)

					apiSBReserve(request).then((res) => {
						console.log(res)
						timerAlert('신청되었습니다.', 'success', () => {})
						onSlideBlockPopupClose()
						setPopupData({})
						setSelectedSbList([])
					})
				},
				() => {}
			)
		}
	}

	// 슬라이드블럭 대여신청
	const actionSlideBlock = () => {
		if (!slideBlockBtnActive) return
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)

		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			testSearch.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		if (!iterArray[0].ReceptionID) {
			alertModal('접수ID가 없습니다.', 'error', () => {})
			return
		}

		const request = {
			clientID: testSearch.clientID as any,
			patientName: testSearch.patientName,
			chartNo: testSearch.chartNo,
			startDate: moment(testSearch.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: testSearch.startDate,
			endDate: testSearch.endDate,
			socialNumber: '',
			pathologyNo: '',
			status: '0',
			step: '0',
			searchType: testSearch.searchType,
			receptionID: iterArray[0].ReceptionID,
		}

		console.table(request)

		apiSBReceptionList(request)
			.then((res) => {
				console.log(res)
				if (res.length > 0) {
					setPopupData(res[0])
					setSlideBlickPopupisOpen(true)
				} else {
					alertModal('Slide/Block 정보가 없습니다.', 'error', () => {})
				}
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {})
	}

	// 리포트
	const actionReport = () => {
		console.log(settings)
		console.log(selectionModel)
		setRptTypeCodeList([])

		setRptRowClicked(true)
		setReportUrl('')

		if (disableRowClick) return

		setTimeout(() => {
			setDisableRowClick(true)
			setReportLoading(true)
			setPdfActive(false)
			const datas = grid1Ref.current?.getSelectedRows()
			const iterArray = valuesInIterator(datas)
			console.log(iterArray)

			// if iterArray is contain P0 or P1 or P3 or P4 then return true
			const partCode = iterArray[0]?.PartCode
			const isContainP = ['P0', 'P1', 'P3', 'P4'].includes(partCode)
			console.log(partCode, isContainP)

			if (isContainP) {
				setSlideBlockBtnActive(true)
			} else {
				setSlideBlockBtnActive(false)
			}

			//setReportIsLoading(true)
			const receptionIDs = iterArray.map((element: any) => {
				return element?.ReceptionID
			})
			const receptionIDText = receptionIDs.join(',')
			const request = {
				receptionIDs: receptionIDText,
				clientID:
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? testSearch.clientID
						: userInfo.clientID,
			}
			console.log(request)
			apiRptTypeCodeList(request).then((res) => {
				console.log(res)

				if (res.length === 0) {
					setDisableRowClick(false)
				}

				console.log(iterArray[0].StateCode)
				if (userInfo.infoType === 'C' && iterArray[0].StateCode === 'D90') {
					const request = {
						ReceptionID: receptionIDText,
						ClientID: userInfo.clientID,
					}
					console.log(request)
					receptionView(request)
				}

				const arr = new Array()

				// R001 : 종검, R002 : 산전 구분
				const filterArray1 = ['B110', 'B115', 'B120']
				if (res.some((item: any) => filterArray1.includes(item.RptTypeCode))) {
					res.map((item: any) => {
						if (item.RptTypeCode === 'B110' && item?.RClassCode === 'R002') {
							arr.push(item)
						} else if (item.RptTypeCode === 'B115' && item?.RClassCode === 'R001') {
							arr.push(item)
							const B120 = {
								...item,
								RptTypeCode: 'B120',
								ReportTitle: '혈액검사',
							}
							arr.push(B120)
						} else if (
							item.RptTypeCode !== 'B110' &&
							item.RptTypeCode !== 'B115' &&
							item.RptTypeCode !== 'B120'
						) {
							arr.push(item)
						}
					})
				}

				// 영문 결과지 A915, A921 옵션 체크 (PassportNumber가 없으면 삭제) PassportNumber
				const filterArray2 = ['A915', 'A921']
				if (res.some((item: any) => filterArray2.includes(item.RptTypeCode))) {
					res.map((item: any) => {
						if (item.RptTypeCode === 'A915' || item.RptTypeCode === 'A921') {
							if (item?.PassportNumber !== undefined) {
								arr.push(item)
							}
						} else {
							arr.push(item)
						}
					})
				}

				const filterArray3 = ['B110', 'B115', 'B120', 'A915', 'A921']
				if (!res.some((item: any) => filterArray3.includes(item.RptTypeCode))) {
					res.map((item: any) => {
						if (!filterArray3.includes(item.RptTypeCode)) {
							console.table(item)
							arr.push(item)
						}
					})
				}

				console.log(arr)

				// setRptTypeCodeList(arr)
				console.log(res.length)

				if (res.length > 0) {
					// setReportCode(res[0].RptTypeCode)
					// setReportDialog(true)
					const request = {
						receptionID: iterArray[0].ReceptionID,
						clientID:
							userInfo.infoType === 'S' || userInfo.infoType === 'W'
								? testSearch.clientID
								: userInfo.clientID,
						rptTypeCode: res[0]?.RptTypeCode,
					}
					const isContainA320 = res.filter(
						(item: any) => item.RptTypeCode === 'A320'
					)
					console.log(isContainA320)
					setIsA320(isContainA320.length > 0 ? 'Y' : 'N')
					setReportUrl(
						`${process.env.REACT_APP_HOST}/rpt/reportIframe?receptionID=${
							request.receptionID
						}&clientID=${request.clientID}&rptTypeCode=${request.rptTypeCode}&zoom=${
							settings.zoom ? settings.zoom : '100'
						}&isA320=${isContainA320.length > 0 ? 'Y' : 'N'}`
					)

					const iframe = document.getElementById('reportIframe') as HTMLIFrameElement
					iframe.onload = () => {
						setReportLoading(false)
					}

					setTimeout(() => {
						setActiveButtonNumber(0)
						setDisableRowClick(false)
					}, 1500)
				}

				const req = {
					receptionID: iterArray[0].ReceptionID,
					clientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? testSearch.clientID
							: userInfo.clientID,
				}
				testImageList(req).then((res) => {
					console.log('imagelist', res)
					const imageList = new Array()
					const fileList = new Array()
					// 이미지만 필터링
					const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
					res.forEach((item: any) => {
						const ext = item.AttachFileExtension?.toLowerCase()
						if (imageExtensions.includes(ext)) {
							imageList.push(item)
						} else {
							fileList.push(item)
						}
					})
					setImageList(imageList)
					setFileList(fileList)
					console.log('fileList', fileList.length)
					if (fileList) {
						console.log(fileList)
						const fileObject: any[] = []
						fileList.forEach((item: any) => {
							if (
								(item?.AttachFileExtension === 'PDF' ||
									item?.AttachFileExtension === 'pdf') &&
								item?.AttachFileName
							) {
								// item is not in rptTypeCodeList
								let dup: boolean = false
								rptTypeCodeList.forEach((rptTypeCodeItem: any) => {
									console.log(rptTypeCodeItem)
									if (rptTypeCodeItem.ReportTitle === item?.AttachFileName) {
										dup = true
									}
								})

								if (!dup) {
									//
								}
								fileObject.push({
									LastStateCode: 'D90',
									ReceptionID: receptionIDText,
									ReportTitle: item?.AttachFileName,
									RptTypeCode: 'PDF',
									RptTypeNotes: '',
									AttachFile: item?.AttachFile,
								})
							}
						})
						let A320Contains = false
						let A320Obj = {}
						arr.forEach((item: any) => {
							if (item.RptTypeCode === 'A320' && item.LastStateCode !== 'D90') {
								A320Contains = true
								// 첨부파일이 없는경우를 대비해서 오브젝트 생성
								item.ReportTitle = '결과보고서'
								A320Obj = item
								console.log(item)
							}
						})
						console.log('A320Contains', A320Contains)
						console.log(arr)
						console.log(A320Obj)
						if (A320Contains) {
							// 제본결과지가 있으면 A320 제거하고 첨부파일명을 탭에 출력
							const filteredArr = arr.filter(
								(item: any) => item.RptTypeCode !== 'A320'
							)
							console.log(fileObject)
							setRptTypeCodeList([...fileObject, ...filteredArr])
							// 검사항목이 제본결과지만 있으면 첨부파일명을 탭에 출력 (다른 검사 없이 제본결과지만 있는 경우)
							if (filteredArr.length === 0 && fileObject.length > 0) {
								setPdfActive(true)
								const file = fileObject[0].AttachFile
								// file to byte array
								const byteCharacters = atob(file)
								const byteNumbers = new Array(byteCharacters.length)
								for (let i = 0; i < byteCharacters.length; i++) {
									byteNumbers[i] = byteCharacters.charCodeAt(i)
								}
								const byteArray = new Uint8Array(byteNumbers)
								const blob = new Blob([byteArray], { type: 'application/pdf' })
								const blobUrl = URL.createObjectURL(blob)
								setPdfBlob(blobUrl)
							}
							setRptTypeCodeList([A320Obj, ...filteredArr, ...fileObject])
						} else {
							// 첨부파일이 없는경우 제본결과지를 결과보고서의 이름으로 rptTypeCodeList에 추가
							console.log(
								'첨부파일이 없는경우 제본결과지를 결과보고서의 이름으로 rptTypeCodeList에 추가'
							)
							console.log(arr)
							let finalArr: any[] = []
							arr.forEach((item: any) => {
								if (item.RptTypeCode === 'A320') {
									if (item.LastStateCode === 'D90') finalArr.push(item)
								} else if (item.RptTypeCode !== 'A320') {
									finalArr.push(item)
								}
							})
							setRptTypeCodeList([...finalArr, ...fileObject])
							// rptTypeCode 목록 없이 달랑 PDF 파일이 있으면 첫번째 파일을 클릭한 것으로 처리
							if (fileObject[0] && finalArr.length === 0) {
								onRptTypeCodeClick(fileObject[0], 0)
							}
						}
					}
				})
			})
		}, 100)
	}

	// 첨부파일에 pdf가 있을 경우의 변수값
	const [pdfActive, setPdfActive] = useState(false)
	const [pdfBlob, setPdfBlob] = useState<any>(null)

	// rptTypeCode 클릭 이벤트 (PDF가 있을 때 추가 처리)
	const onRptTypeCodeClick = (item: any, index: number) => {
		setDisableRowClick(true)
		if (item.RptTypeCode === 'PDF') {
			setPdfActive(true)
			const file = item.AttachFile
			// file to byte array
			const byteCharacters = atob(file)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const blob = new Blob([byteArray], { type: 'application/pdf' })
			const blobUrl = URL.createObjectURL(blob)
			setPdfBlob(blobUrl)
			setActiveButtonNumber(index)
			setDisableRowClick(false)
		} else {
			setPdfActive(false)
			setReportLoading(true)
			const request = {
				receptionID: item.ReceptionID,
				clientID:
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? testSearch.clientID
						: userInfo.clientID,
				rptTypeCode: item.RptTypeCode,
			}
			// setReportUrl(
			// 	`${process.env.REACT_APP_HOST}/rpt/reportIframe?receptionID=${
			// 		request.receptionID
			// 	}&clientID=${request.clientID}&rptTypeCode=B115&zoom=${
			// 		settings.zoom ? settings.zoom : '100'
			// 	}&isA320=${isA320}`
			// )
			setReportUrl(
				`${process.env.REACT_APP_HOST}/rpt/reportIframe?receptionID=${
					request.receptionID
				}&clientID=${request.clientID}&rptTypeCode=${item.RptTypeCode}&zoom=${
					settings.zoom ? settings.zoom : '100'
				}&isA320=${isA320}`
			)

			const iframe = document.getElementById('reportIframe') as HTMLIFrameElement
			iframe.onload = () => {
				setReportLoading(false)
			}
			setTimeout(() => {
				setActiveButtonNumber(index)
				setPdfBlob(null)
				setDisableRowClick(false)
			}, 1500)
		}
	}

	// const reportImageAction = () => {
	// 	setTimeout(() => {
	// 		const datas = grid1Ref.current?.getSelectedRows()
	// 		console.log(datas)
	// 		const iterArray = valuesInIterator(datas)
	// 		if (iterArray.length === 0) {
	// 			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
	// 			return
	// 		} else {
	// 			setReportIsLoading(true)
	// 			const zip = new JSZip()

	// 			const receptionIDs = iterArray.map((element: any) => {
	// 				return element.ReceptionID
	// 			})
	// 			const receptionIDText = receptionIDs.join(',')
	// 			const request = {
	// 				ReceptionIDs: receptionIDText,
	// 			}

	// 			generatePdf(request)
	// 				.then((res) => {
	// 					console.log(res)
	// 					const list = res[0].body
	// 					if (list.length > 0) {
	// 						let cnt = 0
	// 						list.forEach((base64Data: any, index: number) => {
	// 							if (base64Data.URL) {
	// 								cnt++
	// 							}
	// 						})
	// 						console.log(cnt)
	// 						if (cnt === 0) {
	// 							alertModal('출력할 리포트가 없습니다.', 'error', () => {})
	// 							return
	// 						}
	// 						list.forEach((base64Data: any, index: number) => {
	// 							if (base64Data.URL) {
	// 								const byteCharacters = atob(base64Data.URL.split(',')[1])
	// 								const byteNumbers = new Array(byteCharacters.length)

	// 								for (let i = 0; i < byteCharacters.length; i++) {
	// 									byteNumbers[i] = byteCharacters.charCodeAt(i)
	// 								}

	// 								const byteArray = new Uint8Array(byteNumbers)
	// 								const blob = new Blob([byteArray], { type: base64Data.FileExtension })

	// 								// const filename = `image${index + 1}.${base64Data.FileExtension}`
	// 								const filename = `${moment().format('YYYYMMDD')}_${
	// 									base64Data.ChartNo
	// 								}_${base64Data.ReceptionRegNum}_${base64Data.PatientName}_${
	// 									index + 1
	// 								}.${base64Data.FileExtension}`

	// 								if (cnt === 1) {
	// 									const downloadLink = document.createElement('a')
	// 									downloadLink.href = URL.createObjectURL(blob)
	// 									downloadLink.download = filename
	// 									downloadLink.click()
	// 								} else {
	// 									zip.file(filename, blob)
	// 								}
	// 							}
	// 						})
	// 						if (cnt > 1) {
	// 							zip.generateAsync({ type: 'blob' }).then((content) => {
	// 								const downloadLink = document.createElement('a')
	// 								downloadLink.href = URL.createObjectURL(content)
	// 								const downloadFileName =
	// 									userInfo.infoType === 'C'
	// 										? moment().format('YYYYMMDD') + '_' + userInfo.clientName
	// 										: moment().format('YYYYMMDD')
	// 								downloadLink.download = `${downloadFileName}.zip`
	// 								downloadLink.click()
	// 							})
	// 						}
	// 						// }
	// 					} else {
	// 						alertModal('리포트가 없습니다.', 'error', () => {})
	// 						return
	// 					}
	// 				})
	// 				.finally(() => setReportIsLoading(false))
	// 		}
	// 	}, 100)
	// }

	const reportTextAction = () => {
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)

		if (iterArray.length > 0) {
			const request = {
				receptionID: iterArray[0].ReceptionID,
				clientID:
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? testSearch.clientID
						: userInfo.clientID,
			}

			window.open(
				`/report/text?receptionID=${request.receptionID}&clientID=${
					request.clientID
				}&isP0=${
					iterArray[0]?.PartCode === 'P0' ||
					iterArray[0]?.PartCode === 'P1' ||
					iterArray[0]?.PartCode === 'P2' ||
					iterArray[0]?.PartCode === 'P3'
						? 'Y'
						: ''
				}`,
				'결과보고서',
				'width=640, height=740'
			)
		}
	}

	// api response
	const [receptionListData, setReceptionListData] = useState([])
	const [size1, setSize1] = useState(env.row ? env.row : 100)
	const [grid1isLoading, setGrid1isLoading] = useState(false)
	const [isSearchLoading, setIsSearchLoading] = useState(false)

	// 검색조건
	const [testSearch, setTestSearch] = useState({
		clientID: '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		lastStateCode: '0',
		searchTestCd: userInfo?.testCd || '0',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		departName: userInfo.departName || '',
		ward: userInfo.ward || '',
		doctorName: '',
		isPDC: false,
		searchType: 'R',
		socailNumber: '',
		insuranceCode: '',
		testName: '',
		etcType: '',
	})

	const [isDateRangeChanged, setIsDateRangeChanged] = useState(false)

	const [rptRowClicked, setRptRowClicked] = useState(false)

	// 수진자 목록
	const getReceptionList = () => {
		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			testSearch.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		// if (moment(testSearch.startDate).isBefore('2023-08-01')) {
		// 	setTestSearch({
		// 		...testSearch,
		// 		startDate: '2023-08-01',
		// 	})
		// }

		if (testSearch.socailNumber) {
			if (testSearch.socailNumber.length !== 8) {
				alertModal('주민번호는 뒤 1자리까지 입력하세요.', 'error', () => {})
				return
			} else {
				const regexp = /^\d{6}-[1-8]$/g
				if (!regexp.test(testSearch.socailNumber)) {
					alertModal('주민번호 형식이 올바르지 않습니다.', 'error', () => {})
					return
				}
			}
		}

		const startDate = moment(testSearch.startDate)
		const endDate = moment(testSearch.endDate)

		// const monthDiff = endDate.diff(startDate, 'months')
		const dayDiff = endDate.diff(startDate, 'days')

		console.log(
			dayDiff,
			!testSearch.patientName,
			!testSearch.socailNumber,
			!testSearch.chartNo,
			isDateRangeChanged
		)

		// 검색기간 6개월 초과 시 testSearch.patientName 또는 testSearch.socialNumber 또는 testSearch.chartNo 중에 하나는 입력되어야 함
		if (
			dayDiff > 365 &&
			!isDateRangeChanged &&
			!testSearch.patientName &&
			!testSearch.socailNumber &&
			!testSearch.chartNo
		) {
			alertModal(
				'검색기간이 <span style="color: red;font-weight: bold;">12개월</span>을 초과할 경우<br/><span style="color: red;font-weight: bold;">조회종류(수진자명, 차트번호, 주민번호)</span> 를<br/>변경하시고 조회부탁 드립니다.',
				'info',
				() => {}
			)
			return
		}

		setIsSearchLoading(true)

		const request = {
			clientID: testSearch.clientID as any,
			patientName: testSearch.patientName,
			chartNo: testSearch.chartNo,
			receptionRegNum: testSearch.receptionRegNum,
			lastStateCode: testSearch.lastStateCode,
			searchTestCd: testSearch.searchTestCd === '0' ? '' : testSearch.searchTestCd,
			startDate: moment(testSearch.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: testSearch.startDate,
			endDate: testSearch.endDate,
			departName: testSearch.departName,
			doctorName: testSearch.doctorName,
			isPDC: testSearch.isPDC ? '1' : '',
			searchType: testSearch.searchType,
			socailNumber: testSearch.socailNumber,
			ward: testSearch.ward,
			insuranceCode: testSearch.insuranceCode,
			testName: testSearch.testName,
		}

		console.log(request)

		setRptTypeCodeList([])
		setReportUrl('')
		setPdfBlob(null)
		setPdfActive(false)
		setFileList([])
		setImageList([])

		setRptRowClicked(false)

		setGrid1isLoading(true)

		setReportLoading(true)
		receptionList(request)
			.then((res) => {
				console.log(res)

				setReceptionListData(res)
				setReportUrl('')
				setDisableRowClick(false)
				setSelectionModel([])
				// if (res.length > 0) {
				// 	setTimeout(() => {
				// 		setSelectionModel([1])
				// 		setTimeout(() => {
				// 			actionReport()
				// 		}, 100)
				// 	}, 100)
				// } else {
				// 	setReportUrl('')
				// }
			})
			.finally(() => {
				setReportLoading(false)
				setGrid1isLoading(false)
				setIsSearchLoading(false)
				setPage1(0)
				// 검색 실행 후 상단으로 스크롤
				const div = document.querySelector('.MuiDataGrid-virtualScroller')
				if (div) div.scrollTop = 0
				// window.scrollTo({ top: 0, behavior: 'smooth' })
			})
	}

	// 이전 결과 팝업
	const [prevResultDialog, setPrevResultDialog] = useState(false)
	const [prevResultList, setPrevResultList] = useState<any[]>([])

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const prevResultClose = () => {
		setPrevResultList([])
		setPrevResultDialog(false)
	}

	const [referenceMList, setReferenceMList] = useState<any[]>([]) // 참고치 목록 (남자)
	const [referenceFList, setReferenceFList] = useState<any[]>([]) // 참고치 목록 (여자)
	const [referenceCList, setReferenceCList] = useState<any[]>([]) // 참고치 목록 (공통)
	const [testInfoItem, setTestInfoItem] = useState({
		TestName: '',
		TestCode: '',
		InsuranceClassChar: '',
		InsuranceCode: '',
		InsurancePrice: '',
		TestTerm: '',
		TestNeccessaryDay: '',
		TestMethod: '',
		SpecimenName: '',
		VolumeOptimum: '',
		KeepMethod: '',
		SpecimenStability: '',
		Reference: '',
		ClinicalSigni: '',
		TakeCaution: '',
		GeneAgreement: '',
		TestRequest: '',
		ContainerPhoto: '',
	})
	const [testInfoDialog, setTestInfoDialog] = useState(false)

	const testInfoClose = () => {
		setTestInfoItem({
			TestName: '',
			TestCode: '',
			InsuranceClassChar: '',
			InsuranceCode: '',
			InsurancePrice: '',
			TestTerm: '',
			TestNeccessaryDay: '',
			TestMethod: '',
			SpecimenName: '',
			VolumeOptimum: '',
			KeepMethod: '',
			SpecimenStability: '',
			Reference: '',
			ClinicalSigni: '',
			TakeCaution: '',
			GeneAgreement: '',
			TestRequest: '',
			ContainerPhoto: '',
		})
		setReferenceMList([])
		setReferenceFList([])
		setReferenceCList([])
		setTestInfoDialog(false)
	}

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleReportListClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleReportListClose = () => {
		setAnchorEl(null)
	}

	const [fileAnchorEl, setFileAnchorEl] = React.useState<null | HTMLElement>(
		null
	)
	const fileOpen = Boolean(fileAnchorEl)
	const handleFileListClick = (event: React.MouseEvent<HTMLElement>) => {
		setFileAnchorEl(event.currentTarget)
	}
	const handleFileListClose = () => {
		setFileAnchorEl(null)
	}

	const reportClose = () => {
		setReportDialog(false)
		setImageDataList([])
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setTestSearch({
			...testSearch,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	// 첨부파일 다운로드
	const attachFileDownload = (
		attachFile: string,
		attachFileName: string,
		AttachFileExtension: string
	) => {
		// download base64 file
		if (!attachFile) return
		if (!attachFileName) return
		if (!AttachFileExtension) return

		const content = atob(attachFile)
		const byteArray = new Uint8Array(content.length)
		for (let i = 0; i < content.length; i++) {
			byteArray[i] = content.charCodeAt(i)
		}
		const blob = new Blob([byteArray], {
			type: `application/${AttachFileExtension}`,
		})
		const downloadLink = document.createElement('a')
		const url = URL.createObjectURL(blob)
		downloadLink.href = url
		downloadLink.download = attachFileName
		downloadLink.click()
		downloadLink.remove()
	}

	// 검사결과 그리드의 결과 버튼 처리
	const [resultTitle, setResultTitle] = useState<string>('')
	const [resultPopupOpen, setResultPopupOpen] = useState(false)
	const [resultPopupText, setResultPopupText] = useState<string>('')
	const resultPopupClose = () => {
		setResultPopupOpen(false)
		setResultTitle('')
		setResultPopupText('')
	}

	const searchRadioTypeOnChange = (e: any) => {
		// 0 : 수진자명, 1 : 차트번호, 3: 생년월일
		if (e.target.value === '0') {
			setTestSearch({
				...testSearch,
				chartNo: '',
				socailNumber: '',
			})
		} else if (e.target.value === '1') {
			setTestSearch({
				...testSearch,
				patientName: '',
				socailNumber: '',
			})
		} else if (e.target.value === '3') {
			setTestSearch({
				...testSearch,
				patientName: '',
				chartNo: '',
			})
		}
		setRadioSearchType(e.target.value)
	}

	const [ICSDialogOpen, setICSDialogOpen] = useState(false)
	const [dialogType, setDialogType] = useState('')
	const ICSDialogClose = () => {
		setICSDialogOpen(false)
	}
	const ICSPopupSubmit = (e: any) => {
		if (dialogType === 'insu') {
			setTestSearch({
				...testSearch,
				insuranceCode: e.InsuranceCode,
				testName: '',
			})
		} else if (dialogType === 'tName') {
			setTestSearch({
				...testSearch,
				insuranceCode: '',
				testName: e.DisplayName,
			})
		}
		setICSDialogOpen(false)
		setDialogType('')
	}
	const ICSDialogOpenAction = (type: string) => {
		// insu : 보험코드, tName : 검사명
		if (type === 'insu') {
			setDialogType('insu')
			setTestSearch({
				...testSearch,
				insuranceCode: '',
			})
		} else if (type === 'tName') {
			setDialogType('tName')
			setTestSearch({
				...testSearch,
				testName: '',
			})
		}
		setICSDialogOpen(true)
	}

	useEffect(() => {
		// 사용자 타입이 거래처일 경우만 자동 조회
		if (userInfo.infoType === 'C') getReceptionList()
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		// 검체 종류의 셀렉트박스 기초 value
		// lv2?.level2.map((item: { id: any; cmmnCd: string }) => {
		// 	if (item.cmmnCd === 'TEST_ALL') {
		// 		setTestSearch({
		// 			...testSearch,
		// 			searchTestCd: '',
		// 		})
		// 	}
		// })

		// setTestSearch({
		// 	...testSearch,
		// 	searchTestCd: '0',
		// })

		// if (
		// 	clientIDs.includes(userInfo?.clientID) ||
		// 	userInfo.infoType === 'S' ||
		// 	userInfo.infoType === 'W'
		// ) {
		// } else {
		// 	// alertModal('권한이 없습니다.', 'error', () => {
		// 	// 	navigate('/admin/test/resultOld')
		// 	// })
		// 	navigate('/admin/test/resultOld')
		// }

		// 사용자 타입이 거래처일 경우만 자동 조회
		if (userInfo.infoType === 'C') {
			setTimeout(() => {
				getReceptionList()
			}, 500)
		}

		apiSBList().then((res) => {
			setSbList(res)
			// let resultList: [] = []
			// if (userInfo?.isEhwa) {
			// 	resultList = res.filter((x: any) => x.DisplayName.includes('이화'))
			// } else {
			// 	resultList = res.filter((x: any) => !x.DisplayName.includes('이화'))
			// }
			// setSbList(resultList)
		})
		// window.addEventListener('resize', handleResize)
		// return () => {
		// 	window.removeEventListener('resize', handleResize)
		// }
	}, [])

	return (
		<>
			{reportIsLoading && (
				<ReportGenerator>
					<CircularProgress />
				</ReportGenerator>
			)}
			{showImageOverlay && (
				<ReportSlider
					imageList={imageList}
					reportDialog={reportDialog}
					reportClose={reportClose}
				/>
			)}
			<SimplePopup
				title={resultTitle}
				message={resultPopupText}
				simplePopupOpen={resultPopupOpen}
				simplePopupClose={resultPopupClose}
				isHTML={false}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<SlideBlockPopup
				popupOpen={slideBlickPopupisOpen}
				popupData={popupData}
				setPopupData={setPopupData}
				selectedSbList={selectedSbList}
				sbList={sbList}
				onPopupClose={onSlideBlockPopupClose}
				onSBItemClick={onSlideBlockItemClick}
				onPopupSubmit={onSlideBlockPopupSubmit}
			/>
			<InsuCodeSearchPopup
				dialogOpen={ICSDialogOpen}
				dialogClose={ICSDialogClose}
				popupSubmit={ICSPopupSubmit}
			/>
			<Stack
				sx={{
					position: 'relative',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.2rem' }}>
					<PageBg2 sx={{ padding: '0.1rem 0rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								검사결과조회
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								{userInfo.branchCode === 'B01201' || userInfo.isEHWAClass ? (
									<>
										<span>
											<Button
												color="secondary"
												size="small"
												variant="contained"
												startIcon={<InsertLinkIcon />}
												sx={{ marginRight: '0.5rem' }}
												onClick={() => {
													navigate('/admin/test/sbResult')
												}}
												disabled={
													selectionModel.length > 0 && slideBlockBtnActive ? false : true
												}
											>
												슬라이드대여조회
											</Button>
										</span>
										<span>
											<Button
												color="secondary"
												size="small"
												variant="contained"
												startIcon={<AllInboxIcon />}
												sx={{ marginRight: '0.5rem' }}
												onClick={actionSlideBlock}
												disabled={
													selectionModel.length > 0 && slideBlockBtnActive ? false : true
												}
											>
												슬라이드대여신청
											</Button>
										</span>
									</>
								) : (
									<></>
								)}
								{/* <span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FileDownloadIcon />}
										sx={{ marginRight: '0.5rem' }}
										onClick={actionReportNewWindow}
										disabled={selectionModel.length > 0 ? false : true}
									>
										프린트(A4)
									</Button>
								</span> */}
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FileDownloadIcon />}
										sx={{ marginRight: '0.5rem' }}
										onClick={actionExcel}
										disabled={
											selectionModel.length > 0 || !disabledExcelDownload ? false : true
										}
									>
										엑셀
									</Button>
								</span>
								{/* <span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<ReceiptIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportPrintAction}
										disabled={selectionModel.length > 0 ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										결과보고서
									</Button>
								</span> */}
								{/* <span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<ImageIcon />}
										sx={{ marginRight: '0.5rem' }}
										onClick={reportImageAction}
										disabled={selectionModel.length > 0 ? false : true}
									>
										이미지
									</Button>
								</span> */}
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FontDownloadTwoToneIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportTextAction}
										disabled={selectionModel.length > 0 ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										텍스트 결과보고서
									</Button>
								</span>
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<CloudDownloadTwoToneIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										onClick={handleFileListClick}
										disabled={fileList?.length === 0 ? true : false}
										aria-controls={fileOpen ? 'file-positioned-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={fileOpen ? 'true' : undefined}
									>
										첨부파일
									</Button>
								</span>
								<Menu
									id="file-positioned-menu"
									aria-labelledby="demo-positioned-button"
									anchorEl={fileAnchorEl}
									open={fileOpen}
									onClose={handleFileListClose}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{fileList?.map((item: any, index: number) => {
										return (
											<MenuItem
												key={index}
												onClick={() =>
													attachFileDownload(
														item.AttachFile,
														item.AttachFileName,
														item.AttachFileExtension
													)
												}
											>
												{item.AttachFileName}
											</MenuItem>
										)
									})}
								</Menu>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
			</Stack>
			<Paper
				id="back-to-top"
				elevation={0}
				sx={{
					width: '100%',
					// overflow: 'hidden',
					backgroundColor: 'transparent',
					// overflow: 'auto',
				}}
			>
				<Wrapper>
					<div className="left">
						<PageBg>
							<Box
								component="form"
								noValidate
								autoComplete="off"
								onKeyUp={onKeyUp}
								sx={
									{
										// borderTop:
										// 	theme.palette.mode === 'dark'
										// 		? '1px solid rgba(81, 81, 81, 1)'
										// 		: '1px solid #ccc',
										// paddingTop: '1rem',
									}
								}
							>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale={locale}
								>
									{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
										<SearchRowItem>
											<p className="labelText">거&nbsp;&nbsp;래&nbsp;&nbsp;처</p>
											<Grid
												container
												spacing={1}
											>
												<Grid
													item
													xs={12}
												>
													<TextField
														variant="outlined"
														size="small"
														type="text"
														fullWidth
														aria-readonly={true}
														value={searchClientName}
														onClick={clientPopupOpenAction}
														inputProps={{
															style: {
																height: '5px',
																fontSize: 12,
															},
														}}
													/>
												</Grid>
											</Grid>
										</SearchRowItem>
									)}
									<SearchRowItem className="h27">
										<p className="labelText">조회범위</p>
										<Grid
											container
											spacing={1}
										>
											<Grid
												item
												xs={12}
											>
												<FormControl>
													<RadioGroup
														row
														aria-labelledby="date-controlled-radio-buttons-group"
														name="date-radio-buttons-group"
														value={dateRange}
														onChange={searchDateRanageOnChange}
														sx={{ ml: '0.5rem' }}
													>
														<FormControlLabel
															value="7d"
															control={<Radio sx={{ padding: '1px' }} />}
															label="7일"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														<FormControlLabel
															value="15d"
															control={<Radio sx={{ padding: '1px' }} />}
															label="15일"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														<FormControlLabel
															value="1m"
															control={<Radio sx={{ padding: '1px' }} />}
															label="1개월"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														<FormControlLabel
															value="3m"
															control={<Radio sx={{ padding: '1px' }} />}
															label="3개월"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														<FormControlLabel
															value="6m"
															control={<Radio sx={{ padding: '1px' }} />}
															label="6개월"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														<FormControlLabel
															value="1y"
															control={<Radio sx={{ padding: '1px' }} />}
															label="12개월"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</SearchRowItem>
									<SearchRowItem className="h27">
										<p className="labelText">
											학&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;부
										</p>
										<Grid
											container
											spacing={1}
										>
											<Grid
												item
												xs={12}
											>
												<FormControl>
													<RadioGroup
														row
														aria-labelledby="testCd-controlled-radio-buttons-group"
														name="testCd-radio-buttons-group"
														value={testSearch.searchTestCd}
														onChange={searchTestCdOnChange}
														sx={{ ml: '0.5rem' }}
													>
														<FormControlLabel
															value={'0'}
															control={<Radio sx={{ padding: '1px' }} />}
															label="전체"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														{testCdList &&
															testCdList.length > 0 &&
															testCdList.map(
																(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
																	<FormControlLabel
																		key={item.id}
																		value={item?.cmmnCd}
																		disabled={userInfo?.testCd ? true : false}
																		control={<Radio sx={{ padding: '1px' }} />}
																		label={item?.cmmnNm === 'STD/HPV' ? '분자진단' : item?.cmmnNm}
																		sx={{
																			'& .MuiFormControlLabel-label': {
																				fontSize: '12px',
																				fontWeight: '500',
																			},
																			width: '70px',
																		}}
																	/>
																)
															)}
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</SearchRowItem>
									<SearchRowItem className="h27">
										<p className="labelText">조회종류</p>
										<Grid
											container
											spacing={1}
										>
											<Grid
												item
												xs={12}
											>
												<FormControl>
													<RadioGroup
														row
														aria-labelledby="date-controlled-radio-buttons-group"
														name="date-radio-buttons-group"
														value={radioSearchType}
														onChange={searchRadioTypeOnChange}
														sx={{ ml: '0.5rem' }}
													>
														<FormControlLabel
															value="0"
															control={<Radio sx={{ padding: '1px' }} />}
															label="수진자명"
															id="searchType0"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														<FormControlLabel
															value="1"
															control={<Radio sx={{ padding: '1px' }} />}
															label="차트번호"
															id="searchType1"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
														{/* <FormControlLabel
															value="2"
															control={<Radio sx={{ padding: '1px' }} />}
															label="접수번호"
															sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
														/> */}
														<FormControlLabel
															value="3"
															control={<Radio sx={{ padding: '1px' }} />}
															label="주민번호"
															id="searchType3"
															sx={{
																'& .MuiFormControlLabel-label': {
																	fontSize: '12px',
																	fontWeight: '500',
																},
																width: '70px',
															}}
														/>
													</RadioGroup>
												</FormControl>
												<FormGroup sx={{ display: 'inline-flex' }}>
													<FormControlLabel
														control={
															<Checkbox
																sx={{ padding: '1px' }}
																checked={testSearch.isPDC}
																onChange={(e) => {
																	setTestSearch({
																		...testSearch,
																		isPDC: e.target.checked,
																	})
																}}
															/>
														}
														label="이상결과만 보기"
														sx={{
															color: 'red',
															'& .MuiFormControlLabel-label': {
																fontSize: '12px',
																fontWeight: '500',
															},
														}}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</SearchRowItem>
									<SearchRowItem className="last">
										<p className="labelText">조회내용</p>
										<Grid
											container
											spacing={1}
										>
											<Grid
												item
												xs={7}
											>
												<Grid
													container
													spacing={0.3}
												>
													<Grid
														item
														xs={6}
													>
														<DatePicker
															value={testSearch.startDate}
															mask={'____-__-__'}
															inputFormat={'YYYY-MM-DD'}
															onChange={(newValue) => {
																dateRanageOnChange(newValue, 'startDate')
															}}
															renderInput={(params) => (
																<TextField
																	size="small"
																	{...params}
																	fullWidth
																	// inputProps={{
																	// 	style: {
																	// 		fontWeight: '500',
																	// 	},
																	// }}
																/>
															)}
														/>
													</Grid>
													<Grid
														item
														xs={6}
													>
														<DatePicker
															value={testSearch.endDate}
															mask={'____-__-__'}
															inputFormat={'YYYY-MM-DD'}
															onChange={(newValue) => {
																dateRanageOnChange(newValue, 'endDate')
															}}
															renderInput={(params) => (
																<TextField
																	size="small"
																	{...params}
																	fullWidth
																	// inputProps={{
																	// 	style: {
																	// 		fontWeight: '500',
																	// 	},
																	// }}
																/>
															)}
														/>
													</Grid>
													<Grid
														item
														xs={12}
													>
														{radioSearchType === '0' ? (
															<div style={{ position: 'relative' }}>
																<span
																	style={{
																		position: 'absolute',
																		fontSize: 14,
																		fontWeight: '500',
																		top: '0',
																		left: '0',
																		bottom: '0',
																		padding: '6px',
																		borderRight: '1px solid #ccc',
																	}}
																>
																	수진자명
																</span>
																<TextField
																	variant="outlined"
																	size="small"
																	type="text"
																	fullWidth
																	value={testSearch.patientName}
																	onChange={(e) => {
																		setTestSearch({
																			...testSearch,
																			patientName: e.target.value,
																		})
																	}}
																	inputProps={{
																		style: {
																			// height: '5px',
																			fontSize: 14,
																			paddingLeft: 70,
																		},
																	}}
																/>
															</div>
														) : radioSearchType === '1' ? (
															<div style={{ position: 'relative' }}>
																<span
																	style={{
																		position: 'absolute',
																		fontSize: 14,
																		fontWeight: '500',
																		top: '0',
																		left: '0',
																		bottom: '0',
																		padding: '6px',
																		borderRight: '1px solid #ccc',
																	}}
																>
																	차트번호
																</span>
																<TextField
																	variant="outlined"
																	size="small"
																	type="text"
																	fullWidth
																	value={testSearch.chartNo}
																	onChange={(e) => {
																		setTestSearch({
																			...testSearch,
																			chartNo: e.target.value,
																		})
																	}}
																	inputProps={{
																		style: {
																			//height: '5px',
																			fontSize: 14,
																			paddingLeft: 70,
																		},
																	}}
																/>
															</div>
														) : radioSearchType === '2' ? (
															<div style={{ position: 'relative' }}>
																<span
																	style={{
																		position: 'absolute',
																		fontSize: 14,
																		fontWeight: '500',
																		top: '0',
																		left: '0',
																		bottom: '0',
																		padding: '6px',
																		borderRight: '1px solid #ccc',
																	}}
																>
																	접수번호
																</span>
																<TextField
																	variant="outlined"
																	size="small"
																	type="text"
																	fullWidth
																	value={testSearch.receptionRegNum}
																	onChange={(e) => {
																		setTestSearch({
																			...testSearch,
																			receptionRegNum: e.target.value,
																		})
																	}}
																	inputProps={{
																		style: {
																			//height: '5px',
																			fontSize: 14,
																			paddingLeft: 70,
																		},
																	}}
																/>
															</div>
														) : (
															<div style={{ position: 'relative' }}>
																<span
																	style={{
																		position: 'absolute',
																		fontSize: 14,
																		fontWeight: '500',
																		top: '0',
																		left: '0',
																		bottom: '0',
																		padding: '6px',
																		borderRight: '1px solid #ccc',
																	}}
																>
																	주민번호
																</span>
																<TextField
																	variant="outlined"
																	size="small"
																	type="text"
																	fullWidth
																	value={testSearch.socailNumber}
																	onChange={(e) => {
																		let inputValue = e.target.value

																		// 정규식을 사용하여 입력값을 형식에 맞게 가공합니다
																		inputValue = inputValue.replace(/\D/g, '') // 숫자 이외의 문자 제거
																		if (inputValue.length > 6) {
																			// 입력값이 6자리 이상이면 7번째 자리에 하이픈을 추가합니다
																			inputValue =
																				inputValue.slice(0, 6) + '-' + inputValue.slice(6)
																		}
																		setTestSearch({
																			...testSearch,
																			socailNumber: inputValue,
																		})
																	}}
																	inputProps={{
																		style: {
																			// height: '5px',
																			fontSize: 14,
																			paddingLeft: 70,
																		},
																		maxLength: 8,
																	}}
																/>
															</div>
														)}
													</Grid>
												</Grid>
											</Grid>
											<Grid
												item
												xs={5}
											>
												<div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<Button
														color="primary"
														variant="contained"
														// startIcon={<ContentPasteSearchTwoToneIcon />}
														onClick={actionSearch}
														disabled={isSearchLoading ? true : false}
														sx={{
															height: 73,
															width: 'calc(50% - 2.5px)',
															background: '#3498db',
															fontSize: '16px',
														}}
													>
														조회
													</Button>
													<Button
														color="success"
														variant="contained"
														// startIcon={<ExpandIcon />}
														onClick={() => setIsSearchExpanded(!isSearchExpanded)}
														sx={{
															height: 73,
															width: 'calc(50% - 2.5px)',
															fontSize: '16px',
														}}
													>
														조건확장
													</Button>
												</div>
											</Grid>
										</Grid>
									</SearchRowItem>
									<SearchRowItem
										sx={{ mt: 0.2, display: isSearchExpanded ? 'flex' : 'none' }}
									>
										<p className="labelText">기타</p>
										<Grid
											container
											spacing={1}
										>
											<Grid
												item
												xs={4}
											>
												<TextField
													variant="outlined"
													size="small"
													type="text"
													fullWidth
													value={testSearch.doctorName}
													onChange={(e) => {
														setTestSearch({
															...testSearch,
															doctorName: e.target.value,
														})
													}}
													inputProps={{
														style: {
															height: '5px',
															fontSize: 12,
														},
														placeholder: '담당의',
													}}
												/>
											</Grid>
											<Grid
												item
												xs={4}
											>
												<TextField
													variant="outlined"
													size="small"
													type="text"
													fullWidth
													value={userInfo.departName || testSearch.departName}
													disabled={userInfo.departName ? true : false}
													onChange={(e) => {
														setTestSearch({
															...testSearch,
															departName: e.target.value,
														})
													}}
													inputProps={{
														style: {
															height: '5px',
															fontSize: 12,
														},
														placeholder: '진료과',
													}}
												/>
											</Grid>
											<Grid
												item
												xs={4}
											>
												<TextField
													variant="outlined"
													size="small"
													type="text"
													fullWidth
													value={userInfo.ward || testSearch.ward}
													disabled={userInfo.ward ? true : false}
													onChange={(e) => {
														setTestSearch({
															...testSearch,
															ward: e.target.value,
														})
													}}
													inputProps={{
														style: {
															height: '5px',
															fontSize: 12,
														},
														placeholder: '병동',
													}}
												/>
											</Grid>
											<Grid
												item
												xs={4}
												sx={{ pt: '4px !important' }}
											>
												<TextField
													variant="outlined"
													size="small"
													type="text"
													fullWidth
													value={testSearch.insuranceCode}
													onClick={() => ICSDialogOpenAction('insu')}
													inputProps={{
														style: {
															height: '5px',
															fontSize: 12,
														},
														placeholder: '보험코드',
													}}
												/>
											</Grid>
											<Grid
												item
												xs={4}
												sx={{ pt: '4px !important' }}
											>
												<TextField
													variant="outlined"
													size="small"
													type="text"
													fullWidth
													value={testSearch.testName}
													onClick={() => ICSDialogOpenAction('tName')}
													inputProps={{
														style: {
															height: '5px',
															fontSize: 12,
														},
														placeholder: '검사명',
													}}
												/>
											</Grid>
											<Grid
												item
												xs={4}
												sx={{ pt: '4px !important' }}
											>
												<FormControl
													fullWidth
													size={'small'}
												>
													{/* <InputLabel id="status-select-label">진행단계</InputLabel> */}
													<Select
														labelId="status-select-label"
														id="status-simple-select"
														value={testSearch.lastStateCode}
														onChange={(e) =>
															setTestSearch({
																...testSearch,
																lastStateCode: e.target.value,
															})
														}
														size={'small'}
														fullWidth
														style={{
															height: '22px',
														}}
													>
														<MenuItem value={''}>진행단계</MenuItem>
														<MenuItem value={'0'}>전체</MenuItem>
														<MenuItem value={'1'}>접수</MenuItem>
														<MenuItem value={'2'}>재검</MenuItem>
														<MenuItem value={'3'}>진행</MenuItem>
														<MenuItem value={'4'}>완료</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</SearchRowItem>
								</LocalizationProvider>
							</Box>
							{/* <Typography
								variant="subtitle1"
								component="h3"
								sx={{ lineHeight: '1rem', mt: '1rem' }}
							>
								수진자 리스트
							</Typography> */}
							<Box
								sx={{
									height: isLgUp
										? userInfo.infoType === 'W' || userInfo.infoType === 'S'
											? 'calc(100vh - 365px)'
											: 'calc(100vh - 335px)'
										: 'calc(100vh - 365px)',
									// height: isLgUp
									// 	? isSearchExpanded
									// 		? 'calc(100vh - 380px)'
									// 		: 'calc(100vh - 336px)'
									// 	: 'auto',
									'@media (max-width: 1023px)': {
										height: '400px',
										overflow: 'auto',
									},
									width: '100%',
								}}
							>
								<DataGridPremium
									apiRef={grid1Ref}
									page={page1}
									onPageChange={(newPage) => setPage1(newPage)}
									rows={receptionListData}
									getRowId={(row) => row.rowNum}
									columns={columns}
									pageSize={size1}
									onPageSizeChange={(newPageSize) => setSize1(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
									pagination
									// autoHeight={true}
									autoHeight={isMdUp ? false : true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									loading={grid1isLoading}
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										!disableRowClick && setSelectionModel(newSelection)
									}}
									onRowClick={() => {
										!disableRowClick && actionReport()
									}}
									// onSelectionModelChange={(newSelection) => {
									// 	setSelectionModel(newSelection)
									// }}
									// onRowClick={actionReport}
									density={(env.desnse as GridDensity) || 'compact'}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											fontWeight: '500',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
										'& .MuiDataGrid-cell:focus': {
											outline: 'none',
										},
										'& .MuiDataGrid-cell:focus-within': {
											outline: 'none',
										},
										'& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
											backgroundColor: 'rgba(75, 123, 236, 0.15)',
										},
										'& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected.Mui-hovered':
											{
												backgroundColor: 'rgba(75, 123, 236, 0.3)',
											},
									}}
									disableMultipleSelection={true}
									onColumnOrderChange={handleColumnReorder}
									onColumnVisibilityModelChange={(newModel) =>
										handleColumnVisibleChange(newModel)
									}
									initialState={{
										columns: {
											columnVisibilityModel: gridColumnVisible
												? {
														...gridColumnVisible,
														PartName: gridColumnVisible?.PartName ?? false,
														DepartName: gridColumnVisible?.DepartName ?? false,
														ReceptionID: gridColumnVisible?.ReceptionID ?? false,
														isPrint: gridColumnVisible?.isPrint ?? false,
														PartCode: gridColumnVisible?.PartCode ?? false,
														DoctorName: gridColumnVisible?.DoctorName ?? false,
												  }
												: {
														PartName: false,
														DepartName: false,
														ReceptionID: false,
														isPrint: false,
														PartCode: false,
														DoctorName: false,
												  },
											orderedFields: [...columnOrder],
										},
									}}
									rowHeight={40}
								/>
							</Box>
						</PageBg>
					</div>
					<div className="right">
						<PageBg
							style={{
								height: isMdUp ? '100%' : '800px',
								marginLeft: isLgUp ? '1rem' : '0',
								padding: 0,
							}}
						>
							<Box
								sx={{
									height: isMdUp
										? isSearchExpanded
											? 'calc(100vh - 560px)'
											: 'calc(100vh - 190px)'
										: 'auto',
									'@media (max-width: 1023px)': {
										height: '400px',
										overflow: 'auto',
										marginTop: '1rem',
									},
								}}
							>
								<div>
									{rptTypeCodeList && rptTypeCodeList.length > 0 && (
										<ReportButtonGroup
											variant="contained"
											aria-label="결과지 버튼 그룹"
										>
											{rptTypeCodeList &&
												rptTypeCodeList.map((item: any, index: number) => {
													return (
														<ReportButton
															variant="contained"
															//className={item.LastStateCode === 'D90' ? 'isDone' : ''}
															className={
																activeButtonNumber === index && item.LastStateCode === 'D90'
																	? 'isDone active'
																	: activeButtonNumber === index && item.LastStateCode !== 'D90'
																	? 'inProgress active'
																	: activeButtonNumber !== index && item.LastStateCode === 'D90'
																	? 'isDone'
																	: activeButtonNumber !== index && item.LastStateCode !== 'D90'
																	? 'inProgress'
																	: ''
															}
															onClick={() => {
																onRptTypeCodeClick(item, index)
															}}
															sx={{ borderColor: 'red' }}
														>
															{item.ReportTitle}
														</ReportButton>
													)
												})}
										</ReportButtonGroup>
									)}
									{/* <Button
										variant="contained"
										color="secondary"
										sx={{ mr: '0.5rem' }}
									>
										조직
									</Button>
									<Button
										variant="contained"
										color="secondary"
										sx={{ mr: '0.5rem' }}
									>
										세포
									</Button>
									<Button
										variant="contained"
										color="secondary"
										sx={{ mr: '0.5rem' }}
									>
										STI
									</Button>
									<Button
										variant="contained"
										color="secondary"
										sx={{ mr: '0.5rem' }}
									>
										HPV
									</Button>
									<Button
										variant="contained"
										color="secondary"
										sx={{ mr: '0.5rem' }}
									>
										미생물
									</Button>
									<Button
										variant="contained"
										color="secondary"
										sx={{ mr: '0.5rem' }}
									>
										산전기형
									</Button> */}
								</div>
								<IFrameContainer
									id="reportIframe"
									className={reportLoading ? 'active' : ''}
								>
									<IfrmaeLoading
										// style={{
										// 	display: reportLoading ? 'block' : 'none',
										// }}
										style={{ display: 'none' }}
									>
										<CircularProgress />
									</IfrmaeLoading>
									<Iframe
										src={reportUrl}
										title="결과지"
										id="reportIframe"
									></Iframe>
								</IFrameContainer>

								{pdfActive && (
									<PDFContainer>
										<iframe
											src={pdfBlob}
											title="결과지"
											id="pdfIframe"
											style={{ width: '100%', height: '100%' }}
										/>
									</PDFContainer>
								)}
							</Box>
						</PageBg>
					</div>
				</Wrapper>
			</Paper>
			<div style={{ display: 'none' }}>
				<DataGridPremium
					page={1}
					apiRef={excelDownloadRef}
					rows={excelDataGridRows}
					getRowId={(row) => row.rowNum}
					pagination
					localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
					components={{
						Toolbar: GridToolbar,
						LoadingOverlay: LinearProgress,
					}}
					componentsProps={{
						toolbar: {
							csvOptions: { disableToolbarButton: true },
							excelOptions: { disableToolbarButton: true },
							printOptions: { disableToolbarButton: true },
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 250 },
						},
					}}
					getDetailPanelHeight={({ row }) => 'auto'}
					density={(env.desnse as GridDensity) || 'compact'}
					getRowHeight={() => 'auto'}
					initialState={{
						columns: {
							columnVisibilityModel: {
								rowNum: false,
							},
						},
					}}
					columns={[
						{
							field: 'rowNum',
							headerName: 'rowNum',
							width: 100,
						},
						{
							field: 'ClientCode',
							headerName: '병원코드',
							width: 100,
						},
						{
							field: 'TestCode',
							headerName: '검사코드',
							width: 100,
						},
						{
							field: 'ResultData',
							headerName: '검사결과',
							width: 100,
						},
						{
							field: 'QualityAddCode',
							headerName: '보험코드',
							width: 100,
						},
						{
							field: 'Reference',
							headerName: '참고치',
							width: 100,
						},
						{
							field: 'Unit',
							headerName: '단위',
							width: 100,
						},
						{
							field: 'ReceptionDate',
							headerName: '접수일자',
							width: 100,
						},
						{
							field: 'ReceptionRegNum',
							headerName: '접수번호',
							width: 100,
						},
						{
							field: 'ChartNo',
							headerName: '챠트번호',
							width: 100,
						},
						{
							field: 'SCode',
							headerName: '검체코드',
							width: 100,
						},
						{
							field: 'TestName',
							headerName: '검사명',
							width: 100,
						},
						{
							field: 'PatientName',
							headerName: '수진자명',
							width: 100,
						},
						{
							field: 'SocialNumber',
							headerName: '주민번호',
							width: 100,
						},
						{
							field: 'Decision',
							headerName: '판정',
							width: 100,
						},
					]}
					pageSize={10}
					autoHeight={true}
				/>
			</div>
			<PrevResult
				prevResultList={prevResultList}
				prevResultClose={prevResultClose}
				prevResultDialog={prevResultDialog}
			/>
			<TestInfo
				testInfoItem={testInfoItem}
				referenceMList={referenceMList}
				referenceFList={referenceFList}
				referenceCList={referenceCList}
				testInfoDialog={testInfoDialog}
				testInfoClose={testInfoClose}
			/>
		</>
	)
}

export default Result
