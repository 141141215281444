import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'

/**
 * data-grid iterator에서 object key 리턴
 * @param iter
 * @returns
 */
export const keysInIterator = (iter: Map<GridRowId, GridValidRowModel>) => {
	return Array.from(iter.keys())
}

export const valuesInIterator = (iter: Map<GridRowId, GridValidRowModel>) => {
	return Array.from(iter.values())
}

/**
 * Object 내부의 value를 배열로 리턴
 */
export const valuesInObject = (obj: any) => {
	return Object.values(obj)
}
