import { createSlice } from '@reduxjs/toolkit'

export const cmmnCodeSlice = createSlice({
  name: 'cmmnCodeFactory',
  initialState: {
    codeList: [],
  },
  reducers: {
    setCmmnCd: (state, actions) => {
      state.codeList = actions.payload
    },
    removeCmmnCd: state => {
      state.codeList = []
    },
  },
})

export const { setCmmnCd, removeCmmnCd } = cmmnCodeSlice.actions
export default cmmnCodeSlice.reducer
export const selectCmmnCode = (state: any) => state.cmmnCodeFactory.codeList
