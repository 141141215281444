import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	InputAdornment,
	ButtonGroup,
	Alert,
	AppBar,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Toolbar,
	AlertTitle,
	Table,
	TableRow,
	TableBody,
	TableCell,
	List,
	ListItem,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridDensity,
	useGridApiRef,
	GridRenderEditCellParams,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
	GridRowModesModel,
	GridRowsProp,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
	alertModal,
	autoCellPhone,
	confirmAlert,
	hexToRgba,
	validCellPhone,
} from 'util/util'
import SaveAsTwoTone from '@mui/icons-material/SaveAsTwoTone'

import {
	apiSMSGroupPrvList,
	apiSMSGroupPrvSave,
	apiSMSSlvPrvList,
	apiSMSSlvPrvSave,
	apiSlavePrvCopy,
	apiClientSmsMstList,
} from 'api/api'
import ClientSearchPopup from 'views/test/clientSearchPopup'

import Divider from '@mui/material/Divider'
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone'
import BackupTwoToneIcon from '@mui/icons-material/BackupTwoTone'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import { valuesInIterator } from 'util/objectUtil'
import { read, utils } from 'xlsx'
import TransitionEffect from 'components/TransitionEffect'
import CloseIcon from '@mui/icons-material/Close'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useDropzone } from 'react-dropzone'
import Img1 from '../../assets/img/sms_example.png'
import { useNavigate } from 'react-router-dom'

import FolderIcon from '@mui/icons-material/Folder'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import Delete from '@mui/icons-material/Delete'
import DeleteForeverTwoTone from '@mui/icons-material/DeleteForeverTwoTone'
import OfflineShareIcon from '@mui/icons-material/OfflineShare'

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const ExampleBox = styled(Box)(({ theme }) => ({
	marginTop: '1rem',
	'& .exampleImg': {
		display: 'block',
		maxWidth: '100%',
		margin: '2rem auto',
	},
}))

const Address = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const navigate = useNavigate()

	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const [isSmsUser, setIsSmsUser] = useState(false)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(false)

	const [search, setSearch] = useState({
		ClientID: userInfo.clientID,
		ChartNo: '',
		PatientName: '',
	})
	const [slvSearch, setSlvSearch] = useState({
		ClientID: search.ClientID,
		MstID: '',
		ChartNo: '',
		PatientName: '',
	})

	const actionAdd = () => {
		if (selectedMstID === 0) {
			alertModal('고객그룹을 선택하세요.', 'error', () => {})
			return
		}
		// 추가된 행이 있는지 확인
		let cnt = 0
		slvRows.forEach((row) => {
			if (row.RowCrud === 'C') cnt++
		})

		if (cnt > 0) {
			alertModal(
				'추가된 행을 저장 후 다시 추가하세요.<br/>등록할 고객이 많을 경우 엑셀 업로드를 이용하세요.',
				'error',
				() => {}
			)
			return
		}

		setSlvRows((prevRows) => [
			{
				SlvID: Math.round(Math.random() * 99999999),
				ChartNo: '',
				PatientName: '',
				CellNumber: '',
				Memo: '',
				RowCrud: 'C',
				MstID: selectedMstID,
			},
			...prevRows,
		])
	}

	const actionSearch = () => {
		console.table(search)
		setGridMstLoading(true)
		apiSMSGroupPrvList(search)
			.then((res) => {
				console.log(res)
				setMstRows(res)
				setClientList(res)
			})
			.finally(() => {
				setGridMstLoading(false)
			})
	}

	const actionReset = () => {}

	const actionSlvSearch = () => {
		apiSMSSlvPrvList(slvSearch).then((res) => setSlvRows(res))
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) {
			setSlvSearch({
				...slvSearch,
				ClientID: search.ClientID,
			})
			const MstID = selectionMstModel[0]
			console.log(MstID)
			const request = {
				...slvSearch,
				ClientID: search.ClientID,
				MstID: MstID,
			}
			apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
		}
	}

	const [clientList, setClientList] = useState<any[]>([])

	const [selectedDropdown, setSelectedDropdown] = useState<any>(null)

	// grid state
	const mstRef = useGridApiRef()
	const [mstRows, setMstRows] = useState<GridRowModel[]>([])
	const [selectionMstModel, setSelectionMstModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	const onMstRowClick = (e: GridRowModel) => {
		setSlvSearch({
			...slvSearch,
			ClientID: search.ClientID,
			MstID: e.row.MstID,
		})
		const request = {
			...slvSearch,
			ClientID: search.ClientID,
			MstID: e.row.MstID,
		}
		console.table(request)
		apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
	}

	const [groupName, setGroupName] = useState('')

	const slvRef = useGridApiRef()
	const [slvRows, setSlvRows] = useState<GridRowModel[]>([])
	const [selectionSlvModel, setSelectionSlvModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridSlvLoading, setGridSlvLoading] = useState(false)
	const [gridSlvPageSize, setGridSlvPageSize] = useState(env.row ? env.row : 100)

	const onSlvRowClick = (e: GridRowModel) => {}

	const actionSlvSave = () => {
		console.log(slvRows)
		console.log(slvSelectionModel)
		const valuesArrays = valuesInIterator(slvRef.current.getSelectedRows())
		console.log(valuesArrays)
		if (valuesArrays.length === 0) {
			alertModal('저장할 데이터가 없습니다.', 'error', () => {})
			return
		}
		let isInValid = false
		valuesArrays.forEach((row: any) => {
			const { ChartNo, PatientName, CellNumber } = row
			if (
				(ChartNo && ChartNo.trim() === '') ||
				(PatientName && PatientName.trim() === '') ||
				(CellNumber && CellNumber.trim() === '')
			)
				isInValid = true
		})
		let isCellNumberIsInValid = false
		valuesArrays.forEach((row: any) => {
			const cellPhoneNumber = validCellPhone(row.CellNumber)
			console.log(cellPhoneNumber)
			if (cellPhoneNumber === false) {
				isCellNumberIsInValid = true
			} else if (!row.CellNumber.includes('-')) {
				row.CellNumber = autoCellPhone(row.CellNumber)
			}
		})
		if (isInValid) {
			alertModal(
				'차트번호, 성명, 핸드폰 번호는 필수 입력값입니다.',
				'error',
				() => {}
			)
			return
		} else if (isCellNumberIsInValid) {
			alertModal('핸드폰 번호가 올바르지 않습니다.', 'error', () => {})
			return
		} else {
			// apiSMSSlvList, apiSMSSlvSave
			confirmAlert(
				'저장하시겠습니까?',
				() => {
					setGridSlvLoading(true)
					const data = {
						ClientID:
							userInfo.infoType === 'S' || userInfo.infoType === 'W'
								? search.ClientID
								: userInfo.clientID,
						json: [...valuesArrays],
					}
					console.log(data)
					apiSMSSlvPrvSave(data)
						.then((res) => {
							console.log(res)
							alertModal(res.result, 'success', () => {
								const request = {
									ClientID: search.ClientID,
									MstID: selectedMstID,
								}
								apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
							})
						})
						.finally(() => {
							setGridSlvLoading(false)
						})
				},
				() => {}
			)
		}
	}

	const actionSlvDelete = () => {
		if (selectionSlvModel.length === 0) {
			alertModal('삭제 할 행을 선택하세요.', 'error', () => {})
			return
		}

		const selectedRows = slvRows.filter((row) =>
			selectionSlvModel.includes(row.SlvID)
		)
		console.log(selectedRows)

		selectedRows.forEach((row: any) => {
			row.RowCrud = 'D'
		})

		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				setGridSlvLoading(true)
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...selectedRows],
				}
				console.log(data)
				apiSMSSlvPrvSave(data)
					.then((res) => {
						console.log(res)
						alertModal(res.result, 'success', () => {
							const request = {
								ClientID: search.ClientID,
								MstID: selectedMstID,
							}
							apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
						})
					})
					.finally(() => {
						setGridSlvLoading(false)
					})
			},
			() => {}
		)
	}

	const actionSlvCopy = () => {
		if (selectedDropdown === selectedMstID) {
			alertModal('같은 그룹으로 복사할 수 없습니다.', 'error', () => {})
			return
		}
		if (!selectedDropdown) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (selectionSlvModel.length === 0) {
			alertModal('복사 할 행을 선택하세요.', 'error', () => {})
			return
		}

		confirmAlert(
			'복사하시겠습니까?',
			() => {
				const selectedRows = slvRows.filter((row) =>
					selectionSlvModel.includes(row.SlvID)
				)
				console.log(selectedRows)

				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...selectedRows],
					CMD: 'COPY',
					MstID: selectedDropdown,
				}
				console.table(data)
				apiSlavePrvCopy(data).then((res) => {
					console.log(res)
					alertModal(res.result, 'success', () => {
						const request = {
							ClientID: search.ClientID,
							MstID: selectedMstID,
						}
						apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
					})
				})
			},
			() => {}
		)
	}

	const actionSlvMove = () => {
		if (selectedDropdown === selectedMstID) {
			alertModal('같은 그룹으로 이동할 수 없습니다.', 'error', () => {})
			return
		}
		if (!selectedDropdown) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (selectionSlvModel.length === 0) {
			alertModal('복사 할 행을 선택하세요.', 'error', () => {})
			return
		}

		confirmAlert(
			'이동 하시겠습니까? 기존 그룹에서는 삭제됩니다.',
			() => {
				const selectedRows = slvRows.filter((row) =>
					selectionSlvModel.includes(row.SlvID)
				)
				console.log(selectedRows)

				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...selectedRows],
					CMD: 'MOVE',
					MstID: selectedDropdown,
				}
				console.table(data)
				apiSlavePrvCopy(data).then((res) => {
					console.log(res)
					alertModal(res.result, 'success', () => {
						const request = {
							ClientID: search.ClientID,
							MstID: selectedMstID,
						}
						apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
					})
				})
			},
			() => {}
		)
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			ClientID: e.ClientID,
		})
		setClientDialogOpen(false)
		const request = {
			...search,
			ClientID: e.ClientID,
		}
		apiSMSGroupPrvList(request).then((res) => {
			console.log(res)
			setMstRows(res)
			setClientList(res)
		})
	}

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [editRows, setEditRows] = useState<GridRowsProp>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		setEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}

	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionMstModel((prevState) => [...rows])
	}

	const actionMstAdd = () => {
		const clientID =
			userInfo.infoType === 'S' || userInfo.infoType === 'W'
				? search.ClientID
				: userInfo.clientID
		if (clientID === '') {
			alertModal('거래처를 선택하세요.', 'error', () => {})
			return
		}
		let isNotSave = false
		mstRows.forEach((row) => {
			if (row.RowCrud === 'C') isNotSave = true
		})
		if (isNotSave) {
			alertModal('추가된 행을 저장 후 다시 추가하세요.', 'error', () => {})
			return
		}

		setMstRows((prevRows) => [
			{
				MstID: Math.round(Math.random() * 99999999),
				GroupName: '',
				RowCrud: 'C',
			},
			...prevRows,
		])
	}
	const actionMstDel = () => {
		const json = mstRef.current.getSelectedRows()
		const valuesArray = valuesInIterator(json)
		console.log(valuesArray)

		if (slvSelectionModel.length > 0) {
			alertModal(
				'하위그룹 목록이 있을 경우 하위 그룹을 삭제 후 다시 시도하세요.',
				'error',
				() => {}
			)
			return
		}

		// C 인거는 그냥 삭제
		valuesArray.forEach((element: any) => {
			if (element.RowCrud === 'C') {
				setMstRows((prevRows) => {
					return prevRows.filter((row) => row.MstID !== element.MstID)
				})
			} else {
				element.RowCrud = 'D'
			}
		})
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...valuesArray],
				}
				apiSMSGroupPrvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						actionSearch()
					})
				})
			},
			() => {}
		)
	}

	// 그룹 목록에서 input을 클릭 했을때
	const onMstItemClick = (MstID: number) => {
		setSlvSearch({
			...slvSearch,
			ClientID: search.ClientID,
			MstID: MstID.toString(),
		})
		setSelectedMstID(MstID)
		const request = {
			...slvSearch,
			ClientID: search.ClientID,
			MstID: MstID,
		}
		console.table(request)
		apiSMSSlvPrvList(request).then((res) => setSlvRows(res))
	}

	const [selectedMstID, setSelectedMstID] = useState(0)
	const mstRowRef = useRef<any[]>([])
	const [mstGroupName, setMstGroupName] = useState('')
	const actionMstSave = () => {
		if (mstGroupName && mstGroupName.trim() === '') {
			alertModal('그룹명을 입력하세요.', 'error', () => {})
			return
		}
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [{ RowCrud: 'C', GroupName: mstGroupName }],
				}
				console.log(data)
				apiSMSGroupPrvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						// setSelectedMstID(0)
						setMstGroupName('')
						actionSearch()
					})
				})
			},
			() => {}
		)
	}
	const actionMstUpdate = (e: any, MstID: number) => {
		let isGroupNameEmpty = false
		const editedRows: any[] = []
		mstRows.forEach((row) => {
			if (row.MstID === MstID) {
				row.RowCrud = 'U'
				if (row.GroupName.trim() === '') {
					isGroupNameEmpty = true
				} else {
					editedRows.push(row)
				}
			}
		})
		if (isGroupNameEmpty) {
			alertModal('그룹명을 입력하세요.', 'error', () => {})
			return
		}
		console.log(editedRows)
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...editedRows],
				}
				console.log(data)
				apiSMSGroupPrvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setMstGroupName('')
						actionSearch()
					})
				})
			},
			() => {}
		)
	}
	const actionMstDelete = (e: any, MstID: number) => {
		const editedRows: any[] = []
		mstRows.forEach((row) => {
			if (row.MstID === MstID) {
				row.RowCrud = 'D'
				editedRows.push(row)
			}
		})
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...editedRows],
				}
				apiSMSGroupPrvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						actionSearch()
					})
				})
			},
			() => {}
		)
	}

	const onMstStateChange = () => {}

	const [rowSlvModesModel, setRowSlvModesModel] = useState<GridRowModesModel>({})
	const [slvSelectionModel, setSlvSelectionModel] = useState<GridSelectionModel>(
		[]
	)
	const [editSlvRows, setSlvEditRows] = useState<GridRowsProp>([])

	const processSlvRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const selectSlvChangeHandler = (rows: GridSelectionModel) => {
		setSelectionSlvModel((prevState) => [...rows])
	}
	const handleSlvRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleSlvRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onSlvCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setSlvEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const onSlvStateChange = () => {}

	const openExcelPopup = () => {
		setUploadOpen(true)
		setFileObject(null)
	}

	const setCloseDialog = () => {
		setUploadOpen(false)
		setFileObject(null)
	}

	const [uploadOpen, setUploadOpen] = useState(false)
	const [fileObject, setFileObject] = useState<File | null>(null)
	const [fileInfo, setFileInfo] = useState({
		fileName: '',
		orgFileName: '',
	})
	const [tmpIsDuplicate, setTmpIsDuplicate] = useState(false)
	const [tmpDataList, setTmpDataList] = useState<any[]>([])

	const [excelMstID, setExcelMstID] = useState('0')

	const actionExcelUpload = () => {
		const file = fileObject as File
		console.log(file)
		if (file) {
			const reader = new FileReader()
			console.log(reader)
			reader.onload = (e) => {
				const f = new Uint8Array(e.target?.result as ArrayBuffer)
				const wb = read(f)
				console.log(wb)
				const tmpData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
					raw: false,
					header: ['ChartNo', 'PatientName', 'CellNumber', 'Memo'],
				})
				// data remove first row
				const data = tmpData.slice(1)
				console.log(data)

				if (excelMstID === '0') {
					alertModal('고객그룹을 선택하세요.', 'error', () => {})
					return
				}

				let isInValid = false
				data.forEach((row: any) => {
					const { ChartNo, PatientName, CellNumber } = row
					row.RowCrud = 'C'

					if (
						(ChartNo && ChartNo.trim() === '') ||
						(PatientName && PatientName.trim() === '') ||
						(CellNumber && CellNumber.trim() === '')
					)
						isInValid = true
				})
				let isCellNumberInValid = false
				data.forEach((row: any) => {
					row.MstID = excelMstID
					const cellPhoneNumber = validCellPhone(row.CellNumber)
					console.log(cellPhoneNumber, row.CellNumber)
					if (cellPhoneNumber === false) {
						isCellNumberInValid = true
					} else if (!row.CellNumber.includes('-')) {
						row.CellNumber = autoCellPhone(row.CellNumber)
					}
				})
				if (isInValid) {
					alertModal('필수 입력값이 없습니다.', 'error', () => {})
					return
				}
				if (isCellNumberInValid) {
					alertModal('핸드폰 번호가 올바르지 않습니다.', 'error', () => {})
					return
				}
				console.log(isInValid && isCellNumberInValid && data.length > 0)
				if (!isInValid && !isCellNumberInValid && data.length > 0) {
					confirmAlert(
						'저장하시겠습니까?',
						() => {
							setGridSlvLoading(true)
							const request = {
								ClientID:
									userInfo.infoType === 'S' || userInfo.infoType === 'W'
										? search.ClientID
										: userInfo.clientID,
								json: [...data],
							}
							console.log(request)
							apiSMSSlvPrvSave(request)
								.then((res) => {
									console.log(res)
									alertModal(res.result, 'success', () => {
										const request = {
											ClientID: search.ClientID,
											MstID: selectionMstModel[0],
										}
										apiSMSSlvPrvList(request)
											.then((res) => setSlvRows(res))
											.finally(() => {
												setUploadOpen(false)
												setFileObject(null)
												setExcelMstID('0')
											})
									})
								})
								.finally(() => {
									setGridSlvLoading(false)
								})
						},
						() => {}
					)
				}
			}
			reader.readAsArrayBuffer(file)
		}
	}

	// 첨부파일 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0]) {
			console.log(acceptedFiles[0])
			setFileObject(acceptedFiles[0])
		}
	}, [])

	// 첨부파일 handler
	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop: onDrop,
			accept: {
				'application/vnd.ms-excel': ['.xls', '.xlsx'],
			},
			multiple: false,
		})

	// slv grid에서 선택한 데이터는 /admin/message/sendMessage 페이지로 전달 및 이동
	const sendDataToMessagePage = () => {
		const valuesArrays = valuesInIterator(slvRef.current.getSelectedRows())
		if (valuesArrays.length === 0) {
			alertModal('보낼 고객을 선택하세요.', 'error', () => {})
			return
		}
		console.log(valuesArrays)
		navigate('/admin/message/sendMessage', {
			state: {
				prvSelectedList: valuesArrays,
			},
		})
	}

	const MstGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div />
				<ButtonGroup>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionMstAdd}
					>
						추가
					</Button>
					<Button
						variant="contained"
						color="warning"
						size="small"
						startIcon={<SaveAsTwoTone />}
						onClick={actionMstDel}
						disabled={selectionMstModel.length === 0 || slvSelectionModel.length > 0}
					>
						삭제
					</Button>
					<Button
						variant="contained"
						color="success"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionMstSave}
						disabled={selectionMstModel.length === 0}
					>
						저장
					</Button>
				</ButtonGroup>
			</GridToolbarContainer>
		)
	}

	const SlvGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div>
					<GridToolbarColumnsButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarFilterButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarDensitySelector
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarExport />
				</div>
				<ButtonGroup>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionAdd}
						disabled={selectedMstID === 0}
					>
						추가
					</Button>
					<Button
						variant="contained"
						color="warning"
						size="small"
						startIcon={<SaveAsTwoTone />}
						onClick={actionSlvDelete}
						disabled={selectionSlvModel.length === 0}
					>
						삭제
					</Button>
					<Button
						variant="contained"
						color="success"
						size="small"
						startIcon={<SaveAsTwoTone />}
						onClick={actionSlvSave}
						disabled={selectedMstID === 0 || selectionSlvModel.length === 0}
					>
						저장
					</Button>
				</ButtonGroup>
			</GridToolbarContainer>
		)
	}

	useEffect(() => {
		if (!userInfo.isSmsActive) {
			alertModal('권한이 없습니다', 'error', () => navigate('/admin/test/result'))
		}
		if (userInfo?.infoType === 'C') {
			const request = {
				ClientID: userInfo.clientID,
			}
			apiClientSmsMstList(request).then((res) => {
				console.log(res)
				const result = res[0]
				console.log(result.IsActive)
				setIsSmsUser(result.IsActive === 1 ? true : false)
				actionSearch()
			})
		}
	}, [])

	return (
		<>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Dialog
				open={uploadOpen}
				onClose={setCloseDialog}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							엑셀 업로드(고객등록)
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={setCloseDialog}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Table size="small">
						<colgroup>
							<col width="100" />
							<col width="*" />
						</colgroup>
						<TableBody>
							<TableRow>
								<TableCell>고객그룹</TableCell>
								<TableCell>
									<TextField
										label="고객그룹"
										id="filled-select-currency"
										select
										fullWidth
										defaultValue="0"
										onChange={(e) => {
											setExcelMstID(e.target.value)
										}}
										sx={{ mb: 1 }}
									>
										<MenuItem value="0">선택하세요.</MenuItem>
										{clientList.map((option) => (
											<MenuItem
												key={option.MstID}
												value={option.MstID}
											>
												{option.GroupName}
											</MenuItem>
										))}
									</TextField>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>파일업로드</TableCell>
								<TableCell>
									{fileInfo ? (
										<>
											<div
												className="dropzone dz-clickable dz-started"
												{...getRootProps()}
											>
												<input {...getInputProps()} />
												{!isDragActive && (
													<Card
														sx={{
															backgroundColor:
																theme.palette.mode === 'dark' ? '#2C3A47' : '#2C3A47',
														}}
													>
														<CardContent sx={{ paddingBottom: '16px !important' }}>
															<Typography
																color="#fff"
																variant="body1"
																component="p"
																sx={{ marginBottom: '0' }}
															>
																<AttachFileIcon
																	fontSize="small"
																	sx={{ marginBottom: '0' }}
																/>{' '}
																{fileObject?.name ? fileObject.name : '파일을 선택하세요.'}
															</Typography>
														</CardContent>
													</Card>
												)}
												{isDragReject && (
													<p className="text-warning">지원하지 않는 파일입니다.</p>
												)}
											</div>
										</>
									) : (
										<Typography
											variant="body2"
											color="text.secondary"
										>
											업로드 가능한 파일양식이 없습니다. 관리자에게 문의하세요.
										</Typography>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					<ExampleBox>
						<img
							src={Img1}
							alt=""
							className="exampleImg"
						/>

						<Alert
							severity="success"
							sx={{ mb: 1 }}
						>
							<AlertTitle>참고하세요.</AlertTitle>
							<Typography
								variant="body2"
								color="text.secondary"
							>
								- 엑셀샘플에 맞추어 데이터를 입력한 다음 해당 파일을 업로드하시기
								바랍니다.
								<br />- 성명, 핸드폰은 필수 항목입니다.
							</Typography>
						</Alert>
						<Alert severity="info">
							<AlertTitle>엑셀 양식</AlertTitle>
							엑셀 양식은
							<a
								href="/sms_template.xlsx"
								style={{ color: 'red' }}
							>
								여기
							</a>
							를 눌러 다운로드 받으세요.
						</Alert>
					</ExampleBox>
				</DialogContent>
				<Divider />
				<DialogActions>
					{tmpDataList.length > 0 && tmpIsDuplicate ? (
						<Button
							variant="contained"
							color="success"
						>
							확인
						</Button>
					) : (
						<Button
							variant="contained"
							color="success"
							onClick={actionExcelUpload}
						>
							업로드
						</Button>
					)}
					<Button
						variant="contained"
						color="secondary"
						onClick={setCloseDialog}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								개인 주소록
							</TitleText>
						</Breadcrumbs>
						<BtnBox />
					</PageBg2>
				</PageWrap>
			</Stack>
			<Paper
				elevation={0}
				sx={{
					mt: 2,
				}}
			>
				<Grid
					container
					sx={{ pt: 2, px: 2 }}
				>
					<Grid
						item
						xs={12}
					>
						<Alert severity="info">
							메세지함(주소록,SMS메세지함,쪽지함) 서비스는 병원의
							아이디(마스터,서브)마다 개인적으로 사용할수 있는 공간입니다.
							<br />
							병원고객관리 및 고객SMS발송은
							고객SMS관리(SMS서비스,고객그룹관리,예약문자발송)메뉴를 통해 이용하시기
							바랍니다.
						</Alert>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={0}
				>
					<Grid
						item
						md={3}
						sx={{ p: 2 }}
					>
						<Box>
							<Typography
								variant="h6"
								sx={{ lineHeight: 1, m: 0, pt: 1.5, pb: 3.3 }}
							>
								그룹 목록
							</Typography>
						</Box>
						<Box sx={{ height: 'calc(100vh - 325px)' }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									mb: 1,
								}}
							>
								<div style={{ flex: 1 }}>
									<TextField
										size="small"
										fullWidth
										label="그룹명"
										value={mstGroupName}
										onChange={(e) => {
											setMstGroupName(e.target.value)
										}}
									/>
								</div>
								<Button
									variant="contained"
									color="primary"
									disabled={mstGroupName.length === 0}
									sx={{ ml: 1 }}
									onClick={actionMstSave}
								>
									<SaveAsTwoTone sx={{ mr: 1 }} />
									추가
								</Button>
							</Box>
							{mstRows.map((item: any) => {
								return (
									<React.Fragment key={item.MstID}>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												mb: 1,
											}}
										>
											{selectedMstID === item.MstID ? (
												<FolderIcon
													color="primary"
													sx={{ mr: 1 }}
												/>
											) : (
												<FolderOpenIcon
													color="primary"
													sx={{ mr: 1 }}
												/>
											)}
											<div style={{ flex: 1 }}>
												<TextField
													size="small"
													fullWidth
													value={item.GroupName}
													ref={(el) => {
														mstRowRef.current[item.MstID] = el
													}}
													onChange={(e) => {
														const { value } = e.target
														setMstRows((prevRows) => {
															return prevRows.map((row) => {
																if (row.MstID === item.MstID) {
																	row.GroupName = value
																}
																return row
															})
														})
													}}
													onClick={(e) => onMstItemClick(item.MstID)}
												/>
											</div>
											<ButtonGroup sx={{ ml: 1 }}>
												<Button
													size="small"
													color="info"
													onClick={(e) => {
														actionMstUpdate(e, item.MstID)
													}}
												>
													수정
												</Button>
												<Button
													size="small"
													color="warning"
													onClick={(e) => actionMstDelete(e, item.MstID)}
												>
													<DeleteForeverTwoTone />
												</Button>
											</ButtonGroup>
										</Box>
									</React.Fragment>
								)
							})}
							{/* <DataGridPremium
								rows={mstRows}
								columns={[
									{
										field: 'MstID',
										headerName: 'ID',
										type: 'number',
										width: 80,
										editable: false,
									},
									{
										field: 'RowNum',
										headerName: '번호',
										type: 'number',
										width: 50,
										editable: false,
									},
									{
										field: 'GroupName',
										headerName: '그룹명',
										type: 'string',
										minWidth: 150,
										flex: 1,
										editable: true,
									},
									{
										field: 'RowCrud',
										headerName: 'CRUD',
										type: 'string',
										minWidth: 50,
										editable: false,
										headerAlign: 'center',
									},
								]}
								// checkboxSelection
								disableMultipleSelection
								getRowId={(row) => row.MstID}
								apiRef={mstRef}
								onRowClick={onMstRowClick}
								pageSize={gridMstPageSize}
								onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								pagination
								autoHeight={false}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								experimentalFeatures={{ newEditingApi: true }}
								rowModesModel={rowModesModel}
								onRowEditStart={handleRowEditStart}
								onRowEditStop={handleRowEditStop}
								onCellEditStop={onCellEditStop}
								onStateChange={onMstStateChange}
								processRowUpdate={processRowUpdate}
								onSelectionModelChange={selectChangeHandler}
								selectionModel={selectionMstModel}
								components={{
									Toolbar: MstGridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={gridMstLoading}
								density={(env.desnse as GridDensity) || 'compact'}
								initialState={{
									columns: {
										columnVisibilityModel: {
											RowCrud: false,
											MstID: false,
										},
									},
								}}
								hideFooterSelectedRowCount
								sx={{
									'& .MuiDataGrid-columnHeaderTitleContainer': {
										fontSize: '13px',
									},
									'& .MuiDataGrid-cell': {
										fontSize: '13px !important',
										borderRight:
											theme.palette.mode === 'dark'
												? '1px solid rgba(81, 81, 81, 1)'
												: '1px solid rgba(224, 224, 224, 1)',
									},
								}}
							/> */}
						</Box>
					</Grid>
					<Grid
						item
						md={9}
						sx={{ p: 2 }}
					>
						<Paper
							component="div"
							elevation={0}
							sx={{ mb: 2 }}
						>
							<Grid
								container
								spacing={0}
							>
								<Grid
									item
									xs={12}
									md={6}
									sx={{ display: 'flex', alignItems: 'center' }}
								>
									<Typography sx={{ pr: '10px' }}>선택한 고객을</Typography>
									<TextField
										id="filled-select-currency"
										select
										defaultValue="A"
										size="small"
										onChange={(e) => {
											setSelectedDropdown(e.target.value)
										}}
									>
										<MenuItem value="A">선택하세요.</MenuItem>
										{clientList.map((option) => (
											<MenuItem
												key={option.MstID}
												value={option.MstID}
											>
												{option.GroupName}
											</MenuItem>
										))}
									</TextField>
									<Typography sx={{ px: '10px' }}>로</Typography>
									<ButtonGroup
										variant="contained"
										aria-label="outlined primary button group"
										sx={{ mr: 1 }}
									>
										<Button
											variant="contained"
											color="secondary"
											startIcon={<FileCopyTwoToneIcon />}
											onClick={actionSlvCopy}
											disabled={
												selectedMstID !== 0 &&
												selectionSlvModel.length > 0 &&
												selectedDropdown !== slvSearch.MstID &&
												selectedDropdown !== 'A'
													? false
													: true
											}
										>
											복사
										</Button>
										<Button
											variant="contained"
											color="primary"
											startIcon={<FileCopyTwoToneIcon />}
											onClick={actionSlvMove}
											disabled={
												selectedMstID !== 0 &&
												selectionSlvModel.length > 0 &&
												selectedDropdown !== slvSearch.MstID &&
												selectedDropdown !== 'A'
													? false
													: true
											}
										>
											이동
										</Button>
									</ButtonGroup>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
									}}
								>
									<Button
										variant="contained"
										color="secondary"
										startIcon={<OfflineShareIcon />}
										disabled={selectionSlvModel.length === 0}
										onClick={sendDataToMessagePage}
									>
										문자 보내기
									</Button>
								</Grid>
							</Grid>
							{/* <Button
								variant="contained"
								color="info"
								startIcon={<BackupTwoToneIcon />}
								onClick={openExcelPopup}
								disabled={mstRows.length === 0}
							>
								엑셀 업로드
							</Button> */}
						</Paper>
						<Box sx={{ height: 'calc(100vh - 325px)' }}>
							<DataGridPremium
								rows={slvRows}
								columns={[
									{
										field: 'SlvID',
										headerName: 'ID',
										type: 'string',
										width: 80,
										editable: false,
										headerAlign: 'center',
										align: 'center',
									},
									{
										field: 'ChartNo',
										headerName: '차트번호',
										type: 'string',
										width: 120,
										editable: true,
										headerAlign: 'center',
										align: 'center',
										// renderCell: (params: GridRenderEditCellParams) => {
										// 	return (
										// 		<TextField
										// 			name="ChartNo"
										// 			value={params.value ? params.value : ''}
										// 			size="small"
										// 			fullWidth
										// 		/>
										// 	)
										// },
									},
									{
										field: 'PatientName',
										headerName: '성명',
										type: 'string',
										minWidth: 150,
										editable: true,
										headerAlign: 'center',
										align: 'center',
										// renderCell: (params: GridRenderEditCellParams) => {
										// 	return (
										// 		<TextField
										// 			name="PatientName"
										// 			value={params.value ? params.value : ''}
										// 			size="small"
										// 			fullWidth
										// 		/>
										// 	)
										// },
									},
									{
										field: 'CellNumber',
										headerName: '핸드폰 번호',
										type: 'string',
										minWidth: 200,
										editable: true,
										headerAlign: 'center',
										align: 'center',
										// renderCell: (params: GridRenderEditCellParams) => {
										// 	return (
										// 		<TextField
										// 			name="CellNumber"
										// 			value={params.value ? params.value : ''}
										// 			size="small"
										// 			fullWidth
										// 		/>
										// 	)
										// },
									},
									{
										field: 'Memo',
										headerName: '메모',
										type: 'string',
										minWidth: 300,
										editable: true,
										flex: 1,
										headerAlign: 'center',
										align: 'center',
										// renderCell: (params: GridRenderEditCellParams) => {
										// 	return (
										// 		<TextField
										// 			name="Memo"
										// 			value={params.value ? params.value : ''}
										// 			size="small"
										// 			fullWidth
										// 		/>
										// 	)
										// },
									},
								]}
								checkboxSelection
								disableSelectionOnClick
								getRowId={(row) => row.SlvID}
								apiRef={slvRef}
								onRowClick={onSlvRowClick}
								pageSize={gridSlvPageSize}
								onPageSizeChange={(newPageSize) => setGridSlvPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								pagination
								autoHeight={false}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								experimentalFeatures={{ newEditingApi: true }}
								rowModesModel={rowSlvModesModel}
								onRowEditStart={handleSlvRowEditStart}
								onRowEditStop={handleSlvRowEditStop}
								onCellEditStop={onSlvCellEditStop}
								onStateChange={onSlvStateChange}
								processRowUpdate={processSlvRowUpdate}
								onSelectionModelChange={selectSlvChangeHandler}
								selectionModel={selectionSlvModel}
								components={{
									Toolbar: SlvGridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={gridSlvLoading}
								initialState={{
									columns: {
										columnVisibilityModel: {
											RowCrud: false,
											SlvID: false,
										},
									},
								}}
								density={(env.desnse as GridDensity) || 'compact'}
								sx={{
									'& .MuiDataGrid-columnHeaderTitleContainer': {
										fontSize: '13px',
									},
									'& .MuiDataGrid-cell': {
										fontSize: '13px !important',
										borderRight:
											theme.palette.mode === 'dark'
												? '1px solid rgba(81, 81, 81, 1)'
												: '1px solid rgba(224, 224, 224, 1)',
									},
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default Address
