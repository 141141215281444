import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Alert,
	Grid,
	Box,
	Button,
	Switch,
	TextField,
	FormControlLabel,
	MenuItem,
	Typography,
	Dialog,
	DialogContent,
	DialogActions,
	LinearProgress,
	AlertTitle,
	InputAdornment,
	ButtonGroup,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	IconButton,
	Breadcrumbs,
	Checkbox,
	AppBar,
	Toolbar,
	Fab,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
	GridRowsProp,
	GridRowModesModel,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
	GridRenderEditCellParams,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import DeleteIcon from '@mui/icons-material/Delete'
import SmsIcon from '@mui/icons-material/Sms'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import CloseIcon from '@mui/icons-material/Close'

import moment from 'moment'
import 'moment/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'

import {
	alertModal,
	autoCellPhone,
	confirmAlert,
	hexToRgba,
	validCellPhone,
} from 'util/util'
import {
	apiClientSmsMstList,
	apiSMSSlvList,
	apiSMSGroupList,
	apiSmsSend,
	apiSMSSlvSave,
	apiSmsTemplateMstList,
	apiSmsTemplateSlvList,
	apiTemplateGroupList,
	apiSmsCount,
	apiCustomMessageSave,
} from 'api/api'
import { useNavigate, useLocation } from 'react-router-dom'
import TransitionEffect from 'components/TransitionEffect'
import {
	SmsInfoType,
	SmsMessageFormTypes,
	SmsSlavePersonTypes,
} from 'constants/types'
import { valuesInIterator } from 'util/objectUtil'
import React from 'react'
import PhoneImg from 'assets/img/phone.png'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle'

const locales = ['ko'] as const

const ContentWrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	height: 'calc(100vh - 75px)',
}))

const PhoneWrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '62px',
	left: '0',
	bottom: '15px',
	width: '380px',
	minHeight: '600px',
	'& > .pageBg': {
		height: '100%',
		overflowY: 'auto',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: 0,
	},
}))

const DestWrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '62px',
	left: '390px',
	bottom: '15px',
	width: '380px',
	minHeight: '600px',
	'& > .pageBg': {
		height: '100%',
		overflowY: 'auto',
	},
}))

const InfoWrapper = styled('div')(({ theme }) => ({
	paddingLeft: '780px',
}))

const PhoneContainer = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '350px',
	height: '750px',
	background: `url(${PhoneImg}) no-repeat 0 0`,
	backgroundSize: '100%',
	padding: '30px 10px 0 8px',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	overflowX: 'hidden',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '0.5rem 1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const hourList = Array.from(Array(24).keys()).map((item) => {
	return item * 1 < 10 ? `0${item * 1}` : `${item * 1}`
})

// minList 매 5분 간격으로 생성
const minList = Array.from(Array(12).keys()).map((item) => {
	return item * 5 < 10 ? `0${item * 5}` : `${item * 5}`
})

const SendMessage = () => {
	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const navigate = useNavigate()
	const location = useLocation()

	const theme = useTheme()
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	// grid columns
	/**
	 * 미결과 항목 결과 컬럼
	 */
	const columns: GridColDef[] = [
		{
			field: 'RowNum',
			headerName: '번호',
			type: 'number',
			width: 70,
			editable: false,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'GroupName',
			headerName: '그룹명',
			type: 'string',
			minWidth: 150,
			editable: false,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'ChartNo',
			headerName: '차트번호',
			type: 'string',
			width: 120,
			editable: false,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'PatientName',
			headerName: '성명',
			type: 'string',
			width: 150,
			editable: false,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'CellNumber',
			headerName: '핸드폰 번호',
			type: 'string',
			width: 180,
			editable: false,
			headerAlign: 'center',
			align: 'center',
		},
	]

	// grid state
	const mstRef = useGridApiRef()
	const [mstRows, setMstRows] = useState<GridRowModel[]>([])
	const [selectionMstModel, setSelectionMstModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	const onRowClick = (e: GridRowModel) => {}

	// 조회조건
	const [search, setSearch] = useState({
		ClientID: userInfo.clientID,
		ChartNo: '',
		PatientName: '',
		MstID: 0,
		SearchType: '0',
	})

	const actionSearch = () => {
		console.log(userInfo)
		console.log(search)
		const request = {
			...search,
			Keyword: search.PatientName,
		}
		console.log(request)
		apiSMSSlvList(request).then((res) => {
			setMstRows(res)
		})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	// 발송대상자 조건
	const [sendCondition, setSendCondition] = useState({
		date: moment().format('YYYY-MM-DD'),
	})

	// 고객그룹 목록
	const [groupList, setGroupList] = useState([])

	// 그룹팝업
	const [groupPopupIsOpen, setGroupPopupIsOpen] = useState(false)
	const [gropupPopupMstID, setGropupPopupMstID] = useState(0)

	const groupPopupClose = () => {
		setGroupPopupIsOpen(false)
		setGropupPopupMstID(0)
	}

	const onGroupPopupSubmit = () => {
		if (gropupPopupMstID > 0) {
			setGroupPopupIsOpen(false)
			const request = {
				ClientID: userInfo.clientID,
				MstID: gropupPopupMstID,
				SearchType: '0',
			}
			apiSMSSlvList(request).then((res) => {
				console.log(res)
				if (res.length === 0) {
					alertModal('그룹에 등록된 고객이 없습니다.', 'error', () => {})
				}
				// concat messageTargetList and res
				const newList = messageTargetList.concat(res)
				// remove duplicate SlvID from new List
				const uniqueList = newList.filter(
					(v: any, i: any, a: any) =>
						a.findIndex((t: any) => t.SlvID === v.SlvID) === i
				)
				setMessageTargetList(uniqueList)
			})
		} else {
			alertModal('그룹을 선택해주세요.', 'error', () => {})
		}
	}

	// 전송대상
	const [person, setPerson] = useState<SmsSlavePersonTypes>({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		GroupName: '',
		MstID: 0,
		SlvID: 0,
		RowCrud: 'C',
		RowNum: 0,
		IsNew: true,
	})
	const [messageTargetList, setMessageTargetList] = useState<
		SmsSlavePersonTypes[]
	>([])

	const removeFromMessageTargetList = (slvID: number) => {
		const newList = messageTargetList.filter((item: any) => item.SlvID !== slvID)
		setMessageTargetList(newList)
		setMessageForm({
			...messageForm,
			PatientList: newList,
		})
	}

	const personOnKeyUp = (e: any) => {
		if (e.keyCode === 13) actionAddToMessageTargetList()
	}

	// 전송 대상자 개별 추가
	const actionAddToMessageTargetList = () => {
		if (person.PatientName?.trim() === '') {
			alertModal('성명을 입력해주세요.', 'error', () => {})
			return
		}
		if (person.CellNumber?.trim() === '') {
			alertModal('핸드폰 번호를 입력해주세요.', 'error', () => {})
			return
		}
		if (validCellPhone(person.CellNumber) === false) {
			alertModal('핸드폰 번호를 정확히 입력해주세요.', 'error', () => {})
			return
		}
		if (!person.CellNumber.includes('-')) {
			alertModal('핸드폰 번호를 정확히 입력해주세요.', 'error', () => {})
		}
		const personRow = {
			...person,
			RowNum: messageTargetList.length + 1,
			MstID: 0,
			SlvID: Math.floor(Math.random() * 1000000000),
		}
		setMessageTargetList([...messageTargetList, personRow])
		setPerson({
			ChartNo: '',
			PatientName: '',
			CellNumber: '',
			GroupName: '',
			MstID: 0,
			SlvID: Math.floor(Math.random() * 1000000000),
			RowCrud: 'C',
			RowNum: 0,
			IsNew: true,
		})
		setMessageForm({
			...messageForm,
			PatientList: [...messageTargetList, personRow],
		})
	}

	// mst grid의 발송대상 추가
	const actionMstAddToMessageTargetList = () => {
		const selectedRows = valuesInIterator(mstRef.current?.getSelectedRows())
		const datas: SmsSlavePersonTypes[] = []
		selectedRows.forEach((item: any) => {
			const data = {
				ChartNo: item.ChartNo,
				PatientName: item.PatientName,
				CellNumber: item.CellNumber,
				GroupName: item.GroupName,
				MstID: item.MstID,
				SlvID: item.SlvID,
				RowCrud: item.RowCrud,
				RowNum: item.RowNum,
				IsNew: false,
			}
			datas.push(data)
		})
		if (datas) {
			const newList = messageTargetList.concat(datas)
			// remove duplicate SlvID from new List
			const uniqueList = newList.filter(
				(v: any, i: any, a: any) =>
					a.findIndex((t: any) => t.SlvID === v.SlvID) === i
			)
			setMessageTargetList(uniqueList)
			setMessageForm({
				...messageForm,
				PatientList: [...messageTargetList, ...datas],
			})
		} else {
			alertModal('고객을 선택해주세요.', 'error', () => {})
		}
	}

	// 메시지
	const [messageForm, setMessageForm] = useState<SmsMessageFormTypes>({
		Message: '',
		PatientList: [],
		SendDate: moment().format('YYYY-MM-DD'),
		SendHour: '00',
		SendMinute: '00',
		IsReserved: false,
		SMSNumber: '',
	})

	const [isSending, setIsSending] = useState(false)
	// 메시지 전송
	const actionSendMessage = () => {
		console.log(messageForm)
		if (smsInfo.IsActive && smsInfo.IsNumberActive) {
			if (messageForm.Message.length === 0) {
				alertModal('메시지를 입력해주세요.', 'error', () => {})
				return
			}
			if (messageForm.PatientList.length === 0) {
				alertModal('발송대상을 추가해주세요.', 'error', () => {})
				return
			}
			if (messageForm.IsReserved) {
				if (messageForm.SendDate === '') {
					alertModal('발송일자를 선택해주세요.', 'error', () => {})
					return
				}
				if (messageForm.SendHour === '') {
					alertModal('발송시간을 선택해주세요.', 'error', () => {})
					return
				}
				if (messageForm.SendMinute === '') {
					alertModal('발송시간을 선택해주세요.', 'error', () => {})
					return
				}
			}

			if (
				smsSummery.Total + messageForm.PatientList.length >
				smsSummery.SmsCount
			) {
				alertModal(
					`발송건이 당월 약정 ${smsSummery.SmsCount}건을 초과합니다.`,
					'error',
					() => {}
				)
				return
			}

			confirmAlert(
				'문자를 발송하시겠습니까?',
				() => {
					setIsSending(true)
					apiSmsSend(messageForm)
						.then((res) => {
							console.log(res)
							alertModal(res.result, 'success', () => {
								// 발송 후 고객 등록 요청이 있을 경우
								if (addToGroup) {
									// count messageTargetList by IsNew
									const count = messageTargetList.filter(
										(item: any) => item.IsNew
									).length

									if (count > 0) {
										// filter messageTargetList by IsNew
										const newList = messageTargetList.filter((item: any) => item.IsNew)
										setSlvRows(newList)
										setRegisterPopupIsOpen(true)
									}
								}

								setMessageForm({
									Message: '',
									PatientList: [],
									SendDate: moment().format('YYYY-MM-DD'),
									SendHour: '00',
									SendMinute: '00',
									IsReserved: false,
									SMSNumber: '',
								})
								setMessageTargetList([])
								// mst grid uncheck
								mstRef.current?.setSelectionModel([])
								setAddToGroup(false)
							})
						})
						.finally(() => {
							setIsSending(false)
						})
				},
				() => {}
			)
		} else {
			alertModal('설정된 SMS 서비스가 없습니다.', 'error', () => {})
		}
	}

	const MstGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div>
					<GridToolbarColumnsButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarFilterButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarDensitySelector
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarExport />
				</div>
				<ButtonGroup>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionMstAddToMessageTargetList}
					>
						발송대상추가
					</Button>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={() => setGroupPopupIsOpen(true)}
					>
						그룹단위추가
					</Button>
				</ButtonGroup>
			</GridToolbarContainer>
		)
	}

	const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
		MstID: 0,
		ClientID: userInfo.clientID,
		IsActive: false,
		ClientName: '',
		IsNumberActive: false,
		SMSNumber: '',
	})

	const [smsSummery, setSmsSummery] = useState({
		Fail: 0,
		Reservation: 0,
		SmsCount: 0,
		Success: 0,
		Total: 0,
	})
	const smsCount = () => {
		apiSmsCount().then((res) => setSmsSummery(res))
	}

	useEffect(() => {
		if (!userInfo.isSmsActive) {
			alertModal('권한이 없습니다', 'error', () => navigate('/admin/test/result'))
		}
		const request = {
			ClientID: userInfo.clientID,
		}
		apiClientSmsMstList(request).then((res) => {
			console.log(res)
			setSmsInfo(res[0])
			setMessageForm({
				...messageForm,
				SMSNumber: res[0].SMSNumber,
			})
			if (res[0].IsActive && res[0].IsNumberActive) {
				apiSMSGroupList(request).then((res) => setGroupList(res))
				actionSearch()

				// 주소록에서 선택한 고객이 있을 경우 (state로 넘어옴)
				console.log(location.state)
				if (location?.state) {
					const state = location.state as any
					console.log(state.prvSelectedList)
					setMessageTargetList(state.prvSelectedList)
					setMessageForm({
						...messageForm,
						PatientList: [...state.prvSelectedList],
					})
				}
				smsCount()
			} else {
				alertModal('설정된 SMS 서비스가 없습니다.', 'error', () => {
					navigate('/admin/test/result')
				})
			}
		})
	}, [])

	// 고객 그룹 등록
	const slvRef = useGridApiRef()
	const [slvRows, setSlvRows] = useState<GridRowModel[]>([])
	const [selectionSlvModel, setSelectionSlvModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridSlvLoading, setGridSlvLoading] = useState(false)
	const [gridSlvPageSize, setGridSlvPageSize] = useState(env.row ? env.row : 100)

	const onSlvRowClick = (e: GridRowModel) => {}

	const [rowSlvModesModel, setRowSlvModesModel] = useState<GridRowModesModel>({})
	const [slvSelectionModel, setSlvSelectionModel] = useState<GridSelectionModel>(
		[]
	)
	const [editSlvRows, setSlvEditRows] = useState<GridRowsProp>([])

	const [registerPopupIsOpen, setRegisterPopupIsOpen] = useState(false)
	const registerPopupClose = () => {
		setRegisterPopupIsOpen(false)
		setSlvRows([])
		// mst grid uncheck
		mstRef.current?.setSelectionModel([])
	}

	const processSlvRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const selectSlvChangeHandler = (rows: GridSelectionModel) => {
		setSelectionSlvModel((prevState) => [...rows])
	}
	const handleSlvRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleSlvRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onSlvCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setSlvEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const onSlvStateChange = () => {}

	const registerAction = () => {
		// 메시지 전송 후 수동으로 등록한 전송 대상자에서 그룹 등록이 있을 경우의 처리
		let MstIDZeroCheck = false
		slvRows.forEach((item: any) => {
			if (item.MstID === 0) MstIDZeroCheck = true
		})
		if (MstIDZeroCheck) {
			alertModal('그룹을 선택해주세요.', 'error', () => {})
			return
		}
		console.log(slvRows)
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				setGridSlvLoading(true)
				const request = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...slvRows],
				}
				apiSMSSlvSave(request)
					.then((res) => {
						console.log(res)
						alertModal(res.result, 'success', () => {
							registerPopupClose()
						})
					})
					.finally(() => {
						setGridSlvLoading(false)
					})
			},
			() => {}
		)
	}

	const [templateSearch, setTemplateSearch] = useState({
		MstID: 0,
		SlvID: 0,
		ClientID: userInfo.clientID,
		Category: '',
	})
	const [templateMstList, setTemplateMstList] = useState([])
	const [templateSlvList, setTemplateSlvList] = useState([])
	const [templatePopupOpen, setTemplatePopupOpen] = useState(false)

	const templateRef = useGridApiRef()
	const [templateRows, setTemplateRows] = useState<GridRowModel[]>([])
	const [templateSelectedModel, setTemplateSelectedModel] =
		useState<GridSelectionModel>([])
	const [templateIsLoading, setTemplateIsLoading] = useState(false)
	const [templatePageSize, setTemplatePageSize] = useState(
		env.row ? env.row : 100
	)

	const templatePopupCose = () => {
		setTemplatePopupOpen(false)
	}
	const templatePopupOpenAction = () => {
		const request = {
			ClientID: '',
		}
		apiSmsTemplateMstList(request)
			.then((res) => {
				console.log(res)
				setTemplateMstList(res)
			})
			.finally(() => {
				setTemplatePopupOpen(true)
			})
	}

	// 문자 샘플함에서 그룹을 선택
	const onTemplateMstChange = (e: any) => {
		const { value } = e.target
		console.log(value)
		const depart: any = templateMstList.find((item: any) => item.MstID === value)

		let request = {}
		setTemplateSearch({
			...templateSearch,
			MstID: parseInt(value) || 0,
			Category: '0',
		})
		if (depart === 'CMMN') {
			// 공통 문자 샘플
			console.log(depart.DepartCode)
			request = {
				MstID: parseInt(value) || 0,
				ClientID: '',
			}
		} else {
			// 사용자 정의 문자 샘플
			request = {
				MstID: parseInt(value) || 0,
				ClientID: userInfo.clientID,
			}
		}

		apiTemplateGroupList(request).then((res) => {
			console.log(res)
			setTemplateSlvList(res)
			// 하위 그룹 전체 불러와서 그리드에 출력한다.
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				setTemplateRows(res)
			})
		})
	}

	const onTemplateSlvChange = (e: any) => {
		const { value } = e.target
		setTemplateSearch({
			...templateSearch,
			Category: value,
		})
		if (value === '0') {
			const request = {
				MstID: templateSearch.MstID || 0,
				ClientID: '',
			}
			// 하위 그룹 전체 불러와서 그리드에 출력한다.
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				setTemplateRows(res)
				setTemplateSearch({
					...templateSearch,
					Category: '0',
				})
			})
		} else {
			const request = {
				Category: value,
				MstID: templateSearch.MstID,
			}
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				setTemplateRows(res)
			})
		}
		// const request = {
		// 	Category: value,
		// 	MstID: templateSearch.MstID,
		// }
		// apiSmsTemplateSlvList(request).then((res) => {
		// 	console.log(res)
		// 	setTemplateRows(res)
		// })
	}

	// 문자 샘플함에서 선택
	const onTemplateSelectAction = (row: any) => {
		console.log(row)
		if (messageForm.Message.length > 1000) {
			return
		}
		setMessageForm({
			...messageForm,
			Message: row.Message,
		})
		templatePopupCose()
	}

	const [addToGroup, setAddToGroup] = useState(false)

	// 사용자정의 문자 로직
	const [addIsOpen, setAddIsOpen] = useState(false)

	const [customMessageForm, setCustomMessageForm] = useState({
		ClientID: userInfo.clientID,
		Message: '',
	})

	// 사용자정의 문자 저장
	const addSaveAction = () => {
		console.log(customMessageForm)

		const { Message } = customMessageForm

		if (Message.trim() === '') {
			alertModal('메시지를 입력해주세요.', 'error', () => {})
			return
		}

		const request = {
			ClientID: userInfo.clientID,
			json: [
				{
					...customMessageForm,
					RowCrud: 'C',
				},
			],
		}
		apiCustomMessageSave(request).then((res) => {
			console.log(res)
			alertModal(res.result, 'success', () => {
				setTemplatePopupOpen(false)
				setCustomMessageForm({
					ClientID: userInfo.clientID,
					Message: '',
				})
				setMessageForm({
					...messageForm,
					Message: customMessageForm.Message,
				})
				setAddIsOpen(false)
			})
		})
	}

	return (
		<>
			<Dialog
				open={templatePopupOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							문자 샘플함
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={templatePopupCose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={6}
						>
							<TextField
								fullWidth
								select
								size="small"
								label="그룹"
								defaultValue="0"
								value={templateSearch.MstID}
								onChange={(e) => {
									onTemplateMstChange(e)
								}}
							>
								<MenuItem value="0">선택하세요.</MenuItem>
								{templateMstList.map((option: any) => (
									<MenuItem
										key={option.MstID}
										value={option.MstID}
									>
										{option.DepartName}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<TextField
								fullWidth
								select
								size="small"
								label="그룹"
								defaultValue="0"
								value={templateSearch.Category}
								onChange={(e) => {
									onTemplateSlvChange(e)
								}}
							>
								<MenuItem value="0">선택하세요.</MenuItem>
								{templateSlvList.map((option: any) => (
									<MenuItem
										key={option.RowNum}
										value={option.Category}
									>
										{option.Category}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					{/* <Button
						variant="contained"
						color="primary"
						size="small"
						sx={{ my: 1 }}
						startIcon={<PlaylistAddCircleIcon />}
						onClick={() => setAddIsOpen(!addIsOpen)}
					>
						사용자정의문자 추가
					</Button>
					{addIsOpen && (
						<>
							<Alert severity="info">
								새로운 메세지를 추가하면 병원(과)의 <b>[사용자정의 문자]</b>부분에
								등록됩니다.
							</Alert>
							<Table
								size="small"
								sx={{
									my: 1,
									borderTop: '2px solid rgb(12,177,200)',
									borderBottom: '2px solid rgb(12,177,200)',
								}}
							>
								<TableBody>
									<TableRow>
										<TableCell
											sx={{
												background: 'rgb(241,248,250)',
												borderRight: '1px solid rgb(12,177,200)',
												borderLeft: '1px solid rgb(12,177,200)',
												color: 'rgb(48,126,170)',
											}}
										>
											<b>메세지 추가</b>
										</TableCell>
										<TableCell>
											<TextField
												fullWidth
												size="small"
												value={customMessageForm.Message}
												onChange={(e) => {
													setCustomMessageForm({
														...customMessageForm,
														Message: e.target.value,
													})
												}}
											/>
										</TableCell>
										<TableCell sx={{ borderRight: '1px solid rgb(12,177,200)' }}>
											<Button
												variant="contained"
												color="success"
												fullWidth
												onClick={addSaveAction}
											>
												저장
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</>
					)} */}
					<Box sx={{ height: '400px', mt: 1 }}>
						<DataGridPremium
							rows={templateRows}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'SlvID',
									headerName: 'ID',
									type: 'number',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Category',
									headerName: '구분',
									type: 'string',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Message',
									headerName: '메시지',
									type: 'string',
									width: 80,
									flex: 1,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Button',
									headerName: '선택',
									type: 'string',
									width: 80,
									editable: false,
									headerAlign: 'center',
									renderCell: (params: GridRenderEditCellParams) => {
										return (
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={(e) => {
													onTemplateSelectAction(params.row)
												}}
											>
												선택
											</Button>
										)
									},
								},
							]}
							getRowId={(row) => row.SlvID}
							apiRef={templateRef}
							pageSize={templatePageSize}
							onPageSizeChange={(newPageSize) => setTemplatePageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							selectionModel={templateSelectedModel}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={templateIsLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										RowNum: false,
									},
								},
							}}
							hideFooterSelectedRowCount
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="secondary"
						onClick={templatePopupCose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={registerPopupIsOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							고객 그룹 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={registerPopupClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Box sx={{ height: '400px' }}>
						<DataGridPremium
							rows={slvRows}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'ChartNo',
									headerName: '차트번호',
									type: 'string',
									width: 80,
									editable: true,
								},
								{
									field: 'PatientName',
									headerName: '성명',
									type: 'string',
									width: 80,
									editable: true,
								},
								{
									field: 'CellNumber',
									headerName: '전화번호',
									type: 'string',
									width: 120,
									editable: true,
								},
								{
									field: 'Memo',
									headerName: '메모',
									type: 'string',
									width: 80,
									editable: true,
								},
								{
									field: 'MstID',
									headerName: '그룹명',
									type: 'string',
									minWidth: 150,
									renderCell: (params: GridRenderEditCellParams) => {
										return (
											<TextField
												name="SmsName"
												value={params.value ? params.value : '0'}
												size="small"
												fullWidth
												select
												onChange={(e) => {
													const { value } = e.target
													const { id } = params
													const row = slvRows.find((item: any) => item.SlvID === id)
													if (row) {
														row.MstID = parseInt(value) || 0
														setSlvRows([...slvRows])
													}
												}}
											>
												<MenuItem value="0">선택하세요.</MenuItem>
												{groupList.map((option: any) => (
													<MenuItem
														key={option.MstID}
														value={option.MstID}
													>
														{option.GroupName}
													</MenuItem>
												))}
											</TextField>
										)
									},
								},
							]}
							checkboxSelection
							disableSelectionOnClick
							getRowId={(row) => row.SlvID}
							apiRef={slvRef}
							onRowClick={onSlvRowClick}
							pageSize={gridSlvPageSize}
							onPageSizeChange={(newPageSize) => setGridSlvPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							rowModesModel={rowSlvModesModel}
							onRowEditStart={handleSlvRowEditStart}
							onRowEditStop={handleSlvRowEditStop}
							onCellEditStop={onSlvCellEditStop}
							onStateChange={onSlvStateChange}
							processRowUpdate={processSlvRowUpdate}
							onSelectionModelChange={selectSlvChangeHandler}
							selectionModel={selectionSlvModel}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={gridMstLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										ClientID: false,
										RowNum: false,
									},
								},
							}}
							hideFooterSelectedRowCount
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={registerAction}
						disabled={selectionSlvModel.length === 0}
					>
						저장
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={registerPopupClose}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={groupPopupIsOpen}
				onClose={groupPopupClose}
				TransitionComponent={TransitionEffect}
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							그룹 선택
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={groupPopupClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<TextField
						label="그룹명"
						id="groupName-select-popup"
						select
						defaultValue="0"
						size="small"
						fullWidth
						onChange={(e) => setGropupPopupMstID(parseInt(e.target.value) || 0)}
					>
						<MenuItem value="0">선택하세요.</MenuItem>
						{groupList.map((option: any) => (
							<MenuItem
								key={option.MstID}
								value={option.MstID}
							>
								{option.GroupName}
							</MenuItem>
						))}
					</TextField>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={onGroupPopupSubmit}
					>
						확인
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={groupPopupClose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<ContentWrapper>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								SMS 발송
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<Alert
								severity="warning"
								sx={{ my: 0, py: 0 }}
							>
								<AlertTitle sx={{ m: 0 }}>
									발송일자와 시간을 따로 설정하시지 않을때는 즉시 발송됩니다.
								</AlertTitle>
							</Alert>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<PhoneWrapper>
					<PageBg className="pageBg">
						<PhoneContainer>
							<Typography
								variant="h6"
								component="h2"
								sx={{ px: 2, display: 'flex', alignItems: 'center', mb: '0.3rem' }}
							>
								{smsInfo?.SMSNumber && (
									<>
										<PermPhoneMsgIcon
											fontSize="small"
											sx={{ mr: 1 }}
										/>{' '}
										{smsInfo?.SMSNumber}
									</>
								)}
							</Typography>
							<div style={{ paddingLeft: '0.3rem', paddingRight: '0.3rem' }}>
								<TextField
									id="message"
									size="small"
									fullWidth
									multiline
									rows={30}
									label="메시지 내용"
									value={messageForm.Message}
									onChange={(e) => {
										if (e.target.value.length > 1000) {
											return
										}
										setMessageForm({
											...messageForm,
											Message: e.target.value,
										})
										e.target.focus()
									}}
									sx={{ backgroundColor: '#fff' }}
								/>
							</div>
							{/* <Fab
								variant="extended"
								size="small"
								color="secondary"
								sx={{ position: 'absolute', bottom: '5rem', right: '1.5rem', px: 2 }}
								onClick={templatePopupOpenAction}
							>
								<ManageSearchIcon sx={{ mr: 1 }} />
								문자샘플함
							</Fab> */}
							<Button
								variant="contained"
								color="primary"
								fullWidth
								size="large"
								startIcon={<SmsIcon />}
								sx={{
									zIndex: 101,
									position: 'absolute',
									bottom: '23px',
									left: '28px',
									width: '290px',
								}}
								onClick={actionSendMessage}
								disabled={
									messageForm.Message.length === 0 ||
									messageForm.PatientList.length === 0 ||
									smsInfo.IsNumberActive === false ||
									smsInfo.IsActive === false ||
									isSending
								}
							>
								문자보내기
							</Button>
						</PhoneContainer>
						<Divider sx={{ mt: 1 }} />
						<Alert
							severity="info"
							sx={{ my: 1 }}
						>
							{messageForm.Message.length} byte / 1000 byte
							<br />
							(한글 1000자 / 영어 1000자 이내)
						</Alert>
						<Divider />
						<Button
							variant="contained"
							color="primary"
							fullWidth
							startIcon={<ManageSearchIcon />}
							sx={{ mt: 1 }}
							onClick={templatePopupOpenAction}
						>
							문자샘플함
						</Button>
						<FormControlLabel
							sx={{ mt: 1 }}
							control={
								<Checkbox
									checked={addToGroup}
									onChange={(e) => {
										setAddToGroup(e.target.checked)
									}}
								/>
							}
							label="신규고객등록화면보기"
						/>
						<Alert
							severity="info"
							sx={{ my: 1 }}
						>
							문자발송 후, 발송대상자중 고객그룹에 등록되어 있지 않은 경우
							고객저장화면이 나타납니다. 저장을 원치 않으시면 체크를 해제해 주세요.
						</Alert>
					</PageBg>
				</PhoneWrapper>
				<DestWrapper>
					<PageBg
						className="pageBg"
						style={{
							border: '5px solid #3f8eb5',
							backgroundColor: '#f7faff',
						}}
					>
						<div>
							<Grid container>
								<Grid
									item
									xs={6}
								>
									<Typography
										variant="h6"
										component="h3"
									>
										전송 대상자
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
								>
									<Typography
										variant="h6"
										component="h3"
										sx={{ textAlign: 'right' }}
									>
										<span style={{ color: 'red' }}>{messageForm.PatientList.length}</span>
										건
									</Typography>
								</Grid>
							</Grid>
							<Divider sx={{ mt: 1 }} />
						</div>
						<div>
							<List
								dense
								sx={{
									border: '5px solid #b0cad7',
									height: '625px',
									overflowY: 'auto',
									px: '1rem',
									backgroundColor: '#fff',
									borderRadius: '5px',
								}}
							>
								{messageTargetList.map((item: any) => {
									return (
										<React.Fragment key={item.SlvID}>
											<ListItem
												disablePadding
												secondaryAction={
													<IconButton
														edge="end"
														aria-label="delete"
														color="error"
														onClick={() => {
															removeFromMessageTargetList(item.SlvID)
														}}
													>
														<DeleteIcon />
													</IconButton>
												}
											>
												{/* <Checkbox
													edge="start"
													tabIndex={-1}
													disableRipple
													value={item.SlvID}
													disabled={item.RowCrud === 'R'}
													checked={item.IsNew}
													onChange={(e) => {
														const newList = messageTargetList.map((row: any) =>
															row.SlvID === item.SlvID
																? { ...row, IsNew: e.target.checked }
																: row
														)
														setMessageTargetList(newList)
														setMessageForm({
															...messageForm,
															PatientList: newList,
														})
													}}
												/> */}
												<ListItemButton>
													<ListItemText
														primary={item.PatientName}
														secondary={`${item.GroupName} ${item.CellNumber}`}
													/>
												</ListItemButton>
											</ListItem>
											<Divider />
										</React.Fragment>
									)
								})}
							</List>
							<div>
								<Box sx={{ mb: 1 }}>
									<FormControlLabel
										control={
											<Switch
												onChange={(e) => {
													setMessageForm({
														...messageForm,
														IsReserved: e.target.checked,
													})
												}}
											/>
										}
										label="예약발송시 체크해주세요."
									/>
								</Box>
								<Divider />
								<Box
									component="form"
									noValidate
									autoComplete="off"
									onKeyUp={personOnKeyUp}
									sx={{ mt: 1 }}
								>
									<Grid
										container
										spacing={2}
									>
										<Grid
											item
											md={5}
										>
											<TextField
												label="성명"
												size="small"
												value={person.PatientName}
												fullWidth
												onChange={(e) => {
													setPerson({ ...person, PatientName: e.target.value })
													setSearch({ ...search, PatientName: e.target.value })
												}}
												sx={{ backgroundColor: '#fff' }}
											/>
										</Grid>
										<Grid
											item
											md={7}
										>
											<TextField
												label="핸드폰 번호"
												size="small"
												value={person.CellNumber}
												fullWidth
												onChange={(e) =>
													setPerson({ ...person, CellNumber: autoCellPhone(e.target.value) })
												}
												sx={{ backgroundColor: '#fff' }}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															{/* <Button
														variant="contained"
														color="primary"
														sx={{ transform: 'translateX(0.8rem)' }}
														onClick={actionAddToMessageTargetList}
													>
														<AddCircleTwoToneIcon sx={{ mr: 1 }} />
														추가
													</Button> */}
															<IconButton
																aria-label="add"
																color="primary"
																onClick={actionAddToMessageTargetList}
																sx={{ transform: 'translateX(1rem)' }}
															>
																<AddCircleTwoToneIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
									</Grid>
								</Box>
								<Divider sx={{ my: 1 }} />
							</div>
						</div>
						<div>
							<Typography
								variant="h6"
								component="h3"
								sx={{ mb: 1 }}
							>
								발송일자
							</Typography>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale={locale}
							>
								<DatePicker
									label="발송일자"
									value={messageForm.SendDate}
									mask={'____-__-__'}
									inputFormat={'YYYY-MM-DD'}
									disabled={messageForm.IsReserved === false}
									onChange={(newValue: any) => {
										setMessageForm({
											...messageForm,
											SendDate: moment(new Date(newValue)).format('YYYY-MM-DD'),
										})
									}}
									renderInput={(params) => (
										<TextField
											size="small"
											{...params}
											fullWidth
											sx={{ backgroundColor: '#fff' }}
										/>
									)}
								/>
							</LocalizationProvider>
							<Typography
								variant="h6"
								component="h3"
								sx={{ mt: 2, mb: 1 }}
							>
								발송시간
							</Typography>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									md={6}
								>
									<TextField
										id="hour"
										select
										defaultValue="00"
										size="small"
										fullWidth
										disabled={messageForm.IsReserved === false}
										onChange={(e) => {
											setMessageForm({
												...messageForm,
												SendHour: e.target.value,
											})
										}}
										sx={{ backgroundColor: '#fff' }}
									>
										{hourList.map((item) => {
											return (
												<MenuItem
													key={item}
													value={item}
												>
													{item}시
												</MenuItem>
											)
										})}
									</TextField>
								</Grid>
								<Grid
									item
									md={6}
								>
									<TextField
										id="min"
										select
										defaultValue="00"
										size="small"
										fullWidth
										disabled={messageForm.IsReserved === false}
										onChange={(e) => {
											setMessageForm({
												...messageForm,
												SendMinute: e.target.value,
											})
										}}
										sx={{ backgroundColor: '#fff' }}
									>
										{minList.map((item) => {
											return (
												<MenuItem
													key={item}
													value={item}
												>
													{item}분
												</MenuItem>
											)
										})}
									</TextField>
								</Grid>
							</Grid>
						</div>
					</PageBg>
				</DestWrapper>
				<InfoWrapper>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<PageBg>
								<Typography
									variant="h6"
									component="h3"
								>
									고객 검색
								</Typography>
								<Alert
									severity="info"
									sx={{ my: 1 }}
								>
									[고객검색]은 '고객그룹관리'를 통해 등록된 고객데이터 입니다.
								</Alert>
								<Box
									component="form"
									noValidate
									autoComplete="off"
									onKeyUp={onKeyUp}
								>
									<Grid
										container
										spacing={2}
									>
										<Grid
											item
											md={4}
										>
											<TextField
												id="groupName-select"
												select
												defaultValue="A"
												size="small"
												fullWidth
												onChange={(e) =>
													setSearch({ ...search, MstID: parseInt(e.target.value) || 0 })
												}
											>
												<MenuItem value="A">선택하세요.</MenuItem>
												{groupList.map((option: any) => (
													<MenuItem
														key={option.MstID}
														value={option.MstID}
													>
														{option.GroupName}
													</MenuItem>
												))}
											</TextField>
										</Grid>
										<Grid
											item
											md={4}
										>
											<TextField
												label="성명"
												size="small"
												value={search.PatientName}
												fullWidth
												onChange={(e) =>
													setSearch({ ...search, PatientName: e.target.value })
												}
											/>
										</Grid>
										<Grid
											item
											md={4}
											sx={{ display: 'flex' }}
										>
											<TextField
												label="차트번호"
												size="small"
												value={search.ChartNo}
												fullWidth
												onChange={(e) => setSearch({ ...search, ChartNo: e.target.value })}
												// InputProps={{
												// 	endAdornment: (
												// 		<InputAdornment position="end">
												// 			<IconButton
												// 				aria-label="add"
												// 				color="primary"
												// 				onClick={actionSearch}
												// 				sx={{ transform: 'translateX(1rem)' }}
												// 			>
												// 				<ContentPasteSearchTwoToneIcon />
												// 			</IconButton>
												// 			{/* <Button
												// 				variant="contained"
												// 				color="primary"
												// 				sx={{ transform: 'translateX(0.8rem)' }}
												// 				onClick={actionSearch}
												// 			>
												// 				<ContentPasteSearchTwoToneIcon sx={{ mr: 1 }} />
												// 				검색
												// 			</Button> */}
												// 		</InputAdornment>
												// 	),
												// }}
											/>
											<Button
												color="primary"
												onClick={actionSearch}
												variant="contained"
												size="small"
												sx={{ ml: 1 }}
											>
												검색
											</Button>
										</Grid>
									</Grid>
								</Box>
								<Box sx={{ height: 'calc(100vh - 320px)', mt: 1 }}>
									<DataGridPremium
										apiRef={mstRef}
										rows={mstRows}
										getRowId={(row) => row.SlvID}
										columns={columns}
										pageSize={gridMstPageSize}
										onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										checkboxSelection
										pagination
										autoHeight={false}
										localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
										experimentalFeatures={{ newEditingApi: true }}
										components={{
											Toolbar: MstGridToolbar,
											LoadingOverlay: LinearProgress,
										}}
										loading={gridMstLoading}
										density={(env.desnse as GridDensity) || 'compact'}
										initialState={{
											columns: {
												columnVisibilityModel: {
													RowCrud: false,
													MstID: false,
													RowNum: false,
												},
											},
										}}
										sx={{
											'& .MuiDataGrid-columnHeaderTitleContainer': {
												fontSize: '13px',
											},
											'& .MuiDataGrid-cell': {
												fontSize: '13px !important',
												borderRight:
													theme.palette.mode === 'dark'
														? '1px solid rgba(81, 81, 81, 1)'
														: '1px solid rgba(224, 224, 224, 1)',
											},
										}}
									/>
								</Box>
							</PageBg>
						</Grid>
					</Grid>
				</InfoWrapper>
			</ContentWrapper>
		</>
	)
}

export default SendMessage
