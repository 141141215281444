import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpBitEmr: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '성명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'AGE_SEX',
		headerName: '나이/성별',
		type: 'string',
		width: 90,
		editable: false,
	},
	{
		field: 'DEPT_BED',
		headerName: '과목/병실',
		type: 'string',
		width: 110,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '검사일시',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '사용코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명칭',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'INSUCD',
		headerName: '청구코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '식별번호',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'EXCELDATE',
		headerName: 'EXCEL 전환일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'SPCNO',
		headerName: '검체번호',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'SPCCD',
		headerName: '검체코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SPCNM',
		headerName: '검체명',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'BED',
		headerName: '병동',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'RSLTM',
		headerName: '서술결과',
		type: 'string',
		width: 200,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'LABYN',
		headerName: '수탁여부',
		type: 'string',
		width: 60,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SEX2',
		headerName: '성별',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'RENMARK',
		headerName: '처방메모',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
