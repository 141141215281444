import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import React from 'react'

import {
	Alert,
	Grid,
	Box,
	Button,
	Switch,
	TextField,
	FormControlLabel,
	MenuItem,
	Typography,
	Dialog,
	DialogContent,
	DialogActions,
	LinearProgress,
	Breadcrumbs,
	AlertTitle,
	Divider,
	Checkbox,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	AppBar,
	Toolbar,
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableRow,
	InputAdornment,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	GridRenderEditCellParams,
	useGridApiRef,
	GridRowModesModel,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle'

import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'

import moment from 'moment'
import 'moment/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import {
	alertModal,
	autoCellPhone,
	confirmAlert,
	hexToRgba,
	validCellPhone,
} from 'util/util'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import allLocales from '@fullcalendar/core/locales-all'

import SmsIcon from '@mui/icons-material/Sms'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'

import { read, utils } from 'xlsx'

import {
	apiClientSmsMstList,
	apiSMSSlvList,
	apiSmsSend,
	apiSmsScheduleDelete,
	apiSmsTemplateMstList,
	apiSmsTemplateSlvList,
	apiTemplateGroupList,
	apiSmsSendAndReserveList,
	apiSMSGroupList,
	apiSMSSlvSave,
	apiSmsCount,
	apiCustomMessageSave,
} from 'api/api'
import TransitionEffect from 'components/TransitionEffect'
import {
	SmsInfoType,
	SmsMessageFormTypes,
	SmsSlavePersonTypes,
} from 'constants/types'
import { valuesInIterator } from 'util/objectUtil'
import { useNavigate } from 'react-router-dom'
import PhoneImg from 'assets/img/phone.png'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'

import PublishIcon from '@mui/icons-material/Publish'
import Img1 from '../../assets/img/sms_example.png'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useDropzone } from 'react-dropzone'

const locales = ['ko'] as const

const ContentWrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	height: 'calc(100vh - 75px)',
}))

const PhoneWrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '67px',
	left: '0',
	bottom: '15px',
	width: '310px',
	minHeight: '600px',
	overflowX: 'hidden',
	'& > .pageBg': {
		height: '100%',
		overflowY: 'auto',
	},
}))

const PhoneContainer = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '278px',
	height: '460px',
	background: `url(${PhoneImg}) no-repeat center center`,
	backgroundSize: '100% 460px',
	padding: '25px 10px 0 8px',
}))

const InfoWrapper = styled('div')(({ theme }) => ({
	paddingLeft: '320px',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const hourList = Array.from(Array(24).keys()).map((item) => {
	return item * 1 < 10 ? `0${item * 1}` : `${item * 1}`
})

// minList 매 5분 간격으로 생성
const minList = Array.from(Array(12).keys()).map((item) => {
	return item * 5 < 10 ? `0${item * 5}` : `${item * 5}`
})

const Category = styled('div')(({ theme }) => ({
	display: 'flex',
	'& > .item': {
		marginRight: '1rem',
	},
}))

const ExampleBox = styled(Box)(({ theme }) => ({
	marginTop: '1rem',
	'& .exampleImg': {
		display: 'block',
		maxWidth: '100%',
		margin: '2rem auto',
	},
}))

const Dot = styled('span')(({ theme }) => ({
	width: '8px',
	height: '8px',
	display: 'inline-block',
	borderRadius: '50%',
	'&.success': {
		backgroundColor: '#0000ff',
	},
	'&.fail': {
		backgroundColor: '#ff0000',
	},
	'&.reserved': {
		backgroundColor: '#00ff00',
	},
}))

const ResvMessage = () => {
	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const navigate = useNavigate()

	const theme = useTheme()
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const actionSearch = () => {
		// startDate and EndDate between this month
		let startDate = moment().startOf('month').format('YYYY-MM-DD')
		let endDate = moment().endOf('month').format('YYYY-MM-DD')

		startDate = startDate.replace(/-/gi, '')
		endDate = endDate.replace(/-/gi, '')
		startDate = startDate + '000000'
		endDate = endDate + '235959'

		const request = {
			ClientID: userInfo.clientID,
			StartDate: startDate,
			EndDate: endDate,
		}
		apiSmsSendAndReserveList(request).then((res) => {
			const arr: any = []
			res.forEach((element: any) => {
				const row = {
					title: element.PatientName + ' ' + element.CellNumber,
					date: element.Start,
					color: element.Color,
					msgID: element.MsgID,
					sendStatus: element.Status,
				}
				arr.push(row)
			})
			setEvents(arr)
		})
	}

	// 발송대상자 조건
	const [sendCondition, setSendCondition] = useState({
		date: moment().format('YYYY-MM-DD'),
	})

	const [events, setEvents] = useState<any[]>([])

	const [templateSearch, setTemplateSearch] = useState({
		MstID: 0,
		SlvID: 0,
		ClientID: userInfo.clientID,
		Category: '',
	})
	const [templateMstList, setTemplateMstList] = useState([])
	const [templateSlvList, setTemplateSlvList] = useState([])
	const [templatePopupOpen, setTemplatePopupOpen] = useState(false)

	const templateRef = useGridApiRef()
	const [templateRows, setTemplateRows] = useState<GridRowModel[]>([])
	const [templateSelectedModel, setTemplateSelectedModel] =
		useState<GridSelectionModel>([])
	const [templateIsLoading, setTemplateIsLoading] = useState(false)
	const [templatePageSize, setTemplatePageSize] = useState(
		env.row ? env.row : 100
	)

	const templatePopupCose = () => {
		setTemplatePopupOpen(false)
	}
	// 문자 샘픟함 팝업이 열리면 그룹목록을 조회한다
	const templatePopupOpenAction = () => {
		const request = {
			ClientID: '',
		}
		apiSmsTemplateMstList(request)
			.then((res) => {
				console.log(res)
				setTemplateMstList(res)
			})
			.finally(() => {
				setTemplatePopupOpen(true)
			})
	}

	// 문자 샘플함에서 그룹을 선택
	const onTemplateMstChange = (e: any) => {
		const { value } = e.target
		console.log(value)
		const depart: any = templateMstList.find((item: any) => item.MstID === value)

		let request = {}
		setTemplateSearch({
			...templateSearch,
			MstID: parseInt(value) || 0,
			Category: '0',
		})
		if (depart === 'CMMN') {
			// 공통 문자 샘플
			console.log(depart.DepartCode)
			request = {
				MstID: parseInt(value) || 0,
				ClientID: '',
			}
		} else {
			// 사용자 정의 문자 샘플
			request = {
				MstID: parseInt(value) || 0,
				ClientID: userInfo.clientID,
			}
		}

		apiTemplateGroupList(request).then((res) => {
			console.log(res)
			setTemplateSlvList(res)
			// 하위 그룹 전체 불러와서 그리드에 출력한다.
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				setTemplateRows(res)
			})
		})
	}

	const onTemplateSlvChange = (e: any) => {
		const { value } = e.target
		setTemplateSearch({
			...templateSearch,
			Category: value,
		})
		if (value === '0') {
			const request = {
				MstID: templateSearch.MstID || 0,
				ClientID: '',
			}
			// 하위 그룹 전체 불러와서 그리드에 출력한다.
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				setTemplateRows(res)
				setTemplateSearch({
					...templateSearch,
					Category: '0',
				})
			})
		} else {
			const request = {
				Category: value,
				MstID: templateSearch.MstID,
			}
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				setTemplateRows(res)
			})
		}
	}

	// 문자 샘플함에서 선택
	const onTemplateSelectAction = (row: any) => {
		console.log(row)
		if (messageForm.Message.length > 1000) {
			return
		}
		setMessageForm({
			...messageForm,
			Message: row.Message,
		})
		templatePopupCose()
	}

	// 메시지
	const [messageForm, setMessageForm] = useState<SmsMessageFormTypes>({
		Message: '',
		PatientList: [],
		SendDate: moment().format('YYYY-MM-DD'),
		SendHour: '00',
		SendMinute: '00',
		IsReserved: false,
		SMSNumber: '',
	})

	const [isSending, setIsSending] = useState(false)
	const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
		MstID: 0,
		ClientID: userInfo.clientID,
		IsActive: false,
		ClientName: '',
		IsNumberActive: false,
		SMSNumber: '',
	})

	// 메시지 전송
	const actionSendMessage = () => {
		console.log(messageForm)
		if (smsInfo.IsActive && smsInfo.IsNumberActive) {
			if (messageForm.Message.length === 0) {
				alertModal('메시지를 입력해주세요.', 'error', () => {})
				return
			}
			if (messageForm.PatientList.length === 0) {
				alertModal('발송대상을 추가해주세요.', 'error', () => {})
				return
			}
			if (messageForm.IsReserved) {
				if (messageForm.SendDate === '') {
					alertModal('발송일자를 선택해주세요.', 'error', () => {})
					return
				}
				if (messageForm.SendHour === '') {
					alertModal('발송시간을 선택해주세요.', 'error', () => {})
					return
				}
				if (messageForm.SendMinute === '') {
					alertModal('발송시간을 선택해주세요.', 'error', () => {})
					return
				}
			}

			if (
				smsSummery.Total + messageForm.PatientList.length >
				smsSummery.SmsCount
			) {
				alertModal(
					`발송건이 당월 약정 ${smsSummery.SmsCount}건을 초과합니다.`,
					'error',
					() => {}
				)
				return
			}

			confirmAlert(
				'문자를 발송하시겠습니까?',
				() => {
					console.log(messageForm)
					setIsSending(true)
					apiSmsSend(messageForm)
						.then((res) => {
							console.log(res)
							alertModal(res.result, 'success', () => {
								if (addToGroup) {
									// 그룹에 추가
									// 다시작성할것...
									if (addToGroup) {
										// count messageTargetList by IsNew
										const count = messageTargetList.filter(
											(item: any) => item.IsNew
										).length

										if (count > 0) {
											// filter messageTargetList by IsNew
											const newList = messageTargetList.filter((item: any) => item.IsNew)
											setPersonRows(newList)
											setRegisterPopupIsOpen(true)
										}
										// mst grid uncheck
										personGridRef.current?.setSelectionModel([])
										setAddToGroup(false)
									}
								}
								setMessageTargetList([])
								setMessageForm({
									Message: '',
									PatientList: [],
									SendDate: moment().format('YYYY-MM-DD'),
									SendHour: '00',
									SendMinute: '00',
									IsReserved: false,
									SMSNumber: '',
								})
								actionSearch()
							})
						})
						.finally(() => {
							setIsSending(false)
						})
				},
				() => {}
			)
		} else {
			alertModal('설정된 SMS 서비스가 없습니다.', 'error', () => {})
		}
	}

	// 전송대상
	const [person, setPerson] = useState<SmsSlavePersonTypes>({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		GroupName: '',
		MstID: 0,
		SlvID: 0,
		RowCrud: 'C',
		RowNum: 0,
		IsNew: true,
	})
	const [messageTargetList, setMessageTargetList] = useState<
		SmsSlavePersonTypes[]
	>([])

	const removeFromMessageTargetList = (slvID: number) => {
		const newList = messageTargetList.filter((item: any) => item.SlvID !== slvID)
		setMessageTargetList(newList)
		setMessageForm({
			...messageForm,
			PatientList: newList,
		})
	}

	const personOnKeyUp = (e: any) => {
		if (e.keyCode === 13) actionAddToMessageTargetList()
	}

	// 전송 대상자 개별 추가
	const actionAddToMessageTargetList = () => {
		if (person.PatientName?.trim() === '') {
			alertModal('성명을 입력해주세요.', 'error', () => {})
			return
		}
		if (person.CellNumber?.trim() === '') {
			alertModal('핸드폰 번호를 입력해주세요.', 'error', () => {})
			return
		}
		if (validCellPhone(person.CellNumber) === false) {
			alertModal('핸드폰 번호를 정확히 입력해주세요.', 'error', () => {})
			return
		}
		if (!person.CellNumber.includes('-')) {
			alertModal('핸드폰 번호를 정확히 입력해주세요.', 'error', () => {})
		}
		const personRow = {
			...person,
			RowNum: messageTargetList.length + 1,
			MstID: 0,
			SlvID: Math.floor(Math.random() * 1000000000),
		}
		// 중복 체크
		let isDuplicate = false
		messageTargetList.forEach((item: any) => {
			if (item.CellNumber === person.CellNumber) isDuplicate = true
		})
		if (isDuplicate) {
			alertModal('이미 추가된 핸드폰 번호입니다.', 'error', () => {})
			return
		} else {
			setMessageTargetList([...messageTargetList, personRow])
			setPerson({
				ChartNo: '',
				PatientName: '',
				CellNumber: '',
				GroupName: '',
				MstID: 0,
				SlvID: Math.floor(Math.random() * 1000000000),
				RowCrud: 'C',
				RowNum: 0,
				IsNew: true,
			})
			setMessageForm({
				...messageForm,
				PatientList: [...messageTargetList, personRow],
			})
		}
	}

	const [personPopupIsOpen, setPersonPopupIsOpen] = useState(false)
	const personPopupOnClose = () => {
		setPersonPopupIsOpen(false)
	}

	const [personSearch, setPersonSearch] = useState({
		Keyword: '',
		SearchType: '0', // 0: 이름, 1: 전화번호
	})
	const personSearchAction = () => {
		const request = {
			ClientID: userInfo.clientID,
			Keyword: '',
			SearchType: '0',
		}
		apiSMSSlvList(request).then((res) => {
			console.log(res)
			setSlvRows(res)
			setPersonPopupIsOpen(true)
		})
	}

	// 고객 그룹 등록
	const slvRef = useGridApiRef()
	const [slvRows, setSlvRows] = useState<GridRowModel[]>([])
	const [selectionSlvModel, setSelectionSlvModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridSlvLoading, setGridSlvLoading] = useState(false)
	const [gridSlvPageSize, setGridSlvPageSize] = useState(env.row ? env.row : 100)

	const onSlvRowClick = (e: GridRowModel) => {}

	const [rowSlvModesModel, setRowSlvModesModel] = useState<GridRowModesModel>({})

	const selectSlvChangeHandler = (rows: GridSelectionModel) => {
		setSelectionSlvModel((prevState) => [...rows])
	}

	// 고객 목록 팝업 처리
	const personSelectAction = () => {
		const selectedRows = valuesInIterator(slvRef.current?.getSelectedRows())
		const datas: SmsSlavePersonTypes[] = []
		selectedRows.forEach((item: any) => {
			const data = {
				ChartNo: item.ChartNo,
				PatientName: item.PatientName,
				CellNumber: item.CellNumber,
				GroupName: item.GroupName,
				MstID: item.MstID,
				SlvID: item.SlvID,
				RowCrud: item.RowCrud,
				RowNum: item.RowNum,
				IsNew: false,
			}
			datas.push(data)
		})
		if (datas) {
			const newList = messageTargetList.concat(datas)
			// remove duplicate SlvID from new List
			const uniqueList = newList.filter(
				(v: any, i: any, a: any) =>
					a.findIndex((t: any) => t.SlvID === v.SlvID) === i
			)
			setMessageTargetList(uniqueList)
			setMessageForm({
				...messageForm,
				PatientList: [...messageTargetList, ...datas],
			})
			setPersonPopupIsOpen(false)
		} else {
			alertModal('고객을 선택해주세요.', 'error', () => {})
		}
	}

	const eventOnClick = (e: any) => {
		if (
			e.event?.extendedProps?.sendStatus === 'SCHEDULE' &&
			e.event?.extendedProps?.msgID
		) {
			confirmAlert(
				'발송을 취소하시겠습니까?',
				() => {
					const request = {
						MsgID: e.event?.extendedProps?.msgID,
						RowCrud: 'D',
					}
					const data = {
						json: [{ ...request }],
					}
					console.log(data)
					apiSmsScheduleDelete(data).then((res) => {
						alertModal('삭제되었습니다.', 'success', () => {})
						actionSearch()
					})
				},
				() => {}
			)
		}
	}

	const [addToGroup, setAddToGroup] = useState(false)

	// 고객그룹 목록
	const [groupList, setGroupList] = useState([])

	const [slvEditRows, setSlvEditRows] = useState<GridRowModel[]>([])

	const [gridMstLoading, setGridMstLoading] = useState(false)

	// 그룹팝업
	const [groupPopupIsOpen, setGroupPopupIsOpen] = useState(false)
	const [gropupPopupMstID, setGropupPopupMstID] = useState(0)

	const groupPopupClose = () => {
		setGroupPopupIsOpen(false)
		setGropupPopupMstID(0)
	}

	const handleSlvRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleSlvRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onSlvCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setSlvEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const processSlvRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const onSlvStateChange = () => {}

	const onGroupPopupSubmit = () => {
		if (gropupPopupMstID > 0) {
			setGroupPopupIsOpen(false)
			const request = {
				ClientID: userInfo.clientID,
				MstID: gropupPopupMstID,
			}
			apiSMSSlvList(request).then((res) => {
				console.log(res)
				if (res.length === 0) {
					alertModal('그룹에 등록된 고객이 없습니다.', 'error', () => {})
				}
				// concat messageTargetList and res
				const newList = messageTargetList.concat(res)
				// remove duplicate SlvID from new List
				const uniqueList = newList.filter(
					(v: any, i: any, a: any) =>
						a.findIndex((t: any) => t.SlvID === v.SlvID) === i
				)
				setMessageTargetList(uniqueList)
			})
		} else {
			alertModal('그룹을 선택해주세요.', 'error', () => {})
		}
	}

	const openExcelPopup = () => {
		setUploadOpen(true)
		setFileObject(null)
	}

	const setCloseDialog = () => {
		setUploadOpen(false)
		setFileObject(null)
	}

	const [uploadOpen, setUploadOpen] = useState(false)
	const [fileObject, setFileObject] = useState<File | null>(null)
	const [fileInfo, setFileInfo] = useState({
		fileName: '',
		orgFileName: '',
	})
	const [tmpIsDuplicate, setTmpIsDuplicate] = useState(false)
	const [tmpDataList, setTmpDataList] = useState<any[]>([])

	const [excelMstID, setExcelMstID] = useState('0')

	const actionExcelUpload = () => {
		const file = fileObject as File
		console.log(file)
		if (file) {
			const reader = new FileReader()
			console.log(reader)
			reader.onload = (e) => {
				const f = new Uint8Array(e.target?.result as ArrayBuffer)
				const wb = read(f)
				console.log(wb)
				const tmpData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
					raw: false,
					header: ['ChartNo', 'PatientName', 'CellNumber', 'Memo'],
				})
				// data remove first row
				const data = tmpData.slice(1)
				console.log(data)

				let isInValid = false
				data.forEach((row: any) => {
					const { ChartNo, PatientName, CellNumber } = row
					row.RowCrud = 'C'

					if (
						(ChartNo && ChartNo.trim() === '') ||
						(PatientName && PatientName.trim() === '') ||
						(CellNumber && CellNumber.trim() === '')
					)
						isInValid = true
				})
				let isCellNumberInValid = false
				data.forEach((row: any) => {
					row.MstID = excelMstID
					const cellPhoneNumber = validCellPhone(row.CellNumber)
					console.log(cellPhoneNumber, row.CellNumber)
					if (cellPhoneNumber === false) {
						isCellNumberInValid = true
					} else if (!row.CellNumber.includes('-')) {
						row.CellNumber = autoCellPhone(row.CellNumber)
					}
				})
				if (isInValid) {
					alertModal('필수 입력값이 없습니다.', 'error', () => {})
					return
				}
				if (isCellNumberInValid) {
					alertModal('핸드폰 번호가 올바르지 않습니다.', 'error', () => {})
					return
				}
				console.log(isInValid, isCellNumberInValid, data.length)
				if (!isInValid && !isCellNumberInValid && data.length > 0) {
					const targetList: SmsSlavePersonTypes[] = []
					data.forEach((row: any) => {
						const data = {
							ChartNo: row.ChartNo,
							PatientName: row.PatientName,
							CellNumber: row.CellNumber,
							GroupName: '',
							MstID: row.MstID,
							SlvID: Math.floor(Math.random() * 1000000000),
							RowCrud: row.RowCrud,
							RowNum: 0,
							IsNew: true,
						}
						// 중복 체크
						let isDuplicate = false
						messageTargetList.forEach((item: any) => {
							if (item.CellNumber === data.CellNumber) isDuplicate = true
						})
						if (!isDuplicate) targetList.push(data)
					})
					setMessageTargetList([...messageTargetList, ...targetList])
					setUploadOpen(false)
					setFileObject(null)
				}
			}
			reader.readAsArrayBuffer(file)
		}
	}

	// 첨부파일 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0]) {
			console.log(acceptedFiles[0])
			setFileObject(acceptedFiles[0])
		}
	}, [])

	// 첨부파일 handler
	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop: onDrop,
			accept: {
				'application/vnd.ms-excel': ['.xls', '.xlsx'],
			},
			multiple: false,
		})

	// grid state
	const personGridRef = useGridApiRef()
	const [personRow, setPersonRows] = useState<GridRowModel[]>([])
	const [selectionPersonModel, setSelectionPersonModel] =
		useState<GridSelectionModel>([])
	const [gridPersonLoading, setGridPersonLoading] = useState(false)
	const [gridPersonPageSize, setGridPersonPageSize] = useState(
		env.row ? env.row : 100
	)

	const [registerPopupIsOpen, setRegisterPopupIsOpen] = useState(false)
	const registerPopupClose = () => {
		setRegisterPopupIsOpen(false)
		setSlvRows([])
		// mst grid uncheck
		personGridRef.current?.setSelectionModel([])
	}

	// 고객 그룹 등록
	const registerAction = () => {
		const selectedRows = valuesInIterator(
			personGridRef.current?.getSelectedRows()
		)
		console.log(selectedRows)
		if (selectedRows.length === 0) {
			alertModal('고객을 선택해주세요.', 'error', () => {})
			return
		}
		let isPatientNameEmpty = false
		let isCellNumberInValid = false
		let isGroupEmpty = false
		selectedRows.forEach((row: any) => {
			if (row.PatientName?.trim() === '') isPatientNameEmpty = true
			if (row.CellNumber?.trim() === '') isCellNumberInValid = true
			if (!row.MstID) isGroupEmpty = true
		})
		if (isPatientNameEmpty) {
			alertModal('성명을 입력해주세요.', 'error', () => {})
			return
		}
		if (isCellNumberInValid) {
			alertModal('핸드폰 번호를 입력해주세요.', 'error', () => {})
			return
		}
		if (isGroupEmpty) {
			alertModal('그룹명을 입력해주세요.', 'error', () => {})
			return
		}
		// 중복 체크
		let isDuplicate = false
		selectedRows.forEach((row: any) => {
			messageTargetList.forEach((item: any) => {
				if (item.CellNumber === row.CellNumber) isDuplicate = true
			})
		})
		if (isDuplicate) {
			alertModal('이미 추가된 핸드폰 번호입니다.', 'error', () => {})
			return
		} else {
			confirmAlert(
				'저장하시겠습니까?',
				() => {
					setGridSlvLoading(true)
					const request = {
						ClientID: userInfo.clientID,
						json: [...selectedRows],
					}
					console.log(request)
					apiSMSSlvSave(request)
						.then((res) => {
							console.log(res)
							alertModal(res.result, 'success', () => {
								registerPopupClose()
								setPersonRows([])
								setSlvRows([])
								// mst grid uncheck
								personGridRef.current?.setSelectionModel([])
								actionSearch()
							})
						})
						.finally(() => {
							setGridSlvLoading(false)
						})
				},
				() => {}
			)
		}
	}

	const [smsSummery, setSmsSummery] = useState({
		Fail: 0,
		Reservation: 0,
		SmsCount: 0,
		Success: 0,
		Total: 0,
	})
	const smsCount = () => {
		apiSmsCount().then((res) => setSmsSummery(res))
	}

	useEffect(() => {
		if (!userInfo.isSmsActive) {
			alertModal('권한이 없습니다', 'error', () => navigate('/admin/test/result'))
		}
		const request = {
			ClientID: userInfo.clientID,
		}
		apiClientSmsMstList(request).then((res) => {
			console.log(res)
			setSmsInfo(res[0])
			setMessageForm({
				...messageForm,
				SMSNumber: res[0].SMSNumber,
			})
			if (res[0].IsActive && res[0].IsNumberActive) {
				apiSMSGroupList(request).then((res) => setGroupList(res))
				actionSearch()
			} else {
				alertModal('설정된 SMS 서비스가 없습니다.', 'error', () => {
					navigate('/admin/test/result')
				})
			}
			smsCount()
		})
	}, [])

	const [personRowModesModel, setPersonRowModesModel] =
		useState<GridRowModesModel>({})

	const handlePersonRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handlePersonRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onPersonCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setPersonRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const onPersonStateChange = () => {}

	const processPersonRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const selectPersonChangeHandler = (rows: GridSelectionModel) => {
		setSelectionPersonModel((prevState) => [...rows])
	}

	// 사용자정의 문자 로직
	const [addIsOpen, setAddIsOpen] = useState(false)

	const [customMessageForm, setCustomMessageForm] = useState({
		ClientID: userInfo.clientID,
		Message: '',
	})

	// 사용자정의 문자 저장
	const addSaveAction = () => {
		console.log(customMessageForm)

		const { Message } = customMessageForm

		if (Message.trim() === '') {
			alertModal('메시지를 입력해주세요.', 'error', () => {})
			return
		}

		const request = {
			ClientID: userInfo.clientID,
			json: [
				{
					...customMessageForm,
					RowCrud: 'C',
				},
			],
		}
		apiCustomMessageSave(request).then((res) => {
			console.log(res)
			alertModal(res.result, 'success', () => {
				setTemplatePopupOpen(false)
				setCustomMessageForm({
					ClientID: userInfo.clientID,
					Message: '',
				})
				setMessageForm({
					...messageForm,
					Message: customMessageForm.Message,
				})
				setAddIsOpen(false)
			})
		})
	}

	const searchCustomerAction = () => {
		const request = {
			ClientID: userInfo.clientID,
			Keyword: personSearch.Keyword,
			SearchType: personSearch.SearchType,
		}
		apiSMSSlvList(request).then((res) => {
			console.log(res)
			setSlvRows(res)
			setPersonPopupIsOpen(true)
		})
	}

	return (
		<>
			<Dialog
				open={registerPopupIsOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							고객 그룹 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={registerPopupClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Box sx={{ height: '400px' }}>
						<DataGridPremium
							rows={personRow}
							getRowId={(row) => row.SlvID}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'SlvID',
									headerName: 'SlvID',
									type: 'number',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'ChartNo',
									headerName: '차트번호',
									type: 'string',
									width: 80,
									editable: true,
								},
								{
									field: 'PatientName',
									headerName: '성명',
									type: 'string',
									width: 80,
									editable: true,
								},
								{
									field: 'CellNumber',
									headerName: '전화번호',
									type: 'string',
									width: 120,
									editable: true,
								},
								{
									field: 'Memo',
									headerName: '메모',
									type: 'string',
									width: 80,
									flex: 1,
									editable: true,
								},
								{
									field: 'MstID',
									headerName: '그룹명',
									type: 'string',
									minWidth: 150,
									renderCell: (params: GridRenderEditCellParams) => {
										return (
											<TextField
												name="SmsName"
												value={params.value ? params.value : '0'}
												size="small"
												fullWidth
												select
												onChange={(e) => {
													const { value } = e.target
													const { id } = params
													const row = personRow.find((item: any) => item.SlvID === id)
													if (row) {
														row.MstID = parseInt(value) || 0
														setPersonRows([...personRow])
													}
												}}
											>
												<MenuItem value="0">선택하세요.</MenuItem>
												{groupList.map((option: any) => (
													<MenuItem
														key={option.MstID}
														value={option.MstID}
													>
														{option.GroupName}
													</MenuItem>
												))}
											</TextField>
										)
									},
								},
							]}
							checkboxSelection
							apiRef={personGridRef}
							pageSize={gridPersonPageSize}
							onPageSizeChange={(newPageSize) => setGridPersonPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							rowModesModel={personRowModesModel}
							onRowEditStart={handlePersonRowEditStart}
							onRowEditStop={handlePersonRowEditStop}
							onCellEditStop={onPersonCellEditStop}
							onStateChange={onPersonStateChange}
							processRowUpdate={processPersonRowUpdate}
							onSelectionModelChange={selectPersonChangeHandler}
							selectionModel={selectionPersonModel}
							loading={gridPersonLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										ClientID: false,
										RowNum: false,
									},
								},
							}}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={registerAction}
						disabled={selectionPersonModel.length === 0}
					>
						저장
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={registerPopupClose}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={uploadOpen}
				onClose={setCloseDialog}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							엑셀 업로드(문자발송 고객 등록)
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={setCloseDialog}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Table size="small">
						<colgroup>
							<col width="100" />
							<col width="*" />
						</colgroup>
						<TableBody>
							<TableRow>
								<TableCell>파일업로드</TableCell>
								<TableCell>
									{fileInfo ? (
										<>
											<div
												className="dropzone dz-clickable dz-started"
												{...getRootProps()}
											>
												<input {...getInputProps()} />
												{!isDragActive && (
													<Card
														sx={{
															backgroundColor:
																theme.palette.mode === 'dark' ? '#2C3A47' : '#2C3A47',
														}}
													>
														<CardContent sx={{ paddingBottom: '16px !important' }}>
															<Typography
																color="#fff"
																variant="body1"
																component="p"
																sx={{ marginBottom: '0' }}
															>
																<AttachFileIcon
																	fontSize="small"
																	sx={{ marginBottom: '0' }}
																/>{' '}
																{fileObject?.name ? fileObject.name : '파일을 선택하세요.'}
															</Typography>
														</CardContent>
													</Card>
												)}
												{isDragReject && (
													<p className="text-warning">지원하지 않는 파일입니다.</p>
												)}
											</div>
										</>
									) : (
										<Typography
											variant="body2"
											color="text.secondary"
										>
											업로드 가능한 파일양식이 없습니다. 관리자에게 문의하세요.
										</Typography>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					<ExampleBox>
						<img
							src={Img1}
							alt=""
							className="exampleImg"
						/>

						<Alert
							severity="success"
							sx={{ mb: 1 }}
						>
							<AlertTitle>참고하세요.</AlertTitle>
							<Typography
								variant="body2"
								color="text.secondary"
							>
								- 엑셀샘플에 맞추어 데이터를 입력한 다음 해당 파일을 업로드하시기
								바랍니다.
								<br />- 성명, 핸드폰은 필수 항목입니다.
							</Typography>
						</Alert>
						<Alert severity="info">
							<AlertTitle>엑셀 양식</AlertTitle>
							엑셀 양식은
							<a
								href="/sms_template.xlsx"
								style={{ color: 'red' }}
							>
								여기
							</a>
							를 눌러 다운로드 받으세요.
						</Alert>
					</ExampleBox>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="success"
						onClick={actionExcelUpload}
					>
						업로드
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={setCloseDialog}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={groupPopupIsOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							고객 그룹 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={groupPopupClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Box sx={{ height: '400px' }}>
						<DataGridPremium
							rows={slvRows}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'ChartNo',
									headerName: '차트번호',
									type: 'string',
									width: 80,
									editable: true,
									headerAlign: 'center',
								},
								{
									field: 'PatientName',
									headerName: '성명',
									type: 'string',
									width: 80,
									editable: true,
									headerAlign: 'center',
								},
								{
									field: 'CellNumber',
									headerName: '전화번호',
									type: 'string',
									width: 120,
									editable: true,
									headerAlign: 'center',
								},
								{
									field: 'Memo',
									headerName: '메모',
									type: 'string',
									width: 80,
									editable: true,
									headerAlign: 'center',
								},
								{
									field: 'MstID',
									headerName: '그룹명',
									type: 'string',
									minWidth: 150,
									renderCell: (params: GridRenderEditCellParams) => {
										return (
											<TextField
												name="SmsName"
												value={params.value ? params.value : '0'}
												size="small"
												fullWidth
												select
												onChange={(e) => {
													const { value } = e.target
													const { id } = params
													const row = slvRows.find((item: any) => item.SlvID === id)
													if (row) {
														row.MstID = parseInt(value) || 0
														setSlvRows([...slvRows])
													}
												}}
											>
												<MenuItem value="0">선택하세요.</MenuItem>
												{groupList.map((option: any) => (
													<MenuItem
														key={option.MstID}
														value={option.MstID}
													>
														{option.GroupName}
													</MenuItem>
												))}
											</TextField>
										)
									},
								},
							]}
							checkboxSelection
							disableSelectionOnClick
							getRowId={(row) => row.SlvID}
							apiRef={slvRef}
							onRowClick={onSlvRowClick}
							pageSize={gridSlvPageSize}
							onPageSizeChange={(newPageSize) => setGridSlvPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							rowModesModel={rowSlvModesModel}
							onRowEditStart={handleSlvRowEditStart}
							onRowEditStop={handleSlvRowEditStop}
							onCellEditStop={onSlvCellEditStop}
							onStateChange={onSlvStateChange}
							processRowUpdate={processSlvRowUpdate}
							onSelectionModelChange={selectSlvChangeHandler}
							selectionModel={selectionSlvModel}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={gridMstLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										ClientID: false,
										RowNum: false,
									},
								},
							}}
							hideFooterSelectedRowCount
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={onGroupPopupSubmit}
					>
						확인
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={groupPopupClose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={personPopupIsOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							고객 목록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={personPopupOnClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid container>
						<Grid
							item
							xs={5}
							sx={{ pr: 0.5 }}
						>
							<TextField
								select
								fullWidth
								size="small"
								value={personSearch.SearchType}
								onChange={(e) => {
									setPersonSearch({
										...personSearch,
										SearchType: e.target.value,
									})
								}}
							>
								<MenuItem value="0">이름으로 검색</MenuItem>
								<MenuItem value="1">그룹명으로 검색</MenuItem>
							</TextField>
						</Grid>
						<Grid
							item
							xs={7}
							sx={{ pl: 0.5 }}
						>
							<TextField
								size="small"
								label="검색어"
								fullWidth
								sx={{ mb: 1 }}
								value={personSearch.Keyword}
								onChange={(e) => {
									setPersonSearch({
										...personSearch,
										Keyword: e.target.value,
									})
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position="end"
											sx={{ m: 0, transform: 'translateX(12px)' }}
										>
											<Button
												variant="contained"
												onClick={searchCustomerAction}
											>
												검색
											</Button>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>

					<Box sx={{ height: '400px', mt: 1 }}>
						<DataGridPremium
							rows={slvRows}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
									align: 'center',
								},
								{
									field: 'ChartNo',
									headerName: '차트번호',
									type: 'string',
									width: 80,
									editable: true,
									headerAlign: 'center',
									align: 'center',
								},
								{
									field: 'PatientName',
									headerName: '성명',
									type: 'string',
									width: 80,
									editable: true,
									headerAlign: 'center',
									align: 'center',
								},
								{
									field: 'CellNumber',
									headerName: '전화번호',
									type: 'string',
									width: 120,
									editable: true,
									headerAlign: 'center',
									align: 'center',
								},
								{
									field: 'GroupName',
									headerName: '그룹명',
									type: 'string',
									width: 120,
									editable: true,
									headerAlign: 'center',
									align: 'center',
								},
								{
									field: 'Memo',
									headerName: '메모',
									type: 'string',
									width: 80,
									flex: 1,
									editable: true,
									headerAlign: 'center',
									align: 'center',
								},
							]}
							checkboxSelection
							getRowId={(row) => row.SlvID}
							apiRef={slvRef}
							onRowClick={onSlvRowClick}
							pageSize={gridSlvPageSize}
							onPageSizeChange={(newPageSize) => setGridSlvPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							rowModesModel={rowSlvModesModel}
							// onRowEditStart={handleSlvRowEditStart}
							// onRowEditStop={handleSlvRowEditStop}
							// onCellEditStop={onSlvCellEditStop}
							// onStateChange={onSlvStateChange}
							// processRowUpdate={processSlvRowUpdate}
							onSelectionModelChange={selectSlvChangeHandler}
							selectionModel={selectionSlvModel}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={gridSlvLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										ClientID: false,
										RowNum: false,
									},
								},
							}}
							hideFooterSelectedRowCount
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={personSelectAction}
					>
						확인
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={personPopupOnClose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={templatePopupOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							문자 샘플함
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={templatePopupCose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={6}
						>
							<TextField
								fullWidth
								select
								size="small"
								label="그룹"
								defaultValue="0"
								value={templateSearch.MstID}
								onChange={(e) => {
									onTemplateMstChange(e)
								}}
							>
								<MenuItem value="0">선택하세요.</MenuItem>
								{templateMstList.map((option: any) => (
									<MenuItem
										key={option.MstID}
										value={option.MstID}
									>
										{option.DepartName}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<TextField
								fullWidth
								select
								size="small"
								label="그룹"
								defaultValue="0"
								value={templateSearch.Category}
								onChange={(e) => {
									onTemplateSlvChange(e)
								}}
							>
								<MenuItem value="0">전체</MenuItem>
								{templateSlvList.map((option: any) => (
									<MenuItem
										key={option.RowNum}
										value={option.Category}
									>
										{option.Category}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					<Button
						variant="contained"
						color="primary"
						size="small"
						sx={{ my: 1 }}
						startIcon={<PlaylistAddCircleIcon />}
						onClick={() => setAddIsOpen(!addIsOpen)}
					>
						사용자정의문자 추가
					</Button>
					{addIsOpen && (
						<>
							<Alert severity="info">
								새로운 메세지를 추가하면 병원(과)의 <b>[사용자정의 문자]</b>부분에
								등록됩니다.
							</Alert>
							<Table
								size="small"
								sx={{
									my: 1,
									borderTop: '2px solid rgb(12,177,200)',
									borderBottom: '2px solid rgb(12,177,200)',
								}}
							>
								<TableBody>
									<TableRow>
										<TableCell
											sx={{
												background: 'rgb(241,248,250)',
												borderRight: '1px solid rgb(12,177,200)',
												borderLeft: '1px solid rgb(12,177,200)',
												color: 'rgb(48,126,170)',
											}}
										>
											<b>메세지 추가</b>
										</TableCell>
										<TableCell>
											<TextField
												fullWidth
												size="small"
												value={customMessageForm.Message}
												onChange={(e) => {
													setCustomMessageForm({
														...customMessageForm,
														Message: e.target.value,
													})
												}}
											/>
										</TableCell>
										<TableCell sx={{ borderRight: '1px solid rgb(12,177,200)' }}>
											<Button
												variant="contained"
												color="success"
												fullWidth
												onClick={addSaveAction}
											>
												저장
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</>
					)}
					<Box sx={{ height: '400px', mt: 1 }}>
						<DataGridPremium
							rows={templateRows}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'SlvID',
									headerName: 'ID',
									type: 'number',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Category',
									headerName: '구분',
									type: 'string',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Message',
									headerName: '메시지',
									type: 'string',
									width: 80,
									flex: 1,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Button',
									headerName: '선택',
									type: 'string',
									width: 80,
									editable: false,
									headerAlign: 'center',
									renderCell: (params: GridRenderEditCellParams) => {
										return (
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={(e) => {
													onTemplateSelectAction(params.row)
												}}
											>
												선택
											</Button>
										)
									},
								},
							]}
							getRowId={(row) => row.SlvID}
							apiRef={templateRef}
							pageSize={templatePageSize}
							onPageSizeChange={(newPageSize) => setTemplatePageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							selectionModel={templateSelectedModel}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={templateIsLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										RowNum: false,
									},
								},
							}}
							hideFooterSelectedRowCount
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="secondary"
						onClick={templatePopupCose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<ContentWrapper>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								예약문자발송
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<Category>
								<div className="item">
									<Dot className="success" /> 성공
								</div>
								<div className="item">
									<Dot className="fail" /> 실폐
								</div>
								<div className="item">
									<Dot className="reserved" /> 예약
								</div>
							</Category>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<PhoneWrapper>
					<PageBg className="pageBg">
						<PhoneContainer>
							<Typography
								variant="h6"
								component="h2"
								sx={{ px: 2, display: 'flex', alignItems: 'center', mb: 1 }}
							>
								{smsInfo?.SMSNumber && (
									<>
										<PermPhoneMsgIcon
											fontSize="small"
											sx={{ mr: 1 }}
										/>{' '}
										{smsInfo?.SMSNumber}
									</>
								)}
							</Typography>
							<TextField
								id="message"
								size="small"
								fullWidth
								multiline
								rows={16}
								label="메시지 내용"
								value={messageForm.Message}
								onChange={(e) => {
									if (e.target.value.length > 1000) {
										return
									}
									setMessageForm({
										...messageForm,
										Message: e.target.value,
									})
									e.target.focus()
								}}
								sx={{ backgroundColor: '#fff' }}
							/>
							<Divider />
							<div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									startIcon={<SmsIcon />}
									sx={{ mt: 1 }}
									onClick={actionSendMessage}
									disabled={
										messageForm.Message.length === 0 ||
										messageForm.PatientList.length === 0 ||
										smsInfo.IsNumberActive === false ||
										smsInfo.IsActive === false ||
										isSending
									}
								>
									문자보내기
								</Button>
							</div>
						</PhoneContainer>
						<Divider />
						<Alert
							severity="info"
							sx={{ my: 1 }}
						>
							{messageForm.Message.length} byte / 1000 byte
							<br />
							(한글 1000자 / 영어 1000자 이내)
						</Alert>
						<Divider sx={{ mb: 1 }} />
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={6}
							>
								<Button
									variant="contained"
									color="info"
									fullWidth
									startIcon={<ManageSearchIcon />}
									onClick={personSearchAction}
								>
									고객찾기
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									startIcon={<PublishIcon />}
									onClick={openExcelPopup}
								>
									엑셀추가
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<Button
									variant="contained"
									color="success"
									fullWidth
									startIcon={<ManageSearchIcon />}
									onClick={templatePopupOpenAction}
								>
									문자샘플함
								</Button>
							</Grid>
						</Grid>
						<List
							dense
							sx={{
								border: '5px solid #b0cad7',
								height: '300px',
								overflowY: 'auto',
								px: '1rem',
								backgroundColor: '#fff',
								borderRadius: '5px',
								marginTop: '1rem',
							}}
						>
							{messageTargetList.map((item: any) => {
								return (
									<React.Fragment key={item.SlvID}>
										<ListItem
											disablePadding
											secondaryAction={
												<IconButton
													edge="end"
													aria-label="delete"
													color="error"
													onClick={() => {
														removeFromMessageTargetList(item.SlvID)
													}}
												>
													<DeleteIcon />
												</IconButton>
											}
										>
											<ListItemButton>
												<ListItemText
													primary={item.PatientName}
													secondary={`${item.GroupName} ${item.CellNumber}`}
												/>
											</ListItemButton>
										</ListItem>
										<Divider />
									</React.Fragment>
								)
							})}
						</List>
						<Divider />
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={personOnKeyUp}
							sx={{ mt: 1 }}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									md={5}
								>
									<TextField
										label="성명"
										size="small"
										value={person.PatientName}
										fullWidth
										onChange={(e) =>
											setPerson({ ...person, PatientName: e.target.value })
										}
										sx={{ backgroundColor: '#fff' }}
									/>
								</Grid>
								<Grid
									item
									md={7}
								>
									<TextField
										label="핸드폰 번호"
										size="small"
										value={person.CellNumber}
										fullWidth
										onChange={(e) =>
											setPerson({ ...person, CellNumber: autoCellPhone(e.target.value) })
										}
										sx={{ backgroundColor: '#fff' }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{/* <Button
														variant="contained"
														color="primary"
														sx={{ transform: 'translateX(0.8rem)' }}
														onClick={actionAddToMessageTargetList}
													>
														<AddCircleTwoToneIcon sx={{ mr: 1 }} />
														추가
													</Button> */}
													<IconButton
														aria-label="add"
														color="primary"
														onClick={actionAddToMessageTargetList}
														sx={{ transform: 'translateX(1rem)' }}
													>
														<AddCircleTwoToneIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
							</Grid>
						</Box>
						<Divider sx={{ my: 1 }} />
						<Box sx={{ mb: 1 }}>
							<FormControlLabel
								control={
									<Switch
										onChange={(e) => {
											setMessageForm({
												...messageForm,
												IsReserved: e.target.checked,
											})
										}}
									/>
								}
								label="예약발송시 체크해주세요."
							/>
						</Box>
						<Typography
							variant="h6"
							component="h3"
							sx={{ mt: 2, mb: 1 }}
						>
							발송일자
						</Typography>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<DatePicker
								label="발송일자"
								value={sendCondition.date}
								mask={'____-__-__'}
								inputFormat={'YYYY-MM-DD'}
								disabled={messageForm.IsReserved === false}
								onChange={(newValue: any) => {
									setMessageForm({
										...messageForm,
										SendDate: moment(new Date(newValue)).format('YYYY-MM-DD'),
									})
								}}
								renderInput={(params) => (
									<TextField
										size="small"
										{...params}
										fullWidth
									/>
								)}
							/>
						</LocalizationProvider>
						<Typography
							variant="h6"
							component="h3"
							sx={{ mt: 2, mb: 1 }}
						>
							발송시간
						</Typography>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								md={6}
							>
								<TextField
									id="hour"
									select
									defaultValue="00"
									size="small"
									fullWidth
									disabled={messageForm.IsReserved === false}
									onChange={(e) => {
										setMessageForm({
											...messageForm,
											SendHour: e.target.value,
										})
									}}
								>
									{hourList.map((item) => {
										return (
											<MenuItem
												key={item}
												value={item}
											>
												{item}시
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
							<Grid
								item
								md={6}
							>
								<TextField
									id="min"
									select
									defaultValue="00"
									size="small"
									fullWidth
									disabled={messageForm.IsReserved === false}
									onChange={(e) => {
										setMessageForm({
											...messageForm,
											SendMinute: e.target.value,
										})
									}}
								>
									{minList.map((item) => {
										return (
											<MenuItem
												key={item}
												value={item}
											>
												{item}분
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
						</Grid>
						<Divider sx={{ mt: 2 }} />
						<FormControlLabel
							control={
								<Checkbox
									checked={addToGroup}
									onChange={(e) => {
										setAddToGroup(e.target.checked)
									}}
								/>
							}
							label="신규고객등록화면보기"
						/>
					</PageBg>
				</PhoneWrapper>
				<InfoWrapper>
					<PageBg>
						<Typography
							variant="h6"
							component="h3"
							sx={{ mb: 1 }}
						>
							발송예약 현황
						</Typography>
						<Alert
							severity="warning"
							sx={{ mb: 1 }}
						>
							<AlertTitle>
								발송 예약건에 한해서 클릭 후 발송 취소가 가능합니다. 대량으로 취소해야
								할 경우 발송결과리스트 메뉴을 이용하세요.
							</AlertTitle>
						</Alert>
						<div>
							<FullCalendar
								plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
								locales={allLocales}
								locale={'ko'}
								initialView={'dayGridMonth'}
								headerToolbar={{
									start: 'today',
									center: 'title',
									end: 'prev,next',
								}}
								height={'85vh'}
								events={events}
								eventClick={(e) => eventOnClick(e)}
							/>
						</div>
					</PageBg>
				</InfoWrapper>
			</ContentWrapper>
		</>
	)
}

export default ResvMessage
