import { useMemo } from 'react'
import styled from 'styled-components'
import Slider, { Settings } from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SlideWrapper = styled.section`
	position: relative;
`

interface sliderProps {
	/** 슬라이더 아이템 요소 */
	children: React.ReactNode
	/** 커스텀 클래스 */
	class_name?: string
	/** 자동재생 (속도 설정시 number 타입으로) */
	autoplay?: boolean | number
	/** 슬라이더 속도 */
	speed?: number
	/** 반복 여부 */
	loop?: boolean
}

function Slick({
	children,
	class_name,
	autoplay = false,
	speed = 300,
	loop = false,
}: sliderProps) {
	const settings = useMemo<Settings>(
		() => ({
			dots: false,
			infinite: loop,
			arrows: true,
			speed: speed,
			slidesToShow: 1,
			autoplay: Boolean(autoplay),
			autoplaySpeed: typeof autoplay === 'boolean' ? 3000 : autoplay,
		}),
		[autoplay, loop, speed]
	)
	return (
		<SlideWrapper className={class_name}>
			<Slider {...settings}>{children}</Slider>
		</SlideWrapper>
	)
}

export default Slick
