import { memo } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { styled, useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'

import SaveAsTwoToneIcon from '@mui/icons-material/SaveAsTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'

import { useSelector } from 'react-redux'
import { selectPagePosition } from '../hooks/pagePos'
import { Box, useMediaQuery } from '@mui/material'
import { hexToRgba } from 'util/util'

const PageBg = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'sticky',
	top: '65px',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '55px',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

/**
 * breadcrumb 및 버튼을 출력하는 컴포넌트
 * @param param0
 * @returns
 */
const PagePosition = ({
	actionAdd,
	actionSave,
	actionDel,
	actionSearch,
	actionReset,
	disabledAdd,
	disabledSave,
	disabledDel,
	disabledSearch,
	disabledReset,
	hideAdd,
	hideSave,
	hideDel,
	hideReset,
	hideSearch,
}: {
	actionAdd: any
	actionSave: any
	actionDel: any
	actionSearch: any
	actionReset: any
	disabledAdd: boolean
	disabledSave: boolean
	disabledDel: boolean
	disabledSearch: boolean
	disabledReset: boolean
	hideAdd: boolean
	hideSave: boolean
	hideDel: boolean
	hideReset: boolean
	hideSearch: boolean
}) => {
	const { home, pos1, pos2 } = useSelector(selectPagePosition)
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	return (
		<PageWrap>
			<PageBg>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					[
					{/* {
            <Typography key="1" color="text.primary">
              홈
            </Typography>
          }
          ,
          {pos1?.title && (
            <Link
              underline="hover"
              key="2"
              color="inherit"
              component={RouterLink}
              to=""
            >
              {pos1.title}
            </Link>
          )}
          , */}
					{pos2?.title && (
						<TitleText
							key="3"
							color="text.primary"
							sx={{ fontWeight: '500', fontSize: 17 }}
						>
							{pos2.title}
						</TitleText>
					)}
					, ]
				</Breadcrumbs>
				<BtnBox>
					{!hideReset && (
						<span>
							<Button
								size="small"
								color="success"
								variant="contained"
								startIcon={isMdUp && <RotateLeftIcon />}
								sx={{
									marginRight: '0.5rem',
									padding: isMdUp ? '4px 10px' : '4px 6px',
									minWidth: isMdUp ? '64px' : '50px',
								}}
								disabled={disabledReset}
								onClick={actionReset}
							>
								초기화
							</Button>
						</span>
					)}
					{!hideSearch && (
						<span>
							<Button
								size="small"
								color="primary"
								variant="contained"
								startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
								sx={{
									marginRight: '0.5rem',
									padding: isMdUp ? '4px 10px' : '4px 6px',
									minWidth: isMdUp ? '64px' : '50px',
								}}
								disabled={disabledSearch}
								onClick={actionSearch}
							>
								검색
							</Button>
						</span>
					)}
					{!hideAdd && (
						<span>
							<Button
								color="secondary"
								size="small"
								variant="contained"
								startIcon={isMdUp && <AddToPhotosTwoToneIcon />}
								sx={{
									marginRight: '0.5rem',
									padding: isMdUp ? '4px 10px' : '4px 6px',
									minWidth: isMdUp ? '64px' : '50px',
								}}
								onClick={actionAdd}
								disabled={disabledAdd}
							>
								추가
							</Button>
						</span>
					)}
					{!hideSave && (
						<span>
							<Button
								color="primary"
								size="small"
								variant="contained"
								startIcon={isMdUp && <SaveAsTwoToneIcon />}
								sx={{
									marginRight: '0.5rem',
									padding: isMdUp ? '4px 10px' : '4px 6px',
									minWidth: isMdUp ? '64px' : '50px',
								}}
								onClick={actionSave}
								disabled={disabledSave}
							>
								저장
							</Button>
						</span>
					)}
					{!hideDel && (
						<span>
							<Button
								color="warning"
								size="small"
								variant="contained"
								startIcon={isMdUp && <DeleteForeverTwoToneIcon />}
								sx={{
									padding: isMdUp ? '4px 10px' : '4px 6px',
									minWidth: isMdUp ? '64px' : '50px',
								}}
								onClick={actionDel}
								disabled={disabledDel}
							>
								삭제
							</Button>
						</span>
					)}
				</BtnBox>
			</PageBg>
		</PageWrap>
	)
}

export default memo(PagePosition)
