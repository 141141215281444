import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import AttachFileIcon from '@mui/icons-material/AttachFile'

import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	Typography,
	LinearProgress,
	Card,
	CardContent,
	Dialog,
	useMediaQuery,
	useTheme,
	AppBar,
	DialogContent,
	IconButton,
	Toolbar,
	ButtonGroup,
	Breadcrumbs,
	AlertTitle,
	Table,
	TableRow,
	TableBody,
	TableCell,
	Alert,
	Divider,
	DialogActions,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridRowModel,
	useGridApiRef,
	GridSelectionModel,
	GridDensity,
	GridCellEditCommitParams,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridCellEditStopParams,
	GridEventListener,
	GridRowModesModel,
	GridRowParams,
	GridRowsProp,
	MuiEvent,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'

import { useDropzone } from 'react-dropzone'
import {
	fileDownloadByFileId,
	apiSmSMonthSelect,
	apiSmsMonthSave,
} from 'api/api'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert, alertModal, hexToRgba } from 'util/util'
import { getSettings } from 'helpers/storage'
import { valuesInIterator } from 'util/objectUtil'
import SaveAsTwoTone from '@mui/icons-material/SaveAsTwoTone'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import ClientSearchPopup from 'views/test/clientSearchPopup'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { styled } from '@mui/material/styles'

import { selectuserInfo } from 'hooks/userInfo'

import {
	apiClientSmsMstList,
	apiClientSMSMstSave,
	apiClientSmsSlvList,
	apiClientSMSSlvSave,
} from 'api/api'
import DeleteForeverTwoTone from '@mui/icons-material/DeleteForeverTwoTone'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import { read, utils } from 'xlsx'
import TransitionEffect from 'components/TransitionEffect'

const locales = ['ko'] as const

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const ExampleBox = styled(Box)(({ theme }) => ({
	marginTop: '1rem',
	'& .exampleImg': {
		display: 'block',
		maxWidth: '100%',
		margin: '2rem auto',
	},
}))

// 게시물 저장시 파일의 id값만 저장
let dropzoneFiles = []
// 게시물 열람용 첨부파일 처리
let tempUploadFiles: any[] = []

type Nullable<T> = T | null

const SmsClient = () => {
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')
	// redux store
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const env = getSettings()
	const userInfo = useSelector(selectuserInfo)

	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		apiClientSmsMstList(search).then((res) => {
			console.log(res)
			setMstRows(res)
		})
	}
	const actionReset = () => {}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) {
			actionSearch()
		}
	}

	const [search, setSearch] = useState({
		ClientName: '',
		ClientID: '',
	})

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		let isExist = false
		mstRows.forEach((row) => {
			if (row.ClientID === e.ClientID) {
				isExist = true
			}
		})
		if (isExist) {
			alertModal('이미 등록된 고객사입니다.', 'warning', () => {})
			return
		}

		setClientDialogOpen(false)

		const newRow = {
			RowCrud: 'C',
			RowNum: mstRows.length + 1,
			MstID: Math.round(Math.random() * 99999999),
			ClientID: e.ClientID,
			ClientName: e.ClientName,
			IsActive: true,
		}
		setMstRows((prevState) => [newRow, ...prevState])
	}

	// grid state
	const mstRef = useGridApiRef()
	const [mstRows, setMstRows] = useState<GridRowModel[]>([])
	const [selectionMstModel, setSelectionMstModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [editRows, setEditRows] = useState<GridRowsProp>([])

	const [selectedMstRow, setSelectedMstRow] = useState<GridRowModel | null>(null)
	const [slaveButtonActive, setSlaveButtonActive] = useState(true)

	// grid row click event
	const onMstRowClick = (e: GridRowModel) => {
		const request = {
			ClientID: e.row.ClientID,
		}
		console.table(request)
		setSelectedMstRow(e)
		setSlaveButtonActive(e.row.RowCrud === 'C' ? true : false)
		apiClientSmsSlvList(request).then((res) => {
			setSlvRows(res)
		})
		setMonthRows([])
	}

	const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		setEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}

	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionMstModel((prevState) => [...rows])
	}

	const onMstStateChange = () => {}

	const actionMstAdd = () => {
		setClientDialogOpen(true)
	}
	const actionMstSave = () => {
		const json = mstRef.current.getSelectedRows()
		const valuesArray = valuesInIterator(json)
		if (valuesArray.length === 0) {
			alertModal('저장할 데이터가 없습니다.', 'warning', () => {})
			return
		}
		let CreateCount = 0
		let UpdateCount = 0
		valuesArray.forEach((row) => {
			if (row.RowCrud === 'C') {
				CreateCount++
			} else if (row.RowCrud === 'U') {
				UpdateCount++
			}
		})
		const createText = `${CreateCount > 0 ? `신규 ${CreateCount}건` : ''}`
		const updateText = `${UpdateCount > 0 ? `수정 ${UpdateCount}건` : ''}`
		const concatText = `${
			CreateCount > 0 && UpdateCount > 0
				? CreateCount + ', ' + UpdateCount
				: CreateCount + UpdateCount
		}건을 저장하시겠습니까?`

		if (CreateCount > 0 || UpdateCount > 0) {
			confirmAlert(
				concatText,
				() => {
					setGridMstLoading(true)
					const request = {
						json: [...valuesArray],
					}
					apiClientSMSMstSave(request)
						.then((res) => {
							console.log(res)
							alertModal(res.result, 'success', () => {
								actionSearch()
							})
						})
						.finally(() => {
							setGridMstLoading(false)
						})
				},
				() => {}
			)
		} else {
			alertModal('변경되거나 생성된 데이터가 없습니다.', 'warning', () => {})
		}
	}

	const actionMstDel = () => {
		if (slvRows.length > 0) {
			alertModal('하위 발신번호가 존재합니다.', 'warning', () => {})
			return
		}
		const json = mstRef.current.getSelectedRows()
		const valuesArray = valuesInIterator(json)
		console.log(valuesArray)
		valuesArray.forEach((row) => {
			if (row.RowCrud === 'C') {
				confirmAlert(
					'삭제하시겠습니까?',
					() => {
						// remove from grid
						const newRows = mstRows.filter((item) => item.MstID !== row.MstID)
						setMstRows(newRows)
					},
					() => {}
				)
			} else if (row.RowCrud === 'R' || row.RowCrud === 'U') {
				confirmAlert(
					'삭제하시겠습니까?',
					() => {
						valuesArray.forEach((row) => {
							if (row.RowCrud === 'R' || row.RowCrud === 'U') {
								row.RowCrud = 'D'
							}
						})
						setGridMstLoading(true)
						const request = {
							json: [...valuesArray],
						}
						apiClientSMSMstSave(request)
							.then((res) => {
								console.log(res)
								alertModal(res.result, 'success', () => {
									actionSearch()
								})
							})
							.finally(() => {
								setGridMstLoading(false)
							})
					},
					() => {}
				)
			}
		})
	}

	const slvRef = useGridApiRef()
	const [slvRows, setSlvRows] = useState<GridRowModel[]>([])
	const [selectionSlvModel, setSelectionSlvModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridSlvLoading, setGridSlvLoading] = useState(false)
	const [gridSlvPageSize, setGridSlvPageSize] = useState(env.row ? env.row : 100)

	const [rowSlvModesModel, setRowSlvModesModel] = useState<GridRowModesModel>({})
	const [slvSelectionModel, setSlvSelectionModel] = useState<GridSelectionModel>(
		[]
	)
	const [selectedSlvRow, setSelectedSlvRow] = useState<GridRowModel | null>(null)
	const [editSlvRows, setSlvEditRows] = useState<GridRowsProp>([])

	// grid row click event
	const onSlvRowClick = (e: GridRowModel) => {
		setSelectedSlvRow(e)
		// setMonthRows([])
		// const request = {
		// 	ClientID: e.row.ClientID,
		// 	SlvID: e.row.SlvID,
		// }
		// apiSmSMonthSelect(request).then((res) => {
		// 	console.log(res)
		// 	setMonthRows(res)
		// })
		// setSelectedMstRow(e)
		// setSlaveButtonActive(e.row.RowCrud === 'C' ? true : false)
		// apiClientSmsSlvList(request).then((res) => setSlvRows(res))
	}

	const processSlvRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const selectSlvChangeHandler = (rows: GridSelectionModel) => {
		setSelectionSlvModel((prevState) => [...rows])
	}
	const handleSlvRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleSlvRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onSlvCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setSlvEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const onSlvStateChange = () => {}

	const actionSlvAdd = () => {
		if (selectionMstModel.length === 1 && slvRows.length === 0) {
			console.log(selectedMstRow)
			if (selectedMstRow?.row?.RowCrud === 'C') {
				alertModal('고객사를 저장 후 발신번호를 등록해주세요.', 'warning', () => {})
				return
			}

			const selectedRowData = mstRef.current.getSelectedRows()
			const values = valuesInIterator(selectedRowData)
			// add row
			const newRow = {
				RowCrud: 'C',
				RowNum: slvRows.length + 1,
				SlvID: Math.round(Math.random() * 99999999),
				MstID: values[0].MstID,
				ClientID: values[0].ClientID,
				SmsName: '',
				SmsNumber: '',
				IsNumberActive: false,
			}
			setSlvRows((prevState) => [newRow, ...prevState])
		}
	}

	// 고객사 발신번호 삭제
	const actionSlvDel = () => {
		const valuesArrays = valuesInIterator(slvRef.current.getSelectedRows())
		if (!valuesArrays[0].SlvID) {
			alertModal('발신번호를 선택해주세요.', 'error', () => {})
			return
		}
		valuesArrays.forEach((row: any) => {
			row.RowCrud = 'D'
		})
		console.log(valuesArrays)
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				setGridSlvLoading(true)
				const request = {
					ClientID: valuesArrays[0].ClientID,
					json: [...valuesArrays],
				}
				apiClientSMSSlvSave(request)
					.then((res) => {
						console.log(res)
						alertModal(res.result, 'success', () => {
							// slv 목록 호출
							const request = {
								ClientID: valuesArrays[0].ClientID,
							}
							console.table(request)
							apiClientSmsSlvList(request).then((res) => {
								setSlvRows(res)
							})
							setMonthRows([])
						})
					})
					.finally(() => {
						setGridSlvLoading(false)
					})
			},
			() => {}
		)
	}

	const actionSlvSave = () => {
		const mstRow = valuesInIterator(mstRef.current.getSelectedRows())
		const valuesArrays = valuesInIterator(slvRef.current.getSelectedRows())
		if (valuesArrays.length === 0) {
			alertModal('저장할 데이터가 없습니다.', 'error', () => {})
			return
		}
		if (!mstRow[0].ClientID) {
			alertModal('고객사를 선택해주세요.', 'error', () => {})
			return
		}
		let isInValid = false
		valuesArrays.forEach((row: any) => {
			if (row.SmsName.trim() === '' || row.SmsNumber.trim() === '')
				isInValid = true
		})
		if (isInValid) {
			alertModal('발신번호 별칭, 발신번호는 필수 입력값입니다.', 'error', () => {})
			return
		}
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				setGridSlvLoading(true)
				const request = {
					ClientID: mstRow[0].ClientID,
					json: [...valuesArrays],
				}
				apiClientSMSSlvSave(request)
					.then((res) => {
						console.log(res)
						alertModal(res.result, 'success', () => {
							// slv 목록 호출
						})
					})
					.finally(() => {
						setGridSlvLoading(false)
					})
			},
			() => {}
		)
	}

	const monthRef = useGridApiRef()
	const [monthRows, setMonthRows] = useState<GridRowModel[]>([])
	const [selectionMonthModel, setSelectionMonthModel] =
		useState<GridSelectionModel>([])
	const [gridMonthLoading, setGridMonthLoading] = useState(false)
	const [gridMonthPageSize, setGridMonthPageSize] = useState(
		env.row ? env.row : 100
	)

	const onMonthRowClick = (e: GridRowModel) => {}

	const [rowMonthModesModel, setRowMonthModesModel] =
		useState<GridRowModesModel>({})
	const [monthSelectionModel, setMonthSelectionModel] =
		useState<GridSelectionModel>([])
	const [monthEditRows, setMonthEditRows] = useState<GridRowsProp>([])

	const processMonthRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const selectMonthChangeHandler = (rows: GridSelectionModel) => {
		setSelectionMonthModel((prevState) => [...rows])
	}
	const handleMonthRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleMonthRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onMonthCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setMonthEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const onMonthStateChange = () => {}

	const actionMonthAdd = () => {
		if (selectionMstModel.length === 1) {
			console.log(selectedMstRow)
			if (selectedMstRow?.row?.RowCrud === 'C') {
				alertModal('고객사를 저장 후 발신번호를 등록해주세요.', 'warning', () => {})
				return
			}
			if (selectionSlvModel.length === 0) {
				alertModal('발신번호를 선택해주세요.', 'warning', () => {})
				return
			}
			if (selectionSlvModel.length > 1) {
				alertModal('발신번호는 1개만 선택해주세요.', 'warning', () => {})
				return
			}
			// 추가된 행이 있는지 확인
			let cnt = 0
			monthRows.forEach((row) => {
				if (row.RowCrud === 'C') cnt++
			})

			if (cnt > 0) {
				alertModal(
					'추가된 행을 저장 후 다시 추가하세요.<br/>대량으로 등록할 경우 엑셀 업로드를 이용하세요.',
					'error',
					() => {}
				)
				return
			}

			const selectedRowData = slvRef.current.getSelectedRows()
			const values = valuesInIterator(selectedRowData)
			setMonthRows((prevState) => [
				{
					RowCrud: 'C',
					RowNum: Math.round(Math.random() * 99999999),
					SlvID: values[0].SlvID,
					ClientID: values[0].ClientID,
					YM: '',
					Count: '',
				},
				...prevState,
			])
		}
	}

	const actionMonthDel = () => {
		const slvRow = valuesInIterator(slvRef.current.getSelectedRows())
		if (!slvRow[0].SlvID) {
			alertModal('발신번호를 선택해주세요.', 'error', () => {})
			return
		}
		const json = monthRef.current.getSelectedRows()
		const valuesArray = valuesInIterator(json)
		console.log(valuesArray)
		valuesArray.forEach((row) => {
			if (row.RowCrud === 'C') {
				confirmAlert(
					'삭제하시겠습니까?',
					() => {
						// remove from grid
						const newRows = monthRows.filter((item) => item.RowNum !== row.RowNum)
						setMonthRows(newRows)
					},
					() => {}
				)
			} else if (row.RowCrud === 'R' || row.RowCrud === 'U') {
				confirmAlert(
					'삭제하시겠습니까?',
					() => {
						valuesArray.forEach((row) => {
							if (row.RowCrud === 'R' || row.RowCrud === 'U') {
								row.RowCrud = 'D'
							}
						})
						setGridMonthLoading(true)
						const request = {
							ClientID: slvRow[0].ClientID,
							json: [...valuesArray],
						}
						console.log(request)
						apiSmsMonthSave(request)
							.then((res) => {
								console.log(res)
								// 고객사 월별 전송 건수 설정 목록 호출
								alertModal(res.result, 'success', () => {
									const request = {
										ClientID: slvRow[0].ClientID,
										SlvID: slvRow[0].SlvID,
									}
									apiSmSMonthSelect(request).then((res) => {
										console.log(res)
										setMonthRows(res)
									})
								})
							})
							.finally(() => {
								setGridMonthLoading(false)
							})
					},
					() => {}
				)
			}
		})
	}

	const actionMonthSave = () => {
		const mstRow = valuesInIterator(monthRef.current.getSelectedRows())
		const slvRow = valuesInIterator(slvRef.current.getSelectedRows())
		const valuesArrays = valuesInIterator(monthRef.current.getSelectedRows())
		if (valuesArrays.length === 0) {
			alertModal('저장할 데이터가 없습니다.', 'error', () => {})
			return
		}
		if (!mstRow[0].ClientID) {
			alertModal('고객사를 선택해주세요.', 'error', () => {})
			return
		}
		if (!slvRow[0].SlvID) {
			alertModal('발신번호를 선택해주세요.', 'error', () => {})
			return
		}
		let isInValid = false
		valuesArrays.forEach((row: any) => {
			if (row.YM.trim() === '' || !row.Count) isInValid = true
		})
		if (isInValid) {
			alertModal('연월, 사용건수는 필수 입력값입니다.', 'error', () => {})
			return
		}
		console.log(valuesArrays)
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				setGridMonthLoading(true)
				const request = {
					ClientID: mstRow[0].ClientID,
					json: [...valuesArrays],
				}
				apiSmsMonthSave(request)
					.then((res) => {
						console.log(res)
						alertModal(res.result, 'success', () => {
							// 고객사 월별 전송 건수 설정 목록 호출
							const request = {
								ClientID: slvRow[0].ClientID,
								SlvID: slvRow[0].SlvID,
							}
							apiSmSMonthSelect(request)
								.then((res) => {
									console.log(res)
									setMonthRows(res)
								})
								.finally(() => {
									setMonthSelectionModel([])
								})
						})
					})
					.finally(() => {
						setGridMonthLoading(false)
					})
			},
			() => {}
		)
	}

	const handleCellEditCommit = (params: GridCellEditCommitParams) => {
		const { id, field, value } = params

		console.log(id, field, value)

		setMonthEditRows((prevRows: any) =>
			prevRows.map((row: any) => {
				if (row.RowNum === id) {
					return {
						...row,
						[field]: value,
					}
				}
				return row
			})
		)
	}

	const openExcelPopup = () => {
		setUploadOpen(true)
		setFileObject(null)
	}

	const setCloseDialog = () => {
		setUploadOpen(false)
		setFileObject(null)
	}

	const [uploadOpen, setUploadOpen] = useState(false)
	const [fileObject, setFileObject] = useState<File | null>(null)
	const [fileInfo, setFileInfo] = useState({
		fileName: '',
		orgFileName: '',
	})
	const [tmpIsDuplicate, setTmpIsDuplicate] = useState(false)
	const [tmpDataList, setTmpDataList] = useState<any[]>([])

	// 첨부파일 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0]) {
			console.log(acceptedFiles[0])
			setFileObject(acceptedFiles[0])
		}
	}, [])

	// 첨부파일 handler
	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop: onDrop,
			accept: {
				'application/vnd.ms-excel': ['.xls', '.xlsx'],
			},
			multiple: false,
		})

	const actionExcelUpload = () => {
		const mstRow = valuesInIterator(mstRef.current.getSelectedRows())
		const slvRow = valuesInIterator(slvRef.current.getSelectedRows())
		if (!mstRow[0].ClientID) {
			alertModal('고객사를 선택해주세요.', 'error', () => {})
			return
		}
		if (!slvRow[0].SlvID) {
			alertModal('발신번호를 선택해주세요.', 'error', () => {})
			return
		}
		const file = fileObject as File
		if (file) {
			const reader = new FileReader()
			reader.onload = (e) => {
				const f = new Uint8Array(e.target?.result as ArrayBuffer)
				const wb = read(f)
				console.log(wb)
				const tmpData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
					raw: false,
					header: ['YM', 'Count'],
				})
				// data remove first row
				let data = tmpData.slice(1)

				// 데이터 행에 undefined 값이 있으면 삭제
				data = data.filter((row: any) => {
					const { YM, Count } = row
					return YM?.trim() !== undefined && Count !== undefined
				})

				let isInValid = false
				data.forEach((row: any) => {
					const { YM, Count } = row
					row.RowCrud = 'C'
					row.SlvID = slvRow[0].SlvID
					// YM 형식이 yyyy-MM 이 아니거나 Count가 string 형식인데 int 형으로 변환이 안되면 isInValid = true
					if (!moment(YM, 'YYYY-MM').isValid() || isNaN(parseInt(Count))) {
						isInValid = true
					}
				})
				if (isInValid) {
					alertModal(
						'연월은 yyyy-MM 형식이어야 하고 사용건수는 숫자여야 합니다.',
						'error',
						() => {}
					)
					return
				}
				confirmAlert(
					'저장하시겠습니까?',
					() => {
						setGridMonthLoading(true)
						const request = {
							ClientID: mstRow[0].ClientID,
							json: [...data],
						}
						apiSmsMonthSave(request)
							.then((res) => {
								console.log(res)
								alertModal(res.result, 'success', () => {
									// 고객사 월별 전송 건수 설정 목록 호출
									const request = {
										ClientID: slvRow[0].ClientID,
										SlvID: slvRow[0].SlvID,
									}
									apiSmSMonthSelect(request)
										.then((res) => {
											console.log(res)
											setMonthRows(res)
										})
										.finally(() => {
											setUploadOpen(false)
											setFileObject(null)
											setMonthSelectionModel([])
										})
								})
							})
							.finally(() => {
								setGridMonthLoading(false)
							})
					},
					() => {}
				)
			}
			reader.readAsArrayBuffer(file)
		}
	}

	const MstGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div>
					<GridToolbarColumnsButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarFilterButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarDensitySelector
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarExport />
				</div>
				<GridToolbarQuickFilter />
			</GridToolbarContainer>
		)
	}

	const SlvGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div>
					<GridToolbarColumnsButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarFilterButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarDensitySelector
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarExport />
				</div>
				<GridToolbarQuickFilter />
			</GridToolbarContainer>
		)
	}

	useEffect(() => {
		actionSearch()
	}, [])

	return (
		<>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Dialog
				open={uploadOpen}
				onClose={setCloseDialog}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							월별 전송 건수 업로드
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={setCloseDialog}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Table size="small">
						<colgroup>
							<col width="100" />
							<col width="*" />
						</colgroup>
						<TableBody>
							<TableRow>
								<TableCell>파일업로드</TableCell>
								<TableCell>
									{fileInfo ? (
										<>
											<div
												className="dropzone dz-clickable dz-started"
												{...getRootProps()}
											>
												<input {...getInputProps()} />
												{!isDragActive && (
													<Card
														sx={{
															backgroundColor:
																theme.palette.mode === 'dark' ? '#2C3A47' : '#2C3A47',
														}}
													>
														<CardContent sx={{ paddingBottom: '16px !important' }}>
															<Typography
																color="#fff"
																variant="body1"
																component="p"
																sx={{ marginBottom: '0' }}
															>
																<AttachFileIcon
																	fontSize="small"
																	sx={{ marginBottom: '0' }}
																/>{' '}
																{fileObject?.name ? fileObject.name : '파일을 선택하세요.'}
															</Typography>
														</CardContent>
													</Card>
												)}
												{isDragReject && (
													<p className="text-warning">지원하지 않는 파일입니다.</p>
												)}
											</div>
										</>
									) : (
										<Typography
											variant="body2"
											color="text.secondary"
										>
											업로드 가능한 파일양식이 없습니다. 관리자에게 문의하세요.
										</Typography>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<ExampleBox>
						{/* <img
							src={Img1}
							alt=""
							className="exampleImg"
						/> */}
						<Alert
							severity="success"
							sx={{ mb: 1 }}
						>
							<AlertTitle>참고하세요.</AlertTitle>
							<Typography
								variant="body2"
								color="text.secondary"
							>
								- 엑셀샘플에 맞추어 데이터를 입력한 다음 해당 파일을 업로드하시기
								바랍니다.
								<br />- 연월, 사용건수는 필수 항목입니다.
							</Typography>
						</Alert>
						<Alert severity="info">
							<AlertTitle>엑셀 양식</AlertTitle>
							엑셀 양식은
							<a
								href="/month_template.xlsx"
								style={{ color: 'red' }}
							>
								여기
							</a>
							를 눌러 다운로드 받으세요.
						</Alert>
					</ExampleBox>
				</DialogContent>
				<Divider />
				<DialogActions>
					{tmpDataList.length > 0 && tmpIsDuplicate ? (
						<Button
							variant="contained"
							color="success"
						>
							확인
						</Button>
					) : (
						<Button
							variant="contained"
							color="success"
							onClick={actionExcelUpload}
						>
							업로드
						</Button>
					)}
					<Button
						variant="contained"
						color="secondary"
						onClick={setCloseDialog}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								SMS 사용 설정
							</TitleText>
						</Breadcrumbs>
						<BtnBox />
					</PageBg2>
				</PageWrap>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden', mt: 2 }}
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						md={6}
					>
						<Box sx={{ pt: 2, pr: 1, pb: 2, pl: 2 }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography
									variant="subtitle1"
									component="h3"
								>
									고객사 목록
								</Typography>
								<div>
									<ButtonGroup>
										<Button
											variant="contained"
											color="primary"
											size="small"
											startIcon={<AddCircleTwoToneIcon />}
											onClick={actionMstAdd}
										>
											추가
										</Button>
										<Button
											variant="contained"
											color="warning"
											size="small"
											startIcon={<DeleteForeverTwoTone />}
											onClick={actionMstDel}
											disabled={selectionMstModel.length === 0 || slvRows.length > 0}
										>
											삭제
										</Button>
										<Button
											variant="contained"
											color="success"
											size="small"
											startIcon={<SaveAsTwoTone />}
											onClick={actionMstSave}
										>
											저장
										</Button>
									</ButtonGroup>
								</div>
							</Box>
							<Box sx={{ height: 'calc(100vh - 230px)', mt: 2 }}>
								<DataGridPremium
									rows={mstRows}
									columns={[
										{
											field: 'RowCrud',
											headerName: 'CRUD',
											type: 'string',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'RowNum',
											headerName: '번호',
											type: 'number',
											width: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'MstID',
											headerName: 'ID',
											type: 'number',
											width: 80,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ClientID',
											headerName: '거래처ID',
											type: 'number',
											width: 150,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ClientName',
											headerName: '거래처명',
											type: 'string',
											minWidth: 150,
											flex: 1,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'SmsCount',
											headerName: '발송건수 설정',
											type: 'number',
											minWidth: 150,
											flex: 1,
											editable: true,
											headerAlign: 'center',
										},
										{
											field: 'IsActive',
											headerName: '사용여부',
											type: 'boolean',
											width: 80,
											editable: true,
											headerAlign: 'center',
										},
									]}
									// checkboxSelection
									// disableSelectionOnClick
									disableMultipleSelection
									getRowId={(row) => row.MstID}
									apiRef={mstRef}
									onRowClick={onMstRowClick}
									pageSize={gridMstPageSize}
									onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									rowModesModel={rowModesModel}
									onRowEditStart={handleRowEditStart}
									onRowEditStop={handleRowEditStop}
									onCellEditStop={onCellEditStop}
									onStateChange={onMstStateChange}
									processRowUpdate={processRowUpdate}
									onSelectionModelChange={selectChangeHandler}
									selectionModel={selectionMstModel}
									components={{
										Toolbar: MstGridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									loading={gridMstLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												MstID: false,
												ClientID: false,
											},
										},
									}}
									hideFooterSelectedRowCount
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						md={6}
					>
						<Box sx={{ pt: 2, pr: 2, pb: 2, pl: 1 }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography
									variant="subtitle1"
									component="h3"
								>
									고객사 발신번호 목록
								</Typography>

								<div>
									<ButtonGroup>
										{/* <Button
							variant="contained"
							color="primary"
							size="small"
							startIcon={<AddCircleTwoToneIcon />}
							onClick={actionSlvAdd}
							disabled={slaveButtonActive || selectionMstModel.length === 0}
						>
							추가
						</Button> */}
										<Button
											variant="contained"
											color="warning"
											size="small"
											startIcon={<DeleteForeverTwoTone />}
											disabled={selectionSlvModel.length === 0}
											onClick={actionSlvDel}
										>
											삭제
										</Button>
										<Button
											variant="contained"
											color="success"
											size="small"
											startIcon={<SaveAsTwoTone />}
											onClick={actionSlvSave}
											disabled={selectionSlvModel.length === 0}
										>
											저장
										</Button>
									</ButtonGroup>
								</div>
							</Box>
							<Box sx={{ height: 'calc(100vh - 230px)', mt: 2 }}>
								<DataGridPremium
									rows={slvRows}
									columns={[
										{
											field: 'RowCrud',
											headerName: 'CRUD',
											type: 'string',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'RowNum',
											headerName: '번호',
											type: 'number',
											width: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'MstID',
											headerName: 'MstID',
											type: 'number',
											width: 80,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'SlvID',
											headerName: 'SlvID',
											type: 'number',
											width: 80,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ClientID',
											headerName: 'ClientID',
											type: 'number',
											width: 80,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'SmsName',
											headerName: '발신번호 별칭',
											type: 'string',
											minWidth: 150,
											flex: 1,
											editable: true,
											headerAlign: 'center',
											// renderCell: (params: GridRenderEditCellParams) => {
											// 	return (
											// 		<TextField
											// 			name="SmsName"
											// 			value={params.value ? params.value : ''}
											// 			size="small"
											// 			fullWidth
											// 			sx={{ border: 0 }}
											// 		/>
											// 	)
											// },
										},
										{
											field: 'SmsNumber',
											headerName: '발신번호',
											type: 'string',
											minWidth: 150,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'IsNumberActive',
											headerName: '발신번호 활성화 여부',
											type: 'boolean',
											width: 150,
											editable: true,
											headerAlign: 'center',
										},
										{
											field: 'Memo',
											headerName: '메모',
											type: 'string',
											minWidth: 150,
											flex: 1,
											editable: true,
											headerAlign: 'center',
										},
										// {
										// 	field: 'OrgFileName',
										// 	headerName: '원본파일명',
										// 	type: 'string',
										// 	width: 130,
										// 	editable: false,
										// 	headerAlign: 'center',
										// },
										// {
										// 	field: 'FileID',
										// 	headerName: '메모',
										// 	type: 'string',
										// 	width: 110,
										// 	editable: false,
										// 	headerAlign: 'center',
										// 	renderCell: (params) => {
										// 		const { FileID, OrgFileName } = params.row
										// 		return FileID ? (
										// 			<Button
										// 				variant="contained"
										// 				color="primary"
										// 				size="small"
										// 				onClick={() => {
										// 					fileDownloadByFileId(FileID, OrgFileName)
										// 				}}
										// 			>
										// 				파일 다운로드
										// 			</Button>
										// 		) : (
										// 			<>
										// 				<Typography
										// 					variant="body2"
										// 					color="text.secondary"
										// 				>
										// 					파일 없음
										// 				</Typography>
										// 			</>
										// 		)
										// 	},
										// },
									]}
									checkboxSelection
									// disableSelectionOnClick
									getRowId={(row) => row.SlvID}
									apiRef={slvRef}
									onRowClick={onSlvRowClick}
									pageSize={gridSlvPageSize}
									onPageSizeChange={(newPageSize) => setGridSlvPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									rowModesModel={rowSlvModesModel}
									onRowEditStart={handleSlvRowEditStart}
									onRowEditStop={handleSlvRowEditStop}
									onCellEditStop={onSlvCellEditStop}
									onStateChange={onSlvStateChange}
									processRowUpdate={processSlvRowUpdate}
									onSelectionModelChange={selectSlvChangeHandler}
									selectionModel={selectionSlvModel}
									components={{
										Toolbar: SlvGridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									loading={gridMstLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												SlvID: false,
												MstID: false,
												ClientID: false,
												OrgFileName: false,
											},
										},
									}}
									hideFooterSelectedRowCount
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						md={4}
						sx={{ display: 'none' }}
					>
						<Box sx={{ pt: 2, pr: 2, pb: 2, pl: 1 }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography
									variant="subtitle1"
									component="h3"
								>
									고객사 월별 전송 건수 설정
								</Typography>
								<div>
									<ButtonGroup>
										<Button
											variant="contained"
											color="secondary"
											size="small"
											startIcon={<AttachFileIcon />}
											disabled={
												selectionMstModel.length === 0 || selectionSlvModel.length === 0
											}
											onClick={openExcelPopup}
										>
											엑셀 업로드
										</Button>
										<Button
											variant="contained"
											color="primary"
											size="small"
											startIcon={<AddCircleTwoToneIcon />}
											onClick={actionMonthAdd}
											disabled={
												selectionMstModel.length === 0 || selectionSlvModel.length === 0
											}
										>
											추가
										</Button>
										<Button
											variant="contained"
											color="warning"
											size="small"
											startIcon={<DeleteForeverTwoTone />}
											disabled={selectionMonthModel.length === 0}
											onClick={actionMonthDel}
										>
											삭제
										</Button>
										<Button
											variant="contained"
											color="success"
											size="small"
											startIcon={<SaveAsTwoTone />}
											disabled={selectionMonthModel.length === 0}
											onClick={actionMonthSave}
										>
											저장
										</Button>
									</ButtonGroup>
								</div>
							</Box>
							<Box sx={{ height: 'calc(100vh - 230px)', mt: 2 }}>
								{/* <LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale={locale}
								> */}
								<DataGridPremium
									rows={monthRows}
									columns={[
										{
											field: 'RowCrud',
											headerName: 'CRUD',
											type: 'string',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ClientID',
											headerName: 'ClientID',
											type: 'number',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'SlvID',
											headerName: 'SlvID',
											type: 'number',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'RowNum',
											headerName: '번호',
											type: 'number',
											width: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'YM',
											headerName: '연월',
											type: 'string',
											width: 150,
											editable: true,
											headerAlign: 'center',
											flex: 1,
											// renderCell: (params: GridRenderEditCellParams) => {
											// 	// MonthPicker
											// 	return (
											// 		<DatePicker
											// 			openTo="month"
											// 			views={['year', 'month']}
											// 			inputFormat="YYYY-MM"
											// 			mask="____-__"
											// 			onChange={(e) => {
											// 				console.log(e)
											// 				console.log(moment(e).format('YYYY-MM'))
											// 				const newValue = e ? moment(e).format('YYYY-MM') : ''
											// 				console.log(newValue)
											// 				handleCellEditCommit({
											// 					id: params.id,
											// 					field: params.field,
											// 					value: newValue,
											// 				})
											// 			}}
											// 			value={params.value ? params.value : null}
											// 			renderInput={(params) => (
											// 				<TextField
											// 					size="small"
											// 					{...params}
											// 					fullWidth
											// 				/>
											// 			)}
											// 		/>
											// 	)
											// },
										},
										{
											field: 'Count',
											headerName: '사용 건수',
											type: 'string',
											width: 150,
											editable: true,
											headerAlign: 'center',
										},
									]}
									checkboxSelection
									disableSelectionOnClick
									getRowId={(row) => row.RowNum}
									apiRef={monthRef}
									onRowClick={onMonthRowClick}
									pageSize={gridMonthPageSize}
									onPageSizeChange={(newPageSize) => setGridMonthPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									rowModesModel={rowMonthModesModel}
									onRowEditStart={handleMonthRowEditStart}
									onRowEditStop={handleMonthRowEditStop}
									onCellEditStop={onMonthCellEditStop}
									onStateChange={onMonthStateChange}
									processRowUpdate={processMonthRowUpdate}
									onSelectionModelChange={selectMonthChangeHandler}
									selectionModel={selectionMonthModel}
									components={{
										Toolbar: SlvGridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									loading={gridMonthLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												ClientID: false,
												SlvID: false,
												RowNum: false,
											},
										},
									}}
									hideFooterSelectedRowCount
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
								{/* </LocalizationProvider> */}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default SmsClient
