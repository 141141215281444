import React, { useEffect, useState, ReactNode, useRef } from 'react'

import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { styled, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import MuiDrawer from '@mui/material/Drawer'
import Switch from '@mui/material/Switch'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import ListItemIcon from '@mui/material/ListItemIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { darkTheme, lightTheme } from '../constants/defaultValues'

import { selectError, removeGlobalAlert } from '../hooks/globalError'
import { selectuserInfo, removeUserInfo } from '../hooks/userInfo'
import { selectDisplayMode, setDisplayMode } from '../hooks/themeMode'
import { selectGnbToggle, setGnbToggle } from 'hooks/gnbToggle'
import { getCurrentUser, removeCurrentUser } from '../helpers/storage'
import { selectPagePosition, setPos1, setPos2 } from '../hooks/pagePos'

import { GnbType, TempElementItem, UserInfoUpdateType } from 'constants/types'
import Gnb from '../components/Gnb'
import { apiGetMenuList, confirmPassword, updateUserData } from '../api/api'
import Fab from '@mui/material/Fab'

import ScrollTop from 'components/ScrollTop'
import {
	Fade,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Slide,
	SlideProps,
	Snackbar,
	TextField,
	Divider,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'

import CloseIcon from '@mui/icons-material/Close'
import { getSettings, setSettings } from 'helpers/storage'
import { TransitionProps } from '@mui/material/transitions'
import { alertModal, confirmAlert, timerAlert } from 'util/util'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { userInfoStateProps } from 'constants/types'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import MenuBookIcon from '@mui/icons-material/MenuBook'

// import { clientIDs } from 'constants/clientIDs'
import { selectEnvSettingInfo, setEnvSetting } from 'hooks/envSetting'

import SurveyImg from 'assets/img/survey.jpeg'

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window
	children: React.ReactElement
}

type ChildProps = {
	children: ReactNode
}

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
	open?: boolean
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		padding: 0,
	},
}))

const MaterialUISwitch = styled(Switch)(({ theme }: any) => ({
	width: 62,
	height: 34,
	padding: 7,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 0,
		transform: 'translateX(6px)',
		'&.Mui-checked': {
			color: '#fff',
			transform: 'translateX(22px)',
			'& .MuiSwitch-thumb:before': {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					'#fff'
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
			},
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
			},
		},
	},
	'& .MuiSwitch-thumb': {
		backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
		width: 32,
		height: 32,
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				'#fff'
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
		borderRadius: 20 / 2,
	},
}))

const Drawer = styled(MuiDrawer)(({ theme }) => ({
	width: drawerWidth,
	flexShrink: 0,
	paddingBottom: '60px',
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	'& .MuiDrawer-paper': {
		width: drawerWidth,
	},
	'& .MuiListItemText-root': {
		opacity: 1,
		marginLeft: '1rem',
	},
}))

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	minHeight: '44px',
	height: '44px',
	'@media (min-width: 600px)': {
		minHeight: '44px',
	},
}))

const BottomIcons = styled('ul')(({ theme }) => ({
	position: 'fixed',
	bottom: 0,
	left: 0,
	right: 0,
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	listStyle: 'none',
	margin: 0,
	padding: 0,
	width: '240px',
	background: theme.palette.mode === 'dark' ? '#fff' : '#f6f6f6',
	li: {
		width: '50%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		'&:first-of-type': {
			borderRight: '0',
		},
		'button, a': {
			border: 0,
			outline: 0,
			color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
			backgroundColor:
				theme.palette.mode === 'dark' ? '#2C3A47' : 'rgba(0, 0, 0, .03)',
			width: '100%',
			padding: '10px 0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textDecoration: 'none',
			span: {
				marginLeft: 5,
			},
		},
	},
}))

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

interface SettingsModel {
	desnse: string
	row: number
	zoom: string
	res: string
	ocs: string
	resultPatientExand: string
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme, open }) => ({
	backgroundColor: theme.palette.custom.main,
	zIndex: theme.zIndex.drawer + 1,
	[theme.breakpoints.down('md')]: {
		zIndex: theme.zIndex.drawer - 1,
	},
}))

function SlideTransition(props: SlideProps) {
	return (
		<Slide
			{...props}
			direction="up"
		/>
	)
}

// 사용자 이름 영문 판단
const engPattern = /[a-zA-Z]/

const AppLayout = ({ children }: Props) => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const settingValues = getSettings()
	const settingValuesInfo = useSelector(selectEnvSettingInfo)

	const { home, pos1, pos2 } = useSelector(selectPagePosition)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const userInfo = useSelector(selectuserInfo)
	const gnbInfo = useSelector(selectPagePosition)
	const user = getCurrentUser()
	const themeMode = useSelector(selectDisplayMode)
	const errorInfo = useSelector(selectError)
	const gnbToggle = useSelector(selectGnbToggle)
	const [open, setOpen] = useState(false)
	const [isDark, setIsDark] = useState(true)
	const [menuList, setMenuList] = useState<GnbType[]>([])

	const [gnbUsername, setGnbUserName] = useState('')

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const menuOpen = Boolean(anchorEl)
	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const [messages, setMessages] = useState<any[]>([])

	const eventSourceRef = useRef<EventSource | null>(null)
	const [listening, setListening] = useState(false)

	const [messageOpen, setMessageOpen] = useState(false)

	const handleMessageClick = () => {
		setMessageOpen(true)
	}

	const handleMessageClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}
		setMessageOpen(false)
	}

	const messageAction = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleMessageClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	)

	/**
	 * alert
	 * 계정 관련 오류이면 스토리지 초기화
	 */
	const handleClose = () => {
		if (errorInfo.isAccountError) {
			dispatch(removeGlobalAlert())
			dispatch(removeUserInfo())
			window.location.href = '/'
		} else {
			setOpen(false)
			dispatch(removeGlobalAlert())
		}
	}

	/**
	 * 메뉴 열기
	 */
	const handleDrawerOpen = () => {
		//setOpen(true)
		dispatch(setGnbToggle(!gnbToggle))
	}

	/**
	 * 메뉴 닫기
	 */
	const handleDrawerClose = () => {
		//setOpen(false)
		dispatch(setGnbToggle(false))
	}

	/**
	 * 로그아웃
	 */
	const logoutAction = () => {
		removeCurrentUser()
		// removeSettings()
		dispatch(removeUserInfo())
		eventSourceRef.current?.close()
		navigate('/')
	}

	/**
	 * 다크모드 전환
	 */
	const switchTheme = () => {
		const themeColor = document.querySelector(
			"meta[name='theme-color']"
		) as HTMLElement
		themeColor.setAttribute(
			'content',
			themeMode.isDarkMode ? '#323036' : '#323036'
		)
		dispatch(setDisplayMode(!themeMode.isDarkMode))
		setIsDark(!isDark)
	}

	/**
	 * 환경설정
	 */
	const [envOpen, setEnvOpen] = useState(false)
	const [variables, setVariables] = useState<SettingsModel>({
		desnse: settingValues.desnse ? settingValues.desnse : 'compact',
		row: settingValues.row ? settingValues.row : 10,
		zoom: settingValues.zoom ? settingValues.zoom : '100%',
		res: settingValues.res ? settingValues.res : 'n',
		ocs: settingValues.ocs ? settingValues.ocs : 'n',
		resultPatientExand: settingValues.resultPatientExand || 'n',
	})
	const [variablesCpy, setVariablesCpy] = useState<SettingsModel>({
		desnse: settingValues.desnse ? settingValues.desnse : 'compact',
		row: settingValues.row ? settingValues.row : 10,
		zoom: settingValues.zoom ? settingValues.zoom : '100%',
		res: settingValues.res ? settingValues.res : 'n',
		ocs: settingValues.ocs ? settingValues.ocs : 'n',
		resultPatientExand: settingValues.resultPatientExand || 'n',
	})
	const envClose = () => {
		setEnvOpen(false)
		setVariablesCpy({ ...variables })
	}

	const [state, setState] = useState<{
		open: boolean
		Transition: React.ComponentType<
			TransitionProps & {
				children: React.ReactElement<any, any>
			}
		>
	}>({
		open: false,
		Transition: Fade,
	})

	const envSave = () => {
		console.table(variablesCpy)
		console.log({ ...variablesCpy })

		setState({
			...state,
			open: true,
			Transition: SlideTransition,
		})
		setEnvOpen(false)
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				setTimeout(() => {
					setSettings(variablesCpy)
					dispatch(setEnvSetting(variablesCpy))
				}, 100)
				setTimeout(() => {
					setVariables({ ...variablesCpy })
					setVariablesCpy({ ...variablesCpy })
				}, 100)

				const pathName = window.location.pathname
				const variableRes = variablesCpy.res

				console.table(variableRes)

				/**
				 * o : v1
				 * v : v1.1
				 * n : v1.5
				 * v2 : v2
				 */

				if (pathName === '/admin/test/result') {
					if (variableRes === 'o') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOld' }))
						navigate('/admin/test/resultOld')

						gnbInfoChange()
					} else if (variableRes === 'v') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOldV11' }))
						navigate('/admin/test/resultOldV11')

						gnbInfoChange()
					} else if (variables.res === 'v2') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultV2' }))
						navigate('/admin/test/resultV2')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/result' }))
						navigate('/admin/test/result')

						gnbInfoChange()
					}
				} else if (pathName === '/admin/test/resultOld') {
					if (variableRes === 'n') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/result' }))
						navigate('/admin/test/result')

						gnbInfoChange()
					} else if (variableRes === 'v') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOldV11' }))
						navigate('/admin/test/resultOldV11')

						gnbInfoChange()
					} else if (variables.res === 'v2') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultV2' }))
						navigate('/admin/test/resultV2')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOld' }))
						navigate('/admin/test/resultOld')

						gnbInfoChange()
					}
				} else if (pathName === '/admin/test/resultOldV11') {
					if (variableRes === 'o') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOld' }))
						navigate('/admin/test/resultOld')

						gnbInfoChange()
					} else if (variableRes === 'n') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/result' }))
						navigate('/admin/test/result')

						gnbInfoChange()
					} else if (variables.res === 'v2') {
						console.log('1')
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultV2' }))
						navigate('/admin/test/resultV2')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOldV11' }))
						navigate('/admin/test/resultOldV11')

						gnbInfoChange()
					}
				} else if (pathName === '/admin/test/resultV2') {
					if (variableRes === 'v') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOldV11' }))
						navigate('/admin/test/resultOldV11')

						gnbInfoChange()
					} else if (variableRes === 'n') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/result' }))
						navigate('/admin/test/result')

						gnbInfoChange()
					} else if (variables.res === 'o') {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOld' }))
						navigate('/admin/test/resultOld')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultV2' }))
						navigate('/admin/test/resultV2')

						gnbInfoChange()
					}
				} else if (pathName === '/admin/test/syncUpload') {
					if (variableRes === 'o') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: 'OCS 연동', link: '' }))
						dispatch(setPos2({ title: 'OCS 연동', link: '/admin/test/syncOld' }))
						navigate('/admin/test/syncOld')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: 'OCS 접수 업로드', link: '' }))
						dispatch(
							setPos2({ title: 'OCS 접수 업로드', link: '/admin/test/syncUpload' })
						)
						navigate('/admin/test/syncUpload')

						gnbInfoChange()
					}
				} else if (pathName === '/admin/test/syncDownload') {
					if (variableRes === 'o') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: 'OCS 연동', link: '' }))
						dispatch(setPos2({ title: 'OCS 연동', link: '/admin/test/syncOld' }))
						navigate('/admin/test/syncOld')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: 'OCS 결과 다운로드', link: '' }))
						dispatch(
							setPos2({ title: 'OCS 결과 다운로드', link: '/admin/test/syncDownload' })
						)
						navigate('/admin/test/syncDownload')

						gnbInfoChange()
					}
				} else if (pathName === '/admin/test/syncOld') {
					if (variableRes === 'n') {
						//dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: 'OCS 접수 업로드', link: '' }))
						dispatch(
							setPos2({ title: 'OCS 접수 업로드', link: '/admin/test/syncUpload' })
						)
						navigate('/admin/test/syncUpload')

						gnbInfoChange()
					} else {
						dispatch(setPos1({ title: 'OCS 연동', link: '' }))
						dispatch(setPos2({ title: 'OCS 연동', link: '/admin/test/syncOld' }))
						navigate('/admin/test/syncOld')

						gnbInfoChange()
					}
				} else {
					gnbInfoChange()
					//window.location.reload()
				}
				timerAlert('저장되었습니다.', 'success', () => {})
			},
			() => {
				setTimeout(() => {
					setSettings(variables)
					dispatch(setEnvSetting(variables))
				}, 100)
				setTimeout(() => {
					setVariables({ ...variables })
					setVariablesCpy({ ...variables })
				}, 100)
			}
		)
	}

	/**
	 * 개인정보
	 */
	const passwordRef = useRef<HTMLInputElement>(null)
	const [userInfoOpen, setUserInfoOpen] = useState(false)
	const [userInfoStep, setUserInfoStep] = useState(0)
	const [userPassword, setUserPassword] = useState('')
	const [userInfoState, setUserInfoState] = useState<UserInfoUpdateType>({
		UserID: '',
		Username: '',
		EMail: '',
		TelNumber: '',
		CellNumber: '',
		EmployeeID: 0,
		password: '',
		password2: '',
	})

	const userInfoClose = () => {
		setUserInfoOpen(false)
		setUserInfoState({
			UserID: '',
			Username: '',
			EMail: '',
			TelNumber: '',
			CellNumber: '',
			EmployeeID: 0,
			password: '',
			password2: '',
		})
		setUserPassword('')
		setUserInfoStep(0)
	}

	const passwordCheck = () => {
		if (userInfoStep === 0) {
			const request: userInfoStateProps = {
				password: userPassword,
			}
			confirmPassword(request).then((res) => {
				console.log(res)
				setUserInfoState({
					...res,
				})
				setUserInfoStep(1)
			})
			//setUserInfoStep(1)
		}
		//setUserInfoOpen(false)
	}

	/**
	 * 개인정보 저장
	 */
	const saveUserInfo = () => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
		const phoneRegex = /^\d{2,3}-\d{3,4}-\d{4}$/
		if (userInfoState.Username === undefined) {
			alertModal('이름을 입력해주세요.', 'error', () => {})
			return
		}
		if (userInfoState.Username?.length < 2) {
			alertModal('이름을 2자 이상 입력해주세요.', 'error', () => {})
			return
		}
		if (userInfoState.password === undefined) {
			alertModal('비밀번호를 입력해주세요.', 'error', () => {})
			return
		}
		if (userInfoState.password?.length < 8) {
			alertModal('비밀번호는 8자 이상이어야 합니다.', 'error', () => {})
			return
		}
		if (userInfoState.password !== userInfoState.password2) {
			alertModal('비밀번호가 일치하지 않습니다.', 'error', () => {})
			return
		}
		if (!emailRegex.test(userInfoState.EMail)) {
			alertModal('이메일 형식이 올바르지 않습니다.', 'error', () => {})
			return
		}
		if (userInfo.infoType === 'S') {
			if (userInfoState.TelNumber === undefined) {
				alertModal('전화번호를 입력해주세요.', 'error', () => {})
				return
			}
			if (userInfoState.CellNumber === undefined) {
				alertModal('휴대폰번호를 입력해주세요.', 'error', () => {})
				return
			}
			if (!phoneRegex.test(userInfoState.TelNumber)) {
				alertModal('전화번호 형식이 올바르지 않습니다.', 'error', () => {})
				return
			}
			if (!phoneRegex.test(userInfoState.CellNumber)) {
				alertModal('휴대폰번호 형식이 올바르지 않습니다.', 'error', () => {})
				return
			}
		}
		if (
			userInfo.infoType === 'C' &&
			(userInfo.clientID === 0 || userInfoState.EmployeeID === 0)
		) {
			alertModal(
				'거래처 ID가 없습니다. 문제가 지속될 경우 관리자에게 문의하세요.',
				'error',
				() => {}
			)
			return
		}
		updateUserData(userInfoState).then((res) => {
			console.log(res)
			if (res) {
				setUserInfoOpen(false)
				confirmAlert(
					'저장되었습니다. 보안을 위해 다시 로그인해주세요.',
					() => {
						setUserInfoStep(0)
						setUserInfoState({
							UserID: '',
							Username: '',
							EMail: '',
							TelNumber: '',
							CellNumber: '',
							EmployeeID: 0,
							password: '',
							password2: '',
						})
						setUserPassword('')
						logoutAction()
					},
					() => {}
				)
			}
		})
	}

	const onKeyup = (e: any) => {
		if (e.keyCode === 13) passwordCheck()
	}

	const pageMove = (
		e: { menuName: string; menuLink: string },
		parent: string,
		panelName: string
	) => {
		// console.log(e.menuName, e.menuLink, parent, panelName)
		// if (!isMdUp) {
		// 	dispatch(setGnbToggle(false))
		// }
		dispatch(setGnbToggle(false))
		dispatch(setPos1({ title: parent, link: '' }))
		dispatch(setPos2({ title: e.menuName, link: e.menuLink }))
		navigate(e.menuLink)
		console.log('page move')
		console.log(e.menuName, e.menuLink, parent, panelName)
		// console.log(nowMenu)
	}

	const gnbInfoChange = () => {
		setVariables({ ...settingValues })
		const { isSmsActive } = userInfo
		apiGetMenuList().then((res) => {
			const tmpMenuArray = new Array()

			console.log(res)

			res.forEach((item: TempElementItem, index: number) => {
				// isSmsActive false 일 경우 SMS 메뉴 제외
				// if (isSmsActive === false && item.menuName.indexOf('SMS') > -1) {
				// 	return
				// }
				// clientID 2984 : 서울내외의원 체크
				if (item.menuName === '결과 연동' && userInfo?.clientID === 2984) {
					const addedItem: TempElementItem = {
						id: 9999,
						menuName: '엑셀 다운로드',
						menuLink: '/admin/test/excelDownload',
						depth: 2,
						active: true,
						level: item.level,
						menuIcon: 'FileDownloadIcon',
						parentId: item.parentId,
						roleAdmin: item.roleAdmin,
						roleId: item.roleId,
						roleSystem: item.roleSystem,
						roleUser: item.roleUser,
						children: [],
					}
					if (item.children) item.children.push(addedItem)
					tmpMenuArray.push(item)
				} else {
					if (item.id === 9) {
						// 검사결과조회 parent node
						item?.children.forEach((child: TempElementItem) => {
							if (child.id === 10) {
								if (variablesCpy.res === 'n') {
									child.menuLink = '/admin/test/result'
								} else if (variablesCpy.res === 'v') {
									child.menuLink = '/admin/test/resultOldV11'
								} else if (variablesCpy.res === 'v2') {
									child.menuLink = '/admin/test/resultV2'
								} else {
									child.menuLink = '/admin/test/resultOld'
								}
							}
						})
					} else if (item.id === 15) {
						item?.children.forEach((child: TempElementItem) => {
							if (child.id === 16) {
								if (variablesCpy.res === 'n') {
									child.menuLink = '/admin/test/syncUpload'
								} else {
									child.menuLink = '/admin/test/syncOld'
								}
							}
						})
					}
					tmpMenuArray.push(item)
				}
				// 슬라이드 대여 조회 메뉴가 자바코드에서 링크가 잘못 걸려 있는 관계로 임시 처리
				item?.children.forEach((child: TempElementItem) => {
					if (child.menuName === '슬라이드 대여 조회') {
						child.menuLink = '/admin/test/sbResult'
					}
				})
			})

			setMenuList([])

			setTimeout(() => {
				setMenuList(tmpMenuArray)
			}, 100)
		})
	}

	const onSettingsChange = (e: any) => {
		console.log(e.target.name, e.target.value)
	}

	const [noticeItem, setNoticeItem] = useState<any>(null)
	const [noticeOpen, setNoticeOpen] = useState(false)
	const onNoticeClose = () => {
		setNoticeOpen(false)
	}
	const handleNoticeClose = () => {
		setNoticeOpen(false)
	}
	const noticePopupAction = () => {
		setNoticeOpen(false)
		setNoticePopup(true)
	}
	const noticePopupClose = () => {
		setNoticePopup(false)
		setNoticeOpen(false)
		setTimeout(() => {
			setNoticeItem(null)
		}, 500)
	}
	const noticeAction = (
		<React.Fragment>
			<Button
				color="primary"
				size="small"
				variant="contained"
				onClick={noticePopupAction}
			>
				확인하기
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleNoticeClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	)
	const setupEventSource = () => {
		eventSourceRef.current = new EventSource(
			`${process.env.REACT_APP_HOST}/message/subdscribe`
		)
		eventSourceRef.current.onopen = (event) => {
			console.log('onopen')
			console.log(event)
		}
		eventSourceRef.current.onmessage = (event) => {
			console.log('onmessage')
			console.log(event.data)
		}
		eventSourceRef.current.onerror = (event) => {
			console.log('onerror')
			console.log(event)
			if (
				event.currentTarget instanceof EventSource &&
				event.currentTarget.readyState === EventSource.CLOSED
			) {
				console.log('closed')
			}
			eventSourceRef.current?.close()
		}

		setListening(true)

		return () => {
			eventSourceRef.current?.close()
		}
	}

	const [noticePopup, setNoticePopup] = useState(false)

	const [notiImg1Show, setNotiImg1Show] = useState(false)

	const notShowToday = () => {
		setNoticePopup(false)
		// 오늘 하루 보지 않기
		const today = new Date()
		const year = today.getFullYear()
		const month = today.getMonth() + 1
		const date = today.getDate()
		const todayStr = `${year}-${month < 10 ? '0' + month : month}-${
			date < 10 ? '0' + date : date
		}`
		localStorage.setItem('notShowToday', todayStr)
	}

	useEffect(() => {
		// 오늘 하루 보지 않기
		const today = new Date()
		const year = today.getFullYear()
		const month = today.getMonth() + 1
		const date = today.getDate()
		const todayStr = `${year}-${month < 10 ? '0' + month : month}-${
			date < 10 ? '0' + date : date
		}`
		const notShowTodayStr = localStorage.getItem('notShowToday')

		// if (todayStr > '2024-07-31') {
		// 	setNotiImg1Show(false)
		// }

		// // notShowTodayStr이 2024-07-22일 까지만 출력
		if (todayStr >= '2024-09-19' && todayStr <= '2024-10-11') {
			if (notShowTodayStr === todayStr) {
				setNoticePopup(false)
			} else {
				setNoticePopup(true)
			}
		}
		// setNoticePopup(true)
		// settingValuesInfo

		gnbInfoChange()
		/**
		 * appbar 상단 사용자 이름 첫글자 처리
		 * 영문이면 첫글자 대문자 처리
		 * 그외에 그냥 첫글자 출력
		 */
		let firstName = userInfo?.clientName?.substring(0, 1)
		if (userInfo?.infoType === 'W') firstName = userInfo.username?.substring(0, 1)
		// console.log('firstName', firstName)
		if (firstName) {
			if (engPattern.test(firstName)) {
				setGnbUserName(firstName.toUpperCase())
			} else {
				setGnbUserName(firstName)
			}
		}
		// title 변경
		if (userInfo?.infoType === 'W') {
			document.getElementsByTagName(
				'title'
			)[0].innerHTML = `신원의료재단 - ${userInfo.username}`
		} else {
			document.getElementsByTagName('title')[0].innerHTML = `신원의료재단
		${userInfo?.clientName && ' - ' + userInfo.clientName}`
		}

		// if not localhost
		// if (window.location.hostname !== 'localhost') {
		// 	setupEventSource()
		// }

		// sse event
		// if (!listening) {
		// 	setupEventSource()
		// }
	}, [])

	return (
		<>
			<ThemeProvider theme={themeMode.isDarkMode ? darkTheme : lightTheme}>
				<CssBaseline />
				{/* 개인정보 창 */}
				<Snackbar
					open={noticeOpen}
					onClose={onNoticeClose}
					action={noticeAction}
					message={noticeItem?.title}
				/>
				<Dialog
					open={noticePopup}
					// maxWidth="sm"
					scroll="paper"
					sx={{
						'.MuiDialog-paper': {
							position: 'fixed',
							top: '0px',
							left: '0px',
							height: 'auto',
							width: '450px',
							'@media (max-width: 639px)': {
								width: 'auto',
								right: '0px',
							},
						},
					}}
				>
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<Typography
								sx={{ flex: 1 }}
								variant="h6"
								component="p"
							>
								[공지사항]
								{/* {noticeItem?.title} */}
							</Typography>
							<IconButton
								edge="end"
								color="inherit"
								onClick={noticePopupClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<DialogContent sx={{ p: 0 }}>
						{/* <div dangerouslySetInnerHTML={{ __html: noticeItem?.content }}></div> */}
						<a
							href="https://naver.me/GDaQcS4C"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={SurveyImg}
								alt=""
								style={{ width: '100%' }}
							/>
						</a>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Grid container>
							<Grid
								item
								xs={6}
								sx={{ display: 'flex', justifyContent: 'flex-start' }}
							>
								<Button
									startIcon={<CancelIcon />}
									type="button"
									variant="contained"
									color="primary"
									onClick={notShowToday}
								>
									오늘 하루 보지 않기
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{ display: 'flex', justifyContent: 'flex-end' }}
							>
								<Button
									type="button"
									variant="contained"
									color="secondary"
									onClick={noticePopupClose}
								>
									확인
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
				<Dialog open={userInfoOpen}>
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<Typography
								sx={{ ml: 2, flex: 1 }}
								variant="h6"
								component="p"
							>
								개인정보
							</Typography>
							<IconButton
								edge="end"
								color="inherit"
								onClick={userInfoClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Box sx={{ p: 2 }}>
						{userInfoStep === 0 && (
							<TextField
								label="비밀번호"
								variant="outlined"
								size="small"
								type="password"
								fullWidth
								value={userPassword}
								inputRef={passwordRef}
								onChange={(e) => {
									setUserPassword(e.target.value)
								}}
								onKeyUp={onKeyup}
							/>
						)}
						{userInfoStep === 1 && (
							<Grid
								container
								gap={2}
							>
								<Grid
									item
									xs={12}
								>
									<TextField
										label="아이디"
										variant="outlined"
										size="small"
										fullWidth
										InputProps={{
											readOnly: true,
										}}
										value={userInfoState.UserID}
										onChange={(e) => {
											setUserInfoState({
												...userInfoState,
												UserID: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<TextField
										label="비밀번호"
										variant="outlined"
										size="small"
										type="password"
										fullWidth
										value={userInfoState.password || ''}
										onChange={(e) => {
											setUserInfoState({
												...userInfoState,
												password: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<TextField
										label="비밀번호 확인"
										variant="outlined"
										size="small"
										type="password"
										fullWidth
										value={userInfoState.password2 || ''}
										onChange={(e) => {
											setUserInfoState({
												...userInfoState,
												password2: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<TextField
										label="이름"
										variant="outlined"
										size="small"
										fullWidth
										value={userInfoState.Username || ''}
										onChange={(e) => {
											setUserInfoState({
												...userInfoState,
												Username: e.target.value,
											})
										}}
									/>
								</Grid>
								{userInfo.infoType !== 'S' && (
									<Grid
										item
										xs={12}
									>
										<TextField
											label="이메일"
											variant="outlined"
											size="small"
											type="email"
											fullWidth
											value={userInfoState.EMail || ''}
											onChange={(e) => {
												setUserInfoState({
													...userInfoState,
													EMail: e.target.value,
												})
											}}
										/>
									</Grid>
								)}
								{userInfo.infoType === 'S' && (
									<>
										<Grid
											item
											xs={12}
										>
											<TextField
												label="전화번호"
												variant="outlined"
												size="small"
												type="tel"
												fullWidth
												value={userInfoState.TelNumber || ''}
												onChange={(e) => {
													setUserInfoState({
														...userInfoState,
														TelNumber: e.target.value,
													})
												}}
											/>
										</Grid>
										<Grid
											item
											xs={12}
										>
											<TextField
												label="휴대전화"
												variant="outlined"
												size="small"
												type="tel"
												fullWidth
												value={userInfoState.CellNumber || ''}
												onChange={(e) => {
													setUserInfoState({
														...userInfoState,
														CellNumber: e.target.value,
													})
												}}
											/>
										</Grid>
									</>
								)}
							</Grid>
						)}
					</Box>
					<DialogActions>
						<Button
							startIcon={<CancelIcon />}
							type="button"
							variant="contained"
							color="secondary"
							onClick={userInfoClose}
						>
							취소
						</Button>
						{userInfoStep === 0 && (
							<Button
								type="submit"
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}
								onClick={passwordCheck}
							>
								확인
							</Button>
						)}
						{userInfoStep === 1 && (
							<Button
								type="submit"
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}
								onClick={saveUserInfo}
							>
								저장
							</Button>
						)}
					</DialogActions>
				</Dialog>
				{/* 환경설정 창 */}
				<Dialog
					open={envOpen}
					onClose={envClose}
				>
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<Typography
								sx={{ ml: 2, flex: 1 }}
								variant="h6"
								component="div"
							>
								환경설정
							</Typography>
							<IconButton
								edge="end"
								color="inherit"
								onClick={envClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Box sx={{ px: 2, pt: 2 }}>
						<FormControl>
							<FormLabel id="res-version">결과조회</FormLabel>
							<RadioGroup
								row
								aria-labelledby="res-version"
								name="res-version"
								value={variablesCpy.res}
								onChange={(e) => setVariablesCpy({ ...variables, res: e.target.value })}
							>
								<FormControlLabel
									value="o"
									control={<Radio />}
									label="Ver1.0"
								/>
								<FormControlLabel
									value="v"
									control={<Radio />}
									label="Ver1.1"
								/>
								<FormControlLabel
									value="n"
									control={<Radio />}
									label="Ver1.5"
								/>
								{(userInfo?.infoType === 'C' && userInfo?.isEHWAClass) ||
								userInfo?.infoType === 'W' ||
								userInfo?.infoType === 'S' ? (
									<FormControlLabel
										value="v2"
										control={<Radio />}
										label="Ver2.0"
									/>
								) : (
									<></>
								)}
							</RadioGroup>
						</FormControl>
						<FormControl>
							<FormLabel id="resultPatientExand">수진자보기</FormLabel>
							<RadioGroup
								row
								aria-labelledby="resultPatientExand"
								name="resultPatientExand"
								value={variablesCpy.resultPatientExand}
								onChange={(e) =>
									setVariablesCpy({ ...variables, resultPatientExand: e.target.value })
								}
							>
								<FormControlLabel
									value="y"
									control={<Radio />}
									label="보임"
								/>
								<FormControlLabel
									value="n"
									control={<Radio />}
									label="숨김"
								/>
							</RadioGroup>
						</FormControl>
					</Box>
					<Box sx={{ px: 2 }}>
						<FormControl>
							<FormLabel id="demo-row-radio-buttons-group-label">그리드 폭</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={variablesCpy.desnse}
								onChange={(e) =>
									setVariablesCpy({ ...variablesCpy, desnse: e.target.value })
								}
							>
								<FormControlLabel
									value="compact"
									control={<Radio />}
									label="좁게"
								/>
								<FormControlLabel
									value="standard"
									control={<Radio />}
									label="기본"
								/>
								<FormControlLabel
									value="comfortable"
									control={<Radio />}
									label="넓게"
								/>
							</RadioGroup>
						</FormControl>
					</Box>
					<Box sx={{ px: 2 }}>
						<FormControl>
							<FormLabel id="demo-row-radio-buttons-group-label2">
								한번에 출력할 테이블 행의 갯수
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label2"
								name="row-radio-buttons-group2"
								value={variablesCpy.row}
								onChange={(e) =>
									setVariablesCpy({ ...variablesCpy, row: parseInt(e.target.value) })
								}
							>
								<FormControlLabel
									value="10"
									control={<Radio />}
									label="10개"
								/>
								<FormControlLabel
									value="20"
									control={<Radio />}
									label="20개"
								/>
								<FormControlLabel
									value="50"
									control={<Radio />}
									label="50개"
								/>
								<FormControlLabel
									value="100"
									control={<Radio />}
									label="100개"
								/>
								<FormControlLabel
									value="500"
									control={<Radio />}
									label="500개"
								/>
								<FormControlLabel
									value="1000"
									control={<Radio />}
									label="1000개"
								/>
							</RadioGroup>
						</FormControl>
					</Box>
					<Box sx={{ px: 2 }}>
						<FormControl>
							<FormLabel id="zoom-row-radio-buttons-group-label2">
								리포트 기본 확대/축소 비율
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby="zoom-row-radio-buttons-group-label2"
								name="zoom-row-radio-buttons-group2"
								value={variablesCpy.zoom}
								onChange={(e) =>
									setVariablesCpy({ ...variablesCpy, zoom: e.target.value })
								}
							>
								<FormControlLabel
									value="80"
									control={<Radio />}
									label="80%"
								/>
								<FormControlLabel
									value="90"
									control={<Radio />}
									label="90%"
								/>
								<FormControlLabel
									value="100"
									control={<Radio />}
									label="100%"
								/>
								<FormControlLabel
									value="110"
									control={<Radio />}
									label="110%"
								/>
								<FormControlLabel
									value="120"
									control={<Radio />}
									label="120%"
								/>
								<FormControlLabel
									value="999"
									control={<Radio />}
									label="화면 맞춤"
								/>
							</RadioGroup>
						</FormControl>
					</Box>
					<DialogActions>
						<Button
							startIcon={<CancelIcon />}
							type="button"
							variant="contained"
							color="secondary"
							onClick={envClose}
						>
							취소
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							startIcon={<SaveIcon />}
							onClick={envSave}
						>
							저장
						</Button>
					</DialogActions>
				</Dialog>
				{errorInfo?.isError && (
					<Dialog
						open={errorInfo.isError}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">오류가 발생했습니다.</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{errorInfo.errorMessage}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								autoFocus
							>
								확인
							</Button>
						</DialogActions>
					</Dialog>
				)}
				<Box sx={{ display: 'flex' }}>
					<AppBar
						position="fixed"
						open={open}
						sx={{
							backdropFilter: 'blur(10px)',
							backgroundColor: themeMode.isDarkMode
								? 'rgba(50,48,54, 0.65)'
								: 'rgba(255,255,255, 0.65)',
						}}
					>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								// sx={{
								// 	...(open && { display: 'none' }),
								// }}
								// sx={{
								//   [theme.breakpoints.up('md')]: {
								//     display: 'none',
								//   },
								// }}
								sx={{
									color: themeMode.isDarkMode ? '#fff' : '#000',
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								variant="h6"
								noWrap
								component="h1"
							>
								{isMdUp ? (
									<span style={{ color: themeMode.isDarkMode ? '#fff' : '#000' }}>
										신원의료재단
										{userInfo?.clientName && ' - ' + userInfo.clientName}{' '}
										{variables.res === 'v2'
											? '(Ver2.0)'
											: variables.res === 'n'
											? '(Ver1.5)'
											: variables.res === 'v'
											? '(Ver1.1)'
											: '(Ver1.0)'}
									</span>
								) : (
									<span>
										{pos2.title}{' '}
										{variables.res === 'v2'
											? '(Ver2.0)'
											: variables.res === 'n'
											? '(Ver1.5)'
											: variables.res === 'v'
											? '(Ver1.1)'
											: '(Ver1.0)'}
									</span>
								)}
							</Typography>
							<Box
								sx={{
									flexGrow: 1,
									justifyContent: 'center',
									display: 'flex',
									paddingLeft: '0.5rem',
								}}
							>
								{isMdUp && (
									<>
										{!userInfo.isEmpSub && (
											<>
												<Button
													color="secondary"
													variant="contained"
													size="small"
													onClick={() => {
														pageMove(
															{
																menuName: '결과조회',
																menuLink: '/admin/test/resultOld',
															},
															'결과조회',
															''
														)
													}}
													sx={{
														mx: 0.5,
														height: '40px',
														lineHeight: 1.2,
														fontSize: 14,
														textTransform: 'none',
														backgroundColor:
															gnbInfo?.pos2?.link === '/admin/test/resultOld'
																? 'rgb(79, 92, 121)'
																: '#95a5a6',
													}}
												>
													결과 조회
													<br />
													(Ver1.0)
												</Button>
												<Button
													color="secondary"
													variant="contained"
													size="small"
													onClick={() => {
														pageMove(
															{
																menuName: '결과조회',
																menuLink: '/admin/test/resultOldV11',
															},
															'결과조회',
															''
														)
													}}
													sx={{
														mx: 0.5,
														height: '40px',
														lineHeight: 1.2,
														fontSize: 14,
														textTransform: 'none',
														backgroundColor:
															gnbInfo?.pos2?.link === '/admin/test/resultOldV11'
																? 'rgb(79, 92, 121)'
																: '#95a5a6',
													}}
												>
													결과 조회
													<br />
													(Ver1.1)
												</Button>
												<Button
													color="secondary"
													variant="contained"
													size="small"
													onClick={() => {
														pageMove(
															{
																menuName: '결과조회',
																menuLink: '/admin/test/result',
															},
															'결과조회',
															''
														)
													}}
													sx={{
														mx: 0.5,
														height: '40px',
														lineHeight: 1.2,
														fontSize: 14,
														textTransform: 'none',
														backgroundColor:
															gnbInfo?.pos2?.link === '/admin/test/result'
																? 'rgb(79, 92, 121)'
																: '#95a5a6',
													}}
												>
													결과 조회
													<br />
													(Ver1.5)
												</Button>
												{(userInfo?.infoType === 'C' && userInfo?.isEHWAClass) ||
												userInfo?.infoType === 'W' ||
												userInfo?.infoType === 'S' ? (
													<Button
														color="secondary"
														variant="contained"
														size="small"
														onClick={() => {
															pageMove(
																{
																	menuName: '결과조회',
																	menuLink: '/admin/test/resultV2',
																},
																'결과조회',
																''
															)
														}}
														sx={{
															mx: 0.5,
															height: '40px',
															lineHeight: 1.2,
															fontSize: 14,
															textTransform: 'none',
															backgroundColor:
																gnbInfo?.pos2?.link === '/admin/test/resultV2'
																	? 'rgb(79, 92, 121)'
																	: '#95a5a6',
														}}
													>
														결과 조회
														<br />
														(Ver2.0)
													</Button>
												) : (
													<></>
												)}
												{userInfo?.tablePostFix &&
													userInfo.infoType === 'C' &&
													variables.res === 'n' && (
														<>
															<Button
																color="secondary"
																variant="contained"
																size="small"
																onClick={() => {
																	pageMove(
																		{
																			menuName: 'OCS 접수 업로드',
																			menuLink: '/admin/test/syncUpload',
																		},
																		'OCS 접수 업로드',
																		''
																	)
																}}
																sx={{
																	mx: 0.5,
																	height: '40px',
																	lineHeight: 1.2,
																	fontSize: 14,
																	textTransform: 'none',
																	backgroundColor:
																		gnbInfo?.pos2?.link === '/admin/test/syncUpload'
																			? 'rgb(79, 92, 121)'
																			: '#95a5a6',
																}}
															>
																OCS 접수
																<br />
																업로드
															</Button>
															{/* <Button
														color="secondary"
														variant="contained"
														size="small"
														onClick={() => {
															pageMove(
																{
																	menuName: 'OCS 결과 다운로드',
																	menuLink: '/admin/test/syncDownload2',
																},
																'OCS 결과 다운로드',
																''
															)
														}}
														sx={{
															mx: 0.5,
															height: '40px',
															lineHeight: 1.2,
															fontSize: 14,
															textTransform: 'none',
															backgroundColor:
																gnbInfo?.pos2?.link === '/admin/test/syncDownload2'
																	? 'rgb(79, 92, 121)'
																	: '#95a5a6',
														}}
													>
														OCS 다운로드
														<br />
														(테스트)
													</Button> */}
															<Button
																color="secondary"
																variant="contained"
																size="small"
																onClick={() => {
																	pageMove(
																		{
																			menuName: 'OCS 결과 다운로드',
																			menuLink: '/admin/test/syncDownload',
																		},
																		'OCS 결과 다운로드',
																		''
																	)
																}}
																sx={{
																	mx: 0.5,
																	height: '40px',
																	lineHeight: 1.2,
																	fontSize: 14,
																	textTransform: 'none',
																	backgroundColor:
																		gnbInfo?.pos2?.link === '/admin/test/syncDownload'
																			? 'rgb(79, 92, 121)'
																			: '#95a5a6',
																}}
															>
																OCS 결과
																<br />
																다운로드
															</Button>
														</>
													)}
												{userInfo?.tablePostFix &&
													userInfo.infoType === 'C' &&
													variables.res !== 'n' && (
														<Button
															color="secondary"
															variant="contained"
															size="small"
															onClick={() => {
																pageMove(
																	{
																		menuName: 'OCS 연동',
																		menuLink: '/admin/test/syncOld',
																	},
																	'OCS 연동',
																	''
																)
															}}
															sx={{
																mx: 0.5,
																height: '40px',
																lineHeight: 1.2,
																fontSize: 14,
																textTransform: 'none',
																backgroundColor:
																	gnbInfo?.pos2?.link === '/admin/test/syncOld'
																		? 'rgb(79, 92, 121)'
																		: '#95a5a6',
															}}
														>
															OCS
															<br />
															연동
														</Button>
													)}
												<Button
													color="secondary"
													variant="contained"
													size="small"
													onClick={() => {
														pageMove(
															{
																menuName: '일괄 다운로드',
																menuLink: '/admin/test/resultDownload',
															},
															'일괄 다운로드',
															''
														)
													}}
													sx={{
														mx: 0.5,
														height: '40px',
														lineHeight: 1.2,
														fontSize: 14,
														backgroundColor:
															gnbInfo?.pos2?.link === '/admin/test/resultDownload'
																? 'rgb(79, 92, 121)'
																: '#95a5a6',
													}}
												>
													일괄
													<br />
													다운로드
												</Button>
												<Button
													color="secondary"
													variant="contained"
													size="small"
													onClick={() => {
														pageMove(
															{
																menuName: '코로나 조회',
																menuLink: '/admin/test/covid',
															},
															'코로나 조회',
															''
														)
													}}
													sx={{
														mx: 0.5,
														height: '40px',
														lineHeight: 1.2,
														fontSize: 14,
														backgroundColor:
															gnbInfo?.pos2?.link === '/admin/test/covid'
																? 'rgb(79, 92, 121)'
																: '#95a5a6',
													}}
												>
													코로나
													<br />
													조회
												</Button>
												<Button
													color="secondary"
													variant="contained"
													size="small"
													onClick={() => {
														pageMove(
															{
																menuName: '병원체 신고조회',
																menuLink: '/admin/test/disease',
															},
															'병원체 신고조회',
															''
														)
													}}
													sx={{
														mx: 0.5,
														height: '40px',
														lineHeight: 1.2,
														fontSize: 14,
														backgroundColor:
															gnbInfo?.pos2?.link === '/admin/test/disease'
																? 'rgb(79, 92, 121)'
																: '#95a5a6',
													}}
												>
													병원체
													<br />
													신고조회
												</Button>
												{userInfo?.isEhwa ? (
													<Button
														color="secondary"
														variant="contained"
														size="small"
														onClick={() => {
															window.open('http://report.ewhalab.co.kr', '_blank')
														}}
														sx={{
															mx: 0.5,
															height: '40px',
															lineHeight: 1.2,
															fontSize: 14,
															backgroundColor: '#95a5a6',
														}}
													>
														이전 결과
														<br />
														조회
													</Button>
												) : (
													<Button
														color="secondary"
														variant="contained"
														size="small"
														onClick={() => {
															window.open('http://61.252.130.67:200', '_blank')
														}}
														sx={{
															mx: 0.5,
															height: '40px',
															lineHeight: 1.2,
															fontSize: 14,
															backgroundColor: '#95a5a6',
														}}
													>
														2023.8월
														<br />
														이전 조회
													</Button>
												)}
												{userInfo?.infoType === 'C' && userInfo?.isSmsActive && (
													<Button
														color="secondary"
														variant="contained"
														size="small"
														onClick={() => {
															pageMove(
																{
																	menuName: 'SMS 발송',
																	menuLink: '/admin/message/sendMessage',
																},
																'SMS 발송',
																''
															)
														}}
														sx={{
															mx: 0.5,
															height: '40px',
															lineHeight: 1.2,
															fontSize: 14,
															backgroundColor:
																gnbInfo?.pos2?.link === '/admin/message/sendMessage'
																	? 'rgb(79, 92, 121)'
																	: '#95a5a6',
														}}
													>
														SMS
														<br />
														서비스
													</Button>
												)}
												{/* <Button
											color="secondary"
											variant="contained"
											size="small"
											sx={{
												mx: 0.5,
												height: '40px',
												lineHeight: 1.2,
												fontSize: 14,
												backgroundColor: '#95a5a6',
											}}
											onClick={() => {
												window.open('https://939.co.kr/swcl', '_blank')
											}}
										>
											원격
											<br />
											지원
										</Button> */}
											</>
										)}
									</>
								)}
							</Box>
							<MaterialUISwitch
								sx={{ m: 1 }}
								checked={themeMode.isDarkMode}
								onChange={switchTheme}
							/>
							<Tooltip title="Account settings">
								<IconButton
									onClick={handleMenuClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={menuOpen ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={menuOpen ? 'true' : undefined}
								>
									<Avatar sx={{ width: 32, height: 32 }}>{gnbUsername}</Avatar>
								</IconButton>
							</Tooltip>
							<Menu
								anchorEl={anchorEl}
								id="account-menu"
								open={menuOpen}
								onClose={handleMenuClose}
								onClick={handleMenuClose}
								PaperProps={{
									elevation: 0,
									sx: {
										overflow: 'visible',
										filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
										mt: 1.5,
										'& .MuiAvatar-root': {
											width: 32,
											height: 32,
											ml: -0.5,
											mr: 1,
										},
										'&:before': {
											content: '""',
											display: 'block',
											position: 'absolute',
											top: 0,
											right: 14,
											width: 10,
											height: 10,
											bgcolor: 'background.paper',
											transform: 'translateY(-50%) rotate(45deg)',
											zIndex: 0,
										},
									},
								}}
								transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
							>
								{/* <MenuItem
									onClick={() => {
										navigate('/admin/myInfo')
									}}
								>
									<Avatar /> 정보변경
								</MenuItem> */}
								{!userInfo.isEmpSub && (
									<MenuItem
										onClick={() => {
											setEnvOpen(true)
										}}
										// onClick={() => {
										// 	navigate('/admin/env')
										// }}
									>
										<ListItemIcon>
											<AdminPanelSettingsIcon />
										</ListItemIcon>
										<Typography variant="inherit">환경설정</Typography>
									</MenuItem>
								)}
								{/* <Divider />
								<MenuItem
									onClick={() => {
										setUserInfoOpen(true)
										setTimeout(() => {
											passwordRef.current?.focus()
										}, 100)
									}}
								>
									<ListItemIcon>
										<AssignmentIndIcon />
									</ListItemIcon>
									<Typography variant="inherit">정보변경</Typography>
								</MenuItem>
								<Divider /> */}
								{/* <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem> */}
								<MenuItem onClick={logoutAction}>
									<ListItemIcon>
										<Logout fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">로그아웃</Typography>
								</MenuItem>
							</Menu>
						</Toolbar>
					</AppBar>
					<Drawer
						variant={isMdUp ? 'persistent' : 'temporary'}
						anchor="left"
						open={gnbToggle}
						onClose={handleDrawerClose}
					>
						<DrawerHeader>
							<IconButton onClick={handleDrawerClose}>
								{theme.direction === 'rtl' ? (
									<ChevronRightIcon />
								) : (
									open && <ChevronLeftIcon />
								)}
							</IconButton>
						</DrawerHeader>
						{/* <Divider /> */}
						<Gnb
							menuList={menuList}
							open={open}
							handleDrawerClose={handleDrawerClose}
						/>
						<BottomIcons>
							<li>
								<a
									href="http://web.onway-lab.org:7715/제23_52호_첨부차세대_LIS_Onway_Lab_프로그램_Web_결과조회_안내.pdf"
									target="_blank"
									rel="noreferrer"
								>
									<MenuBookIcon />
									<span>메뉴얼</span>
								</a>
							</li>
							<li>
								<a
									href="https://939.co.kr/swcl"
									target="_blank"
									rel="noreferrer"
								>
									<SupportAgentIcon />
									<span>원격지원</span>
								</a>
							</li>
						</BottomIcons>
					</Drawer>
					<Main open={open}>
						<DrawerHeader
							id="back-to-top-anchor"
							sx={{
								width: '0',
								height: '0',
								margin: '0',
								padding: '0',
								minWidth: '0',
								minHeight: '44px',
								'@media (min-width: 600px)': {
									minHeight: '44px',
								},
							}}
						/>
						<div
							style={
								isMdUp
									? {
											transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
											marginLeft: gnbToggle ? '0px' : '-240px',
									  }
									: {
											transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
											marginLeft: '0',
									  }
							}
						>
							{children}
							<ScrollTop {...children.props}>
								<Fab
									size="small"
									aria-label="상단으로"
								>
									<KeyboardArrowUpIcon />
								</Fab>
							</ScrollTop>
						</div>
						{/* <Snackbar
							open={messageOpen}
							autoHideDuration={6000}
							onClose={handleMessageClose}
							message={messages}
							action={messageAction}
						/> */}
						{messages.map((item: any, index: number) => {
							return (
								<Snackbar
									key={index}
									open={messageOpen}
									autoHideDuration={6000}
									onClose={handleMessageClose}
									message={item}
									action={messageAction}
								/>
							)
						})}
					</Main>
				</Box>
			</ThemeProvider>
		</>
	)
}

export default AppLayout
