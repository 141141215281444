import { createSlice } from '@reduxjs/toolkit'

export const pagePosSlice = createSlice({
  name: 'pagePos',
  initialState: {
    home: {
      title: '대시보드',
      link: '/admin/dashboard',
    },
    pos1: {
      title: '',
      link: '',
    },
    pos2: {
      title: '',
      link: '',
    },
  },
  reducers: {
    setPos1: (state, actions) => {
      state.pos1 = actions.payload
    },
    setPos2: (state, actions) => {
      state.pos2 = actions.payload
    },
    removePos1: state => {
      state.pos1 = {
        title: '',
        link: '',
      }
    },
    removePos2: state => {
      state.pos2 = {
        title: '',
        link: '',
      }
    },
  },
})

export const { setPos1, setPos2, removePos1, removePos2 } = pagePosSlice.actions
export default pagePosSlice.reducer
export const selectPagePosition = (state: any) => state.pagePos
