import React, { useEffect } from 'react'
import JSZip from 'jszip'
import { Button } from '@mui/material'

interface Props {
	base64DataArray: any // 여러 이미지의 base64 데이터 배열
}

const ImageDownloader: React.FC<Props> = ({ base64DataArray }) => {
	useEffect(() => {
		console.log('-------------')
		console.log(base64DataArray)
		console.log('-------------')
	}, [])
	const handleDownload = () => {
		const zip = new JSZip()

		const promises = base64DataArray.map((base64Data: any, index: number) => {
			return new Promise<void>((resolve, reject) => {
				const byteCharacters = atob(base64Data.AttachFile.split(',')[0])
				const byteNumbers = new Array(byteCharacters.length)

				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}

				const byteArray = new Uint8Array(byteNumbers)
				const blob = new Blob([byteArray], { type: base64Data.AttachFileExtension })

				const filename = `image${index + 1}.${base64Data.AttachFileExtension}`

				zip.file(filename, blob)

				resolve()
			})
		})

		Promise.all(promises).then(() => {
			zip.generateAsync({ type: 'blob' }).then((content) => {
				const downloadLink = document.createElement('a')
				downloadLink.href = URL.createObjectURL(content)
				downloadLink.download = '결과이미지.zip'
				downloadLink.click()
			})
		})
	}

	return (
		<button
			type="button"
			onClick={handleDownload}
		>
			일괄 다운로드
		</button>
	)
}

export default ImageDownloader
