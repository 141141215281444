import React, { useEffect, useState, useCallback } from 'react'

import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Alert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import LinearProgress from '@mui/material/LinearProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import Typography from '@mui/material/Typography'

import {
	DataGridPremium,
	koKR,
	useGridApiRef,
	GridCellEditStopParams,
	MuiEvent,
	GridColDef,
	GridRowsProp,
	GridEventListener,
	GridRowParams,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridValueSetterParams,
	GridValueGetterParams,
	GridRenderCellParams,
	GridRenderEditCellParams,
	GridCellEditCommitParams,
} from '@mui/x-data-grid-premium'

import {
	employeeList,
	employeeCheck,
	employeeSave,
	employeeDelete,
	employeeUpdate,
	apiGetCmmnCodeLevel2ByParentCmmnCd,
} from 'api/api'

import { extractCmmnValue, setCmmnName } from 'util/cmmnCodeUtil'
import {
	LevelType,
	SearchRequestType,
	EmployeeRequestType,
	employeeSearchType,
} from '../../constants/types'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'

import PagePositon from 'components/PagePositon'

import { Formik, Form, Field, FormikHelpers } from 'formik'
import {
	TextField as FormTextField,
	Switch as FormSwitch,
	Select as FormSelect,
} from 'formik-mui'
import * as Yup from 'yup'
import { keysInIterator, valuesInIterator } from 'util/objectUtil'
import Swal from 'sweetalert2'
// import ConfirmAlert from 'components/ConfirmAlert'
import { selectuserInfo } from 'hooks/userInfo'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { alertModal, confirmAlert } from 'util/util'
import ClientSearchPopupRef from 'views/test/clientSearchPopup'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

const PageBg = styled('div')(({ theme }) => ({
	padding: '1rem',
	borderRadius: '0.5rem',
	background: theme.palette.custom.wrapper,
	filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
}))

let empCdList: LevelType[] = []
let apCdList: LevelType[] = []
// let smsList: LevelType[] = []
// let repList: LevelType[] = []

/**
 * 검색 조건
 */
const searchOptions = [
	{
		value: 'employeeName',
		label: '이름',
	},
	{
		value: 'loginID',
		label: '아이디',
	},
]

const StackRow = styled('div')(({ theme }) => ({
	width: '100%',
	display: 'block',
	'.MuiFormControl-root': {
		width: '100%',
	},
}))

const Employee = () => {
	// UI
	const [snackbar, setSnackbar] = useState<Pick<
		AlertProps,
		'children' | 'severity'
	> | null>(null)
	const userInfo = useSelector(selectuserInfo)

	const [searchType, setSearchType] = useState('employeeName')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchClient, setSearchClient] = useState({
		clientID: 0,
		clientName: '',
	})

	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const navigate = useNavigate()

	const handleCloseSnackbar = () => setSnackbar(null)

	// data grid(value: React.SetStateAction<null>) => void
	const apiRef = useGridApiRef()
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [cmmnLevel, setCmmnLevel] = useState([])
	const [rowModesModel, setRowModesModel] = useState({})
	const [editRows, setEditRows] = useState<GridRowsProp>([])

	const onRowClick = (e: any) => {
		console.log(selectionModel)
	}

	/**
	 * 그리드 체크박스
	 * @param rows
	 */
	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionModel((prevState) => [...rows])
		console.log(rows)
		if (rows.length > 0) {
			setDisabledSave(false)
			setDisabledDel(false)
		} else {
			setDisabledSave(true)
			setDisabledDel(true)
		}
	}

	const processRowUpdate = useCallback(
		async (newRow: GridRowModel) => {
			console.log(newRow)
			console.log('processRowUpdate')
			// setSnackbar({ children: '수정되었습니다.', severity: 'success' })
			return newRow
			// surveyCountModify(newRow).then(res => {
			//   callData()
			//   setSnackbar({ children: '수정되었습니다.', severity: 'success' })
			// })
			// Make the HTTP request to save in the backend
			// const response = await mutateRow(newRow)
			// setSnackbar({ children: 'User successfully saved', severity: 'success' })
			// return response
		},
		[]
		// [mutateRow],
	)

	const handleProcessRowUpdateError = useCallback((error: Error) => {
		console.log('handleProcessRowUpdateError', error)
		setSnackbar({ children: error.message, severity: 'error' })
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
		console.log('start', event, params)
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
		console.log('end', event, params)
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		// if (params.reason === GridCellEditStopReasons.cellFocusOut) {
		//   event.defaultMuiPrevented = true
		// }
		setEditRows((prevState) => {
			return [...prevState, { ...row }]
		})
	}

	const onStateChange = (params: any) => {
		// console.log(params)
		// console.log(params.selection.length)
		// console.log(params.editRows)
	}

	// api response
	const [content, setContent] = useState([])
	const [last, setLast] = useState(false)
	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)
	const [totalElements, setTotalElements] = useState(0)
	const [totalpages, setTotalPages] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [addDialog, setAddDialog] = useState(false)
	const [idError, setIdError] = useState(false)

	const cmmnCdList = useSelector(selectCmmnCode)
	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	const initialValues: EmployeeRequestType = {
		employeeID: 0,
		clientID: 0,
		employeeType: '4',
		employeeName: '',
		loginID: '',
		loginPW: '',
		passwordConfirmation: '',
		mobilePhone: '',
		emailAddress: '',
		memoText: '',
		approvalState: '1',
		sms: false,
		rep: false,
		departName: '',
		ward: '',
	}
	const validationSchema = Yup.object({
		employeeType: Yup.string().required('권한은 필수항목입니다.'),
		employeeName: Yup.string()
			.min(2, '이름은 최소 2글자 입니다.')
			.max(10, '이름은 최대 10글자 입니다.')
			.required('이름은 필수항목입니다.'),
		loginID: Yup.string()
			.min(4, '아이디는 최소 4글자 입니다.')
			.max(20, '아이디는 최대 20글자 입니다.')
			.required('아이디는 필수항목입니다.'),
		loginPW: Yup.string()
			.min(8, '비밀번호는 최소 8글자 입니다.')
			.max(30, '비밀번호는 최대 30글자 입니다.')
			.required('비밀번호는 필수항목입니다.')
			.matches(
				/^(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
				'특수문자를 포함해주세요.'
			),
		passwordConfirmation: Yup.string()
			.oneOf([Yup.ref('loginPW')], '비밀번호가 일치하지 않습니다.')
			.required('비밀번호는 필수항목입니다.'),
		mobilePhone: Yup.string(),
		emailAddress: Yup.string(),
		memoText: Yup.string().max(250, '비밀번호는 최대 250글자 입니다.'),
		approvalState: Yup.string().required('승인상태는 필수항목입니다.'),
		sms: Yup.boolean(),
		rep: Yup.boolean(),
		editorID: Yup.string(),
		lastEditDate: Yup.string(),
		creationDate: Yup.string(),
		departName: Yup.string(),
		ward: Yup.string(),
	})

	/**
	 * 데이터 목록
	 */
	const getData = () => {
		if (userInfo.infoType === 'S' && !searchClient.clientID) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}
		const searchRequest: employeeSearchType = {
			searchType,
			searchKeyword,
			clientId: userInfo.infoType === 'S' ? searchClient.clientID : '',
		}
		setIsLoading(true)
		employeeList(searchRequest)
			.then((res) => {
				console.log(res)
				setContent(res)
				apiRef.current?.setQuickFilterValues([])
				apiRef.current?.setPage(0)
			})
			.finally(() => setIsLoading(false))
	}

	const getCmmnCode = () => {
		apiGetCmmnCodeLevel2ByParentCmmnCd('EMP_CD', 2).then((res) => {
			setCmmnLevel(res)
			empCdList = res
			console.log(empCdList)
		})
		apiGetCmmnCodeLevel2ByParentCmmnCd('AP_STAT', 2).then((res) => {
			setCmmnLevel(res)
			apCdList = res
			console.log(apCdList)
		})
		// apiGetCmmnCodeLevel2ByParentCmmnCd('IS_SMS', 2).then(res => {
		//   setCmmnLevel(res)
		//   smsList = res
		// })
		// apiGetCmmnCodeLevel2ByParentCmmnCd('IS_REP', 2).then(res => {
		//   setCmmnLevel(res)
		//   repList = res
		// })
	}

	// ui

	// input change
	const handleSearchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		if (event.target.name === 'searchType') {
			console.log(event.target.value)
			setSearchType(event.target.value)
		} else if (event.target.name === 'searchKeyword') {
			setSearchKeyword(event.target.value)
		}
	}

	// search submit
	const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault()
		getData()
	}

	// page position buttons
	const actionAdd = () => {
		if (userInfo.infoType === 'S' && !searchClient.clientID) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}
		setAddDialog(true)
		// apiRef.current.updateRows([addItem()])
	}

	const actionSave = () => {
		const valuesArrays = valuesInIterator(apiRef.current.getSelectedRows())
		console.log(valuesArrays)
		if (valuesArrays.length > 1) {
			alertModal('하나의 항목만 선택해주세요.', 'error', () => {})
			return
		}
		if (valuesArrays.length === 1) {
			confirmAlert(
				'수정하시겠습니까?',
				() => {
					employeeUpdate(valuesArrays).then((result) => {
						console.log(result)
						Swal.fire({
							title: 'Success!',
							text: `수정 성공 ${result.successCount}건, 수정 실패 ${result.failCount}건`,
							icon: 'success',
							confirmButtonText: '확인',
						}).then(() => {
							getData()
							setSelectionModel([])
							setDisabledSave(true)
							setDisabledDel(true)
						})
					})
				},
				() => {}
			)
		}
	}

	const actionDel = () => {
		const keyArrays = keysInIterator(apiRef.current.getSelectedRows())
		if (keyArrays.length > 1) {
			alertModal('하나의 항목만 선택해주세요.', 'error', () => {})
			return
		}
		if (keyArrays.length === 1) {
			confirmAlert(
				'삭제하시겠습니까?',
				() => {
					employeeDelete(keyArrays).then((result) => {
						console.log(result)
						Swal.fire({
							title: 'Success!',
							text: `삭제 성공 ${result.successCount}건, 삭제 실패 ${result.failCount}건`,
							icon: 'success',
							confirmButtonText: '확인',
						}).then(() => {
							getData()
							setSelectionModel([])
							setDisabledSave(true)
							setDisabledDel(true)
						})
					})
				},
				() => {}
			)
		}
	}

	const actionSearch = () => {
		getData()
	}
	const actionReset = () => {
		setSearchType('employeeName')
		setSearchKeyword('')
		setSearchClient({
			clientID: 0,
			clientName: '',
		})
		setDisabledDel(true)
		setDisabledSave(true)
		setSelectionModel([])
		apiRef.current?.setQuickFilterValues([])
		apiRef.current?.setPage(0)
	}

	const actionAddClose = () => {
		setAddDialog(false)
	}

	/**
	 * 거래처 직원 저장
	 */
	const employeeSubmit = (
		values: EmployeeRequestType,
		helpers: FormikHelpers<EmployeeRequestType>
	) => {
		const clientID = searchClient.clientID
		if ((userInfo.infoType === 'S' || userInfo.infoType === 'W') && !clientID) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		confirmAlert(
			'저장하시겠습니까?',
			() => {
				employeeCheck(values.loginID).then((res) => {
					if (res.exist === false) {
						console.log('저장')
						setIdError(false)
						const employeeRequest: EmployeeRequestType = {
							employeeID: values.employeeID || null,
							clientID: userInfo.infoType === 'S' ? clientID : userInfo.clientID,
							employeeType: values.employeeType,
							employeeName: values.employeeName,
							loginID: values.loginID,
							loginPW: values.loginPW,
							passwordConfirmation: '',
							mobilePhone: values.mobilePhone,
							emailAddress: values.emailAddress,
							memoText: values.memoText,
							approvalState: values.approvalState,
							sms: values.sms,
							rep: values.rep,
							departName: values.departName,
							ward: values.ward,
						}

						console.log(employeeRequest)

						employeeSave(employeeRequest)
							.then((res) => {
								if (res.success) {
									setAddDialog(false)
									Swal.fire({
										title: 'Success!',
										text: '저장되었습니다.',
										icon: 'success',
										confirmButtonText: '확인',
									}).then((result) => {
										if (result.isConfirmed) {
											setAddDialog(false)
										}
									})
								}
							})
							.finally(() => {
								getData()
								setAddDialog(false)
								setSelectionModel([])
								setDisabledSave(true)
								setDisabledDel(true)
							})
					} else {
						console.log('중복')
						setIdError(true)
					}
				})
			},
			() => {}
		)

		helpers.setSubmitting(false)
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) handleSearchSubmit(e)
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClient({
			clientID: e.ClientID,
			clientName: e.ClientName,
		})
		// setSearch({
		// 	...search,
		// 	clientID: e.ClientID,
		// })
		setClientDialogOpen(false)
	}

	const handleCellEditCommit = (params: GridCellEditCommitParams) => {
		const { id, field, value } = params

		console.log(id, field, value)

		setContent((prevRows: any) =>
			prevRows.map((row: any) => {
				if (row.employeeID === id) {
					return {
						...row,
						[field]: value,
					}
				}
				return row
			})
		)
	}

	useEffect(() => {
		// if (!userInfo?.master) {
		// 	Swal.fire({
		// 		html: '권한이없습니다',
		// 		icon: 'error',
		// 		confirmButtonText: '확인',
		// 	}).then((result) => {
		// 		if (result.isConfirmed) navigate(-1)
		// 	})
		// } else {
		// 	getData()
		// 	getCmmnCode()
		// }
		console.log(userInfo)
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		console.log(lv2?.level2)

		console.log(userInfo.infoType, userInfo?.master)

		if (userInfo.infoType === 'C' && !userInfo?.master) {
			Swal.fire({
				html: '권한이없습니다',
				icon: 'error',
				confirmButtonText: '확인',
			}).then((result) => {
				if (result.isConfirmed) navigate(-1)
			})
		} else if (userInfo.infoType === 'C' && userInfo?.master) {
			getData()
			getCmmnCode()
		} else if (userInfo.infoType === 'S' || userInfo.infoType === 'W') {
			getCmmnCode()
		}
	}, [])

	return (
		<>
			<Dialog
				open={addDialog}
				maxWidth="lg"
				onClose={actionAddClose}
			>
				<DialogTitle>계정 등록</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={employeeSubmit}
						enableReinitialize
					>
						{({
							isSubmitting,
							values,
							submitForm,
							resetForm,
							setFieldValue,
							handleChange,
							handleBlur,
							touched,
							errors,
							dirty,
						}) => (
							<Form style={{ marginTop: '1rem' }}>
								<Stack
									direction="column"
									spacing={2}
								>
									<Stack
										direction="row"
										spacing={2}
									>
										<Field
											name="employeeName"
											type="text"
											label="종사자명"
											component={FormTextField}
											value={values.employeeName}
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.employeeName && Boolean(errors.employeeName)}
											helperText={touched.employeeName && errors.employeeName}
											size="small"
										/>
										<div>
											<Field
												name="loginID"
												type="text"
												label="종사자ID"
												component={FormTextField}
												value={values.loginID}
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.loginID && Boolean(errors.loginID)}
												helperText={touched.loginID && errors.loginID}
												size="small"
											/>
											{idError && (
												<Typography
													variant="body2"
													sx={{ color: '#ff0000' }}
												>
													아이디가 이미 사용중입니다.
												</Typography>
											)}
										</div>
									</Stack>
									<Stack
										direction="row"
										spacing={2}
									>
										<Field
											name="mobilePhone"
											type="text"
											label="휴대폰번호"
											component={FormTextField}
											value={values.mobilePhone}
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.mobilePhone && Boolean(errors.mobilePhone)}
											helperText={touched.mobilePhone && errors.mobilePhone}
											size="small"
										/>
										<Field
											name="emailAddress"
											type="email"
											label="이메일"
											component={FormTextField}
											value={values.emailAddress}
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.emailAddress && Boolean(errors.emailAddress)}
											helperText={touched.emailAddress && errors.emailAddress}
											size="small"
										/>
									</Stack>
									<Stack
										direction="row"
										spacing={2}
									>
										<Field
											name="loginPW"
											type="password"
											label="종사자비밀번호"
											component={FormTextField}
											value={values.loginPW}
											onChange={handleChange}
											onBlur={handleBlur}
											autoComplete="new-password"
											error={touched.loginPW && Boolean(errors.loginPW)}
											helperText={touched.loginPW && errors.loginPW}
											size="small"
										/>
										<Field
											name="passwordConfirmation"
											type="password"
											label="종사자비밀번호 확인"
											component={FormTextField}
											value={values.passwordConfirmation}
											onChange={handleChange}
											onBlur={handleBlur}
											autoComplete="new-password"
											error={
												touched.passwordConfirmation && Boolean(errors.passwordConfirmation)
											}
											helperText={
												touched.passwordConfirmation && errors.passwordConfirmation
											}
											size="small"
										/>
									</Stack>
									<Stack
										direction="row"
										spacing={2}
									>
										<StackRow>
											<Field
												name="employeeType"
												value={values.employeeType}
												onChange={handleChange}
												onBlur={handleBlur}
												label="권한"
												component={FormSelect}
												error={touched.employeeType && Boolean(errors.employeeType)}
												size="small"
												fullWidth
												// helperText={touched.employeeType && errors.employeeType}
											>
												<MenuItem value="">선택하세요.</MenuItem>
												{empCdList.map((option) => (
													<MenuItem
														key={option.cmmnCd}
														value={option.cmmnCd}
													>
														{option.cmmnNm}
													</MenuItem>
												))}
											</Field>
										</StackRow>
										<StackRow>
											<Field
												name="approvalState"
												value={values.approvalState}
												onChange={handleChange}
												onBlur={handleBlur}
												label="승인상태"
												component={FormSelect}
												error={touched.approvalState && Boolean(errors.approvalState)}
												size="small"
												fullWidth
												// helperText={touched.employeeType && errors.employeeType}
											>
												<MenuItem value="">선택하세요.</MenuItem>
												{apCdList.map((option) => (
													<MenuItem
														key={option.cmmnCd}
														value={option.cmmnCd}
													>
														{option.cmmnNm}
													</MenuItem>
												))}
											</Field>
										</StackRow>
									</Stack>
									<Stack
										direction="row"
										spacing={2}
									>
										<StackRow>
											<Field
												name="departName"
												type="text"
												label="진료과"
												component={FormTextField}
												value={values.departName}
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.departName && Boolean(errors.departName)}
												helperText={touched.departName && errors.departName}
												size="small"
											/>
										</StackRow>
										<StackRow>
											<Field
												name="ward"
												type="text"
												label="병동"
												component={FormTextField}
												value={values.ward}
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.ward && Boolean(errors.ward)}
												helperText={touched.ward && errors.ward}
												size="small"
											/>
										</StackRow>
									</Stack>
									<Field
										name="memoText"
										type="text"
										label="메모"
										component={FormTextField}
										value={values.memoText}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.memoText && Boolean(errors.memoText)}
										helperText={touched.memoText && errors.memoText}
										size="small"
									/>
									{/* <FormControlLabel
										control={
											<Field
												component={FormSwitch}
												type="checkbox"
												name="sms"
												onChange={handleChange}
												onBlur={handleBlur}
												checked={values.sms}
											/>
										}
										label="SMS수신허용"
									/> */}
									<FormControlLabel
										control={
											<Field
												component={FormSwitch}
												type="checkbox"
												name="rep"
												onChange={handleChange}
												onBlur={handleBlur}
												checked={values.rep}
											/>
										}
										label="대표계정"
									/>
									<Stack
										direction="row"
										spacing={2}
										sx={{ display: 'flex', justifyContent: 'end' }}
									>
										<Button
											variant="contained"
											color="secondary"
											onClick={actionAddClose}
											startIcon={<CancelIcon />}
										>
											취소
										</Button>
										<LoadingButton
											type="submit"
											variant="contained"
											color="primary"
											startIcon={<SaveIcon />}
											loading={isSubmitting}
											disabled={isSubmitting}
										>
											{values.employeeID === 0 ? '저장' : '수정'}
										</LoadingButton>
									</Stack>
								</Stack>
							</Form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<ClientSearchPopupRef
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack sx={{ marginBottom: '1rem' }}>
				<PageBg>
					<Box
						component="form"
						sx={{
							'& > :not(style)': { m: 0.5 },
							display: 'flex',
							justifyContent: 'space-between',
						}}
						noValidate
						autoComplete="off"
						// onSubmit={handleSearchSubmit}
						onKeyUp={onKeyUp}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={6}
								sm={2}
								lg={3}
							>
								<TextField
									id="searchType"
									name="searchType"
									select
									label="검색 구분"
									size="small"
									value={searchType}
									onChange={handleSearchChange}
									fullWidth
								>
									{searchOptions.map((option) => (
										<MenuItem
											key={option.value}
											value={option.value}
										>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid
								item
								xs={6}
								sm={2}
								lg={3}
							>
								<TextField
									id="searchKeyword"
									name="searchKeyword"
									label="검색어"
									variant="outlined"
									size="small"
									type="text"
									value={searchKeyword}
									onChange={handleSearchChange}
									fullWidth
								/>
							</Grid>
							{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
								<Grid
									item
									xs={6}
									sm={2}
									lg={3}
								>
									<TextField
										label="거래처"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										aria-readonly={true}
										value={searchClient.clientName}
										onClick={clientPopupOpenAction}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Grid>
							)}
						</Grid>
					</Box>
				</PageBg>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden' }}
			>
				<Grid container>
					<Grid
						item
						lg={12}
						xs={12}
					>
						<div style={{ height: 'auto', width: '100%', overflow: 'auto' }}>
							<DataGridPremium
								rows={content}
								columns={[
									{
										field: 'employeeID',
										headerName: '종사자ID',
										type: 'number',
										width: 100,
										editable: false,
										hide: true,
									},
									{
										field: 'clientID',
										headerName: '고객ID',
										type: 'number',
										width: 100,
										editable: false,
										hide: true,
									},
									{
										field: 'employeeType',
										headerName: '권한',
										width: 100,
										type: 'singleSelect',
										editable: true,
										valueGetter: ({ row }: { row: GridValueGetterParams }) =>
											extractCmmnValue(row, empCdList, 'employeeType', 'cmmnNm'),
										valueSetter: (row: GridValueSetterParams) =>
											setCmmnName(row, empCdList, 'employeeType'),
										valueOptions: ({ row }) => {
											const options: string[] = []
											empCdList.map((item) => options.push(item.cmmnNm))
											return options
										},
									},
									{
										field: 'employeeName',
										headerName: '종사자명',
										type: 'string',
										width: 130,
										editable: true,
									},
									{
										field: 'loginID',
										headerName: '종사자ID',
										type: 'string',
										width: 130,
										editable: false,
									},
									{
										field: 'loginPW',
										headerName: '비밀번호',
										type: 'string',
										width: 130,
										renderCell: (params) => {
											if (params.row.emp2 === true) {
												// editable true
												return (
													<TextField
														name="loginPW"
														type="password"
														value={params.value ? params.value : ''}
														size="small"
														onChange={(e) => {
															const newValue = e.target.value
															handleCellEditCommit({
																id: params.id,
																field: params.field,
																value: newValue,
															})
														}}
														fullWidth
													/>
												)
											} else {
												return (
													<TextField
														name="loginPW"
														type="password"
														value={params.value ? params.value : ''}
														size="small"
														fullWidth
														disabled
														InputProps={{
															readOnly: true,
														}}
													/>
												)
											}
										},
									},
									{
										field: 'mobilePhone',
										headerName: '휴대폰번호',
										type: 'string',
										width: 150,
										renderCell: (params: GridRenderEditCellParams) => {
											if (params.row.emp2 === true) {
												return (
													<TextField
														name="mobilePhone"
														type="text"
														value={params.value ? params.value : ''}
														size="small"
														onChange={(e) => {
															const newValue = e.target.value
															handleCellEditCommit({
																id: params.id,
																field: params.field,
																value: newValue,
															})
														}}
														fullWidth
													/>
												)
											} else {
												return (
													<TextField
														name="mobilePhone"
														type="text"
														value={params.value ? params.value : ''}
														size="small"
														fullWidth
														disabled
														InputProps={{
															readOnly: true,
														}}
													/>
												)
											}
										},
									},
									{
										field: 'emailAddress',
										headerName: '이메일',
										type: 'string',
										width: 150,
										renderCell: (params: GridRenderEditCellParams) => {
											if (params.row.emp2 === true) {
												return (
													<TextField
														name="emailAddress"
														type="text"
														value={params.value ? params.value : ''}
														size="small"
														onChange={(e) => {
															const newValue = e.target.value
															handleCellEditCommit({
																id: params.id,
																field: params.field,
																value: newValue,
															})
														}}
														fullWidth
													/>
												)
											} else {
												return (
													<TextField
														name="emailAddress"
														type="text"
														value={params.value ? params.value : ''}
														size="small"
														fullWidth
														disabled
														InputProps={{
															readOnly: true,
														}}
													/>
												)
											}
										},
									},
									{
										field: 'memoText',
										headerName: '메모',
										type: 'string',
										width: 150,
										renderCell: (params: GridRenderEditCellParams) => {
											if (params.row.emp2 === true) {
												return (
													<TextField
														name="memoText"
														type="text"
														value={params.value ? params.value : ''}
														size="small"
														fullWidth
													/>
												)
											} else {
												return (
													<TextField
														name="memoText"
														type="text"
														value={params.value ? params.value : ''}
														size="small"
														fullWidth
														disabled
														InputProps={{
															readOnly: true,
														}}
													/>
												)
											}
										},
									},
									{
										field: 'approvalState',
										headerName: '승인상태',
										type: 'singleSelect',
										width: 150,
										editable: true,
										valueGetter: ({ row }: { row: GridValueGetterParams }) =>
											extractCmmnValue(row, apCdList, 'approvalState', 'cmmnNm'),
										valueSetter: (row: GridValueSetterParams) =>
											setCmmnName(row, apCdList, 'approvalState'),
										valueOptions: ({ row }) => {
											const options: string[] = []
											apCdList.map((item) => options.push(item.cmmnNm))
											return options
										},
									},
									{
										field: 'rep',
										headerName: '대표계정',
										width: 150,
										type: 'boolean',
										editable: true,
									},
									{
										field: 'departName',
										headerName: '진료과',
										width: 150,
										type: 'string',
										editable: true,
										renderCell: (params: GridRenderEditCellParams) => {
											return (
												<TextField
													name="departName"
													value={params.value ? params.value : ''}
													size="small"
													fullWidth
												/>
											)
										},
									},
									{
										field: 'ward',
										headerName: '병동',
										width: 150,
										type: 'string',
										editable: true,
										renderCell: (params: GridRenderEditCellParams) => {
											return (
												<TextField
													name="ward"
													value={params.value ? params.value : ''}
													size="small"
													fullWidth
												/>
											)
										},
									},
								]}
								checkboxSelection
								disableSelectionOnClick
								getRowId={(row) => row.employeeID}
								apiRef={apiRef}
								onRowClick={onRowClick}
								pageSize={size}
								onPageSizeChange={(newPageSize) => setSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
								pagination
								autoHeight={true}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								experimentalFeatures={{ newEditingApi: true }}
								rowModesModel={rowModesModel}
								onRowEditStart={handleRowEditStart}
								onRowEditStop={handleRowEditStop}
								onCellEditStop={onCellEditStop}
								onStateChange={onStateChange}
								processRowUpdate={processRowUpdate}
								onSelectionModelChange={selectChangeHandler}
								// onSelectionModelChange={newSelectionModel => {
								//   setSelectionModel(prevState => [...newSelectionModel])
								//   if (newSelectionModel.length > 0) {
								//     setDisabledSave(false)
								//     setDisabledDel(false)
								//   } else {
								//     setDisabledSave(true)
								//     setDisabledDel(true)
								//   }
								// }}
								selectionModel={selectionModel}
								onProcessRowUpdateError={handleProcessRowUpdateError}
								isCellEditable={(params) => params.row.username !== 'system'}
								components={{
									Toolbar: GridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={isLoading}
							/>
						</div>
					</Grid>
				</Grid>

				{!!snackbar && (
					<Snackbar
						open
						onClose={handleCloseSnackbar}
						autoHideDuration={6000}
					>
						<Alert
							{...snackbar}
							onClose={handleCloseSnackbar}
						/>
					</Snackbar>
				)}
			</Paper>
		</>
	)
}

export default Employee
