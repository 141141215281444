import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Alert,
	Stack,
	Grid,
	Paper,
	Snackbar,
	Box,
	Button,
	Switch,
	TextField,
	FormControlLabel,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	LinearProgress,
	Select,
	AlertProps,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridApi,
} from '@mui/x-data-grid-premium'
import { styled } from '@mui/material/styles'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

const EtcSearch = () => {
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {}
	const actionReset = () => {}

	// grid columns
	/**
	 * 미결과 항목 결과 컬럼
	 */
	const columns: GridColDef[] = [
		{
			field: 'InsuranceCode',
			headerName: '보험코드',
			type: 'string',
			width: 100,
			editable: false,
		},
		{
			field: 'TestName',
			headerName: '검사항목',
			type: 'string',
			width: 300,
			editable: false,
		},
		{
			field: 'ResultData',
			headerName: '결과보고예정일',
			type: 'string',
			width: 130,
			editable: false,
		},
	]

	// grid state
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(10)
	const [gridPage, setGridPage] = useState(0)
	const [gridRowCount, setGridRowCount] = useState(0)

	// grid row click event
	const onRowClick = (e: GridRowModel) => {}

	// 조회조건
	const [search, setSearch] = useState({
		keyword: '',
	})
	const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {}

	useEffect(() => {}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<Stack sx={{ marginBottom: '1rem' }}>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSearchSubmit}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={6}
								sm={3}
							>
								<TextField
									label="검색어"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.keyword}
									onChange={(e) => {
										setSearch({
											keyword: e.target.value,
										})
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</PageBg>
			</Stack>
			<PageBg style={{ marginTop: '1rem' }}>
				<Paper
					elevation={0}
					sx={{ width: '100%', overflow: 'hidden' }}
				>
					<Box>
						<Typography
							variant="subtitle1"
							component="h3"
						>
							수진자 리스트
						</Typography>
						<Box
							sx={{
								height: '500px',
								width: '100%',
								overflow: 'auto',
								marginTop: '1rem',
								'@media (max-width: 1199px)': {
									height: '400px',
								},
							}}
						>
							<DataGridPremium
								rows={gridRows}
								getRowId={(row) => row.ReceptionID}
								columns={columns}
								pageSize={gridPageSize}
								onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								pagination
								autoHeight={false}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								components={{
									Toolbar: GridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={gridLoading}
								selectionModel={gridSelectionModel}
								onRowClick={onRowClick}
							/>
						</Box>
					</Box>
				</Paper>
			</PageBg>
		</>
	)
}

export default EtcSearch
