import { createSlice } from '@reduxjs/toolkit'

export const envSettingSlice = createSlice({
	name: 'envSetting',
	initialState: {
		info: {},
	},
	reducers: {
		setEnvSetting: (state, actions) => {
			state.info = actions.payload
		},
		removeEnvSetting: (state) => {
			state.info = {}
		},
	},
})

export const { setEnvSetting, removeEnvSetting } = envSettingSlice.actions
export default envSettingSlice.reducer
export const selectEnvSettingInfo = (state: any) => state.envSetting
