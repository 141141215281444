export const headerOcsOntic = [
	{
		field: 'SEQ',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '접수번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'SPCCD',
		headerName: '검체코드',
		type: 'string',
		width: 90,
		editable: false,
	},
	{
		field: 'SPCNM',
		headerName: '검체명',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '수진자명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 130,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'BED',
		headerName: '병동',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'ORDERNO',
		headerName: '오더ID',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '처방일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'DOCTOR',
		headerName: '진료의',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'ACPTDD',
		headerName: '접수일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'ACPTTIME',
		headerName: '접수시간',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'PHONE',
		headerName: '핸드폰',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'cmmnNm',
		headerName: '상태',
		type: 'string',
		width: 70,
		editable: false,
	},
]
