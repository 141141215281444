import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpMedi4u: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'EXTRNO',
		headerName: '검사ID',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '처방일자',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '환자이름',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'EXTRTYPE',
		headerName: '유형',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '명칭',
		type: 'string',
		width: 300,
		editable: false,
	},
	{
		field: 'RSLT',
		headerName: '검사결과',
		type: 'string',
		width: 200,
		editable: false,
	},
	{
		field: 'RSLTM',
		headerName: '서술결과',
		type: 'string',
		width: 200,
		editable: false,
	},
	{
		field: 'JUDG',
		headerName: 'H/L',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'UNIT',
		headerName: '단위',
		type: 'string',
		width: 60,
		editable: false,
	},
	{
		field: 'RVAL',
		headerName: '참고치',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'SENDDT',
		headerName: '위탁일자',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
