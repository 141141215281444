// OCS_BITEMR, OCS_FRIPOS 공용
export const headerOcsBitEmr = [
	{
		field: 'SEQ',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '수진자명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'AGE_SEX',
		headerName: '나이/성별',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'DEPT_BED',
		headerName: '진료과/병동',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '처방일자',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '명칭',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'INSUCD',
		headerName: '청구코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '식별번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXCELDATE',
		headerName: 'EXCEL전환일',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SPCNO',
		headerName: '검체번호',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'SPCNM',
		headerName: '검체명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'BED',
		headerName: '병동',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'LABYN',
		headerName: '수탁여부',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'REMARK',
		headerName: '처방메모',
		type: 'string',
		width: 130,
		editable: false,
	},
	{
		field: 'cmmnNm',
		headerName: '상태',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'BAR_CODE',
		headerName: '바코드',
		type: 'string',
		width: 130,
		editable: false,
	},
]
