import { createSlice } from '@reduxjs/toolkit'

export const editorHtmlSlice = createSlice({
  name: 'editorHtml',
  initialState: {
    content: '',
  },
  reducers: {
    setEditorHtml: (state, actions) => {
      state.content = actions.payload
    },
    removeEditorHtml: state => {
      state.content = ''
    },
  },
})

export const { setEditorHtml, removeEditorHtml } = editorHtmlSlice.actions
export default editorHtmlSlice.reducer
export const selectEditorHtml = (state: any) => state.editorHtml.content
