import { createSlice } from '@reduxjs/toolkit'

export const gnbToggleSlice = createSlice({
	name: 'gnbToggle',
	initialState: {
		isOpen: false,
	},
	reducers: {
		setGnbToggle: (state, action) => {
			state.isOpen = action.payload
		},
	},
})

export const { setGnbToggle } = gnbToggleSlice.actions
export default gnbToggleSlice.reducer
export const selectGnbToggle = (state: any) => state.gnbToggle.isOpen
