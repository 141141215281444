import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Alert,
	Stack,
	Grid,
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	Dialog,
	DialogContent,
	DialogActions,
	LinearProgress,
	Select,
	Card,
	CardContent,
	AppBar,
	Toolbar,
	IconButton,
	useMediaQuery,
	TextField,
	Breadcrumbs,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'

import moment, { locale } from 'moment'
import 'moment/locale/ko'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridApi,
	GridDensity,
	useGridApiRef,
	GridValidRowModel,
	GridCellEditCommitParams,
} from '@mui/x-data-grid-premium'
import { useTheme } from '@mui/material/styles'
import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'
import { getSettings } from 'helpers/storage'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { useDropzone } from 'react-dropzone'
import {
	alertModal,
	compareDate,
	confirmAlert,
	isValidDateType,
} from 'util/util'
import {
	fileDownloadByFileName,
	syncRequest,
	syncList,
	tableName,
	deleteSync,
	syncUploadFile,
	jsonUpload,
	textDownload,
} from 'api/api'
import TransitionEffect from 'components/TransitionEffect'
import userInfo, { selectuserInfo } from 'hooks/userInfo'

import { headerOcsKChart } from './gridHeaders/headerOcsKChart'
import { headerOcsMedi4u } from './gridHeaders/headerOcsMedi4u'
import { headerOcsAmis } from './gridHeaders/headerOcsAmis'
import { headerOcsByungcom } from './gridHeaders/headerOcsByungcom'
import { headerOcsBitEmr } from './gridHeaders/headerOcsBitEmr'
import { headerOcsBitEmr2 } from './gridHeaders/headerOcsBitEmr2'
import { headerOcsBrain } from './gridHeaders/headerOcsBrain'
import { headerOcsEonm } from './gridHeaders/headerOcsEonm'
import { headerOcsOntic } from './gridHeaders/headerOcsOntic'
import { headerOcsHwasan } from './gridHeaders/headerOcsHwasan'
import { headerOcsEHealth } from './gridHeaders/headerOcsEHealth'
import { headerOcsMdjin } from './gridHeaders/headerOcsMdjin'
import { headerOcsEmc } from './gridHeaders/headerOcsEmc'
import { headerOcsNeo } from './gridHeaders/headerOcsNeo'
import { headerOcsMsysTech } from './gridHeaders/headerOcsMsysTech'

import { headerOcsTmpMdjin } from './gridTmpHeaders/headerOcsTmpMdjin'
import { headerOcsTmpEHealth } from './gridTmpHeaders/headerOcsTmpEHealth'
import { headerOcsTmpBrain } from './gridTmpHeaders/headerOcsTmpBrain'
import { headerOcsTmpHwasan } from './gridTmpHeaders/headerOcsTmpHwasan'
import { headerOcsTmpByungcom } from './gridTmpHeaders/headerOcsTmpByungcom'
import { headerOcsTmpMedi4u } from './gridTmpHeaders/headerOcsTmpMedi4u'
import { headerOcsTmpBitEmr } from './gridTmpHeaders/headerOcsTmpBitEmr'
import { headerOcsTmpBitEmr2 } from './gridTmpHeaders/headerOcsTmpBitEmr2'
import { headerOcsTmpKChart } from './gridTmpHeaders/headerOcsTmpKChart'
import { headerOcsTmpEmc } from './gridTmpHeaders/headerOcsTmpEmc'
import { headerOcsTmpEonm } from './gridTmpHeaders/headerOcsTmpEonm'
import { headerOcsTmpAmis } from './gridTmpHeaders/headerOcsTmpAmis'
import { headerOcsTmpOntic } from './gridTmpHeaders/headerOcsTmpOntic'
import { headerOcsTmpNeo } from './gridTmpHeaders/headerOcsTmpNeo'
import { headerOcsTmpMsysTech } from './gridTmpHeaders/headerOcsTmpMsysTech'

import { keysInIterator, valuesInIterator } from 'util/objectUtil'

import { read, utils } from 'xlsx'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { forEach } from 'jszip'

import { hexToRgba } from 'util/util'

import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const locales = ['ko'] as const

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const RadioWrapper = styled('div')(({ theme }) => ({
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(255,255,255,0.23)'
			: '1px solid rgba(0,0,0,0.23)',
	display: 'flex',
	height: 37,
	alignItems: 'center',
	justifyContent: 'flex-start',
	paddingLeft: '1rem',
	borderRadius: '0.3rem',
	position: 'relative',
	'.labelText': {
		position: 'absolute',
		top: '0',
		left: '0',
		fontSize: '0.8571428571428571rem',
		fontWeight: 400,
		transform: 'translate(14px, -11px) scale(0.75)',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		background: theme.palette.mode === 'dark' ? '#323036' : '#fff',
		paddingLeft: '0.2rem',
		paddingRight: '0.2rem',
		color:
			theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
	},
}))

const TestSync = () => {
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)
	const navigate = useNavigate()
	const currentUser = useSelector(selectuserInfo)

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const env = getSettings()

	const apiRef = useGridApiRef()
	const tempApiRef = useGridApiRef()

	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')
	// 사용자 정보

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const [statusList, setStatusList] = useState<any[]>([])
	const [downloadList, setDownloadList] = useState<any[]>([])

	const [disabledSync, setDisabledSync] = useState(false)
	const [disabledDownload, setDisabledDownload] = useState(false)
	const [disabledUpload, setDisabledUpload] = useState(false)
	const [downloadType, setDownloadType] = useState<string>('')

	const actionAdd = () => {
		setUploadOpen(true)
	}
	const actionSave = () => {
		const keyArrays = keysInIterator(apiRef.current.getSelectedRows())
		const valuesArrays = valuesInIterator(apiRef.current.getSelectedRows())
		if (
			valuesArrays.length > 0 &&
			keyArrays[0] === valuesArrays[0].SEQ &&
			gridSelectionModel[0] === valuesArrays[0].SEQ
		) {
			console.log(valuesArrays[0])
		}
	}
	const actionDel = () => {
		console.log(gridSelectionModel)
		if (gridSelectionModel) {
			confirmAlert(
				'삭제하시겠습니까?',
				() => {
					const seq = gridSelectionModel[0] as number
					deleteSync(seq).then((res) => {
						console.log(res)
						if (res.success) {
							alertModal('삭제되었습니다.', 'success', () => {
								setGridSelectionModel([])
								getSyncList()
								setDisabledDel(true)
							})
						}
					})
				},
				() => {}
			)
		}
	}
	const actionSearch = () => {
		getSyncList()
	}
	const actionReset = () => {
		setSearch({
			keyword: '',
			status: '30',
			downloadStatus: '30',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(1, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			searchType: 'R',
		})
		setDateRange('1d')
	}

	const [gridHeaderName, setGridHeaderName] = useState('')

	let columns: GridColDef[] = []

	if (gridHeaderName === 'OCS_MEDI4U') {
		columns = headerOcsMedi4u
	} else if (gridHeaderName === 'OCS_AMIS') {
		columns = headerOcsAmis
	} else if (gridHeaderName === 'OCS_BYUNGCOM') {
		columns = headerOcsByungcom
	} else if (gridHeaderName === 'OCS_KCHART') {
		columns = headerOcsKChart
	} else if (gridHeaderName === 'OCS_BITEMR') {
		columns = headerOcsBitEmr
	} else if (gridHeaderName === 'OCS_BITEMR2') {
		columns = headerOcsBitEmr2
	} else if (gridHeaderName === 'OCS_BRAIN') {
		columns = headerOcsBrain
	} else if (gridHeaderName === 'OCS_EONM') {
		columns = headerOcsEonm
	} else if (gridHeaderName === 'OCS_ONTIC') {
		columns = headerOcsOntic
	} else if (gridHeaderName === 'OCS_HWASAN') {
		columns = headerOcsHwasan
	} else if (gridHeaderName === 'OCS_EHEALTH') {
		columns = headerOcsEHealth
	} else if (gridHeaderName === 'OCS_MDJIN') {
		columns = headerOcsMdjin
	} else if (gridHeaderName === 'OCS_EMC') {
		columns = headerOcsEmc
	} else if (gridHeaderName === 'OCS_NEO') {
		columns = headerOcsNeo
	} else if (gridHeaderName === 'OCS_MSYSTECH') {
		columns = headerOcsMsysTech
	} else {
		columns = [
			{
				field: 'SEQ',
				headerName: '번호',
				type: 'string',
				width: 80,
				editable: false,
			},
		]
	}

	// grid state
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)
	const [gridPage, setGridPage] = useState(0)
	const [gridRowCount, setGridRowCount] = useState(0)

	const [dateRange, setDateRange] = useState('1d')

	// grid row click event
	const onRowClick = (e: GridRowModel) => {
		console.log(e.row)
		setGridSelectionModel([e.id])
		if (e.id && e.row.RItemID === 0 && e.row.TESTSTATUS === '10') {
			setDisabledDel(false)
			//setDisabledSave(false)
		} else {
			setDisabledDel(true)
			//setDisabledSave(true)
		}
	}

	// 조회조건
	const [search, setSearch] = useState({
		keyword: '',
		status: '30',
		downloadStatus: '30',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(1, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		searchType: 'R',
	})
	const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {}

	// 첨부파일 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0]) {
			setFileObject(acceptedFiles[0])
		}
	}, [])

	const [tmpColumns, setTmpColumns] = useState<any[]>([])
	const [tmpIsOpen, setTmpIsOpen] = useState(false)
	const [tmpDataList, setTmpDataList] = useState<any[]>([])
	const [tmpGridSelectionModel, setTmpGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [tmpIsDuplicate, setTmpIsDuplicate] = useState(false)

	// grid row click event
	const onTmpRowClick = (e: GridRowModel) => {
		console.log(e.row)
		setTmpGridSelectionModel([e.id])
	}

	const utf8Encode = (str: string) => {
		const encoder = new TextEncoder()
		const data = encoder.encode(str)
		const utf8Array = Array.from(data)
		return utf8Array
	}

	const uploadAction = () => {
		console.log(fileObject)

		const file = fileObject as File

		if (file) {
			// checkAfterUpload()

			const reader = new FileReader()
			reader.onload = (e) => {
				const f = new Uint8Array(e.target?.result as ArrayBuffer)
				const wb = read(f)
				let data = null
				if (gridHeaderName === 'OCS_EHEALTH') {
					data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
						range: 3,
						raw: false,
					})
				} else if (gridHeaderName === 'OCS_NEO') {
					data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
						raw: false,
						header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
					})
				} else if (gridHeaderName === 'OCS_AMIS') {
					data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
						raw: false,
						header: [
							'A',
							'B',
							'C',
							'D',
							'E',
							'F',
							'G',
							'H',
							'I',
							'J',
							'K',
							'L',
							'M',
							'N',
						],
					})
				} else if (gridHeaderName === 'OCS_EONM') {
					data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
						raw: false,
						header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
					})
				} else if (gridHeaderName === 'OCS_EMC') {
					data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
						raw: false,
						header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
					})
				} else {
					data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
						raw: false,
					})
				}

				let tmpIsDup = false

				console.log('gridHeaderName', gridHeaderName)

				if (data.length > 0) {
					let tmpList: any = []
					if (gridHeaderName === 'OCS_MDJIN') {
						// headerOcsTmpMdjin
						setTmpColumns(headerOcsTmpMdjin)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['처방일자']) {
								tmp.EXTRDD = item?.['처방일자'] || ''
							}
							if (item?.['검체번호']) {
								tmp.EXTRNO = item?.['검체번호'] || ''
							}
							if (item?.['등록번호']) {
								tmp.CHARTNO = item?.['등록번호'] || ''
							}
							if (item?.['성명']) {
								tmp.NM = item?.['성명'] || ''
							}
							if (item?.['주민번호']) {
								tmp.RGSTNO = item?.['주민번호'] || ''
							}
							if (item?.['나이']) {
								tmp.AGE = item?.['나이'] || ''
							}
							if (item?.['성별']) {
								tmp.SEX = item?.['성별'] || ''
							}
							if (item?.['진료과']) {
								tmp.DEPT_DOCTOR = item?.['진료과'] || ''
							}
							if (item?.['병실']) {
								tmp.BED = item?.['병실'] || ''
							}
							if (item?.['처방번호']) {
								tmp.ORDERNO = item?.['처방번호'] || ''
							}
							if (item?.['코드']) {
								tmp.EXAMCD = item?.['코드'] || ''
							}
							if (item?.['검사명']) {
								tmp.EXAMNM = item?.['검사명'] || ''
							}
							if (item?.['검체명']) {
								tmp.SPCNM = item?.['검체명'] || ''
							}
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.ORDERNO
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_EHEALTH') {
						setTmpColumns(headerOcsTmpEHealth)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['Hos.No']) {
								tmp.CHARTNO = item?.['Hos.No'] || ''
							}
							if (item?.['적용일']) {
								tmp.EXTRDD = item?.['적용일'] || ''
							}
							if (item?.['코드']) {
								tmp.EXAMCD = item?.['코드'] || ''
							}
							if (item?.['Description']) {
								tmp.EXAMNM = item?.['Description'] || ''
							}
							if (item?.['의사']) {
								tmp.DOCTORID = item?.['의사'] || ''
							}
							if (item?.['과']) {
								tmp.DEPT = item?.['과'] || ''
							}
							if (item?.['입력자']) {
								tmp.USERID = item?.['입력자'] || ''
							}
							if (item?.['입력시간']) {
								tmp.ACPTTIME = item?.['입력시간'] || ''
							}
							if (item?.['입력일']) {
								tmp.ACPTDD = item?.['입력일'] || ''
							}
							if (item?.['환자명']) {
								tmp.NM = item?.['환자명'] || ''
							}
							if (item?.['보험코드']) {
								tmp.INSUCD = item?.['보험코드'] || ''
							}
							if (item?.['의사명']) {
								tmp.DOCTOR = item?.['의사명'] || ''
							}
							if (item?.['생년월일']) {
								tmp.RGSTNO = item?.['생년월일'] || ''
							}
							if (item?.['임신주수']) {
								tmp.GSTATE = item?.['임신주수'] || ''
							}
							if (item?.['체중']) {
								tmp.WEIGHT = item?.['체중'] || ''
							}
							if (item?.['태아수']) {
								tmp.CNT = item?.['태아수'] || ''
							}
							tmp.key = tmp.EXTRDD + '_' + tmp.CHARTNO + '_' + tmp.EXAMCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_BRAIN') {
						setTmpColumns(headerOcsTmpBrain)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['의뢰일자']) {
								tmp.EXTRDD = item?.['의뢰일자'] || ''
							}
							if (item?.['검체번호']) {
								tmp.EXTRNO = item?.['검체번호'] || ''
							}
							if (item?.['차트번호']) {
								tmp.CHARTNO = item?.['차트번호'] || ''
							}
							if (item?.['수진자명']) {
								tmp.NM = item?.['수진자명'] || ''
							}
							if (item?.['주민번호']) {
								tmp.RGSTNO = item?.['주민번호'] || ''
							}
							if (item?.['나이']) {
								tmp.AGE = item?.['나이'] || ''
							}
							if (item?.['Sex']) {
								tmp.SEX = item?.['Sex'] || ''
							}
							if (item?.['진료과']) {
								tmp.DEPT = item?.['진료과'] || ''
							}
							if (item?.['병동']) {
								tmp.BED = item?.['병동'] || ''
							}
							if (item?.['처방번호']) {
								tmp.ORDERNO = item?.['처방번호'] || ''
							}
							if (item?.['검사코드']) {
								tmp.EXAMCD = item?.['검사코드'] || ''
							}
							if (item?.['검사명']) {
								tmp.EXAMNM = item?.['검사명'] || ''
							}
							if (item?.['참고사항']) {
								tmp.REMARK = item?.['참고사항'] || ''
							}
							if (item?.['검사파트']) {
								tmp.EXAMPART = item?.['검사파트'] || ''
							}
							if (item?.['처방시행일']) {
								tmp.SENDDT = item?.['처방시행일'] || ''
							}
							if (item?.['접수일자']) {
								tmp.ACPTDD = item?.['접수일자'] || ''
							}
							if (item?.['혈액형']) {
								tmp.BLDTYPE = item?.['혈액형'] || ''
							}
							tmp.key =
								tmp.EXTRDD + '_' + tmp.EXTRNO + '_' + tmp.CHARTNO + '_' + tmp.EXAMCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_HWASAN') {
						setTmpColumns(headerOcsTmpHwasan)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['등록번호']) {
								tmp.CHARTNO = item?.['등록번호'] || ''
							}
							if (item?.['성명']) {
								tmp.NM = item?.['성명'] || ''
							}
							if (item?.['나이/성별']) {
								tmp.AGE_SEX = item?.['나이/성별'] || ''
							}
							if (item?.['진료과/병실']) {
								tmp.DEPT_BED = item?.['진료과/병실'] || ''
							}
							if (item?.['접수일']) {
								tmp.EXTRDD = item?.['접수일'] || ''
							}
							if (item?.['검사코드']) {
								tmp.EXAMCD = item?.['검사코드'] || ''
							}
							if (item?.['검사명']) {
								tmp.EXAMNM = item?.['검사명'] || ''
							}
							if (item?.['검체코드']) {
								tmp.SPCCD = item?.['검체코드'] || ''
							}
							if (item?.['검체명']) {
								tmp.SPCNM = item?.['검체명'] || ''
							}
							if (item?.['주민번호']) {
								tmp.RGSTNO = item?.['주민번호'] || ''
							}
							if (item?.['검체번호']) {
								tmp.EXTRNO = item?.['검체번호'] || ''
							}
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_BYUNGCOM') {
						setTmpColumns(headerOcsTmpByungcom)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['수진자명']) {
								tmp.NM = item?.['수진자명'] || ''
							}
							if (item?.['처방일자']) {
								tmp.EXTRDD = item?.['처방일자'] || ''
							}
							if (item?.['차트번호']) {
								tmp.CHARTNO = item?.['차트번호'] || ''
							}
							if (item?.['성별']) {
								tmp.SEX = item?.['성별'] || ''
							}
							if (item?.['나이']) {
								tmp.AGE = item?.['나이'] || ''
							}
							if (item?.['주민번호']) {
								tmp.RGSTNO = item?.['주민번호'] || ''
							}
							if (item?.['병동']) {
								tmp.BED = item?.['병동'] || ''
							}
							if (item?.['의사명']) {
								tmp.DOCTOR = item?.['의사명'] || ''
							}
							if (item?.['진료과']) {
								tmp.DEPT = item?.['진료과'] || ''
							}
							if (item?.['검사코드']) {
								tmp.EXAMCD = item?.['검사코드'] || ''
							}
							if (item?.['검사명']) {
								tmp.EXAMNM = item?.['검사명'] || ''
							}
							if (item?.['PK']) {
								tmp.EXTRNO = item?.['PK'] || ''
							}
							tmp.key =
								tmp.EXTRDD + '_' + tmp.EXTRNO + '_' + tmp.CHARTNO + '_' + tmp.EXAMCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_MEDI4U') {
						setTmpColumns(headerOcsTmpMedi4u)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['검사ID']) tmp.EXTRNO = item?.['검사ID'] || ''
							if (item?.['처방일자']) tmp.EXTRDD = item?.['처방일자'] || ''
							if (item?.['차트번호']) tmp.CHARTNO = item?.['차트번호'] || ''
							if (item?.['환자이름']) tmp.NM = item?.['환자이름'] || ''
							if (item?.['주민번호']) tmp.RGSTNO = item?.['주민번호'] || ''
							if (item?.['유형']) tmp.EXTRTYPE = item?.['유형'] || ''
							if (item?.['코드']) tmp.EXAMCD = item?.['코드'] || ''
							if (item?.['명칭']) tmp.EXAMNM = item?.['명칭'] || ''
							if (item?.['검사결과']) tmp.RSLT = item?.['검사결과'] || ''
							if (item?.['서술결과']) tmp.RSLTM = item?.['서술결과'] || ''
							if (item?.['H/L']) tmp.JUDG = item?.['H/L'] || ''
							if (item?.['단위']) tmp.UNIT = item?.['단위'] || ''
							if (item?.['참고치']) tmp.RVAL = item?.['참고치'] || ''
							if (item?.['위탁일자']) tmp.SENDDT = item?.['위탁일자'] || ''
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_BITEMR') {
						setTmpColumns(headerOcsTmpBitEmr)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['차트번호']) tmp.CHARTNO = item?.['차트번호'] || ''
							if (item?.['성명']) tmp.NM = item?.['성명'] || ''
							if (item?.['나이/성별']) tmp.AGE_SEX = item?.['나이/성별'] || ''
							if (item?.['과목/병실']) tmp.DEPT_BED = item?.['과목/병실'] || ''
							if (item?.['검사일시']) {
								let extrdd = item?.['검사일시'] || ''
								// if extrdd contains . then replace with -
								if (extrdd.includes('.')) {
									extrdd = extrdd.replace('.', '-')
									// extrdd split by -
									const extrddSplit = extrdd.split('-')
									// if extrddSplit[1] length is 1 then add 0 before
									if (extrddSplit[1].length === 1) {
										extrddSplit[1] = '0' + extrddSplit[1]
									}
									// if extrddSplit[2] length is 1 then add 0 before
									if (extrddSplit[2].length === 1) {
										extrddSplit[2] = '0' + extrddSplit[2]
									}
									// join extrddSplit with -
									extrdd = extrddSplit[0] + '-' + extrddSplit[1] + '-' + extrddSplit[2]
								} else if (extrdd.includes('/')) {
									// extrdd split by /
									const extrddSplit = extrdd.split('/')
									if (extrddSplit[0].length === 1) {
										extrddSplit[0] = '0' + extrddSplit[0]
									}
									if (extrddSplit[1].length === 1) {
										extrddSplit[1] = '0' + extrddSplit[1]
									}
									if (extrddSplit[2].length === 2) {
										extrddSplit[2] = '20' + extrddSplit[2]
									}
									extrdd = extrddSplit[2] + '-' + extrddSplit[0] + '-' + extrddSplit[1]
								}
								tmp.EXTRDD = extrdd
							}
							if (item?.['사용코드']) tmp.EXAMCD = item?.['사용코드'] || ''
							if (item?.['검사명칭']) tmp.EXAMNM = item?.['검사명칭'] || ''
							if (item?.['청구코드']) tmp.INSUCD = item?.['청구코드'] || ''
							if (item?.['식별번호']) tmp.EXTRNO = item?.['식별번호'] || ''
							if (item?.['Excel전환일(삼광)']) {
								let excelDate = item?.['Excel전환일(삼광)'] || ''
								// if extrdd contains . then replace with -
								if (excelDate.includes('.')) {
									excelDate = excelDate.replace('.', '-')
									// extrdd split by -
									const extrddSplit = excelDate.split('-')
									// if extrddSplit[1] length is 1 then add 0 before
									if (extrddSplit[1].length === 1) {
										extrddSplit[1] = '0' + extrddSplit[1]
									}
									// if extrddSplit[2] length is 1 then add 0 before
									if (extrddSplit[2].length === 1) {
										extrddSplit[2] = '0' + extrddSplit[2]
									}
									// join extrddSplit with -
									excelDate =
										extrddSplit[0] + '-' + extrddSplit[1] + '-' + extrddSplit[2]
								}
								tmp.EXCELDATE = excelDate
							}
							if (item?.['Excel전환일']) {
								let excelDate = item?.['Excel전환일'] || ''
								// if extrdd contains . then replace with -
								if (excelDate.includes('.')) {
									excelDate = excelDate.replace('.', '-')
									// extrdd split by -
									const extrddSplit = excelDate.split('-')
									// if extrddSplit[1] length is 1 then add 0 before
									if (extrddSplit[1].length === 1) {
										extrddSplit[1] = '0' + extrddSplit[1]
									}
									// if extrddSplit[2] length is 1 then add 0 before
									if (extrddSplit[2].length === 1) {
										extrddSplit[2] = '0' + extrddSplit[2]
									}
									// join extrddSplit with -
									excelDate =
										extrddSplit[0] + '-' + extrddSplit[1] + '-' + extrddSplit[2]
								}
								tmp.EXCELDATE = excelDate
							}
							if (item?.['검체번호(녹십자)'])
								tmp.SPCNO = item?.['검체번호(녹십자)'] || ''
							if (item?.['검체번호']) tmp.SPCNO = item?.['검체번호'] || ''
							if (item?.['검체코드(녹십자)'])
								tmp.SPCCD = item?.['검체코드(녹십자)'] || ''
							if (item?.['검체코드']) tmp.SPCCD = item?.['검체코드'] || ''
							if (item?.['검체명(녹십자)']) tmp.SPCNM = item?.['검체명(녹십자)'] || ''
							if (item?.['검체명']) tmp.SPCNM = item?.['검체명'] || ''
							if (item?.['주민번호(녹십자)'])
								tmp.RGSTNO = item?.['주민번호(녹십자)'] || ''
							if (item?.['주민번호']) tmp.RGSTNO = item?.['주민번호'] || ''
							if (item?.['성별(녹십자)']) tmp.SEX = item?.['성별(녹십자)'] || ''
							if (item?.['성별']) tmp.SEX = item?.['성별'] || ''
							if (item?.['병동(녹십자)']) tmp.BED = item?.['병동(녹십자)'] || ''
							if (item?.['병동']) tmp.BED = item?.['병동'] || ''
							if (item?.['진료과(녹십자)']) tmp.DEPT = item?.['진료과(녹십자)'] || ''
							if (item?.['진료과']) tmp.DEPT = item?.['진료과'] || ''
							if (item?.['수탁여부(이원)']) tmp.LABYN = item?.['수탁여부(이원)'] || ''
							if (item?.['수탁여부']) tmp.LABYN = item?.['수탁여부'] || ''
							if (item?.['나이(SCL)']) tmp.AGE = item?.['나이(SCL)'] || ''
							if (item?.['나이']) tmp.AGE = item?.['나이'] || ''
							if (item?.['성별(SCL)']) tmp.SEX2 = item?.['성별(SCL)'] || ''
							if (item?.['성별']) tmp.SEX2 = item?.['성별'] || ''
							if (item?.['처방메모']) tmp.REMARK = item?.['처방메모'] || ''
							// if (item?.['바코드']) tmp.REMARK = item?.['바코드'] || ''
							// 주치의, 바코드 문의해봐야함...
							tmp.key =
								tmp.EXRTDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_BITEMR2') {
						setTmpColumns(headerOcsTmpBitEmr2)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['차트번호']) tmp.CHARTNO = item?.['차트번호'] || ''
							if (item?.['성명']) tmp.NM = item?.['성명'] || ''
							if (item?.['나이/성별']) tmp.AGE_SEX = item?.['나이/성별'] || ''
							if (item?.['과목/병실']) tmp.DEPT_BED = item?.['과목/병실'] || ''
							if (item?.['검사일시']) tmp.EXTRDD = item?.['검사일시'] || ''
							if (item?.['사용코드']) tmp.EXAMCD = item?.['사용코드'] || ''
							if (item?.['검사명칭']) tmp.EXAMNM = item?.['검사명칭'] || ''
							if (item?.['청구코드']) tmp.INSUCD = item?.['청구코드'] || ''
							if (item?.['식별번호']) tmp.EXTRNO = item?.['식별번호'] || ''
							if (item?.['Excel전환일(삼광)'])
								tmp.EXCELDATE = item?.['Excel전환일(삼광)'] || ''
							if (item?.['검체번호(녹십자)'])
								tmp.SPCNO = item?.['검체번호(녹십자)'] || ''
							if (item?.['검체코드(녹십자)'])
								tmp.SPCCD = item?.['검체코드(녹십자)'] || ''
							if (item?.['검체명(녹십자)']) tmp.SPCNM = item?.['검체명(녹십자)'] || ''
							if (item?.['주민번호(녹십자)'])
								tmp.RGSTNO = item?.['주민번호(녹십자)'] || ''
							if (item?.['성별(녹십자)']) tmp.SEX = item?.['성별(녹십자)'] || ''
							if (item?.['병동(녹십자)']) tmp.BED = item?.['병동(녹십자)'] || ''
							if (item?.['진료과(녹십자)']) tmp.DEPT = item?.['진료과(녹십자)'] || ''
							if (item?.['수탁여부(이원)']) tmp.LABYN = item?.['수탁여부(이원)'] || ''
							if (item?.['나이(SCL)']) tmp.AGE = item?.['나이(SCL)'] || ''
							if (item?.['성별(SCL)']) tmp.SEX2 = item?.['성별(SCL)'] || ''
							tmp.key =
								tmp.EXRTDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_KCHART') {
						setTmpColumns(headerOcsTmpKChart)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['검사ID']) tmp.EXTRNO = item?.['검사ID'] || ''
							if (item?.['접수ID']) tmp.ACPTNO = item?.['접수ID'] || ''
							if (item?.['접수일자']) tmp.EXTRDD = item?.['접수일자'] || ''
							if (item?.['시간']) tmp.ACPTTIME = item?.['시간'] || ''
							if (item?.['환자이름']) tmp.NM = item?.['환자이름'] || ''
							if (item?.['차트번호'] || item?.['챠트번호']) {
								const data = item?.['챠트번호'] || item?.['차트번호'] || ''
								tmp.CHARTNO = data || ''
							}
							// tmp.CHARTNO = item?.['차트번호'] || ''
							// if (item?.['챠트번호']) tmp.CHARTNO = item?.['챠트번호'] || ''
							if (item?.['성별']) tmp.SEX = item?.['성별'] || ''
							if (item?.['나이']) tmp.AGE = item?.['나이'] || ''
							if (item?.['주민번호']) tmp.RGSTNO = item?.['주민번호'] || ''
							if (item?.['유형']) tmp.EXTRTYPE = item?.['유형'] || ''
							if (item?.['진료실']) tmp.DEPT = item?.['진료실'] || ''
							if (item?.['담당의']) tmp.DOCTOR = item?.['담당의'] || ''
							if (item?.['병실명']) tmp.BED = item?.['병실명'] || ''
							if (item?.['코드']) tmp.EXAMCD = item?.['코드'] || ''
							if (item?.['명칭']) tmp.EXAMNM = item?.['명칭'] || ''
							if (item?.['의뢰일자']) tmp.ACPTDD = item?.['의뢰일자'] || ''
							if (item?.['검사결과']) tmp.RSLT = item?.['검사결과'] || ''
							if (item?.['서술결과']) tmp.RSLTM = item?.['서술결과'] || ''
							if (item?.['하이로우']) tmp.JUDG = item?.['하이로우'] || ''
							if (item?.['단위']) tmp.UNIT = item?.['단위'] || ''
							if (item?.['참고치']) tmp.RVAL = item?.['참고치'] || ''
							tmp.key =
								tmp.EXTRDD + '_' + tmp.EXTRNO + '_' + tmp.CHARTNO + '_' + tmp.EXAMCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_EMC') {
						setTmpColumns(headerOcsTmpEmc)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.A) tmp.EXTRNO = item?.A || ''
							if (item?.B) tmp.EXAMCD = item?.B || ''
							if (item?.C) tmp.EXAMNM = item?.C || ''
							if (item?.D) tmp.SPCCD = item?.D || ''
							if (item?.E) tmp.SPCNM = item?.E || ''
							if (item?.F) tmp.CHARTNO = item?.F || ''
							if (item?.G) tmp.NM = item?.G || ''
							if (item?.H) tmp.RGSTNO = item?.H || ''
							if (item?.I) tmp.SEX = item?.I || ''
							if (item?.J) tmp.DEPT = item?.J || ''
							if (item?.K) tmp.EXAMCD2 = item?.K || ''
							if (item?.L) tmp.BARCODE = item?.L || ''
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_EONM') {
						setTmpColumns(headerOcsTmpEonm)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.A) tmp.EXTRNO = item?.A || ''
							if (item?.B) tmp.EXTRDD = item?.B || ''
							if (item?.C) tmp.EXAMCD = item?.C || ''
							if (item?.D) tmp.EXAMNM = item?.D || ''
							if (item?.E) tmp.SPCCD = item?.E || ''
							if (item?.F) tmp.SPCNM = item?.F || ''
							if (item?.G) tmp.CHARTNO = item?.G || ''
							if (item?.H) tmp.RGSTNO = item?.H || ''
							if (item?.I) tmp.SEX = item?.I || ''
							if (item?.J) tmp.AGE = item?.J || ''
							if (item?.K) tmp.NM = item?.K || ''
							if (item?.L) tmp.DEPT = item?.L || ''
							if (item?.M) tmp.DOCTOR = item?.M || ''
							if (item?.N) tmp.BED = item?.N || ''
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_AMIS') {
						setTmpColumns(headerOcsTmpAmis)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.A) tmp.EXTRNO = item?.A || ''
							if (item?.B) tmp.EXAMCD = item?.B || ''
							if (item?.C) tmp.EXAMNM = item?.C || ''
							if (item?.D) tmp.CHARTNO = item?.D || ''
							if (item?.E) tmp.SPCCD = item?.E || ''
							if (item?.F) tmp.CHARTNO = item?.F || ''
							if (item?.G) tmp.NM = item?.G || ''
							if (item?.H) tmp.RGSTNO = item?.H || ''
							if (item?.I) tmp.SEX = item?.I || ''
							if (item?.L) tmp.EXTRDD = item?.L || ''
							if (item?.M) tmp.DEPT = item?.M || ''
							if (item?.N) tmp.DOCTOR = item?.N || ''
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_ONTIC') {
						setTmpColumns(headerOcsTmpOntic)
						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['접수번호']) tmp.EXTRNO = item?.['접수번호'] || ''
							if (item?.['검사코드']) tmp.EXAMCD = item?.['검사코드'] || ''
							if (item?.['검사명']) tmp.EXAMNM = item?.['검사명'] || ''
							if (item?.['검체코드']) tmp.SPCCD = item?.['검체코드'] || ''
							if (item?.['검체명']) tmp.SPCNM = item?.['검체명'] || ''
							if (item?.['환자번호']) tmp.CHARTNO = item?.['환자번호'] || ''
							if (item?.['수진자명']) tmp.NM = item?.['수진자명'] || ''
							if (item?.['주민번호']) tmp.RGSTNO = item?.['주민번호'] || ''
							if (item?.['성별']) tmp.SEX = item?.['성별'] || ''
							if (item?.['병동']) tmp.BED = item?.['병동'] || ''
							if (item?.['진료과']) tmp.DEPT = item?.['진료과'] || ''
							if (item?.['오더ID']) tmp.ORDERNO = item?.['오더ID'] || ''
							if (item?.['처방일']) tmp.EXTRDD = item?.['처방일'] || ''
							if (item?.['처방의']) tmp.DOCTOR = item?.['처방의'] || ''
							if (item?.['접수일']) tmp.ACPTDD = item?.['접수일'] || ''
							if (item?.['접수시간']) tmp.ACPTTIME = item?.['접수시간'] || ''
							if (item?.['핸드폰']) tmp.PHONE = item?.['핸드폰'] || ''
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_NEO') {
						setTmpColumns(headerOcsTmpNeo)

						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.A) tmp.EXTRNO = item?.A || ''
							if (item?.B) tmp.EXTRDD = item?.B || ''
							if (item?.C) tmp.NM = item?.C || ''
							if (item?.D) tmp.CHARTNO = item?.D || ''
							if (item?.E) tmp.SEX = item?.E || ''
							if (item?.F) tmp.AGE = item?.F || ''
							if (item?.G) tmp.RGSTNO = item?.G || ''
							if (item?.H) tmp.EXAMCD = item?.H || ''
							if (item?.I) tmp.EXAMNM = item?.I || ''
							if (item?.J) tmp.PRINTDT = item?.J || ''
							if (item?.K) tmp.ORDERSTATUS = item?.K || ''
							if (item?.L) tmp.DEPT = item?.L || ''
							tmp.key = tmp.EXTRDD + '_' + tmp.EXTRNO
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					} else if (gridHeaderName === 'OCS_MSYSTECH') {
						setTmpColumns(headerOcsTmpMsysTech)

						data.map((item: any, index: number) => {
							const tmp: any = {}
							if (item?.['접수번호']) tmp.EXTRNO = item?.['접수번호'] || ''
							if (item?.['접수일시']) {
								tmp.EXTRDT = item?.['접수일시'] || ''
								let extrdd = item?.['접수일시']?.split(' ')[0]
								extrdd = extrdd.replace(/-/gi, '')
								tmp.EXTRDD = extrdd
							}
							if (item?.['환자명']) tmp.NM = item?.['환자명'] || ''
							if (item?.['성별']) tmp.SEX = item?.['성별'] || ''
							if (item?.['나이']) tmp.AGE = item?.['나이'] || ''
							if (item?.['주민번호']) tmp.RGSTNO = item?.['주민번호'] || ''
							if (item?.['차트번호']) tmp.CHARTNO = item?.['차트번호'] || ''
							if (item?.['바코드번호']) tmp.BARCODE = item?.['바코드번호'] || ''
							if (item?.['검체명']) tmp.SPCNM = item?.['검체명'] || ''
							if (item?.['진료과']) tmp.DEPT = item?.['진료과'] || ''
							if (item?.['병동']) tmp.WARD = item?.['병동'] || ''
							if (item?.['검사코드']) tmp.EXAMCD = item?.['검사코드'] || ''
							if (item?.['검사명칭']) tmp.EXAMNM = item?.['검사명칭'] || ''
							if (item?.['주치의']) tmp.DOCTOR = item?.['주치의'] || ''
							if (item?.['병실']) tmp.BED = item?.['병실'] || ''
							if (item?.['검체코드']) tmp.SPCCD = item?.['검체코드'] || ''
							tmp.key =
								tmp.EXTRDD +
								'_' +
								tmp.EXTRNO +
								'_' +
								tmp.CHARTNO +
								'_' +
								tmp.EXAMCD +
								'_' +
								tmp.SPCCD
							tmp.id = index + 1
							tmpList.push(tmp)
						})
					}

					// unique key check
					const distinctTmpList = tmpList.map(
						(item: any, index: number, array: any[]) => {
							const isDuplicate = array.some(
								(element: any, elementIndex: number) =>
									elementIndex !== index && element.key === item.key
							)

							if (isDuplicate) {
								setTmpIsDuplicate(true)
								tmpIsDup = true
							}
							return {
								...item,
								isDuplicate,
							}
						}
					)

					console.log('gridHeaderName', gridHeaderName)

					// 중복이면 그리드 출력
					if (tmpIsDup) {
						const res = distinctTmpList.filter((item: any) => item.isDuplicate)
						console.log(res)
						setTmpDataList(res)
					} else {
						console.log('done')
						console.log(tmpList)
						setTmpIsDuplicate(false)
						// checkAfterUpload()
						if (
							gridHeaderName === 'OCS_MDJIN' ||
							gridHeaderName === 'OCS_BITEMR' ||
							gridHeaderName === 'OCS_BITEMR2'
						) {
							// tmpList 에서 CHARTNO가 숫자인 것만 필터링
							tmpList = tmpList.filter((item: any) => !isNaN(item.CHARTNO))
						}
						if (
							gridHeaderName === 'OCS_MDJIN' ||
							gridHeaderName === 'OCS_BITEMR' ||
							gridHeaderName === 'OCS_BITEMR2' ||
							gridHeaderName === 'OCS_KCHART' ||
							gridHeaderName === 'OCS_MSYSTECH'
						) {
							console.log(tmpList)
							confirmAlert(
								'파일을 업로드하시겠습니까?',
								() => {
									jsonUpload(tmpList)
										.then((res) => {
											console.log(res)
											if (res.ErrorMessage) {
												alertModal(res.ErrorMessage, 'error', () => {})
											} else {
												alertModal('업로드가 완료되었습니다.', 'success', () => {
													setTmpIsDuplicate(false)
													setUploadOpen(false)
													setFileObject(null)
													setTmpDataList([])
												})
											}
										})
										.finally(() => {
											getSyncList()
										})
								},
								() => {}
							)
						} else {
							checkAfterUpload()
							setTmpIsDuplicate(false)
							setUploadOpen(false)
							setFileObject(null)
							setTmpDataList([])
						}
					}
				}
			}
			reader.readAsArrayBuffer(file)
		}
	}

	const handleCellEditCommit = (params: GridCellEditCommitParams) => {
		const { id, field, value } = params

		console.log(id, field, value)

		setTmpDataList((prevRows) =>
			prevRows.map((row) => {
				if (row.id === id) {
					return {
						...row,
						[field]: value,
					}
				}
				return row
			})
		)
	}

	// 그리드 데이터 수정 처리
	const uploaModifyAction = () => {
		setTmpIsDuplicate(false)
		setUploadOpen(false)
		setFileObject(null)
		setTmpDataList([])
		// // unique key check
		// let tmpIsDup = false
		// let dupCnt = 0

		// // get all data from tempApiRef
		// const tempApiRefList = tempApiRef.current?.getAllRowIds().map((id) => {
		// 	return tempApiRef.current?.getRow(id)
		// })

		// const distinctTmpList = tempApiRefList.map(
		// 	(item: any, index: number, array: any[]) => {
		// 		// key 재생성
		// 		item.key =
		// 			item.EXTRDD + '_' + item.EXTRNO + '_' + item.CHARTNO + '_' + item.EXAMCD
		// 		const isDuplicate = array.some(
		// 			(element: any, elementIndex: number) =>
		// 				elementIndex !== index && element.key === item.key
		// 		)

		// 		if (isDuplicate) {
		// 			setTmpIsDuplicate(true)
		// 			tmpIsDup = true
		// 			dupCnt++
		// 		}

		// 		return {
		// 			...item,
		// 			isDuplicate,
		// 		}
		// 	}
		// )
		// console.log(dupCnt)
		// // 중복이면 그리드 출력
		// if (tmpIsDup) {
		// 	// distinctTmpList sort by isDuplicate true
		// 	distinctTmpList.sort((a: any, b: any) => {
		// 		if (a.isDuplicate) return -1
		// 		if (b.isDuplicate) return 1
		// 		return 0
		// 	})

		// 	setTmpDataList(distinctTmpList)
		// } else if (!tmpIsDup && dupCnt === 0) {
		// 	console.log('done')
		// 	console.log(tmpDataList)
		// 	setTmpIsDuplicate(false)
		// 	checkAfterUpload()
		// }
	}

	// 중복 체크 완료 후 업로드 처리
	const checkAfterUpload = () => {
		if (fileObject) {
			confirmAlert(
				'파일을 업로드하시겠습니까?',
				() => {
					console.log(1065)
					syncUploadFile(fileObject)
						.then((res) => {
							// setCloseDialog()
							if (res) {
								const {
									ErrorLine,
									ErrorMessage,
									ErrorNumber,
									ErrorProcedure,
									ErrorSeverity,
									ErrorState,
								} = res
								console.log(ErrorMessage)
								if (ErrorMessage) alertModal(ErrorMessage, 'error', () => {})
							} else {
								alertModal('업로드가 완료되었습니다.', 'success', () => {
									setUploadOpen(false)
									setFileObject(null)
								})
							}
						})
						.catch((err) => {
							console.log(err.response.status)
							if (err.response.status === 500) {
								alertModal(
									'업로드가 실패하였습니다.<br />파일이 손상되었을 수 있습니다.<br />파일을 별도로 저장 후 다시 업로드하세요.',
									'error',
									() => {}
								)
							}
							// alertModal('asdfadsfadsf', 'error', () => {})
						})
						.finally(() => {
							getSyncList()
						})
				},
				() => {}
			)
		}
	}

	// 첨부파일 handler
	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop: onDrop,
			accept: {
				'application/vnd.ms-excel': ['.xls', '.xlsx'],
			},
			multiple: false,
		})

	// 엑셀 양식 다운로드
	const downloadTemplate = () => {}

	const [fileObject, setFileObject] = useState<File | null>(null)
	const [uploadOpen, setUploadOpen] = useState(false)

	const [fileInfo, setFileInfo] = useState({
		fileName: '',
		orgFileName: '',
	})

	const setCloseDialog = () => {
		setUploadOpen(false)
		setFileObject(null)
		setTmpIsDuplicate(false)
		setTmpDataList([])
		setTmpColumns([])
		setTmpGridSelectionModel([])
	}

	const actionDownloadTemplate = () => {
		fileDownloadByFileName(fileInfo?.fileName, fileInfo?.orgFileName)
	}

	const actionSync = () => {
		confirmAlert(
			'동기화 요청을 하시겠습니까?',
			() => {
				syncRequest()
					.then((res) => {
						if (res.success) {
							alertModal(res.data, 'success', () => {})
						} else {
							alertModal(res.data, 'error', () => {})
						}
					})
					.finally(() => {
						getSyncList()
					})
			},
			() => {}
		)
	}

	const getSyncList = () => {
		// 다운로드 상태 = 미완 : 30, 완료: 40 전체 : 30,40
		// 접수상태 10,20 일때 다운로드 조건 비활성화
		// 접수상태 전체 > 접수상태 무시 및 다운로드 상태 30, 40
		// 조회 기본값 : 결과완료, 미완료, 보고일, 1일
		// 접수상태 > 결과다운완료는 삭제

		const request = {
			keyword: '',
			status:
				search.status === 'A'
					? search.downloadStatus === 'A'
						? ''
						: search.downloadStatus
					: search.status === '30'
					? search.downloadStatus === 'A'
						? '50'
						: search.downloadStatus !== 'A'
						? search.downloadStatus
						: ''
					: search.status,
			startDate: search.startDate,
			endDate: search.endDate,
			searchType: search.searchType,
		}

		console.table(request)

		setDisabledSearch(true)

		// gridLoading
		setGridLoading(true)

		syncList(request)
			.then((res) => {
				setGridRows(res)
			})
			.finally(() => {
				setDisabledSearch(false)
				setGridLoading(false)
			})
	}

	const [noSettings, setNoSettings] = useState({
		data: '',
		success: false,
	})

	const actionExcel = () => {
		const request = {
			status:
				search.status === 'A'
					? search.downloadStatus === 'A'
						? ''
						: search.downloadStatus
					: search.status === '30'
					? search.downloadStatus === 'A'
						? '50'
						: search.downloadStatus !== 'A'
						? search.downloadStatus
						: ''
					: search.status,
			startDate: search.startDate,
			endDate: search.endDate,
			searchType: search.searchType,
		}

		window.open(
			`${process.env.REACT_APP_HOST}/sync/excelDownload?clientID=${currentUser?.clientID}&status=${request.status}&startDate=${request.startDate}&endDate=${request.endDate}&searchType=${request.searchType}`,
			'결과엑셀다운로드'
		)
	}

	const actionTxt = () => {
		// const request = {
		// 	clientID: currentUser?.clientID,
		// 	status: search.status === 'A' ? '' : search.status,
		// 	startDate: search.startDate,
		// 	endDate: search.endDate,
		// 	searchType: search.searchType,
		// }
		const request = {
			clientID: currentUser?.clientID,
			status:
				search.status === 'A'
					? search.downloadStatus === 'A'
						? ''
						: search.downloadStatus
					: search.status === '30'
					? search.downloadStatus === 'A'
						? ''
						: search.downloadStatus !== 'A'
						? search.downloadStatus
						: ''
					: search.status,
			startDate: search.startDate,
			endDate: search.endDate,
			searchType: search.searchType,
		}

		const fileName = currentUser.userId + '_' + moment().format('YYYYMMDDHHmmss')
		textDownload(request, fileName).then((res) => {
			console.log(res)
		})
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '1d':
				getBeforeDate(1)
				break
			case '2d':
				getBeforeDate(2)
				break
			case '3d':
				getBeforeDate(3)
				break
			case '7d':
				getBeforeDate(7)
				break
			case '12d':
				getBeforeDate(12)
				break
			case '14d':
				getBeforeDate(14)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		// if (moment(new Date(e)).isBefore('2023-08-01')) {
		// 	alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
		// 	setSearch({
		// 		...search,
		// 		startDate: '2023-08-01',
		// 		endDate: '2023-08-07',
		// 	})
		// 	return
		// } else {
		if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
			if (!isValidDateType(e)) {
				alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
				setDisabledSearch(true)
				return
			}

			if (target === 'startDate') {
				let diffValue = compareDate(e, search.endDate)
				if (diffValue > 0) {
					alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
				if (diffValue < -1825) {
					alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
			} else if (target === 'endDate') {
				let diffValue = compareDate(e, search.startDate)
				if (diffValue < 0) {
					alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
				if (diffValue > 1825) {
					alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
			}

			setDisabledSearch(false)

			setSearch((prevState) => {
				return {
					...search,
					[target]: moment(new Date(e)).format('yyyy-MM-DD'),
				}
			})
			// } else {
			// 	setDisabledSearch(true)
			// }
		}
	}

	useEffect(() => {
		// 공통코드
		let lv2 = extractCmmnCode(cmmnCdList, 'OCS_STATUS', 1)
		setStatusList(lv2?.level2)
		// reportFileByClientID().then((res) => {
		// 	if (res.success === false) {
		// 		setDisabledAdd(true)
		// 		setDisabledDel(true)
		// 		setDisabledSave(true)
		// 		setDisabledSync(true)
		// 		setDisabledSearch(true)
		// 		setDisabledReset(true)
		// 		setDisabledDownload(true)
		// 		setNoSettings({
		// 			data: res.data,
		// 			success: res.success,
		// 		})
		// 	} else {
		// 		setFileInfo(res)
		// 		setNoSettings({
		// 			data: '',
		// 			success: true,
		// 		})
		// 	}
		// })

		// if (currentUser?.infoType === 'C' && currentUser?.clientID === 4194) {
		// 	navigate('/admin/test/syncOld')
		// }

		// 다운로드 상태 = 미완 : 30, 완료: 40 전체 : 30,40
		// 접수상태 10,20 일때 다운로드 조건 비활성화
		// 접수상태 전체 > 접수상태 무시 및 다운로드 상태 30, 40
		// 조회 기본값 : 결과완료, 미완료, 보고일, 1일
		// 접수상태 > 결과다운완료는 삭제

		lv2 = extractCmmnCode(cmmnCdList, 'OCS_DOWNLOAD', 1)
		setDownloadList(lv2?.level2)
		if (currentUser.infoType === 'C') {
			tableName()
				.then((res) => {
					if (res) {
						console.log(res)
						setGridHeaderName(res.TablePostFix)
						setDisabledAdd(false)
						setDisabledDel(true)
						setDisabledSave(true)
						setDisabledSync(false)
						setDisabledSearch(false)
						setDisabledReset(false)
						setDisabledUpload(false)
						setDownloadType(res.DownloadType)

						if (
							res.TablePostFix === 'OCS_BYUNGCOM' ||
							res.TablePostFix === 'OCS_EHEALTH'
						) {
							setDisabledDownload(true)
						} else {
							setDisabledDownload(false)
						}
						getSyncList()
					} else {
						setGridHeaderName('')
						setDisabledAdd(true)
						setDisabledDel(true)
						setDisabledSave(true)
						setDisabledSync(true)
						setDisabledSearch(true)
						setDisabledReset(true)
						setDisabledDownload(true)
						setDisabledUpload(true)
					}
				})
				.then(() => {})
		} else {
			setDisabledAdd(true)
			setDisabledDel(true)
			setDisabledSave(true)
			setDisabledSync(true)
			setDisabledSearch(true)
			setDisabledReset(true)
			setDisabledDownload(true)
			setDisabledUpload(true)
		}
	}, [])

	return (
		<>
			<Dialog
				open={uploadOpen}
				onClose={setCloseDialog}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							검사결과 대량 업로드
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={setCloseDialog}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					{currentUser.clientID && (
						<>
							{fileInfo ? (
								<>
									{/* <Typography
										variant="body2"
										color="text.secondary"
										sx={{ mb: 2 }}
									>
										업로드 파일 양식은{' '}
										<button
											type="button"
											onClick={actionDownloadTemplate}
											style={{
												border: 0,
												outline: 0,
												background: 'transparent',
												color: '#ff0000',
												margin: 0,
												padding: 0,
											}}
										>
											여기
										</button>
										를 눌러 다운로드 받으세요.
									</Typography> */}
									<div
										className="dropzone dz-clickable dz-started"
										{...getRootProps()}
									>
										<input {...getInputProps()} />
										{!isDragActive && (
											<Card
												sx={{
													backgroundColor:
														theme.palette.mode === 'dark' ? '#2C3A47' : '#2C3A47',
												}}
											>
												<CardContent sx={{ paddingBottom: '16px !important' }}>
													<Typography
														color="#fff"
														variant="body1"
														component="p"
														sx={{ marginBottom: '0' }}
													>
														<AttachFileIcon
															fontSize="small"
															sx={{ marginBottom: '0' }}
														/>{' '}
														{fileObject?.name ? fileObject.name : '파일을 선택하세요.'}
													</Typography>
												</CardContent>
											</Card>
										)}
										{isDragReject && (
											<p className="text-warning">지원하지 않는 파일입니다.</p>
										)}
									</div>
								</>
							) : (
								<Typography
									variant="body2"
									color="text.secondary"
								>
									업로드 가능한 파일양식이 없습니다. 관리자에게 문의하세요.
								</Typography>
							)}
							{tmpDataList.length > 0 && tmpIsDuplicate && (
								<>
									<Alert
										severity="error"
										sx={{ mt: 1, mb: 1 }}
									>
										중복 데이터가 있습니다.
										<br />
										수정 후 다시 업로드 하세요. <br />
										문제가 지속될 경우 관리자에게 문의하세요.
									</Alert>
									<Box
										sx={{
											width: '100%',
											overflow: 'auto',
											'@media (max-width: 1199px)': {
												height: '400px',
											},
										}}
									>
										<DataGridPremium
											apiRef={tempApiRef}
											rows={tmpDataList}
											getRowId={(row) => row.id}
											columns={tmpColumns}
											pageSize={gridPageSize}
											onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
											autoHeight={isMdUp ? true : false}
											localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
											components={{
												Toolbar: GridToolbar,
												LoadingOverlay: LinearProgress,
											}}
											componentsProps={{
												toolbar: {
													csvOptions: { disableToolbarButton: true },
													excelOptions: { disableToolbarButton: true },
													printOptions: { disableToolbarButton: true },
													showQuickFilter: true,
													quickFilterProps: { debounceMs: 250 },
												},
											}}
											loading={gridLoading}
											selectionModel={tmpGridSelectionModel}
											onRowClick={onTmpRowClick}
											density={(env.desnse as GridDensity) || 'compact'}
											getRowClassName={(params) =>
												`super-app-theme--${params.row.isDuplicate}`
											}
											// isCellEditable={(params) => {
											// 	if (params.row.isDuplicate === true) {
											// 		return true
											// 	}
											// 	return false
											// }}
											// editMode="cell"
											// onCellEditCommit={handleCellEditCommit}
										/>
									</Box>
								</>
							)}
						</>
					)}
				</DialogContent>
				<DialogActions>
					{tmpDataList.length > 0 && tmpIsDuplicate ? (
						<Button
							variant="contained"
							color="success"
							onClick={uploaModifyAction}
						>
							확인
						</Button>
					) : (
						<Button
							variant="contained"
							color="success"
							onClick={uploadAction}
						>
							업로드
						</Button>
					)}
					<Button
						variant="contained"
						color="secondary"
						onClick={setCloseDialog}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			{/* <PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={true}
				hideSave={true}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/> */}
			<Stack sx={{ marginBottom: '1rem' }}>
				<PageWrap>
					<PageBg2>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								OCS 연동
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								<span>
									<Button
										type="button"
										variant="contained"
										color="info"
										size="small"
										startIcon={<CloudUploadTwoToneIcon />}
										disabled={disabledUpload}
										onClick={actionAdd}
										sx={{ mr: 1 }}
									>
										접수 업로드
									</Button>
								</span>
								<span>
									{downloadType &&
										(downloadType === 'xls' || downloadType === 'all') && (
											<Button
												type="button"
												variant="contained"
												color="secondary"
												size="small"
												startIcon={<DownloadForOfflineTwoToneIcon />}
												disabled={disabledDownload}
												onClick={actionExcel}
											>
												엑셀 다운로드
											</Button>
										)}
								</span>
								{downloadType && (downloadType === 'txt' || downloadType === 'all') && (
									<span>
										<Button
											type="button"
											variant="contained"
											color="error"
											size="small"
											startIcon={<DownloadForOfflineTwoToneIcon />}
											disabled={disabledDownload}
											sx={{ ml: 1 }}
											onClick={actionTxt}
										>
											텍스트 다운로드
										</Button>
									</span>
								)}
								<span>
									<Button
										type="button"
										variant="contained"
										color="info"
										size="small"
										onClick={actionSync}
										startIcon={<CloudSyncIcon />}
										sx={{ ml: 1 }}
										disabled={disabledSync}
									>
										동기화 요청
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSearchSubmit}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									lg={1}
								>
									<FormControl fullWidth>
										<InputLabel id="date-range-select-label">접수 상태</InputLabel>
										<Select
											labelId="date-range-select-label"
											id="date-range-simple-select"
											value={search.status}
											label="접수 상태"
											name="status"
											onChange={(e) => {
												if (e.target.value === '10' || e.target.value === '20') {
													setSearch({
														...search,
														status: e.target.value,
														downloadStatus: 'A',
													})
												} else if (e.target.value === '30') {
													setSearch({
														...search,
														status: e.target.value,
													})
												} else {
													setSearch({
														...search,
														status: 'A',
													})
												}
											}}
											size={'small'}
										>
											<MenuItem value="A">전체</MenuItem>
											<MenuItem value="10">의뢰완료</MenuItem>
											<MenuItem value="20">접수완료</MenuItem>
											<MenuItem value="30">결과완료</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									{/* // 다운로드 상태 = 미완 : 30, 완료: 40 전체 : 30,40
		// 접수상태 10,20 일때 다운로드 조건 비활성화
		// 접수상태 전체 > 접수상태 무시 및 다운로드 상태 30, 40
		// 조회 기본값 : 결과완료, 미완료, 보고일, 1일
		// 접수상태 > 결과다운완료는 삭제 */}
									<RadioWrapper>
										<p className="labelText">다운로드 상태</p>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby="date-controlled-radio-buttons-group"
												name="date-radio-buttons-group"
												value={search.downloadStatus}
												onChange={(e) => {
													setSearch({
														...search,
														downloadStatus: e.target.value,
													})
												}}
											>
												<FormControlLabel
													value="30"
													control={<Radio sx={{ padding: '1px' }} />}
													label="미완료"
													disabled={search.status === '10' || search.status === '20'}
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
												<FormControlLabel
													value="40"
													control={<Radio sx={{ padding: '1px' }} />}
													label="완료"
													disabled={search.status === '10' || search.status === '20'}
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
												<FormControlLabel
													value="A"
													control={<Radio sx={{ padding: '1px' }} />}
													label="전체"
													disabled={search.status === '10' || search.status === '20'}
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
											</RadioGroup>
										</FormControl>
									</RadioWrapper>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									<RadioWrapper>
										<p className="labelText">조회조건</p>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby="date-controlled-radio-buttons-group"
												name="date-radio-buttons-group"
												value={search.searchType}
												onChange={(e) =>
													setSearch({
														...search,
														searchType: e.target.value,
													})
												}
											>
												<FormControlLabel
													value="R"
													control={<Radio sx={{ padding: '1px' }} />}
													label="의뢰일"
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
												<FormControlLabel
													value="D"
													control={<Radio sx={{ padding: '1px' }} />}
													label="보고일"
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
											</RadioGroup>
										</FormControl>
									</RadioWrapper>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									{isLgUp ? (
										<RadioWrapper>
											<p className="labelText">조회기간</p>
											<FormControl>
												<RadioGroup
													row
													aria-labelledby="date-controlled-radio-buttons-group"
													name="date-radio-buttons-group"
													value={dateRange}
													onChange={searchDateRanageOnChange}
												>
													<FormControlLabel
														value="1d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="1일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="2d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="2일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="3d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="3일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="7d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="7일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="14d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="14일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
												</RadioGroup>
											</FormControl>
										</RadioWrapper>
									) : (
										<FormControl fullWidth>
											<InputLabel id="date-range-select-label">조회 기간</InputLabel>
											<Select
												labelId="date-range-select-label"
												id="date-range-simple-select"
												value={dateRange}
												label="조회 기간"
												onChange={searchDateRanageOnChange}
												size={'small'}
											>
												<MenuItem value={'0'}>선택하세요.</MenuItem>
												<MenuItem value={'1d'}>1일</MenuItem>
												<MenuItem value={'2d'}>2일</MenuItem>
												<MenuItem value={'3d'}>3일</MenuItem>
												<MenuItem value={'7d'}>7일</MenuItem>
												<MenuItem value={'12d'}>12일</MenuItem>
												<MenuItem value={'14d'}>14일</MenuItem>
											</Select>
										</FormControl>
									)}
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									<DatePicker
										label="시작일"
										value={search.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									<DatePicker
										label="종료일"
										value={search.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Box>
				</PageBg>
			</Stack>
			<PageBg style={{ marginTop: '1rem', width: '100%', overflow: 'hidden' }}>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: gridHeaderName ? 'flex-end' : 'space-between',
							}}
						>
							{gridHeaderName === '' ? (
								<Alert severity="error">검사결과연동 이용이 불가능합니다.</Alert>
							) : (
								<div />
							)}
							<div></div>
						</div>
					</Grid>
					<Box
						sx={{
							width: '100%',
							overflow: 'auto',
							'@media (max-width: 1199px)': {
								height: '400px',
							},
						}}
					>
						<DataGridPremium
							apiRef={apiRef}
							rows={gridRows}
							getRowId={(row) => row.SEQ}
							columns={columns}
							pageSize={gridPageSize}
							onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={isMdUp ? true : false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									excelOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
									showQuickFilter: false,
									quickFilterProps: { debounceMs: 250 },
								},
							}}
							loading={gridLoading}
							selectionModel={gridSelectionModel}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
							rowHeight={40}
						/>
					</Box>
				</Grid>
			</PageBg>
		</>
	)
}

export default TestSync
