export const headerOcsNeo = [
	{
		field: 'SEQ',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '의뢰번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '의뢰일자',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '수진자명',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 60,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 130,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명',
		type: 'string',
		width: 250,
		editable: false,
	},
	{
		field: 'PRINTDT',
		headerName: '출력일자',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'ORDERSTATUS',
		headerName: '상태',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'cmmnNm',
		headerName: '상태',
		type: 'string',
		width: 70,
		editable: false,
	},
]
