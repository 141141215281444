import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// 각종 설정

export const UPLOAD_MAX_SIZE = 1048576 * 5

/**
 * 다크모드, 라이트모드와 관련된 설정
 */
const colorRed = red[500]
declare module '@mui/material/styles' {
	interface Palette {
		custom: {
			main: string
			contrastText: string
			wrapper: string
		}
	}
	interface PaletteOptions {
		custom?: {
			main?: string
			contrastText?: string
			wrapper?: string
		}
	}
}

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#4b7bec',
			// main: '#835FED',
		},
		secondary: {
			main: '#7184AD',
		},
		warning: {
			main: colorRed,
		},
		custom: {
			main: '#323036',
			contrastText: '#ffffff',
			wrapper: '#323036',
		},
	},
	typography: {
		fontFamily: "'Noto Sans KR', sans-serif",
		fontSize: 12,
	},
})
export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		background: {
			default: 'rgb(227, 242, 253)',
		},
		primary: {
			main: '#4b7bec',
			// main: '#835FED',
		},
		secondary: {
			main: '#7184AD',
		},
		warning: {
			main: colorRed,
		},
		custom: {
			main: '#323036',
			contrastText: '#ffffff',
			wrapper: '#fff',
		},
	},
	typography: {
		fontFamily: "'Noto Sans KR', sans-serif",
		fontSize: 12,
	},
})
