import { styled } from '@mui/material/styles'

/**
 * 공통 스타일
 */
export const PageBg = styled('div')(({ theme }) => ({
  padding: '1rem',
  borderRadius: '0.5rem',
  background: theme.palette.custom.wrapper,
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
}))

export const PageWrap = styled('div')(({ theme }) => ({
  marginBottom: '1rem',
  position: 'sticky',
  top: '65px',
  left: '0',
  right: '0',
  zIndex: '10',
}))
