interface UserModel {
	accessToken: string
	refreshToken: string
	tokenType: string
	expiryDuration: number
}
interface SettingsModel {
	desnse: string
	row: number
	zoom: string
	res: string
	ocs: string
	resultPatientExand: string
}

// interface GridColumnModel {
// 	[key: string]: any
// }

/**
 * 로그인
 * @param user
 */
export const setCurrentUser = (user: UserModel) => {
	try {
		if (user) {
			localStorage.setItem(
				`${process.env.REACT_APP_USER_STORAGE_NAME}`,
				JSON.stringify(user)
			)
		} else {
			localStorage.removeItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`)
		}
	} catch (error) {
		console.log(`${process.env.REACT_APP_USER_STORAGE_NAME} ERROR`, error)
	}
}

/**
 * 로그아웃
 */
export const removeCurrentUser = () => {
	localStorage.removeItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`)
}

/**
 * 사용자 정보 호출
 * @returns
 */
export const getCurrentUser = () => {
	let user: any = ''
	try {
		user =
			localStorage.getItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`) != null
				? JSON.parse(
						localStorage.getItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`) || ''
				  )
				: null
	} catch (error) {
		console.log(`${process.env.REACT_APP_USER_STORAGE_NAME} ERROR`, error)
		user = null
	}
	return user
}

/**
 * 환경설정 저장
 * @param settings
 */
export const setSettings = (settings: SettingsModel) => {
	try {
		if (settings) {
			localStorage.setItem(
				`${process.env.REACT_APP_SETTINGS_STORAGE_NAME}`,
				JSON.stringify(settings)
			)
		} else {
			localStorage.removeItem(`${process.env.REACT_APP_SETTINGS_STORAGE_NAME}`)
		}
	} catch (error) {
		console.log(`${process.env.REACT_APP_SETTINGS_STORAGE_NAME} ERROR`, error)
	}
}

/**
 * 환경설정 호출
 * @returns
 */
export const getSettings = () => {
	let settings: SettingsModel = {
		desnse: 'compact',
		row: 100,
		zoom: '100',
		res: 'n',
		ocs: 'n',
		resultPatientExand: 'y',
	}
	try {
		settings =
			localStorage.getItem(`${process.env.REACT_APP_SETTINGS_STORAGE_NAME}`) !=
			null
				? JSON.parse(
						localStorage.getItem(`${process.env.REACT_APP_SETTINGS_STORAGE_NAME}`) ||
							''
				  )
				: {
						desnse: 'compact',
						row: 100,
						zoom: '100',
						res: 'n',
						ocs: 'n',
						resultPatientExand: 'y',
				  }
	} catch (error) {
		settings = {
			desnse: 'compact',
			row: 100,
			zoom: '100',
			res: 'n',
			ocs: 'n',
			resultPatientExand: 'y',
		}
	}
	return settings
}

/**
 * 환경설정 삭제
 */
export const removeSettings = () => {
	localStorage.removeItem(`${process.env.REACT_APP_SETTINGS_STORAGE_NAME}`)
}

/**
 * 그리드 헤더 정렬 설정 호출
 * @param settngs
 */
export const getGridColumnSettings = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME}`) !=
			null
				? JSON.parse(
						localStorage.getItem(
							`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME}`
						) || ''
				  )
				: null
	} catch (error) {
		console.log(`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME} ERROR`, error)
		setting = null
	}
	return setting
}

/**
 * 그리드 헤더 설정 저장
 * @param settngs
 */
export const setGridColumnSettings = (settngs: any) => {
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME}`)
		}
	} catch (error) {
		console.log(`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME} ERROR`, error)
	}
}

/**
 * 그리드 헤더 설정 삭제
 */
export const removeGridColumnSettings = () => {
	localStorage.removeItem(`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME}`)
}

/**
 * 그리드 헤더 보임 및 숨김 설정 호출
 * @param settngs
 */
export const getGridColumnVisibleSettings = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_STORAGE_NAME}`
			) != null
				? JSON.parse(
						localStorage.getItem(
							`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_STORAGE_NAME}`
						) || ''
				  )
				: { ReceptionID: false, SocialNumber: false }
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_STORAGE_NAME} ERROR`,
			error
		)
		setting = null
	}
	return setting
}

/**
 * 그리드 컬럼 보임 및 숨김 설정 저장
 * @param settngs
 */
export const setGridColumnVisibleSettings = (settngs: any) => {
	console.log(settngs)
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_STORAGE_NAME}`
			)
		}
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_STORAGE_NAME} ERROR`,
			error
		)
	}
}

/**
 * 그리드 컬럼 설정 삭제
 */
export const removeGridColumnVisibleSettings = () => {
	localStorage.removeItem(`${process.env.REACT_APP_GRID_COLUMN_STORAGE_NAME}`)
}

/**
 * 기타 개인 환경 설정 호출
 * @param settngs
 */
export const getEnvSettings = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(`${process.env.REACT_APP_ENV_STORAGE_NAME}`) != null
				? JSON.parse(
						localStorage.getItem(`${process.env.REACT_APP_ENV_STORAGE_NAME}`) || ''
				  )
				: null
	} catch (error) {
		console.log(`${process.env.REACT_APP_ENV_STORAGE_NAME} ERROR`, error)
		setting = null
	}
	return setting
}

/**
 * 기타 개인 환경 설정 저장
 * @param settngs
 */
export const setEnvSettings = (settngs: any) => {
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_ENV_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(`${process.env.REACT_APP_ENV_STORAGE_NAME}`)
		}
	} catch (error) {
		console.log(`${process.env.REACT_APP_ENV_STORAGE_NAME} ERROR`, error)
	}
}

/**
 * 기타 개인 환경 설정 삭제
 */
export const removeEnvSettings = () => {
	localStorage.removeItem(`${process.env.REACT_APP_ENV_STORAGE_NAME}`)
}

/**
 * 그리드 헤더 정렬 설정 호출
 * @param settngs
 */
export const getGridColumnSettingsV11 = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(
				`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME}`
			) != null
				? JSON.parse(
						localStorage.getItem(
							`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME}`
						) || ''
				  )
				: null
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME} ERROR`,
			error
		)
		setting = null
	}
	return setting
}

/**
 * 그리드 헤더 설정 저장
 * @param settngs
 */
export const setGridColumnSettingsV11 = (settngs: any) => {
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(
				`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME}`
			)
		}
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME} ERROR`,
			error
		)
	}
}

/**
 * 그리드 헤더 설정 삭제
 */
export const removeGridColumnSettingsV11 = () => {
	localStorage.removeItem(
		`${process.env.REACT_APP_GRID_COLUMN_V11_STORAGE_NAME}`
	)
}

/**
 * 그리드 헤더 보임 및 숨김 설정 호출
 * @param settngs
 */
export const getGridColumnVisibleSettingsV11 = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME}`
			) != null
				? JSON.parse(
						localStorage.getItem(
							`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME}`
						) || ''
				  )
				: { ReceptionID: false, SocialNumber: false }
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME} ERROR`,
			error
		)
		setting = null
	}
	return setting
}

/**
 * 그리드 컬럼 보임 및 숨김 설정 저장
 * @param settngs
 */
export const setGridColumnVisibleSettingsV11 = (settngs: any) => {
	console.log(settngs)
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME}`
			)
		}
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME} ERROR`,
			error
		)
	}
}

/**
 * 그리드 컬럼 설정 삭제
 */
export const removeGridColumnVisibleSettingsV11 = () => {
	localStorage.removeItem(
		`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V11_STORAGE_NAME}`
	)
}

/**
 * 그리드 헤더 정렬 설정 호출 V2
 * @param settngs
 */
export const getGridColumnOrderSettingsV2 = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(
				`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME}`
			) != null
				? JSON.parse(
						localStorage.getItem(
							`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME}`
						) || ''
				  )
				: null
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME} ERROR`,
			error
		)
		setting = null
	}
	return setting
}

/**
 * 그리드 헤더 정렬 설정 저장 V2
 * @param settngs
 */
export const setGridColumnOrderSettingsV2 = (settngs: any) => {
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(
				`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME}`
			)
		}
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME} ERROR`,
			error
		)
	}
}

/**
 * 그리드 헤더 설정 정렬 삭제
 */
export const removeGridColumnOrderSettingsV2 = () => {
	localStorage.removeItem(
		`${process.env.REACT_APP_GRID_COLUMN_ORDER_V2_STORAGE_NAME}`
	)
}

/**
 * 그리드 헤더 보임 및 숨김 설정 호출 V2
 * @param settngs
 */
export const getGridColumnVisibleSettingsV2 = () => {
	let setting: any = ''
	try {
		setting =
			localStorage.getItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME}`
			) != null
				? JSON.parse(
						localStorage.getItem(
							`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME}`
						) || ''
				  )
				: { ReceptionID: false, SocialNumber: false }
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME} ERROR`,
			error
		)
		setting = null
	}
	return setting
}

/**
 * 그리드 컬럼 보임 및 숨김 설정 저장 V2
 * @param settngs
 */
export const setGridColumnVisibleSettingsV2 = (settngs: any) => {
	console.log(settngs)
	try {
		if (settngs) {
			localStorage.setItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME}`,
				JSON.stringify(settngs)
			)
		} else {
			localStorage.removeItem(
				`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME}`
			)
		}
	} catch (error) {
		console.log(
			`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME} ERROR`,
			error
		)
	}
}

/**
 * 그리드 컬럼 설정 삭제 V2
 */
export const removeGridColumnVisibleSettingsV2 = () => {
	localStorage.removeItem(
		`${process.env.REACT_APP_GRID_COLUMN_VISIBLE_V2_STORAGE_NAME}`
	)
}
