export const headerOcsEHealth = [
	{
		field: 'SEQ',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: 'HosNo',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '적용일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: 'Desciption',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'DOCTORID',
		headerName: '의사',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '과',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'USERID',
		headerName: '입력자',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'ACPTTIME',
		headerName: '입력시간',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '환자명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'INSUCD',
		headerName: '보험코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'DOCTOR',
		headerName: '의사명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '생년월일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'WEIGHT',
		headerName: '체중',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'CNT',
		headerName: '태아수',
		type: 'string',
		width: 60,
		editable: false,
	},
	{
		field: 'cmmnNm',
		headerName: '상태',
		type: 'string',
		width: 70,
		editable: false,
	},
]
