import { useCallback, useState, useRef, useEffect } from 'react'
import {
	Button,
	Grid,
	CardContent,
	Card,
	CardActions,
	CardMedia,
	Typography,
	useTheme,
} from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useDropzone } from 'react-dropzone'
import {
	attachFileList,
	fileDelete,
	fileDownloadByFileName,
	fileUpload,
} from 'api/api'
import { alertModal, confirmAlert } from 'util/util'
import DescriptionIcon from '@mui/icons-material/Description'

const FileUploaderComponent = ({
	fileDest,
	attachFileId,
	fileType,
	multiple,
}: {
	fileDest: string
	attachFileId: string
	fileType: any
	multiple: boolean
}) => {
	const theme = useTheme()
	const ref = useRef<HTMLInputElement>(null)
	const [fileList, setFileList] = useState<any[]>([])

	// 첨부파일 업로드시 attachFileId 값이 있으면 그냥 업로드... 없으면 생성 후 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		console.log(acceptedFiles)
		uploadAction(acceptedFiles)
	}, [])

	const uploadAction = (files: File[]) => {
		console.log(ref?.current?.value)
		const attachFileId = ref?.current?.value
		if (attachFileId) {
			fileUpload(files, attachFileId, fileDest).then((res) => {
				// setFileList((prevState) => {
				// 	console.log(prevState)
				// 	console.log(res)
				// 	return [...prevState, ...res]
				// })
				attachFileList(attachFileId).then((res) => {
					console.log(res)
					setFileList(res)
				})
			})
		} else {
			alertModal('첨부파일 마스터 아이디가 없습니다.', 'error', () => {})
			return
		}
	}

	const deleteFile = (fileId: number, fileDest: string) => {
		if (fileId && fileDest && attachFileId) {
			confirmAlert(
				'파일을 삭제하시겠습니까?',
				() => {
					fileDelete(attachFileId, fileId).then((res) => {
						attachFileList(attachFileId).then((res) => {
							console.log(res)
							setFileList(res)
						})
					})
				},
				() => {}
			)
		}
	}

	// 첨부파일 handler
	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop: onDrop,
			accept: fileType,
			multiple: multiple,
		})

	const viewImage = (fileName: string) => {
		let imageWindow = window.open(``, 'image')
		imageWindow?.document.write(
			`<html><body><img src=${process.env.REACT_APP_HOST2}/file/view/name/${fileName} alt='' /></body></html>`
		)
	}

	useEffect(() => {
		attachFileList(attachFileId).then((res) => {
			console.log(res)
			setFileList(res)
		})
	}, [attachFileId])

	return (
		<Grid
			container
			spacing={1}
		>
			<input
				type="hidden"
				value={attachFileId}
				ref={ref}
				readOnly
			/>
			<Grid
				item
				xs={12}
			>
				{fileList?.length === 0 && (
					<div
						className="dropzone dz-clickable dz-started"
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						{!isDragActive && (
							<Card
								sx={{
									backgroundColor: theme.palette.mode === 'dark' ? '#2C3A47' : '#2C3A47',
								}}
							>
								<CardContent sx={{ paddingBottom: '16px !important' }}>
									<Typography
										color="#fff"
										variant="body1"
										component="p"
										sx={{ marginBottom: '0' }}
									>
										<AttachFileIcon
											fontSize="small"
											sx={{ marginBottom: '0' }}
										/>{' '}
										파일을 선택하세요.
									</Typography>
								</CardContent>
							</Card>
						)}
						{isDragReject && (
							<p className="text-warning">지원하지 않는 파일입니다.</p>
						)}
					</div>
				)}
			</Grid>
			<Grid
				item
				xs={12}
			>
				<div
					className="dz-preview dz-processing dz-image-preview dz-success dz-complete"
					style={{ marginTop: '1rem' }}
				>
					<div className="dz-details">
						<div className="dz-filename">
							<Grid
								container
								spacing={2}
							>
								{fileList?.map((file: any) => {
									return (
										<Grid
											item
											xs={6}
											key={file.fileId}
										>
											<Card sx={{ maxWidth: '100%' }}>
												{file?.fileMime?.startsWith('image') ? (
													<CardMedia
														sx={{ height: 150, width: '100%' }}
														image={`${process.env.REACT_APP_HOST2}/file/view/name/${file.fileName}`}
														title={file.fileName}
														onClick={() => viewImage(file.fileName)}
														style={{ cursor: 'pointer' }}
													/>
												) : (
													<div
														style={{
															height: '150px',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<DescriptionIcon />
													</div>
												)}
												<CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
													<Typography
														gutterBottom
														variant="body2"
														component="p"
													>
														{file.orgFileName}
													</Typography>
													<Button
														type="button"
														variant="contained"
														color="info"
														size="small"
														onClick={() =>
															fileDownloadByFileName(file.fileName, file.orgFileName)
														}
													>
														다운로드
													</Button>
												</CardContent>
												<CardActions>
													<Button
														color="warning"
														size="small"
														variant="contained"
														onClick={() => deleteFile(file.fileId, file.fileDest)}
													>
														삭제
													</Button>
												</CardActions>
											</Card>
										</Grid>
									)
								})}
							</Grid>
						</div>
					</div>
					<div className="dz-progress">
						<span
							className="dz-upload"
							data-dz-uploadprogress=""
							style={{ width: '100%' }}
						></span>
					</div>
				</div>
			</Grid>
		</Grid>
	)
}

export default FileUploaderComponent
