import {
	Button,
	Typography,
	Dialog,
	DialogContent,
	useMediaQuery,
	useTheme,
	Divider,
	DialogActions,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TransitionEffect from './TransitionEffect'
import { replaceBrTag } from 'util/stringUtil'

const SimplePopup = ({
	title,
	message,
	simplePopupOpen,
	simplePopupClose,
	isHTML,
}: {
	title: string
	message: string
	simplePopupOpen: boolean
	simplePopupClose: () => void
	isHTML?: boolean
}) => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<Dialog
			fullScreen={isMdUp ? false : true}
			open={simplePopupOpen}
			onClose={simplePopupClose}
			TransitionComponent={TransitionEffect}
			maxWidth="lg"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography
						sx={{ flex: 1 }}
						variant="h6"
						component="div"
					>
						{title}
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={simplePopupClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				{isHTML ? (
					<div dangerouslySetInnerHTML={{ __html: message }} />
				) : (
					<Typography
						variant="body1"
						component="p"
						dangerouslySetInnerHTML={{ __html: replaceBrTag(message) }}
					></Typography>
				)}
			</DialogContent>
			<Divider />
			<DialogActions sx={{ p: 2 }}>
				<Button
					type="button"
					variant="contained"
					onClick={simplePopupClose}
					color="secondary"
				>
					확인
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SimplePopup
