import { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
	Button,
	Grid,
	CardContent,
	Card,
	CardMedia,
	Typography,
	Divider,
} from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { fileDownloadByFileName } from 'api/api'
import DescriptionIcon from '@mui/icons-material/Description'
import TransitionEffect from './TransitionEffect'

const Content = styled('div')(({ theme }) => ({
	display: 'block',
	'& img': {
		width: 'auto !important',
		maxWidth: '100%',
		display: 'block !important',
	},
}))

const BoardView = ({
	dataSet,
	fileList,
	dialogOpen,
	dialogClose,
	attachFileId,
	searchAttachFile,
	boardEdit,
	isDashboard = false,
}: any) => {
	const ref = useRef<HTMLInputElement>(null)
	const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')

	/**
	 * 첨부파일 삭제
	 * @param fileId
	 */
	// const deleteFile = (fileId: number) => {
	//   console.log(fileId, ref.current?.value)
	//   const attachFileId = ref?.current?.value
	//   if (fileId && attachFileId) {
	//     confirmAlert(
	//       '삭제하시겠습니까?',
	//       () => {
	//         fileDelete(attachFileId, fileId).then(res => {
	//           if (res.success === true) {
	//             if (attachFileId) {
	//               attachFileList(attachFileId).then(res => {
	//                 console.log(res)
	//                 searchAttachFile(attachFileId)
	//               })
	//             }
	//           }
	//         })
	//       },
	//       () => {}
	//     )
	//   }
	// }

	const viewImage = (fileName: string) => {
		let imageWindow = window.open(``, 'image')
		imageWindow?.document.write(
			`<html><body><img src=${process.env.REACT_APP_HOST2}/file/view/name/${fileName} alt='' /></body></html>`
		)
	}

	return (
		<Dialog
			fullScreen
			open={dialogOpen}
			onClose={dialogClose}
			TransitionComponent={TransitionEffect}
			scroll={scroll}
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="div"
					>
						공지사항
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={dialogClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<input
				type="hidden"
				value={attachFileId}
				ref={ref}
				readOnly
			/>
			<DialogTitle>{dataSet.title}</DialogTitle>
			<DialogContent dividers={scroll === 'paper'}>
				<Content
					dangerouslySetInnerHTML={{ __html: dataSet.content }}
					sx={{
						paddingBottom: '1rem',
						marginBottom: '1rem',
						'& > img': { maxWidth: '1000px !important' },
					}}
				/>
				<Divider sx={{ marginBottom: '2rem' }} />
				{fileList?.length > 0 && (
					<Typography
						variant="h6"
						component="p"
						sx={{ marginBottom: '2rem' }}
					>
						첨부파일
					</Typography>
				)}
				<Grid
					container
					spacing={2}
				>
					{fileList?.map((file: any) => {
						return (
							<Grid
								item
								xs={6}
								md={2}
								key={file.fileId}
							>
								<Card sx={{ maxWidth: '100%' }}>
									{file?.fileMime?.startsWith('image') ? (
										<CardMedia
											sx={{ height: 150, width: '100%' }}
											image={`${process.env.REACT_APP_HOST2}/file/view/name/${file.fileName}`}
											title={file.fileName}
											onClick={() => viewImage(file.fileName)}
											style={{ cursor: 'pointer' }}
										/>
									) : (
										<div
											style={{
												height: '150px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<DescriptionIcon sx={{ fontSize: '50px' }} />
										</div>
									)}
									<CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
										<Typography
											gutterBottom
											variant="body2"
											component="p"
										>
											{file.orgFileName}
										</Typography>
										<Button
											type="button"
											variant="contained"
											color="info"
											size="small"
											onClick={() =>
												fileDownloadByFileName(file.fileName, file.orgFileName)
											}
										>
											다운로드
										</Button>
									</CardContent>
								</Card>
							</Grid>
						)
					})}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={dialogClose}
				>
					확인
				</Button>
				{!isDashboard && (
					<Button
						variant="contained"
						color="secondary"
						onClick={boardEdit}
					>
						수정
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)
}

export default BoardView
