import { useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SettingsIcon from '@mui/icons-material/Settings'
import InfoIcon from '@mui/icons-material/Info'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import WidgetsIcon from '@mui/icons-material/Widgets'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ScienceIcon from '@mui/icons-material/Science'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import CoronavirusIcon from '@mui/icons-material/Coronavirus'
import HistoryIcon from '@mui/icons-material/History'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import SyncIcon from '@mui/icons-material/Sync'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import TimelineIcon from '@mui/icons-material/Timeline'
import AssessmentIcon from '@mui/icons-material/Assessment'
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'
import BloodtypeIcon from '@mui/icons-material/Bloodtype'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BugReportIcon from '@mui/icons-material/BugReport'
import SummarizeIcon from '@mui/icons-material/Summarize'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop'
import SmsIcon from '@mui/icons-material/Sms'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PaymentIcon from '@mui/icons-material/Payment'
import PhoneIcon from '@mui/icons-material/Phone'
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt'
import BiotechIcon from '@mui/icons-material/Biotech'
import DownloadingIcon from '@mui/icons-material/Downloading'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DownloadIcon from '@mui/icons-material/Download'
import PublishIcon from '@mui/icons-material/Publish'
import PrintIcon from '@mui/icons-material/Print'
import MediationIcon from '@mui/icons-material/Mediation'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SendIcon from '@mui/icons-material/Send'
import GroupsIcon from '@mui/icons-material/Groups'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import ListAltIcon from '@mui/icons-material/ListAlt'
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked'
import MessageIcon from '@mui/icons-material/Message'
import GradingIcon from '@mui/icons-material/Grading'

import { GnbType, TempElementItem } from '../constants/types'
import { setPos1, setPos2 } from '../hooks/pagePos'

import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { darkTheme, lightTheme } from '../constants/defaultValues'
import { selectDisplayMode } from 'hooks/themeMode'

import { setGnbToggle } from 'hooks/gnbToggle'
import { useMediaQuery } from '@mui/material'
import { selectuserInfo } from 'hooks/userInfo'

import { clientIDs } from 'constants/clientIDs'

/**
 * 아코디언 아이콘 설정
 */
type IconType = {
	menuName: string
	menuIcon: keyof typeof icons
	//iconStr: (props: SvgIconProps) => JSX.Element
}
const icons = {
	DashboardIcon,
	ManageAccountsIcon,
	SettingsIcon,
	IntegrationInstructionsIcon,
	InfoIcon,
	WidgetsIcon,
	NewspaperIcon,
	PictureAsPdfIcon,
	ScienceIcon,
	ManageSearchIcon,
	HistoryIcon,
	CoronavirusIcon,
	WorkHistoryIcon,
	SyncIcon,
	AutoGraphIcon,
	TimelineIcon,
	AssessmentIcon,
	DataSaverOnIcon,
	BubbleChartIcon,
	BloodtypeIcon,
	AssignmentTurnedInIcon,
	BugReportIcon,
	SummarizeIcon,
	ScreenSearchDesktopIcon,
	SmsIcon,
	MonetizationOnIcon,
	ReceiptIcon,
	PaymentIcon,
	PhoneIcon,
	MarkUnreadChatAltIcon,
	BiotechIcon,
	DownloadingIcon,
	ContentPasteIcon,
	FileDownloadIcon,
	DownloadIcon,
	PublishIcon,
	PrintIcon,
	MediationIcon,
	SendIcon,
	GroupsIcon,
	ScheduleSendIcon,
	ListAltIcon,
	PlaylistAddCheckCircleIcon,
	ImportContactsIcon,
	AllInboxIcon,
	PhoneLockedIcon,
	MessageIcon,
	GradingIcon,
}

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
	':last-of-type': {
		marginBottom: '60px',
	},
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

/**
 * 좌측 메뉴바
 * @param param0
 * @returns
 */
const Gnb = ({ menuList, open, handleDrawerClose }: any) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const themeMode = useSelector(selectDisplayMode)

	const userInfo = useSelector(selectuserInfo)

	// const gnbToggle = useSelector(selectGnbToggle)
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const [expanded, setExpanded] = useState<string | false>('panel_0')
	const [nowMenu, setNowMenu] = useState(
		userInfo.infoType === 'C' ? '/admin/test/result' : '/admin/dashboard'
	)

	/**
	 * 아코디언 toggle
	 * @param panel
	 * @returns
	 */
	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false)
		}

	/**
	 * 아이콘 지정
	 * @param item
	 * @returns
	 */
	const getIcon = (item: TempElementItem): JSX.Element => {
		const convertedIcon = item as IconType
		const Icon = icons[convertedIcon.menuIcon]
		return <Icon />
	}

	/**
	 * 페이지 이동
	 * @param {*} e : 클릭한 대상 , parent : 부모 level
	 */
	const pageMove = (
		e: { menuName: string; menuLink: string },
		parent: string,
		panelName: string
	) => {
		// console.log(e.menuName, e.menuLink, parent, panelName)
		// if (!isMdUp) {
		// 	dispatch(setGnbToggle(false))
		// }
		// dispatch(setGnbToggle(false))
		console.log('pageMove', e, parent, panelName)
		dispatch(setPos1({ title: parent, link: '' }))
		dispatch(setPos2({ title: e.menuName, link: e.menuLink }))
		setNowMenu(e.menuLink)
		navigate(e.menuLink)
		// accordion expand 처리
		//setExpanded(panelName)
		setExpanded(panelName)
		// console.log('page move')
		// console.log(e.menuName, e.menuLink, parent, panelName)
		// console.log(nowMenu)
	}

	return (
		menuList &&
		menuList.map((item: TempElementItem, index: number) => {
			return (
				<ThemeProvider
					key={item.id}
					theme={themeMode.isDarkMode ? darkTheme : lightTheme}
				>
					<Accordion
						expanded={expanded === `panel_${index}`}
						//expanded={true}
						onChange={handleChange(`panel_${index}`)}
						key={index}
					>
						{item.menuLink === '/admin/dashboard' ||
						item.menuLink === '/admin/search' ||
						item.menuLink === '/admin/test/disease' ||
						item.menuLink === '/admin/document/print' ||
						item.menuLink === '/admin/employee' ? (
							<ListItem
								disablePadding
								sx={{ display: 'block' }}
								key={item.id}
							>
								<ListItemButton
									onClick={() => pageMove(item, '', `panel_${index}`)}
									sx={{ background: 'rgba(0, 0, 0, .03)' }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
											color: item.menuLink === nowMenu ? theme.palette.primary.main : '',
										}}
									>
										<ChevronRightIcon
											sx={{ fontSize: '1.4rem', transform: 'translateX(-5px)' }}
										/>
									</ListItemIcon>
									<ListItemText
										primary={item.menuName}
										sx={{
											marginLeft: '0 !important',
											opacity: open ? 1 : 0,
											color: item.menuLink === nowMenu ? theme.palette.primary.main : '',
										}}
									/>
								</ListItemButton>
							</ListItem>
						) : (
							<AccordionSummary
								aria-controls={`panel${item.id}-content`}
								id={`panel${item.id}-header`}
							>
								<Typography>{item.menuName}</Typography>
							</AccordionSummary>
						)}
						{item?.children?.length > 0 && (
							<AccordionDetails sx={{ padding: '0' }}>
								<List>
									{item &&
										item.children.map((i: any, j: number) => {
											return (
												<ListItem
													disablePadding
													sx={{ display: 'block' }}
													key={i.id}
												>
													{i.menuName === '2023년 8월 이전 결과' ||
													i.menuName === '이전 결과 조회' ? (
														<ListItemButton
															onClick={() => {
																window.open(
																	i.menuName === '이전 결과 조회'
																		? i.menuLink
																		: 'http://61.252.130.67:200',
																	'_blank'
																)
															}}
															sx={{
																minHeight: 48,
																justifyContent: open ? 'initial' : 'center',
																px: 2.5,
															}}
														>
															<ListItemIcon
																sx={{
																	minWidth: 0,
																	mr: open ? 3 : 'auto',
																	justifyContent: 'center',
																	color:
																		i.menuLink === nowMenu ? theme.palette.primary.main : '',
																}}
															>
																{getIcon(i)}
															</ListItemIcon>
															<ListItemText
																primary={i.menuName}
																sx={{
																	opacity: open ? 1 : 0,
																	color:
																		i.menuLink === nowMenu ? theme.palette.primary.main : '',
																}}
															/>
														</ListItemButton>
													) : (clientIDs.includes(userInfo?.clientID) && i.id === 44) ||
													  userInfo.infoType === 'S' ||
													  userInfo.infoType === 'W' ? (
														<ListItemButton
															onClick={() => pageMove(i, item.menuName, `panel_${index}`)}
															sx={{
																minHeight: 48,
																justifyContent: open ? 'initial' : 'center',
																px: 2.5,
															}}
														>
															<ListItemIcon
																sx={{
																	minWidth: 0,
																	mr: open ? 3 : 'auto',
																	justifyContent: 'center',
																	color:
																		i.menuLink === nowMenu ? theme.palette.primary.main : '',
																}}
															>
																{getIcon(i)}
															</ListItemIcon>
															<ListItemText
																primary={i.menuName}
																sx={{
																	opacity: open ? 1 : 0,
																	color:
																		i.menuLink === nowMenu ? theme.palette.primary.main : '',
																}}
															/>
														</ListItemButton>
													) : i.id === 16 ? ( // OCS 연동메뉴 출력 여부
														userInfo.tablePostFix && (
															<>
																<ListItemButton
																	onClick={() => pageMove(i, item.menuName, `panel_${index}`)}
																	sx={{
																		minHeight: 48,
																		justifyContent: open ? 'initial' : 'center',
																		px: 2.5,
																	}}
																>
																	<ListItemIcon
																		sx={{
																			minWidth: 0,
																			mr: open ? 3 : 'auto',
																			justifyContent: 'center',
																			color:
																				i.menuLink === nowMenu ? theme.palette.primary.main : '',
																		}}
																	>
																		{getIcon(i)}
																	</ListItemIcon>
																	<ListItemText
																		primary={i.menuName}
																		sx={{
																			opacity: open ? 1 : 0,
																			color:
																				i.menuLink === nowMenu ? theme.palette.primary.main : '',
																		}}
																	/>
																</ListItemButton>
															</>
														)
													) : (
														i.id !== 44 && (
															<>
																<ListItemButton
																	onClick={() => pageMove(i, item.menuName, `panel_${index}`)}
																	sx={{
																		minHeight: 48,
																		justifyContent: open ? 'initial' : 'center',
																		px: 2.5,
																	}}
																>
																	<ListItemIcon
																		sx={{
																			minWidth: 0,
																			mr: open ? 3 : 'auto',
																			justifyContent: 'center',
																			color:
																				i.menuLink === nowMenu ? theme.palette.primary.main : '',
																		}}
																	>
																		{getIcon(i)}
																	</ListItemIcon>
																	<ListItemText
																		primary={i.menuName}
																		sx={{
																			opacity: open ? 1 : 0,
																			color:
																				i.menuLink === nowMenu ? theme.palette.primary.main : '',
																		}}
																	/>
																</ListItemButton>
															</>
														)
													)}
												</ListItem>
											)
										})}
								</List>
							</AccordionDetails>
						)}
					</Accordion>
				</ThemeProvider>
			)
		})
	)
}

export default memo(Gnb)
