import { createSlice } from '@reduxjs/toolkit'

export const gridColumnVisibleV11Slice = createSlice({
	name: 'gridColumnVisibleV11',
	initialState: {
		info: {},
	},
	reducers: {
		setGridColumnVisibleV11: (state, actions) => {
			console.log(actions.payload)
			state.info = actions.payload
		},
		removeGridColumnVisibleV11: (state) => {
			state.info = {}
		},
	},
})

export const { setGridColumnVisibleV11, removeGridColumnVisibleV11 } =
	gridColumnVisibleV11Slice.actions
export default gridColumnVisibleV11Slice.reducer
export const selectGridColumnVisibleInfoV11 = (state: any) =>
	state.gridColumnVisibleV11
