import XLSX from 'xlsx-js-style'

export const sheetjsExcelDownload = (
	fileName: string,
	downloadType: boolean | undefined,
	sheetName: string,
	list: [],
	header: [],
	column: [],
	clientID: number
) => {
	console.log(downloadType)
	//그리드 헤더 스타일 정의
	const styleHeaderCell = {
		t: 's',
		s: {
			font: {
				name: 'Arial',
				sz: 12,
				bold: true,
				color: { argb: 'ff252525' },
			},
			fill: {
				patternType: 'solid',
				fgColor: { rgb: 'ffebebeb' },
			},
			border: {
				top: { style: 'thin', color: { rgb: '-100000f' } },
				left: { style: 'thin', color: { rgb: '-100000f' } },
				bottom: { style: 'thin', color: { rgb: '-100000f' } },
				right: { style: 'thin', color: { rgb: '-100000f' } },
			},
			alignment: {
				vertical: 'middle',
				horizontal: 'center',
				wrapText: true,
			},
		},
	}

	//그리드 데이타 스타일 정의
	const styleDataCell = {
		t: 's',
		s: {
			'font-size': '15pt',
			font: {
				name: 'Arial',
			},
			fill: {
				patternType: 'solid',
				fgColor: { rgb: 'ffffffff' },
			},
			border: {
				bottom: { style: 'thin', color: { rgb: '-100000f' } },
				right: { style: 'thin', color: { rgb: '-100000f' } },
				bgColor: { indexed: 64 },
			},
			alignment: {
				vertical: 'top',
				horizontal: 'left',
				wrapText: true,
			},
		},
	}

	const wb = XLSX.utils.book_new()
	const sheetRows: any[] = []

	//그리드 헤더 영역
	if (header) {
		const headerRows: any[] = []
		header.forEach((items, index) => {
			headerRows.push({ ...styleHeaderCell, v: items })
		})
		sheetRows.push(headerRows)
	}

	//그리드 데이타 영역
	list.forEach((items, index) => {
		const rowDatas: any[] = [] //Object.values(items);
		column.forEach((key, index) => {
			rowDatas.push({ ...styleDataCell, v: items[key] ?? '' })
		})
		sheetRows.push(rowDatas)
	})

	const ws = XLSX.utils.json_to_sheet(sheetRows, {
		skipHeader: true,
		cellStyles: true,
	})
	XLSX.utils.book_append_sheet(wb, ws, sheetName)

	//컬럼 길이 조정
	autofitColumns(list, ws, column)

	//엑셀 출력
	/**
	 * xls 출력시
	 * bookType을 biff8 지정
	 * bookSST true 지정 false 경우 255자 이후에 짤리는 형상이 일어남
	 * cellStyles이 적용되지 않음
	 * */
	//xls 형식은 bookType을 biff8 지정한다.
	//bookSST true 하여 cell 문자열을 짜리지 않게 한다.
	XLSX.writeFile(wb, fileName + '.' + (downloadType ? 'xlsx' : 'xls'), {
		bookType: downloadType ? 'xlsx' : 'biff8', //Microsoft Office Excel 97-2003 Binary File Format, xls
		bookSST: true,
		cellStyles: true,
	})
}

const autofitColumns = (json: any[], worksheet: any, header?: string[]) => {
	const jsonKeys = header ? header : Object.keys(json[0])

	const objectMaxLength: any[] = []
	for (let i = 0; i < json.length; i++) {
		let value = json[i]
		for (let j = 0; j < jsonKeys.length; j++) {
			if (typeof value[jsonKeys[j]] == 'number') {
				objectMaxLength[j] = 10
			} else {
				const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0

				objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
			}
		}

		let key = jsonKeys
		for (let j = 0; j < key.length; j++) {
			objectMaxLength[j] =
				objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length
		}
	}

	const wscols = objectMaxLength.map((w) => {
		return { width: w < 20 ? 20 : w > 50 ? 50 : w }
	})

	worksheet['!cols'] = wscols
}
