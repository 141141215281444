import { ReactNode } from 'react'

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window
	children: React.ReactElement
}

type ChildProps = {
	children: ReactNode
}

const ReportLayout = ({ children }: Props) => {
	return <>{children}</>
}

export default ReportLayout
