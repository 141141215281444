import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Alert,
	Stack,
	Grid,
	Paper,
	Snackbar,
	Box,
	Button,
	Switch,
	TextField,
	FormControlLabel,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	LinearProgress,
	Select,
	AlertProps,
	FormLabel,
	RadioGroup,
	Radio,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridApi,
} from '@mui/x-data-grid-premium'
import { styled } from '@mui/material/styles'
import Fade from '@mui/material/Fade'
import Slide, { SlideProps } from '@mui/material/Slide'
import Grow, { GrowProps } from '@mui/material/Grow'
import { TransitionProps } from '@mui/material/transitions'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import { selectuserInfo } from 'hooks/userInfo'

import { getSettings } from 'helpers/storage'
import { setSettings } from 'helpers/storage'

interface SettingsModel {
	desnse: string
	row: number
	zoom: string
	res: string
	ocs: string
	resultPatientExand: string
}

function SlideTransition(props: SlideProps) {
	return (
		<Slide
			{...props}
			direction="up"
		/>
	)
}

const Env = () => {
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(false)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {
		console.log(variables)
		setSettings(variables)

		setState({
			...state,
			open: true,
			Transition: SlideTransition,
		})
	}
	const actionDel = () => {}
	const actionSearch = () => {}
	const actionReset = () => {}

	const [variables, setVariables] = useState<SettingsModel>({
		desnse: 'compact',
		row: 10,
		zoom: '100%',
		res: 'n',
		ocs: 'n',
		resultPatientExand: 'y',
	})

	const [state, setState] = useState<{
		open: boolean
		Transition: React.ComponentType<
			TransitionProps & {
				children: React.ReactElement<any, any>
			}
		>
	}>({
		open: false,
		Transition: Fade,
	})

	const handleClose = () => {
		setState({
			...state,
			open: false,
		})
	}

	useEffect(() => {
		const settingValues = getSettings()
		console.log(settingValues)
		setVariables({ ...settingValues })
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden' }}
			>
				<Box sx={{ p: 2 }}>
					<FormControl>
						<FormLabel id="demo-row-radio-buttons-group-label">그리드 폭</FormLabel>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="row-radio-buttons-group"
							value={variables.desnse ?? 'compact'}
							onChange={(e) => setVariables({ ...variables, desnse: e.target.value })}
						>
							<FormControlLabel
								value="compact"
								control={<Radio />}
								label="좁게"
							/>
							<FormControlLabel
								value="standard"
								control={<Radio />}
								label="기본"
							/>
							<FormControlLabel
								value="comfortable"
								control={<Radio />}
								label="넓게"
							/>
						</RadioGroup>
					</FormControl>
				</Box>
				<Box sx={{ p: 2 }}>
					<FormControl>
						<FormLabel id="demo-row-radio-buttons-group-label2">
							한번에 출력할 테이블 행의 갯수
						</FormLabel>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label2"
							name="row-radio-buttons-group2"
							value={variables.row ?? 10}
							onChange={(e) =>
								setVariables({ ...variables, row: parseInt(e.target.value) })
							}
						>
							<FormControlLabel
								value="10"
								control={<Radio />}
								label="10개"
							/>
							<FormControlLabel
								value="20"
								control={<Radio />}
								label="20개"
							/>
							<FormControlLabel
								value="50"
								control={<Radio />}
								label="50개"
							/>
							<FormControlLabel
								value="100"
								control={<Radio />}
								label="100개"
							/>
						</RadioGroup>
					</FormControl>
				</Box>
				<Box sx={{ p: 2 }}>
					<FormControl>
						<FormLabel id="demo-zoom-radio-buttons-group-label">
							화면 확대 비율
						</FormLabel>
						<RadioGroup
							row
							aria-labelledby="demo-zoom-radio-buttons-group-label"
							name="zoom-radio-buttons-group"
							value={variables.desnse ?? 'zoom'}
							onChange={(e) => setVariables({ ...variables, zoom: e.target.value })}
						>
							<FormControlLabel
								value="compact"
								control={<Radio />}
								label="70%"
							/>
							<FormControlLabel
								value="standard"
								control={<Radio />}
								label="80%"
							/>
							<FormControlLabel
								value="standard"
								control={<Radio />}
								label="90%"
							/>
							<FormControlLabel
								value="comfortable"
								control={<Radio />}
								label="100%"
							/>
							<FormControlLabel
								value="comfortable"
								control={<Radio />}
								label="120%"
							/>
						</RadioGroup>
					</FormControl>
				</Box>
			</Paper>
			<Snackbar
				open={state.open}
				onClose={handleClose}
				TransitionComponent={state.Transition}
				message="저장되었습니다."
				key={state.Transition.name}
			/>
		</>
	)
}

export default Env
