import * as React from 'react'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'

/**
 * 화면 상단으로 이동하는 버튼
 * @param props
 * @returns
 */
const ScrollTop = (props: { children: any; window: any }) => {
	const { children, window } = props
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100,
	})

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = (
			(event.target as HTMLDivElement).ownerDocument || document
		).querySelector('#back-to-top-anchor')

		if (anchor) {
			anchor.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			})
		}
	}

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role="presentation"
				sx={{ position: 'fixed', bottom: 16, right: 16 }}
			>
				{children}
			</Box>
		</Fade>
	)
}

export default ScrollTop
