import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpByungcom: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'NM',
		headerName: '환자명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '접수일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '등록번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '생년월일',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'BED',
		headerName: '병동',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'DOCTOR',
		headerName: '의사명',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명',
		type: 'string',
		width: 250,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: 'PK',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
