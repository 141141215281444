import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
	Stack,
	Grid,
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	LinearProgress,
	Select,
	SelectChangeEvent,
	Chip,
	Tooltip,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridRowModel,
	GridToolbar,
	GridApi,
	GridCellValue,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColumnGroupingModel,
	GridCellParams,
	GridDensity,
	useGridApiRef,
	GridRowParams,
	GridSelectionModel,
} from '@mui/x-data-grid-premium'
import { styled } from '@mui/material/styles'
import CustomDetailPanelToggle from 'components/CustomDetailPanelToggle'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { alertModal, confirmAlert } from 'util/util'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import moment from 'moment'
import 'moment/locale/ko'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { CmmnCodeType, CovidInspectionResultSearchType } from 'constants/types'
import { compareDate, isValidDateType } from 'util/util'
import {
	covidList,
	covidExcelAbnormalList,
	covidExcelNegativeList,
	sendSMS,
	covidExcelAllList,
	generatePdf,
	covidExcelInspectionList,
	apiRptTypeCodeList,
} from 'api/api'

import { selectuserInfo } from 'hooks/userInfo'
import { replaceBrTag } from 'util/stringUtil'
import { getSettings } from 'helpers/storage'
import ClientSearchPopup from './clientSearchPopup'
import jsPDF from 'jspdf'
import { valuesInIterator } from 'util/objectUtil'
import CircularIndeterminate from 'components/CircularIndeterminate'

const locales = ['ko'] as const

const LoaderContainer = styled(Grid)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	zIndex: '10000',
	background: 'rgba(0,0,0,0.2)',
}))

const RemarkContainer = styled(`div`)(({ theme }) => ({
	padding: '1rem',
}))

// column grouping
const columnGroupingModel: GridColumnGroupingModel = [
	{
		groupId: 'test_01',
		headerName: '1차',
		description: '',
		headerAlign: 'center',
		headerClassName: 'test_result_1',
		children: [
			{ field: '101' },
			{ field: '102' },
			{ field: '103' },
			{ field: '104' },
			{ field: '105' },
		],
	},
	{
		groupId: 'test_02',
		headerName: '2차',
		freeReordering: true,
		headerAlign: 'center',
		headerClassName: 'test_result_2',
		children: [
			{ field: '201' },
			{ field: '202' },
			{ field: '203' },
			{ field: '204' },
			{ field: '205' },
		],
	},
]

const GB1columnGroupingModel: GridColumnGroupingModel = [
	{
		groupId: 'test_01',
		headerName: '1차',
		description: '',
		headerAlign: 'center',
		headerClassName: 'test_result_1',
		children: [{ field: '101' }, { field: '102' }],
	},
]

const GB2columnGroupingModel: GridColumnGroupingModel = [
	{
		groupId: 'test_01',
		headerName: '1차',
		description: '',
		headerAlign: 'center',
		headerClassName: 'test_result_1',
		children: [{ field: '101' }, { field: '102' }],
	},
	{
		groupId: 'test_02',
		headerName: '2차',
		freeReordering: true,
		headerAlign: 'center',
		headerClassName: 'test_result_2',
		children: [{ field: '201' }, { field: '202' }],
	},
]

const GB3columnGroupingModel: GridColumnGroupingModel = [
	{
		groupId: 'test_01',
		headerName: '1차',
		description: '',
		headerAlign: 'center',
		headerClassName: 'test_result_1',
		children: [
			{ field: '101' },
			{ field: '102' },
			{ field: '103' },
			{ field: '104' },
			{ field: '105' },
		],
	},
	{
		groupId: 'test_02',
		headerName: '2차',
		freeReordering: true,
		headerAlign: 'center',
		headerClassName: 'test_result_2',
		children: [
			{ field: '201' },
			{ field: '202' },
			{ field: '203' },
			{ field: '204' },
			{ field: '205' },
		],
	},
]

const TestCovid = () => {
	// redux store
	const cmdCdList = useSelector(selectCmmnCode)
	const testCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()
	const settings = getSettings()

	const gridRef = useGridApiRef()
	const gridSubRef = useGridApiRef() // 엑셀 (전체)
	const inspectionRef = useGridApiRef() // 엑셀 (동시)
	const abnormalRef = useGridApiRef() // 엑셀 (이상결과)
	const negativeRef = useGridApiRef() // 엑셀 (음성)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [disabledExcel, setDisabledExcel] = useState(false)

	const [loading, setLoading] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		console.log(search)
		if (!disabledSearch) getCovidList()
	}
	const actionReset = () => {
		setSearch({
			CMD: 'ALL',
			testCode: 'ALL',
			clientID: userInfo.clientID,
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			patientName: '',
			chartNo: '',
			socialNumber: '',
			cellNumber: '',
			searchType: 'R',
		})
		setDateRange('7d')
		setSearchClientName('')
		gridRef.current?.setQuickFilterValues([])
		gridRef.current?.setPage(0)
	}

	// grid state
	// const [gridApi, setGridApi] = useState<GridApi | null>(null)
	// const [gridSelectionModel, setGridSelectionModel] =
	// 	useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)
	const [gridCopyRows, setGridCopyRows] = useState<GridRowModel[]>([])

	const [inspectionRows, setInspectionRows] = useState<GridRowModel[]>([])
	const [abnormalRows, setAbnormalRows] = useState<GridRowModel[]>([])
	const [negativeRows, setNegativeRows] = useState<GridRowModel[]>([])

	// const [gridPage, setGridPage] = useState(0)
	// const [gridRowCount, setGridRowCount] = useState(0)

	const [resultCountIsArray, setResultCountIsArray] = useState(false)

	const [resultCount, setResultCount] = useState({
		Cnt: 0, // 전체 카운트
		NCnt: 0, // 음성
		PCnt: 0, // 양성
		ICnt: 0, // 미결정
		RCnt: 0, // 판정불가
		TCnt: 0, // 검사중
	})

	const [resultCountArray, setResultCountArray] = useState([
		{ flag: '', Cnt: 0, NCnt: 0, PCnt: 0, ICnt: 0, RCnt: 0, TCnt: 0 },
	])

	// grid row click event
	// const onRowClick = (e: GridRowModel) => {}

	// 조회조건
	const [search, setSearch] = useState<CovidInspectionResultSearchType>({
		CMD: 'ALL',
		testCode: 'ALL',
		clientID: '',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		patientName: '',
		chartNo: '',
		socialNumber: '',
		cellNumber: '',
		searchType: 'R',
	})
	const [cmdList, setCmdList] = useState<CmmnCodeType[]>([])
	const [testCodeList, setTestCodeList] = useState<CmmnCodeType[]>([])
	const [dateRange, setDateRange] = useState('7d')
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	// 조회조건 공통코드 변경 이벤트
	const searchCmdOnChange = (e: SelectChangeEvent<string>) => {
		setSearch({
			...search,
			CMD: e.target.value,
		})
	}

	const searchWorkCodeOnChange = (e: SelectChangeEvent<string>) => {
		setSearch({
			...search,
			testCode: e.target.value,
		})
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch((prevState) => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const actionExcel = (type: string) => {
		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(search.startDate).isBefore('2023-08-01')) {
			alertModal(
				'검색 시작일은 2023-08-01 이후로 설정해주세요.',
				'error',
				() => {}
			)
			return
		}
		if (moment(search.endDate).isBefore('2023-08-01')) {
			alertModal(
				'검색 종료일은 2023-08-01 이후로 설정해주세요.',
				'error',
				() => {}
			)
			return
		}

		if (type === 'ABNORMAL') {
			// 이상결과
			setLoading(true)
			if (
				userInfo.infoType === 'S' ||
				(userInfo.infoType === 'W' && search.clientID === '')
			) {
				alertModal('고객사를 선택해주세요.', 'error', () => {})
				return
			}
			const request = {
				...search,
				CMD: 'ABNORMAL',
				testCode: '',
			}
			covidList(request)
				.then((res) => {
					console.log(res)
					res.list?.map((item: any, index: number) => (item.rowNum = index + 1))
					setAbnormalRows(res.list)
					setTimeout(() => {
						abnormalRef.current?.exportDataAsExcel()
					}, 500)
				})
				.finally(() => {
					setLoading(false)
				})
		} else if (type === 'NEGATIVE') {
			// 음성
			setLoading(true)
			if (
				userInfo.infoType === 'S' ||
				(userInfo.infoType === 'W' && search.clientID === '')
			) {
				alertModal('고객사를 선택해주세요.', 'error', () => {})
				return
			}
			const request = {
				...search,
				CMD: 'NEGATIVE',
				testCode: '',
			}
			covidList(request)
				.then((res) => {
					console.log(res)
					res.list?.map((item: any, index: number) => (item.rowNum = index + 1))
					setNegativeRows(res.list)
					setTimeout(() => {
						negativeRef.current?.exportDataAsExcel()
					}, 500)
				})
				.finally(() => {
					setLoading(false)
				})
		} else if (type === 'INSPECTION') {
			// 동시
			setLoading(true)
			if (
				userInfo.infoType === 'S' ||
				(userInfo.infoType === 'W' && search.clientID === '')
			) {
				alertModal('고객사를 선택해주세요.', 'error', () => {})
				return
			}
			const request = {
				...search,
				CMD: 'INSPECTION',
				testCode: 'GB3',
			}
			covidList(request)
				.then((res) => {
					console.log(res)
					res.list?.map((item: any, index: number) => (item.rowNum = index + 1))
					setInspectionRows(res.list)
					setTimeout(() => {
						inspectionRef.current?.exportDataAsExcel()
					}, 500)
				})
				.finally(() => {
					setLoading(false)
				})
		} else if (type === 'ALL') {
			setLoading(true)
			// confirmAlert(
			// 	'데이터가 많을 경우<br />시간이 많이 소요 될 수 있습니다.<br />계속 하시겠습니까?',
			// 	() => {
			// 		setDisabledExcel(true)
			// 		const request = {
			// 			...search,
			// 			clientID: userInfo.infoType === 'S' ? search.clientID : userInfo.clientID,
			// 		}
			// 		covidExcelAllList(request).finally(() => {
			// 			setDisabledExcel(false)
			// 			setLoading(false)
			// 		})
			// 	},
			// 	() => {}
			// )
		}
	}

	const reportPrintAction = () => {
		const datas = gridRef.current?.getSelectedRows()
		console.log(datas)
		const iterArray = valuesInIterator(datas)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			const doc = new jsPDF()
			let pageSize = 0
			let docPageSize = 0
			let noDataSize = 0
			const requests = iterArray.map((element: any) => {
				const request = {
					ReceptionIDs: element.ReceptionID,
				}
				return generatePdf(request).then((res) => {
					console.log(res)
					const list = res[0].body
					if (list.length > 0) {
						list.forEach((item: any, index: number) => {
							const imgData = item?.URL
							if (imgData && pageSize > 0) {
								doc.addPage()
							}
							if (imgData) {
								console.log(item)
								doc.addImage(imgData, item?.FileExtension, 0, 0, 210, 297)
							} else {
								noDataSize++
							}
							pageSize++
							docPageSize++
						})
					}
				})
			})

			Promise.all(requests)
				.then((responses) => {
					// 모든 axios 요청이 완료된 후 실행될 코드
					console.log('모든 요청이 완료되었습니다.', noDataSize)
					// responses 배열에는 각 요청의 응답이 순서대로 저장되어 있음
					if (iterArray.length > 0 && docPageSize === noDataSize) {
						alertModal('출력할 리포트가 없습니다.', 'error', () => {})
						return
					} else {
						doc.setProperties({
							title: '결과보고서',
						})
						window.open(
							URL.createObjectURL(doc.output('blob')),
							'_blank',
							'width=900, height=1000'
						)
					}
				})
				.catch((error) => {
					// 오류 처리
					alertModal('오류가 발생했습니다.', 'error', () => {})
					return
				})
		}
	}

	const getCovidList = () => {
		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			search.clientID === ''
		) {
			alertModal('고객사를 선택해주세요.', 'error', () => {})
			return
		}

		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(search.startDate).isBefore('2023-08-01')) {
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
		}
		setGridLoading(true)
		setDisabledSearch(true)

		const request = {
			...search,
			CMD: search.CMD === 'ALL' ? '' : search.CMD,
			testCode: search.testCode === 'ALL' ? '' : search.testCode,
			startDate: moment(search.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: search.startDate,
			endDate: search.endDate,
		}
		console.log(request)
		covidList(request)
			.then((res) => {
				console.log(res)
				res.list?.map((item: any, index: number) => (item.rowNum = index))
				setGridRows(res.list)
				setGridCopyRows(res.list)
				if (Array.isArray(res.count)) {
					setResultCountArray(res.count)
					setResultCountIsArray(true)
				} else {
					setResultCount(res.count)
					setResultCountIsArray(false)
				}
			})
			.finally(() => {
				setGridLoading(false)
				setDisabledSearch(false)
				gridRef.current?.setQuickFilterValues([])
				gridRef.current?.setPage(0)
			})
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	const smsAction = (row: any) => {
		console.log(row)
		const { ReceptionID } = row || ''
		console.log(ReceptionID)
		if (ReceptionID) {
			confirmAlert(
				'SMS를 전송하시겠습니까?',
				() => {
					sendSMS(ReceptionID).then((res) => {
						if (res) {
							alertModal('SMS 전송이 완료되었습니다.', 'success', () => {})
						}
					})
				},
				() => {}
			)
		}
	}

	useEffect(() => {
		// 검색조건의 공통코드
		const cmd = extractCmmnCode(cmdCdList, 'CMD', 1)
		const testCode = extractCmmnCode(testCdList, 'TEST_CODE', 1)
		setCmdList(cmd?.level2)
		// 검체 종류의 셀렉트박스 기초 value
		// cmd?.level2.map((item: { id: any; cmmnCd: string }) => {
		// 	if (item.cmmnCd === 'ALL') {
		// 		setSearch({
		// 			...search,
		// 			CMD: item.cmmnCd,
		// 		})
		// 	}
		// })
		cmd?.level2.map(
			(item: { id: any; cmmnCd: string }) =>
				item.cmmnCd === 'ALL' &&
				setSearch({
					...search,
					CMD: item.cmmnCd,
				})
		)
		// 검사구분의 셀렉트박스 기초 value
		console.log(testCode?.level2)
		setTestCodeList(testCode?.level2)
		// workCode?.level2.map((item: { id: any; cmmnCd: string }) => {
		//   if (item.cmmnCd === '2') {
		//     console.log('workCode', item.cmmnCd)
		//     console.log(item)
		setSearch({
			...search,
			testCode: 'ALL',
		})
		//   }
		// })
		if (userInfo.infoType === 'C') getCovidList()
	}, [])

	return (
		<>
			{loading && (
				<LoaderContainer>
					<CircularIndeterminate />
				</LoaderContainer>
			)}
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack
				sx={{
					marginBottom: '1rem',
					position: 'sticky',
					top: 120,
					left: 0,
					right: 0,
					zIndex: 1,
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '55px',
						top: '0px',
					},
				}}
			>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onKeyUp={onKeyUp}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex' }}>
									<div
										style={{ flex: '1 1 0', paddingRight: '0.25rem', minWidth: '100px' }}
									>
										<FormControl fullWidth>
											<InputLabel id="searchType-select-label">기간 유형</InputLabel>
											<Select
												labelId="searchType-select-label"
												id="searchTypee-simple-select"
												value={search.searchType}
												label="기간 유형"
												onChange={(e) => {
													setSearch({
														...search,
														searchType: e.target.value,
													})
												}}
												size={'small'}
											>
												<MenuItem value={'R'}>접수일자</MenuItem>
												<MenuItem value={'D'}>보고일자</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
											minWidth: '145px',
										}}
									>
										<DatePicker
											label="시작일"
											value={search.startDate}
											mask={'____-__-__'}
											inputFormat={'YYYY-MM-DD'}
											onChange={(newValue) => {
												dateRanageOnChange(newValue, 'startDate')
											}}
											renderInput={(params) => (
												<TextField
													size="small"
													{...params}
													fullWidth
												/>
											)}
										/>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
											minWidth: '145px',
										}}
									>
										<DatePicker
											label="종료일"
											value={search.endDate}
											mask={'____-__-__'}
											inputFormat={'YYYY-MM-DD'}
											onChange={(newValue) => {
												dateRanageOnChange(newValue, 'endDate')
											}}
											renderInput={(params) => (
												<TextField
													size="small"
													{...params}
													fullWidth
												/>
											)}
										/>
									</div>
								</div>
								<div style={{ display: 'flex' }}>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<FormControl fullWidth>
											<InputLabel id="date-range-select-label">조회 기간</InputLabel>
											<Select
												labelId="date-range-select-label"
												id="date-range-simple-select"
												value={dateRange}
												label="조회 기간"
												onChange={searchDateRanageOnChange}
												size={'small'}
											>
												<MenuItem value={'0'}>선택하세요.</MenuItem>
												<MenuItem value={'7d'}>7일</MenuItem>
												<MenuItem value={'15d'}>15일</MenuItem>
												<MenuItem value={'1m'}>1개월</MenuItem>
												<MenuItem value={'3m'}>3개월</MenuItem>
												<MenuItem value={'6m'}>6개월</MenuItem>
												<MenuItem value={'1y'}>1년</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<FormControl fullWidth>
											<InputLabel id="CMD-simple-select-label">결과 구분</InputLabel>
											<Select
												labelId="CMD-simple-select-label"
												id="CMD"
												label="결과 구분"
												value={search.CMD}
												onChange={searchCmdOnChange}
												size={'small'}
											>
												{cmdList &&
													cmdList.length > 0 &&
													cmdList.map(
														(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
															<MenuItem
																key={item.id}
																value={item?.cmmnCd}
															>
																{item?.cmmnNm}
															</MenuItem>
														)
													)}
											</Select>
										</FormControl>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<FormControl fullWidth>
											<InputLabel id="TESTCODE-simple-select-label">검사 구분</InputLabel>
											<Select
												labelId="TESTCODE-simple-select-label"
												id="TESTCODE"
												label="검사 구분"
												value={search.testCode}
												onChange={searchWorkCodeOnChange}
												size={'small'}
											>
												<MenuItem value="ALL">전체</MenuItem>
												{testCodeList &&
													testCodeList.length > 0 &&
													testCodeList.map(
														(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
															<MenuItem
																key={item.id}
																value={item?.cmmnCd}
															>
																{item?.cmmnNm}
															</MenuItem>
														)
													)}
											</Select>
										</FormControl>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<TextField
											label="수진자명"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											value={search.patientName}
											onChange={(e) => {
												setSearch({
													...search,
													patientName: e.target.value,
												})
											}}
										/>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<TextField
											label="차트번호"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											value={search.chartNo}
											onChange={(e) => {
												setSearch({
													...search,
													chartNo: e.target.value,
												})
											}}
										/>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<TextField
											label="주민번호"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											value={search.socialNumber}
											onChange={(e) => {
												setSearch({
													...search,
													socialNumber: e.target.value,
												})
											}}
										/>
									</div>
									<div
										style={{
											flex: '1 1 0',
											paddingLeft: '0.25rem',
											paddingRight: '0.25rem',
										}}
									>
										<TextField
											label="전화번호"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											value={search.cellNumber}
											onChange={(e) => {
												setSearch({
													...search,
													cellNumber: e.target.value,
												})
											}}
										/>
									</div>
									{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
										<div
											style={{
												flex: '1 1 0',
												paddingLeft: '0.25rem',
												paddingRight: '0.25rem',
											}}
										>
											<TextField
												label="거래처"
												variant="outlined"
												size="small"
												type="text"
												fullWidth
												aria-readonly={true}
												value={searchClientName}
												onClick={clientPopupOpenAction}
											/>
										</div>
									)}
								</div>
							</div>
						</LocalizationProvider>
					</Box>
				</PageBg>
			</Stack>
			<PageBg style={{ marginTop: '1rem', width: '100%', overflow: 'hidden' }}>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '1rem',
							}}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								수진자 리스트
							</Typography>
							<div>
								<Button
									color="secondary"
									size="small"
									variant="contained"
									startIcon={<FileDownloadIcon />}
									sx={{ marginRight: '0.5rem' }}
									onClick={() => gridSubRef.current?.exportDataAsExcel()}
									disabled={disabledExcel}
								>
									엑셀(전체)
								</Button>
								<Button
									color="secondary"
									size="small"
									variant="contained"
									startIcon={<FileDownloadIcon />}
									sx={{ marginRight: '0.5rem' }}
									onClick={() => actionExcel('INSPECTION')}
									disabled={disabledExcel}
								>
									엑셀(동시검사)
								</Button>
								<Button
									color="secondary"
									size="small"
									variant="contained"
									startIcon={<FileDownloadIcon />}
									sx={{ marginRight: '0.5rem' }}
									onClick={() => actionExcel('ABNORMAL')}
									disabled={disabledExcel}
								>
									엑셀(이상결과)
								</Button>
								<Button
									color="secondary"
									size="small"
									variant="contained"
									startIcon={<FileDownloadIcon />}
									onClick={() => actionExcel('NEGATIVE')}
									disabled={disabledExcel}
								>
									엑셀(음성)
								</Button>
							</div>
						</Box>
						<Box
							sx={{
								width: '100%',
								overflow: 'auto',
								'@media (max-width: 1199px)': {
									// height: '600px',
								},
								'& .test_result_1': {
									color: '#000000',
									backgroundColor: '#e3f2fd',
								},
								'& .test_result_2': {
									color: '#000000',
									backgroundColor: '#b3dcfa',
								},
								'& .super-app.negative': {
									backgroundColor: 'rgba(157, 255, 118, 0.49)',
									color: '#1a3e72',
									fontWeight: '600',
								},
								'& .super-app.positive': {
									backgroundColor: '#d47483',
									color: '#fff',
									fontWeight: '600',
								},
							}}
						>
							<DataGridPremium
								apiRef={gridRef}
								rows={gridRows}
								getRowId={(row) => row.rowNum}
								columns={
									search.testCode === 'GB1'
										? [
												{
													...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
													type: 'string',
													renderCell: (params) => (
														<CustomDetailPanelToggle
															id={params.id}
															value={params?.row?.Remark}
														/>
													),
												},
												{
													field: 'rowNum',
													headerName: '번호',
													type: 'number',
													width: 100,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionID', // acptdd
													headerName: '접수번호',
													type: 'string',
													width: 90,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionDate', // acptdd
													headerName: '접수일자',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'ReceptionRegNum', // acptno
													headerName: '접수번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'PatientName', // nm
													headerName: '환자명',
													type: 'string',
													width: 70,
													editable: false,
												},
												{
													field: 'SocialNumber', // rgstno
													headerName: '주민번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'ChartNo', // chartno
													headerName: '차트번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'TestName',
													headerName: '검사명',
													type: 'string',
													width: 300,
													editable: false,
												},
												{
													field: '101', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '102',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: 'D90',
													headerName: '보고일시',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'C10',
													headerName: '재검여부',
													type: 'string',
													width: 90,
													editable: false,
													renderCell: (params) => {
														return params.value === undefined ? '' : '재검'
													},
												},
												{
													field: 'SMSResult',
													headerName: '비고',
													type: 'string',
													width: 250,
													editable: false,
												},
												{
													field: 'CellNumber',
													headerName: '휴대폰번호',
													type: 'string',
													width: 150,
													editable: false,
												},
												// {
												// 	field: 'ApplyType',
												// 	headerName: '접수구분',
												// 	type: 'string',
												// 	width: 90,
												// 	editable: false,
												// },
												{
													field: 'result',
													headerName: '결과',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const onClick = (e: any) => {
															// e.stopPropagation() // don't select this row after clicking

															console.log(e)

															setGridSelectionModel([params.id])

															const ReceptionID = params.row.ReceptionID
															const PatientName = params.row?.PatientName || ''
															const CharNo = params.row?.ChartNo || ''
															const TestCode = params.row?.TestCode || ''
															const ClientID =
																userInfo.infoType === 'S' ? search.clientID : userInfo.clientID

															if (ReceptionID) {
																window.open(
																	`/report/covid?receptionID=${ReceptionID}&testCode=${TestCode}&chartNo=${CharNo}&patientName=${PatientName}&clientID=${ClientID}`,
																	'',
																	'width=900,height=900'
																)
															}
														}

														return (
															<Button
																variant="contained"
																color="secondary"
																size="small"
																onClick={onClick}
															>
																출력
															</Button>
														)
													},
												},
												{
													field: 'sms',
													headerName: '문자',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const { CellNumber, ApplyType, ApplyType2 } = params.row
														const D102 = params.row['102']
														const D202 = params.row['202']

														// ApplyType2 split \n 문자로 배열 분리
														const ApplyType2Array = ApplyType2?.split('\n')

														// console.log(D102, D202)

														let result = ''
														let num = '0'
														if (D102 && !D202) {
															result = D102
														} else if (D102 && D202) {
															result = D202
														}

														result = result.toUpperCase()

														if (result?.startsWith('POS')) {
															num = '5'
														} else if (result?.startsWith('NEG')) {
															num = '1'
														} else if (result?.startsWith('IND')) {
															num = '6'
														} else if (
															result?.startsWith('IC') ||
															result?.startsWith('RESAMPLE') ||
															result?.startsWith('INVALID')
														) {
															num = '8'
														}

														// num in ApplyType2Array
														const ApplyType2ArrayNum = ApplyType2Array?.includes(num)

														return CellNumber && ApplyType === num && result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : CellNumber &&
														  !ApplyType &&
														  ApplyType2ArrayNum &&
														  num &&
														  result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : (
															<></>
														)
													},
												},
										  ]
										: search.testCode === 'GB2'
										? [
												{
													...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
													type: 'string',
													renderCell: (params) => (
														<CustomDetailPanelToggle
															id={params.id}
															value={params?.row?.Remark}
														/>
													),
												},
												{
													field: 'rowNum',
													headerName: '번호',
													type: 'number',
													width: 100,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionID', // acptdd
													headerName: '접수번호',
													type: 'string',
													width: 90,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionDate', // acptdd
													headerName: '접수일자',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'ReceptionRegNum', // acptno
													headerName: '접수번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'PatientName', // nm
													headerName: '환자명',
													type: 'string',
													width: 70,
													editable: false,
												},
												{
													field: 'SocialNumber', // rgstno
													headerName: '주민번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'ChartNo', // chartno
													headerName: '차트번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'TestName',
													headerName: '검사명',
													type: 'string',
													width: 300,
													editable: false,
												},
												{
													field: '101', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '102',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '201', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '202',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: 'D90',
													headerName: '보고일시',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'C10',
													headerName: '재검여부',
													type: 'string',
													width: 90,
													editable: false,
													renderCell: (params) => {
														return params.value === undefined ? '' : '재검'
													},
												},
												{
													field: 'SMSResult',
													headerName: '비고',
													type: 'string',
													width: 250,
													editable: false,
												},
												{
													field: 'CellNumber',
													headerName: '휴대폰번호',
													type: 'string',
													width: 150,
													editable: false,
												},
												// {
												// 	field: 'ApplyType',
												// 	headerName: '접수구분',
												// 	type: 'string',
												// 	width: 90,
												// 	editable: false,
												// },
												{
													field: 'result',
													headerName: '결과',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const onClick = (e: any) => {
															e.stopPropagation() // don't select this row after clicking

															const ReceptionID = params.row.ReceptionID
															const PatientName = params.row?.PatientName || ''
															const CharNo = params.row?.ChartNo || ''
															const TestCode = params.row?.TestCode || ''
															const ClientID =
																userInfo.infoType === 'S' ? search.clientID : userInfo.clientID

															if (ReceptionID) {
																window.open(
																	`/report/covid?receptionID=${ReceptionID}&testCode=${TestCode}&chartNo=${CharNo}&patientName=${PatientName}&clientID=${ClientID}`,
																	'',
																	'width=900,height=900'
																)
															}
														}

														return (
															<Button
																variant="contained"
																color="secondary"
																size="small"
																onClick={onClick}
															>
																출력
															</Button>
														)
													},
												},
												{
													field: 'sms',
													headerName: '문자',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const { CellNumber, ApplyType, ApplyType2 } = params.row
														const D102 = params.row['102']
														const D202 = params.row['202']

														// ApplyType2 split \n 문자로 배열 분리
														const ApplyType2Array = ApplyType2?.split('\n')

														// console.log(D102, D202)

														let result = ''
														let num = '0'
														if (D102 && !D202) {
															result = D102
														} else if (D102 && D202) {
															result = D202
														}

														result = result.toUpperCase()

														if (result?.startsWith('POS')) {
															num = '5'
														} else if (result?.startsWith('NEG')) {
															num = '1'
														} else if (result?.startsWith('IND')) {
															num = '6'
														} else if (
															result?.startsWith('IC') ||
															result?.startsWith('RESAMPLE') ||
															result?.startsWith('INVALID')
														) {
															num = '8'
														}

														// num in ApplyType2Array
														const ApplyType2ArrayNum = ApplyType2Array?.includes(num)

														return CellNumber && ApplyType === num && result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : CellNumber &&
														  !ApplyType &&
														  ApplyType2ArrayNum &&
														  num &&
														  result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : (
															<></>
														)
													},
												},
										  ]
										: search.testCode === 'GB3'
										? [
												{
													...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
													type: 'string',
													renderCell: (params) => (
														<CustomDetailPanelToggle
															id={params.id}
															value={params?.row?.Remark}
														/>
													),
												},
												{
													field: 'rowNum',
													headerName: '번호',
													type: 'number',
													width: 100,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionID', // acptdd
													headerName: '접수번호',
													type: 'string',
													width: 90,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionDate', // acptdd
													headerName: '접수일자',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'ReceptionRegNum', // acptno
													headerName: '접수번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'PatientName', // nm
													headerName: '환자명',
													type: 'string',
													width: 70,
													editable: false,
												},
												{
													field: 'SocialNumber', // rgstno
													headerName: '주민번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'ChartNo', // chartno
													headerName: '차트번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'TestName',
													headerName: '검사명',
													type: 'string',
													width: 300,
													editable: false,
												},
												{
													field: '101', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '102',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '103',
													headerName: 'Influ-A',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '104',
													headerName: 'Influ-B',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '105',
													headerName: 'rsv',
													type: 'string',
													width: 150,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '201', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '202',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '203',
													headerName: 'Influ-A',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '204',
													headerName: 'Influ-B',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '205',
													headerName: 'rsv',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: 'D90',
													headerName: '보고일시',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'C10',
													headerName: '재검여부',
													type: 'string',
													width: 90,
													editable: false,
													renderCell: (params) => {
														return params.value === undefined ? '' : '재검'
													},
												},
												{
													field: 'SMSResult',
													headerName: '비고',
													type: 'string',
													width: 250,
													editable: false,
												},
												{
													field: 'CellNumber',
													headerName: '휴대폰번호',
													type: 'string',
													width: 150,
													editable: false,
												},
												// {
												// 	field: 'ApplyType',
												// 	headerName: '접수구분',
												// 	type: 'string',
												// 	width: 90,
												// 	editable: false,
												// },
												{
													field: 'result',
													headerName: '결과',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const onClick = (e: any) => {
															e.stopPropagation() // don't select this row after clicking

															const ReceptionID = params.row.ReceptionID
															const PatientName = params.row?.PatientName || ''
															const CharNo = params.row?.ChartNo || ''
															const TestCode = params.row?.TestCode || ''
															const ClientID =
																userInfo.infoType === 'S' ? search.clientID : userInfo.clientID

															if (ReceptionID) {
																window.open(
																	`/report/covid?receptionID=${ReceptionID}&testCode=${TestCode}&chartNo=${CharNo}&patientName=${PatientName}&clientID=${ClientID}`,
																	'',
																	'width=900,height=900'
																)
															}
														}

														return (
															<Button
																variant="contained"
																color="secondary"
																size="small"
																onClick={onClick}
															>
																출력
															</Button>
														)
													},
												},
												{
													field: 'sms',
													headerName: '문자',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const { CellNumber, ApplyType, ApplyType2 } = params.row
														const D102 = params.row['102']
														const D202 = params.row['202']

														// ApplyType2 split \n 문자로 배열 분리
														const ApplyType2Array = ApplyType2?.split('\n')

														// console.log(D102, D202)

														let result = ''
														let num = '0'
														if (D102 && !D202) {
															result = D102
														} else if (D102 && D202) {
															result = D202
														}

														result = result.toUpperCase()

														if (result?.startsWith('POS')) {
															num = '5'
														} else if (result?.startsWith('NEG')) {
															num = '1'
														} else if (result?.startsWith('IND')) {
															num = '6'
														} else if (
															result?.startsWith('IC') ||
															result?.startsWith('RESAMPLE') ||
															result?.startsWith('INVALID')
														) {
															num = '8'
														}

														// num in ApplyType2Array
														const ApplyType2ArrayNum = ApplyType2Array?.includes(num)

														return CellNumber && ApplyType === num && result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : CellNumber &&
														  !ApplyType &&
														  ApplyType2ArrayNum &&
														  num &&
														  result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : (
															<></>
														)
													},
												},
										  ]
										: [
												{
													...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
													type: 'string',
													renderCell: (params) => (
														<CustomDetailPanelToggle
															id={params.id}
															value={params?.row?.Remark}
														/>
													),
												},
												{
													field: 'rowNum',
													headerName: '번호',
													type: 'number',
													width: 100,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionID', // acptdd
													headerName: '접수번호',
													type: 'string',
													width: 90,
													editable: false,
													hideable: true,
													hide: true,
												},
												{
													field: 'ReceptionDate', // acptdd
													headerName: '접수일자',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'ReceptionRegNum', // acptno
													headerName: '접수번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'PatientName', // nm
													headerName: '환자명',
													type: 'string',
													width: 70,
													editable: false,
												},
												{
													field: 'SocialNumber', // rgstno
													headerName: '주민번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'ChartNo', // chartno
													headerName: '차트번호',
													type: 'string',
													width: 80,
													editable: false,
												},
												{
													field: 'TestName',
													headerName: '검사명',
													type: 'string',
													width: 300,
													editable: false,
												},
												{
													field: '101', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '102',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '103',
													headerName: 'Influ-A',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '104',
													headerName: 'Influ-B',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '105',
													headerName: 'rsv',
													type: 'string',
													width: 150,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '201', // rslt1e
													headerName: '(E/N) gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '202',
													headerName: 'RdRp gene',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '203',
													headerName: 'Influ-A',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '204',
													headerName: 'Influ-B',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: '205',
													headerName: 'rsv',
													type: 'string',
													width: 150,
													editable: false,
													cellClassName: (params: GridCellParams<any>) => {
														if (params.value == null) {
															return ''
														}

														return clsx('super-app', {
															nagative:
																!params.value?.startsWith('Pos') &&
																!params.value?.startsWith('Ind'),
															positive:
																params.value?.startsWith('Pos') ||
																params.value?.startsWith('Ind'),
														})
													},
												},
												{
													field: 'D90',
													headerName: '보고일시',
													type: 'string',
													width: 90,
													editable: false,
												},
												{
													field: 'C10',
													headerName: '재검여부',
													type: 'string',
													width: 90,
													editable: false,
													renderCell: (params) => {
														return params.value === undefined ? '' : '재검'
													},
												},
												{
													field: 'SMSResult',
													headerName: '비고',
													type: 'string',
													width: 250,
													editable: false,
												},
												{
													field: 'CellNumber',
													headerName: '휴대폰번호',
													type: 'string',
													width: 150,
													editable: false,
												},
												// {
												// 	field: 'ApplyType',
												// 	headerName: '접수구분',
												// 	type: 'string',
												// 	width: 90,
												// 	editable: false,
												// },
												{
													field: 'result',
													headerName: '결과',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const onClick = (e: any) => {
															e.stopPropagation() // don't select this row after clicking
															const ReceptionID = params.row.ReceptionID
															const ClientID =
																userInfo.infoType === 'S' ? search.clientID : userInfo.clientID

															setTimeout(() => {
																const request = {
																	receptionIDs: ReceptionID,
																	clientID:
																		userInfo.infoType === 'S' || userInfo.infoType === 'W'
																			? search.clientID
																			: userInfo.clientID,
																}
																console.log(request)
																apiRptTypeCodeList(request).then((res) => {
																	console.log(res)

																	const arr = new Array()

																	// 영문 결과지 A915, A921 옵션 체크 (PassportNumber가 없으면 삭제) PassportNumber
																	const filterArray2 = ['A915', 'A921']
																	if (
																		res.some((item: any) =>
																			filterArray2.includes(item.RptTypeCode)
																		)
																	) {
																		res.map((item: any) => {
																			if (
																				item.RptTypeCode === 'A915' ||
																				item.RptTypeCode === 'A921'
																			) {
																				if (item?.PassportNumber !== undefined) {
																					arr.push(item)
																				}
																			} else {
																				arr.push(item)
																			}
																		})
																	}

																	const filterArray3 = ['A175', 'A177', 'A915', 'A921']
																	if (
																		!res.some((item: any) =>
																			filterArray3.includes(item.RptTypeCode)
																		)
																	) {
																		res.map((item: any) => {
																			if (!filterArray3.includes(item.RptTypeCode)) {
																				console.table(item)
																				arr.push(item)
																			}
																		})
																	}

																	console.log(arr)
																	if (res.length > 0) {
																		const request = {
																			receptionID: ReceptionID,
																			clientID:
																				userInfo.infoType === 'S' || userInfo.infoType === 'W'
																					? search.clientID
																					: userInfo.clientID,
																			rptTypeCode: res[0]?.RptTypeCode,
																		}

																		window.open(
																			`${process.env.REACT_APP_HOST}/rpt/reportCovid?receptionID=${
																				request.receptionID
																			}&clientID=${request.clientID}&rptTypeCode=${
																				request.rptTypeCode
																			}&zoom=${settings.zoom ? settings.zoom : '100'}`,
																			'_blank',
																			'width=900, height=1000'
																		)
																	}
																})
															}, 100)
														}

														return (
															<Button
																variant="contained"
																color="secondary"
																size="small"
																onClick={onClick}
															>
																출력
															</Button>
														)
													},
												},
												{
													field: 'sms',
													headerName: '문자',
													type: 'string',
													width: 85,
													editable: false,
													renderCell: (params) => {
														const { CellNumber, ApplyType, ApplyType2 } = params.row
														const D102 = params.row['102']
														const D202 = params.row['202']

														// ApplyType2 split \n 문자로 배열 분리
														const ApplyType2Array = ApplyType2?.split('\n')

														// console.log(D102, D202)

														let result = ''
														let num = '0'
														if (D102 && !D202) {
															result = D102
														} else if (D102 && D202) {
															result = D202
														}

														result = result.toUpperCase()

														if (result?.startsWith('POS')) {
															num = '5'
														} else if (result?.startsWith('NEG')) {
															num = '1'
														} else if (result?.startsWith('IND')) {
															num = '6'
														} else if (
															result?.startsWith('IC') ||
															result?.startsWith('RESAMPLE') ||
															result?.startsWith('INVALID')
														) {
															num = '8'
														}

														// num in ApplyType2Array
														const ApplyType2ArrayNum = ApplyType2Array?.includes(num)

														return CellNumber && ApplyType === num && result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : CellNumber &&
														  !ApplyType &&
														  ApplyType2ArrayNum &&
														  num &&
														  result !== '검사중' ? (
															<Button
																variant="contained"
																size="small"
																onClick={() => smsAction(params.row)}
															>
																전송
															</Button>
														) : (
															<></>
														)
													},
												},
										  ]
								}
								pageSize={gridPageSize}
								onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								pagination
								autoHeight={true}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								components={{
									Toolbar: GridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								componentsProps={{
									toolbar: {
										csvOptions: { disableToolbarButton: true },
										excelOptions: { disableToolbarButton: true },
										printOptions: { disableToolbarButton: true },
										showQuickFilter: true,
										quickFilterProps: { debounceMs: 250 },
									},
								}}
								loading={gridLoading}
								checkboxSelection
								onSelectionModelChange={(newSelectionModel) => {
									setGridSelectionModel(newSelectionModel)
								}}
								selectionModel={gridSelectionModel}
								// onRowClick={onRowClick}
								rowThreshold={0}
								getDetailPanelContent={({ row }) => (
									<RemarkContainer>
										<Typography
											variant="body2"
											component="p"
											dangerouslySetInnerHTML={{
												__html: replaceBrTag(row.Remark),
											}}
										></Typography>
									</RemarkContainer>
								)}
								getDetailPanelHeight={({ row }) => 'auto'}
								experimentalFeatures={{ columnGrouping: true }}
								columnGroupingModel={
									search.testCode === 'GB1'
										? GB1columnGroupingModel
										: search.testCode === 'GB2'
										? GB2columnGroupingModel
										: search.testCode === 'GB3'
										? GB3columnGroupingModel
										: columnGroupingModel
								}
								density={(env.desnse as GridDensity) || 'compact'}
								// initialState={{
								// 	pinnedColumns: { left: ['PatientName'], right: ['sms'] },
								// }}
							/>
							<div style={{ display: 'none' }}>
								<DataGridPremium
									apiRef={gridSubRef}
									rows={gridCopyRows}
									getRowId={(row) => row.rowNum}
									loading={gridLoading}
									// selectionModel={gridSelectionModel}
									// onRowClick={onRowClick}
									columns={[
										{
											field: 'rowNum',
											headerName: '번호',
											type: 'number',
											width: 100,
											editable: false,
											hideable: true,
											hide: true,
										},
										{
											field: 'ReceptionID', // acptdd
											headerName: '접수번호',
											type: 'string',
											width: 90,
											editable: false,
											hideable: true,
											hide: true,
										},
										{
											field: 'ReceptionDate', // acptdd
											headerName: '접수일자',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'ReceptionRegNum', // acptno
											headerName: '접수번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'PatientName', // nm
											headerName: '환자명',
											type: 'string',
											width: 70,
											editable: false,
										},
										{
											field: 'SocialNumber', // rgstno
											headerName: '주민번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'ChartNo', // chartno
											headerName: '차트번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'TestName',
											headerName: '검사명',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: '101', // rslt1e
											headerName: '(E/N) gene',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '102',
											headerName: 'RdRp gene',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '103',
											headerName: 'Influ-A',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '104',
											headerName: 'Influ-B',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '105',
											headerName: 'rsv',
											type: 'string',
											width: 150,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: 'TestName2',
											headerName: '검사명',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: '201', // rslt1e
											headerName: '(E/N) gene',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '202',
											headerName: 'RdRp gene',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '203',
											headerName: 'Influ-A',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '204',
											headerName: 'Influ-B',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: '205',
											headerName: 'rsv',
											type: 'string',
											width: 150,
											editable: false,
											cellClassName: (params: GridCellParams<any>) => {
												if (params.value == null) {
													return ''
												}

												return clsx('super-app', {
													nagative:
														!params.value?.startsWith('Pos') &&
														!params.value?.startsWith('Ind'),
													positive:
														params.value?.startsWith('Pos') ||
														params.value?.startsWith('Ind'),
												})
											},
										},
										{
											field: 'D90',
											headerName: '보고일시',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'C10',
											headerName: '재검여부',
											type: 'string',
											width: 90,
											editable: false,
											renderCell: (params) => {
												return params.value === undefined ? '' : '재검'
											},
										},
										{
											field: 'SMSResult',
											headerName: '비고',
											type: 'string',
											width: 250,
											editable: false,
										},
										{
											field: 'CellNumber',
											headerName: '휴대폰번호',
											type: 'string',
											width: 150,
											editable: false,
										},
									]}
									pageSize={gridPageSize}
									onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: true },
											excelOptions: { disableToolbarButton: true },
											printOptions: { disableToolbarButton: true },
											showQuickFilter: true,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									// selectionModel={gridSelectionModel}
									// onRowClick={onRowClick}
									rowThreshold={0}
									getDetailPanelContent={({ row }) => (
										<RemarkContainer>
											<Typography
												variant="body2"
												component="p"
												dangerouslySetInnerHTML={{
													__html: replaceBrTag(row.Remark),
												}}
											></Typography>
										</RemarkContainer>
									)}
									getDetailPanelHeight={({ row }) => 'auto'}
									experimentalFeatures={{ columnGrouping: true }}
									density={(env.desnse as GridDensity) || 'compact'}
								/>
							</div>
							{/* 이상결과 */}
							<div style={{ display: 'none' }}>
								<DataGridPremium
									apiRef={abnormalRef}
									rows={abnormalRows}
									getRowId={(row) => row.rowNum}
									loading={gridLoading}
									columns={[
										{
											field: 'rowNum',
											headerName: '번호',
											type: 'number',
											width: 100,
											editable: false,
										},
										{
											field: 'FormattedDate', // acptdd
											headerName: '처방일자',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'aaaa', // acptno
											headerName: '처방일자',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'bbbb', // nm
											headerName: '그룹번호',
											type: 'string',
											width: 70,
											editable: false,
										},
										{
											field: 'ReceptionRegNum', // rgstno
											headerName: '라벨번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'ChartNo', // chartno
											headerName: '차트번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'PatientName',
											headerName: '성명',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'SocialNumber',
											headerName: '주민번호',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'SA',
											headerName: 'SA',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'TestName',
											headerName: '검사명',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: 'SpecimenName',
											headerName: '검체',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'rdrdResult',
											headerName: '결과',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'rdrpGene',
											headerName: '소견',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: 'D90',
											headerName: '보고일시',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'status',
											headerName: '상태',
											type: 'string',
											width: 100,
											editable: false,
										},
									]}
									pageSize={gridPageSize}
									onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ columnGrouping: true }}
									density={(env.desnse as GridDensity) || 'compact'}
								/>
							</div>
							{/* 음성 */}
							<div style={{ display: 'none' }}>
								<DataGridPremium
									apiRef={negativeRef}
									rows={negativeRows}
									getRowId={(row) => row.rowNum}
									loading={gridLoading}
									columns={[
										{
											field: 'rowNum',
											headerName: '번호',
											type: 'number',
											width: 100,
											editable: false,
										},
										{
											field: 'FormattedDate', // acptdd
											headerName: '처방일자',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'aaaa', // acptno
											headerName: '처방일자',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'bbbb', // nm
											headerName: '그룹번호',
											type: 'string',
											width: 70,
											editable: false,
										},
										{
											field: 'ReceptionRegNum', // rgstno
											headerName: '라벨번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'ChartNo', // chartno
											headerName: '차트번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'PatientName',
											headerName: '성명',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'SocialNumber',
											headerName: '주민번호',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'SA',
											headerName: 'SA',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'TestName',
											headerName: '검사명',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: 'SpecimenName',
											headerName: '검체',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'rdrdResult',
											headerName: '결과',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'rdrpGene',
											headerName: '소견',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: 'D90',
											headerName: '보고일시',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'status',
											headerName: '상태',
											type: 'string',
											width: 100,
											editable: false,
										},
									]}
									pageSize={gridPageSize}
									onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ columnGrouping: true }}
									density={(env.desnse as GridDensity) || 'compact'}
								/>
							</div>
							{/* 동시 */}
							<div style={{ display: 'none' }}>
								<DataGridPremium
									apiRef={inspectionRef}
									rows={inspectionRows}
									getRowId={(row) => row.rowNum}
									loading={gridLoading}
									columns={[
										{
											field: 'rowNum',
											headerName: '번호',
											type: 'number',
											width: 100,
											editable: false,
										},
										{
											field: 'FormattedDate', // acptdd
											headerName: '처방일자',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'aaaa', // acptno
											headerName: '처방일자',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'bbbb', // nm
											headerName: '그룹번호',
											type: 'string',
											width: 70,
											editable: false,
										},
										{
											field: 'ReceptionRegNum', // rgstno
											headerName: '라벨번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'ChartNo', // chartno
											headerName: '차트번호',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'PatientName',
											headerName: '성명',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'SocialNumber',
											headerName: '주민번호',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'SA',
											headerName: 'SA',
											type: 'string',
											width: 100,
											editable: false,
										},
										{
											field: 'TestName',
											headerName: '검사명',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: 'SpecimenName',
											headerName: '검체',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'rdrdResult',
											headerName: '결과',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'rdrpGene',
											headerName: '소견',
											type: 'string',
											width: 300,
											editable: false,
										},
										{
											field: 'D90',
											headerName: '보고일시',
											type: 'string',
											width: 90,
											editable: false,
										},
										{
											field: 'status',
											headerName: '상태',
											type: 'string',
											width: 100,
											editable: false,
										},
									]}
									pageSize={gridPageSize}
									onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
									pagination
									autoHeight={true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ columnGrouping: true }}
									density={(env.desnse as GridDensity) || 'compact'}
								/>
							</div>
						</Box>
					</Grid>
					<div style={{ marginTop: '1rem' }}>
						{resultCountIsArray ? (
							<>
								{resultCountArray.map((item, index) => (
									<Box>
										{item.flag === 'A' && (
											<Chip
												color="info"
												label="A"
												sx={{ m: 1 }}
											/>
										)}
										{item.flag === 'B' && (
											<Chip
												color="info"
												label="오정임시선별소"
												sx={{ m: 1 }}
											/>
										)}
										{item.flag === 'C' && (
											<Chip
												color="info"
												label="오정임시선별소(보육)"
												sx={{ m: 1 }}
											/>
										)}
										<Chip
											color="info"
											label={`전체 : ${item.Cnt ? item.Cnt : 0}건`}
											sx={{ m: 1 }}
										/>
										<Chip
											color="success"
											label={`음성: ${item.NCnt ? item.NCnt : 0}건`}
											sx={{ m: 1 }}
										/>
										<Chip
											color="warning"
											label={`양성: ${item.PCnt ? item.PCnt : 0}건`}
											sx={{ m: 1 }}
										/>
										<Chip
											color="default"
											label={`미결정: ${item.ICnt ? item.ICnt : 0}건`}
											sx={{ m: 1 }}
										/>
										<Chip
											color="primary"
											label={`판정불가: ${item.RCnt ? item.RCnt : 0}건`}
											sx={{ m: 1 }}
										/>
										<Chip
											color="secondary"
											label={`검사중: ${item.TCnt ? item.TCnt : 0}건`}
											sx={{ m: 1 }}
										/>
									</Box>
								))}
							</>
						) : (
							<Box>
								<Chip
									color="info"
									label={`전체 : ${resultCount.Cnt ? resultCount.Cnt : 0}건`}
									sx={{ m: 1 }}
								/>
								<Chip
									color="success"
									label={`음성: ${resultCount.NCnt ? resultCount.NCnt : 0}건`}
									sx={{ m: 1 }}
								/>
								<Chip
									color="warning"
									label={`양성: ${resultCount.PCnt ? resultCount.PCnt : 0}건`}
									sx={{ m: 1 }}
								/>
								<Chip
									color="default"
									label={`미결정: ${resultCount.ICnt ? resultCount.ICnt : 0}건`}
									sx={{ m: 1 }}
								/>
								<Chip
									color="primary"
									label={`판정불가: ${resultCount.RCnt ? resultCount.RCnt : 0}건`}
									sx={{ m: 1 }}
								/>
								<Chip
									color="secondary"
									label={`검사중: ${resultCount.TCnt ? resultCount.TCnt : 0}건`}
									sx={{ m: 1 }}
								/>
							</Box>
						)}
					</div>
				</Grid>
			</PageBg>
		</>
	)
}

export default TestCovid
