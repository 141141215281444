import { createSlice } from '@reduxjs/toolkit'

export const themeModeSlice = createSlice({
	name: 'displayMode',
	initialState: {
		isDarkMode:
			localStorage.getItem(`${process.env.REACT_APP_DARK_MODE_NAME}`) === 'true'
				? true
				: false,
	},
	reducers: {
		setDisplayMode: (state, actions) => {
			localStorage.setItem(
				`${process.env.REACT_APP_DARK_MODE_NAME}`,
				actions.payload ? 'true' : 'false'
			)
			state.isDarkMode = actions.payload
		},
		removeDisplayMode: (state) => {
			state.isDarkMode = false
		},
	},
})

export const { setDisplayMode, removeDisplayMode } = themeModeSlice.actions
export default themeModeSlice.reducer
export const selectDisplayMode = (state: any) => state.displayMode
