import moment from 'moment'
import { alertModal } from './util'

/**
 * 랜덤 문자열
 * @param len : 문자열 길이
 * @returns
 */
export const randomString = (len: number) =>
	Math.random().toString(36).substring(2, len)

/**
 * 숫자 또는 문자를 통화로 변경
 * @param num
 * @returns
 */
export const currencyFormat = (num: number | string) => {
	if (typeof num === 'string') num = Number(num)
	return (
		num &&
		new Intl.NumberFormat('ko-KR', {
			style: 'currency',
			currency: 'KRW',
		}).format(num)
	)
}

/**
 * \r\n -> <br>로 변경
 * @param str
 * @returns
 */
export const replaceBrTag = (str: string) => {
	if (str == undefined || str == null) {
		return ''
	}

	str = str.replace(/\r\n/gi, '<br />')
	str = str.replace(/\\n/gi, '<br />')
	str = str.replace(/\n/gi, '<br />')
	return str
}

/**
 * OCS 결과에서 &lg;br/&gt; 이나 \r\n 이나 %%br/%% 들 \n으로 변경
 * @param str
 */
export const replaceNewLine = (str: string) => {
	if (str == undefined || str == null) {
		return ''
	}

	str = str.replaceAll('\r\n', '\n')
	str = str.replaceAll('%%br/%%', '\n')
	str = str.replaceAll('&lt;br/&gt;', '\n')
	str = str.replaceAll('<br/>', '\n')
	str = str.replaceAll('<br />', '\n')
	str = str.replaceAll('<br>', '\n')
	str = str.replaceAll('&lt;', '<')
	str = str.replaceAll('&gt;', '>')
	str = str.replaceAll('&#x0D;', '\n')
	if (str.startsWith('\n')) {
		str = str.substring(1)
	}
	return str
}

/**
 * 시작일과 종료일 비교 후 결과 반환
 * @param str
 * @returns
 */
export const compareDateValues = (startDate: string, endDate: string) => {
	// 만약 serach.startDate가 serach.endDate보다 크면 alert
	if (moment(endDate).isBefore(startDate)) {
		alertModal('시작일이 종료일보다 큽니다.', 'warning', () => {})
		return false
	}
	// 만약 search.StartDate가 2023년 8월 1일 이전이면 alert
	if (moment(startDate).isBefore('2023-08-01')) {
		alertModal(
			'2023년 8월 1일 이전의 데이터는 조회할 수 없습니다.',
			'warning',
			() => {}
		)
		return false
	}
	// search.startDate와 search.endDate의 최대 조회 가능 기간은 14일 이내
	if (moment(endDate).diff(moment(startDate), 'days') > 14) {
		alertModal('최대 조회 가능 기간은 14일 입니다.', 'warning', () => {})
		return false
	}
	return true
}
