import Swal, { SweetAlertIcon } from 'sweetalert2'

import moment from 'moment'
import 'moment/locale/ko'
import { decomposeColor, hexToRgb } from '@mui/material'

/**
 * 휴대폰 유효성 검사
 * @param val
 * @returns
 */
export const validCellPhone = (val: string) => {
	const hp = val.split('-').join('')
	const regPhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/
	return regPhone.test(hp)
}

/**
 * 전화번호 정규식
 * @param val
 * @returns
 */
export const validPhoneNumber = (val: string) => {
	/* 유전 전화 + 휴대폰 번호 */
	const hp = /^(0[2-8][0-5]?|01[01346-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})$/

	/* 대표전화번호 1588 등 */
	const tel = /^(1544|1566|1577|1588|1644|1688|1899)-?([0-9]{4})$/

	return hp.test(val) || tel.test(val)
}

/**
 * 이메일 유효성 검사
 * @param val
 * @returns
 */
export const validEmail = (val: string) => {
	const regEmail = /(.+)@(.+){2,}\.(.+){2,}/
	return regEmail.test(val)
}

/**
 * 휴대전화 자동 하이픈
 * @param val
 */
export const autoCellPhone = (val: string) => {
	const arr = [3, 4, 4]
	const str = val.replace(/[^0-9]/g, '')
	const delimiter = '-'
	let result = ''
	let fromIndex = 0
	let strlen = str.length
	for (let i = 0; i < arr.length; i++) {
		let currentStrCnt = arr[i]
		result += str.substr(fromIndex, currentStrCnt)
		fromIndex += currentStrCnt
		if (strlen <= fromIndex) {
			result += str.substr(fromIndex, strlen)
			break
		}
		if (i < arr.length) result += delimiter
	}
	return result
}

/**
 * 날짜 형식 유효성 검사
 * @param date
 * @returns
 */
export const isValidDateType = (date: string) =>
	moment(new Date(date), 'yyyy-MM-DD', true).isValid()

/**
 * 날짜 비교
 * @param date1
 * @param date2
 * @returns
 */
export const compareDate = (date1: string, date2: string): number => {
	return moment(moment(new Date(date1)).format('yyyy-MM-DD')).diff(
		moment(moment(new Date(date2)).format('yyyy-MM-DD')),
		'days'
	)
}

/**
 * alert
 * @param msg
 * @param icon : 'success' | 'error' | 'warning' | 'info' | 'question'
 * @param callback
 */
export const alertModal = (
	msg: string,
	icon: SweetAlertIcon,
	callback: Function
) => {
	Swal.fire({
		html: msg,
		icon: icon,
		confirmButtonText: '확인',
		showCancelButton: false,
		didClose: () => {
			if (callback) callback()
		},
	})
}

/**
 * 확인 취소 confirm 창
 * @param msg
 * @param callback1
 * @param callback2
 * @returns
 */
export const confirmAlert = async (
	msg: string,
	callback1: Function,
	callback2: Function
) => {
	const result = await Swal.fire({
		html: msg,
		icon: 'question',
		showCancelButton: true,
		confirmButtonText: '확인',
		cancelButtonText: '취소',
	})
	if (result.isConfirmed) {
		if (callback1) {
			callback1()
		}
	} else {
		if (callback2) {
			callback2()
		}
	}
}

export const timerAlert = async (
	msg: string,
	icon: SweetAlertIcon,
	callback: Function
) => {
	return await Swal.fire({
		html: msg,
		icon: icon,
		showCancelButton: false,
		confirmButtonText: '확인',
		timer: 1000,
		timerProgressBar: true,
		didClose: () => {
			if (callback) callback()
		},
	})
}

/**
 * null 체크
 * @param val
 * @returns
 */
export const isEmpty = (val: string) => {
	return val === undefined || val === 'undefined' || val === null || val === ''
		? true
		: false
}

/**
 * hex 컬러를 rgba로 변환
 * @param color
 * @param opacity
 * @returns
 */
export const hexToRgba = (color: string, opacity: number) => {
	const { values } = decomposeColor(color)
	return `rgba(${values[0]}, ${values[1]}, ${values[2]}, ${opacity})`
}

/**
 * cm -> px
 * @param cm
 * @returns
 */
export const cmToPx = (cm: number) => {
	return cm * 37.795275591
}

/**
 * px -> cm
 * @param px
 * @returns
 */
export const pxToCm = (px: number) => {
	return px / 37.795275591
}

/**
 * mm -> px
 */
export const mmToPx = (mm: number) => {
	return mm * 3.7795275591
}

/**
 * px -> mm
 */
export const pxToMm = (px: number) => {
	return px / 3.7795275591
}
