import { createSlice } from '@reduxjs/toolkit'

export const gridColumnVisibleSlice = createSlice({
	name: 'gridColumnVisible',
	initialState: {
		info: {},
	},
	reducers: {
		setGridColumnVisible: (state, actions) => {
			console.log(actions.payload)
			state.info = actions.payload
		},
		removeGridColumnVisible: (state) => {
			state.info = {}
		},
	},
})

export const { setGridColumnVisible, removeGridColumnVisible } =
	gridColumnVisibleSlice.actions
export default gridColumnVisibleSlice.reducer
export const selectGridColumnVisibleInfo = (state: any) =>
	state.gridColumnVisible
