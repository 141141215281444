import { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import AttachFileIcon from '@mui/icons-material/AttachFile'

import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	LinearProgress,
	Select,
	Card,
	CardContent,
	SelectChangeEvent,
	Chip,
	OutlinedInput,
	Dialog,
	useMediaQuery,
	useTheme,
	AppBar,
	DialogContent,
	IconButton,
	Toolbar,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridRowModel,
	GridToolbar,
	GridApi,
	useGridApiRef,
	GridSelectionModel,
	GridDensity,
	GridValueGetterParams,
	GridValueSetterParams,
	GridCellEditCommitParams,
} from '@mui/x-data-grid-premium'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { useDropzone } from 'react-dropzone'
import {
	fileMasterId,
	fileUpload,
	fileDelete,
	reportFileList,
	fileDownloadByFileId,
	clientSyncList,
	clientList,
	clientSyncSave,
} from 'api/api'
import CloseIcon from '@mui/icons-material/Close'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import {
	extractCmmnCode,
	extractCmmnValue,
	singleSelectSetter,
	singleSelectGetter,
	setCmmnName,
} from 'util/cmmnCodeUtil'
import { confirmAlert, alertModal } from 'util/util'
import { InfoClientSearch } from 'constants/types'
import { getSettings } from 'helpers/storage'
import TransitionComponent from 'components/TransitionComponent'
import { replaceBrTag } from 'util/stringUtil'
import { valuesInIterator } from 'util/objectUtil'

// 게시물 저장시 파일의 id값만 저장
let dropzoneFiles = []
// 게시물 열람용 첨부파일 처리
let tempUploadFiles: any[] = []

type Nullable<T> = T | null

const DownloadType = () => {
	// redux store
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const env = getSettings()

	const cmmnCdRef = useRef<Nullable<HTMLInputElement>>(null)
	const selectOptionRef = useRef<Nullable<HTMLSelectElement>>(null)
	const apiRef = useGridApiRef()
	const formRef = useRef<Nullable<HTMLInputElement>>(null)
	const fileId1Ref = useRef<Nullable<HTMLInputElement>>(null)
	const fileId2Ref = useRef<Nullable<HTMLInputElement>>(null)
	// ui
	const cmmnCdList = useSelector(selectCmmnCode)
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const [cmmnCdLv2List, setCmmnCdLv2List] = useState([])

	const [tableNameList, setTableNameList] = useState([])
	const [downloadTypeList, setDownloadTypeList] = useState([])

	const [fileRow, setFileRow] = useState({
		rowNum: 0,
		id: 0,
		attachFileId: 'REPORT_FILES',
		fileDest: '',
		cmmnCd: '',
		cmmnNm: '',
		fileId1: 0,
		orgFileName1: '',
		fileSize1: 0,
		fileId2: 0,
		orgFileName2: '',
		fileSize2: 0,
	})

	// 상단 버튼
	const actionAdd = () => {}
	const actionSave = () => {
		const data = valuesInIterator(apiRef.current.getSelectedRows())
		console.log(data[0])
		if (!data[0]) {
			alertModal('선택된 행이 없습니다.', 'error', () => {})
		}
		if (!data[0].ClientID) {
			alertModal('고객사 코드가 업습니다.', 'error', () => {})
		}
		if (!data[0].ClientName) {
			alertModal('고객사 명이 업습니다.', 'error', () => {})
		}
		if (!data[0].TablePostFix && data[0].TablePostFix !== 'A') {
			alertModal('테이블 접미사가 업습니다.', 'error', () => {})
		}
		if (!data[0].downloadType && data[0].downloadType !== 'A') {
			alertModal('다운로드 타입이 업습니다.', 'error', () => {})
		}
		if (
			data[0].ClientID &&
			data[0].ClientName &&
			data[0].TablePostFix &&
			data[0].downloadType
		) {
			const request = {
				clientID: data[0].ClientID,
				clientName: data[0].ClientName,
				tablePostFix: data[0].TablePostFix,
				downloadType: data[0].downloadType,
				ocsDownload: data[0].ocsDownload,
				isXlsx: data[0].isXlsx,
			}
			console.log(request)
			confirmAlert(
				'저장하시겠습니까?',
				() => {
					clientSyncSave(request).then((res) => {
						alertModal('저장되었습니다.', 'success', () => {
							getInfoClientList()
						})
					})
				},
				() => {}
			)
		}
	}
	const actionDel = () => {}
	const actionSearch = () => {
		getInfoClientList()
	}
	const actionReset = () => {
		setSearch({
			clientID: '',
			clientName: '',
			tablePostFix: 'A',
			downloadType: 'A',
			ocsDownload: false,
		})
	}

	// 그리드 row 클릭
	const onRowClick = (e: GridRowModel) => {
		// 입력 폼에 데이터 셋팅
		setSelectionModel([e.id])
		console.log(e.id)
		if (e.id) {
			setDisabledSave(false)
		} else {
			setDisabledSave(true)
		}
		// console.log(apiRef.current.getSelectedRow())
		// setFileForm((prevState) => ({
		// 	// ...e.row,
		// 	fileId: e.row.fileId || 0,
		// 	attachFileId: 'REPORT_FILES',
		// 	fileDest: e.row.cmmnCd || '',
		// 	fileMime: e.row.fileMime || '',
		// 	fileName: e.row.fileName || '',
		// 	fileSize: e.row.fileSize || 0,
		// 	orgFileName: e.row.orgFileName || '',
		// 	cmmnNm: e.row.cmmnNm || '',
		// 	cmmnCd: e.row.cmmnCd || '',
		// }))
		// setFileRow((prevState) => ({
		// 	rowNum: e.row.rowNum || 0,
		// 	id: e.row.id || 0,
		// 	attachFileId: 'REPORT_FILES',
		// 	fileDest: e.row.cmmnCd || '',
		// 	cmmnNm: e.row.cmmnNm || '',
		// 	cmmnCd: e.row.cmmnCd || '',
		// 	fileId1: e.row.fileId1 || 0,
		// 	fileSize1: e.row.fileSize1 || 0,
		// 	orgFileName1: e.row.orgFileName1 || '',
		// 	fileId2: e.row.fileId2 || 0,
		// 	fileSize2: e.row.fileSize2 || 0,
		// 	orgFileName2: e.row.orgFileName2 || '',
		// }))
		// console.table(fileForm)
	}

	// 조회조건
	const [searchCondition, setSearchCondition] = useState({
		keyword: '',
		cmmnCd: 'NN',
	})

	/**
	 * 조회조건의 공통코드 변경시
	 * @param e
	 */
	const cmmnCdOnChange = (e: SelectChangeEvent<string>): void => {
		if (e.target.value === 'NN') {
			setSearchCondition((prevState) => {
				return {
					...searchCondition,
					keyword: '',
				}
			})
		} else {
			// 파일 목록
			console.log(e.target.value)
			reportFileList(parseInt(e.target.value), searchCondition.keyword).then(
				(res) => {
					setRows(res)
				}
			)
		}
		setFileForm({
			fileId: 0,
			attachFileId: 'REPORT_FILES',
			fileDest: '',
			fileMime: '',
			fileName: '',
			fileSize: 0,
			orgFileName: '',
			cmmnNm: '',
			cmmnCd: '',
		})
		setSearchCondition({
			...searchCondition,
			cmmnCd: e.target.value,
		})
	}

	// 파일 저장
	const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		//
	}

	// page variables
	const [rows, setRows] = useState<GridRowModel[]>([])
	const [size, setSize] = useState(10)
	const [isLoading, setIsLoading] = useState(false)

	// 양식 등록 및 수정
	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {}
	const [fileForm, setFileForm] = useState({
		fileId: 0,
		attachFileId: 'REPORT_FILES',
		fileDest: '',
		fileMime: '',
		fileName: '',
		fileSize: 0,
		orgFileName: '',
		cmmnNm: '',
		cmmnCd: '',
	})

	// 첨부파일 업로드시 attachFileId 값이 있으면 그냥 업로드... 없으면 생성 후 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		// if (fileForm.attachFileId === '') {
		// 	fileMasterId().then((res) => {
		// 		uploadAction(acceptedFiles, res.attachFileId)
		// 	})
		// } else {
		// 	uploadAction(acceptedFiles, fileForm.attachFileId)
		// }
	}, [])

	// 업로드 액션
	const uploadAction = (acceptedFiles: File[], clientID: string) => {
		fileUpload(acceptedFiles, 'REPORT_FILES', clientID).then((res) => {
			console.log(res)
			getInfoClientList()
		})
	}
	// 첨부파일 삭제
	const handleFileDelete = (fileID: number) => {
		if (fileID) {
			confirmAlert(
				'파일을 삭제하시겠습니까?',
				() => {
					fileDelete('REPORT_FILES', fileID).then((res) => {
						// 업로드 후 그리드 갱신
						console.log(res)
						getInfoClientList()
					})
				},
				() => {}
			)
		} else {
			alertModal('삭제할 파일이 없습니다.', 'error', () => {})
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const [open, setOpen] = useState(false)
	const [search, setSearch] = useState<any>({
		clientID: '',
		clientName: '',
		tablePostFix: '',
		downloadType: '',
	})
	const [clientRows, setClientRows] = useState<any[]>([])
	const [clientSelectionModel, setClientSelectionModel] =
		useState<GridSelectionModel>([])

	const onClose = () => {
		setOpen(false)
	}

	/**
	 * 파일 필드가 포함된 고객사 리스트 조회
	 */
	const getInfoClientList = () => {
		setIsLoading(true)
		clientSyncList(search)
			.then((res) => {
				setRows(res)
				setIsLoading(false)
			})
			.catch((err) => {
				setIsLoading(false)
				console.log(err)
			})
	}

	/**
	 * 팝업 row select
	 * @param e
	 */
	const onClientRowClick = (e: GridRowModel) => {
		setClientSelectionModel([e.id])
	}

	const handleSave = (e: GridRowModel) => {
		if (!e.ClientID) {
			alertModal('고객사 코드가 업습니다.', 'error', () => {})
		}
		if (!e.ClientName) {
			alertModal('고객사 명이 업습니다.', 'error', () => {})
		}
		if (!e.TablePostFix) {
			alertModal('테이블 접미사가 업습니다.', 'error', () => {})
		}
		if (e.ClientID && e.ClientName && e.TablePostFix) {
			const request = {
				clientID: e.ClientID,
				clientName: e.ClientName,
				tablePostFix: e.TablePostFix,
				downloadType: e.downloadType,
			}
			console.log(request)
			// confirmAlert(
			// 	'저장하시겠습니까?',
			// 	() => {
			// 		clientSyncSave(request).then((res) => {
			// 			alertModal('저장되었습니다.', 'success', () => {
			// 				getInfoClientList()
			// 			})
			// 		})
			// 	},
			// 	() => {}
			// )
		}
	}

	const [valueOptions, setValueOptions] = useState<any[]>([])

	const handleCellEditCommit = (params: GridCellEditCommitParams) => {
		const { id, field, value } = params
		console.log(id, field, value)

		setRows((prevRows) =>
			prevRows.map((row) => {
				if (row.rowNum === id) {
					return {
						...row,
						[field]: value,
					}
				}
				return row
			})
		)
	}

	useEffect(() => {
		// 공통코드
		let lv2 = extractCmmnCode(cmmnCdList, 'OCS_MATCH', 1)
		if (lv2?.level2) setTableNameList(lv2?.level2)
		lv2?.level2?.map((item: any, index: number) => {
			if (index === 0) {
				setValueOptions(() => {
					return [
						{
							value: '',
							label: '선택하세요.',
						},
					]
				})
			}
			setValueOptions((prevState) => {
				return [
					...prevState,
					{
						value: item.cmmnCd,
						label: item.cmmnNm,
					},
				]
			})
		})
		lv2 = extractCmmnCode(cmmnCdList, 'OCS_TYPE', 1)
		console.log(lv2?.level2)
		if (lv2?.level2) setDownloadTypeList(lv2?.level2)
		getInfoClientList()
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<Stack sx={{ marginBottom: '1rem' }}>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						// onSubmit={handleSearchSubmit}
						onKeyUp={onKeyUp}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={6}
								sm={3}
							>
								<TextField
									label="고객사명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.clientName}
									onChange={(e) => {
										setSearch({
											...search,
											clientName: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
							>
								<TextField
									label="고객사코드"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.clientID}
									onChange={(e) => {
										setSearch({
											...search,
											clientID: e.target.value ? parseInt(e.target.value) : '',
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
							>
								<FormControl fullWidth>
									<InputLabel
										shrink
										id="search-label"
									></InputLabel>
									<Select
										labelId="search-label"
										id="demo-simple-select-helper"
										value={search.tablePostFix}
										displayEmpty
										onChange={(e) => {
											setSearch({
												...search,
												tablePostFix: e.target.value,
											})
										}}
										size={'small'}
									>
										{valueOptions &&
											valueOptions.map(
												(item: { label: string; value: string }, index: number) => (
													<MenuItem
														key={index}
														value={item?.value}
													>
														{item?.label}
													</MenuItem>
												)
											)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</PageBg>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden' }}
			>
				<Box sx={{ p: 2 }}>
					<Typography
						variant="subtitle1"
						component="h3"
					>
						고객사 목록
					</Typography>
					<Box
						sx={{
							height: '500px',
							width: '100%',
							overflow: 'auto',
							marginTop: '1rem',
							'@media (max-width: 1199px)': {
								height: '400px',
							},
						}}
					>
						<DataGridPremium
							apiRef={apiRef}
							rows={rows}
							getRowId={(row) => row.rowNum}
							columns={[
								{
									field: 'rowNum',
									headerName: '번호',
									type: 'number',
									width: 50,
									filterable: false,
								},
								{
									field: 'ClientID',
									headerName: 'ID',
									type: 'number',
									width: 60,
									editable: false,
								},
								{
									field: 'ClientName',
									headerName: '고객사명',
									type: 'string',
									width: 200,
									editable: false,
								},
								{
									field: 'TablePostFix',
									headerName: '테이블명',
									type: 'string',
									width: 150,
									editable: false,
									renderCell: (params) => {
										const { TablePostFix } = params.row
										return (
											<FormControl fullWidth>
												<Select
													labelId="TablePostFixNm-select-label"
													id="TablePostFixNm-select"
													value={TablePostFix ? TablePostFix : 'A'}
													label="테이블명"
													name="TablePostFix"
													onChange={(e) => {
														const newValue = e.target.value
														handleCellEditCommit({
															id: params.id,
															field: params.field,
															value: newValue,
														})
													}}
													size="small"
													fullWidth
												>
													<MenuItem value="A">선택하세요.</MenuItem>
													{tableNameList &&
														tableNameList.map(
															(item: { cmmnCd: string; cmmnNm: string }, index: number) => (
																<MenuItem
																	key={index}
																	value={item?.cmmnCd}
																>
																	{item?.cmmnNm}
																</MenuItem>
															)
														)}
												</Select>
											</FormControl>
										)
									},
								},
								// {
								// 	field: 'TablePostFixNm',
								// 	headerName: '테이블명',
								// 	type: 'singleSelect',
								// 	width: 150,
								// 	editable: true,
								// 	// valueGetter: (params: GridValueGetterParams) =>
								// 	// 	singleSelectGetter(params, valueOptions, 'TablePostFix'),
								// 	// valueSetter: (params: GridValueSetterParams) =>
								// 	// 	singleSelectSetter(params, valueOptions, 'TablePostFix'),
								// 	// valueOptions,
								// },
								// {
								// 	field: 'fileID',
								// 	headerName: '파일ID',
								// 	type: 'number',
								// 	width: 150,
								// 	editable: false,
								// 	hide: true,
								// },
								// {
								// 	field: 'fileName',
								// 	headerName: '파일명',
								// 	type: 'string',
								// 	width: 150,
								// 	editable: false,
								// },
								{
									field: 'downloadType',
									headerName: '다운로드',
									type: 'singleSelect',
									width: 150,
									renderCell: (params) => {
										const { downloadType } = params.row
										return (
											<FormControl fullWidth>
												<Select
													labelId="downloadType-select-label"
													id="downloadType-select"
													value={downloadType ? downloadType : 'A'}
													label="다운로드"
													name="downloadType"
													onChange={(e) => {
														const newValue = e.target.value
														handleCellEditCommit({
															id: params.id,
															field: params.field,
															value: newValue,
														})
													}}
													size="small"
													fullWidth
												>
													<MenuItem value="A">선택하세요.</MenuItem>
													{downloadTypeList &&
														downloadTypeList.map(
															(item: { cmmnCd: string; cmmnNm: string }, index: number) => (
																<MenuItem
																	key={index}
																	value={item?.cmmnCd}
																>
																	{item?.cmmnNm}
																</MenuItem>
															)
														)}
												</Select>
											</FormControl>
										)
									},
								},
								{
									field: 'ocsDownload',
									headerName: '다운로드 버튼 활성화',
									type: 'boolean',
									width: 150,
									editable: true,
								},
								{
									field: 'isXlsx',
									headerName: '엑셀 버전',
									type: 'singleSelect',
									width: 180,
									editable: true,
									renderCell: (params) => {
										const { isXlsx } = params.row
										let tmpValue = 'A'
										if (isXlsx === false) {
											tmpValue = 'N'
										} else if (isXlsx === true) {
											tmpValue = 'Y'
										}
										return (
											<FormControl fullWidth>
												<Select
													value={tmpValue}
													onChange={(e) => {
														const newValue = e.target.value
														handleCellEditCommit({
															id: params.id,
															field: params.field,
															value: newValue === 'Y' ? true : false,
														})
													}}
												>
													<MenuItem value="A">선택하세요.</MenuItem>
													<MenuItem value="N">xls (97~2003 버전)</MenuItem>
													<MenuItem value="Y">xlsx (2003이상 버전)</MenuItem>
												</Select>
											</FormControl>
										)
									},
								},
								// {
								// 	field: 'uploadButton',
								// 	headerName: '업로드',
								// 	type: 'string',
								// 	width: 90,
								// 	editable: false,
								// 	renderCell: (params) => {
								// 		const onChange = (e: GridRowModel) => {
								// 			const files = e.target.files
								// 			const clientID = params.row.ClientID as string
								// 			console.log(files, clientID)
								// 			if (files && clientID) {
								// 				confirmAlert(
								// 					'파일을 업로드 하시겠습니까?',
								// 					() => {
								// 						uploadAction(files, clientID)
								// 					},
								// 					() => {}
								// 				)
								// 			}
								// 		}

								// 		return (
								// 			<Button
								// 				variant="contained"
								// 				component="label"
								// 				size="small"
								// 				disabled={params.row.fileName && params.row.fileID ? true : false}
								// 			>
								// 				업로드
								// 				<input
								// 					hidden
								// 					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								// 					type="file"
								// 					onChange={onChange}
								// 				/>
								// 			</Button>
								// 		)
								// 	},
								// },
								// {
								// 	field: 'buttonXlsx',
								// 	headerName: '다운로드',
								// 	type: 'string',
								// 	width: 90,
								// 	editable: false,
								// 	renderCell: (params) => {
								// 		const onClick = () => {
								// 			// 파일 다운로드
								// 			fileDownloadByFileId(params.row.fileID, params.row.fileName)
								// 		}

								// 		return (
								// 			<Button
								// 				variant="contained"
								// 				color="primary"
								// 				size="small"
								// 				onClick={onClick}
								// 				disabled={params.row.fileName && params.row.fileID ? false : true}
								// 			>
								// 				다운로드
								// 			</Button>
								// 		)
								// 	},
								// },
								// {
								// 	field: 'btns',
								// 	headerName: '',
								// 	type: 'string',
								// 	width: 150,
								// 	editable: false,
								// 	renderCell: (params) => {
								// 		const onSave = () => {
								// 			console.log(params.row)
								// 			handleSave(params.row)
								// 		}
								// 		const onDelete = () => {
								// 			console.log(params.row)
								// 			handleFileDelete(params.row.fileID)
								// 		}
								// 		return (
								// 			<>
								// 				<Button
								// 					variant="contained"
								// 					color="primary"
								// 					size="small"
								// 					onClick={onSave}
								// 				>
								// 					저장
								// 				</Button>
								// 				<Button
								// 					variant="contained"
								// 					color="warning"
								// 					size="small"
								// 					onClick={onDelete}
								// 					sx={{ marginLeft: 1 }}
								// 					disabled={params.row.fileName && params.row.fileID ? false : true}
								// 				>
								// 					파일삭제
								// 				</Button>
								// 			</>
								// 		)
								// 	},
								// },
							]}
							pageSize={size}
							onPageSizeChange={(newPageSize) => setSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									excelOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 250 },
								},
							}}
							loading={isLoading}
							selectionModel={selectionModel}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</Box>
			</Paper>
		</>
	)
}

export default DownloadType
