import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Grid,
	Paper,
	Box,
	Button,
	Typography,
	LinearProgress,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridApi,
	GridColumns,
	GridRenderCellParams,
	GridDensity,
} from '@mui/x-data-grid-premium'

import PagePositon from 'components/PagePositon'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'

import { attachFileList, boardView, dashboardData } from 'api/api'
import { dateTimeFormatter } from 'util/formatUtil'

import BoardView from 'components/BoardVIew'
import { BoardRequestType } from 'constants/types'
import { getSettings } from 'helpers/storage'

const RenderData = (props: GridRenderCellParams<string>) => {
	const { value } = props

	return <strong>{value}</strong>
}

const Dashboard = () => {
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(true)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {}
	const actionReset = () => {}

	// grid columns
	/**
	 * 공지사항 컬럼
	 */
	const columns: GridColumns = [
		{
			field: 'rowNum',
			headerName: 'No',
			type: 'number',
			width: 100,
			hide: true,
		},
		{
			field: 'boardId',
			headerName: '순번',
			type: 'string',
			width: 150,
			hide: true,
			renderCell: RenderData,
		},
		{
			field: 'title',
			headerName: '제목',
			type: 'string',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { value, row } = params
				return (
					<Button
						variant="text"
						sx={{ textDecoration: 'underline' }}
						onClick={() => {
							boardView(row?.boardId, userInfo?.infoType).then((result) => {
								setNoticeDataSet({ ...result })
								searchAttachFile(result.attachFileId)
								setNoticeOpen(true)
							})
						}}
					>
						{value}
					</Button>
				)
			},
		},
		{ field: 'createdUser', headerName: '작성자', type: 'string', width: 150 },
		{
			field: 'createdAt',
			headerName: '작성일',
			type: 'string',
			width: 165,
			valueFormatter: ({ value }: { value: string }) =>
				value && dateTimeFormatter(value),
		},
	]

	// grid state
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(10)
	const [gridPage, setGridPage] = useState(0)
	const [gridRowCount, setGridRowCount] = useState(0)

	const [noticeList, setNoticeList] = useState<any[]>([])

	// grid row click event
	const onRowClick = (e: GridRowModel) => {}

	// 조회조건
	const [search, setSearch] = useState({
		keyword: '',
	})

	const getDashboardData = () => {
		dashboardData(userInfo?.infoType)
			.then((res) => {
				console.log(res.boardList)
				setNoticeList(res.boardList)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const [noticeOpen, setNoticeOpen] = useState(false)
	const [noticeDataSet, setNoticeDataSet] = useState<BoardRequestType>({
		boardId: '',
		category: '',
		displayTo: '',
		title: '',
		content: '',
		startDate: '',
		endDate: '',
		attachFileId: '',
		fileDest: '',
		createdUser: '',
		createdAt: '',
		updatedUser: '',
		updatedAt: '',
		deleteFlag: false,
		isPopup: 'A',
	})
	const [noticeFileList, setNoticeFileList] = useState([])
	/**
	 * 첨부파일 조회
	 * @param attachFileId
	 */
	const searchAttachFile = (attachFileId: string) => {
		attachFileList(attachFileId).then((res) => {
			console.log(res)
			setNoticeFileList(res)
		})
	}

	const noticeClose = () => {
		setNoticeOpen(false)
		setNoticeDataSet({
			boardId: '',
			category: '',
			displayTo: '',
			title: '',
			content: '',
			startDate: '',
			endDate: '',
			attachFileId: '',
			fileDest: '',
			createdUser: '',
			createdAt: '',
			updatedUser: '',
			updatedAt: '',
			deleteFlag: false,
			isPopup: 'A',
		})
	}

	const boardEdit = () => {}

	useEffect(() => {
		getDashboardData()
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={true}
				hideSave={true}
				hideDel={true}
				hideReset={true}
				hideSearch={true}
			/>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden', padding: '1rem' }}
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
						// md={6}
						// lg={4}
					>
						<Typography
							variant="subtitle1"
							component="h3"
							sx={{ lineHeight: '1', marginBottom: '1rem' }}
						>
							공지사항
						</Typography>
						<Box
							sx={{
								width: '100%',
								overflow: 'auto',
								marginTop: '0.5rem',
								background: 'transparent',
								'@media (max-width: 1199px)': {
									height: '400px',
								},
							}}
						>
							<DataGridPremium
								rows={noticeList}
								getRowId={(row) => row.boardId}
								columns={columns}
								pageSize={env.row ? env.row : 100}
								onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								pagination
								autoHeight={true}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								components={{
									LoadingOverlay: LinearProgress,
								}}
								loading={gridLoading}
								selectionModel={gridSelectionModel}
								onRowClick={onRowClick}
								density={(env.desnse as GridDensity) || 'compact'}
							/>
						</Box>
					</Grid>
					{/* <Grid
						item
						xs={12}
						md={6}
						lg={4}
					></Grid>
					<Grid
						item
						xs={12}
						md={6}
						lg={4}
					></Grid>
					<Grid
						item
						xs={12}
						md={6}
						lg={4}
					></Grid> */}
				</Grid>
			</Paper>
			<BoardView
				dialogOpen={noticeOpen}
				dialogClose={noticeClose}
				dataSet={noticeDataSet}
				fileList={noticeFileList}
				attachFileId={noticeDataSet.attachFileId}
				searchAttachFile={searchAttachFile}
				boardEdit={boardEdit}
				isDashboard={true}
			/>
		</>
	)
}

export default Dashboard
