import {
	Dialog,
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	DialogContent,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	TableHead,
	Button,
	FormControlLabel,
	Divider,
	DialogActions,
} from '@mui/material'
import { TextField, Checkbox } from '@mui/material'
import TransitionEffect from './TransitionEffect'
import CloseIcon from '@mui/icons-material/Close'

const SlideBlockPopup2 = ({
	popupOpen,
	popupData,
	setPopupData,
	selectedSbList,
	sbList,
	onPopupClose,
	onSBItemClick,
	onPopupSubmit,
}: {
	popupOpen: boolean
	popupData: any
	setPopupData: any
	selectedSbList: any[]
	sbList: any[]
	onPopupClose: () => void
	onSBItemClick: any
	onPopupSubmit: () => void
}) => {
	return (
		<Dialog
			open={popupOpen}
			onClose={onPopupClose}
			TransitionComponent={TransitionEffect}
			fullWidth
		>
			<AppBar
				sx={{ position: 'sticky' }}
				className="noPrint"
			>
				<Toolbar>
					<Typography
						sx={{ flex: 1 }}
						variant="h6"
						component="p"
					>
						Slide/Block 외부반출 신청
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						onClick={onPopupClose}
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Typography
					variant="body1"
					gutterBottom
				>
					* 접수정보
				</Typography>
				<Table
					size="small"
					sx={{ mb: 2, border: '1px solid rgba(224, 224, 224, 1)' }}
				>
					<colgroup>
						<col style={{ width: 95 }} />
						<col style={{ width: 170 }} />
						<col style={{ width: 95 }} />
						<col />
					</colgroup>
					<TableBody>
						<TableRow>
							<TableCell
								sx={{ backgroundColor: 'rgb(227, 242, 253)', textAlign: 'center' }}
							>
								수진자명
							</TableCell>
							<TableCell>
								<TextField
									fullWidth
									variant="outlined"
									value={popupData?.PatientName}
									size="small"
									onChange={(e) => {
										setPopupData({
											...popupData,
											PatientName: e.target.value,
										})
									}}
								/>
							</TableCell>
							<TableCell
								sx={{ backgroundColor: 'rgb(227, 242, 253)', textAlign: 'center' }}
							>
								병리번호
							</TableCell>
							<TableCell>
								<TextField
									fullWidth
									variant="outlined"
									value={popupData?.PathologyNo}
									size="small"
									onChange={(e) => {
										setPopupData({
											...popupData,
											PathologyNo: e.target.value,
										})
									}}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Typography
					variant="body1"
					gutterBottom
				>
					* Slide/Block 선택
				</Typography>
				<TableContainer sx={{ maxHeight: 240 }}>
					<Table
						size="small"
						sx={{ mb: 2, border: '1px solid rgba(224, 224, 224, 1)' }}
						stickyHeader
					>
						<colgroup>
							<col style={{ width: 80 }} />
							<col />
							<col style={{ width: 80 }} />
						</colgroup>
						<TableHead>
							<TableRow>
								<TableCell sx={{ textAlign: 'center' }}>순번</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>항목</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>신청</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sbList &&
								sbList.map((item: any, index: number) => (
									<TableRow key={item.rowNum}>
										<TableCell sx={{ textAlign: 'center' }}>{item.rowNum}</TableCell>
										<TableCell>{item.DisplayName}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{/*
												selectedSbList 에 동일한 rowNum이 없으면 신청버튼 활성화
												없으면 취소 버튼 활성화
												*/}
											{selectedSbList.findIndex((x: any) => x.rowNum === item.rowNum) ===
											-1 ? (
												<Button
													size="small"
													color="success"
													variant="contained"
													onClick={() => onSBItemClick(item)}
												>
													신청
												</Button>
											) : (
												<Button
													size="small"
													color="error"
													variant="contained"
													onClick={() => onSBItemClick(item)}
												>
													취소
												</Button>
											)}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<Typography
					variant="body1"
					gutterBottom
				>
					* 신청항목
				</Typography>
				<TableContainer sx={{ maxHeight: 100 }}>
					<Table
						size="small"
						sx={{ mb: 2, border: '1px solid rgba(224, 224, 224, 1)' }}
						stickyHeader
					>
						<colgroup>
							<col style={{ width: 80 }} />
							<col />
						</colgroup>
						<TableHead>
							<TableRow>
								<TableCell sx={{ textAlign: 'center' }}>순번</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>신청항목</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{selectedSbList &&
								selectedSbList.map((item: any, index: number) => (
									<TableRow key={item.rowNum}>
										<TableCell>{item.rowNum}</TableCell>
										<TableCell>{item.DisplayName}</TableCell>
									</TableRow>
								))}
							{selectedSbList.length === 0 && (
								<TableRow>
									<TableCell
										colSpan={2}
										sx={{ textAlign: 'center' }}
									>
										신청항목이 없습니다.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<Typography
					variant="body1"
					gutterBottom
				>
					비고
				</Typography>
				<TextField
					multiline
					fullWidth
					rows={3}
					variant="outlined"
					sx={{ mb: 2 }}
					value={popupData?.RequestNotes}
					onChange={(e) => {
						setPopupData({
							...popupData,
							RequestNotes: e.target.value,
						})
					}}
				/>
				<Typography
					variant="body2"
					sx={{ mb: 1 }}
				>
					* 신청하신 Slide/Block은 신청일 기준 2일 후에 받아보실 수 있습니다.
					<br />
					<span style={{ color: 'red' }}>
						(단, 금/토요일 접수 분은 익주 화요일에 받아보실 수 있습니다.)
						<br />
						(공휴일이 포함된 날은 공휴일이 끝나는 마지막 날로부터 이틀 뒤 수령)
					</span>
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							value={popupData?.agree}
							onChange={() => {
								setPopupData({
									...popupData,
									agree: !popupData?.agree,
								})
							}}
						/>
					}
					label="확인했습니다."
				/>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					variant="contained"
					color="success"
					onClick={onPopupSubmit}
				>
					신청
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SlideBlockPopup2
