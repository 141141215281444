import { useRef, useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { useDispatch, useSelector } from 'react-redux'
import { selectEditorHtml, setEditorHtml } from 'hooks/editorHtml'
import { editorImageUpload } from 'api/api'

import { darkTheme, lightTheme } from 'constants/defaultValues'
import { selectDisplayMode } from 'hooks/themeMode'
import { styled } from '@mui/material/styles'

// 에디터 참고... https://haranglog.tistory.com/12

/**
 * 에디터 스타일
 */
const EditorContainer = styled('div')(({ theme }) => ({
  '& .rdw-editor-toolbar': {
    background: theme.palette.mode === 'dark' && '#121212',
    border:
      theme.palette.mode === 'dark' ? '1px solid #121212' : '1px solid #ccc',
    '& .rdw-option-wrapper': {
      background: theme.palette.mode === 'dark' && '#cccccc',
      border:
        theme.palette.mode === 'dark' ? '1px solid #323036' : '1px solid #ccc',
    },
    '& .rdw-dropdown-wrapper': {
      background: theme.palette.mode === 'dark' && '#cccccc',
      border:
        theme.palette.mode === 'dark' ? '1px solid #323036' : '1px solid #ccc',
      color: theme.palette.mode === 'dark' && '#323036',
    },
    '& .rdw-image-modal-header-option, & .rdw-image-modal-alt-lbl': {
      color: theme.palette.mode === 'dark' ? '#323036' : '#000',
    },
    '& .rdw-image-modal-alt-input, & .rdw-image-modal-size-input, & .rdw-image-modal-btn, & .rdw-image-modal-url-input':
      {
        border:
          theme.palette.mode === 'dark' ? '1px solid #ccc' : '1px solid #bbb',
      },
    '& .rdw-image-modal-size': {
      color: theme.palette.mode === 'dark' ? '#121212' : '#000',
    },
    '& .rdw-image-modal-header-label': {
      border:
        theme.palette.mode === 'dark' ? `1px solid #ccc` : `1px solid #ccc`,
      '&.rdw-image-modal-header-label-highlighted': {
        borderBottom:
          theme.palette.mode === 'dark'
            ? `1px solid #835FED`
            : `1px solid #835FED`,
      },
    },
  },
}))

/**
 * 에디터 컴포넌트
 * @returns
 */
const WysiwygEditor = ({ htmlContent }: { htmlContent: string }) => {
  const dispatch = useDispatch()
  const themeMode = useSelector(selectDisplayMode)

  const content = useSelector(selectEditorHtml)
  const rendered = useRef(false)
  // EditorState.createEmpty() 로 초기값 설정
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  )

  const htmlToEditor = htmlContent

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState)
    const editorToHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
    // 에디터 상태가 변경되면 redux에 저장한다
    dispatch(setEditorHtml(editorToHtml))
  }

  const uploadImageCallBack = (file: File) => {
    return new Promise((resolve, reject) => {
      editorImageUpload(file)
        .then((res: string) => {
          console.log(`${process.env.REACT_APP_HOST}${res}`)
          resolve({
            data: {
              link: `${process.env.REACT_APP_HOST}${res}`,
            },
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(htmlToEditor)
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml
      // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      // ContentState를 EditorState기반으로 새 개체를 반환.
      // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
      const editorToHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )
      console.log(editorToHtml)
      dispatch(setEditorHtml(editorToHtml))
    }
  }, [])

  return (
    <EditorContainer>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alt: { present: true, mandatory: true },
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
            defaultSize: {
              height: 'auto',
              width: '100%',
            },
          },
        }}
        localization={{
          locale: 'ko',
        }}
        editorStyle={{
          minHeight: '300px',
          padding: '1rem',
          border: themeMode.isDarkMode ? '1px solid #666' : '1px solid #ccc',
        }}
      />
    </EditorContainer>
  )
}

export default WysiwygEditor
