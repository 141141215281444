import { createSlice } from '@reduxjs/toolkit'

export const gridColumnV11Slice = createSlice({
	name: 'gridColumnV11',
	initialState: {
		info: {},
	},
	reducers: {
		setGridColumnV11: (state, actions) => {
			state.info = actions.payload
		},
		removeGridColumnV11: (state) => {
			state.info = {}
		},
	},
})

export const { setGridColumnV11, removeGridColumnV11 } =
	gridColumnV11Slice.actions
export default gridColumnV11Slice.reducer
export const selectGridColumnInfoV11 = (state: any) => state.gridColumnV11
