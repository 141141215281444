import { createSlice } from '@reduxjs/toolkit'

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: {
		info: {
			roles: [],
			username: '',
			userId: '',
			infoType: '',
			active: false,
			master: false,
			clientID: 0,
			clientName: '',
			tablePostFix: '',
			departName: '',
			ward: '',
			emp2: false,
			medicalNum: '',
			branchCode: '',
			isImageResult: false,
			isSmsActive: false,
			isPrintView: false,
			ocsDownload: false,
			isXlsx: false,
			downloadLoc: '',
			isEHWA: false,
			isEmpSub: false,
			isEHWAClass: false,
		},
	},
	reducers: {
		setUserInfo: (state, actions) => {
			state.info = actions.payload
		},
		removeUserInfo: (state) => {
			state.info = {
				roles: [],
				username: '',
				userId: '',
				infoType: '',
				active: false,
				master: false,
				clientID: 0,
				clientName: '',
				tablePostFix: '',
				departName: '',
				ward: '',
				emp2: false,
				medicalNum: '',
				branchCode: '',
				isImageResult: false,
				isSmsActive: false,
				isPrintView: false,
				ocsDownload: false,
				isXlsx: false,
				downloadLoc: '',
				isEHWA: false,
				isEmpSub: false,
				isEHWAClass: false,
			}
		},
	},
})

export const { setUserInfo, removeUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer
export const selectuserInfo = (state: any) => state.userInfo.info
