import * as React from 'react'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'

/**
 * 팝업이나 기타 오버레이로 출력되는 페이지의 애니메이션을 위한 컴포넌트
 */
const TransitionComponent = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default TransitionComponent
