import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Alert,
	Stack,
	Grid,
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	LinearProgress,
	Select,
	useMediaQuery,
	TextField,
	Breadcrumbs,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material'

import moment from 'moment'
import 'moment/locale/ko'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridApi,
	GridDensity,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import { useTheme } from '@mui/material/styles'
import { PageBg } from 'components/ComponentStyles'
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'
import { getSettings } from 'helpers/storage'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
	syncList,
	tableName,
	textDownload,
	apiSyncDownload,
	getExcelDownload,
	getExcelDownload2,
} from 'api/api'
import { selectuserInfo } from 'hooks/userInfo'

import { headerOcsKChart } from './gridHeaders/headerOcsKChart'
import { headerOcsMedi4u } from './gridHeaders/headerOcsMedi4u'
import { headerOcsAmis } from './gridHeaders/headerOcsAmis'
import { headerOcsByungcom } from './gridHeaders/headerOcsByungcom'
import { headerOcsBitEmr } from './gridHeaders/headerOcsBitEmr'
import { headerOcsBitEmr2 } from './gridHeaders/headerOcsBitEmr2'
import { headerOcsBrain } from './gridHeaders/headerOcsBrain'
import { headerOcsEonm } from './gridHeaders/headerOcsEonm'
import { headerOcsOntic } from './gridHeaders/headerOcsOntic'
import { headerOcsHwasan } from './gridHeaders/headerOcsHwasan'
import { headerOcsEHealth } from './gridHeaders/headerOcsEHealth'
import { headerOcsMdjin } from './gridHeaders/headerOcsMdjin'
import { headerOcsEmc } from './gridHeaders/headerOcsEmc'
import { headerOcsNeo } from './gridHeaders/headerOcsNeo'
import { headerOcsMsysTech } from './gridHeaders/headerOcsMsysTech'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'

import { hexToRgba } from 'util/util'

import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { compareDateValues } from 'util/stringUtil'
import ocsMedi4u from './ocsDownloadXlsx/ocsMedi4u'
import ocsAmis from './ocsDownloadXlsx/ocsAmis'
import ocsKchart from './ocsDownloadXlsx/ocsKchart'
import ocsBitEmr from './ocsDownloadXlsx/ocsBitEmr'
import ocsBitEmr2 from './ocsDownloadXlsx/ocsBitEmr2'
import ocsBrain from './ocsDownloadXlsx/ocsBrain'
import ocsEonm from './ocsDownloadXlsx/ocsEonm'
import ocsOntic from './ocsDownloadXlsx/ocsOntic'
import ocsHwasan from './ocsDownloadXlsx/ocsHwasan'
import ocsMdjin from './ocsDownloadXlsx/ocsMdjin'
import ocsEmc from './ocsDownloadXlsx/ocsEmc'
import ocsNeo from './ocsDownloadXlsx/ocsNeo'
import ocsMsysTech from './ocsDownloadXlsx/ocsMsysTech'
import { sheetjsExcelDownload } from './ocsDownloadXlsx/excelDownload'
import ocsEhealth from './ocsDownloadXlsx/ocsEhealth'

const locales = ['ko'] as const

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const RadioWrapper = styled('div')(({ theme }) => ({
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(255,255,255,0.23)'
			: '1px solid rgba(0,0,0,0.23)',
	display: 'flex',
	height: 37,
	alignItems: 'center',
	justifyContent: 'flex-start',
	paddingLeft: '1rem',
	borderRadius: '0.3rem',
	position: 'relative',
	'.labelText': {
		position: 'absolute',
		top: '0',
		left: '0',
		fontSize: '0.8571428571428571rem',
		fontWeight: 400,
		transform: 'translate(14px, -11px) scale(0.75)',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		background: theme.palette.mode === 'dark' ? '#323036' : '#fff',
		paddingLeft: '0.2rem',
		paddingRight: '0.2rem',
		color:
			theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
	},
}))

const TestSyncDownload = () => {
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)
	const navigate = useNavigate()
	const currentUser = useSelector(selectuserInfo)

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const env = getSettings()

	const apiRef = useGridApiRef()
	const tempApiRef = useGridApiRef()

	const [limit, setLimit] = useState(true)

	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')
	// 사용자 정보

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const [statusList, setStatusList] = useState<any[]>([])
	const [downloadList, setDownloadList] = useState<any[]>([])

	const [disabledSync, setDisabledSync] = useState(false)
	const [disabledDownload, setDisabledDownload] = useState(false)
	const [disabledUpload, setDisabledUpload] = useState(false)
	const [downloadType, setDownloadType] = useState<string>('')
	//2024-02-01 추가
	const [downloadLoc, setDownloadLoc] = useState<string>('web')

	const actionSearch = () => {
		getSyncList()
	}
	const actionReset = () => {
		setSearch({
			keyword: '',
			status: '30',
			downloadStatus: '30',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(1, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			searchType: 'R',
		})
		setDateRange('1d')
	}

	const [gridHeaderName, setGridHeaderName] = useState('')

	let columns: GridColDef[] = []

	if (gridHeaderName === 'OCS_MEDI4U') {
		columns = headerOcsMedi4u
	} else if (gridHeaderName === 'OCS_AMIS') {
		columns = headerOcsAmis
	} else if (gridHeaderName === 'OCS_BYUNGCOM') {
		columns = headerOcsByungcom
	} else if (gridHeaderName === 'OCS_KCHART') {
		columns = headerOcsKChart
	} else if (
		gridHeaderName === 'OCS_BITEMR' ||
		gridHeaderName === 'OCS_FRIPOS' ||
		gridHeaderName === 'OCS_BITNIXSILVER'
	) {
		columns = headerOcsBitEmr
	} else if (gridHeaderName === 'OCS_BITEMR2') {
		columns = headerOcsBitEmr2
	} else if (gridHeaderName === 'OCS_BRAIN') {
		columns = headerOcsBrain
	} else if (gridHeaderName === 'OCS_EONM') {
		columns = headerOcsEonm
	} else if (gridHeaderName === 'OCS_ONTIC') {
		columns = headerOcsOntic
	} else if (gridHeaderName === 'OCS_HWASAN') {
		columns = headerOcsHwasan
	} else if (gridHeaderName === 'OCS_EHEALTH') {
		columns = headerOcsEHealth
	} else if (gridHeaderName === 'OCS_MDJIN') {
		columns = headerOcsMdjin
	} else if (gridHeaderName === 'OCS_EMC') {
		columns = headerOcsEmc
	} else if (gridHeaderName === 'OCS_NEO') {
		columns = headerOcsNeo
	} else if (gridHeaderName === 'OCS_MSYSTECH') {
		columns = headerOcsMsysTech
	} else {
		columns = [
			{
				field: 'SEQ',
				headerName: '번호',
				type: 'string',
				width: 80,
				editable: false,
			},
		]
	}

	// grid state
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)
	const [gridPage, setGridPage] = useState(0)
	const [gridRowCount, setGridRowCount] = useState(0)

	const [dateRange, setDateRange] = useState('1d')

	// grid row click event
	const onRowClick = (e: GridRowModel) => {
		console.log(e.row)
		setGridSelectionModel([e.id])
		if (e.id && e.row.RItemID === 0 && e.row.TESTSTATUS === '10') {
			setDisabledDel(false)
			//setDisabledSave(false)
		} else {
			setDisabledDel(true)
			//setDisabledSave(true)
		}
	}

	// 조회조건
	const [search, setSearch] = useState({
		keyword: '',
		status: '30',
		downloadStatus: '30',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(1, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		searchType: 'R',
	})
	const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {}

	// 첨부파일 업로드
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0]) {
			setFileObject(acceptedFiles[0])
		}
	}, [])

	const [tmpColumns, setTmpColumns] = useState<any[]>([])
	const [tmpIsOpen, setTmpIsOpen] = useState(false)
	const [tmpDataList, setTmpDataList] = useState<any[]>([])
	const [tmpGridSelectionModel, setTmpGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [tmpIsDuplicate, setTmpIsDuplicate] = useState(false)

	const [fileObject, setFileObject] = useState<File | null>(null)
	const [uploadOpen, setUploadOpen] = useState(false)

	const [fileInfo, setFileInfo] = useState({
		fileName: '',
		orgFileName: '',
	})

	const setCloseDialog = () => {
		setUploadOpen(false)
		setFileObject(null)
		setTmpIsDuplicate(false)
		setTmpDataList([])
		setTmpColumns([])
		setTmpGridSelectionModel([])
	}

	const getSyncList = () => {
		// 다운로드 상태 = 미완 : 30, 완료: 40 전체 : 30,40
		// 접수상태 10,20 일때 다운로드 조건 비활성화
		// 접수상태 전체 > 접수상태 무시 및 다운로드 상태 30, 40
		// 조회 기본값 : 결과완료, 미완료, 보고일, 1일
		// 접수상태 > 결과다운완료는 삭제

		// limit === true : 기간제한	limit === false : 기간제한 없음
		const dateCompare = limit
			? compareDateValues(search.startDate, search.endDate)
			: true
		console.log(limit, dateCompare)
		if (dateCompare) {
			const request = {
				keyword: '',
				status:
					search.status === 'A'
						? search.downloadStatus === 'A'
							? ''
							: search.downloadStatus
						: search.status === '30'
						? search.downloadStatus === 'A'
							? '50'
							: search.downloadStatus !== 'A'
							? search.downloadStatus
							: ''
						: search.status,
				startDate: search.startDate,
				endDate: search.endDate,
				searchType: search.searchType,
			}

			console.table(request)

			setDisabledSearch(true)

			// gridLoading
			setGridLoading(true)

			syncList(request)
				.then((res) => {
					setGridRows(res)
				})
				.finally(() => {
					setDisabledSearch(false)
					setGridLoading(false)
				})

			// if (currentUser.medicalNum === '31209360') {
			// 	syncList2(request)
			// 		.then((res) => {
			// 			setGridRows(res)
			// 		})
			// 		.finally(() => {
			// 			setDisabledSearch(false)
			// 			setGridLoading(false)
			// 		})
			// } else {
			// 	syncList(request)
			// 		.then((res) => {
			// 			setGridRows(res)
			// 		})
			// 		.finally(() => {
			// 			setDisabledSearch(false)
			// 			setGridLoading(false)
			// 		})
			// }
		}
	}

	const [noSettings, setNoSettings] = useState({
		data: '',
		success: false,
	})

	const excelRef = useGridApiRef()
	const [excelRows, setExcelRows] = useState<any[]>([])

	const actionExcelWas = () => {
		const dateCompare = compareDateValues(search.startDate, search.endDate)

		if (dateCompare) {
			setDisabledDownload(true)
			const request = {
				status:
					search.status === 'A'
						? search.downloadStatus === 'A'
							? ''
							: search.downloadStatus
						: search.status === '30'
						? search.downloadStatus === 'A'
							? '50'
							: search.downloadStatus !== 'A'
							? search.downloadStatus
							: ''
						: search.status,
				startDate: search.startDate,
				endDate: search.endDate,
				searchType: search.searchType,
				clientID: `${currentUser?.clientID}`,
			}
			window.open(
				`${process.env.REACT_APP_HOST2}/sync/excelDownloadWas?clientID=${currentUser?.clientID}&status=${request.status}&startDate=${request.startDate}&endDate=${request.endDate}&searchType=${request.searchType}`,
				'결과엑셀다운로드'
			)
			setTimeout(() => {
				setDisabledDownload(false)
			}, 2000)
		}
	}

	const actionExcelJs = () => {
		const dateCompare = compareDateValues(search.startDate, search.endDate)
		if (dateCompare) {
			setDisabledDownload(true)
			const request = {
				status:
					search.status === 'A'
						? search.downloadStatus === 'A'
							? ''
							: search.downloadStatus
						: search.status === '30'
						? search.downloadStatus === 'A'
							? '50'
							: search.downloadStatus !== 'A'
							? search.downloadStatus
							: ''
						: search.status,
				startDate: search.startDate,
				endDate: search.endDate,
				searchType: search.searchType,
				clientID: currentUser?.clientID ?? '',
			}

			//2024-02-01 추가 시작
			//downloadLoc가 was 인경우 서버 자원을 사용(jxl.jar 이용) 아닌 경우 기존과 같이 Sheet.js 이용
			// if (downloadLoc === 'was') {
			// 	window.open(
			// 		`${process.env.REACT_APP_HOST}/sync/excelDownloadWas?clientID=${currentUser?.clientID}&clientName=${currentUser?.clientName}&status=${request.status}&startDate=${request.startDate}&endDate=${request.endDate}&searchType=${request.searchType}&moduleType=jxl`,
			// 		'결과엑셀다운로드'
			// 	)
			// 	setTimeout(() => {
			// 		setDisabledDownload(false)
			// 	}, 2000)
			// } else {
			// 	getExcelDownload(request)
			// 		.then((res) => {
			// 			sheetjsExcelDownload(
			// 				(currentUser?.clientName ?? '') + '_' + res.fileName,
			// 				res.downloadType,
			// 				res.sheetName,
			// 				res.list,
			// 				res.header,
			// 				res.column,
			// 				currentUser.clientID
			// 			)
			// 		})
			// 		.finally(() => {
			// 			setDisabledDownload(false)
			// 		})
			// }
			//2024-02-01 추가 종료

			getExcelDownload(request)
				.then((res) => {
					console.log(res)
					console.log(res.downloadType)

					let isOver4000byte = false
					const { list } = res
					// slice top 100 rows from list
					list.forEach((element: any) => {
						let { ResultData, ResultText } = element

						// ResultData 또는 ResultText가 4000byte 이상인 경우 was로 다운로드
						const resultDataByte = new Blob([ResultData]).size
						const resultTextByte = new Blob([ResultText]).size
						if (resultDataByte > 4000 || resultTextByte > 4000) {
							console.log(resultDataByte)
							isOver4000byte = true
						}
					})

					if (isOver4000byte) {
						console.log('actionExcelWas')
						actionExcelWas()
					} else {
						sheetjsExcelDownload(
							currentUser.clientID === 2293
								? res.fileName
								: (currentUser?.clientName ?? '') + '_' + res.fileName,
							false,
							res.sheetName,
							list,
							res.header,
							res.column,
							currentUser.clientID
						)
					}
				})
				.finally(() => setDisabledDownload(false))
		}
	}

	const actionExcelJs2 = () => {
		const dateCompare = compareDateValues(search.startDate, search.endDate)
		if (dateCompare) {
			setDisabledDownload(true)
			const request = {
				status:
					search.status === 'A'
						? search.downloadStatus === 'A'
							? ''
							: search.downloadStatus
						: search.status === '30'
						? search.downloadStatus === 'A'
							? '50'
							: search.downloadStatus !== 'A'
							? search.downloadStatus
							: ''
						: search.status,
				startDate: search.startDate,
				endDate: search.endDate,
				searchType: search.searchType,
				clientID: currentUser?.clientID ?? '',
			}

			getExcelDownload2(request)
				.then((res) => {
					console.log(res)
					console.log(res.downloadType)
					sheetjsExcelDownload(
						currentUser.clientID === 2293
							? res.fileName
							: (currentUser?.clientName ?? '') + '_' + res.fileName,
						false,
						res.sheetName,
						res.list,
						res.header,
						res.column,
						currentUser.clientID
					)
				})
				.finally(() => setDisabledDownload(false))
		}
	}

	/**
	 * OCS 결과 다운로드 2024.01.08 미라벨 작업분
	 */
	// const actionExcel = () => {
	// 	const dateCompare = compareDateValues(search.startDate, search.endDate)

	// 	if (dateCompare) {
	// 		setDisabledDownload(true)
	// 		const request = {
	// 			status:
	// 				search.status === 'A'
	// 					? search.downloadStatus === 'A'
	// 						? ''
	// 						: search.downloadStatus
	// 					: search.status === '30'
	// 					? search.downloadStatus === 'A'
	// 						? '50'
	// 						: search.downloadStatus !== 'A'
	// 						? search.downloadStatus
	// 						: ''
	// 					: search.status,
	// 			startDate: search.startDate,
	// 			endDate: search.endDate,
	// 			searchType: search.searchType,
	// 			clientID: `${currentUser?.clientID}`,
	// 		}

	// 		apiSyncDownload(request)
	// 			.then((res) => {
	// 				console.log(res)
	// 				// OCS_BYUNGCOM , OCS_EHEALTH는 다운로드 없음
	// 				if (currentUser.tablePostFix === 'OCS_MEDI4U') {
	// 					ocsMedi4u(res)
	// 				} else if (currentUser.tablePostFix === 'OCS_AMIS') {
	// 					ocsAmis(res)
	// 				} else if (currentUser.tablePostFix === 'OCS_KCHART') {
	// 					ocsKchart(res, currentUser.clientID)
	// 				} else if (currentUser.tablePostFix === 'OCS_BITEMR') {
	// 					ocsBitEmr(res, currentUser.clientID)
	// 				} else if (currentUser.tablePostFix === 'OCS_BITEMR2') {
	// 					ocsBitEmr2(res)
	// 				} else if (currentUser.tablePostFix === 'OCS_BRAIN') {
	// 					ocsBrain(res, currentUser.clientID)
	// 				} else if (currentUser.tablePostFix === 'OCS_EONM') {
	// 					ocsEonm(res, currentUser.clientID, currentUser.clientName)
	// 				} else if (currentUser.tablePostFix === 'OCS_ONTIC') {
	// 					ocsOntic(res, currentUser.clientID)
	// 				} else if (currentUser.tablePostFix === 'OCS_HWASAN') {
	// 					ocsHwasan(res, currentUser.clientID)
	// 				} else if (currentUser.tablePostFix === 'OCS_EHEALTH') {
	// 					ocsEhealth(res)
	// 				} else if (currentUser.tablePostFix === 'OCS_MDJIN') {
	// 					ocsMdjin(res, currentUser.clientName)
	// 				} else if (currentUser.tablePostFix === 'OCS_EMC') {
	// 					ocsEmc(res)
	// 				} else if (currentUser.tablePostFix === 'OCS_NEO') {
	// 					ocsNeo(res)
	// 				} else if (currentUser.tablePostFix === 'OCS_MSYSTECH') {
	// 					ocsMsysTech(res)
	// 				}
	// 			})
	// 			.finally(() => {
	// 				setDisabledDownload(false)
	// 			})
	// 	}
	// }

	const actionTxt = () => {
		const dateCompare = compareDateValues(search.startDate, search.endDate)

		if (dateCompare) {
			setDisabledDownload(true)
			const request = {
				clientID: currentUser?.clientID,
				status:
					search.status === 'A'
						? search.downloadStatus === 'A'
							? ''
							: search.downloadStatus
						: search.status === '30'
						? search.downloadStatus === 'A'
							? ''
							: search.downloadStatus !== 'A'
							? search.downloadStatus
							: ''
						: search.status,
				startDate: search.startDate,
				endDate: search.endDate,
				searchType: search.searchType,
			}

			const fileName = currentUser.userId + '_' + moment().format('YYYYMMDDHHmmss')
			textDownload(request, fileName)
				.then((res) => {
					console.log(res)
				})
				.finally(() => {
					setDisabledDownload(false)
				})
		}
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		// startDate subtract n day from endDate
		setSearch({
			...search,
			startDate: moment(search.endDate).subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '1d':
				getBeforeDate(1)
				break
			case '2d':
				getBeforeDate(2)
				break
			case '3d':
				getBeforeDate(3)
				break
			case '7d':
				getBeforeDate(7)
				break
			default:
				getBeforeDate(7)
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		setSearch((prevState) => {
			return {
				...search,
				[target]: moment(new Date(e)).format('yyyy-MM-DD'),
			}
		})
	}

	useEffect(() => {
		// 공통코드
		let lv2 = extractCmmnCode(cmmnCdList, 'OCS_STATUS', 1)
		setStatusList(lv2?.level2)
		// reportFileByClientID().then((res) => {
		// 	if (res.success === false) {
		// 		setDisabledAdd(true)
		// 		setDisabledDel(true)
		// 		setDisabledSave(true)
		// 		setDisabledSync(true)
		// 		setDisabledSearch(true)
		// 		setDisabledReset(true)
		// 		setDisabledDownload(true)
		// 		setNoSettings({
		// 			data: res.data,
		// 			success: res.success,
		// 		})
		// 	} else {
		// 		setFileInfo(res)
		// 		setNoSettings({
		// 			data: '',
		// 			success: true,
		// 		})
		// 	}
		// })

		// if (currentUser?.infoType === 'C' && currentUser?.clientID === 4194) {
		// 	navigate('/admin/test/syncOld')
		// }

		// 다운로드 상태 = 미완 : 30, 완료: 40 전체 : 30,40
		// 접수상태 10,20 일때 다운로드 조건 비활성화
		// 접수상태 전체 > 접수상태 무시 및 다운로드 상태 30, 40
		// 조회 기본값 : 결과완료, 미완료, 보고일, 1일
		// 접수상태 > 결과다운완료는 삭제

		lv2 = extractCmmnCode(cmmnCdList, 'OCS_DOWNLOAD', 1)
		setDownloadList(lv2?.level2)
		if (currentUser.infoType === 'C') {
			tableName()
				.then((res) => {
					if (res) {
						console.log(res)
						setGridHeaderName(res.TablePostFix)
						setDisabledAdd(false)
						setDisabledDel(true)
						setDisabledSave(true)
						setDisabledSync(false)
						setDisabledSearch(false)
						setDisabledReset(false)
						setDisabledUpload(false)
						setDownloadType(res.DownloadType)
						//2024-02-01 추가
						setDownloadLoc(currentUser.downloadLoc ?? 'web')

						if (
							res.TablePostFix === 'OCS_BYUNGCOM' ||
							res.TablePostFix === 'OCS_EHEALTH'
						) {
							setDisabledDownload(true)
						} else {
							setDisabledDownload(false)
						}
						getSyncList()
					} else {
						setGridHeaderName('')
						setDisabledAdd(true)
						setDisabledDel(true)
						setDisabledSave(true)
						setDisabledSync(true)
						setDisabledSearch(true)
						setDisabledReset(true)
						setDisabledDownload(true)
						setDisabledUpload(true)
					}
				})
				.then(() => {})
		} else {
			setDisabledAdd(true)
			setDisabledDel(true)
			setDisabledSave(true)
			setDisabledSync(true)
			setDisabledSearch(true)
			setDisabledReset(true)
			setDisabledDownload(true)
			setDisabledUpload(true)
		}
	}, [])

	return (
		<>
			{/* <PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={true}
				hideSave={true}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/> */}
			<button
				type="button"
				style={{
					position: 'fixed',
					bottom: 0,
					right: 0,
					width: '10px',
					height: '10px',
					cursor: 'pointer',
					border: 0,
					outline: 0,
					background: 'transparent',
				}}
				onClick={() => {
					setLimit(!limit)
				}}
			/>
			<Stack sx={{ marginBottom: '1rem' }}>
				<PageWrap>
					<PageBg2>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								OCS 결과 다운로드
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								{/* <span>
									<Button
										type="button"
										variant="contained"
										color="info"
										size="small"
										startIcon={<CloudUploadTwoToneIcon />}
										disabled={disabledUpload}
										onClick={actionAdd}
										sx={{ mr: 1 }}
									>
										접수 업로드
									</Button>
								</span> */}
								<>
									{currentUser.medicalNum === '31209360' && (
										<span>
											{downloadType &&
												(downloadType === 'xls' || downloadType === 'all') && (
													<Button
														type="button"
														variant="contained"
														color="error"
														size="small"
														startIcon={<DownloadForOfflineTwoToneIcon />}
														disabled={disabledDownload}
														onClick={actionExcelJs2}
														sx={{ mr: 1 }}
													>
														한사랑병원 (화산) 엑셀 다운로드
													</Button>
												)}
										</span>
									)}
									{currentUser.tablePostFix === 'OCS_EONM' ? (
										<span>
											{downloadType &&
												(downloadType === 'xls' || downloadType === 'all') && (
													<Button
														type="button"
														variant="contained"
														color="error"
														size="small"
														startIcon={<DownloadForOfflineTwoToneIcon />}
														disabled={disabledDownload}
														onClick={actionExcelWas}
														sx={{ ml: 1 }}
													>
														엑셀 다운로드
													</Button>
												)}
										</span>
									) : (
										<span>
											{downloadType &&
												(downloadType === 'xls' || downloadType === 'all') && (
													<Button
														type="button"
														variant="contained"
														color="error"
														size="small"
														startIcon={<DownloadForOfflineTwoToneIcon />}
														disabled={disabledDownload}
														onClick={actionExcelJs}
													>
														엑셀 다운로드
													</Button>
												)}
										</span>
									)}
									{/*<span>
										{downloadType &&
											(downloadType === 'xls' || downloadType === 'all') && (
												<Button
													type="button"
													variant="contained"
													color="secondary"
													size="small"
													startIcon={<DownloadForOfflineTwoToneIcon />}
													disabled={disabledDownload}
													onClick={actionExcelWas}
													sx={{ ml: 1 }}
												>
													보아스 엑셀 (서버 부하)
												</Button>
											)}
									</span>
									<span>
										{downloadType &&
											(downloadType === 'xls' || downloadType === 'all') && (
												<Button
													type="button"
													variant="contained"
													color="secondary"
													size="small"
													startIcon={<DownloadForOfflineTwoToneIcon />}
													disabled={disabledDownload}
													onClick={actionExcel}
													sx={{ ml: 1 }}
												>
													보아스 엑셀 (PC 부하)
												</Button>
											)}
										</span> */}
									{downloadType &&
										(downloadType === 'txt' || downloadType === 'all') && (
											<span>
												<Button
													type="button"
													variant="contained"
													color="error"
													size="small"
													startIcon={<DownloadForOfflineTwoToneIcon />}
													disabled={disabledDownload}
													sx={{ ml: 1 }}
													onClick={actionTxt}
												>
													텍스트 다운로드
												</Button>
											</span>
										)}
								</>
								{/* <span>
									<Button
										type="button"
										variant="contained"
										color="info"
										size="small"
										onClick={actionSync}
										startIcon={<CloudSyncIcon />}
										sx={{ ml: 1 }}
										disabled={disabledSync}
									>
										동기화 요청
									</Button>
								</span> */}
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSearchSubmit}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									lg={1}
								>
									<FormControl fullWidth>
										<InputLabel id="date-range-select-label">접수 상태</InputLabel>
										<Select
											labelId="date-range-select-label"
											id="date-range-simple-select"
											value={search.status}
											disabled
											label="접수 상태"
											name="status"
											onChange={(e) => {
												if (e.target.value === '10' || e.target.value === '20') {
													setSearch({
														...search,
														status: e.target.value,
														downloadStatus: 'A',
													})
												} else if (e.target.value === '30') {
													setSearch({
														...search,
														status: e.target.value,
													})
												} else {
													setSearch({
														...search,
														status: 'A',
													})
												}
											}}
											size={'small'}
										>
											<MenuItem value="A">전체</MenuItem>
											<MenuItem value="10">의뢰완료</MenuItem>
											<MenuItem value="20">접수완료</MenuItem>
											<MenuItem value="30">결과완료</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									{/* // 다운로드 상태 = 미완 : 30, 완료: 40 전체 : 30,40
		// 접수상태 10,20 일때 다운로드 조건 비활성화
		// 접수상태 전체 > 접수상태 무시 및 다운로드 상태 30, 40
		// 조회 기본값 : 결과완료, 미완료, 보고일, 1일
		// 접수상태 > 결과다운완료는 삭제 */}
									<RadioWrapper>
										<p className="labelText">다운로드 상태</p>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby="date-controlled-radio-buttons-group"
												name="date-radio-buttons-group"
												value={search.downloadStatus}
												onChange={(e) => {
													setSearch({
														...search,
														downloadStatus: e.target.value,
													})
												}}
											>
												<FormControlLabel
													value="30"
													control={<Radio sx={{ padding: '1px' }} />}
													label="미완료"
													disabled={search.status === '10' || search.status === '20'}
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
														mr: 3,
													}}
												/>
												<FormControlLabel
													value="40"
													control={<Radio sx={{ padding: '1px' }} />}
													label="완료"
													disabled={search.status === '10' || search.status === '20'}
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
														mr: 3,
													}}
												/>
												<FormControlLabel
													value="A"
													control={<Radio sx={{ padding: '1px' }} />}
													label="전체"
													disabled={search.status === '10' || search.status === '20'}
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
											</RadioGroup>
										</FormControl>
									</RadioWrapper>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									<RadioWrapper>
										<p className="labelText">조회조건</p>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby="date-controlled-radio-buttons-group"
												name="date-radio-buttons-group"
												value={search.searchType}
												onChange={(e) =>
													setSearch({
														...search,
														searchType: e.target.value,
													})
												}
											>
												<FormControlLabel
													value="R"
													control={<Radio sx={{ padding: '1px' }} />}
													label="의뢰일"
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
														mr: 3,
													}}
												/>
												<FormControlLabel
													value="D"
													control={<Radio sx={{ padding: '1px' }} />}
													label="보고일"
													sx={{
														'& .MuiFormControlLabel-label': { fontSize: '12px' },
													}}
												/>
											</RadioGroup>
										</FormControl>
									</RadioWrapper>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									{isLgUp ? (
										<RadioWrapper>
											<p className="labelText">조회기간</p>
											<FormControl>
												<RadioGroup
													row
													aria-labelledby="date-controlled-radio-buttons-group"
													name="date-radio-buttons-group"
													value={dateRange}
													onChange={searchDateRanageOnChange}
												>
													<FormControlLabel
														value="1d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="1일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
															mr: 3,
														}}
													/>
													<FormControlLabel
														value="2d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="2일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
															mr: 3,
														}}
													/>
													<FormControlLabel
														value="3d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="3일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
															mr: 3,
														}}
													/>
													<FormControlLabel
														value="7d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="7일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
															mr: 3,
														}}
													/>
													{/* <FormControlLabel
														value="14d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="14일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/> */}
												</RadioGroup>
											</FormControl>
										</RadioWrapper>
									) : (
										<FormControl fullWidth>
											<InputLabel id="date-range-select-label">조회 기간</InputLabel>
											<Select
												labelId="date-range-select-label"
												id="date-range-simple-select"
												value={dateRange}
												label="조회 기간"
												onChange={searchDateRanageOnChange}
												size={'small'}
											>
												<MenuItem value={'0'}>선택하세요.</MenuItem>
												<MenuItem value={'1d'}>1일</MenuItem>
												<MenuItem value={'2d'}>2일</MenuItem>
												<MenuItem value={'3d'}>3일</MenuItem>
												<MenuItem value={'7d'}>7일</MenuItem>
											</Select>
										</FormControl>
									)}
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									<DatePicker
										label="시작일"
										value={search.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={2}
								>
									<DatePicker
										label="종료일"
										value={search.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Box>
				</PageBg>
			</Stack>
			<PageBg style={{ marginTop: '1rem', width: '100%', overflow: 'hidden' }}>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: gridHeaderName ? 'flex-end' : 'space-between',
							}}
						>
							{gridHeaderName === '' ? (
								<Alert severity="error">검사결과연동 이용이 불가능합니다.</Alert>
							) : (
								<div />
							)}
							<div></div>
						</div>
					</Grid>
					<Box
						sx={{
							width: '100%',
							overflow: 'auto',
							'@media (max-width: 1199px)': {
								height: '400px',
							},
						}}
					>
						<DataGridPremium
							apiRef={apiRef}
							rows={gridRows}
							getRowId={(row) => row.SEQ}
							columns={columns}
							pageSize={gridPageSize}
							onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={isMdUp ? true : false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									excelOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
									showQuickFilter: false,
									quickFilterProps: { debounceMs: 250 },
								},
							}}
							loading={gridLoading}
							selectionModel={gridSelectionModel}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
							rowHeight={40}
						/>
					</Box>
				</Grid>
			</PageBg>
			{/* <DataGridPremium
				apiRef={excelRef}
				rows={excelRows}
				getRowId={(row) => row.SEQ}
				columns={[
					{
						field: 'SEQ',
						headerName: '번호',
						type: 'string',
						width: 80,
						editable: false,
					},
					{
						field: 'CHARTNO',
						headerName: '차트번호',
						type: 'string',
						width: 120,
						editable: false,
					},
					{
						field: 'EXAMNM',
						headerName: '검사명',
						type: 'string',
						width: 120,
						editable: false,
					},
					{
						field: 'Reference',
						headerName: '참고치',
						type: 'string',
						width: 120,
					},
				]}
			/> */}
		</>
	)
}

export default TestSyncDownload
