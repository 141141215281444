import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	InputAdornment,
	ButtonGroup,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridCellEditCommitParams,
	GridRenderEditCellParams,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
	GridRowModesModel,
	GridRowsProp,
	GridCellEditStopReasons,
	GridRowEditStopReasons,
	GridRowId,
	GridRowModes,
	GridRenderCellParams,
	GridEditRowsModel,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { alertModal, confirmAlert, hexToRgba } from 'util/util'
import SaveAsTwoTone from '@mui/icons-material/SaveAsTwoTone'

import {
	apiSMSGroupList,
	apiSMSGroupSave,
	apiSMSSlvList,
	apiSMSSlvSave,
	apiSlaveCopy,
} from 'api/api'
import ClientSearchPopup from 'views/test/clientSearchPopup'

import Divider from '@mui/material/Divider'
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import BackupTwoToneIcon from '@mui/icons-material/BackupTwoTone'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import { valuesInIterator } from 'util/objectUtil'

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const BlankPage = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(false)

	const [search, setSearch] = useState({
		ClientID: userInfo.ClientID,
		ChartNo: '',
		PatientName: '',
	})
	const [slvSearch, setSlvSearch] = useState({
		ClientID: search.ClientID,
		MstID: '',
		ChartNo: '',
		PatientName: '',
	})

	const actionSave = () => {
		const aa = mstRef.current?.getSelectedRows()
		console.log(aa)

		const valuesArrays = valuesInIterator(mstRef.current.getSelectedRows())
		console.log(valuesArrays)

		// const data = {
		// 	ClientID:
		// 		userInfo.infoType === 'S' || userInfo.infoType === 'W'
		// 			? search.ClientID
		// 			: userInfo.clientID,
		// 	json: [...mstRows],
		// }
		// console.log(data)
	}
	const actionDel = () => {
		if (selectionMstModel.length === 0) {
			alertModal('삭제 할 행을 선택하세요.', 'error', () => {})
			return
		}
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				setGridMstLoading(true)
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [
						{
							MstID: selectionMstModel[0],
							RowCrud: 'D',
						},
					],
				}
				console.log(data)
				apiSMSGroupSave(data)
					.then((res) => {
						console.log(res)
						alertModal(res.result, 'success', () => {
							setGroupName('')
							actionSearch()
						})
					})
					.finally(() => {
						setGridMstLoading(false)
					})
			},
			() => {}
		)
	}
	const actionSearch = () => {
		console.table(search)
		setGridMstLoading(true)
		apiSMSGroupList(search)
			.then((res) => {
				console.log(res)
				//setMstRows(res)
			})
			.finally(() => {
				setGridMstLoading(false)
			})
	}

	const [clientList, setClientList] = useState<any[]>([])

	// grid state
	const mstRef = useGridApiRef()
	const [rows, setRows] = useState([
		{
			id: 1,
			RowNum: 1,
			GroupName: '그룹명1',
			RowCrud: 'C',
			isNew: false,
		},
		{
			id: 2,
			RowNum: 2,
			GroupName: '그룹명1',
			RowCrud: 'C',
			isNew: false,
		},
	])
	const [selectionMstModel, setSelectionMstModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	const onMstRowClick = (e: GridRowModel) => {
		console.log(e)
		setSlvSearch({
			...slvSearch,
			ClientID: search.ClientID,
			MstID: e.row.MstID,
		})
		const request = {
			...slvSearch,
			ClientID: search.ClientID,
			MstID: e.row.MstID,
		}
		console.table(request)
	}

	const [groupName, setGroupName] = useState('')

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			ClientID: e.ClientID,
		})
		setClientDialogOpen(false)
		const request = {
			...search,
			ClientID: e.ClientID,
		}
		apiSMSGroupList(request).then((res) => {
			console.log(res)
			setRows(res)
			setClientList(res)
		})
	}

	interface EditToolbarProps {
		setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
		setRowModesModel: (
			newModel: (oldModel: GridRowModesModel) => GridRowModesModel
		) => void
	}

	function EditToolbar(props: EditToolbarProps) {
		const { setRows, setRowModesModel } = props

		const handleClick = () => {
			const id = Math.round(Math.random() * 100000000)
			setRows((oldRows) => [...oldRows, { id, GroupName: '', isNew: true }])
			setRowModesModel((oldModel) => ({
				...oldModel,
				[id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
			}))
		}

		return (
			<GridToolbarContainer>
				<Button color="primary">Add record</Button>
			</GridToolbarContainer>
		)
	}

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows(rows.filter((row) => row.id !== id))
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = rows.find((row) => row.id === id)
		if (editedRow!.isNew) {
			setRows(rows.filter((row) => row.id !== id))
		}
	}

	const [editRows, setEditRows] = useState<GridRowsProp>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	/**
	 * 그리드 체크박스
	 * @param rows
	 */
	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionModel((prevState) => [...rows])
		console.log(rows)
		if (rows.length > 0) {
			setDisabledSave(false)
			setDisabledDel(false)
		} else {
			setDisabledSave(true)
			setDisabledDel(true)
		}
	}

	const processRowUpdate = useCallback(
		async (newRow: GridRowModel) => {
			console.log(newRow)
			console.log('processRowUpdate')
			// setSnackbar({ children: '수정되었습니다.', severity: 'success' })
			return newRow
			// surveyCountModify(newRow).then(res => {
			//   callData()
			//   setSnackbar({ children: '수정되었습니다.', severity: 'success' })
			// })
			// Make the HTTP request to save in the backend
			// const response = await mutateRow(newRow)
			// setSnackbar({ children: 'User successfully saved', severity: 'success' })
			// return response
		},
		[]
		// [mutateRow],
	)

	const handleProcessRowUpdateError = useCallback((error: Error) => {
		console.log('handleProcessRowUpdateError', error)
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
		console.log('start', event, params)
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
		console.log('end', event, params)
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		// if (params.reason === GridCellEditStopReasons.cellFocusOut) {
		//   event.defaultMuiPrevented = true
		// }
		setEditRows((prevState) => {
			return [...prevState, { ...row }]
		})
	}

	const onStateChange = (params: any) => {
		// console.log(params)
		// console.log(params.selection.length)
		// console.log(params.editRows)
	}

	useEffect(() => {
		if (userInfo?.infoType === 'C') actionSearch()
	}, [])

	return (
		<>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Paper
				elevation={0}
				sx={{
					mt: 2,
				}}
			>
				<Box sx={{ height: 'calc(100vh - 320px)' }}>
					<DataGridPremium
						rows={rows}
						apiRef={mstRef}
						experimentalFeatures={{ newEditingApi: true }}
						rowModesModel={rowModesModel}
						onRowEditStart={handleRowEditStart}
						onRowEditStop={handleRowEditStop}
						onCellEditStop={onCellEditStop}
						onStateChange={onStateChange}
						processRowUpdate={processRowUpdate}
						onSelectionModelChange={selectChangeHandler}
						columns={[
							{
								field: 'id',
								headerName: 'ID',
								type: 'number',
								width: 80,
								editable: false,
								headerAlign: 'center',
							},
							{
								field: 'RowNum',
								headerName: '번호',
								type: 'number',
								width: 50,
								editable: false,
								headerAlign: 'center',
							},
							{
								field: 'GroupName',
								headerName: '그룹명',
								type: 'string',
								minWidth: 150,
								editable: true,
								headerAlign: 'center',
								renderCell: (params: GridRenderEditCellParams) => {
									return (
										<TextField
											name="GroupName"
											value={params.value ? params.value : ''}
											size="small"
											fullWidth
										/>
									)
								},
							},
							{
								field: 'RowCrud',
								headerName: 'CRUD',
								type: 'string',
								minWidth: 50,
								editable: false,
								headerAlign: 'center',
							},
							{
								field: 'update',
								headerName: '수정',
								type: 'string',
								minWidth: 40,
								width: 85,
								editable: false,
								headerAlign: 'center',
								renderCell: (params) => {
									return (
										<Button
											variant="contained"
											color="secondary"
											size="small"
											onClick={actionSave}
										>
											수정
										</Button>
									)
								},
							},
							{
								field: 'delete',
								headerName: '삭제',
								type: 'string',
								minWidth: 40,
								width: 85,
								editable: false,
								headerAlign: 'center',
								renderCell: (params) => {
									return (
										<Button
											variant="contained"
											color="warning"
											size="small"
											onClick={actionDel}
										>
											삭제
										</Button>
									)
								},
							},
						]}
						pageSize={gridMstPageSize}
						onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
						rowsPerPageOptions={[5, 10, 20, 50, 100]}
						pagination
						autoHeight={false}
						localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
						components={{
							Toolbar: EditToolbar,
							LoadingOverlay: LinearProgress,
						}}
						loading={gridMstLoading}
						// onRowClick={onMstRowClick}
						// slots={{
						// 	toolbar: EditToolbar,
						// }}
						// slotProps={{
						// 	toolbar: { setMstRows, setRowModesModel },
						// }}
						density={(env.desnse as GridDensity) || 'compact'}
						initialState={{
							columns: {
								columnVisibilityModel: {
									RowCrud: false,
									MstID: false,
								},
							},
						}}
						sx={{
							'& .MuiDataGrid-columnHeaderTitleContainer': {
								fontSize: '13px',
							},
							'& .MuiDataGrid-cell': {
								fontSize: '13px !important',
								borderRight:
									theme.palette.mode === 'dark'
										? '1px solid rgba(81, 81, 81, 1)'
										: '1px solid rgba(224, 224, 224, 1)',
							},
						}}
					/>
				</Box>
			</Paper>
		</>
	)
}

export default BlankPage
