import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { selectDisplayMode } from 'hooks/themeMode'
import { darkTheme, lightTheme } from 'constants/defaultValues'

import { styled } from '@mui/material/styles'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'

import { useNavigate } from 'react-router-dom'

import ErrorBg from '../../assets/img/unauth.png'
import { getCurrentUser, removeCurrentUser } from 'helpers/storage'
import { removeGlobalAlert } from 'hooks/globalError'
import { removeUserInfo, selectuserInfo } from 'hooks/userInfo'

const Wrapper = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: '0',
	left: '0',
	right: '0',
	bottom: '0',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	background: `url('${ErrorBg}') no-repeat center`,
}))

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	backgroundColor: theme.palette.mode === 'dark' ? 'rgb(0, 30, 60)' : '#aab4be',
	padding: '3rem',
	borderRadius: '1rem',
}))

const Unauthorized = () => {
	const navigate = useNavigate()
	const themeMode = useSelector(selectDisplayMode)

	const userInfo = useSelector(selectuserInfo)
	const user = getCurrentUser()
	const dispatch = useDispatch()
	return (
		<ThemeProvider theme={themeMode.isDarkMode ? darkTheme : lightTheme}>
			<CssBaseline />
			<Wrapper>
				<Container>
					<AnnouncementIcon
						color="info"
						sx={{ fontSize: '100px' }}
					/>
					<Typography
						variant="h3"
						color="text.secondary"
						style={{ marginBottom: '0.5rem' }}
					>
						Unauthorized
					</Typography>
					<Typography
						variant="h6"
						color="text.secondary"
						style={{ marginBottom: '2.5rem', marginTop: '0.5rem' }}
					>
						인증정보가 없습니다. 로그인 후 이용해주세요.
					</Typography>
					<Button
						startIcon={<ArrowBackIcon />}
						variant="contained"
						onClick={() => {
							removeCurrentUser()
							dispatch(removeGlobalAlert())
							dispatch(removeUserInfo())
							navigate('/')
						}}
					>
						돌아가기
					</Button>
				</Container>
			</Wrapper>
		</ThemeProvider>
	)
}

export default Unauthorized
