import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectuserInfo } from 'hooks/userInfo'
// import { useEffect } from 'react'

/**
 * 페이지 권한 체크
 * @param param0
 * @returns
 */
const RequireAuth = ({ allowedRoles }: any) => {
  const userInfo = useSelector(selectuserInfo)
  const location = useLocation()

  // useEffect(() => {
  //   console.log('-------------------------')
  //   console.log(userInfo)
  //   console.log('-------------------------')
  // }, [])

  return userInfo?.roles?.find((role: any) =>
    allowedRoles?.includes(role.roleId)
  ) ? (
    <Outlet />
  ) : userInfo?.roles ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default RequireAuth
