import { useState, useEffect } from 'react'

import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import {
	DataGridPremium,
	koKR,
	useGridApiRef,
	GridColumns,
	GridRowsProp,
	GridSelectionModel,
	GridRowModel,
	GridRenderCellParams,
	GridDensity,
} from '@mui/x-data-grid-premium'

import { FormikHelpers } from 'formik'

import { BoardRequestType } from 'constants/types'
import BoardWrite from 'components/BoardWrite'
import BoardView from 'components/BoardVIew'
import WysiwygEditor from '../../components/WysiwygEditor'
import { removeEditorHtml, selectEditorHtml } from 'hooks/editorHtml'
import { useDispatch, useSelector } from 'react-redux'
import {
	boardList,
	boardSave,
	boardView,
	fileMasterId,
	attachFileList,
	boardDelete,
} from 'api/api'

import { dateTimeFormatter } from 'util/formatUtil'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'
import { alertModal, confirmAlert } from 'util/util'
import { getSettings } from 'helpers/storage'
import { selectuserInfo } from 'hooks/userInfo'
import { useNavigate } from 'react-router-dom'

const RenderData = (props: GridRenderCellParams<string>) => {
	const { value } = props

	return <strong>{value}</strong>
}

type Nullable<T> = T | null

type BoardType = {
	category: string
	boardId: Nullable<string>
	displayTo: Nullable<string>
	title: string
	content: string
	startDate: string
	endDate: string
	attachFileId: string
	fileDest: string
	createdUser: string
	createdAt: string
	updatedUser: string
	updatedAt: string
	deleteFlag: boolean
	isPopup: string
}

const Notice = () => {
	/**
	 * 그리드 컬럼
	 */
	const columns: GridColumns = [
		{
			field: 'rowNum',
			headerName: 'No',
			type: 'number',
			width: 100,
		},
		{
			field: 'boardId',
			headerName: '순번',
			type: 'string',
			width: 150,
			hide: true,
			renderCell: RenderData,
		},
		{
			field: 'title',
			headerName: '제목',
			type: 'string',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { value, row } = params
				return (
					<Button
						variant="text"
						sx={{ textDecoration: 'underline' }}
						onClick={() => {
							boardView(row?.boardId, currentUser?.infoType).then((result) => {
								result.isPopup = result.popup ? 'P' : 'A'
								console.log(result)
								setDataSet({ ...result })
								searchAttachFile(result.attachFileId)
								setViewOpen(true)
							})
						}}
					>
						{value}
					</Button>
				)
			},
		},
		{ field: 'createdUser', headerName: '작성자', type: 'string', width: 150 },
		{
			field: 'createdAt',
			headerName: '작성일',
			type: 'string',
			width: 200,
			valueFormatter: ({ value }: { value: string }) =>
				value && dateTimeFormatter(value),
		},
	]

	const currentUser = useSelector(selectuserInfo)

	const env = getSettings()
	const navigate = useNavigate()

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [selectedRow, setSelectedRow] = useState<GridRowModel>({})

	const actionAdd = () => {
		addNotice()
	}
	const actionSave = () => {}
	const actionDel = () => {
		if (selectionModel.length === 1) {
			const boardId = selectionModel[0] as string
			confirmAlert(
				'게시물을 삭제하시겠습니까?',
				() => {
					boardDelete(boardId, selectedRow?.row?.attachFileId).then(() => {
						getBoardList()
						setDisabledDel(true)
					})
				},
				() => {}
			)
		}
	}
	const actionSearch = () => {
		getBoardList()
	}
	const actionReset = () => {
		setSearch({
			searchKeyword: '',
			searchDisplayTo: 'A',
		})
		// boardList('', currentUser?.infoType, 'A').then((result) => {
		// 	console.log(result)
		// 	setContent(result)
		// 	setDisabledDel(true)
		// })
	}

	const [search, setSearch] = useState({
		searchKeyword: '',
		searchDisplayTo: 'A',
	})

	const dispatch = useDispatch()
	const htmlContent = useSelector(selectEditorHtml)

	const [dialogOpen, setDialogOpen] = useState(false)
	const [viewOpen, setViewOpen] = useState(false)

	const [searchKeyword, setSearchKeyword] = useState('')

	// ui
	const [disableDelete, setDisableDelete] = useState(true)

	// api response
	const [content, setContent] = useState<GridRowsProp>([])
	const [last, setLast] = useState(false)
	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)
	const [totalElements, setTotalElements] = useState(0)
	const [totalpages, setTotalPages] = useState(0)

	// grid
	const apiRef = useGridApiRef()
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const [dataSet, setDataSet] = useState<BoardRequestType>({
		boardId: '',
		category: '',
		title: '',
		content: '',
		startDate: '',
		endDate: '',
		attachFileId: '',
		fileDest: '',
		createdUser: '',
		createdAt: '',
		updatedUser: '',
		updatedAt: '',
		displayTo: '',
		deleteFlag: false,
		isPopup: 'A',
	})
	const [fileList, setFileList] = useState([])

	const formSubmit = (values: BoardType, helpers: FormikHelpers<BoardType>) => {
		console.log(helpers)

		const boardRequest = {
			boardId: values.boardId,
			category: values.category,
			title: values.title,
			content: htmlContent,
			startDate: values.startDate,
			endDate: values.endDate,
			attachFileId: dataSet.attachFileId,
			fileDest: values.fileDest,
			createdUser: '',
			createdAt: '',
			updatedUser: '',
			updatedAt: '',
			displayTo: values.displayTo,
			deleteFlag: false,
			isPopup: values.isPopup,
		}

		console.log(boardRequest)

		confirmAlert(
			'저장하시겠습니까?',
			() => {
				boardSave(boardRequest).then((res) => {
					if (res.rs_MSG) {
						// 데이터 세트 초기화
						dialogClose()
						getBoardList()
						dispatch(removeEditorHtml())
						setDialogOpen(false)
					}
				})
			},
			() => {}
		)

		// 저장하고 나면 dispatch(removeEditorHtml()) 꼭 필수!!
	}

	// 게시물 목록 조회
	const getBoardList = () => {
		console.log(search)
		boardList(
			search.searchKeyword,
			currentUser?.infoType,
			search.searchDisplayTo === 'A' ? '' : search.searchDisplayTo
		).then((result) => {
			console.log(result)
			setContent(result)
			setDisabledDel(true)
		})
	}

	const dialogClose = () => {
		setDialogOpen(false)
		setViewOpen(false)
		setDataSet({
			boardId: '',
			category: '',
			title: '',
			content: '',
			startDate: '',
			endDate: '',
			attachFileId: '',
			fileDest: '',
			createdUser: '',
			createdAt: '',
			updatedUser: '',
			updatedAt: '',
			displayTo: '',
			deleteFlag: false,
			isPopup: 'A',
		})
		dispatch(removeEditorHtml())
	}

	const addNotice = () => {
		fileMasterId().then((res) => {
			setDataSet({
				...dataSet,
				attachFileId: res.attachFileId,
			})
		})
		setDialogOpen(true)
	}

	// grid
	const onRowClick = (e: GridRowModel) => {
		console.log(e)
		if (e.id) {
			setDisabledDel(false)
			setSelectedRow(e)
		} else {
			setDisabledDel(true)
			setSelectedRow({})
		}
	}

	/**
	 * 첨부파일 조회
	 * @param attachFileId
	 */
	const searchAttachFile = (attachFileId: string) => {
		attachFileList(attachFileId).then((res) => {
			console.log(res)
			setFileList(res)
		})
	}

	const boardEdit = () => {
		setViewOpen(false)
		setDialogOpen(true)
	}

	const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault()
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	useEffect(() => {
		if (currentUser?.infoType !== 'S' && currentUser?.infoType !== 'W') {
			alertModal('권한이 없습니다.', 'error', () => {
				navigate('/admin/test/result')
			})
		}
		getBoardList()
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			{(currentUser?.infoType === 'S' || currentUser?.infoType === 'W') && (
				<>
					<Stack
						sx={{
							marginBottom: '1rem',
							position: 'sticky',
							top: 120,
							left: 0,
							right: 0,
							zIndex: 1,
							'@media (max-width: 1199px)': {
								position: 'relative',
								paddingTop: '55px',
								top: '0px',
							},
						}}
					>
						<PageBg>
							<Box
								component="form"
								noValidate
								autoComplete="off"
								onSubmit={onSearchSubmit}
								onKeyUp={onKeyUp}
							>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										xs={6}
										sm={3}
									>
										<FormControl fullWidth>
											<InputLabel id="date-range-select-label">조회 조건</InputLabel>
											<Select
												labelId="date-range-select-label"
												id="date-range-simple-select"
												value={search.searchDisplayTo}
												label="조회 조건"
												onChange={(e) => {
													setSearch({
														...search,
														searchDisplayTo: e.target.value,
													})
												}}
												size={'small'}
											>
												<MenuItem value={'A'}>전체</MenuItem>
												<MenuItem value={'C'}>거래처</MenuItem>
												<MenuItem value={'S'}>내부사용자</MenuItem>
												<MenuItem value={'W'}>시스템관리자</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={6}
										sm={3}
									>
										<TextField
											label="제목"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											value={search.searchKeyword}
											onChange={(e) => {
												setSearch({
													...search,
													searchKeyword: e.target.value,
												})
											}}
										/>
									</Grid>
								</Grid>
							</Box>
						</PageBg>
					</Stack>
					<Paper
						elevation={0}
						sx={{ width: '100%', overflow: 'hidden', p: 2 }}
					>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								lg={12}
								xs={12}
							>
								<div style={{ height: 'auto', width: '100%', overflow: 'auto' }}>
									<DataGridPremium
										apiRef={apiRef}
										rows={content}
										columns={columns}
										getRowId={(row) => row.boardId}
										pageSize={size}
										onPageSizeChange={(newPageSize) => setSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100, 500, 1000]}
										pagination
										autoHeight={true}
										onRowClick={onRowClick}
										selectionModel={selectionModel}
										onSelectionModelChange={(newSelectionModel) => {
											setSelectionModel(newSelectionModel)
										}}
										localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
										density={(env.desnse as GridDensity) || 'standard'}
									/>
								</div>
							</Grid>
						</Grid>
					</Paper>
					<BoardWrite
						dialogOpen={dialogOpen}
						dialogClose={dialogClose}
						dataSet={dataSet}
						fileList={fileList}
						formSubmit={formSubmit}
						WysiwygEditor={WysiwygEditor}
						attachFileId={dataSet.attachFileId}
					/>
					<BoardView
						dialogOpen={viewOpen}
						dialogClose={dialogClose}
						dataSet={dataSet}
						fileList={fileList}
						attachFileId={dataSet.attachFileId}
						searchAttachFile={searchAttachFile}
						boardEdit={boardEdit}
						isDashboard={false}
					/>
				</>
			)}
		</>
	)
}

export default Notice
