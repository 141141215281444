import { useEffect, useState } from 'react'

import {
	Stack,
	Grid,
	Box,
	Button,
	TextField,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	IconButton,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridRenderEditCellParams,
	GridCellEditCommitParams,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { alertModal, confirmAlert, hexToRgba, timerAlert } from 'util/util'

import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { useSelector } from 'react-redux'
import {
	testItemsList,
	clientListBySystemUserInfo,
	apiPostItemList,
	apiPostItemSave,
	apiPostItemDelete,
} from '../../api/api'

import { getSettings } from 'helpers/storage'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'

import SaveAsTwoToneIcon from '@mui/icons-material/SaveAsTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { InfoClientSearchForInternal } from 'constants/types'

const locales = ['ko'] as const

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const RowControlButtons = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: '-1.5rem',
	width: '3rem',
	bottom: 0,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}))

const PrintCodeManage = () => {
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)
	const currentUser = useSelector(selectuserInfo)

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const env = getSettings()

	const gridRef = useGridApiRef()
	const grid1Ref = useGridApiRef()
	const grid2Ref = useGridApiRef()

	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)

	const [grid1SelectionModel, setGrid1SelectionModel] =
		useState<GridSelectionModel>([])
	const [grid1Rows, setGrid1Rows] = useState<GridRowModel[]>([])
	const [grid1Loading, setGrid1Loading] = useState(false)
	const [grid1PageSize, setGrid1PageSize] = useState(env.row ? env.row : 100)

	const [grid2SelectionModel, setGrid2SelectionModel] =
		useState<GridSelectionModel>([])
	const [grid2Rows, setGrid2Rows] = useState<GridRowModel[]>([])
	const [grid2Loading, setGrid2Loading] = useState(false)
	const [grid2PageSize, setGrid2PageSize] = useState(env.row ? env.row : 100)

	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')
	// 사용자 정보

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(false)

	const [search, setSearch] = useState({
		searchKeyword: '',
		displayName: '',
		insuranceCode: '',
		testCode: '',
		page: 0,
		pageSize: 0,
	})

	const [clientSearch, setClientSearch] = useState<InfoClientSearchForInternal>({
		ClientID: '',
		ClientName: '',
		BranchCode: '',
	})

	const [selectedClientID, setSelectedClientID] = useState<number>(0)

	const actionReset = () => {
		setSearch({
			searchKeyword: '',
			displayName: '',
			insuranceCode: '',
			testCode: '',
			page: 0,
			pageSize: 0,
		})
		setClientSearch({
			ClientID: '',
			ClientName: '',
			BranchCode: '',
		})
		setDisabledSave(true)
		setDisabledDel(true)
		setGrid2Rows([])
		setGrid1SelectionModel([])
		setGrid2SelectionModel([])
		grid1Ref.current?.setPage(0)
		grid2Ref.current?.setPage(0)
		getClientList()
	}

	const actionSearch = () => {
		// setGrid1Loading(true)
		// testItemsList(search)
		// 	.then((res) => {
		// 		setGrid1Rows(res.items)
		// 	})
		// 	.finally(() => {
		// 		setGrid1Loading(false)
		// 	})
	}

	const actionSave = () => {
		console.log(grid2SelectionModel.length)

		if (grid2SelectionModel.length > 0) {
			const request = {
				clientID: selectedClientID,
				list: grid2Rows,
			}
			console.log(request)
			confirmAlert(
				'저장하시겠습니까?',
				() => {
					apiPostItemSave(request).then((res) =>
						alertModal('저장되었습니다.', 'success', () => {})
					)
				},
				() => {}
			)
		} else {
			alertModal('저장할 항목을 선택하세요.', 'warning', () => {})
		}
	}

	const actionDel = () => {
		if (grid2SelectionModel.length > 0) {
			const rows = grid2SelectionModel.map((item) => {
				const row = grid2Rows.find((row) => row.rowKey === item)
				return {
					...row,
				}
			})

			const request = {
				clientID: selectedClientID,
				list: rows,
			}
			if (request.clientID && request.list.length > 0) {
				confirmAlert(
					'선택한 항목을 삭제하시겠습니까?',
					() => {
						apiPostItemDelete(request).then((res) => {
							timerAlert('삭제되었습니다.', 'success', () => {})
							apiPostItemList({ clientID: selectedClientID })
								.then((res) => {
									console.log(res)
									setGrid2Rows(res)
								})
								.finally(() => {
									setGrid1Loading(false)
								})
						})
					},
					() => {}
				)
			} else {
				alertModal('삭제할 항목을 선택하세요.', 'warning', () => {})
			}
		} else {
			alertModal('삭제할 항목을 선택하세요.', 'warning', () => {})
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const getClientList = () => {
		clientListBySystemUserInfo(clientSearch).then((res) => {
			setGridRows(res)
		})
		testItemsList(search)
			.then((res) => {
				setGrid1Rows(res.items)
			})
			.finally(() => {
				setGrid1Loading(false)
			})
	}

	const selectClientChangeHandler = (rows: GridSelectionModel) => {
		setGridSelectionModel((prevState) => [...rows])
	}

	const selectChangeHandler = (rows: GridSelectionModel) => {
		setGrid1SelectionModel((prevState) => [...rows])
	}

	const selectChangeHandler2 = (rows: GridSelectionModel) => {
		console.log(rows)
		setGrid2SelectionModel((prevState) => [...rows])
	}

	const handleCellEditCommit = (params: GridCellEditCommitParams) => {
		const { id, field, value } = params

		console.log(id, field, value)

		setGrid2Rows((prevRows) =>
			prevRows.map((row) => {
				if (row.rowKey === id) {
					return {
						...row,
						[field]: value,
					}
				}
				return row
			})
		)
	}

	const onRowClick = (param: GridRowModel) => {
		console.log(param.row)
		if (param.row) {
			setGrid2Rows([])
			setGrid1SelectionModel([])
			setGrid2SelectionModel([])
			grid1Ref.current?.setPage(0)
			grid2Ref.current?.setPage(0)
			setTimeout(() => {
				setSelectedClientID(param.row.ClientID)
				console.log(param.row.ClientID)
				apiPostItemList({ clientID: param.row.ClientID })
					.then((res) => {
						console.log(res)
						setGrid2Rows(res)
					})
					.finally(() => {
						setGrid1Loading(false)
					})
			}, 100)
		} else {
			alertModal('거래처를 선택하세요.', 'warning', () => {})
		}
	}

	// 검사코드 목록 그리드를 결과지에 사용할 그리드로 복사
	const actionSelectionAdd = () => {
		if (grid1SelectionModel.length > 0) {
			// get grid1SelectionModel rows
			const rows = grid1SelectionModel.map((item) => {
				const row = grid1Rows.find((row) => row.rowKey === item)
				return {
					...row,
				}
			})

			// set grid2Rows with grid1SelectionModel rows is not exist
			setGrid2Rows((prevState) => {
				return [
					...prevState,
					...rows.filter((row) => {
						return !prevState.find((item) => {
							console.log(item.rowKey, row.rowKey)
							return item.rowKey === row.rowKey
						})
					}),
				]
			})
		}
	}

	// 결과지에 사용할 그리드에서 선택된 항목 삭제
	const actionSelectionRemove = () => {
		if (grid2SelectionModel.length > 0) {
			setGrid2Rows((prevState) => {
				return prevState.filter((row) => !grid2SelectionModel.includes(row.rowKey))
			})
		}
	}

	const SearchOnlyGridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarQuickFilter sx={{ flex: 1 }} />
			</GridToolbarContainer>
		)
	}

	useEffect(() => {
		getClientList()
	}, [])

	return (
		<>
			<Stack sx={{ marginBottom: '1rem' }}>
				<PageWrap>
					<PageBg2>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								우편 결과지 출력관리
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								<span>
									<Button
										color="primary"
										size="small"
										variant="contained"
										startIcon={isMdUp && <SaveAsTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										onClick={actionSave}
										disabled={grid2SelectionModel.length > 0 ? false : true}
									>
										저장
									</Button>
								</span>
								<span>
									<Button
										color="warning"
										size="small"
										variant="contained"
										startIcon={isMdUp && <DeleteForeverTwoToneIcon />}
										sx={{
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										onClick={actionDel}
										disabled={grid2SelectionModel.length > 0 ? false : true}
									>
										삭제
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				{/* <Stack
					sx={{
						marginBottom: '1rem',
					}}
				>
					<PageBg>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={12}
									sm={3}
								>
									<TextField
										label="전체"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.searchKeyword}
										onChange={(e) => {
											setSearch({
												...search,
												searchKeyword: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={3}
								>
									<TextField
										label="검사명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.displayName}
										onChange={(e) => {
											setSearch({
												...search,
												displayName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={3}
								>
									<TextField
										label="보험코드"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.insuranceCode}
										onChange={(e) => {
											setSearch({
												...search,
												insuranceCode: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={3}
								>
									<TextField
										label="검사코드"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.testCode}
										onChange={(e) => {
											setSearch({
												...search,
												testCode: e.target.value,
											})
										}}
									/>
								</Grid>
							</Grid>
						</Box>
					</PageBg>
				</Stack> */}
				<PageBg2>
					<Grid container>
						<Grid
							item
							md={3}
							sx={{ pr: isMdUp ? 3 : 0, position: 'relative' }}
						>
							<div style={{ height: 'calc(100vh - 200px)' }}>
								<DataGridPremium
									apiRef={gridRef}
									rows={gridRows}
									getRowId={(row) => row.ClientID}
									columns={[
										{
											field: 'rowNum',
											headerName: '번호',
											type: 'number',
											width: 100,
											editable: false,
											hide: true,
										},
										{
											field: 'ClientID',
											headerName: '거래처코드',
											type: 'number',
											width: 80,
											editable: false,
										},
										{
											field: 'ClientName',
											headerName: '거래처명',
											type: 'string',
											width: 250,
											editable: false,
											flex: 1,
										},
									]}
									pagination
									autoHeight={false}
									onRowClick={onRowClick}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										Toolbar: SearchOnlyGridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: false },
											excelOptions: { disableToolbarButton: false },
											printOptions: { disableToolbarButton: false },
											showQuickFilter: true,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									loading={gridLoading}
									selectionModel={gridSelectionModel}
									disableMultipleSelection
									onSelectionModelChange={selectClientChangeHandler}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												rowNum: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</div>
						</Grid>
						<Grid
							item
							md={5}
							sx={{ pr: isMdUp ? 3 : 0, position: 'relative' }}
						>
							<div style={{ height: 'calc(100vh - 200px)' }}>
								<DataGridPremium
									apiRef={grid1Ref}
									rows={grid1Rows}
									getRowId={(row) => row.rowKey}
									columns={[
										{
											field: 'rowNum',
											headerName: 'No',
											type: 'number',
											width: 55,
										},
										{
											field: 'rowKey',
											headerName: 'rowKey',
											type: 'string',
											width: 55,
										},
										{
											field: 'TestSpecimenID',
											headerName: '검사검체관계ID',
											type: 'number',
											width: 75,
											editable: false,
										},
										{
											field: 'TestCode',
											headerName: '검사코드',
											type: 'string',
											width: 75,
											editable: false,
										},
										{
											field: 'DisplayName',
											headerName: '검사명',
											type: 'string',
											width: 350,
											minWidth: 300,
											editable: false,
										},
										{
											field: 'SpecimenName',
											headerName: '검체명',
											type: 'string',
											width: 150,
											minWidth: 150,
											editable: false,
										},
										{
											field: 'InsuranceCode',
											headerName: '보험코드',
											type: 'string',
											width: 100,
											editable: false,
										},
									]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: true },
											excelOptions: { disableToolbarButton: true },
											printOptions: { disableToolbarButton: true },
											showQuickFilter: true,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									loading={grid1Loading}
									checkboxSelection
									selectionModel={grid1SelectionModel}
									onSelectionModelChange={selectChangeHandler}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												rowNum: false,
												TestSpecimenID: false,
												rowKey: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</div>
							<RowControlButtons>
								<IconButton
									size="large"
									color="primary"
									onClick={actionSelectionAdd}
								>
									<ArrowRightIcon fontSize="inherit" />
								</IconButton>
								<IconButton
									size="large"
									color="primary"
									onClick={actionSelectionRemove}
								>
									<ArrowLeftIcon fontSize="inherit" />
								</IconButton>
							</RowControlButtons>
						</Grid>
						<Grid
							item
							md={4}
							sx={{ pl: isMdUp ? 3 : 0 }}
						>
							<div style={{ height: 'calc(100vh - 200px)' }}>
								<DataGridPremium
									apiRef={grid2Ref}
									rows={grid2Rows}
									getRowId={(row) => row.rowKey}
									columns={[
										{
											field: 'rowNum',
											headerName: 'No',
											type: 'number',
											width: 55,
										},
										{
											field: 'rowKey',
											headerName: 'rowKey',
											type: 'string',
											width: 55,
										},
										{
											field: 'ClientID',
											headerName: '거래처코드',
											type: 'number',
											width: 55,
										},
										{
											field: 'TestSpecimenID',
											headerName: '검사검체관계ID',
											type: 'number',
											width: 75,
											editable: false,
										},
										{
											field: 'TestCode',
											headerName: '검사코드',
											type: 'string',
											width: 75,
											editable: false,
										},
										{
											field: 'DisplayName',
											headerName: '검사명',
											type: 'string',
											width: 350,
											minWidth: 300,
											editable: true,
										},
										{
											field: 'SpecimenName',
											headerName: '검체명',
											type: 'string',
											width: 150,
											minWidth: 150,
											editable: false,
										},
										// {
										// 	field: 'UseFlag',
										// 	headerName: '사용여부',
										// 	type: 'boolean',
										// 	width: 80,
										// 	editable: true,
										// },
									]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: true },
											excelOptions: { disableToolbarButton: true },
											printOptions: { disableToolbarButton: true },
											showQuickFilter: true,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									loading={grid2Loading}
									checkboxSelection
									disableSelectionOnClick
									selectionModel={grid2SelectionModel}
									onSelectionModelChange={selectChangeHandler2}
									density={(env.desnse as GridDensity) || 'compact'}
									onCellEditCommit={handleCellEditCommit}
									initialState={{
										columns: {
											columnVisibilityModel: {
												rowNum: false,
												TestSpecimenID: false,
												rowKey: false,
												ClientID: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</div>
						</Grid>
					</Grid>
				</PageBg2>
			</Stack>
		</>
	)
}

export default PrintCodeManage
