import { createSlice } from '@reduxjs/toolkit'

export const gridColumnVisibleV2Slice = createSlice({
	name: 'gridColumnVisibleV2',
	initialState: {
		info: {},
	},
	reducers: {
		setGridColumnVisibleV2: (state, actions) => {
			console.log(actions.payload)
			state.info = actions.payload
		},
		removeGridColumnVisibleV2: (state) => {
			state.info = {}
		},
	},
})

export const { setGridColumnVisibleV2, removeGridColumnVisibleV2 } =
	gridColumnVisibleV2Slice.actions
export default gridColumnVisibleV2Slice.reducer
export const selectGridColumnVisibleInfoV2 = (state: any) =>
	state.gridColumnVisibleV2
