import { useEffect, useState, forwardRef } from 'react'
import { clientListBySystemUserInfo } from 'api/api'
import {
	Grid,
	Box,
	Button,
	TextField,
	Typography,
	Dialog,
	DialogContent,
	useMediaQuery,
	useTheme,
	styled,
} from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridApi,
	useGridApiRef,
	GridDensity,
} from '@mui/x-data-grid-premium'
import { valuesInIterator } from 'util/objectUtil'
import { getSettings } from 'helpers/storage'
import { InfoClientSearchForInternal } from 'constants/types'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import BookmarkAddedTwoToneIcon from '@mui/icons-material/BookmarkAddedTwoTone'
import { selectuserInfo } from 'hooks/userInfo'
import { useSelector } from 'react-redux'
import { testItemsList } from 'api/api'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	)
})

const columns: GridColDef[] = [
	{
		field: 'rowNum',
		headerName: 'No',
		type: 'number',
		width: 55,
	},
	{
		field: 'TestCode',
		headerName: '검사코드',
		type: 'string',
		width: 75,
		editable: false,
	},
	{
		field: 'InsuranceCode',
		headerName: '보험코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'DisplayName',
		headerName: '검사명',
		type: 'string',
		width: 500,
		minWidth: 300,
		editable: false,
	},
]

const SearchConditionBox = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	flex: '0 1 auto',
	alignItems: 'center',
	'.MuiTextField-root': {
		width: '100%',
	},
	'.searchField': {
		flex: '1 0 auto',
	},
	'.wrap': {
		display: 'flex',
		marginLeft: '1rem',
		gap: '10px',
	},
}))

const InsuCodeSearchPopup = ({ dialogOpen, dialogClose, popupSubmit }: any) => {
	const env = getSettings()
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const [searchCondition, setSearchCondition] = useState({
		displayName: '',
		insuranceCode: '',
		testCode: '',
		page: 0,
		pageSize: 0,
		searchKeyword: '',
	})
	const [searchList, setSearchList] = useState<any[]>([])

	const popupSearchAction = () => {
		console.log(searchCondition)
		testItemsList(searchCondition).then((res) => {
			console.log(res)
			setSearchList(res?.items)
			setTimeout(() => {
				setSelectionModel([0])
			}, 100)
		})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) popupSearchAction()
	}

	// grid
	const apiRef = useGridApiRef()
	useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridPageSize, setGridPageSize] = useState(10)
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const formSubmit = () => {
		const row = valuesInIterator(apiRef.current?.getSelectedRows())
		if (row.length > 0) popupSubmit(row[0])
	}

	useEffect(() => {
		// popupSearchAction()
	}, [])

	return (
		<Dialog
			fullScreen={isMdUp ? false : true}
			open={dialogOpen}
			onClose={dialogClose}
			TransitionComponent={Transition}
			maxWidth="lg"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="div"
					>
						보험코드 조회
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={dialogClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Box
					component="div"
					onKeyUp={onKeyUp}
				>
					<SearchConditionBox>
						<Grid
							container
							spacing={1}
							sx={{ mt: 1 }}
						>
							<Grid
								item
								sm={4}
								xs={12}
							>
								<TextField
									label="보험코드"
									variant="outlined"
									value={searchCondition.insuranceCode}
									size="small"
									onChange={(e) => {
										setSearchCondition({
											...searchCondition,
											insuranceCode: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								sm={4}
								xs={12}
							>
								<TextField
									label="검사명"
									variant="outlined"
									value={searchCondition.displayName}
									size="small"
									// autoFocus
									onChange={(e) => {
										setSearchCondition({
											...searchCondition,
											displayName: e.target.value || '',
										})
									}}
								/>
							</Grid>
							<Grid
								item
								sm={4}
								xs={12}
							>
								<Button
									color="success"
									variant="contained"
									onClick={popupSearchAction}
									startIcon={<ContentPasteSearchTwoToneIcon />}
									sx={{ mr: 1 }}
								>
									검색
								</Button>
								<Button
									color="info"
									variant="contained"
									onClick={formSubmit}
									startIcon={<BookmarkAddedTwoToneIcon />}
									disabled={selectionModel.length === 0}
								>
									선택
								</Button>
							</Grid>
						</Grid>
					</SearchConditionBox>
				</Box>
				<Grid
					container
					spacing={1}
					sx={{ mt: 1 }}
				>
					<Grid
						item
						xs={12}
					>
						<div style={{ height: 400, width: '100%' }}>
							<DataGridPremium
								apiRef={apiRef}
								rows={searchList}
								columns={columns}
								getRowId={(row) => row.rowNum}
								pageSize={env.row ? env.row : 100}
								onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100]}
								pagination
								selectionModel={selectionModel}
								onSelectionModelChange={(newSelectionModel) => {
									setSelectionModel(newSelectionModel)
								}}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								density={(env.desnse as GridDensity) || 'standard'}
								onRowDoubleClick={formSubmit}
							/>
						</div>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default InsuCodeSearchPopup
