import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	LinearProgress,
	AppBar,
	IconButton,
	Toolbar,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-premium'
import { useTheme } from '@mui/material/styles'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { getSettings } from 'helpers/storage'

import FileUploaderComponent from 'components/FileUploaderComponent'
import CloseIcon from '@mui/icons-material/Close'
import { alertModal, confirmAlert } from 'util/util'
import { valuesInIterator } from 'util/objectUtil'
import {
	fileMasterId,
	apiDocumentList,
	apiDocumentSave,
	apiDocumentUpdate,
	apiDocumentDelete,
	fileDelete,
	fileDownloadByFileName,
} from 'api/api'

const Docs = () => {
	const theme = useTheme()

	const env = getSettings()

	// grid state
	const gridRef = useGridApiRef()
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const actionAdd = () => {
		let existZeroId = false
		gridRows.forEach((row) => {
			if (row.id === 0) existZeroId = true
		})
		if (existZeroId) {
			alertModal('신규 행을 저장해주세요.', 'error', () => {})
			return
		}

		const newRow = {
			id: 0,
			NoteName: '',
			attachFileId: '',
		}
		setGridRows([newRow, ...gridRows])
		setSelectionModel([0])
		setDisabledDel(false)
		setDisabledSave(false)
	}
	const actionSave = () => {
		setDisabledSave(true)
		const valuesArrays = valuesInIterator(gridRef.current.getSelectedRows())
		if (valuesArrays.length === 0) {
			alertModal('저장할 행을 선택하세요.', 'error', () => {})
			setDisabledSave(false)
			return
		}
		const request = {
			...valuesArrays[0],
		}
		// request.NoteName remove space
		request.NoteName = request.NoteName.replace(/\s/g, '')
		console.log(request)
		confirmAlert(
			'저장 하시겠습니까?',
			() => {
				if (request.id === 0) {
					fileMasterId()
						.then((res) => {
							console.log(res)
							const { attachFileId } = res
							request.attachFileId = attachFileId
						})
						.then(() => {
							console.log(request)
							if (request.attachFileId) {
								// insert
								apiDocumentSave(request).then((res) => {
									console.log(res)
									actionSearch()
								})
							} else {
								alertModal('첨부파일 ID가 없습니다.', 'error', () => {})
								return
							}
						})
						.finally(() => {
							setDisabledSave(false)
						})
				} else {
					// update
					apiDocumentUpdate(request)
						.then((res) => {
							console.log(res)
							if (res.RS_MESSAGE) {
								alertModal(res.RS_MESSAGE, 'success', () => {
									actionSearch()
								})
							}
						})
						.catch((err) => {
							setDisabledSave(false)
						})
				}
			},
			() => {}
		)
	}
	const actionDel = () => {
		setDisabledDel(true)
		if (selectionModel.length === 1) {
			const dataRow = valuesInIterator(gridRef.current?.getSelectedRows())
			const id = dataRow[0].id
			if (id > 0) {
				confirmAlert(
					'삭제하시겠습니까? 첨부파일도 삭제됩니다.',
					() => {
						const request = {
							id: dataRow[0].id,
							attachFileId: dataRow[0].attachFileId,
							fileId: dataRow[0].fileId,
						}
						apiDocumentDelete(request)
							.then((res) => {
								if (res.RS_MESSAGE) {
									alertModal(res.RS_MESSAGE, 'success', () => {
										actionSearch()
										setSelectionModel([])
									})
								}
							})
							.catch((err) => {
								setDisabledDel(false)
							})
					},
					() => {}
				)
			} else {
				// grid delete row
				const dataRow = valuesInIterator(gridRef.current?.getSelectedRows())
				const id = dataRow[0].id
				const newRows = gridRows.filter((row) => row.id !== id)
				setGridRows(newRows)
				setDisabledSave(true)
				setDisabledDel(true)
				setSelectionModel([])
			}
		}
	}
	const actionSearch = () => {
		setGridLoading(true)
		apiDocumentList(search)
			.then((res) => {
				console.log(res)
				setGridRows(res)
			})
			.finally(() => {
				setGridLoading(false)
			})
	}
	const actionReset = () => {
		setSearch({
			NoteName: '',
			fileName: '',
		})
	}

	// file upload state
	const [fileDest] = useState('docs')
	const [attachFileId, setAttachFileId] = useState('')
	const [fileType] = useState({
		'image/*': ['.gif', '.jpg', '.jpeg', '.png'],
	})
	const [fileUploadOpen, setFileUploadOpen] = useState(false)

	const multipleFileOnClose = () => {
		setFileUploadOpen(false)
		setAttachFileId('')
		actionSearch()
	}

	const attachFileButtonOnClick = (attachFileId: string) => {
		console.log(attachFileId)
		setAttachFileId(attachFileId)
		setFileUploadOpen(true)
	}

	const attachFileDelete = (row: GridValidRowModel) => {
		console.log(row)
		confirmAlert(
			'첨부파일을 삭제하시겠습니까?',
			() => {
				fileDelete(row.attachFileId, row.fileId).then((res) => {
					console.log(res)
					actionSearch()
				})
			},
			() => {}
		)
	}

	// grid row click event
	const onRowClick = (e: GridRowModel) => {
		console.log(e)
		if (e.id) {
			setDisabledDel(false)
			setDisabledSave(false)
		}
	}

	// 조회조건
	const [search, setSearch] = useState({
		NoteName: '',
		fileName: '',
	})

	const onKeyUp = (e: any) => {
		e.preventDefault()
		if (e.keyCode === 13) actionSearch()
	}

	useEffect(() => {
		actionSearch()
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<Dialog
				open={fileUploadOpen}
				fullScreen
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="div"
						>
							다중 파일 업로드
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							onClick={multipleFileOnClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogTitle></DialogTitle>
				<DialogContent>
					<FileUploaderComponent
						fileDest={fileDest}
						attachFileId={attachFileId}
						fileType={fileType}
						multiple={false}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						onClick={multipleFileOnClose}
					>
						확인
					</Button>
				</DialogActions>
			</Dialog>
			<Stack
				sx={{
					marginBottom: '1rem',
					position: 'sticky',
					top: 120,
					left: 0,
					right: 0,
					zIndex: 1,
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '55px',
						top: '0px',
					},
				}}
			>
				<PageBg>
					<Box
						component="div"
						onKeyUp={onKeyUp}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<TextField
									label="서류명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.NoteName}
									onChange={(e) => {
										setSearch({
											...search,
											NoteName: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<TextField
									label="파일명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.fileName}
									onChange={(e) => {
										setSearch({
											...search,
											fileName: e.target.value,
										})
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</PageBg>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden' }}
			>
				<Box sx={{ p: 2 }}>
					<Box
						sx={{
							height: 'auto',
							width: '100%',
							overflow: 'auto',
							marginTop: '1rem',
							'@media (max-width: 1199px)': {
								height: '400px',
							},
						}}
					>
						<DataGridPremium
							apiRef={gridRef}
							rows={gridRows}
							getRowId={(row) => row.id}
							pageSize={gridPageSize}
							onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={true}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={gridLoading}
							selectionModel={selectionModel}
							onSelectionModelChange={(newSelection) => {
								setSelectionModel(newSelection)
							}}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							getRowHeight={() => 'auto'}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
							columns={[
								{
									field: 'id',
									headerName: 'id',
									type: 'string',
									width: 100,
									editable: false,
									hide: true,
								},
								{
									field: 'NoteName',
									headerName: '서류명',
									type: 'string',
									width: 300,
									renderCell: (params: GridCellParams) => (
										<TextField
											fullWidth
											size="small"
											value={params.row.NoteName}
											onChange={(e) => {
												params.row.NoteName = e.target.value
												setGridRows([...gridRows])
											}}
										/>
									),
								},
								{
									field: 'attachFileId',
									headerName: 'file id',
									type: 'string',
									width: 130,
									editable: false,
									hide: true,
								},
								{
									field: 'orgFileName',
									headerName: '파일명',
									type: 'string',
									width: 300,
									editable: false,
									renderCell: (params) => {
										const { fileId, fileName, orgFileName } = params.row
										return fileId ? (
											<>
												<button
													type="button"
													onClick={() => fileDownloadByFileName(fileName, orgFileName)}
													style={{
														border: 'none',
														background: 'none',
														color: '#007bff',
														cursor: 'pointer',
														padding: '0',
														textDecoration: 'underline',
														textAlign: 'left',
													}}
												>
													{params.row.orgFileName}
												</button>
											</>
										) : (
											<></>
										)
									},
								},
								{
									field: 'fileButton',
									headerName: '파일 컨트롤',
									type: 'string',
									width: 100,
									renderCell: (params) => {
										const { fileId } = params.row
										return fileId ? (
											<Button
												variant="contained"
												color="error"
												size="small"
												onClick={() => attachFileDelete(params.row)}
											>
												파일 삭제
											</Button>
										) : (
											<Button
												variant="contained"
												size="small"
												onClick={() => attachFileButtonOnClick(params.row?.attachFileId)}
												disabled={params.row?.attachFileId ? false : true}
											>
												파일 업로드
											</Button>
										)
									},
								},
							]}
						/>
					</Box>
				</Box>
			</Paper>
		</>
	)
}

export default Docs
