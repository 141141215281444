import { forwardRef, useEffect, useState } from 'react'
import {
	Alert,
	Stack,
	Grid,
	Paper,
	Box,
	TextField,
	Typography,
	Dialog,
	DialogContent,
	LinearProgress,
	AppBar,
	IconButton,
	Toolbar,
	Slide,
	useMediaQuery,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { getSettings } from 'helpers/storage'

import { testItemsList, testItemInfo } from 'api/api'
import { replaceBrTag } from 'util/stringUtil'
import { TransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'
import TestInfo from 'views/test/TestInfo'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	)
})

const GridHeader = styled(Grid)(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? '#29b6f6' : 'rgba(2, 136, 209, 0.8)',
	...theme.typography.body2,
	padding: '0.1rem 0.3rem',
	textAlign: 'center',
	color: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(224, 224, 224, 0.5)'
			: '1px solid rgba(224, 224, 224, 0.5)',
}))

const GridItem = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
	...theme.typography.body2,
	padding: '0.1rem 0.7rem',
	textAlign: 'left',
	color: theme.palette.text.primary,
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(224, 224, 224, 0.5)'
			: '1px solid rgba(224, 224, 224, 0.5)',
}))

const Search = () => {
	// redux store
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const env = getSettings()

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		console.log(search)
		testItemsList(search).then((res) => {
			console.log(res)
			setGridRows(res?.items)
		})
	}
	const actionReset = () => {
		setSearch({
			displayName: '',
			insuranceCode: '',
			testCode: '',
			page: 0,
			pageSize: 0,
			searchKeyword: '',
		})
	}

	// grid state
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)
	// grid row click event
	const onRowClick = (e: GridRowModel) => {
		const testCode = e.row.TestCode as string
		const TestSpecimenID = e.row.TestSpecimenID as number
		if (TestSpecimenID) {
			testItemInfo(TestSpecimenID).then((res) => {
				// setItem(res)
				// setOpen(true)

				console.log(res)

				setTestInfoItem({ ...res.item })

				let MlistCount = 0
				let FlistCount = 0
				let tempReferenceTextDisplay = ''

				res.referenceList.forEach((element: any) => {
					if (element.GenderType === 'M') {
						setReferenceMList((prev) => [...prev, element])
						MlistCount++
					} else if (element.GenderType === 'F') {
						setReferenceFList((prev) => [...prev, element])
						FlistCount++
					} else if (
						element.GenderType === 'C' &&
						element.ReferenceTextDisplay !== tempReferenceTextDisplay
					) {
						tempReferenceTextDisplay = element.ReferenceTextDisplay
						setReferenceCList((prev) => [...prev, element])
					}
				})

				// 남자, 여자 둘다 있을 경우 공통 참고치 삭제
				if (MlistCount > 0 && FlistCount > 0) {
					// 20241021 공통참고치 삭제 (임시)
					// setReferenceCList([])
				}

				setTestInfoDialog(true)
			})
		}
	}

	// 조회조건
	const [search, setSearch] = useState<any>({
		displayName: '',
		insuranceCode: '',
		testCode: '',
		page: 0,
		pageSize: 0,
		searchKeyword: '',
	})
	const [item, setItem] = useState({
		DisplayName: '',
		InsuranceCode: '',
		InsurancePrice: 0,
		KeepMethod: '',
		TestTerm: '',
		MethodName: '',
		ReferenceTextDisplay: '',
		SpecimenName: '',
		SpecimenNote: '',
		TestCode: '',
		TestNeccessaryDay: '',
		InsuranceClassChar: '',
		TeetTerm: '',
		Volume: '',
		VolumeUnit: '',
		TakeCaution: '',
		IsQualityDistribute: '',
		TestItem: '',
		ContainerNote: '',
		ContainerPhoto: '',
		TestSpecimenID: 0,
		ClinicalSigni: '',
	})

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const [open, setOpen] = useState(false)
	const onClose = () => {
		setOpen(false)
		setItem({
			DisplayName: '',
			InsuranceCode: '',
			InsurancePrice: 0,
			KeepMethod: '',
			TestTerm: '',
			MethodName: '',
			ReferenceTextDisplay: '',
			SpecimenName: '',
			SpecimenNote: '',
			TestCode: '',
			TestNeccessaryDay: '',
			InsuranceClassChar: '',
			TeetTerm: '',
			Volume: '',
			VolumeUnit: '',
			TakeCaution: '',
			IsQualityDistribute: '',
			TestItem: '',
			ContainerNote: '',
			ContainerPhoto: '',
			TestSpecimenID: 0,
			ClinicalSigni: '',
		})
	}

	const [testInfoItem, setTestInfoItem] = useState({
		TestName: '',
		TestCode: '',
		InsuranceClassChar: '',
		InsuranceCode: '',
		InsurancePrice: '',
		TestTerm: '',
		TestNeccessaryDay: '',
		TestMethod: '',
		SpecimenName: '',
		VolumeOptimum: '',
		KeepMethod: '',
		SpecimenStability: '',
		Reference: '',
		ClinicalSigni: '',
		TakeCaution: '',
		GeneAgreement: '',
		TestRequest: '',
		ContainerPhoto: '',
	})
	const [referenceMList, setReferenceMList] = useState<any[]>([]) // 참고치 목록 (남자)
	const [referenceFList, setReferenceFList] = useState<any[]>([]) // 참고치 목록 (여자)
	const [referenceCList, setReferenceCList] = useState<any[]>([]) // 참고치 목록 (공통)
	const [testInfoDialog, setTestInfoDialog] = useState(false)
	const testInfoClose = () => {
		setTestInfoItem({
			TestName: '',
			TestCode: '',
			InsuranceClassChar: '',
			InsuranceCode: '',
			InsurancePrice: '',
			TestTerm: '',
			TestNeccessaryDay: '',
			TestMethod: '',
			SpecimenName: '',
			VolumeOptimum: '',
			KeepMethod: '',
			SpecimenStability: '',
			Reference: '',
			ClinicalSigni: '',
			TakeCaution: '',
			GeneAgreement: '',
			TestRequest: '',
			ContainerPhoto: '',
		})
		setReferenceMList([])
		setReferenceFList([])
		setReferenceCList([])
		setTestInfoDialog(false)
	}

	useEffect(() => {
		actionSearch()
	}, [])

	return (
		<>
			<Dialog
				fullScreen={isMdUp ? false : true}
				open={open}
				onClose={onClose}
				TransitionComponent={Transition}
				maxWidth="lg"
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar variant="dense">
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="div"
						>
							검사항목정보
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							onClick={onClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid
						container
						spacing={0}
					>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검사명
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={10}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.DisplayName}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검사코드
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.TestCode}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검체명
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.SpecimenName}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검사단위
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.VolumeUnit}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검체량
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.Volume}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								보존방법
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.KeepMethod}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검사요일
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.TestTerm}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검사방법
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.MethodName}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								검사 소요일
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.TestNeccessaryDay}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								보험분류
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.InsuranceClassChar}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								보험코드
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.InsuranceCode}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								급여비용
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.InsurancePrice &&
									Intl.NumberFormat('ko-KR').format(item?.InsurancePrice)}
								{item?.InsurancePrice && ' (*질가산료 미포함 수가입니다.)'}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								참고치
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
								dangerouslySetInnerHTML={{
									__html: replaceBrTag(item?.ReferenceTextDisplay),
								}}
							></Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								주의사항
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.TakeCaution}
							</Typography>
						</GridItem>
						<GridHeader
							item
							xs={2}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								비고
							</Typography>
						</GridHeader>
						<GridItem
							item
							xs={4}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								{item?.SpecimenNote}
							</Typography>
						</GridItem>
					</Grid>
					{item?.TestItem && (
						<Alert
							severity="success"
							sx={{ mt: 1, mb: 1 }}
						>
							{item?.TestItem}
						</Alert>
					)}
					<Grid
						container
						spacing={1}
					>
						<Grid
							item
							xs={12}
							sm={4}
						>
							{item?.ContainerPhoto && (
								<div style={{ backgroundColor: '#fff' }}>
									<img
										src={`data:image/jpeg;base64,${item?.ContainerPhoto}`}
										alt="검체용기 사진"
										style={{ maxHeight: 170, display: 'block', margin: '0 auto' }}
									/>
								</div>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sm={8}
						>
							<Grid
								container
								spacing={0}
							>
								<GridHeader
									item
									xs={2}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										해당검사
									</Typography>
								</GridHeader>
								<GridItem
									item
									xs={10}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										{item?.TestItem}
									</Typography>
								</GridItem>
								<GridHeader
									item
									xs={2}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										보존
									</Typography>
								</GridHeader>
								<GridItem
									item
									xs={10}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										{item?.KeepMethod}
									</Typography>
								</GridItem>
								<GridHeader
									item
									xs={2}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										참고사항
									</Typography>
								</GridHeader>
								<GridItem
									item
									xs={10}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										{item?.ContainerNote}
									</Typography>
								</GridItem>
								<GridHeader
									item
									xs={2}
								>
									<Typography
										variant="subtitle1"
										component="h3"
									>
										임상학적의의
									</Typography>
								</GridHeader>
								<GridItem
									item
									xs={10}
								>
									<Typography
										variant="subtitle1"
										component="h3"
										dangerouslySetInnerHTML={{
											__html: replaceBrTag(item?.ClinicalSigni),
										}}
									></Typography>
								</GridItem>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<Stack
				sx={{
					marginBottom: '1rem',
					position: 'sticky',
					top: 120,
					left: 0,
					right: 0,
					zIndex: 1,
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '55px',
						top: '0px',
					},
				}}
			>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onKeyUp={onKeyUp}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={12}
								sm={3}
							>
								<TextField
									label="전체"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.searchKeyword}
									onChange={(e) => {
										setSearch({
											...search,
											searchKeyword: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={3}
							>
								<TextField
									label="검사명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.displayName}
									onChange={(e) => {
										setSearch({
											...search,
											displayName: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={3}
							>
								<TextField
									label="보험코드"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.insuranceCode}
									onChange={(e) => {
										setSearch({
											...search,
											insuranceCode: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={3}
							>
								<TextField
									label="검사코드"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.testCode}
									onChange={(e) => {
										setSearch({
											...search,
											testCode: e.target.value,
										})
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</PageBg>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden' }}
			>
				<Box sx={{ p: 2 }}>
					<Typography
						variant="subtitle1"
						component="h3"
					>
						검사 항목
					</Typography>
					<Box
						sx={{
							marginTop: '1rem',
							'@media (max-width: 1199px)': {
								height: '400px',
								overflow: 'auto',
							},
						}}
					>
						<DataGridPremium
							rows={gridRows}
							getRowId={(row) => row.rowNum}
							columns={[
								{
									field: 'rowNum',
									headerName: 'No',
									type: 'number',
									width: 55,
								},
								{
									field: 'TestSpecimenID',
									headerName: '검사검체관계ID',
									type: 'number',
									width: 75,
									editable: false,
									hide: true,
								},
								{
									field: 'TestCode',
									headerName: '검사코드',
									type: 'string',
									width: 75,
									editable: false,
								},
								{
									field: 'DisplayName',
									headerName: '검사명',
									type: 'string',
									width: 500,
									minWidth: 300,
									editable: false,
								},
								{
									field: 'SpecimenName',
									headerName: '검체명',
									type: 'string',
									width: 150,
									minWidth: 150,
									editable: false,
								},
								{
									field: 'Volume',
									headerName: '검체(mL)',
									type: 'string',
									width: 90,
									editable: false,
								},
								{
									field: 'MethodName',
									headerName: '검사방법',
									type: 'string',
									width: 250,
									editable: false,
								},
								{
									field: 'KeepMethod',
									headerName: '검체보관',
									type: 'string',
									width: 80,
									editable: false,
								},
								{
									field: 'InsuranceCode',
									headerName: '보험코드',
									type: 'string',
									width: 100,
									editable: false,
								},
								{
									field: 'TestNeccessaryDay',
									headerName: '검사/소요일',
									type: 'string',
									width: 85,
									editable: false,
								},
							]}
							pageSize={gridPageSize}
							onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={isMdUp ? true : false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									excelOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 250 },
								},
							}}
							loading={gridLoading}
							selectionModel={selectionModel}
							onSelectionModelChange={(newSelection) => {
								setSelectionModel(newSelection)
							}}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: { columnVisibilityModel: { TestSpecimenID: false } },
							}}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</Box>
			</Paper>
			<TestInfo
				testInfoItem={testInfoItem}
				referenceMList={referenceMList}
				referenceFList={referenceFList}
				referenceCList={referenceCList}
				testInfoDialog={testInfoDialog}
				testInfoClose={testInfoClose}
			/>
		</>
	)
}

export default Search
