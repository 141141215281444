import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton } from '@mui/material'
import {
	gridDetailPanelExpandedRowsContentCacheSelector,
	GridRenderCellParams,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-premium'

/**
 * collapse/expand detail panel
 * @param props
 * @returns
 */
const CustomDetailPanelToggle = (
	props: Pick<GridRenderCellParams, 'id' | 'value'>
) => {
	const { id, value: isExpanded } = props
	const apiRef = useGridApiContext()

	// To avoid calling ´getDetailPanelContent` all the time, the following selector
	// gives an object with the detail panel content for each row id.
	const contentCache = useGridSelector(
		apiRef,
		gridDetailPanelExpandedRowsContentCacheSelector
	)

	// If the value is not a valid React element, it means that the row has no detail panel.
	let isDisabled = true
	if (
		props.value !== undefined &&
		props.value !== null &&
		props.value !== '' &&
		props.value !== '\r\n'
	) {
		isDisabled = false
	}
	// let hasDetail = isValidElement(contentCache[id])

	return (
		<IconButton
			size="small"
			tabIndex={-1}
			disabled={isDisabled}
			color="primary"
			aria-label={isExpanded ? 'Close' : 'Open'}
			sx={{
				backgroundColor: isDisabled ? 'transparent' : 'primary.main',
				color: isDisabled ? 'text.disabled' : 'primary.contrastText',
				'&:hover': {
					backgroundColor: isDisabled ? 'transparent' : 'primary.dark',
				},
			}}
		>
			<ExpandMoreIcon
				sx={{
					transform: `rotateZ(${isExpanded ? 0 : 180}deg)`,
					transition: (theme) =>
						theme.transitions.create('transform', {
							duration: theme.transitions.duration.shortest,
						}),
				}}
				fontSize="inherit"
			/>
		</IconButton>
	)
}

export default CustomDetailPanelToggle
