import { createGlobalStyle } from 'styled-components'

/**
 * 공통 스타일
 */
const GlobalStyles = createGlobalStyle`
    // .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    //     width: 0.4rem;    
    // }
    // .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    //     background: #f1f1f1;
    // }
    // .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    //     background: #888;
    // }
    // .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
    //     background: #555;
    // }
    .MuiToolbar-root {
        min-height: 44px;
    }
    @media (min-width: 600px) {
        .MuiToolbar-root {
            min-height: 44px;
        }
    }
    .content-container {
        padding: 1.5rem;
        @media (max-width: 767px) {
            padding: 0;
        }
    }
    .swal2-container {
        z-index: 100000;
    }
    .break-word {
        word-break: break-word;
        white-space: normal;
    }
    @media print {
        * {
            -webkit-print-color-adjust: exact;
        }
    }
    @page {
        margin: 0;
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    .sheet {
        margin: 0;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        page-break-after: always;
    }
    
    /** Paper sizes **/
    .A3 .sheet {
        width: 297mm;
        height: 419mm;
    }
    .A3.landscape .sheet {
        width: 420mm;
        height: 296mm;
    }
    .A4 .sheet {
        width: 210mm;
        height: 296mm;
    }
    .A4.landscape .sheet {
        width: 297mm;
        height: 209mm;
    }
    .A5 .sheet {
        width: 148mm;
        height: 209mm;
    }
    .A5.landscape .sheet {
        width: 210mm;
        height: 147mm;
    }
    .B5 .sheet {
        width: 182mm;
        height: 257mm;
    }
    .letter .sheet {
        width: 216mm;
        height: 279mm;
    }
    .letter.landscape .sheet {
        width: 280mm;
        height: 215mm;
    }
    .legal .sheet {
        width: 216mm;
        height: 356mm;
    }
    .legal.landscape .sheet {
        width: 357mm;
        height: 215mm;
    }
    
    /** Padding area **/
    .sheet.padding-10mm {
        padding: 10mm;
    }
    .sheet.padding-15mm {
        padding: 15mm;
    }
    .sheet.padding-20mm {
        padding: 20mm;
    }
    .sheet.padding-25mm {
        padding: 25mm;
    }
    
    /** For screen preview **/
    @media screen {
        .printBody {
            background: rgb(227, 242, 253);
        }
        .sheet {
            background: rgb(29, 21, 21);
            box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
            margin: 5mm auto;
        }
    }
    
    /** Fix for Chrome issue #273306 **/
    @media print {
        .A3.landscape {
            width: 420mm;
        }
        .A3,
        .A4.landscape {
            width: 297mm;
        }
        .A4,
        .A5.landscape {
            width: 210mm;
        }
        .A5 {
            width: 148mm;
        }
        .letter,
        .legal {
            width: 216mm;
        }
        .letter.landscape {
            width: 280mm;
        }
        .legal.landscape {
            width: 357mm;
        }
        .noPrint {
            display: none !important;
        }
    }
    
    .reportWarningBox {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.65);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px;
        text-align: center;
    }
    .reportDialog {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .reportDialog iframe {
        width: 100%;
        height: 100%;
    }
    .reportWrapper {
        position: relative;
        color: #000;
        overflow-y: auto;
    }
    .reportWrapper:last-child {
        page-break-after: avoid;
    }
    .reportInner {
        position: relative;
        background: #fff;
        color: #000;
    }
    .reportHeader,
    .reportFlexBox {
        display: flex;
        align-items: end;
        justify-content: flex-end;
    }
    .reportHeader {
        padding-top: 27px;
    }
    .reportHeader p {
        font-weight: 300;
        font-size: 12px;
        margin-right: 5px;
    }
    .reportHalfBox {
        position: relative;
        display: flex;
    }
    .reportHalfBox .bx1 {
        width: 65%;
        display: flex;
        justify-content: space-between;
    }
    .reportHalfBox .bx2 {
        width: 35%;
        display: flex;
        justify-content: space-between;
    }
    .reportHeaderTable {
        border-collapse: collapse;
        width: 100%;
        margin: 5px 0 15px 0;
        border-top: 2px solid #8aaadb;
        border-bottom: 2px solid #8aaadb;
    }
    .reportInfoTable {
        border-collapse: collapse;
        width: 100%;
        margin: 15px 0 1rem 0;
        table-layout: fixed;
    }
    .reportInfoTableHidden {
        border-collapse: collapse;
        width: 718px;
        margin: 0 !important;
        table-layout: fixed;
        border-bottom: 5px solid red !important;
    }
    .reportHeaderTable th,
    .reportHeaderTable td, reportInfoTableHidden th, reportInfoTableHidden td {
        background: transparent;
        padding: 4px 10px;
        font-size: 13px;
        font-weight: 300;
        vertical-align: top;
        border: 1px solid #c8c8c8;
    }
    .reportHeaderTable th, .reportInfoTableHidden th {
        text-align: center;
        font-weight: 400;
        &:first-of-type {
            border-left: 0;
        }
    }
    .reportHeaderTable td, .reportInfoTableHidden td {
        &:last-child {
            border-right: 0;
        }
    }
    .reportFlexTop.a139 {
        padding-top: 34px;
        height: 955px !important;
    }
    .reportFlexTop.a139 
    .reportHeaderTable th,
    .reportHeaderTable td {
        padding: 5px 10px;
    }
    .reportFlexTop.a139 
    .reportHeaderTable,
    .reportFlexTop.a139 .reportInfoTable {
        border-top: 2px solid #00a499;
        border-bottom: 2px solid #00a499;
    }
    
    .reportInfoTable th,
    .reportInfoTable td, .reportInfoTableHidden th, .reportInfoTableHidden td {
        padding: 5px 10px;
        font-size: 13px;
        vertical-align: top;
        word-break: break-word;
    }
    .reportInfoTable.small th,
    .reportInfoTable.small td {
        padding: 3px;
        font-size: 10px;
    }
    .reportInfoTable th, .reportInfoTableHidden th {
        text-align: center;
        // background-color: #000;
        border: 1px solid #ddd;
        // color: #fff;
    }
    .reportInfoTable td, .reportInfoTableHidden td {
        background-color: #fff;
        white-space: pre-wrap;
        border: 1px solid #ddd;
    }
    .reportFlexTop.a139 
    .reportInfoTable th,
    .reportInfoTable td {
        padding: 5px 10px;
    }
    .reportFlexTop.a139 
    .reportInfoTable th {
        background: #cae8e6;
        color: #000;
        text-align: left;
        padding: 7px 10px;
    }
    .reportFlexTop.a139 .reportInfoTable.sm td {
        padding: 3px 10px;
    }
    .reportFlexTop.a139 .reportInfoTable tr td:first-child {
        border-left: 0;
    }
    .reportFlexTop.a139 .reportInfoTable tr td:last-child {
        border-right: 0;
    }
    .reportSignArea {
        list-style: none;
        margin: 0 0 15px 0;
    }
    .reportSignArea li {
        position: relative;
        font-size: 11px;
        line-height: 1.2;
        margin-bottom: 10px;
        padding-right: 90px;
        text-align: right;
    }
    .reportSignArea.small li {
        margin-bottom: 5px;
    }
    .reportSignArea .reportSignImg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        max-width: 80px;
        max-height: 30px;
    }
    .reportWarningBox {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.65);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px;
        text-align: center;
    }
    .reportRemarkList {
        list-style: none;
        padding: 0;
        margin: 0;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
    .reportRemarkBox {
        background-color: #fff;
        white-space: pre-wrap;
        border-bottom: 1px solid #ddd;
        font-size: 12px;
        padding: 5px 10px;
    }
    .reportRemarkBox p {
        margin: 0;
    }
    .reportRemarkBox:last-child {
        margin-bottom: 20px;
    }
    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .report-text-warning {
        color: #ff0000;
    }
    .report-text-info {
        color: #4834d4;
    }
    .report-text-success {
        color: #05c46b;
    }
    .reportFlexTop {
        height: 890px;
    }
    .reportFlexTop.oneSign {
        height: 970px;
    }
    .reportFooter {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        // padding-top: 10px;
        // margin-bottom: 50px;
        display: flex;
        flex-direction: column;
    }
    .reportFooter .reportFooterText {
        position: absolute;
        top: -140px;
        left: 0;
        right: 0;
        text-align: center;
        font-weight: 300;
    }
    .reportFooterInner {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0px;
        right: 40px;
        height: 100px;
    }
    .reportFooterInner p {
        margin-right: 10px;
        font-size: 11px;
    }
    .reportFooter .warranty {
        margin-bottom: 10px;
    }
    .reportLogo {
        width: 150px;
    }
    .print-on-a4 {
        page-break-before: always;
        page-break-after: always;
    }
    .text-center {
        text-align: center !important;
    }
    td.v-center {
        vertical-align: middle !important;
    }
    .decisionStyleRed {
        color: red;
    }
    .decisionStyleBlue {
        color: blue;
    }
    .decisionStyleBlack {
        color: black;
    }
    .loadingSpinner {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
    // .loadingSpinner .spinner {
    //     width: 40px;
    //     height: 40px;
    //     border-radius: 50%;
    //     border: 3px solid #00a499;
    //     border-top-color: transparent;
    //     animation: spin 1s linear infinite;
    // }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    .super-app-theme--true {
        background: #eb4d4b !important;
    }
    
`
export default GlobalStyles
