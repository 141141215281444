import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
	Stack,
	Grid,
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	LinearProgress,
	Select,
	SelectChangeEvent,
	Chip,
	Tooltip,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridRowModel,
	GridToolbar,
	GridApi,
	GridCellValue,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColumnGroupingModel,
	GridCellParams,
	GridDensity,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import { styled } from '@mui/material/styles'
import CustomDetailPanelToggle from 'components/CustomDetailPanelToggle'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { alertModal } from 'util/util'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import moment from 'moment'
import 'moment/locale/ko'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { CmmnCodeType, CovidInspectionResultSearchType } from 'constants/types'
import { compareDate, isValidDateType } from 'util/util'
import { diseaseExcel, diseaseList } from 'api/api'

import { selectuserInfo } from 'hooks/userInfo'
import { replaceBrTag } from 'util/stringUtil'
import { getSettings } from 'helpers/storage'
import ClientSearchPopup from './clientSearchPopup'
import { useTheme } from '@mui/material/styles'

const locales = ['ko'] as const

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))

const Disease = () => {
	// redux store
	const cmdCdList = useSelector(selectCmmnCode)
	const testCdList = useSelector(selectCmmnCode)

	const theme = useTheme()

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const gridRef = useGridApiRef()

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [disabledExcel, setDisabledExcel] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		console.log(search)
		getDiseaseList()
	}
	const actionReset = () => {
		setSearch({
			clientID: userInfo.clientID,
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			searchType: 'R',
		})
		setDateRange('7d')
		setSearchClientName('')
		gridRef.current?.setQuickFilterValues([])
		gridRef.current?.setPage(0)
	}

	// grid state
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	// const onRowClick = (e: GridRowModel) => {}

	// 조회조건
	const [search, setSearch] = useState<any>({
		clientID: '',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		searchType: 'R',
	})
	const [cmdList, setCmdList] = useState<CmmnCodeType[]>([])
	const [testCodeList, setTestCodeList] = useState<CmmnCodeType[]>([])
	const [dateRange, setDateRange] = useState('7d')
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch(() => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const actionExcel = () => {
		setDisabledExcel(true)
		window.open(
			`${process.env.REACT_APP_HOST}/test/diseaseExcel?startDate=${
				search.startDate
			}&endDate=${search.endDate}&clientID=${
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? search.clientID
					: userInfo.clientID
			}&searchType=${search.searchType}`,
			'결과보고서'
		)
		setDisabledExcel(false)
	}

	const getDiseaseList = () => {
		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			search.clientID === ''
		) {
			alertModal('고객사를 선택해주세요.', 'error', () => {})
			return
		}

		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(search.startDate).isBefore('2023-08-01')) {
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
		}
		// if (moment(search.endDate).isBefore('2023-08-10')) {
		// 	setSearch({
		// 		...search,
		// 		endDate: '2023-08-10',
		// 	})
		// }

		setGridLoading(true)

		const request = {
			...search,
			clientID: search.clientID,
			startDate: moment(search.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: search.startDate,
			endDate: search.endDate,
		}
		// const request = {
		// 	...search,
		// 	clientID: search.clientID,
		// 	startDate: search.startDate,
		// 	endDate: search.endDate,
		// }

		console.log(request)

		diseaseList(request).then((res) => {
			console.log(res)
			setGridRows(res)
			setGridLoading(false)
		})
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	useEffect(() => {
		if (userInfo.infoType === 'C') getDiseaseList()
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack
				sx={{
					marginBottom: '1rem',
					position: 'sticky',
					top: 120,
					left: 0,
					right: 0,
					zIndex: 1,
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '55px',
						top: '0px',
					},
				}}
			>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onKeyUp={onKeyUp}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<SearchRowItem>
								<p className="labelText">기간설정</p>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										xs={6}
										sm={2}
										lg={3}
									>
										<FormControl fullWidth>
											<InputLabel id="searchType-select-label">기간 유형</InputLabel>
											<Select
												labelId="searchType-select-label"
												id="searchTypee-simple-select"
												value={search.searchType}
												label="기간 유형"
												onChange={(e) => {
													setSearch({
														...search,
														searchType: e.target.value,
													})
												}}
												size={'small'}
											>
												<MenuItem value={'D'}>신고일자</MenuItem>
												<MenuItem value={'R'}>접수일자</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={6}
										sm={2}
										lg={3}
									>
										<DatePicker
											label="시작일"
											value={search.startDate}
											mask={'____-__-__'}
											inputFormat={'YYYY-MM-DD'}
											onChange={(newValue) => {
												dateRanageOnChange(newValue, 'startDate')
											}}
											renderInput={(params) => (
												<TextField
													size="small"
													{...params}
													fullWidth
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={6}
										sm={2}
										lg={3}
									>
										<DatePicker
											label="종료일"
											value={search.endDate}
											mask={'____-__-__'}
											inputFormat={'YYYY-MM-DD'}
											onChange={(newValue) => {
												dateRanageOnChange(newValue, 'endDate')
											}}
											renderInput={(params) => (
												<TextField
													size="small"
													{...params}
													fullWidth
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={6}
										sm={2}
										lg={3}
									>
										<FormControl fullWidth>
											<InputLabel id="date-range-select-label">조회 기간</InputLabel>
											<Select
												labelId="date-range-select-label"
												id="date-range-simple-select"
												value={dateRange}
												label="조회 기간"
												onChange={searchDateRanageOnChange}
												size={'small'}
											>
												<MenuItem value={'0'}>선택하세요.</MenuItem>
												<MenuItem value={'7d'}>7일</MenuItem>
												<MenuItem value={'15d'}>15일</MenuItem>
												<MenuItem value={'1m'}>1개월</MenuItem>
												<MenuItem value={'3m'}>3개월</MenuItem>
												<MenuItem value={'6m'}>6개월</MenuItem>
												<MenuItem value={'1y'}>1년</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
										<Grid
											item
											xs={6}
											sm={2}
											lg={3}
										>
											<TextField
												label="거래처"
												variant="outlined"
												size="small"
												type="text"
												fullWidth
												aria-readonly={true}
												value={searchClientName}
												onClick={clientPopupOpenAction}
											/>
										</Grid>
									)}
								</Grid>
							</SearchRowItem>
						</LocalizationProvider>
					</Box>
				</PageBg>
			</Stack>
			<PageBg style={{ marginTop: '1rem', width: '100%', overflow: 'hidden' }}>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '1rem',
							}}
						>
							<Typography
								variant="subtitle1"
								component="h3"
							>
								수진자 리스트
							</Typography>
							<div>
								<Button
									color="secondary"
									size="small"
									variant="contained"
									startIcon={<FileDownloadIcon />}
									sx={{ marginRight: '0.5rem' }}
									onClick={() => actionExcel()}
									disabled={disabledExcel}
								>
									엑셀저장
								</Button>
							</div>
						</Box>
						<Box
							sx={{
								width: '100%',
								overflow: 'auto',
								'@media (max-width: 1199px)': {
									// height: '600px',
								},
							}}
						>
							<DataGridPremium
								apiRef={gridRef}
								rows={gridRows}
								getRowId={(row) => row.rowNum}
								columns={[
									{
										field: 'rowNum',
										headerName: '번호',
										type: 'number',
										width: 80,
										editable: false,
										hideable: true,
										hide: true,
									},
									{
										field: 'DoctorName',
										headerName: '담당의',
										type: 'string',
										width: 80,
										editable: false,
									},
									{
										field: 'PatientName',
										headerName: '성명',
										type: 'string',
										width: 80,
										editable: false,
									},
									{
										field: 'Gender',
										headerName: '성별',
										type: 'string',
										width: 45,
										editable: false,
									},
									{
										field: 'BirthDay',
										headerName: '생년월일',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'ChartNo',
										headerName: '차트번호',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'ReceptionDate',
										headerName: '의뢰일',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'ConfirmDate',
										headerName: '진단일',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'ApproximateDate',
										headerName: '신고예정일',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'ConfirmerMedicalNum',
										headerName: '기관번호',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'StaffName',
										headerName: '진단의',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'ReceptionNum',
										headerName: '검체번호',
										type: 'string',
										width: 145,
										editable: false,
									},
									{
										field: 'TestCode',
										headerName: '검사코드',
										type: 'string',
										width: 80,
										editable: false,
									},
									{
										field: 'InsuranceCode',
										headerName: '보험코드',
										type: 'string',
										width: 90,
										editable: false,
									},
									{
										field: 'TestName',
										headerName: '검사명',
										type: 'string',
										width: 120,
										editable: false,
									},
									{
										field: 'SpecimenTypeList',
										headerName: '검체종류',
										type: 'string',
										width: 70,
										editable: false,
									},
									{
										field: 'TestTypeList',
										headerName: '검사방법',
										type: 'string',
										width: 70,
										editable: false,
									},
									{
										field: 'PathogenCode',
										headerName: '병원체코드',
										type: 'string',
										width: 80,
										editable: false,
									},
								]}
								pageSize={gridPageSize}
								onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
								pagination
								autoHeight={true}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								components={{
									Toolbar: GridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								componentsProps={{
									toolbar: {
										csvOptions: { disableToolbarButton: true },
										excelOptions: { disableToolbarButton: true },
										printOptions: { disableToolbarButton: true },
										showQuickFilter: true,
										quickFilterProps: { debounceMs: 250 },
									},
								}}
								loading={gridLoading}
								// selectionModel={gridSelectionModel}
								// onRowClick={onRowClick}
								rowThreshold={0}
								getDetailPanelHeight={({ row }) => 'auto'}
								experimentalFeatures={{ columnGrouping: true }}
								density={(env.desnse as GridDensity) || 'compact'}
								sx={{
									'& .MuiDataGrid-columnHeaderTitleContainer': {
										fontSize: '13px',
									},
									'& .MuiDataGrid-cell': {
										fontSize: '13px !important',
										borderRight:
											theme.palette.mode === 'dark'
												? '1px solid rgba(81, 81, 81, 1)'
												: '1px solid rgba(224, 224, 224, 1)',
									},
								}}
								// initialState={{
								// 	pinnedColumns: { left: ['PatientName'], right: ['sms'] },
								// }}
							/>
						</Box>
					</Grid>
				</Grid>
			</PageBg>
		</>
	)
}

export default Disease
