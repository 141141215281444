import { createSlice } from '@reduxjs/toolkit'

export const gridColumnOrderV2Slice = createSlice({
	name: 'gridColumnOrderV2',
	initialState: {
		info: {},
	},
	reducers: {
		setGridColumnV2: (state, actions) => {
			state.info = actions.payload
		},
		removeGridColumnV2: (state) => {
			state.info = {}
		},
	},
})

export const { setGridColumnV2, removeGridColumnV2 } =
	gridColumnOrderV2Slice.actions
export default gridColumnOrderV2Slice.reducer
export const selectGridColumnInfoV11 = (state: any) => state.gridColumnOrderV2
