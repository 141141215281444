import { forwardRef, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import { TextField, Switch } from 'formik-mui'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { BoardRequestType } from 'constants/types'
import { styled } from '@mui/material/styles'
import { Button, DialogActions, DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import FileUploader from './FileUploader'
import FormControlLabel from '@mui/material/FormControlLabel'
import { fileMasterId } from 'api/api'
import {
	Grid,
	Paper,
	Box,
	FormControl,
	InputLabel,
	LinearProgress,
	Select,
	Chip,
	SelectChangeEvent,
	useMediaQuery,
	Menu,
	MenuItem,
	TextField as MuiTextField,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'

import moment from 'moment'
import 'moment/locale/ko'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const locales = ['ko'] as const

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	)
})

const EditorBg = styled('div')(({ theme }) => ({
	padding: '2rem',
	borderRadius: '0.5rem',
	background: theme.palette.custom.wrapper,
	filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	marginBottom: '55px',
}))

const FooterBtns = styled('div')(({ theme }) => ({
	position: 'fixed',
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	justifyContent: 'flex-end',
	padding: '8px',
	flex: '0 0 auto',
	backgroundColor: theme.palette.mode === 'dark' ? '#383838' : '#FFFFFF',
	borderTop:
		theme.palette.mode === 'dark'
			? '1px solid rgba(255, 255, 255, 0.12)'
			: '1px solid rgba(0, 0, 0, 0.12)',
}))

const BoardWrite = ({
	dataSet,
	formSubmit,
	WysiwygEditor,
	dialogOpen,
	dialogClose,
	attachFileId,
}: any) => {
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const initialValues: BoardRequestType = {
		boardId: dataSet.boardId || '',
		category: dataSet.category || '',
		title: dataSet.title || '',
		content: dataSet.content || '',
		startDate: dataSet.startDate || moment().format('YYYY-MM-DD HH:mm'),
		endDate: dataSet.endDate || moment().format('YYYY-MM-DD HH:mm'),
		attachFileId: dataSet.attachFileId || '',
		fileDest: dataSet.fileDest || '',
		createdUser: dataSet.createdUser || '',
		createdAt: dataSet.createdAt || '',
		updatedUser: dataSet.updatedUser || '',
		updatedAt: dataSet.updatedAt || '',
		displayTo: dataSet.displayTo || 'A',
		deleteFlag: dataSet.deleteFlag || false,
		isPopup: dataSet.isPopup || 'A',
	}
	const validationSchema = Yup.object({
		title: Yup.string()
			.min(5, '제목은 최소 5글자입니다.')
			.max(255, '제목은 최대 255글자입니다.')
			.required('제목은 필수항목입니다.'),
	})

	useEffect(() => {
		console.log('dataSet', dataSet)
	}, [])

	return (
		<Dialog
			fullScreen
			open={dialogOpen}
			onClose={dialogClose}
			TransitionComponent={Transition}
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="div"
					>
						공지사항
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={dialogClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={formSubmit}
				enableReinitialize
			>
				{({
					isSubmitting,
					values,
					setValues,
					submitForm,
					resetForm,
					setFieldValue,
					handleChange,
					handleBlur,
					touched,
					errors,
					dirty,
				}) => (
					<Form>
						<Stack
							direction="column"
							spacing={2}
						>
							<EditorBg>
								<Stack
									direction="column"
									spacing={2}
								>
									<Field
										name="title"
										type="text"
										label="제목"
										size="small"
										component={TextField}
										value={values.title}
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.title && Boolean(errors.title)}
										helperText={touched.title && errors.title}
									/>
									<Grid container>
										<Grid
											item
											xs={6}
											sx={{ pr: 1 }}
										>
											<FormControl fullWidth>
												<InputLabel id="displayTo-select-label">게시 대상</InputLabel>
												<Select
													labelId="displayTo-select-label"
													id="displayTo-simple-select"
													name="displayTo"
													value={values.displayTo}
													label="게시 대상"
													onChange={handleChange}
													size={'small'}
												>
													<MenuItem value={'A'}>전체</MenuItem>
													<MenuItem value={'C'}>거래처</MenuItem>
													<MenuItem value={'S'}>내부사용자</MenuItem>
													<MenuItem value={'W'}>시스템관리자</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid
											item
											xs={6}
											sx={{ pl: 1 }}
										>
											<FormControl fullWidth>
												<InputLabel id="isPopup-select-label">게시물 종류</InputLabel>
												<Select
													labelId="isPopup-select-label"
													id="isPopup-simple-select"
													name="isPopup"
													value={values.isPopup}
													label="게시물 종류"
													onChange={handleChange}
													size={'small'}
												>
													<MenuItem value={'A'}>일반 게시물</MenuItem>
													<MenuItem value={'P'}>팝업</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										{values.isPopup === 'P' && (
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale={locale}
											>
												<Grid
													item
													xs={6}
													sx={{ pr: 1, mt: 2 }}
												>
													<DateTimePicker
														label="시작일시"
														value={values.startDate}
														inputFormat={'YYYY-MM-DD HH:mm'}
														onChange={(e: any) => {
															const { $D, $H, $L, $M, $W, $d, $m, $ms, $s, $u, $x, $y } = e
															console.log(e)
															const formattedDate = moment([$y, $M, $D, $H, $m]).format(
																'YYYY-MM-DD HH:mm'
															)
															setValues({
																...values,
																startDate: formattedDate,
															})
														}}
														renderInput={(params) => (
															<MuiTextField
																size="small"
																{...params}
																fullWidth
															/>
														)}
													/>
												</Grid>
												<Grid
													item
													xs={6}
													sx={{ pl: 1, mt: 2 }}
												>
													<DateTimePicker
														label="종료일시"
														value={values.endDate}
														inputFormat={'YYYY-MM-DD HH:mm'}
														onChange={(e: any) => {
															const { $D, $H, $L, $M, $W, $d, $m, $ms, $s, $u, $x, $y } = e
															console.log(e)
															const formattedDate = moment([$y, $M, $D, $H, $m]).format(
																'YYYY-MM-DD HH:mm'
															)
															setValues({
																...values,
																endDate: formattedDate,
															})
														}}
														renderInput={(params) => (
															<MuiTextField
																size="small"
																{...params}
																fullWidth
															/>
														)}
													/>
												</Grid>
											</LocalizationProvider>
										)}
									</Grid>
									<WysiwygEditor htmlContent={dataSet.content} />
								</Stack>
								<Stack sx={{ marginTop: '1rem' }}>
									<FileUploader
										fileDest="notice"
										attachFileId={attachFileId}
									/>
								</Stack>
							</EditorBg>
							<FooterBtns>
								<Button
									startIcon={<CancelIcon />}
									type="button"
									variant="contained"
									color="secondary"
									onClick={dialogClose}
									sx={{ marginRight: '8px' }}
								>
									취소
								</Button>
								<LoadingButton
									type="submit"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									{values.boardId === '' ? '저장' : '수정'}
								</LoadingButton>
							</FooterBtns>
						</Stack>
					</Form>
				)}
			</Formik>
		</Dialog>
	)
}

export default BoardWrite
