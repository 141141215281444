import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Grid,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	Tabs,
	Tab,
	Breadcrumbs,
	useMediaQuery,
	AppBar,
	Dialog,
	DialogContent,
	IconButton,
	Toolbar,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Divider,
	DialogActions,
	TableContainer,
	Alert,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { PageBg, PageWrap } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
	alertModal,
	autoCellPhone,
	compareDate,
	confirmAlert,
	hexToRgba,
	isValidDateType,
} from 'util/util'

import TaskAltIcon from '@mui/icons-material/TaskAlt'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'

import moment from 'moment'
import 'moment/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import CloseIcon from '@mui/icons-material/Close'

import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'

import {
	apiClientSmsMstList,
	apiSmsResultList,
	apiSmsReservationList,
	apiSmsScheduleDelete,
	apiSmsCount,
} from 'api/api'
import { SmsInfoType } from 'constants/types'
import { useNavigate } from 'react-router-dom'
import { valuesInIterator } from 'util/objectUtil'
import TransitionEffect from 'components/TransitionEffect'
import { replaceBrTag } from 'util/stringUtil'

const locales = ['ko'] as const

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const cmpColumns: GridColDef[] = [
	{
		field: 'RowNum',
		headerName: '순번',
		width: 60,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'MsgID',
		headerName: '메시지ID',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ScheduleType',
		headerName: '구분',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { ScheduleType } = params.row
			return (
				<>
					{ScheduleType === 1 ? (
						<span
							style={{
								color: '#000',
								background: '#99d696',
								padding: '1px 3px',
								border: '1px solid #99d696',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							예약
						</span>
					) : (
						<span
							style={{
								background: '#f1c40f',
								padding: '1px 3px',
								border: '1px solid #f1c40f',
								color: '#000',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							즉시전송
						</span>
					)}
				</>
			)
		},
	},
	{
		field: 'SendDate',
		headerName: '발송일',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { SendDate } = params.row
			if (SendDate.length > 8) {
				return <>{SendDate.substr(0, 10)}</>
			}
		},
	},
	{
		field: 'resvTime',
		headerName: '발송시간',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { SendDate } = params.row
			if (SendDate.length > 8) {
				return <>{SendDate.substr(10, 12)}</>
			}
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientName',
		headerName: '고객명',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'CellNumber',
		headerName: '전화번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { CellNumber } = params.row
			return <>{autoCellPhone(CellNumber)}</>
		},
	},
	{
		field: 'MmsMsg',
		headerName: '발송문자',
		width: 120,
		flex: 1,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'SuccCount',
		headerName: '발송결과',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { SuccCount } = params.row
			return (
				<>
					{SuccCount === 1 ? (
						<span
							style={{
								color: '#000',
								background: '#74acdb',
								padding: '1px 3px',
								border: '1px solid #74acdb',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							완료
						</span>
					) : (
						<span
							style={{
								background: '#c0392b',
								padding: '1px 3px',
								border: '1px solid #c0392b',
								color: '#fff',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							실패
						</span>
					)}
				</>
			)
		},
	},
	{
		field: 'RegUserID',
		headerName: '등록자',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'RegDate',
		headerName: '등록일',
		width: 130,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
]

const resvColumns: GridColDef[] = [
	{
		field: 'RowNum',
		headerName: '순번',
		width: 60,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'MsgID',
		headerName: '메시지ID',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ScheduleType',
		headerName: '구분',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { ScheduleType } = params.row
			return <>{ScheduleType === 1 ? '예약' : ''}</>
		},
	},
	{
		field: 'SendDate',
		headerName: '예약일',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'SendTime',
		headerName: '예약시간',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientName',
		headerName: '고객명',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'CellNumber',
		headerName: '전화번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { CellNumber } = params.row
			return <>{autoCellPhone(CellNumber)}</>
		},
	},
	{
		field: 'MmsMsg',
		headerName: '발송문자',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'RegUserID',
		headerName: '등록자',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'RegDate',
		headerName: '등록일',
		width: 130,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
]

const MessageResult = () => {
	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const navigate = useNavigate()

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const actionReset = () => {
		setCmpSearch({
			ChartNo: '',
			PatientName: '',
			CellNumber: '',
			StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			EndDate: moment().format('YYYY-MM-DD'),
			Status: 'A',
			SMSNumber: '',
			ScheduleType: 'A',
		})
	}

	const actionReset2 = () => {
		setResvSearch({
			ChartNo: '',
			PatientName: '',
			CellNumber: '',
			StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			EndDate: moment().format('YYYY-MM-DD'),
			Message: '',
			Status: '1',
		})
	}

	// 발송완료 조회
	const actionSearch = () => {
		console.log(cmpSearch)
		setGridCmpLoading(true)
		const request = {
			...cmpSearch,
			Status: cmpSearch.Status === 'A' ? '' : cmpSearch.Status,
			StartDate: cmpSearch.StartDate.replaceAll('-', '') + '000000',
			EndDate: cmpSearch.EndDate.replaceAll('-', '') + '235959',
			ScheduleType: cmpSearch.ScheduleType === 'A' ? '' : cmpSearch.ScheduleType,
		}
		console.log(request)
		apiSmsResultList(request)
			.then((res) => {
				console.log(res)
				setCmpRows(res)
			})
			.finally(() => {
				setGridCmpLoading(false)
			})
	}

	// 발송예약 조회
	const actionSearch2 = () => {
		setGridResvLoading(true)
		console.log(resvSearch)
		const request = {
			...resvSearch,
			startDate: resvSearch.StartDate.replaceAll('-', '') + '000000',
			endDate: resvSearch.EndDate.replaceAll('-', '') + '235959',
		}
		apiSmsReservationList(resvSearch)
			.then((res) => {
				console.log(res)
				setResvRows(res)
			})
			.finally(() => {
				setGridResvLoading(false)
			})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const onKeyUp2 = (e: any) => {
		if (e.keyCode === 13) actionSearch2()
	}

	const [tabValue, setTabValue] = useState(0)

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	const [disabledSearch, setDisabledSearch] = useState(false)
	const [dateRange, setDateRange] = useState('7d')
	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setCmpSearch({
			...cmpSearch,
			StartDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setCmpSearch({
			...cmpSearch,
			StartDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setCmpSearch({
			...cmpSearch,
			StartDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setCmpSearch({
				...cmpSearch,
				StartDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				console.log(target, moment(new Date(e)).format('yyyy-MM-DD'))

				setCmpSearch(() => {
					return {
						...cmpSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// 발송예약의 날짜 변경 이벤트
	const dateRanageOnChange2 = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setResvSearch({
				...resvSearch,
				StartDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setResvSearch(() => {
					return {
						...resvSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// 발송완료 조회 조건
	const [cmpSearch, setCmpSearch] = useState({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		EndDate: moment().format('YYYY-MM-DD'),
		Status: 'A',
		SMSNumber: '',
		ScheduleType: 'A',
	})

	// grid state
	const cmpRef = useGridApiRef()
	const [cmpRows, setCmpRows] = useState<GridRowModel[]>([])
	const [selectionCmpModel, setSelectionCmpModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridCmpLoading, setGridCmpLoading] = useState(false)
	const [gridCmpPageSize, setGridCmpPageSize] = useState(env.row ? env.row : 100)

	const resvRef = useGridApiRef()
	const [resvRows, setResvRows] = useState<GridRowModel[]>([])
	const [selectionResvModel, setSelectionResvModel] =
		useState<GridSelectionModel>([])
	const [gridResvLoading, setGridResvLoading] = useState(false)
	const [gridResvPageSize, setGridResvPageSize] = useState(
		env.row ? env.row : 100
	)

	// 발송예약 조회 조건
	const [resvSearch, setResvSearch] = useState({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		EndDate: moment().format('YYYY-MM-DD'),
		Message: '',
		Status: '1',
	})

	const actionDel = () => {
		// get selected rows in resv grid
		const selectedRows = valuesInIterator(resvRef.current?.getSelectedRows())
		console.log(selectedRows)
		selectedRows.forEach((element: any) => {
			element.RowCrud = 'D'
		})
		const request = {
			ClientID: userInfo.clientID,
			json: [...selectedRows],
		}
		confirmAlert(
			'발송을 취소하시겠습니까?',
			() => {
				apiSmsScheduleDelete(request).then((res) => {
					alertModal('삭제되었습니다.', 'success', () => {})
					actionSearch2()
				})
			},
			() => {}
		)
	}

	const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
		MstID: 0,
		ClientID: userInfo.clientID,
		IsActive: false,
		ClientName: '',
		IsNumberActive: false,
		SMSNumber: '',
	})

	const [smsSummery, setSmsSummery] = useState({
		Fail: 0,
		Reservation: 0,
		SmsCount: 0,
		Success: 0,
		Total: 0,
		MonthCount: 0,
	})
	const smsCount = () => {
		apiSmsCount().then((res) => setSmsSummery(res))
	}

	useEffect(() => {
		const request = {
			ClientID: userInfo.clientID,
		}
		apiClientSmsMstList(request).then((res) => {
			console.log(res)
			setSmsInfo(res[0])
			if (res[0].IsActive) {
				setCmpSearch({
					...cmpSearch,
					SMSNumber: res[0].SMSNumber,
				})
				actionSearch()
				actionSearch2()
				smsCount()
			} else {
				alertModal('설정된 SMS 서비스가 없습니다.', 'error', () => {
					navigate('/admin/test/result')
				})
			}
		})
	}, [])

	const [messageInfo, setMessageInfo] = useState({
		sendDate: '',
		sendTime: '',
		chartNo: '',
		patientName: '',
		message: '',
	})
	const [messagePopupIsOpen, setMessagePopupIsOpen] = useState(false)
	const closeMessagePopup = () => {
		setMessagePopupIsOpen(false)
		setMessageInfo({
			sendDate: '',
			sendTime: '',
			chartNo: '',
			patientName: '',
			message: '',
		})
	}

	return (
		<>
			<Dialog
				open={messagePopupIsOpen}
				onClose={closeMessagePopup}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							발송 메시지
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={closeMessagePopup}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<TableContainer>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell sx={{ backgroundColor: '#efefef' }}>발송일</TableCell>
									<TableCell>{messageInfo?.sendDate?.substr(0, 10)}</TableCell>
									<TableCell sx={{ backgroundColor: '#efefef' }}>발송시간</TableCell>
									<TableCell>{messageInfo?.sendTime}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ backgroundColor: '#efefef' }}>차트번호</TableCell>
									<TableCell>{messageInfo?.chartNo}</TableCell>
									<TableCell sx={{ backgroundColor: '#efefef' }}>수진자명</TableCell>
									<TableCell>{messageInfo?.patientName}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={4}>
										<span
											dangerouslySetInnerHTML={{
												__html: replaceBrTag(messageInfo?.message),
											}}
										></span>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="success"
						onClick={closeMessagePopup}
					>
						확인
					</Button>
				</DialogActions>
			</Dialog>
			<PageWrap style={{ marginBottom: '0.5rem' }}>
				<PageBg2>
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
						sx={{
							'@media (max-width: 900px': {
								display: 'none',
							},
						}}
					>
						<TitleText
							key="3"
							color="text.primary"
							sx={{ fontWeight: '500', fontSize: 17 }}
						>
							발송결과리스트
						</TitleText>
					</Breadcrumbs>
					<BtnBox>
						<Button
							size="small"
							color="success"
							variant="contained"
							startIcon={isMdUp && <RotateLeftIcon />}
							sx={{
								marginRight: '0.5rem',
								padding: isMdUp ? '4px 10px' : '4px 6px',
								minWidth: isMdUp ? '64px' : '50px',
							}}
							disabled={smsInfo.IsActive && smsInfo.IsNumberActive ? false : true}
							onClick={tabValue === 0 ? actionReset : actionReset2}
						>
							초기화
						</Button>
						<Button
							size="small"
							color="primary"
							variant="contained"
							startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
							sx={{
								marginRight: '0.5rem',
								padding: isMdUp ? '4px 10px' : '4px 6px',
								minWidth: isMdUp ? '64px' : '50px',
							}}
							disabled={smsInfo.IsActive && smsInfo.IsNumberActive ? false : true}
							onClick={tabValue === 0 ? actionSearch : actionSearch2}
						>
							검색
						</Button>
						<Button
							color="warning"
							size="small"
							variant="contained"
							startIcon={isMdUp && <DeleteForeverTwoToneIcon />}
							sx={{
								padding: isMdUp ? '4px 10px' : '4px 6px',
								minWidth: isMdUp ? '64px' : '50px',
							}}
							onClick={actionDel}
							disabled={
								tabValue === 0 || selectionResvModel.length === 0 ? true : false
							}
						>
							삭제
						</Button>
					</BtnBox>
				</PageBg2>
			</PageWrap>
			<PageBg>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: 'divider',
						mb: 2,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Tabs
						value={tabValue}
						onChange={handleChange}
						aria-label="메뉴 버튼"
					>
						<Tab
							label="발송완료"
							sx={{
								fontSize: '0.85rem',
								background: `${hexToRgba(theme.palette.primary.main, 0.15)}`,
								'&.Mui-selected': {
									background: `${hexToRgba(theme.palette.primary.main, 0.3)}`,
								},
							}}
							icon={<TaskAltIcon />}
						/>
						<Tab
							label="발송예약"
							sx={{
								fontSize: '0.85rem',
								background: `${hexToRgba(theme.palette.primary.main, 0.15)}`,
								'&.Mui-selected': {
									background: `${hexToRgba(theme.palette.primary.main, 0.3)}`,
								},
							}}
							icon={<AlarmOnIcon />}
						/>
					</Tabs>
					<div style={{ display: 'flex' }}>
						<Alert
							severity="info"
							sx={{ mr: 1 }}
						>
							<Typography variant="body1">
								{moment().format('MM')}월 발송 건수 : {smsSummery.Total} /{' '}
								{smsSummery.MonthCount > 0
									? smsSummery.MonthCount
									: smsSummery.SmsCount}
							</Typography>
						</Alert>
						<Alert
							severity="success"
							sx={{ mr: 1 }}
						>
							<Typography variant="body1">전송 성공 : {smsSummery.Success}</Typography>
						</Alert>
						<Alert
							severity="error"
							sx={{ mr: 1 }}
						>
							<Typography variant="body1">전송 실패 : {smsSummery.Fail}</Typography>
						</Alert>
						<Alert severity="info">
							<Typography variant="body1">
								전송 예약 : {smsSummery.Reservation}
							</Typography>
						</Alert>
					</div>
				</Box>
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					adapterLocale={locale}
				>
					{tabValue === 0 ? (
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="차트번호"
										size="small"
										value={cmpSearch.ChartNo}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, ChartNo: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="고객명"
										size="small"
										value={cmpSearch.PatientName}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, PatientName: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="전화번호"
										size="small"
										value={cmpSearch.CellNumber}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, CellNumber: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="발송구분"
										id="ScheduleType-select"
										select
										defaultValue="A"
										size="small"
										fullWidth
										onChange={(e) => {
											setCmpSearch({ ...cmpSearch, ScheduleType: e.target.value })
										}}
									>
										<MenuItem value="A">전체</MenuItem>
										<MenuItem value="1">예약</MenuItem>
										<MenuItem value="0">즉시전송</MenuItem>
									</TextField>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="SMS문장"
										size="small"
										value={cmpSearch.ChartNo}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, ChartNo: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="발송결과"
										id="status-select"
										select
										defaultValue="A"
										size="small"
										fullWidth
										onChange={(e) => {
											setCmpSearch({ ...cmpSearch, Status: e.target.value })
										}}
									>
										<MenuItem value="A">전체</MenuItem>
										<MenuItem value="1">성공</MenuItem>
										<MenuItem value="0">실패</MenuItem>
									</TextField>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="시작일"
										value={cmpSearch.StartDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'StartDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="종료일"
										value={cmpSearch.EndDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'EndDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Box sx={{ height: 'calc(100vh - 375px)', mt: 1 }}>
								<DataGridPremium
									apiRef={cmpRef}
									rows={cmpRows}
									getRowId={(row) => row.MsgID}
									columns={cmpColumns}
									pageSize={gridCmpPageSize}
									onPageSizeChange={(newPageSize) => setGridCmpPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									onRowClick={(params) => {
										setMessageInfo({
											sendDate: params.row.SendDate,
											sendTime: params.row.SendTime,
											chartNo: params.row.ChartNo,
											patientName: params.row.PatientName,
											message: params.row.MmsMsg,
										})
										setMessagePopupIsOpen(true)
									}}
									loading={gridCmpLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												MsgID: false,
												RowNum: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</Box>
					) : (
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp2}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="차트번호"
										size="small"
										value={resvSearch.ChartNo}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, ChartNo: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="고객명"
										size="small"
										value={resvSearch.PatientName}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, PatientName: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="전화번호"
										size="small"
										value={resvSearch.CellNumber}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, CellNumber: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="SMS문장"
										size="small"
										value={resvSearch.Message}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, Message: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="시작일"
										value={resvSearch.StartDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange2(newValue, 'StartDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="종료일"
										value={resvSearch.EndDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange2(newValue, 'EndDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Box sx={{ height: 'calc(100vh - 350px)', mt: 1 }}>
								<DataGridPremium
									apiRef={resvRef}
									rows={resvRows}
									getRowId={(row) => row.MsgID}
									columns={resvColumns}
									pageSize={gridResvPageSize}
									onPageSizeChange={(newPageSize) => setGridResvPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									checkboxSelection
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									selectionModel={selectionResvModel}
									onSelectionModelChange={(newSelection) => {
										setSelectionResvModel(newSelection)
									}}
									onRowClick={(params) => {
										setMessageInfo({
											sendDate: params.row.SendDate,
											sendTime: params.row.SendTime,
											chartNo: params.row.ChartNo,
											patientName: params.row.PatientName,
											message: params.row.MmsMsg,
										})
										setMessagePopupIsOpen(true)
									}}
									loading={gridResvLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												MsgID: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</Box>
					)}
				</LocalizationProvider>
			</PageBg>
		</>
	)
}

export default MessageResult
