import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, Typography, Breadcrumbs, Alert, AlertTitle } from '@mui/material'
import { styled } from '@mui/material/styles'

import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import { alertModal, confirmAlert, hexToRgba } from 'util/util'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import allLocales from '@fullcalendar/core/locales-all'

import { apiSmsSendAndReserveList, apiSmsScheduleDelete } from 'api/api'
import moment from 'moment'

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const Category = styled('div')(({ theme }) => ({
	display: 'flex',
	'& > .item': {
		marginRight: '1rem',
	},
}))

const Dot = styled('span')(({ theme }) => ({
	width: '8px',
	height: '8px',
	display: 'inline-block',
	borderRadius: '50%',
	'&.success': {
		backgroundColor: '#0000ff',
	},
	'&.fail': {
		backgroundColor: '#ff0000',
	},
	'&.reserved': {
		backgroundColor: '#00ff00',
	},
}))

const ResvCheck = () => {
	const userInfo = useSelector(selectuserInfo)

	const actionSearch = () => {
		// startDate and EndDate between this month
		let startDate = moment().startOf('month').format('YYYY-MM-DD')
		let endDate = moment().endOf('month').format('YYYY-MM-DD')

		startDate = startDate.replace(/-/gi, '')
		endDate = endDate.replace(/-/gi, '')
		startDate = startDate + '000000'
		endDate = endDate + '235959'

		const request = {
			ClientID: userInfo.clientID,
			StartDate: startDate,
			EndDate: endDate,
		}
		apiSmsSendAndReserveList(request).then((res) => {
			const arr: any = []
			res.forEach((element: any) => {
				const row = {
					title: element.PatientName + ' ' + element.CellNumber,
					date: element.Start,
					color: element.Color,
					msgID: element.MsgID,
					sendStatus: element.Status,
				}
				arr.push(row)
			})
			setEvents(arr)
		})
	}

	const [events, setEvents] = useState<any[]>([])

	const eventOnClick = (e: any) => {
		if (
			e.event?.extendedProps?.sendStatus === 'SCHEDULE' &&
			e.event?.extendedProps?.msgID
		) {
			confirmAlert(
				'발송을 취소하시겠습니까?',
				() => {
					const request = {
						MsgID: e.event?.extendedProps?.msgID,
						RowCrud: 'D',
					}
					const data = {
						json: [{ ...request }],
					}
					console.log(data)
					apiSmsScheduleDelete(data).then((res) => {
						alertModal('삭제되었습니다.', 'success', () => {})
						actionSearch()
					})
				},
				() => {}
			)
		}
	}

	useEffect(() => {
		actionSearch()
	}, [])

	return (
		<>
			<PageWrap style={{ marginBottom: '0.5rem' }}>
				<PageBg2>
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
						sx={{
							'@media (max-width: 900px': {
								display: 'none',
							},
						}}
					>
						<TitleText
							key="3"
							color="text.primary"
							sx={{ fontWeight: '500', fontSize: 17 }}
						>
							예약확인
						</TitleText>
					</Breadcrumbs>
					<BtnBox>
						<Category>
							<div className="item">
								<Dot className="success" /> 성공
							</div>
							<div className="item">
								<Dot className="fail" /> 실폐
							</div>
							<div className="item">
								<Dot className="reserved" /> 예약
							</div>
						</Category>
					</BtnBox>
				</PageBg2>
			</PageWrap>
			<PageBg>
				<Alert
					severity="warning"
					sx={{ mb: 1 }}
				>
					<AlertTitle>
						발송 예약건에 한해서 클릭 후 발송 취소가 가능합니다. 대량으로 취소해야 할
						경우 발송결과리스트 메뉴을 이용하세요.
					</AlertTitle>
				</Alert>
				<div>
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						locales={allLocales}
						locale={'ko'}
						initialView={'dayGridMonth'}
						headerToolbar={{
							start: 'today',
							center: 'title',
							end: 'prev,next',
						}}
						height={'85vh'}
						events={events}
						eventClick={(e) => eventOnClick(e)}
					/>
				</div>
			</PageBg>
		</>
	)
}

export default ResvCheck
