import {
	Grid,
	Typography,
	Table,
	Button,
	AppBar,
	Toolbar,
	IconButton,
	Fab,
	Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import moment from 'moment'
import 'moment/locale/ko'

import { selectuserInfo } from 'hooks/userInfo'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { covidData, prevReceptionItemList } from 'api/api'
import PrintIcon from '@mui/icons-material/Print'
import CloseIcon from '@mui/icons-material/Close'
import { replaceBrTag } from 'util/stringUtil'
import { PrevReceptionItemListType } from 'constants/types'
import A4BG from '../../assets/img/a4_bg.png'

const HistoryTable = styled('table')(({ theme }) => ({
	borderCollapse: 'collapse',
	width: '100%',
	margin: '5px 0 15px 0',
	th: {
		textAlign: 'left',
		backgroundColor: '#f0f9fe',
	},
	td: {
		backgroundColor: '#fff',
	},
	'td, th': {
		padding: '3px 10px',
		fontSize: '10px',
		verticalAlign: 'top',
		color: '#000',
		textAlign: 'center',
		border: '1px solid #ddd',
	},
	'td.noBorder': {
		border: '0',
	},
}))

const ArrowRight = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '9px',
	left: 0,
	width: 0,
	height: 0,
	borderTop: '5px solid transparent',
	borderLeft: '5px solid #000',
	borderRight: '5px solid none',
	borderBottom: '5px solid transparent',
}))

export interface SimpleDialogProps {
	open: boolean
	selectedValue: string
	onClose: (value: string) => void
}
type Nullable<T> = T | null

const CovidReport = () => {
	const dispatch = useDispatch()
	const userInfo = useSelector(selectuserInfo)

	const contentRef = useRef<any>([])

	const [receptionHeader, setReceptionHeader] = useState<any>({})
	const [receptionFooter, setReceptionFooter] = useState<any>({})
	const [receptionBody, setReceptionBody] = useState<any>({})
	const [prev, setPrev] = useState<any>([])
	// const [tableHeights, setTableHeights] = useState<number[]>([])
	const [chasoo, setChasoo] = useState<any>(1)
	const [isInflu, setIsInflu] = useState<boolean>(false)
	const [commentHtml, setCommentHtml] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [eGene, setEGene] = useState<string>('')
	const [rdrpGene, setRdrpGene] = useState<string>('')
	const [influA, setInfluA] = useState<string>('')
	const [rsv, setRsv] = useState<string>('')
	const [orf1ab, setOrf1ab] = useState<string>('')

	const [eGene2, setEGene2] = useState<string>('')
	const [rdrpGene2, setRdrpGene2] = useState<string>('')
	const [influA2, setInfluA2] = useState<string>('')
	const [rsv2, setRsv2] = useState<string>('')
	const [orf1ab2, setOrf1ab2] = useState<string>('')

	const textColor = (text: string) => {
		if (text?.toLowerCase().startsWith('positive')) return 'report-text-warning'
		return ''
	}

	const extractComment = (text: string) => {
		let comment: string = text || ''
		const regex: RegExp = /음성|양성/gi // 정규표현식을 사용해 "음성" 또는 "양성"을 찾는다.
		comment = comment.replace(regex, (match: string) => {
			if (match === '음성') {
				return '<strong style="font-size:18px;">' + match + '</strong>'
			} else if (match === '양성') {
				return '<strong style="color: red;font-size: 18px;">' + match + '</strong>'
			}
			return match
		})
		setCommentHtml(comment)
	}

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const receptionID = params.get('receptionID')
		const testCode = params.get('testCode') as string
		const ChartNo = params.get('chartNo') as string
		const PatientName = params.get('patientName') as string
		const LabCode = params.get('LabCode')
		const StaffType = params.get('StaffType')
		const clientID = params.get('clientID')

		const request = {
			receptionID,
			clientID,
			rptTypeCode: '',
			testCode,
			ChartNo,
			PatientName,
			LabCode,
			StaffType,
		}

		console.log(request)

		covidData(request)
			.then((res) => {
				console.log(res[0])
				extractComment(res[0]?.header?.Comment)
				setReceptionHeader(res[0].header)

				const footerFilter = res[0].footer.filter(
					(item: any) => item.StaffName !== '문영석'
				)

				setReceptionFooter(footerFilter)

				console.log(res[0].body)
				// console.log(res[0].receptionItemSelect)

				res[0]?.body?.forEach((item: any) => {
					if (item.RESULT_101 || item.RESULT_102) {
						setIsInflu(false)
						setChasoo(1)
						console.log(item)
						if (item.RESULT_101) setEGene(item.RESULT_101)
						if (item.RESULT_102) setRdrpGene(item.RESULT_102)
					}
					if (item.RESULT_103 || item.RESULT_104 || item.RESULT_105) {
						setIsInflu(true)
						setChasoo(1)
						if (item.RESULT_103) setInfluA(item.RESULT_103)
						if (item.RESULT_104) setOrf1ab(item.RESULT_104)
						if (item.RESULT_105) setRsv(item.RESULT_105)
					}
					if (item.RESULT_201 || item.RESULT_202) {
						setIsInflu(false)
						setChasoo(2)
						if (item.RESULT_201) setEGene2(item.RESULT_201)
						if (item.RESULT_202) setRdrpGene2(item.RESULT_202)
					}
					if (item.RESULT_203 || item.RESULT_204 || item.RESULT_205) {
						setIsInflu(true)
						setChasoo(2)
						if (item.RESULT_203) setInfluA2(item.RESULT_203)
						if (item.RESULT_204) setOrf1ab2(item.RESULT_204)
						if (item.RESULT_205) setRsv2(item.RESULT_205)
					}

					// const [eGene, setEGene] = useState<string>('')
					// const [influA, setInfluA] = useState<string>('')
					// const [rsv, setRsv] = useState<string>('')
					// const [orf1ab, setOrf1ab] = useState<string>('')
					// const [influB, setInfluB] = useState<string>('')
				})

				// setReceptionBody(res[0]?.body[0])
				// if (res[0]?.body[0]?.RESULT_101 || res[0]?.body[0]?.RESULT_102) {
				// 	setIsInflu(false)
				// 	setChasoo(1)
				// }
				// if (res[0]?.body[0]?.RESULT_201 || res[0]?.body[0]?.RESULT_202) {
				// 	setIsInflu(false)
				// 	setChasoo(2)
				// }
				// if (
				// 	res[0]?.body[0]?.RESULT_103 ||
				// 	res[0]?.body[0]?.RESULT_104 ||
				// 	res[0]?.body[0]?.RESULT_105
				// ) {
				// 	setIsInflu(true)
				// 	setChasoo(1)
				// }
				// if (
				// 	res[0]?.body[0]?.RESULT_203 ||
				// 	res[0]?.body[0]?.RESULT_204 ||
				// 	res[0]?.body[0]?.RESULT_205
				// ) {
				// 	setIsInflu(true)
				// 	setChasoo(2)
				// }
				const request: PrevReceptionItemListType = {
					clientID: userInfo.clientID,
					PatientName,
					ChartNo,
					TestCode: testCode,
					isChart: 1,
				}
				prevReceptionItemList(request).then((res) => {
					console.log(res)
					setPrev(res)
				})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	return (
		<>
			<AppBar
				sx={{ position: 'sticky', backgroundColor: '#323036' }}
				className="noPrint"
			>
				<Toolbar>
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="p"
					>
						리포트
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						onClick={() => {
							window.close()
						}}
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box
				className="noPrint"
				sx={{ position: 'fixed', top: 80, right: 16, zIndex: 1000 }}
			>
				<Fab
					color="error"
					aria-label="add"
					variant="extended"
					onClick={() => {
						window.print()
					}}
				>
					<PrintIcon sx={{ mr: 1 }} /> 프린트
				</Fab>
			</Box>
			{isLoading && (
				<div className="loadingSpinner">
					<div className="spinner">
						<CircularProgress color="secondary" />
					</div>
				</div>
			)}
			<div className="printBody">
				<div
					className="reportWrapper A4"
					id="reportWrapper"
				>
					<div
						className="reportInner sheet padding-10mm"
						style={{
							backgroundImage: `url(${A4BG})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: '100% 100%',
						}}
					>
						<div className="reportFlexTop oneSign">
							<header className="reportHeader">
								<Typography
									variant="body2"
									component="p"
									sx={{ textAlign: 'right' }}
								>
									{moment(new Date()).format('yyyy-MM-DD HH:mm:ss')}
								</Typography>
							</header>
							<table className="reportHeaderTable">
								<colgroup>
									<col style={{ width: '90px' }} />
									<col />
									<col />
									<col />
									<col style={{ width: '80px' }} />
									<col style={{ width: '130px' }} />
								</colgroup>
								<tbody>
									<tr>
										<th style={{ width: '100px' }}>의뢰기관명</th>
										<td>{receptionHeader?.ClientName}</td>
										<th style={{ width: '100px' }}>요양기관번호</th>
										<td>{receptionHeader?.MedicalNum}</td>
										<th style={{ width: '100px' }}>진료의</th>
										<td style={{ width: '100px' }}>{receptionHeader?.DoctorName}</td>
									</tr>
									<tr>
										<th style={{ width: '100px' }}>수진자명</th>
										<td>{receptionHeader?.PatientName}</td>
										<th style={{ width: '100px' }}>생년월일</th>
										<td>{receptionHeader?.SocialNumber}</td>
										<th style={{ width: '100px' }}>채취일</th>
										<td>{receptionHeader?.CollectedDate}</td>
									</tr>
									<tr>
										<th style={{ width: '100px' }}>차트번호</th>
										<td>{receptionHeader?.ChartNo}</td>
										<th style={{ width: '100px' }}>성별/나이</th>
										<td>{receptionHeader?.ReceptionUserInfo}</td>
										<th style={{ width: '100px' }}>의뢰일</th>
										<td>{receptionHeader?.ReceptionDate}</td>
									</tr>
									<tr>
										<th style={{ width: '100px' }}>검체종류</th>
										<td>{receptionHeader?.SpecimenName}</td>
										<th style={{ width: '100px' }}>진료과/병동</th>
										<td>{receptionHeader?.WardInfo}</td>
										<th style={{ width: '100px' }}>결과보고일</th>
										<td>{receptionHeader?.LastStateDate}</td>
									</tr>
								</tbody>
							</table>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Typography
									variant="overline"
									component="h6"
									sx={{
										fontSize: '11px',
										position: 'relative',
										paddingLeft: '10px',
										textTransform: 'none',
									}}
								>
									<ArrowRight />
									<strong>검사명</strong>
									{receptionHeader?.TestName}
								</Typography>
								{!isInflu && receptionHeader?.InsuranceCode && (
									<Typography
										variant="overline"
										component="h6"
										sx={{
											fontSize: '11px',
											position: 'relative',
											paddingLeft: '10px',
										}}
									>
										<ArrowRight />
										<strong>보험코드</strong>
										{receptionHeader?.InsuranceCode}
									</Typography>
								)}
							</div>
							{!isInflu && (
								<table
									className="reportInfoTable"
									id="resultTable"
									style={{ marginTop: '0' }}
								>
									<colgroup>
										<col style={{ width: '70px' }} />
										<col />
										<col />
										<col />
										<col />
									</colgroup>
									<thead>
										<tr>
											<th>보험코드</th>
											<th>검사항목</th>
											<th>결과</th>
											<th>검사항목</th>
											<th>결과</th>
										</tr>
									</thead>
									<tbody>
										{!isInflu && chasoo === 1 && (
											// <tr>
											// 	<td>{receptionHeader?.InsuranceCode}</td>
											// 	<td>E gene</td>
											// 	<td className={`${textColor(receptionBody?.RESULT_101)}`}>
											// 		{receptionBody?.RESULT_101}
											// 	</td>
											// 	<td>RdRp gene</td>
											// 	<td className={`${textColor(receptionBody?.RESULT_102)}`}>
											// 		{receptionBody?.RESULT_102}
											// 	</td>
											// </tr>
											<tr>
												<td>{receptionHeader?.InsuranceCode}</td>
												<td>E gene</td>
												<td className={`${textColor(eGene)}`}>{eGene}</td>
												<td>RdRp gene</td>
												<td className={`${textColor(rdrpGene)}`}>{rdrpGene}</td>
											</tr>
										)}
										{!isInflu && chasoo === 2 && (
											// <tr>
											// 	<td>{receptionHeader?.InsuranceCode}</td>
											// 	<td>E gene</td>
											// 	<td className={`${textColor(receptionBody?.RESULT_201)}`}>
											// 		{receptionBody?.RESULT_201}
											// 	</td>
											// 	<td>RdRp gene</td>
											// 	<td className={`${textColor(receptionBody?.RESULT_202)}`}>
											// 		{receptionBody?.RESULT_202}
											// 	</td>
											// </tr>
											<tr>
												<td>{receptionHeader?.InsuranceCode}</td>
												<td>E gene</td>
												<td className={`${textColor(eGene2)}`}>{eGene2}</td>
												<td>RdRp gene</td>
												<td className={`${textColor(rdrpGene2)}`}>{rdrpGene2}</td>
											</tr>
										)}
									</tbody>
								</table>
							)}
							{isInflu && (
								<>
									{receptionHeader?.InsuranceCode && (
										<p style={{ textAlign: 'right', fontSize: '12px', margin: 0 }}>
											보험코드 : {receptionHeader?.InsuranceCode}
										</p>
									)}
									<table
										className="reportInfoTable"
										id="resultTable"
										style={{ marginTop: '0' }}
									>
										<colgroup>
											<col />
											<col />
											<col />
											<col />
											<col />
											<col />
										</colgroup>
										<thead>
											<tr>
												<th colSpan={2}>Covid-19</th>
												<th colSpan={2}>Influenza</th>
												<th colSpan={2}>RSV</th>
											</tr>
											<tr>
												<th>검사항목</th>
												<th>결과</th>
												<th>검사항목</th>
												<th>결과</th>
												<th>검사항목</th>
												<th>결과</th>
											</tr>
										</thead>
										<tbody>
											{chasoo === 1 && (
												<>
													<tr>
														<td>E gene</td>
														<td className={`${textColor(eGene)}`}>{eGene}</td>
														<td>Influenza A</td>
														<td className={`${textColor(influA)}`}>{influA}</td>
														<td>RSV</td>
														<td className={`${textColor(rsv)}`}>{rsv}</td>
													</tr>
													<tr>
														<td>ORF1ab</td>
														<td className={`${textColor(rdrpGene)}`}>{rdrpGene}</td>
														<td>Influenza B</td>
														<td className={`${textColor(influA)}`}>{influA}</td>
														<td></td>
														<td></td>
													</tr>
													{/* <tr>
														<td>E gene</td>
														<td className={`${textColor(receptionBody?.RESULT_101)}`}>
															{receptionBody?.RESULT_101}
														</td>
														<td>Influenza A</td>
														<td className={`${textColor(receptionBody?.RESULT_103)}`}>
															{receptionBody?.RESULT_103}
														</td>
														<td>RSV</td>
														<td className={`${textColor(receptionBody?.RESULT_105)}`}>
															{receptionBody?.RESULT_105}
														</td>
													</tr>
													<tr>
														<td>ORF1ab</td>
														<td>필드 확인</td>
														<td>Influenza B</td>
														<td className={`${textColor(receptionBody?.RESULT_104)}`}>
															{receptionBody?.RESULT_104}
														</td>
														<td></td>
														<td></td>
													</tr> */}
												</>
											)}

											{/* if (item.RESULT_101) setEGene(item.RESULT_101)
							if (item.RESULT_102) setRdrpGene(item.RESULT_102)
						}
						if (item.RESULT_103 || item.RESULT_104 || item.RESULT_105) {
							setIsInflu(true)
							setChasoo(1)
							if (item.RESULT_103) setInfluA(item.RESULT_103)
							if (item.RESULT_104) setOrf1ab(item.RESULT_104)
							if (item.RESULT_105) setRsv(item.RESULT_105) */}
											{chasoo === 2 && (
												<>
													<tr>
														<td>E gene</td>
														<td className={`${textColor(eGene2)}`}>{eGene2}</td>
														<td>Influenza A</td>
														<td className={`${textColor(influA2)}`}>{influA2}</td>
														<td>RSV</td>
														<td className={`${textColor(rsv2)}`}>{rsv2}</td>
													</tr>
													<tr>
														<td>ORF1ab</td>
														<td className={`${textColor(rdrpGene2)}`}>{rdrpGene2}</td>
														<td>Influenza B</td>
														<td className={`${textColor(influA2)}`}>{influA2}</td>
														<td></td>
														<td></td>
													</tr>
													{/* <tr>
														<td>E gene</td>
														<td className={`${textColor(receptionBody?.RESULT_201)}`}>
															{receptionBody?.RESULT_201}
														</td>
														<td>Influenza A</td>
														<td className={`${textColor(receptionBody?.RESULT_203)}`}>
															{receptionBody?.RESULT_203}
														</td>
														<td>RSV</td>
														<td className={`${textColor(receptionBody?.RESULT_205)}`}>
															{receptionBody?.RESULT_205}
														</td>
													</tr>
													<tr>
														<td>ORF1ab</td>
														<td>필드 확인</td>
														<td>Influenza B</td>
														<td className={`${textColor(receptionBody?.RESULT_204)}`}>
															{receptionBody?.RESULT_204}
														</td>
														<td></td>
														<td></td>
													</tr> */}
												</>
											)}
										</tbody>
									</table>
								</>
							)}
							<Typography
								variant="overline"
								component="h6"
								sx={{
									fontSize: '11px',
									position: 'relative',
									paddingLeft: '10px',
									marginBottom: '15px',
									textTransform: 'none',
								}}
							>
								<ArrowRight />
								<strong>Remark</strong>
								{receptionHeader?.Comment && (
									<p
										style={{ fontSize: '10px', lineHeight: '1.3', marginBottom: '10px' }}
										dangerouslySetInnerHTML={{
											__html: commentHtml,
										}}
									></p>
								)}
								<p
									style={{ fontSize: '10px', lineHeight: '1.3' }}
									dangerouslySetInnerHTML={{
										__html: replaceBrTag(receptionHeader?.Remark),
									}}
								></p>
							</Typography>
							<Typography
								variant="overline"
								component="h6"
								sx={{
									fontSize: '11px',
									position: 'relative',
									paddingLeft: '10px',
								}}
							>
								<ArrowRight />
								<strong>History</strong>
							</Typography>
							<HistoryTable>
								<colgroup>
									<col style={{ width: '100px' }} />
									<col style={{ width: '100px' }} />
									<col />
								</colgroup>
								<thead>
									<tr>
										<th>검사의뢰일</th>
										<th>구분</th>
										<th>HISTORY</th>
									</tr>
								</thead>
								<tbody>
									{prev.map((row: any, index: number) => (
										<tr key={index}>
											<td>{row?.ReceptionDate}</td>
											<td>{row?.RequestType}</td>
											<td>
												{row?.ResultData && '결과 : ' + row.ResultData + ' '}
												{row?.Decision && '판정 : ' + row.Decision + ' '}
												{row?.Reference && '참고치 : ' + row.Reference}
											</td>
										</tr>
									))}
									{prev.length === 0 && <></>}
								</tbody>
							</HistoryTable>
						</div>
						<footer className="reportFooter">
							<div className="reportFooterInner">
								{receptionFooter[0]?.StaffName && <p>전문의</p>}
								<ul className="reportSignArea">
									<li>
										{receptionFooter[0]?.StaffName}
										{receptionFooter[0]?.StaffSign && (
											<img
												src={`data:image/jpeg;base64,${receptionFooter[0]?.StaffSign}`}
												alt={receptionFooter[0]?.StaffName}
												className="reportSignImg"
											/>
										)}
									</li>
								</ul>
							</div>
						</footer>
					</div>
				</div>
			</div>
		</>
	)
}

export default CovidReport
