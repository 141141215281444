import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	ButtonGroup,
	AppBar,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Toolbar,
	CardActions,
	CardHeader,
	Tab,
	Menu,
} from '@mui/material'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridDensity,
	useGridApiRef,
	GridRenderEditCellParams,
	GridToolbarContainer,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
	GridRowModesModel,
	GridRowsProp,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { alertModal, confirmAlert, hexToRgba } from 'util/util'
import SaveAsTwoTone from '@mui/icons-material/SaveAsTwoTone'

import {
	apiSMSGroupList,
	apiClientSmsMstList,
	apiSmsTemplateMstList,
	apiSmsTemplateSlvList,
	apiSmsTemplateMstSave,
	apiSmsTemplateSlvSave,
	apiTemplateGroupList,
} from 'api/api'
import ClientSearchPopup from 'views/test/clientSearchPopup'

import Divider from '@mui/material/Divider'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import { valuesInIterator } from 'util/objectUtil'
import TransitionEffect from 'components/TransitionEffect'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import React from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'

import { replaceBrTag } from 'util/stringUtil'
import { SmsTemplateTypes } from 'constants/types'

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const ExampleBox = styled(Box)(({ theme }) => ({
	marginTop: '1rem',
	'& .exampleImg': {
		display: 'block',
		maxWidth: '100%',
		margin: '2rem auto',
	},
}))

const SmsTemplate = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const navigate = useNavigate()

	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const [isSmsUser, setIsSmsUser] = useState(false)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(false)

	const [categoryList, setCategoryList] = useState<any[]>([])

	const [search, setSearch] = useState({
		ClientID: userInfo.infoType === 'C' ? userInfo.clientID : 0,
		ChartNo: '',
		PatientName: '',
	})
	const [slvSearch, setSlvSearch] = useState({
		ClientID: search.ClientID,
		MstID: '',
		Category: '',
	})

	const actionAdd = () => {
		// 추가된 행이 있는지 확인
		let cnt = 0
		// slvRows.forEach((row) => {
		// 	if (row.RowCrud === 'C') cnt++
		// })

		if (cnt > 0) {
			alertModal(
				'추가된 행을 저장 후 다시 추가하세요.<br/>등록할 고객이 많을 경우 엑셀 업로드를 이용하세요.',
				'error',
				() => {}
			)
			return
		}

		// setSlvRows((prevRows) => [
		// 	{
		// 		SlvID: Math.round(Math.random() * 99999999),
		// 		ChartNo: '',
		// 		PatientName: '',
		// 		CellNumber: '',
		// 		Memo: '',
		// 		RowCrud: 'C',
		// 		MstID: selectionMstModel[0],
		// 	},
		// 	...prevRows,
		// ])
	}

	const actionSearch = () => {
		console.table(search)
		setGridMstLoading(true)
		apiSmsTemplateMstList(search)
			.then((res) => {
				console.log(res)
				setMstRows(res)
				setClientList(res)
			})
			.finally(() => {
				setGridMstLoading(false)
			})
	}

	const actionReset = () => {}

	const actionSlvSearch = () => {
		// apiSMSSlvList(slvSearch).then((res) => setSlvRows(res))
	}

	const [tabName, setTabName] = useState<string>('')
	const tabChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabName(newValue)
		const arr: any[] = orgSlvList.filter(
			(item: any) => item.Category === newValue
		)
		setSlvList(arr)
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) {
			setSlvSearch({
				...slvSearch,
				ClientID: search.ClientID,
			})
			const MstID = selectionMstModel[0]
			console.log(MstID)
			const request = {
				...slvSearch,
				ClientID: search.ClientID,
				MstID: MstID,
			}
			// apiSMSSlvList(request).then((res) => setSlvRows(res))
		}
	}

	const [clientList, setClientList] = useState<any[]>([])

	const [selectedDropdown, setSelectedDropdown] = useState<any>(null)

	// grid state
	const mstRef = useGridApiRef()
	const [mstRows, setMstRows] = useState<GridRowModel[]>([])
	const [selectionMstModel, setSelectionMstModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	const onMstRowClick = (e: GridRowModel) => {
		setSlvSearch({
			...slvSearch,
			ClientID: search.ClientID,
			MstID: e.row.MstID,
		})
		const request = {
			...slvSearch,
			ClientID: search.ClientID,
			MstID: e.row.MstID,
		}
		console.table(request)

		let tmpCategory = ''
		apiTemplateGroupList(request).then((res) => {
			console.log(res)
			if (res[0]?.Category) {
				setTabName(res[0]?.Category)
				tmpCategory = res[0]?.Category
				setTabName(res[0]?.Category)
			}

			setCategoryList(res)
			apiSmsTemplateSlvList(request).then((res) => {
				console.log(res)
				console.log(tabName)

				if (res.length > 0) {
					setOrgSlvList(res)

					setTimeout(() => {
						// row를 클릭했을때 탭과 맞춰야해서 필터링한다
						const arr: any[] = res.filter(
							(item: any) => item.Category === tmpCategory
						)
						setSlvList(arr)
					}, 100)
				} else {
					setOrgSlvList([])
					setSlvList([])
				}
			})
		})
	}

	const [groupName, setGroupName] = useState('')

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			ClientID: e.ClientID,
		})
		setClientDialogOpen(false)
		const request = {
			...search,
			ClientID: e.ClientID,
		}
		apiSMSGroupList(request).then((res) => {
			console.log(res)
			setMstRows(res)
			setClientList(res)
		})
	}

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [editRows, setEditRows] = useState<GridRowsProp>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		setEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}

	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionMstModel((prevState) => [...rows])
	}

	const actionMstAdd = () => {
		let isNotSave = false
		mstRows.forEach((row) => {
			if (row.RowCrud === 'C') isNotSave = true
		})
		if (isNotSave) {
			alertModal('추가된 행을 저장 후 다시 추가하세요.', 'error', () => {})
			return
		}

		setMstRows((prevRows) => [
			{
				MstID: Math.round(Math.random() * 99999999),
				DepartName: '',
				RowCrud: 'C',
			},
			...prevRows,
		])
	}
	const actionMstDel = () => {
		const json = mstRef.current.getSelectedRows()
		const valuesArray = valuesInIterator(json)
		console.log(valuesArray)

		if (orgSlvList.length > 0) {
			alertModal(
				'하위그룹 목록이 있을 경우 하위 그룹을 삭제 후 다시 시도하세요.',
				'error',
				() => {}
			)
			return
		}

		// C 인거는 그냥 삭제
		valuesArray.forEach((element: any) => {
			if (element.RowCrud === 'C') {
				setMstRows((prevRows) => {
					return prevRows.filter((row) => row.MstID !== element.MstID)
				})
			} else {
				element.RowCrud = 'D'
			}
		})
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...valuesArray],
				}
				apiSmsTemplateMstSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						actionSearch()
					})
				})
			},
			() => {}
		)
	}

	const actionMstSave = () => {
		const json = mstRef.current.getSelectedRows()
		const valuesArray = valuesInIterator(json)
		console.log(valuesArray)
		console.log(mstRows)
		let isBlankDepartName = false
		valuesArray.forEach((element: any) => {
			if (element.DepartName.trim() === '') isBlankDepartName = true
		})
		if (isBlankDepartName) {
			alertModal('그룹명을 입력하세요.', 'error', () => {})
			return
		}
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [...valuesArray],
				}
				console.log(data)
				apiSmsTemplateMstSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setSelectionMstModel([])
						actionSearch()
					})
				})
			},
			() => {}
		)
	}

	const onMstStateChange = () => {}

	const [slvList, setSlvList] = useState<any[]>([])
	const [orgSlvList, setOrgSlvList] = useState<any[]>([])

	const MstGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div />
				<ButtonGroup>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionMstAdd}
					>
						추가
					</Button>
					<Button
						variant="contained"
						color="warning"
						size="small"
						startIcon={<SaveAsTwoTone />}
						onClick={actionMstDel}
						disabled={selectionMstModel.length === 0 || orgSlvList.length > 0}
					>
						삭제
					</Button>
					<Button
						variant="contained"
						color="success"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionMstSave}
						disabled={selectionMstModel.length === 0}
					>
						저장
					</Button>
				</ButtonGroup>
			</GridToolbarContainer>
		)
	}

	useEffect(() => {
		if (!userInfo.isSmsActive) {
			alertModal('권한이 없습니다', 'error', () => navigate('/admin/test/result'))
		}
		const request = {
			ClientID: userInfo.infoType === 'C' ? userInfo.clientID : 0,
		}
		apiClientSmsMstList(request).then((res) => {
			console.log(res)
			const result = res[0]
			console.log(result.IsActive)
			setIsSmsUser(result.IsActive === 1 ? true : false)
			actionSearch()
		})
	}, [])

	const [templateDialogOpen, setTemplateDialogOpen] = useState(false)
	const onTemplateDialogClose = () => {
		setTemplateDialogOpen(false)
	}
	const [templateItem, setTemplateItem] = useState<SmsTemplateTypes>({
		SlvID: 0,
		ClientID: 0,
		MstID: 0,
		Category: '',
		CategoryText: '',
		Message: '',
		RegID: '',
		RegDttm: '',
		UpdID: '',
		UpdDttm: '',
		RowCrud: '',
	})

	const addTemplateItem = () => {
		if (!slvSearch.MstID) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		setTemplateItem({
			...templateItem,
			MstID: parseInt(slvSearch.MstID),
			RowCrud: 'C',
		})
		setTemplateDialogOpen(true)
	}

	const modifyTemplateItem = (item: SmsTemplateTypes) => {
		item.RowCrud = 'U'
		setTemplateItem(item)
		setTemplateDialogOpen(true)
	}

	// 메시지 탬플릿 저장
	const templateItemSave = () => {
		if (templateItem.MstID === 0 || templateItem.MstID === undefined) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (templateItem.Message?.trim() === '') {
			alertModal('메시지 내용을 입력하세요.', 'error', () => {})
			return
		}
		// if (
		// 	templateItem.Category === '' ||
		// 	templateItem.CategoryText?.trim() === ''
		// ) {
		// 	alertModal('카테고리를 입력하세요.', 'error', () => {})
		// 	return
		// }
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				const data = {
					ClientID: '',
					json: [
						{
							...templateItem,
							ClientID: '',
							Category: templateItem.CategoryText
								? templateItem.CategoryText
								: templateItem.Category,
						},
					],
				}
				console.log(data)
				apiSmsTemplateSlvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setTemplateDialogOpen(false)
						setTemplateItem({
							SlvID: 0,
							ClientID: 0,
							MstID: 0,
							Category: '',
							CategoryText: '',
							Message: '',
							RegID: '',
							RegDttm: '',
							UpdID: '',
							UpdDttm: '',
							RowCrud: '',
						})
						apiTemplateGroupList({ ClientID: '', MstID: slvSearch.MstID }).then(
							(res) => {
								console.log(res)
								setCategoryList(res)

								apiSmsTemplateSlvList(slvSearch).then((res) => {
									setOrgSlvList(res)
									const arr: any[] = res.filter((item: any) => item.Category === tabName)
									setSlvList(arr)
								})
							}
						)
					})
				})
			},
			() => {}
		)
	}

	const templateItemDel = (item: SmsTemplateTypes) => {
		console.log(item)
		if (item.MstID === 0 || item.MstID === undefined) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (item.SlvID === 0 || item.SlvID === undefined) {
			alertModal('삭제할 항목을 선택하세요.', 'error', () => {})
			return
		}
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				const data = {
					ClientID: item.ClientID,
					json: [
						{
							...item,
							ClientID: item.ClientID,
							RowCrud: 'D',
						},
					],
				}
				apiSmsTemplateSlvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setTemplateDialogOpen(false)
						setTemplateItem({
							SlvID: 0,
							ClientID: 0,
							MstID: 0,
							Category: '',
							CategoryText: '',
							Message: '',
							RegID: '',
							RegDttm: '',
							UpdID: '',
							UpdDttm: '',
							RowCrud: '',
						})
						apiTemplateGroupList({ ClientID: '', MstID: slvSearch.MstID }).then(
							(res) => {
								console.log(res)
								setCategoryList(res)

								apiSmsTemplateSlvList(slvSearch).then((res) => {
									setOrgSlvList(res)
									const arr: any[] = res.filter((item: any) => item.Category === tabName)
									setSlvList(arr)
								})
							}
						)
					})
				})
			},
			() => {}
		)
	}

	// 각 CardHeader에 대한 메뉴 열림 상태를 관리하는 state 배열
	const [openMenus, setOpenMenus] = useState<boolean[]>([])

	// useRef를 사용하여 각각의 CardHeader에 대한 참조를 만듭니다.
	const menuRefs = React.useRef<HTMLButtonElement[]>([])
	const handleMenuOepn = (
		event: React.MouseEvent<HTMLButtonElement>,
		index: number
	) => {
		menuRefs.current[index] = event.currentTarget

		setOpenMenus((prevOpenMenus) => {
			const newOpenMenus = [...prevOpenMenus]
			newOpenMenus[index] = true
			return newOpenMenus
		})
	}
	const handleClose = () => {
		setOpenMenus(() => menuRefs.current.map(() => false))
	}

	return (
		<>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Dialog
				open={templateDialogOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar sx={{ position: 'sticky' }}>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							메시지 템플릿 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={onTemplateDialogClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: '1rem',
						}}
					>
						<TextField
							label="카테고리"
							select
							fullWidth
							size="small"
							sx={{ pr: 0.5 }}
							onChange={(e) => {
								setTemplateItem({
									...templateItem,
									Category: e.target.value,
								})
							}}
						>
							<MenuItem>선택하세요.</MenuItem>
							{categoryList.map((item) => (
								<MenuItem
									key={item.RowNum}
									value={item.Category}
								>
									{item.Category}
								</MenuItem>
							))}
						</TextField>
						<TextField
							label="카테고리"
							size="small"
							fullWidth
							sx={{ pl: 0.5 }}
							helperText="좌측의 카테고리에 적합한 분류가 없을떼 입력하세요."
							onChange={(e) => {
								setTemplateItem({
									...templateItem,
									CategoryText: e.target.value,
								})
							}}
						/>
					</div>
					<TextField
						multiline
						rows={6}
						fullWidth
						label="메시지 내용"
						value={templateItem.Message}
						onChange={(e) => {
							setTemplateItem({
								...templateItem,
								Message: e.target.value,
							})
						}}
					></TextField>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={templateItemSave}
					>
						저장
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={onTemplateDialogClose}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								SMS 문자 설정
							</TitleText>
						</Breadcrumbs>
						<BtnBox />
					</PageBg2>
				</PageWrap>
				{/* <Box
					component="div"
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<Grid
						container
						spacing={1}
					>
						<Grid
							container
							spacing={0}
						>
							<Grid
								item
								md={3}
								sx={{ px: 2 }}
							>
								{(userInfo?.infoType === 'W' || userInfo?.infoType === 'S') && (
									<Grid item>
										<TextField
											label="거래처"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											aria-readonly={true}
											value={searchClientName}
											onClick={clientPopupOpenAction}
										/>
									</Grid>
								)}
							</Grid>
							<Grid
								item
								md={9}
							></Grid>
						</Grid>
					</Grid>
				</Box> */}
			</Stack>
			<Paper
				elevation={0}
				sx={{
					mt: 2,
				}}
			>
				<Grid
					container
					spacing={0}
				>
					<Grid
						item
						md={3}
						sx={{ p: 2 }}
					>
						<Box>
							<Typography
								variant="h6"
								sx={{ lineHeight: 1, m: 0, pt: 1.5, pb: 3.3 }}
							>
								그룹 목록
							</Typography>
						</Box>
						<Box sx={{ height: 'calc(100vh - 295px)' }}>
							<DataGridPremium
								rows={mstRows}
								columns={[
									{
										field: 'MstID',
										headerName: 'ID',
										type: 'number',
										width: 80,
										editable: false,
									},
									{
										field: 'RowNum',
										headerName: '번호',
										type: 'number',
										width: 50,
										editable: false,
									},
									{
										field: 'DepartName',
										headerName: '그룹명',
										type: 'string',
										flex: 1,
										editable: true,
									},
									{
										field: 'RowCrud',
										headerName: 'CRUD',
										type: 'string',
										minWidth: 50,
										editable: false,
										headerAlign: 'center',
									},
								]}
								// checkboxSelection
								disableMultipleSelection
								getRowId={(row) => row.MstID}
								apiRef={mstRef}
								onRowClick={onMstRowClick}
								pageSize={gridMstPageSize}
								onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								pagination
								autoHeight={false}
								localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
								experimentalFeatures={{ newEditingApi: true }}
								rowModesModel={rowModesModel}
								onRowEditStart={handleRowEditStart}
								onRowEditStop={handleRowEditStop}
								onCellEditStop={onCellEditStop}
								onStateChange={onMstStateChange}
								processRowUpdate={processRowUpdate}
								onSelectionModelChange={selectChangeHandler}
								selectionModel={selectionMstModel}
								components={{
									Toolbar: MstGridToolbar,
									LoadingOverlay: LinearProgress,
								}}
								loading={gridMstLoading}
								density={(env.desnse as GridDensity) || 'compact'}
								initialState={{
									columns: {
										columnVisibilityModel: {
											RowCrud: false,
											MstID: false,
										},
									},
								}}
								hideFooterSelectedRowCount
								sx={{
									'& .MuiDataGrid-columnHeaderTitleContainer': {
										fontSize: '13px',
									},
									'& .MuiDataGrid-cell': {
										fontSize: '13px !important',
										borderRight:
											theme.palette.mode === 'dark'
												? '1px solid rgba(81, 81, 81, 1)'
												: '1px solid rgba(224, 224, 224, 1)',
									},
								}}
							/>
						</Box>
					</Grid>
					<Grid
						item
						md={9}
						sx={{ p: 2 }}
					>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid xs={6} />
								<Grid
									item
									xs={6}
									sx={{ textAlign: 'right' }}
								>
									<Button
										variant="contained"
										color="primary"
										startIcon={<AddCircleTwoToneIcon />}
										onClick={addTemplateItem}
										disabled={!slvSearch.MstID}
									>
										추가
									</Button>
								</Grid>
								{/* <Grid
									item
									xs={6}
								>
									<TextField
										label="카테고리"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										sx={{ mb: 2 }}
										value={slvSearch.Category}
										onChange={(e) => {
											setSlvSearch({
												...slvSearch,
												Category: e.target.value,
											})
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Button
														variant="contained"
														color="primary"
														sx={{ transform: 'translateX(0.8rem)' }}
														onClick={actionSlvSearch}
														disabled={selectionMstModel.length === 0}
													>
														<ContentPasteSearchTwoToneIcon sx={{ mr: 1 }} />
														검색
													</Button>
												</InputAdornment>
											),
										}}
									/>
								</Grid> */}
							</Grid>
						</Box>
						<TabContext value={tabName}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
								<TabList
									onChange={tabChange}
									aria-label="lab API tabs example"
								>
									{categoryList.map((item) => {
										return (
											<Tab
												label={item.Category}
												value={item.Category}
												key={item.RowNum}
											/>
										)
									})}
								</TabList>
							</Box>
						</TabContext>
						{/* <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
							<ButtonGroup>
								{categoryList.map((item) => {
									return (
										<Button
											key={item.RowNum}
											variant="outlined"
											color="primary"
											size="small"
										>
											{item.Category}
										</Button>
									)
								})}
							</ButtonGroup>
						</Box> */}
						<Box sx={{ height: 'calc(100vh - 325px)', overflowY: 'auto' }}>
							<Grid
								container
								spacing={2}
							>
								{slvList &&
									slvList.map((item: any, index: number) => {
										return (
											<Grid
												item
												xs={6}
												md={3}
												key={item.SlvID}
											>
												<Card sx={{ maxWidth: 345, height: 210 }}>
													<CardHeader
														title={item.Category}
														subheader={item.RegDttm}
														action={
															<>
																<IconButton
																	aria-label="settings"
																	onClick={(e) => handleMenuOepn(e, index)}
																>
																	<MoreVertIcon />
																</IconButton>
																<Menu
																	id={`simple-menu-${item.SlvID}`}
																	anchorEl={menuRefs.current[index]}
																	keepMounted
																	open={openMenus[index]}
																	onClose={handleClose}
																>
																	<MenuItem onClick={(e) => modifyTemplateItem(item)}>
																		수정
																	</MenuItem>
																	<MenuItem onClick={(e) => templateItemDel(item)}>
																		삭제
																	</MenuItem>
																</Menu>
															</>
														}
													/>
													<CardContent
														sx={{
															backgroundColor:
																theme.palette.mode === 'dark' ? '#333' : '#EEF9FD',
														}}
													>
														<Typography
															variant="body2"
															color="text.secondary"
															sx={{ height: 90, overflow: 'auto' }}
															dangerouslySetInnerHTML={{ __html: replaceBrTag(item.Message) }}
														/>
													</CardContent>
													{/* <CardActions disableSpacing>
														<Box
															sx={{
																display: 'flex',
																justifyContent: 'space-between',
																width: '100%',
															}}
														>
															<Button
																variant="contained"
																color="warning"
																size="small"
																startIcon={<SaveAsTwoTone />}
																onClick={(e) => templateItemDel(item)}
															>
																삭제
															</Button>
															<Button
																variant="contained"
																color="secondary"
																size="small"
																startIcon={<SaveAsTwoTone />}
																onClick={(e) => modifyTemplateItem(item)}
															>
																수정
															</Button>
														</Box>
													</CardActions> */}
												</Card>
											</Grid>
										)
									})}
								{slvList.length === 0 && (
									<Typography
										variant="body2"
										color="text.secondary"
										sx={{ mt: 3 }}
									>
										데이터가 없습니다.
									</Typography>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default SmsTemplate
