import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpEHealth: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'CHARTNO',
		headerName: '등록번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '적용일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: 'Description',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'DOCTORID',
		headerName: '의사',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '과',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'USERID',
		headerName: '입력자',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'ACPTTIME',
		headerName: '입력시간',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'ACPTDD',
		headerName: '입력일',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '환자명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'INSUCD',
		headerName: '보험코드',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'DOCTOR',
		headerName: '의사명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '생년월일',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'GSTATE',
		headerName: '임신주수',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'WEGIHT',
		headerName: '체중',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'CNT',
		headerName: '체중',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
