import { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Alert,
	Stack,
	Grid,
	Paper,
	Snackbar,
	Box,
	Button,
	Switch,
	TextField,
	FormControlLabel,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	LinearProgress,
	Select,
	AlertProps,
	SelectChangeEvent,
	Slide,
	useMediaQuery,
	Tooltip,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridApi,
	GridDensity,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import moment from 'moment'
import 'moment/locale/ko'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { alertModal, compareDate, isValidDateType } from 'util/util'
import { selectuserInfo } from 'hooks/userInfo'
import TestItemSearch from 'components/TestItemSearch'
import { PrevReceptionSearchType } from 'constants/types'
import { receptionSelectByItem } from 'api/api'
import { TransitionProps } from '@mui/material/transitions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { replaceBrTag } from 'util/stringUtil'
import { getSettings } from 'helpers/storage'
import ClientSearchPopup from './clientSearchPopup'
import ArrowUp from '../../assets/img/ArrowUp.svg'
import ArrowDown from '../../assets/img/ArrowDown.svg'
import ArrowBoth from '../../assets/img/ArrowBoth.svg'

const locales = ['ko'] as const

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	)
})

const TestItem = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const env = getSettings()

	const gridRef = useGridApiRef()

	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')
	// 사용자 정보
	const userInfo = useSelector(selectuserInfo)
	// 공통코드 redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		console.log('-----------------')
		console.log(search)
		console.log('-----------------')

		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			search.clientID === ''
		) {
			alertModal('고객사를 선택해주세요.', 'error', () => {})
			return
		}

		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(search.startDate).isBefore('2023-08-01')) {
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
		}
		// if (moment(search.endDate).isBefore('2023-08-01')) {
		// 	setSearch({
		// 		...search,
		// 		endDate: '2023-08-10',
		// 	})
		// }

		const request = {
			...search,
			searchTestCd: search.searchTestCd === '0' ? '' : search.searchTestCd,
			startDate: moment(search.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: search.startDate,
			endDate: search.endDate,
		}

		// const request = {
		// 	...search,
		// 	searchTestCd: search.searchTestCd === '0' ? '' : search.searchTestCd,
		// 	startDate: search.startDate,
		// 	endDate: search.endDate,
		// }

		setGridLoading(true)
		receptionSelectByItem(request)
			.then((res) => {
				console.log(res)
				setGridRows(res)
			})
			.finally(() => {
				setGridLoading(false)
				gridRef.current?.setQuickFilterValues([])
				gridRef.current?.setPage(0)
			})
	}
	const actionReset = () => {
		setSearch({
			clientID: userInfo.infoType === 'C' ? userInfo.clientID : '',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			searchTestCd: '',
			patientName: '',
			chartNo: '',
			receptionRegNum: '',
			socialNumber: '',
			testCodes: '',
			searchType: 'R',
		})
		setTestNames('')
		setDateRange('7d')
		setSearchClientName('')
		gridRef.current?.setQuickFilterValues([])
		gridRef.current?.setPage(0)
	}

	// 검색 조건
	const [dateRange, setDateRange] = useState('7d')
	const [search, setSearch] = useState<PrevReceptionSearchType>({
		clientID: userInfo.infoType === 'C' ? userInfo.clientID : '',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		searchTestCd: '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		socialNumber: '',
		testCodes: '',
		searchType: 'R',
	})
	const [testNames, setTestNames] = useState<string>('')
	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	// 조회조건 공통코드 변경 이벤트
	const searchCmdOnChange = (e: SelectChangeEvent<string>) => {
		setSearch({
			...search,
		})
	}

	const searchWorkCodeOnChange = (e: SelectChangeEvent<string>) => {
		setSearch({
			...search,
		})
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch((prevState) => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	/**
	 * 검체종류 select
	 * @param e
	 */
	const searchTestCdOnChange = (e: SelectChangeEvent<string>): void => {
		setSearch({
			...search,
			searchTestCd: e.target.value,
		})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	// grid state
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)
	const [gridPage, setGridPage] = useState(0)
	const [gridRowCount, setGridRowCount] = useState(0)

	// grid row click event
	const onRowClick = (e: GridRowModel) => {}

	const [showSearchPopup, setShowSearchPopup] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)

	const popupOpenAction = () => {
		setShowSearchPopup(true)
		setDialogOpen(true)
	}

	const dialogClose = () => {
		setDialogOpen(false)
	}

	/**
	 * 팝업에서 검사항목 선택시 처리
	 * @param arr
	 */
	const popupSubmit = (arr: []) => {
		setDialogOpen(false)
		const testCodes: string[] = arr.map((item: any) => item.TestCode)
		const testNames = arr.map((item: any) => item.DisplayName)

		const testCodesToString = testCodes.map((item) => item).join(',')

		setSearch({
			...search,
			testCodes: testCodesToString,
		})
		setTestNames(testNames.join(', '))
	}

	const [commentOpen, setCommentOpen] = useState(false)
	const [commentText, setCommentText] = useState('')

	// 소견 팝업
	const showComment = (e: string) => {
		if (e) {
			setCommentText(e)
			setCommentOpen(true)
		}
	}

	const commentClose = () => {
		setCommentText('')
		setCommentOpen(false)
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	// 검사항목 삭제 버튼 처리
	const handleDelete = () => {
		setTestNames('')
		setSearchClientName('')
		if (userInfo.infoType === 'S') {
			setSearch({
				...search,
				clientID: '',
				testCodes: '',
			})
		} else {
			setSearch({
				...search,
				testCodes: '',
			})
		}
	}

	// 그리드 판정 색상
	const renderText = (decision: string) => {
		// decision remove empty space
		decision = decision !== undefined ? decision.replace(/\s/g, '') : ''

		if (decision === undefined || decision === '') {
			return theme.palette.mode === 'dark' ? '#ffffff' : '#000000'
		}
		// if (decision !== undefined && decision === 'L') {
		// 	return '#0288d1' // blue
		// } else if (
		// 	(decision !== undefined && decision === 'H') ||
		// 	(decision !== undefined && decision === 'P')
		// ) {
		// 	return '#FF5722' // red
		// }
		if (decision !== undefined && decision === 'L') {
			return '#0288d1' // blue
		} else {
			return '#FF5722' // red
		}
	}

	useEffect(() => {
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		// 검체 종류의 셀렉트박스 기초 value
		setSearch({
			...search,
			searchTestCd: '0',
		})
	}, [])

	return (
		<>
			<Dialog
				fullScreen={isMdUp ? false : true}
				open={commentOpen}
				onClose={commentClose}
				TransitionComponent={Transition}
				maxWidth="lg"
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar variant="dense">
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="div"
						>
							소견
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							onClick={commentClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<p dangerouslySetInnerHTML={{ __html: replaceBrTag(commentText) }}></p>
				</DialogContent>
			</Dialog>
			<TestItemSearch
				dialogOpen={dialogOpen}
				dialogClose={dialogClose}
				popupSubmit={popupSubmit}
			/>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack
				sx={{
					marginBottom: '1rem',
					position: 'sticky',
					top: 120,
					left: 0,
					right: 0,
					zIndex: 1,
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '55px',
						top: '0px',
					},
				}}
			>
				<PageBg>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onKeyUp={onKeyUp}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={2}
									lg={3}
								>
									<FormControl fullWidth>
										<InputLabel id="searchType-select-label">기간 유형</InputLabel>
										<Select
											labelId="searchType-select-label"
											id="searchTypee-simple-select"
											value={search.searchType}
											label="기간 유형"
											onChange={(e) => {
												setSearch({
													...search,
													searchType: e.target.value,
												})
											}}
											size={'small'}
										>
											<MenuItem value={'R'}>접수일자</MenuItem>
											<MenuItem value={'D'}>보고일자</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									<DatePicker
										label="시작일"
										value={search.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									<DatePicker
										label="종료일"
										value={search.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									<FormControl fullWidth>
										<InputLabel id="date-range-select-label">조회 기간</InputLabel>
										<Select
											labelId="date-range-select-label"
											id="date-range-simple-select"
											value={dateRange}
											label="조회 기간"
											onChange={searchDateRanageOnChange}
											size={'small'}
										>
											<MenuItem value={'0'}>선택하세요.</MenuItem>
											<MenuItem value={'7d'}>7일</MenuItem>
											<MenuItem value={'15d'}>15일</MenuItem>
											<MenuItem value={'1m'}>1개월</MenuItem>
											<MenuItem value={'3m'}>3개월</MenuItem>
											<MenuItem value={'6m'}>6개월</MenuItem>
											<MenuItem value={'1y'}>1년</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								{/* <Grid
									item
									xs={6}
									sm={2}
									lg={2}
								>
									<TextField
										label="접수번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.receptionRegNum}
										onChange={(e) => {
											setSearch({
												...search,
												receptionRegNum: e.target.value,
											})
										}}
									/>
								</Grid> */}
								{/* <Grid
									item
									xs={6}
									sm={2}
									lg={2}
								>
									<TextField
										label="차트번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.chartNo}
										onChange={(e) => {
											setSearch({
												...search,
												chartNo: e.target.value,
											})
										}}
									/>
								</Grid> */}
							</Grid>
							<Grid
								container
								spacing={1}
								sx={{ marginTop: '0.1rem' }}
							>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									<FormControl fullWidth>
										<InputLabel id="date-range-select-label">학부(파트)</InputLabel>
										<Select
											labelId="date-range-select-label"
											id="date-range-simple-select"
											label="학부(파트)"
											value={search.searchTestCd}
											onChange={searchTestCdOnChange}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											{testCdList &&
												testCdList.length > 0 &&
												testCdList.map(
													(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
														<MenuItem
															key={item.id}
															value={item?.cmmnCd}
														>
															{item?.cmmnNm}
														</MenuItem>
													)
												)}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									lg={3}
								>
									<TextField
										label="수진자명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.patientName}
										onChange={(e) => {
											setSearch({
												...search,
												patientName: e.target.value,
											})
										}}
									/>
								</Grid>
								{/* <Grid
									item
									xs={6}
									sm={2}
									lg={2}
								>
									<TextField
										label="생년월일"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.socialNumber}
										onChange={(e) => {
											setSearch({
												...search,
												socialNumber: e.target.value,
											})
										}}
									/>
								</Grid> */}
								<Grid
									item
									xs={12}
									sm={6}
									lg={6}
									// sm={userInfo.infoType === 'S' ? 6 : 9}
									// lg={userInfo.infoType === 'S' ? 6 : 9}
								>
									<div style={{ display: 'flex' }}>
										<TextField
											label="검사항목"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											aria-readonly={true}
											value={testNames}
											onClick={popupOpenAction}
										/>
										<Button
											type="button"
											variant="contained"
											sx={{ width: '100px', ml: 1 }}
											onClick={handleDelete}
										>
											항목 삭제
										</Button>
									</div>
								</Grid>
								{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
									<Grid
										item
										xs={12}
										sm={3}
										lg={3}
									>
										<TextField
											label="거래처"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											aria-readonly={true}
											value={searchClientName}
											onClick={clientPopupOpenAction}
										/>
									</Grid>
								)}
							</Grid>
						</LocalizationProvider>
					</Box>
				</PageBg>
			</Stack>
			<PageBg style={{ marginTop: '1rem' }}>
				<Box>
					<Typography
						variant="subtitle1"
						component="h3"
					>
						검사항목별 조회리스트
					</Typography>
					<Box
						sx={{
							width: '100%',
							overflow: 'auto',
							'@media (max-width: 1199px)': {
								// height: '600px',
							},
						}}
					>
						<DataGridPremium
							apiRef={gridRef}
							rows={gridRows}
							getRowId={(row) => row.rowNum}
							columns={[
								{
									field: 'rowNum',
									headerName: '번호',
									type: 'string',
									width: 50,
									editable: false,
								},
								{
									field: 'ReceptionID',
									headerName: '접수번호',
									type: 'string',
									width: 90,
									editable: false,
								},
								{
									field: 'ReceptionDate',
									headerName: '접수일자',
									type: 'string',
									width: 90,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'ReceptionRegNum',
									headerName: '접수번호',
									type: 'string',
									width: 75,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'PatientName',
									headerName: '수진자명',
									type: 'string',
									width: 80,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'ChartNo',
									headerName: '차트번호',
									type: 'string',
									width: 90,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'ClientInfo',
									headerName: '성별/나이',
									type: 'string',
									width: 70,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'InsuranceCode',
									headerName: '보험코드',
									type: 'string',
									width: 100,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'TestName',
									headerName: '검사명',
									type: 'string',
									width: 300,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'SpecimenName',
									headerName: '검체',
									type: 'string',
									width: 130,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'ResultData',
									headerName: '결과',
									type: 'string',
									width: 130,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										const ResultData: string = params.row?.ResultData as string
										if (
											ResultData &&
											ResultData.length > 20 &&
											params?.row?.StateName === '최종보고'
										) {
											return (
												<Button
													variant="contained"
													color="primary"
													size="small"
													style={{ width: '100%' }}
													onClick={() => showComment(ResultData)}
												>
													결과
												</Button>
											)
										} else {
											return (
												<Typography
													variant="body2"
													sx={{
														color: renderText(Decision),
													}}
												>
													{params?.row?.StateName === '최종보고' ? params.value : ''}
												</Typography>
											)
										}
									},
								},
								{
									field: 'Comment',
									headerName: '소견',
									type: 'string',
									width: 80,
									editable: false,
									renderCell: (params) => {
										let comment: string = params.row?.Comment as string
										comment = comment !== undefined ? comment.replace(/\s/g, '') : ''
										return (
											comment && (
												<Button
													variant="contained"
													color="primary"
													size="small"
													style={{ width: '100%' }}
													onClick={() => showComment(comment)}
												>
													소견
												</Button>
											)
										)
									},
								},
								{
									field: 'Decision',
									headerName: 'H/L',
									type: 'string',
									width: 130,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'Reference',
									headerName: '참고치',
									type: 'string',
									width: 130,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'LastStateDate',
									headerName: '보고일시',
									type: 'string',
									width: 130,
									editable: false,
									renderCell: (params) => {
										const Decision: string = params.row.Decision as string
										return (
											<Typography
												variant="body2"
												sx={{
													color: renderText(Decision),
												}}
											>
												{params.value}
											</Typography>
										)
									},
								},
								{
									field: 'StateName',
									headerName: '상태',
									type: 'string',
									width: 80,
									editable: false,
									renderCell: (params) => {
										if (params?.row?.StateName === '재검진행') {
											return (
												<span
													style={{
														background: '#e67e22',
														padding: '1px 3px',
														border: '1px solid #e67e22',
														color: '#fff',
														width: '100%',
														display: 'block',
														textAlign: 'center',
													}}
												>
													재검
												</span>
											)
										} else if (params?.row?.StateName === '최종보고') {
											return (
												<span
													style={{
														color: '#000',
														background: '#55efc4',
														padding: '1px 3px',
														border: '1px solid rgba(255, 255, 255, 0.3)',
														width: '100%',
														display: 'block',
														textAlign: 'center',
													}}
												>
													최종보고
												</span>
											)
										} else if (params?.row?.StateName === '검사진행') {
											return (
												<span
													style={{
														color: '#fff',
														background: '#74b9ff',
														padding: '1px 3px',
														border: '1px solid rgba(255, 255, 255, 0.3)',
														width: '100%',
														display: 'block',
														textAlign: 'center',
													}}
												>
													검사진행
												</span>
											)
										} else {
											return (
												<span
													style={{
														background: '#fff',
														padding: '1px 3px',
														border: '1px solid #000',
														color: '#000',
														width: '100%',
														display: 'block',
														textAlign: 'center',
													}}
												>
													{params?.row?.StateName}
												</span>
											)
										}
									},
								},
							]}
							pageSize={gridPageSize}
							onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={true}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									excelOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 250 },
								},
							}}
							loading={gridLoading}
							selectionModel={gridSelectionModel}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										rowNum: false,
										ReceptionID: false,
									},
								},
							}}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</Box>
			</PageBg>
		</>
	)
}

export default TestItem
