export const headerOcsByungcom = [
	{
		field: 'SEQ',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '수진자명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '처방일자',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 50,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'BED',
		headerName: '병실명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'DOCTOR',
		headerName: '담당의',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료실',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '명칭',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '검사ID',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'cmmnNm',
		headerName: '상태',
		type: 'string',
		width: 70,
		editable: false,
	},
]
