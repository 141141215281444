import moment from 'moment'
import 'moment/locale/ko'

/**
 * 날짜 포맷 변환
 * @param {*} value
 * @returns
 */
export const dateTimeFormatter = (value: string) => {
  if (value) {
    return moment(value).format('yyyy-MM-DD HH:mm:ss')
  }
  return null
}

/**
 * 통화 포맷 변환
 * param : currencyFormatter.format(value)
 * @param {*} value
 * @returns
 */
export const currencyFormatter = (value: string) => {
  if (value) {
    return new Intl.NumberFormat('ko-KR', {
      style: 'currency',
      currency: 'KRW',
    })
  }
}
