import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	InputAdornment,
	ButtonGroup,
	Alert,
	AppBar,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Toolbar,
	AlertTitle,
	Table,
	TableRow,
	TableBody,
	TableCell,
	List,
	ListItem,
	CardHeader,
	Menu,
	CardActions,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridDensity,
	useGridApiRef,
	GridRenderEditCellParams,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
	GridRowModesModel,
	GridRowsProp,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
	alertModal,
	autoCellPhone,
	confirmAlert,
	hexToRgba,
	isEmpty,
	validCellPhone,
} from 'util/util'
import SaveAsTwoTone from '@mui/icons-material/SaveAsTwoTone'

import {
	apiSMSGroupPrvList,
	apiSMSGroupPrvSave,
	apiSMSSlvPrvList,
	apiSMSSlvPrvSave,
	apiSlavePrvCopy,
	apiClientSmsMstList,
	apiSmsTemplateMstList,
	apiSmsTemplateSlvList,
	apiSmsTemplateSlvSave,
	apiSmsTemplateMstSave,
} from 'api/api'
import ClientSearchPopup from 'views/test/clientSearchPopup'

import Divider from '@mui/material/Divider'
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone'
import BackupTwoToneIcon from '@mui/icons-material/BackupTwoTone'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import { valuesInIterator } from 'util/objectUtil'
import { read, utils } from 'xlsx'
import TransitionEffect from 'components/TransitionEffect'
import CloseIcon from '@mui/icons-material/Close'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useDropzone } from 'react-dropzone'
import Img1 from '../../assets/img/sms_example.png'
import { useNavigate } from 'react-router-dom'

import FolderIcon from '@mui/icons-material/Folder'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import Delete from '@mui/icons-material/Delete'
import DeleteForeverTwoTone from '@mui/icons-material/DeleteForeverTwoTone'
import OfflineShareIcon from '@mui/icons-material/OfflineShare'
import { replaceBrTag } from 'util/stringUtil'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { SmsTemplateTypes } from 'constants/types'

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const ExampleBox = styled(Box)(({ theme }) => ({
	marginTop: '1rem',
	'& .exampleImg': {
		display: 'block',
		maxWidth: '100%',
		margin: '2rem auto',
	},
}))

const Template = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const navigate = useNavigate()

	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const [isSmsUser, setIsSmsUser] = useState(false)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(true)
	const [disabledReset, setDisabledReset] = useState(false)

	const [search, setSearch] = useState({
		ClientID: userInfo.clientID,
		ChartNo: '',
		PatientName: '',
	})
	const [slvSearch, setSlvSearch] = useState({
		ClientID: search.ClientID,
		MstID: '',
		ChartNo: '',
		PatientName: '',
	})

	const actionAdd = () => {}

	const actionSearch = () => {}

	const actionReset = () => {}

	const onKeyUp = (e: any) => {}

	const [clientList, setClientList] = useState<any[]>([])

	const [selectedDropdown, setSelectedDropdown] = useState<any>(null)

	// grid state
	const mstRef = useRef<any[]>([])
	const [mstRows, setMstRows] = useState<GridRowModel[]>([])
	const [selectionMstModel, setSelectionMstModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	const onMstRowClick = (e: GridRowModel) => {}

	const [groupName, setGroupName] = useState('')

	const slvRef = useGridApiRef()
	const [slvRows, setSlvRows] = useState<GridRowModel[]>([])
	const [selectionSlvModel, setSelectionSlvModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridSlvLoading, setGridSlvLoading] = useState(false)
	const [gridSlvPageSize, setGridSlvPageSize] = useState(env.row ? env.row : 100)

	const onSlvRowClick = (e: GridRowModel) => {}

	const actionSlvSave = () => {}

	const actionSlvDelete = () => {}

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [editRows, setEditRows] = useState<GridRowsProp>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		setEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}

	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionMstModel((prevState) => [...rows])
	}

	const actionMstAdd = () => {}
	const actionMstDel = () => {}

	const [isEmptyList, setIsEmptyList] = useState(false)

	// 그룹 목록에서 input을 클릭 했을때
	const onMstItemClick = (MstID: number, DepartName: string) => {
		setSelectedMstID(MstID)
		setSelectedCategoryName(DepartName)
		const request = {
			ClientID: userInfo.clientID,
			Category: '',
			MstID: MstID,
		}
		apiSmsTemplateSlvList(request).then((res) => {
			console.log(res)
			setSlvRows(res)
			if (res.length === 0) {
				setIsEmptyList(true)
			} else {
				setIsEmptyList(false)
			}
		})
	}

	const [selectedMstID, setSelectedMstID] = useState<number>(0)
	const [selectedCategoryName, setSelectedCategoryName] = useState<string>('')
	const mstRowRef = useRef<any[]>([])
	const [mstGroupName, setMstGroupName] = useState('')
	const actionMstSearch = () => {
		apiSmsTemplateMstList(userInfo.clientID).then((res) => {
			console.log(res)
			const result = res.filter((item: any) => item.DepartCode === 'CUST')
			setMstRows(result)
		})
	}
	const actionMstSave = () => {
		if (isEmpty(mstGroupName)) {
			alertModal('그룹명을 입력해 주세요', 'error', () => {})
			return
		}
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [
						{
							DepartName: mstGroupName,
							RowCrud: 'C',
						},
					],
				}
				console.log(data)
				apiSmsTemplateMstSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setMstGroupName('')
						actionMstSearch()
					})
				})
			},
			() => {}
		)
	}
	const actionMstUpdate = (e: any, MstID: number, DepartName: string) => {
		if (isEmpty(DepartName)) {
			alertModal('그룹명을 입력해 주세요', 'error', () => {})
			return
		}
		confirmAlert(
			'수정 하시겠습니까?',
			() => {
				const data = {
					ClientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.ClientID
							: userInfo.clientID,
					json: [
						{
							DepartName: DepartName,
							MstID: MstID,
							RowCrud: 'U',
						},
					],
				}
				console.log(data)
				apiSmsTemplateMstSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setMstGroupName('')
						actionMstSearch()
					})
				})
			},
			() => {}
		)
	}
	const actionMstDelete = (e: any, MstID: number) => {
		const request = {
			ClientID: userInfo.clientID,
			Category: '',
			MstID: MstID,
		}
		apiSmsTemplateSlvList(request).then((res) => {
			if (res.length > 0) {
				alertModal(
					'그룹에 등록된 항목이 있습니다. 등록된 항목을 모두 삭제한 후 다시 시도하세요.',
					'error',
					() => {}
				)
				return
			} else if (res.length === 0) {
				confirmAlert(
					'삭제 하시겠습니까?',
					() => {
						const data = {
							ClientID:
								userInfo.infoType === 'S' || userInfo.infoType === 'W'
									? search.ClientID
									: userInfo.clientID,
							json: [
								{
									DepartName: mstGroupName,
									MstID: MstID,
									RowCrud: 'D',
								},
							],
						}
						console.log(data)
						apiSmsTemplateMstSave(data).then((res) => {
							alertModal(res.result, 'success', () => {
								setMstGroupName('')
								actionMstSearch()
							})
						})
					},
					() => {}
				)
			}
		})
	}

	const onMstStateChange = () => {}

	const [rowSlvModesModel, setRowSlvModesModel] = useState<GridRowModesModel>({})
	const [slvSelectionModel, setSlvSelectionModel] = useState<GridSelectionModel>(
		[]
	)
	const [editSlvRows, setSlvEditRows] = useState<GridRowsProp>([])

	const processSlvRowUpdate = useCallback(async (newRow: GridRowModel) => {
		if (newRow.RowCrud === 'R') {
			newRow.RowCrud = 'U'
		}
		return newRow
	}, [])

	const selectSlvChangeHandler = (rows: GridSelectionModel) => {
		setSelectionSlvModel((prevState) => [...rows])
	}
	const handleSlvRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
	}

	const handleSlvRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
	}

	const onSlvCellEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		const { row } = params
		setSlvEditRows((prevState) => {
			return [
				...prevState,
				{ ...row, RowCrud: row.RowCrud === 'R' ? 'U' : row.RowCrud },
			]
		})
	}

	const onSlvStateChange = () => {}

	const openExcelPopup = () => {
		setUploadOpen(true)
		setFileObject(null)
	}

	const setCloseDialog = () => {
		setUploadOpen(false)
		setFileObject(null)
	}

	const [uploadOpen, setUploadOpen] = useState(false)
	const [fileObject, setFileObject] = useState<File | null>(null)
	const [fileInfo, setFileInfo] = useState({
		fileName: '',
		orgFileName: '',
	})
	const [tmpIsDuplicate, setTmpIsDuplicate] = useState(false)
	const [tmpDataList, setTmpDataList] = useState<any[]>([])

	const [excelMstID, setExcelMstID] = useState('0')

	// 각 CardHeader에 대한 메뉴 열림 상태를 관리하는 state 배열
	const [openMenus, setOpenMenus] = useState<boolean[]>([])

	// useRef를 사용하여 각각의 CardHeader에 대한 참조를 만듭니다.
	const menuRefs = React.useRef<HTMLButtonElement[]>([])
	const handleMenuOepn = (
		event: React.MouseEvent<HTMLButtonElement>,
		index: number
	) => {
		menuRefs.current[index] = event.currentTarget

		setOpenMenus((prevOpenMenus) => {
			const newOpenMenus = [...prevOpenMenus]
			newOpenMenus[index] = true
			return newOpenMenus
		})
	}
	const handleClose = () => {
		setOpenMenus(() => menuRefs.current.map(() => false))
	}

	const [templateDialogOpen, setTemplateDialogOpen] = useState(false)
	const onTemplateDialogClose = () => {
		setTemplateDialogOpen(false)
	}
	const [templateItem, setTemplateItem] = useState<SmsTemplateTypes>({
		SlvID: 0,
		ClientID: 0,
		MstID: 0,
		Category: '',
		CategoryText: '',
		Message: '',
		RegID: '',
		RegDttm: '',
		UpdID: '',
		UpdDttm: '',
		RowCrud: '',
	})

	const addTemplateItem = () => {
		if (selectedMstID === 0 || selectedMstID === undefined) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		setTemplateItem({
			...templateItem,
			MstID: selectedMstID,
			Category: selectedCategoryName,
			RowCrud: 'C',
		})
		setTemplateDialogOpen(true)
	}

	const modifyTemplateItem = (item: any) => {
		item.RowCrud = 'U'
		setTemplateItem(item)
		setTemplateDialogOpen(true)
	}

	// 메시지 탬플릿 저장
	const templateItemSave = () => {
		console.table(templateItem)
		if (selectedMstID === 0 || selectedMstID === undefined) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (selectedCategoryName === '' || selectedCategoryName === undefined) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (templateItem.Message?.trim() === '') {
			alertModal('메시지 내용을 입력하세요.', 'error', () => {})
			return
		}
		confirmAlert(
			'저장하시겠습니까?',
			() => {
				const data = {
					ClientID: '',
					json: [
						{
							...templateItem,
							ClientID: userInfo.clientID,
							Category: templateItem.CategoryText
								? templateItem.CategoryText
								: templateItem.Category,
						},
					],
				}
				console.log(data)
				apiSmsTemplateSlvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setTemplateDialogOpen(false)
						setTemplateItem({
							SlvID: 0,
							ClientID: 0,
							MstID: 0,
							Category: '',
							CategoryText: '',
							Message: '',
							RegID: '',
							RegDttm: '',
							UpdID: '',
							UpdDttm: '',
							RowCrud: '',
						})
						const request = {
							ClientID: userInfo.clientID,
							Category: '',
							MstID: selectedMstID,
						}
						apiSmsTemplateSlvList(request).then((res) => {
							setSlvRows(res)
							res.length > 0 ? setIsEmptyList(false) : setIsEmptyList(true)
						})
					})
				})
			},
			() => {}
		)
	}

	const templateItemDel = (item: any) => {
		console.log(item)
		if (item.MstID === 0 || item.MstID === undefined) {
			alertModal('그룹을 선택하세요.', 'error', () => {})
			return
		}
		if (item.SlvID === 0 || item.SlvID === undefined) {
			alertModal('삭제할 항목을 선택하세요.', 'error', () => {})
			return
		}
		confirmAlert(
			'삭제하시겠습니까?',
			() => {
				const data = {
					ClientID: item.ClientID,
					json: [
						{
							...item,
							ClientID: item.ClientID,
							RowCrud: 'D',
						},
					],
				}
				apiSmsTemplateSlvSave(data).then((res) => {
					alertModal(res.result, 'success', () => {
						setTemplateDialogOpen(false)
						setTemplateItem({
							SlvID: 0,
							ClientID: 0,
							MstID: 0,
							Category: '',
							CategoryText: '',
							Message: '',
							RegID: '',
							RegDttm: '',
							UpdID: '',
							UpdDttm: '',
							RowCrud: '',
						})
						const request = {
							ClientID: userInfo.clientID,
							Category: '',
							MstID: selectedMstID,
						}
						apiSmsTemplateSlvList(request).then((res) => {
							setSlvRows(res)
							res.length > 0 ? setIsEmptyList(false) : setIsEmptyList(true)
						})
					})
				})
			},
			() => {}
		)
	}

	const SlvGridToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div>
					<GridToolbarColumnsButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarFilterButton
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarDensitySelector
						nonce={undefined}
						onResize={undefined}
						onResizeCapture={undefined}
					/>
					<GridToolbarExport />
				</div>
				<ButtonGroup>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<AddCircleTwoToneIcon />}
						onClick={actionAdd}
						disabled={selectedMstID === 0}
					>
						추가
					</Button>
					<Button
						variant="contained"
						color="warning"
						size="small"
						startIcon={<SaveAsTwoTone />}
						onClick={actionSlvDelete}
						disabled={selectionSlvModel.length === 0}
					>
						삭제
					</Button>
					<Button
						variant="contained"
						color="success"
						size="small"
						startIcon={<SaveAsTwoTone />}
						onClick={actionSlvSave}
						disabled={selectedMstID === 0 || selectionSlvModel.length === 0}
					>
						저장
					</Button>
				</ButtonGroup>
			</GridToolbarContainer>
		)
	}

	useEffect(() => {
		if (!userInfo.isSmsActive) {
			alertModal('권한이 없습니다', 'error', () => navigate('/admin/test/result'))
		}
		if (userInfo?.infoType === 'C') {
			const request = {
				ClientID: userInfo.clientID,
			}
			apiClientSmsMstList(request).then((res) => {
				console.log(res)
				const result = res[0]
				console.log(result.IsActive)
				setIsSmsUser(result.IsActive === 1 ? true : false)
				actionSearch()
			})
			actionMstSearch()
		}
	}, [])

	return (
		<>
			<Dialog
				open={templateDialogOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar sx={{ position: 'sticky' }}>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							메시지 템플릿 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={onTemplateDialogClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<TextField
						multiline
						rows={6}
						fullWidth
						label="메시지 내용"
						value={templateItem.Message}
						onChange={(e) => {
							setTemplateItem({
								...templateItem,
								Message: e.target.value,
							})
						}}
					></TextField>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={templateItemSave}
					>
						저장
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={onTemplateDialogClose}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								문자 메시지 템플릿
							</TitleText>
						</Breadcrumbs>
						<BtnBox />
					</PageBg2>
				</PageWrap>
			</Stack>
			<Paper
				elevation={0}
				sx={{
					mt: 2,
				}}
			>
				<Grid
					container
					spacing={0}
				>
					<Grid
						item
						md={3}
						sx={{ p: 2 }}
					>
						<Box>
							<Typography
								variant="h6"
								sx={{ lineHeight: 1, m: 0, pt: 1.5, pb: 3.3 }}
							>
								그룹 목록
							</Typography>
						</Box>
						<Box sx={{ height: 'calc(100vh - 325px)' }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									mb: 1,
								}}
							>
								<div style={{ flex: 1 }}>
									<TextField
										size="small"
										fullWidth
										label="그룹명"
										value={mstGroupName}
										onChange={(e) => {
											setMstGroupName(e.target.value)
										}}
									/>
								</div>
								<Button
									variant="contained"
									color="primary"
									disabled={mstGroupName.length === 0}
									sx={{ ml: 1 }}
									onClick={actionMstSave}
								>
									<SaveAsTwoTone sx={{ mr: 1 }} />
									추가
								</Button>
							</Box>
							{mstRows.map((item: any) => {
								return (
									<React.Fragment key={item.MstID}>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												mb: 1,
											}}
										>
											{selectedMstID === item.MstID ? (
												<FolderIcon
													color="primary"
													sx={{ mr: 1 }}
												/>
											) : (
												<FolderOpenIcon
													color="primary"
													sx={{ mr: 1 }}
												/>
											)}
											<div style={{ flex: 1 }}>
												<TextField
													size="small"
													fullWidth
													defaultValue={item.DepartName}
													value={mstRowRef.current[item.MstID]?.DepartName}
													ref={(el) => {
														mstRowRef.current[item.MstID] = el
													}}
													onChange={(e) => {
														const { value } = e.target
														mstRowRef.current[item.MstID].DepartName = value
													}}
													onClick={(e) => onMstItemClick(item.MstID, item.DepartName)}
												/>
											</div>
											<ButtonGroup sx={{ ml: 1 }}>
												<Button
													size="small"
													color="info"
													onClick={(e) => {
														actionMstUpdate(
															e,
															item.MstID,
															mstRowRef.current[item.MstID]?.DepartName
														)
													}}
												>
													수정
												</Button>
												<Button
													size="small"
													color="warning"
													onClick={(e) => actionMstDelete(e, item.MstID)}
												>
													<DeleteForeverTwoTone />
												</Button>
											</ButtonGroup>
										</Box>
									</React.Fragment>
								)
							})}
						</Box>
					</Grid>
					<Grid
						item
						md={9}
						sx={{ p: 2 }}
					>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid xs={6} />
								<Grid
									item
									xs={6}
									sx={{ textAlign: 'right' }}
								>
									<Button
										variant="contained"
										color="primary"
										startIcon={<AddCircleTwoToneIcon />}
										onClick={addTemplateItem}
										disabled={
											selectedMstID === 0 || selectedMstID === undefined ? true : false
										}
									>
										추가
									</Button>
								</Grid>
							</Grid>
						</Box>
						<Grid
							container
							spacing={2}
						>
							{isEmptyList && (
								<Grid
									item
									xs={12}
									sx={{ textAlign: 'center' }}
								>
									<Typography
										variant="h6"
										sx={{ mt: 2 }}
									>
										등록된 항목이 없습니다.
									</Typography>
								</Grid>
							)}
							{slvRows.length > 0 &&
								slvRows.map((item: any, index: number) => {
									return (
										<Grid
											item
											xs={6}
											md={3}
											key={item.SlvID}
										>
											<Card sx={{ maxWidth: 345, height: 210 }}>
												<CardHeader
													title={item.Category}
													subheader={item.RegDttm}
													action={
														<>
															<IconButton
																aria-label="settings"
																onClick={(e) => handleMenuOepn(e, index)}
															>
																<MoreVertIcon />
															</IconButton>
															<Menu
																id={`simple-menu-${item.SlvID}`}
																anchorEl={menuRefs.current[index]}
																keepMounted
																open={openMenus[index]}
																onClose={handleClose}
															>
																<MenuItem onClick={(e) => modifyTemplateItem(item)}>
																	수정
																</MenuItem>
																<MenuItem onClick={(e) => templateItemDel(item)}>삭제</MenuItem>
															</Menu>
														</>
													}
												/>
												<CardContent
													sx={{
														backgroundColor:
															theme.palette.mode === 'dark' ? '#333' : '#EEF9FD',
													}}
												>
													<Typography
														variant="body2"
														color="text.secondary"
														sx={{ height: 90, overflow: 'auto' }}
														dangerouslySetInnerHTML={{ __html: replaceBrTag(item.Message) }}
													/>
												</CardContent>
												<CardActions disableSpacing>
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'space-between',
															width: '100%',
														}}
													>
														<Button
															variant="contained"
															color="warning"
															size="small"
															startIcon={<SaveAsTwoTone />}
															onClick={(e) => templateItemDel(item)}
														>
															삭제
														</Button>
														<Button
															variant="contained"
															color="secondary"
															size="small"
															startIcon={<SaveAsTwoTone />}
															onClick={(e) => modifyTemplateItem(item)}
														>
															수정
														</Button>
													</Box>
												</CardActions>
											</Card>
										</Grid>
									)
								})}
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default Template
