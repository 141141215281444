import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpHwasan: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'CHARTNO',
		headerName: '등록번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '환자명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'AGE_SEX',
		headerName: '나이/성별',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'DEPT_BED',
		headerName: '진료과/병실',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'EXTRDD',
		headerName: '접수일',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'SPCCD',
		headerName: '검체코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SPCNM',
		headerName: '검체명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '생년월일',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '검체번호',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
