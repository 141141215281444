import React, { useEffect, useState, useRef } from 'react'

import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {
	Button,
	TextField,
	FormControl,
	InputLabel,
	Switch,
} from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import {
	DataGridPremium,
	koKR,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridColumns,
	GridRowModel,
	GridSelectionModel,
	GridRowId,
	useGridApiRef,
	GridDensity,
} from '@mui/x-data-grid-premium'

import { FormikHelpers } from 'formik'
// import CodeComponent1 from './CodeComponent1'

import {
	apiSaveCmmnCode,
	apiGetCmmnCodeParent,
	apiGetCmmnCodeLevel2,
	apiGetCmmnCodeLevel3,
	apiDeleteCmmnCode,
} from 'api/api'
import { CmmnCodeRequestType } from '../../constants/types'

import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { alertModal, confirmAlert } from 'util/util'
import { getSettings } from 'helpers/storage'
import { useSelector } from 'react-redux'
import { selectuserInfo } from 'hooks/userInfo'
import { useNavigate } from 'react-router-dom'

/**
 * 그리드 컬럼
 */
const columns: GridColumns = [
	{ field: 'cmmnCd', headerName: '코드', type: 'string', width: 150 },
	{ field: 'cmmnNm', headerName: '코드명', type: 'string', width: 150 },
	{ field: 'useFlag', headerName: '사용여부', type: 'boolean', width: 100 },
]

/**
 * 그리드 툴바
 * @returns
 */
const CustomToolbar = () => {
	return (
		<GridToolbarContainer>
			{/* <GridToolbarFilterButton /> */}
		</GridToolbarContainer>
	)
}

type Nullable<T> = T | null

type cmmnCodeType = {
	id: number
	parentId: Nullable<number>
	cmmnCd: string
	cmmnNm: string
	expl: string
	level: number
	seq: number
	useFlag: boolean
	isSubmitting: boolean
}

const CmmnCode = () => {
	const env = getSettings()
	const currentUser = useSelector(selectuserInfo)
	const navigate = useNavigate()

	const validationSchema = Yup.object({
		cmmnCd: Yup.string()
			.min(1, '공통코드는 최소 2글자 입니다.')
			.max(255, '공통코드는 최대 255글자 입니다.')
			.required('공통코드는 필수항목입니다.'),
		cmmnNm: Yup.string()
			.min(2, '공통코드명은 최소 2글자 입니다.')
			.max(255, '공통코드명은 최대 255글자 입니다.')
			.required('공통코드명은 필수항목입니다.'),
		expl: Yup.string()
			.min(2, '공통코드설명은 최소 2글자 입니다.')
			.max(255, '공통코드설명은 최대 255글자 입니다.')
			.required('공통코드설명은 필수항목입니다.'),
	})
	/**
	 * 1래벨 공통코드
	 */
	const [lv1Selected, setLv1Selected] = useState(false)
	const [lv2Selected, setLv2Selected] = useState(false)
	const [lv3Selected, setLv3Selected] = useState(false)
	const [lv1Id, setLv1Id] = useState(0)
	const [lv2Id, setLv2Id] = useState(0)
	const [lv3Id, setLv3Id] = useState(0)
	const [selection1Model, setSelection1Model] = useState<GridSelectionModel>([])
	const [selection2Model, setSelection2Model] = useState<GridSelectionModel>([])
	const [selection3Model, setSelection3Model] = useState<GridSelectionModel>([])

	const [dataSet1, setDataSet1] = useState<CmmnCodeRequestType>({
		id: 0,
		parentId: null,
		cmmnCd: '',
		cmmnNm: '',
		expl: '',
		level: 1,
		seq: 0,
		useFlag: false,
	})
	const [dataSet2, setDataSet2] = useState<CmmnCodeRequestType>({
		id: 0,
		parentId: null,
		cmmnCd: '',
		cmmnNm: '',
		expl: '',
		level: 2,
		seq: 0,
		useFlag: false,
	})
	const [dataSet3, setDataSet3] = useState<CmmnCodeRequestType>({
		id: 0,
		parentId: null,
		cmmnCd: '',
		cmmnNm: '',
		expl: '',
		level: 3,
		seq: 0,
		useFlag: false,
	})
	/**
	 * 1래벨 공통코드
	 */
	const [cmmnLv1List, setCmmnLv1List] = useState([])
	const [cmmnLv2List, setCmmnLv2List] = useState([])
	const [cmmnLv3List, setCmmnLv3List] = useState([])

	const [form1Submit, setForm1Submit] = useState(false)
	const [form2Submit, setForm2Submit] = useState(false)
	const [form3Submit, setForm3Submit] = useState(false)

	// 1래벨 공통코드 호출
	const getCmmnCdParent = () => {
		apiGetCmmnCodeParent().then((res) => {
			// console.log(res)
			setCmmnLv1List(res)
		})
	}

	// 2래벨 공통코드 목록
	const apiCmmnCodeLevel2 = (parentId: Nullable<number>) => {
		if (parentId) {
			apiGetCmmnCodeLevel2(parentId).then((res) => setCmmnLv2List(res))
		}
	}

	// 3래벨 공통코드 목록
	const apiCmmnCodeLevel3 = (parentId: Nullable<number>) => {
		if (parentId) {
			apiGetCmmnCodeLevel3(parentId).then((res) => setCmmnLv3List(res))
		}
	}

	/**
	 * 그리드 row 선택
	 * @param data
	 * @param level
	 */
	const onRowClick = (data: GridRowModel, level: number) => {
		// console.log(data.row, level)
		// console.log(selection1Model)
		switch (level) {
			case 1:
				setLv1Selected(true)
				setLv1Id(data.row.id)
				apiCmmnCodeLevel2(data.row.id)
				setDataSet1(data.row)
				console.log(data.row)
				setDataSet2((prevState) => {
					return {
						id: 0,
						parentId: data.row.id,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 2,
						seq: 0,
						useFlag: false,
					}
				})
				setDataSet3((prevState) => {
					return {
						id: 0,
						parentId: null,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 3,
						seq: 0,
						useFlag: false,
					}
				})
				setCmmnLv3List([])
				setLv2Selected(false)
				setLv3Selected(false)
				break
			case 2:
				setLv2Selected(true)
				setLv2Id(data.row.id)
				apiCmmnCodeLevel3(data.row.id)
				setDataSet2(data.row)
				setDataSet3((prevState) => {
					return {
						id: 0,
						parentId: data.row.id,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 3,
						seq: 0,
						useFlag: false,
					}
				})
				setLv3Selected(false)
				break
			case 3:
				setLv3Selected(true)
				setLv3Id(data.row.id)
				setDataSet3(data.row)
				break
			default:
				setLv2Selected(true)
				setLv3Selected(true)
				setLv1Id(0)
				setLv2Id(0)
				break
		}
	}

	/**
	 * 그리드 아이템 추가
	 * @param levelNum
	 */
	const addItem = (levelNum: number) => {
		switch (levelNum) {
			case 1:
				setSelection1Model([])
				setSelection2Model([])
				setSelection3Model([])
				setCmmnLv2List([])
				setCmmnLv3List([])
				setLv1Selected(false)
				setLv2Selected(false)
				setDataSet1({
					id: 0,
					parentId: null,
					cmmnCd: '',
					cmmnNm: '',
					expl: '',
					level: 1,
					seq: 0,
					useFlag: false,
				})
				setDataSet2((prevState) => {
					return {
						id: 0,
						parentId: dataSet1.id,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 2,
						seq: 0,
						useFlag: false,
					}
				})
				setDataSet3({
					id: 0,
					parentId: null,
					cmmnCd: '',
					cmmnNm: '',
					expl: '',
					level: 3,
					seq: 0,
					useFlag: false,
				})
				break
			case 2:
				setSelection2Model([])
				setSelection3Model([])
				setCmmnLv3List([])
				setLv2Selected(false)
				// console.log('aaa')
				setDataSet2((prevState) => {
					return {
						id: 0,
						parentId: dataSet1.id,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 2,
						seq: 0,
						useFlag: false,
					}
				})
				setDataSet3({
					id: 0,
					parentId: null,
					cmmnCd: '',
					cmmnNm: '',
					expl: '',
					level: 3,
					seq: 0,
					useFlag: false,
				})
				break
			case 3:
				// setSelection3Model([])
				setDataSet3({
					id: 0,
					parentId: dataSet2.id,
					cmmnCd: '',
					cmmnNm: '',
					expl: '',
					level: 3,
					seq: 0,
					useFlag: false,
				})
				break
			default:
				break
		}
	}

	/**
	 * 행 삭제
	 * @param levelNum
	 */
	const deleteItem = (levelNum: number) => {
		// console.log(levelNum, lv1Id, lv2Id, lv3Id)
		if (levelNum === 2 && cmmnLv3List.length > 0) {
			// console.log(cmmnLv2List.length)
			alertModal(
				'3레벨 공통코드의 항목이 존재하기 때문에 삭제할 수 없습니다.',
				'error',
				() => {}
			)
			return
		}
		if (levelNum === 1 && (cmmnLv2List.length > 0 || cmmnLv3List.length > 0)) {
			// console.log(cmmnLv2List.length)
			alertModal(
				'2레빌 또는 3레벨 공통코드의 항목이 존재하기 때문에 삭제할 수 없습니다.',
				'error',
				() => {}
			)
			return
		}
		confirmAlert(
			'삭제 하시겠습니까?',
			() => {
				switch (levelNum) {
					case 1:
						apiDeleteCmmnCode(lv1Id).then(() => getCmmnCdParent())
						break
					case 2:
						apiDeleteCmmnCode(lv2Id).then(() => {
							apiCmmnCodeLevel2(lv1Id)
							setDataSet3({
								id: 0,
								parentId: lv1Id,
								cmmnCd: '',
								cmmnNm: '',
								expl: '',
								level: 2,
								seq: 0,
								useFlag: false,
							})
						})
						break
					case 3:
						apiDeleteCmmnCode(lv3Id).then(() => {
							apiCmmnCodeLevel3(lv2Id)
							setDataSet3({
								id: 0,
								parentId: lv2Id,
								cmmnCd: '',
								cmmnNm: '',
								expl: '',
								level: 3,
								seq: 0,
								useFlag: false,
							})
						})
						break
					default:
						break
				}
			},
			() => {}
		)
	}

	// 1 레벨 공통코드 저장
	const handleSubmit1 = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setForm1Submit(true)
		validationSchema
			.validate(dataSet1)
			.then(() => {
				apiSaveCmmnCode(dataSet1).then((res) => {
					getCmmnCdParent()
					setCmmnLv2List([])
					setCmmnLv3List([])
					setDataSet1({
						id: 0,
						parentId: null,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 1,
						seq: 0,
						useFlag: false,
					})
				})
			})
			.catch((err) => {
				alertModal(err.message, 'error', () => {})
			})
			.finally(() => setForm1Submit(false))
	}

	// 2 레벨 공통코드 저장
	const handleSubmit2 = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (dataSet2.parentId === null) {
			alertModal('1레벨 공통코드를 선택하세요.', 'error', () => {})
			return
		}
		setForm2Submit(true)
		validationSchema
			.validate(dataSet2)
			.then(() => {
				apiSaveCmmnCode(dataSet2).then((res) => {
					setCmmnLv3List([])
					apiCmmnCodeLevel2(dataSet1.id)
					setDataSet2((prevState) => {
						return {
							id: 0,
							parentId: prevState.parentId,
							cmmnCd: '',
							cmmnNm: '',
							expl: '',
							level: 2,
							seq: 0,
							useFlag: false,
						}
					})
					setDataSet3({
						id: 0,
						parentId: null,
						cmmnCd: '',
						cmmnNm: '',
						expl: '',
						level: 3,
						seq: 0,
						useFlag: false,
					})
				})
			})
			.catch((err) => {
				alertModal(err.message, 'error', () => {})
			})
			.finally(() => setForm2Submit(false))
	}

	// 3 레벨 공통코드 저장
	const handleSubmit3 = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (dataSet3.parentId === null) {
			alertModal('2레벨 공통코드를 선택하세요.', 'error', () => {})
			return
		}

		setForm3Submit(true)
		// console.log(dataSet2.id)
		validationSchema
			.validate(dataSet3)
			.then(() => {
				apiSaveCmmnCode(dataSet3).then((res) => {
					apiCmmnCodeLevel3(dataSet2.id)
					setDataSet3((prevState) => {
						return {
							id: 0,
							parentId: prevState.parentId,
							cmmnCd: '',
							cmmnNm: '',
							expl: '',
							level: 3,
							seq: 0,
							useFlag: false,
						}
					})
				})
			})
			.catch((err) => {
				alertModal(err.message, 'error', () => {})
			})
			.finally(() => setForm3Submit(false))
	}

	useEffect(() => {
		getCmmnCdParent()
		// if (currentUser?.infoType !== 'W') {
		// 	alertModal('접근 권한이 없습니다.', 'error', () => {
		// 		navigate('/admin/dashboard')
		// 	})
		// }
	}, [])
	// Params: GridRowParams
	// Event: MuiEvent<React.MouseEvent<HTMLElement>>
	return (
		<Paper
			elevation={1}
			className="content-container"
		>
			<Grid
				container
				spacing={4}
			>
				<Grid
					item
					lg={4}
					xs={12}
				>
					<div style={{ height: 400, width: '100%' }}>
						<DataGridPremium
							rows={cmmnLv1List}
							columns={columns}
							onRowClick={(row) => onRowClick(row, 1)}
							hideFooterPagination
							components={{
								Toolbar: CustomToolbar,
							}}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							onSelectionModelChange={setSelection1Model}
							selectionModel={selection1Model}
							experimentalFeatures={{ newEditingApi: true }}
							density={(env.desnse as GridDensity) || 'standard'}
						/>
					</div>
					<Box
						component="div"
						sx={{ marginTop: '1rem' }}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '1rem',
							}}
						>
							<Button
								variant="contained"
								color="warning"
								size="small"
								startIcon={<DeleteForeverIcon />}
								disabled={!lv1Selected}
								onClick={() => deleteItem(1)}
							>
								삭제
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="small"
								startIcon={<AddBoxIcon />}
								onClick={() => addItem(1)}
							>
								추가
							</Button>
						</Box>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit1}
						>
							<Stack
								direction="column"
								spacing={2}
							>
								<TextField
									label="공통코드"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet1.cmmnCd}
									onChange={(e) => {
										setDataSet1({
											...dataSet1,
											cmmnCd: e.target.value,
										})
									}}
								/>
								<TextField
									label="공통코드명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet1.cmmnNm}
									onChange={(e) => {
										setDataSet1({
											...dataSet1,
											cmmnNm: e.target.value,
										})
									}}
								/>
								<TextField
									label="공통코드설명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet1.expl}
									onChange={(e) => {
										setDataSet1({
											...dataSet1,
											expl: e.target.value,
										})
									}}
								/>
								<TextField
									label="출력순서"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet1.seq}
									onChange={(e) => {
										setDataSet1({
											...dataSet1,
											seq: e.target.value ? parseInt(e.target.value) : 0,
										})
									}}
								/>
								<FormControlLabel
									control={
										<Switch
											checked={dataSet1.useFlag}
											onChange={(e) => {
												console.log(e.target.checked)
												setDataSet1({
													...dataSet1,
													useFlag: e.target.checked,
												})
											}}
										/>
									}
									label="사용여부"
								/>
								<LoadingButton
									type="submit"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									loading={form1Submit}
									disabled={form1Submit}
								>
									{dataSet1.id === 0 ? '저장' : '수정'}
								</LoadingButton>
							</Stack>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					lg={4}
					xs={12}
				>
					<div style={{ height: 400, width: '100%' }}>
						<DataGridPremium
							rows={cmmnLv2List}
							columns={columns}
							onRowClick={(row) => onRowClick(row, 2)}
							hideFooterPagination
							components={{
								Toolbar: CustomToolbar,
							}}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							onSelectionModelChange={setSelection2Model}
							selectionModel={selection2Model}
							experimentalFeatures={{ newEditingApi: true }}
							density={(env.desnse as GridDensity) || 'standard'}
						/>
					</div>
					<Box
						component="div"
						sx={{ marginTop: '1rem' }}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '1rem',
							}}
						>
							<Button
								variant="contained"
								color="warning"
								size="small"
								startIcon={<DeleteForeverIcon />}
								disabled={!lv2Selected}
								onClick={() => deleteItem(2)}
							>
								삭제
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="small"
								startIcon={<AddBoxIcon />}
								onClick={() => addItem(2)}
								disabled={!lv1Selected}
							>
								추가
							</Button>
						</Box>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit2}
						>
							<Stack
								direction="column"
								spacing={2}
							>
								<TextField
									label="공통코드"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet2.cmmnCd}
									onChange={(e) => {
										setDataSet2({
											...dataSet2,
											cmmnCd: e.target.value,
										})
									}}
								/>
								<TextField
									label="공통코드명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet2.cmmnNm}
									onChange={(e) => {
										setDataSet2({
											...dataSet2,
											cmmnNm: e.target.value,
										})
									}}
								/>
								<TextField
									label="공통코드설명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet2.expl}
									onChange={(e) => {
										setDataSet2({
											...dataSet2,
											expl: e.target.value,
										})
									}}
								/>
								<TextField
									label="출력순서"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet2.seq}
									onChange={(e) => {
										setDataSet2({
											...dataSet2,
											seq: parseInt(e.target.value),
										})
									}}
								/>
								<FormControlLabel
									control={
										<Switch
											checked={dataSet2.useFlag}
											onChange={(e) => {
												setDataSet2({
													...dataSet2,
													useFlag: e.target.checked,
												})
											}}
										/>
									}
									label="사용여부"
								/>
								<LoadingButton
									type="submit"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									loading={form2Submit}
									disabled={form2Submit}
								>
									{dataSet2.id === 0 ? '저장' : '수정'}
								</LoadingButton>
							</Stack>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					lg={4}
					xs={12}
				>
					<div style={{ height: 400, width: '100%' }}>
						<DataGridPremium
							rows={cmmnLv3List}
							columns={columns}
							onRowClick={(row) => onRowClick(row, 3)}
							hideFooterPagination
							components={{
								Toolbar: CustomToolbar,
							}}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							onSelectionModelChange={(newSelection) =>
								setSelection3Model(newSelection)
							}
							selectionModel={selection3Model}
							experimentalFeatures={{ newEditingApi: true }}
							density={(env.desnse as GridDensity) || 'standard'}
						/>
					</div>
					<Box
						component="div"
						sx={{ marginTop: '1rem' }}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '1rem',
							}}
						>
							<Button
								variant="contained"
								color="warning"
								size="small"
								startIcon={<DeleteForeverIcon />}
								disabled={!lv3Selected}
								onClick={() => deleteItem(3)}
							>
								삭제
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="small"
								startIcon={<AddBoxIcon />}
								onClick={() => addItem(3)}
								disabled={!lv2Selected}
							>
								추가
							</Button>
						</Box>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit3}
						>
							<Stack
								direction="column"
								spacing={2}
							>
								<TextField
									label="공통코드"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet3.cmmnCd}
									onChange={(e) => {
										setDataSet3({
											...dataSet3,
											cmmnCd: e.target.value,
										})
									}}
								/>
								<TextField
									label="공통코드명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet3.cmmnNm}
									onChange={(e) => {
										setDataSet3({
											...dataSet3,
											cmmnNm: e.target.value,
										})
									}}
								/>
								<TextField
									label="공통코드설명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet3.expl}
									onChange={(e) => {
										setDataSet3({
											...dataSet3,
											expl: e.target.value,
										})
									}}
								/>
								<TextField
									label="출력순서"
									variant="outlined"
									size="small"
									type="text"
									fullWidth={true}
									value={dataSet3.seq}
									onChange={(e) => {
										setDataSet3({
											...dataSet3,
											seq: e.target.value ? parseInt(e.target.value) : 0,
										})
									}}
								/>
								<FormControlLabel
									control={
										<Switch
											checked={dataSet3.useFlag}
											onChange={(e) => {
												setDataSet3({
													...dataSet3,
													useFlag: e.target.checked,
												})
											}}
										/>
									}
									label="사용여부"
								/>
								<LoadingButton
									type="submit"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									loading={form3Submit}
									disabled={form3Submit}
								>
									{dataSet3.id === 0 ? '저장' : '수정'}
								</LoadingButton>
							</Stack>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default CmmnCode
