import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	LinearProgress,
	AppBar,
	IconButton,
	Toolbar,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridCellParams,
} from '@mui/x-data-grid-premium'
import { useTheme } from '@mui/material/styles'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { getSettings } from 'helpers/storage'

import { alertModal, compareDate, isValidDateType } from 'util/util'
import { valuesInIterator } from 'util/objectUtil'
import { apiGb34016ExcelList, apiGb34016ExcelListExcel } from 'api/api'

import moment, { locale } from 'moment'
import 'moment/locale/ko'
import { selectuserInfo } from 'hooks/userInfo'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'

const locales = ['ko'] as const

const ExcelDownload = () => {
	const theme = useTheme()

	const env = getSettings()
	const currentUser = useSelector(selectuserInfo)

	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	// grid state
	const gridRef = useGridApiRef()
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const [dateRange, setDateRange] = useState('7d')

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		apiGb34016ExcelList(search).then((res) => {
			setGridRows(res)
		})
	}
	const actionReset = () => {
		setSearch({
			clientID: currentUser.clientID,
			startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
		})
	}

	// grid row click event
	const onRowClick = (e: GridRowModel) => {
		if (e.id) {
			setDisabledDel(false)
			setDisabledSave(false)
		}
	}

	// 조회조건
	const [search, setSearch] = useState({
		clientID: currentUser.clientID,
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
	})

	const onKeyUp = (e: any) => {
		e.preventDefault()
		if (e.keyCode === 13) actionSearch()
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		// if (moment(new Date(e)).isBefore('2023-08-01')) {
		// 	alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
		// 	setSearch({
		// 		...search,
		// 		startDate: '2023-08-01',
		// 		endDate: '2023-08-07',
		// 	})
		// 	return
		// } else {
		if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
			if (!isValidDateType(e)) {
				alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
				setDisabledSearch(true)
				return
			}

			if (target === 'startDate') {
				let diffValue = compareDate(e, search.endDate)
				if (diffValue > 0) {
					alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
				if (diffValue < -1825) {
					alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
			} else if (target === 'endDate') {
				let diffValue = compareDate(e, search.startDate)
				if (diffValue < 0) {
					alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
				if (diffValue > 1825) {
					alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}
			}

			setDisabledSearch(false)

			setSearch((prevState) => {
				return {
					...search,
					[target]: moment(new Date(e)).format('yyyy-MM-DD'),
				}
			})
			// } else {
			// 	setDisabledSearch(true)
			// }
		}
	}

	const excelAction = () => {
		window.open(
			`${process.env.REACT_APP_HOST}/gb34016/excel?startDate=${search.startDate}&endDate=${search.endDate}&clientID=${search.clientID}`,
			'결과보고서'
		)
	}

	useEffect(() => {
		actionSearch()
	}, [])

	return (
		<>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={false}
			/>
			<Stack
				sx={{
					marginBottom: '1rem',
					position: 'sticky',
					top: 120,
					left: 0,
					right: 0,
					zIndex: 1,
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '55px',
						top: '0px',
					},
				}}
			>
				<PageBg>
					<Box
						component="div"
						onKeyUp={onKeyUp}
					>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={locale}
						>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<DatePicker
										label="시작일"
										value={search.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<DatePicker
										label="종료일"
										value={search.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Box>
				</PageBg>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden' }}
			>
				<Box sx={{ p: 2, pt: 0 }}>
					<Box
						sx={{
							height: 'auto',
							width: '100%',
							overflow: 'auto',
							marginTop: '1rem',
							'@media (max-width: 1199px)': {
								height: '400px',
							},
						}}
					>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								variant="contained"
								type="button"
								onClick={excelAction}
								sx={{ mb: 2 }}
							>
								엑셀 다운로드
							</Button>
						</div>
						<DataGridPremium
							apiRef={gridRef}
							rows={gridRows}
							getRowId={(row) => row.rowNum}
							pageSize={gridPageSize}
							onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={true}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={gridLoading}
							selectionModel={selectionModel}
							onSelectionModelChange={(newSelection) => {
								setSelectionModel(newSelection)
							}}
							onRowClick={onRowClick}
							density={(env.desnse as GridDensity) || 'compact'}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
							columns={[
								{
									field: 'ChartNo',
									headerName: '차트번호',
									type: 'string',
									width: 80,
								},
								{
									field: 'ReceptionRegNum',
									headerName: '접수번호',
									type: 'string',
									width: 80,
								},
								{
									field: 'D90',
									headerName: '보고일자',
									type: 'string',
									width: 90,
								},
								{
									field: 'PatientName',
									headerName: '환자명',
									type: 'string',
									width: 100,
								},
								{
									field: 'TestNmEmr',
									headerName: '검사명',
									type: 'string',
									width: 200,
								},
								{
									field: 'ResultData',
									headerName: '결과',
									type: 'string',
									width: 130,
								},
								{
									field: 'Blank1',
									headerName: ' ',
									type: 'string',
									width: 50,
								},
								{
									field: 'Blank2',
									headerName: ' ',
									type: 'string',
									width: 50,
								},
								{
									field: 'Blank3',
									headerName: ' ',
									type: 'string',
									width: 50,
								},
								{
									field: 'Unit',
									headerName: '단위',
									type: 'string',
									width: 100,
								},
								{
									field: 'Reference',
									headerName: '참고치',
									type: 'string',
									width: 130,
								},
								{
									field: 'TestCdEmr',
									headerName: '병원검사코드',
									type: 'string',
									width: 100,
								},
							]}
						/>
					</Box>
				</Box>
			</Paper>
		</>
	)
}

export default ExcelDownload
