import { createSlice } from '@reduxjs/toolkit'

export const gridColumnSlice = createSlice({
	name: 'gridColumn',
	initialState: {
		info: {},
	},
	reducers: {
		setGridColumn: (state, actions) => {
			state.info = actions.payload
		},
		removeGridColumn: (state) => {
			state.info = {}
		},
	},
})

export const { setGridColumn, removeGridColumn } = gridColumnSlice.actions
export default gridColumnSlice.reducer
export const selectGridColumnInfo = (state: any) => state.gridColumn
