import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

import userInfoReducer from './userInfo'
import globalErrorReducer from './globalError'
import themeModeReducer from './themeMode'
import pagePosReducer from './pagePos'
import editorHtmlReducer from './editorHtml'
import gnbToggleReducer from './gnbToggle'
import cmmnCodeReducer from './cmmnCodeFactory'
import gridColumnReducer from './gridColumn'
import gridColumnReducerV11 from './gridColumnV11'
import girdColumnVisibleReducer from './gridColumnVisible'
import gridColumnVisibleReducerV11 from './gridColumnVisibleV11'
import envSettingReducer from './envSetting'
import gridColumnOrderV2 from './gridColumnOrderV2'
import gridColumnVisibleV2 from './gridColumnVisibleV2'

const reducers = combineReducers({
	userInfo: userInfoReducer,
	globalError: globalErrorReducer,
	displayMode: themeModeReducer,
	pagePos: pagePosReducer,
	editorHtml: editorHtmlReducer,
	gnbToggle: gnbToggleReducer,
	cmmnCodeFactory: cmmnCodeReducer,
	gridColumnOrderInfo: gridColumnReducer,
	gridColumnOrderInfoV11: gridColumnReducerV11,
	gridColumnVisibleInfo: girdColumnVisibleReducer,
	gridColumnVisibleInfoV11: gridColumnVisibleReducerV11,
	envSetting: envSettingReducer,
	gridColumnOrderV2: gridColumnOrderV2,
	gridColumnVisibleV2: gridColumnVisibleV2,
})

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
})

export default store
