import { useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { selectDisplayMode } from 'hooks/themeMode'
import { darkTheme, lightTheme } from 'constants/defaultValues'

import { styled } from '@mui/material/styles'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'

import { useNavigate } from 'react-router-dom'

import ErrorBg from '../../assets/img/404.png'

const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: `url('${ErrorBg}') no-repeat center`,
}))

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(0, 30, 60)' : '#aab4be',
  padding: '3rem',
  borderRadius: '1rem',
}))

const NotFound = () => {
  const navigate = useNavigate()
  const themeMode = useSelector(selectDisplayMode)
  return (
    <ThemeProvider theme={themeMode.isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Wrapper>
        <Container>
          <AnnouncementIcon color="info" sx={{ fontSize: '100px' }} />
          <Typography
            variant="h1"
            color="text.secondary"
            style={{ marginBottom: '0.5rem' }}
          >
            404
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            style={{ marginBottom: '2.5rem', marginTop: '0.5rem' }}
          >
            페이지를 찾을 수 없습니다.
          </Typography>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="contained"
            onClick={() => {
              navigate(-1)
            }}
          >
            돌아가기
          </Button>
        </Container>
      </Wrapper>
    </ThemeProvider>
  )
}

export default NotFound
