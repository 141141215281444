import { useEffect, useState, useRef } from 'react'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Typography,
	LinearProgress,
	Select,
	useMediaQuery,
	MenuItem,
	CircularProgress,
	Breadcrumbs,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridDensity,
	useGridApiRef,
	GridToolbar,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import { alertModal, hexToRgba } from 'util/util'

import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { useSelector } from 'react-redux'
import { apiPostReceptionList, apiPostItemList } from '../../api/api'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import moment from 'moment'
import 'moment/locale/ko'

import { compareDate, isValidDateType } from 'util/util'
import { getSettings } from 'helpers/storage'
import ReportSlider from 'views/report/ReportSlider'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DraftsIcon from '@mui/icons-material/Drafts'
import LabelIcon from '@mui/icons-material/Label'

import ClientSearchPopup from '../test/clientSearchPopup'

import SimplePopup from 'components/SimplePopup'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { valuesInIterator } from 'util/objectUtil'
import { useNavigate } from 'react-router-dom'

const locales = ['ko'] as const

const ReportGenerator = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.5)',
	zIndex: 9999,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))

// main: '#835FED',
/**
 * 그리드 컬럼
 */
const columns: GridColDef[] = [
	{
		field: 'ReceptionID',
		headerName: '접수ID',
		type: 'string',
		width: 80,
		editable: false,
		hide: true,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'rowNum',
		headerName: '순번',
		type: 'string',
		width: 50,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ReceptionDate',
		headerName: '일자',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ReceptionRegNum',
		headerName: '접수번호',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientName',
		headerName: '수진자명',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			return <span>{params?.row?.PatientName}</span>
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'DepartName',
		headerName: '진료과',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'Ward',
		headerName: '병동',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'SocialNumber',
		headerName: '주민번호',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ClientInfo',
		headerName: '성별/나이',
		type: 'string',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PrintState',
		headerName: '진행상태',
		type: 'string',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			if (params?.row?.PrintDate) {
				return (
					<span
						style={{
							color: '#000',
							background: '#74acdb',
							padding: '1px 3px',
							border: '1px solid #74acdb',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						출력
					</span>
				)
			} else {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						미출력
					</span>
				)
			}
		},
	},
	{
		field: 'PrintDate',
		headerName: '출력일자/시간',
		type: 'string',
		width: 150,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientPostCode',
		headerName: '우편번호',
		type: 'string',
		width: 75,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientAddress',
		headerName: '주소',
		type: 'string',
		width: 400,
		editable: false,
	},
	{
		field: 'RptTypeCode',
		headerName: '리포트코드',
		type: 'string',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		hide: true,
	},
]

const Print = () => {
	const navigate = useNavigate()

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const grid1Ref = useGridApiRef()

	const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false)

	const [reportIsLoading, setReportIsLoading] = useState<boolean>(false)

	const userInfo = useSelector(selectuserInfo)
	const env = getSettings()
	// ui
	const cmmnCdList = useSelector(selectCmmnCode)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [showImageOverlay, setShowImageOverlay] = useState(false)

	const [dateRange, setDateRange] = useState('1d')

	const [dateActiveButtonNumber, setDateActiveButtonNumber] = useState(0)

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const [page1, setPage1] = useState(0)

	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [imageList, setImageList] = useState<any>([])

	const excelDownloadRef = useGridApiRef()
	const [excelDataGridRows, setExcelDataGridRows] = useState<any>([])

	const actionExcel = () => {
		const row = grid1Ref.current?.getSelectedRows()
		const arrayList = valuesInIterator(row)
		setExcelDataGridRows(arrayList)
		setTimeout(() => {
			excelDownloadRef.current?.exportDataAsExcel()
		}, 100)
	}

	const actionSearch = () => {
		if (!disabledSearch) getReceptionList()
	}
	const actionReset = () => {
		setTestSearch({
			clientID: '',
			patientName: '',
			chartNo: '',
			receptionRegNum: '',
			lastStateCode: '4',
			searchTestCd: '0',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(1, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			departName: '',
			doctorName: '',
			isPDC: false,
			searchType: 'R',
			socailNumber: '',
			printState: '0',
			testCode: '0',
			useFlag: '1',
		})
		setSearchClientName('')
		setDateRange('1d')
		actionSearch()
		grid1Ref.current?.setQuickFilterValues([])
		grid1Ref.current?.setPage(0)
	}

	const [reportDialog, setReportDialog] = useState(false)

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '1d':
				getBeforeDate(1)
				break
			case '3d':
				getBeforeDate(3)
				break
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '2m':
				getBeforeMonth(2)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setTestSearch({
				...testSearch,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, testSearch.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, testSearch.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setTestSearch((prevState) => {
					return {
						...testSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// api response
	const [receptionListData, setReceptionListData] = useState([])
	const [size1, setSize1] = useState(20)
	const [grid1isLoading, setGrid1isLoading] = useState(false)

	// 검색조건
	const [testSearch, setTestSearch] = useState({
		clientID: '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		lastStateCode: '4',
		searchTestCd: '0',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(1, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		departName: '',
		doctorName: '',
		isPDC: false,
		searchType: 'R',
		socailNumber: '',
		printState: '0',
		testCode: '0',
		useFlag: '1',
	})

	// 수진자 목록
	const getReceptionList = () => {
		// 내부사용자이고 clientID가 없으면
		if (testSearch.clientID === '') {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		if (testCodeList.length === 0) {
			alertModal('설정된 항목이 없습니다.', 'error', () => {})
			return
		}

		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(testSearch.startDate).isBefore('2023-08-01')) {
			setTestSearch({
				...testSearch,
				startDate: '2023-08-01',
			})
		}

		// concat testCodeList in testCode
		let testCodeListString = ''
		for (let i = 0; i < testCodeList.length; i++) {
			console.log(testCodeList[i].TestCode)
			testCodeListString += testCodeList[i].TestCode + ','
		}
		if (testCodeListString.charAt(testCodeListString.length - 1) === ',') {
			testCodeListString = testCodeListString.slice(0, -1)
		}

		const request = {
			clientID: testSearch.clientID as any,
			patientName: testSearch.patientName,
			chartNo: testSearch.chartNo,
			receptionRegNum: testSearch.receptionRegNum,
			lastStateCode: testSearch.lastStateCode,
			searchTestCd: testSearch.searchTestCd === '0' ? '' : testSearch.searchTestCd,
			startDate: moment(testSearch.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: testSearch.startDate,
			endDate: testSearch.endDate,
			departName: testSearch.departName,
			doctorName: testSearch.doctorName,
			isPDC: testSearch.isPDC ? '1' : '',
			searchType: testSearch.searchType,
			socailNumber: testSearch.socailNumber,
			printState: testSearch.printState,
			testCode:
				testSearch.testCode === '0' ? testCodeListString : testSearch.testCode,
		}
		console.log(request)

		setGrid1isLoading(true)
		setSelectionModel([])
		apiPostReceptionList(request)
			.then((res) => {
				console.log(res)
				setReceptionListData(res)
				//setExcelDataGridRows(res)
			})
			.finally(() => {
				setGrid1isLoading(false)
				// 검색 실행 후 상단으로 스크롤
				window.scrollTo({ top: 0, behavior: 'smooth' })
			})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const reportClose = () => {
		setReportDialog(false)
	}

	const [testCodeList, setTestCodeList] = useState<any>([])

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setTestSearch({
			...testSearch,
			clientID: e.ClientID,
			useFlag: '1',
		})
		apiPostItemList({
			clientID: e.ClientID,
			useFlag: '1',
		}).then((res) => {
			console.log(res)
			setTestCodeList(res)
		})
		setClientDialogOpen(false)
	}

	const actionReportPrint = () => {
		const selectedRows = grid1Ref.current?.getSelectedRows()
		// extract ReceptionID in selectedRows
		const values = valuesInIterator(selectedRows)
		const receptionIDs = values.map((row: any) => row.ReceptionID)
		// receptionIDs to string
		const receptionIDsString = receptionIDs.join(',')

		let testCodeListString = ''
		for (let i = 0; i < testCodeList.length; i++) {
			console.log(testCodeList[i].TestCode)
			testCodeListString += testCodeList[i].TestCode + ','
		}
		if (testCodeListString.charAt(testCodeListString.length - 1) === ',') {
			testCodeListString = testCodeListString.slice(0, -1)
		}

		window.open(
			`${
				process.env.REACT_APP_HOST
			}/rpt/postReport?receptionIDs=${receptionIDsString}&testCode=${
				testSearch.testCode === '0' ? testCodeListString : testSearch.testCode
			}&clientID=${testSearch.clientID}&startDate=${
				testSearch.startDate
			}&endDate=${testSearch.endDate}&printState=${
				testSearch.printState
			}&searchType=${testSearch.searchType}&lastStateCode=${
				testSearch.lastStateCode
			}&patientName=${testSearch.patientName}&chartNo=${
				testSearch.chartNo
			}&receptionRegNum=${testSearch.receptionRegNum}&searchTestCd=${
				testSearch.searchTestCd === '0' ? '' : testSearch.searchTestCd
			}&departName=${testSearch.departName}&doctorName=${
				testSearch.doctorName
			}&isPDC=${testSearch.isPDC}&socailNumber=${testSearch.socailNumber}`,
			'',
			'width=900,height=900'
		)
	}

	const actionReportLabelPrint = () => {
		const selectedRows = grid1Ref.current?.getSelectedRows()
		// extract ReceptionID in selectedRows
		const values = valuesInIterator(selectedRows)
		const receptionIDs = values.map((row: any) => row.ReceptionID)
		// receptionIDs to string
		const receptionIDsString = receptionIDs.join(',')

		let testCodeListString = ''
		for (let i = 0; i < testCodeList.length; i++) {
			console.log(testCodeList[i].TestCode)
			testCodeListString += testCodeList[i].TestCode + ','
		}
		if (testCodeListString.charAt(testCodeListString.length - 1) === ',') {
			testCodeListString = testCodeListString.slice(0, -1)
		}

		window.open(
			`${
				process.env.REACT_APP_HOST
			}/rpt/postLabel?receptionIDs=${receptionIDsString}&testCode=${
				testSearch.testCode === '0' ? testCodeListString : testSearch.testCode
			}&clientID=${testSearch.clientID}&startDate=${
				testSearch.startDate
			}&endDate=${testSearch.endDate}&printState=${
				testSearch.printState
			}&searchType=${testSearch.searchType}&lastStateCode=${
				testSearch.lastStateCode
			}&patientName=${testSearch.patientName}&chartNo=${
				testSearch.chartNo
			}&receptionRegNum=${testSearch.receptionRegNum}&searchTestCd=${
				testSearch.searchTestCd === '0' ? '' : testSearch.searchTestCd
			}&departName=${testSearch.departName}&doctorName=${
				testSearch.doctorName
			}&isPDC=${testSearch.isPDC}&socailNumber=${testSearch.socailNumber}`,
			'',
			'width=900,height=900'
		)
	}

	// 검사결과 그리드의 결과 버튼 처리
	const [resultTitle, setResultTitle] = useState<string>('')
	const [resultPopupOpen, setResultPopupOpen] = useState(false)
	const [resultPopupText, setResultPopupText] = useState<string>('')

	const resultPopupClose = () => {
		setResultPopupOpen(false)
		setResultTitle('')
		setResultPopupText('')
	}

	useEffect(() => {
		if (!userInfo?.isPrintView) {
			alertModal('권한이 없습니다.', 'error', () => {
				navigate('/admin/test/result')
			})
		}

		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		setTestSearch({
			...testSearch,
			searchTestCd: '0',
		})
	}, [])

	return (
		<>
			{reportIsLoading && (
				<ReportGenerator>
					<CircularProgress />
				</ReportGenerator>
			)}
			{showImageOverlay && (
				<ReportSlider
					imageList={imageList}
					reportDialog={reportDialog}
					reportClose={reportClose}
				/>
			)}
			<SimplePopup
				title={resultTitle}
				message={resultPopupText}
				simplePopupOpen={resultPopupOpen}
				simplePopupClose={resultPopupClose}
				isHTML={false}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								웹전용 출력지
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										color="primary"
										size="small"
										variant="contained"
										startIcon={<FileDownloadIcon />}
										sx={{ marginRight: '0.5rem' }}
										disabled={receptionListData.length === 0}
										onClick={actionExcel}
									>
										엑셀다운로드
									</Button>
								</span>
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={isMdUp && <DraftsIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										onClick={actionReportPrint}
										disabled={selectionModel.length === 0}
									>
										웹결과지출력
									</Button>
								</span>
								<span>
									<Button
										color="info"
										size="small"
										variant="contained"
										startIcon={isMdUp && <LabelIcon />}
										sx={{
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										onClick={actionReportLabelPrint}
										disabled={selectionModel.length === 0}
									>
										라벨출력
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<SearchRowItem>
							<p className="labelText">조회조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={1}
								>
									<TextField
										label="거래처"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										aria-readonly={true}
										value={searchClientName}
										onClick={clientPopupOpenAction}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label11">항목</InputLabel>
										<Select
											labelId="status-select-label11"
											id="status-simple-select11"
											label="출항목력상태"
											value={testSearch.testCode}
											onChange={(e) =>
												setTestSearch({
													...testSearch,
													testCode: e.target.value,
												})
											}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											{testCodeList.map((item: any) => (
												<MenuItem value={item.TestCode}>{item.DisplayName}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={1}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label1">출력상태</InputLabel>
										<Select
											labelId="status-select-label1"
											id="status-simple-select1"
											label="출력상태"
											value={testSearch.printState}
											onChange={(e) =>
												setTestSearch({
													...testSearch,
													printState: e.target.value,
												})
											}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											<MenuItem value={'1'}>미완료</MenuItem>
											<MenuItem value={'2'}>완료</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								{/* <Grid
									item
									xs={6}
									sm={3}
									md={1}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label">진행단계</InputLabel>
										<Select
											labelId="status-select-label"
											id="status-simple-select"
											label="진행단계"
											value={testSearch.lastStateCode}
											onChange={(e) =>
												setTestSearch({
													...testSearch,
													lastStateCode: e.target.value,
												})
											}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											<MenuItem value={'1'}>접수</MenuItem>
											<MenuItem value={'2'}>재검</MenuItem>
											<MenuItem value={'3'}>진행</MenuItem>
											<MenuItem value={'4'}>완료</MenuItem>
										</Select>
									</FormControl>
								</Grid> */}
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="시작일"
										value={testSearch.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="종료일"
										value={testSearch.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={3}
								>
									{isLgUp ? (
										<>
											<FormControl sx={{ pt: 0.8 }}>
												<RadioGroup
													row
													aria-labelledby="date-controlled-radio-buttons-group"
													name="date-radio-buttons-group"
													value={dateRange}
													onChange={searchDateRanageOnChange}
													sx={{ ml: '0.5rem' }}
												>
													<FormControlLabel
														value="1d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="1일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="3d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="3일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="7d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="7일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="1m"
														control={<Radio sx={{ padding: '1px' }} />}
														label="1개월"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
												</RadioGroup>
											</FormControl>
										</>
									) : (
										<>
											<FormControl fullWidth>
												<InputLabel id="date-range-select-label">조회 기간</InputLabel>
												<Select
													labelId="date-range-select-label"
													id="date-range-simple-select"
													value={dateRange}
													label="조회 기간"
													onChange={searchDateRanageOnChange}
													size={'small'}
												>
													<MenuItem value={'0'}>선택하세요.</MenuItem>
													<MenuItem value={'1d'}>1일</MenuItem>
													<MenuItem value={'3d'}>3일</MenuItem>
													<MenuItem value={'7d'}>1주일</MenuItem>
													<MenuItem value={'1m'}>1개월</MenuItem>
													<MenuItem value={'2m'}>2개월</MenuItem>
													<MenuItem value={'3m'}>3개월</MenuItem>
													<MenuItem value={'1y'}>1년</MenuItem>
												</Select>
											</FormControl>
										</>
									)}
								</Grid>
							</Grid>
						</SearchRowItem>
						<SearchRowItem>
							<p className="labelText">검색조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="접수번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.receptionRegNum}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												receptionRegNum: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="차트번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.chartNo}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												chartNo: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="수진자명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.patientName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												patientName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="주민번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.socailNumber}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												socailNumber: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={2}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: isSmDown ? 'flex-end' : 'flex-start',
										}}
									>
										<div>
											<Button
												color="primary"
												variant="contained"
												startIcon={<ContentPasteSearchTwoToneIcon />}
												onClick={actionSearch}
											>
												조회
											</Button>
										</div>
									</div>
								</Grid>
							</Grid>
						</SearchRowItem>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				id="back-to-top"
				elevation={0}
				sx={{
					width: '100%',
					// overflow: 'hidden',
					backgroundColor: 'transparent',
					// overflow: 'auto',
				}}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<PageBg style={{ marginTop: '1rem' }}>
							<Typography
								variant="subtitle1"
								component="h3"
								sx={{ lineHeight: '1rem' }}
							>
								수진자 리스트
							</Typography>
							<Box
								sx={{
									marginTop: '1rem',
									height: 813,
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<DataGridPremium
									apiRef={grid1Ref}
									page={page1}
									onPageChange={(newPage) => setPage1(newPage)}
									rows={receptionListData}
									getRowId={(row) => row.rowNum}
									columns={columns}
									pageSize={size1}
									onPageSizeChange={(newPageSize) => setSize1(newPageSize)}
									rowsPerPageOptions={[5, 10, 20]}
									pagination
									autoHeight={false}
									// autoHeight={isLgUp ? true : false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									loading={grid1isLoading}
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										const isSelectAll = newSelection.length === receptionListData.length
										if (isSelectAll) {
											const pageList: number[] = []
											receptionListData.forEach((row: any) => {
												if (
													row.rowNum >= page1 * size1 + 1 &&
													row.rowNum < (page1 + 1) * size1 + 1
												) {
													pageList.push(row.rowNum)
												}
											})
											setSelectionModel(pageList)
										} else {
											if (selectionModel.length >= 20 && newSelection.length > 20) {
												alertModal('20개 이상 선택할 수 없습니다.', 'error', () => {})
											} else {
												setSelectionModel(newSelection)
											}
										}
									}}
									density={(env.desnse as GridDensity) || 'compact'}
									checkboxSelection
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
									disableMultipleSelection={false}
									getRowHeight={() => 'auto'}
									// initialState={{
									// 	columns: {
									// 		columnVisibilityModel: {
									// 			rowNum: false,
									// 		},
									// 	},
									// }}
								/>
							</Box>
						</PageBg>
					</Grid>
				</Grid>
			</Paper>
			<div style={{ display: 'none' }}>
				<DataGridPremium
					page={1}
					apiRef={excelDownloadRef}
					rows={excelDataGridRows}
					getRowId={(row) => row.rowNum}
					pagination
					localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
					components={{
						Toolbar: GridToolbar,
						LoadingOverlay: LinearProgress,
					}}
					componentsProps={{
						toolbar: {
							csvOptions: { disableToolbarButton: true },
							excelOptions: { disableToolbarButton: true },
							printOptions: { disableToolbarButton: true },
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 250 },
						},
					}}
					getDetailPanelHeight={({ row }) => 'auto'}
					density={(env.desnse as GridDensity) || 'compact'}
					getRowHeight={() => 'auto'}
					initialState={{
						columns: {
							columnVisibilityModel: {
								rowNum: false,
								RptTypeCode: false,
							},
						},
					}}
					columns={columns}
					pageSize={10}
					autoHeight={true}
					checkboxSelection
					selectionModel={selectionModel}
				/>
			</div>
		</>
	)
}

export default Print
