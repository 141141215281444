import { GridColDef } from '@mui/x-data-grid-premium'

export const headerOcsTmpMsysTech: GridColDef[] = [
	{
		field: 'id',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
		hideable: true,
		hide: true,
	},
	{
		field: 'EXTRNO',
		headerName: '접수번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRDT',
		headerName: '접수일시',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '환자명',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'AGE',
		headerName: '나이',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'BARCODE',
		headerName: '바코드번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'SPCNM',
		headerName: '검체명',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'WARD',
		headerName: '병동',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명칭',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'DOCTOR',
		headerName: '주치의',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'BED',
		headerName: '병실',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'SPCCD',
		headerName: '검체코드',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'isDuplicate',
		headerName: '중복',
		type: 'boolean',
		width: 50,
		editable: false,
		hideable: true,
		hide: true,
	},
]
