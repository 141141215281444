import { useEffect, useState, useRef } from 'react'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ImageIcon from '@mui/icons-material/Image'
import ReceiptIcon from '@mui/icons-material/Receipt'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Typography,
	LinearProgress,
	Select,
	SelectChangeEvent,
	useMediaQuery,
	Menu,
	MenuItem,
	CircularProgress,
	Breadcrumbs,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridDensity,
	useGridApiRef,
	GridCellParams,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import CustomDetailPanelToggle from 'components/CustomDetailPanelToggle'

import { alertModal, confirmAlert, hexToRgba } from 'util/util'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { useSelector } from 'react-redux'
import {
	InspectionResultDetailType,
	PrevReceptionItemListType,
} from 'constants/types'
import {
	receptionList,
	receptionDetail,
	prevReceptionItemList,
	testInfoSelect,
	reportData,
	testImageList,
	receptionView,
	generatePdf,
	reportText,
} from '../../api/api'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import moment from 'moment'
import 'moment/locale/ko'

import PrevResult from './PrevResult'
import TestInfo from './TestInfo'

import { compareDate, isValidDateType } from 'util/util'
import { compareDateValues, replaceBrTag } from 'util/stringUtil'
import { getSettings } from 'helpers/storage'
import ReportSlider from 'views/report/ReportSlider'
import React from 'react'
import ArrowUp from '../../assets/img/ArrowUp.svg'
import ArrowDown from '../../assets/img/ArrowDown.svg'
import ArrowBoth from '../../assets/img/ArrowBoth.svg'
import WysiwygTwoToneIcon from '@mui/icons-material/WysiwygTwoTone'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import ClientSearchPopup from './clientSearchPopup'
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone'
import FontDownloadTwoToneIcon from '@mui/icons-material/FontDownloadTwoTone'

import SimplePopup from 'components/SimplePopup'
import jsPDF from 'jspdf'
import JSZip, { forEach } from 'jszip'

import SaveAsTwoToneIcon from '@mui/icons-material/SaveAsTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { valuesInIterator } from 'util/objectUtil'
import axios from 'axios'

const locales = ['ko'] as const

const ReportGenerator = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.5)',
	zIndex: 9999,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}))

const HeaderTable = styled('table')(({ theme }) => ({
	width: '100%',
	borderCollapse: 'collapse',
	'th, td': {
		padding: '0.2rem 0.3rem',
		border:
			theme.palette.mode === 'dark'
				? '1px solid rgba(224, 224, 224, 0.5)'
				: '1px solid rgba(224, 224, 224, 0.5)',
	},
	th: {
		backgroundColor: theme.palette.mode === 'dark' ? '#AACDE8' : '#AACDE8',
		color: theme.palette.mode === 'dark' ? '#000' : '#000',
	},
	td: { backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF' },
	// th: { backgroundColor: theme.palette.mode === 'dark' ? '#835FED' : '#835FED' },
	// td: { backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF' },
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))

// main: '#835FED',
/**
 * 그리드 컬럼
 */
const columns: GridColDef[] = [
	{
		field: 'ReceptionID',
		headerName: '접수ID',
		type: 'string',
		width: 80,
		editable: false,
		hide: true,
	},
	{
		field: 'rowNum',
		headerName: '순번',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'ReceptionDate',
		headerName: '접수일자',
		width: 100,
	},
	{
		field: 'ReceptionInfo',
		headerName: '접수번호',
		type: 'string',
		width: 200,
		editable: false,
	},
	{
		field: 'PatientName',
		headerName: '수진자명',
		type: 'string',
		width: 150,
		editable: false,
		renderCell: (params) => {
			return <span>{params?.row?.PatientName}</span>
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'ClientInfo',
		headerName: '성별/나이',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'DepartName',
		headerName: '진료과',
		type: 'string',
		width: 130,
		editable: false,
		align: 'center',
	},
	{
		field: 'PartName',
		headerName: '학부',
		type: 'string',
		width: 130,
		editable: false,
		align: 'center',
		renderCell: (params) => {
			const { PartName } = params?.row
			return <>{PartName === 'STD/HPV' ? '분자진단' : PartName}</>
		},
	},
	{
		field: 'StateCategory',
		headerName: '진행단계',
		type: 'string',
		width: 100,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.StateCategory === '접수') {
				return (
					<span
						style={{
							background: '#8d8d8d',
							padding: '1px 3px',
							border: '1px solid #8d8d8d',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						접수
					</span>
				)
			} else if (params?.row?.StateCategory === '재검') {
				return (
					<span
						style={{
							background: '#e67e22',
							padding: '1px 3px',
							border: '1px solid #e67e22',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						재검
					</span>
				)
			} else if (params?.row?.StateCategory === '완료') {
				return (
					<span
						style={{
							color: '#000',
							background: '#74acdb',
							padding: '1px 3px',
							border: '1px solid #74acdb',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						완료
					</span>
				)
			} else if (params?.row?.StateCategory === '진행') {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						진행
					</span>
				)
			}
		},
	},
	{
		field: 'Decision',
		headerName: '판정',
		type: 'string',
		width: 80,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.StateCategory === '완료' && params?.row?.Decision === 'L') {
				return (
					<img
						src={ArrowDown}
						alt="L"
						style={{ width: '8px', display: 'block', margin: '0 auto' }}
					/>
				)
			} else if (
				params?.row?.StateCategory === '완료' &&
				params?.row?.Decision === 'H'
			) {
				return (
					<img
						src={ArrowUp}
						alt="H"
						style={{ width: '8px', display: 'block', margin: '0 auto' }}
					/>
				)
			} else if (
				params?.row?.StateCategory === '완료' &&
				params?.row?.Decision === 'A'
			) {
				return (
					<img
						src={ArrowBoth}
						alt="A"
						style={{ width: '8px', display: 'block', margin: '0 auto' }}
					/>
				)
			} else {
				return <></>
			}
		},
	},
	{
		field: 'isView',
		headerName: '읽음',
		type: 'string',
		width: 70,
		editable: false,
		align: 'center',
		renderCell: (params) => {
			if (params?.row?.isView === 1) {
				return <>Y</>
				// return <AssignmentTurnedInIcon color="primary" />
			} else {
				return <></>
			}
		},
	},
]

const ResultDownload = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const grid1Ref = useGridApiRef()

	const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false)

	const [reportIsLoading, setReportIsLoading] = useState<boolean>(false)

	const actionSearchExpand = () => {
		setIsSearchExpanded(!isSearchExpanded)
	}

	const userInfo = useSelector(selectuserInfo)
	const imageRef = useRef<HTMLDivElement>(null)
	const env = getSettings()
	// ui
	const cmmnCdList = useSelector(selectCmmnCode)
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [disabledExcel, setDisabledExcel] = useState(true)
	const [disabledImage, setDisabledImage] = useState(true)
	const [disabledReport, setDisabledReport] = useState(true)
	const [showImageOverlay, setShowImageOverlay] = useState(false)

	const [dateRange, setDateRange] = useState('1d')

	const [dateActiveButtonNumber, setDateActiveButtonNumber] = useState(0)

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const [page1, setPage1] = useState(0)

	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	/**
	 * 검체종류 select
	 * @param e
	 */
	const searchTestCdOnChange = (e: SelectChangeEvent<string>): void => {
		setTestSearch({
			...testSearch,
			searchTestCd: e.target.value,
		})
	}

	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [reportCode, setReportCode] = useState<string>('')
	const [imageList, setImageList] = useState<any>([])
	const [fileList, setFileList] = useState<any>([])

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		if (!disabledSearch) getReceptionList()
	}
	const actionReset = () => {
		setTestSearch({
			clientID: '',
			patientName: '',
			chartNo: '',
			receptionRegNum: '',
			lastStateCode: '4',
			searchTestCd: '0',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(1, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			departName: '',
			doctorName: '',
			isPDC: false,
			searchType: 'R',
			socailNumber: '',
		})
		setSearchClientName('')
		setDateRange('1d')
		actionSearch()
		grid1Ref.current?.setQuickFilterValues([])
		grid1Ref.current?.setPage(0)
	}

	const [imageDataList, setImageDataList] = useState<any>([])
	const [reportDialog, setReportDialog] = useState(false)

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment(testSearch.endDate)
				.subtract(n, 'days')
				.format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '1d':
				getBeforeDate(1)
				break
			case '3d':
				getBeforeDate(3)
				break
			case '7d':
				getBeforeDate(7)
				break
			default:
				getBeforeDate(7)
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	const searchDateRanageOnClick = (event: any) => {
		switch (event) {
			case '1d':
				getBeforeDate(1)
				setDateActiveButtonNumber(0)
				break
			case '3d':
				getBeforeDate(3)
				setDateActiveButtonNumber(1)
				break
			case '7d':
				getBeforeDate(7)
				setDateActiveButtonNumber(2)
				break
			case '14d':
				getBeforeDate(14)
				setDateActiveButtonNumber(2)
				break
			case '1m':
				getBeforeMonth(1)
				setDateActiveButtonNumber(3)
				break
			case '2m':
				getBeforeMonth(2)
				setDateActiveButtonNumber(4)
				break
			case '3m':
				getBeforeMonth(3)
				setDateActiveButtonNumber(5)
				break
			case '1y':
				getBeforeYear(1)
				setDateActiveButtonNumber(6)
				break
			case '5y':
				getBeforeYear(5)
				setDateActiveButtonNumber(6)
				break
			default:
				break
		}
		setDateRange(event)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		setTestSearch((prevState) => {
			return {
				...testSearch,
				[target]: moment(new Date(e)).format('yyyy-MM-DD'),
			}
		})
		// if (moment(new Date(e)).isBefore('2023-08-01')) {
		// 	alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
		// 	setTestSearch({
		// 		...testSearch,
		// 		startDate: '2023-08-01',
		// 	})
		// 	return
		// } else {
		// 	let isInvalid = false
		// 	if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
		// 		if (!isValidDateType(e)) {
		// 			alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
		// 			setDisabledSearch(true)
		// 			return
		// 		}

		// 		if (target === 'startDate') {
		// 			let diffValue = compareDate(e, testSearch.endDate)
		// 			if (diffValue > 0) {
		// 				alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
		// 				//setDisabledSearch(true)
		// 				isInvalid = true
		// 			}
		// 			if (diffValue < -7) {
		// 				alertModal('조회 기간은 7일을 초과할 수  없습니다.', 'error', () => {})
		// 				//setDisabledSearch(true)
		// 				isInvalid = true
		// 			}
		// 		} else if (target === 'endDate') {
		// 			let diffValue = compareDate(e, testSearch.startDate)
		// 			if (diffValue < 0) {
		// 				alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
		// 				//setDisabledSearch(true)
		// 				isInvalid = true
		// 			}
		// 			if (diffValue > 7) {
		// 				alertModal('조회 기간은 7일을 초과할 수  없습니다.', 'error', () => {})
		// 				//setDisabledSearch(true)
		// 				isInvalid = true
		// 			}
		// 		}

		// 		if (!isInvalid) {
		// 			setTestSearch((prevState) => {
		// 				return {
		// 					...testSearch,
		// 					[target]: moment(new Date(e)).format('yyyy-MM-DD'),
		// 				}
		// 			})
		// 		} else {
		// 			if (target === 'startDate') {
		// 				setTestSearch((prevState) => {
		// 					return {
		// 						...testSearch,
		// 						startDate: moment(testSearch.startDate).format('YYYY-MM-DD'),
		// 					}
		// 				})
		// 			} else if (target === 'endDate') {
		// 				setTestSearch((prevState) => {
		// 					return {
		// 						...testSearch,
		// 						endDate: moment(testSearch.endDate).format('YYYY-MM-DD'),
		// 					}
		// 				})
		// 			}
		// 		}
		// 	} else {
		// 		setDisabledSearch(true)
		// 	}
		// }
	}

	const reportImageAction = () => {
		const datas = grid1Ref.current?.getSelectedRows()
		console.log(datas)
		const iterArray = valuesInIterator(datas)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			setReportIsLoading(true)
			const zip = new JSZip()

			const receptionIDs = iterArray.map((element: any) => {
				return element.ReceptionID
			})
			const receptionIDText = receptionIDs.join(',')
			const request = {
				ReceptionIDs: receptionIDText,
			}

			generatePdf(request)
				.then((res) => {
					console.log(res)
					const list = res[0].body
					if (list.length > 0) {
						let cnt = 0
						let urlIndex = 0
						list.forEach((base64Data: any, index: number) => {
							if (base64Data.URL) {
								cnt++
								urlIndex = index
							}
						})
						if (cnt === 1) {
							const byteCharacters = atob(list[urlIndex].URL.split(',')[1])
							const byteNumbers = new Array(byteCharacters.length)
							for (let i = 0; i < byteCharacters.length; i++) {
								byteNumbers[i] = byteCharacters.charCodeAt(i)
							}
							const byteArray = new Uint8Array(byteNumbers)
							const blob = new Blob([byteArray], { type: list[0].FileExtension })

							console.log(list[0].FileName)
							console.log(userInfo?.clientID)

							// const filename = `image${0}.${list[0].FileExtension}`
							let filename = ''
							const chartNo = list[0].ChartNo ? `${list[0].ChartNo}_` : ''
							const receptionDate = list[0].ReceptionDate
								? `${list[0].ReceptionDate}_`
								: ''
							const rptTypeCode = list[0].RptTypeCode ? `${list[0].RptTypeCode}_` : ''
							const receptionRegNum = list[0].ReceptionRegNum
								? `${list[0].ReceptionRegNum}_`
								: ''
							if (userInfo?.clientID === 3900) {
								filename = `C${chartNo}${receptionDate}${rptTypeCode}${receptionRegNum}${list[0].PatientName}.${list[0].FileExtension}`
							} else if (userInfo?.clientID === 5327) {
								// 코키아병원 (이름을 맨 앞으로)
								console.log(5327)
								filename = `${list[0].PatientName}_${chartNo}${receptionDate}${rptTypeCode}${list[0].ReceptionRegNum}.${list[0].FileExtension}`
							} else {
								// filename = `${moment().format(
								// 	'YYYYMMDD'
								// )}_${chartNo}${receptionRegNum}${list[0].PatientName}.${
								// 	list[0].FileExtension
								// }`
								filename = `${receptionDate}_${chartNo}${receptionRegNum}${list[0].PatientName}.${list[0].FileExtension}`
							}

							const downloadLink = document.createElement('a')
							downloadLink.href = URL.createObjectURL(blob)
							downloadLink.download = filename
							downloadLink.click()
						} else if (cnt > 1) {
							let tmpIndex = 0
							let tmpName = ''
							let tmpReceptionRegNum = ''
							list.forEach((base64Data: any, index: number) => {
								console.log(base64Data)
								if (base64Data.URL) {
									const byteCharacters = atob(base64Data.URL.split(',')[1])
									const byteNumbers = new Array(byteCharacters.length)

									for (let i = 0; i < byteCharacters.length; i++) {
										byteNumbers[i] = byteCharacters.charCodeAt(i)
									}

									const byteArray = new Uint8Array(byteNumbers)
									const blob = new Blob([byteArray], { type: base64Data.FileExtension })

									if (tmpReceptionRegNum !== base64Data.ReceptionRegNum) {
										tmpName = base64Data.PatientName
										tmpIndex = 0
										tmpReceptionRegNum = base64Data.ReceptionRegNum
									}

									let filename = ''
									const chartNo = base64Data.ChartNo ? `${base64Data.ChartNo}_` : ''
									const receptionDate = base64Data.ReceptionDate
										? `${base64Data.ReceptionDate}_`
										: ''
									const rptTypeCode = base64Data.RptTypeCode
										? `${base64Data.RptTypeCode}_`
										: ''
									const receptionRegNum = base64Data.ReceptionRegNum
										? `${base64Data.ReceptionRegNum}_`
										: ''
									if (userInfo?.clientID === 3900) {
										// C챠트번호_접수일자_리포트코드_접수번호_이름_순번
										filename = `C${chartNo}${receptionDate}${rptTypeCode}${receptionRegNum}${tmpName}_${
											tmpIndex + 1
										}.${base64Data.FileExtension}`
									} else if (userInfo?.clientID === 5327) {
										// 코키아병원 (이름을 맨 앞으로)
										console.log(5327)
										filename = `${tmpName}_${
											tmpIndex + 1
										}_${chartNo}${receptionDate}${rptTypeCode}${
											base64Data.ReceptionRegNum
										}.${base64Data.FileExtension}`
									} else {
										// filename = `${moment().format(
										// 	'YYYYMMDD'
										// )}_${chartNo}${receptionRegNum}_${tmpName}_${tmpIndex + 1}.${
										// 	base64Data.FileExtension
										// }`
										filename = `${receptionDate}${chartNo}${receptionRegNum}${tmpName}_${
											tmpIndex + 1
										}.${base64Data.FileExtension}`
									}
									console.log(filename)
									tmpIndex++
									// fileName = yyyymmdd
									//  filename = `${moment().format('YYYYMMDD')}_${
									// 	base64Data.ChartNo
									// }_${base64Data.ReceptionRegNum}_${base64Data.PatientName}_${
									// 	index + 1
									// }.${base64Data.FileExtension}`

									zip.file(filename, blob)
								}
							})
							zip.generateAsync({ type: 'blob' }).then((content) => {
								const downloadLink = document.createElement('a')
								downloadLink.href = URL.createObjectURL(content)
								const downloadFileName =
									userInfo.infoType === 'C'
										? moment().format('YYYYMMDD') + '_' + userInfo.clientName
										: moment().format('YYYYMMDD')
								downloadLink.download = `${downloadFileName}.zip`
								downloadLink.click()
							})
						}
					} else {
						alertModal('리포트가 없습니다.', 'error', () => {})
						return
					}
				})
				.finally(() => setReportIsLoading(false))
		}
	}

	const reportPrintAction = () => {
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			setReportIsLoading(true)
			const doc = new jsPDF()
			let pageSize = 0

			const receptionIDs = iterArray.map((element: any) => {
				return element.ReceptionID
			})
			const receptionIDText = receptionIDs.join(',')
			const request = {
				ReceptionIDs: receptionIDText,
			}
			generatePdf(request)
				.then((res) => {
					console.log(res)
					const list = res[0].body
					if (list.length > 0) {
						list.forEach((item: any, index: number) => {
							const imgData = item?.URL
							if (imgData && pageSize > 0) {
								doc.addPage()
							}
							if (imgData) {
								doc.addImage(imgData, item?.FileExtension, 0, 0, 210, 297)
								pageSize++
							}
						})
						doc.setProperties({
							title: '결과보고서',
						})
						window.open(
							URL.createObjectURL(doc.output('blob')),
							'_blank',
							'width=900, height=1000'
						)
					} else {
						alertModal('출력할 리포트가 없습니다.', 'error', () => {})
					}
				})
				.catch((error) => {
					// 오류 처리
					alertModal('오류가 발생했습니다.', 'error', () => {})
					return
				})
				.finally(() => setReportIsLoading(false))
		}
	}

	const reportTextAction = () => {
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)

		if (iterArray.length > 0) {
			const request = {
				receptionID: iterArray[0].ReceptionID,
				clientID:
					userInfo.infoType === 'S' ? testSearch.clientID : userInfo.clientID,
			}

			window.open(
				`/report/text?receptionID=${request.receptionID}&clientID=${request.clientID}`,
				'결과보고서',
				'width=640, height=740'
			)
		}
	}

	// api response
	const [receptionListData, setReceptionListData] = useState([])
	const [size1, setSize1] = useState(env.row ? env.row : 100)
	const [grid1isLoading, setGrid1isLoading] = useState(false)

	// 검색조건
	const [testSearch, setTestSearch] = useState({
		clientID: '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		lastStateCode: '4',
		searchTestCd: '0',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(1, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		departName: '',
		doctorName: '',
		isPDC: false,
		searchType: 'R',
		socailNumber: '',
	})

	// 수진자 기초정보
	const [receptioDetailHeader, setReceptionDetailHeader] = useState({
		ChartNo: '',
		ClientInfo: '',
		CollectedDate: '',
		DepartInfo: '',
		DepartName: '',
		ClientName: '',
		PatientName: '',
		ReceptionDate: '',
		ReceptionID: 0,
		ReceptionInfo: '',
		ReceptionInfoDetail: '',
		ReceptionRegNum: '',
		SpecimenName: '',
		LastStateDate: '',
		RptTypeCode: '',
		TestCode: '',
		LabCode: '',
		LicenseDoctor: '',
		ReceptionUserInfo: '',
		Ward: '',
		ReportMemo: '',
		RItemID: '',
		SocialNumber: '',
		DoctorName: '',
		TotalUrineVolume: '',
	})

	// 수진자 목록
	const getReceptionList = () => {
		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			testSearch.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		// if testSearch.startDate less then 2023-08-01 then startDate = 2023-08-01
		if (moment(testSearch.startDate).isBefore('2023-08-01')) {
			setTestSearch({
				...testSearch,
				startDate: '2023-08-01',
			})
		}

		let dateCompare = true

		// 거래처일 경우 날짜 체크
		if (userInfo.infoType === 'C') {
			dateCompare = compareDateValues(testSearch.startDate, testSearch.endDate)
		}
		// 관리자일 경우 종료일이 시작일보다 이전인지만 체크
		if (userInfo.infoType === 'S' || userInfo.infoType === 'W') {
			if (moment(testSearch.endDate).isBefore(testSearch.startDate)) {
				dateCompare = false
				alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
				return
			}
		}

		if (dateCompare) {
			setDisabledSearch(true)
			const request = {
				clientID: testSearch.clientID as any,
				patientName: testSearch.patientName,
				chartNo: testSearch.chartNo,
				receptionRegNum: testSearch.receptionRegNum,
				lastStateCode: testSearch.lastStateCode,
				searchTestCd:
					testSearch.searchTestCd === '0' ? '' : testSearch.searchTestCd,
				startDate: moment(testSearch.startDate).isBefore('2023-08-01')
					? '2023-08-01'
					: testSearch.startDate,
				endDate: testSearch.endDate,
				departName: testSearch.departName,
				doctorName: testSearch.doctorName,
				isPDC: testSearch.isPDC ? '1' : '',
				searchType: testSearch.searchType,
				socailNumber: testSearch.socailNumber,
			}
			console.log(request)

			setGrid1isLoading(true)
			receptionList(request)
				.then((res) => {
					// console.log(res)
					setReceptionListData(res)
				})
				.finally(() => {
					setGrid1isLoading(false)
					// 검색 실행 후 상단으로 스크롤
					window.scrollTo({ top: 0, behavior: 'smooth' })
					setDisabledSearch(false)
				})
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}
	const [fileAnchorEl, setFileAnchorEl] = React.useState<null | HTMLElement>(
		null
	)
	const fileOpen = Boolean(fileAnchorEl)
	const handleFileListClose = () => {
		setFileAnchorEl(null)
	}

	const reportClose = () => {
		setReportDialog(false)
		setImageDataList([])
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setTestSearch({
			...testSearch,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	// 첨부파일 다운로드
	const attachFileDownload = (
		attachFile: string,
		attachFileName: string,
		AttachFileExtension: string
	) => {
		// download base64 file
		if (!attachFile) return
		if (!attachFileName) return
		if (!AttachFileExtension) return

		const content = atob(attachFile)
		const byteArray = new Uint8Array(content.length)
		for (let i = 0; i < content.length; i++) {
			byteArray[i] = content.charCodeAt(i)
		}
		const blob = new Blob([byteArray], {
			type: `application/${AttachFileExtension}`,
		})
		const downloadLink = document.createElement('a')
		const url = URL.createObjectURL(blob)
		downloadLink.href = url
		downloadLink.download = attachFileName
		downloadLink.click()
		downloadLink.remove()
	}

	const [excelDownload, setExcelDownload] = useState(false)
	const excelDownloadRef = useGridApiRef()
	const [excelDataGridRows, setExcelDataGridRows] = useState<any>([])

	const actionExcel = () => {
		const selectedRows = grid1Ref.current?.getSelectedRows()
		// extract ReceptionID in selectedRows
		const values = valuesInIterator(selectedRows)
		const receptionIDs = values.map((row: any) => row.ReceptionID)
		setDisabledExcel(true)
		if (receptionIDs.length > 500) {
			confirmAlert(
				'500건 이상일 경우 시간이 소요될 수 있습니다.<br/>계속하시겠습니까?',
				() => {
					setExcelDownload(true)
					// receptionIDs to string
					const receptionIDsString = receptionIDs.join(',')

					// get html title
					const title = document.title

					const TmpTitle =
						userInfo.infoType === 'C'
							? moment().format('YYYYMMDD') + '_' + userInfo.clientName
							: moment().format('YYYYMMDD') + '_' + searchClientName

					setExcelDataGridRows([])

					// html title to TmpTitle
					document.title = TmpTitle

					axios
						.post(`${process.env.REACT_APP_HOST}/report/download/excel2`, {
							receptionIDs: receptionIDsString,
							clientID:
								userInfo.infoType === 'S' || userInfo.infoType === 'W'
									? testSearch.clientID
									: userInfo.clientID,
						})
						.then((res) => {
							console.log(res.data)
							res.data.forEach((element: any) => {
								const { ReceptionDate } = element
								element.ReceptionDate2 = ReceptionDate
							})
							setExcelDataGridRows(res.data)
							setTimeout(() => {
								excelDownloadRef.current?.exportDataAsExcel()
								setTimeout(() => {
									document.title = title
								}, 500)
							}, 500)
						})
						.finally(() => {
							setExcelDownload(false)
							setDisabledExcel(false)
						})
				},
				() => {}
			)
			return
		} else {
			setExcelDownload(true)
			// receptionIDs to string
			const receptionIDsString = receptionIDs.join(',')

			// get html title
			const title = document.title

			const TmpTitle =
				userInfo.infoType === 'C'
					? moment().format('YYYYMMDD') + '_' + userInfo.clientName
					: moment().format('YYYYMMDD') + '_' + searchClientName

			setExcelDataGridRows([])

			// html title to TmpTitle
			document.title = TmpTitle

			axios
				.post(`${process.env.REACT_APP_HOST}/report/download/excel2`, {
					receptionIDs: receptionIDsString,
					clientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? testSearch.clientID
							: userInfo.clientID,
				})
				.then((res) => {
					console.log(res.data)
					res.data.forEach((element: any) => {
						const { ReceptionDate } = element
						element.ReceptionDate2 = ReceptionDate
					})
					setExcelDataGridRows(res.data)
					setTimeout(() => {
						excelDownloadRef.current?.exportDataAsExcel()
						setTimeout(() => {
							document.title = title
						}, 500)
					}, 500)
				})
				.finally(() => {
					setExcelDownload(false)
					setDisabledExcel(false)
				})
		}
		// axios
		// 	.get(
		// 		`${
		// 			process.env.REACT_APP_HOST
		// 		}/report/download/excel?receptionIDs=${receptionIDsString}&clientID=${
		// 			userInfo.infoType === 'S' || userInfo.infoType === 'W'
		// 				? testSearch.clientID
		// 				: userInfo.clientID
		// 		}`
		// 	)
		// 	.then((res) => {
		// 		console.log(res.data)
		// 		setExcelDataGridRows(res.data)
		// 		setTimeout(() => {
		// 			excelDownloadRef.current?.exportDataAsExcel()
		// 			setTimeout(() => {
		// 				document.title = title
		// 			}, 500)
		// 		}, 500)
		// 	})

		// window.open(
		// 	`${
		// 		process.env.REACT_APP_HOST
		// 	}/report/download/excel?receptionIDs=${receptionIDsString}&clientID=${
		// 		userInfo.infoType === 'S' || userInfo.infoType === 'W'
		// 			? testSearch.clientID
		// 			: userInfo.clientID
		// 	}`,
		// 	'결과보고서'
		// )
	}

	// 검사결과 그리드의 결과 버튼 처리
	const [resultTitle, setResultTitle] = useState<string>('')
	const [resultPopupOpen, setResultPopupOpen] = useState(false)
	const [resultPopupText, setResultPopupText] = useState<string>('')

	const resultPopupClose = () => {
		setResultPopupOpen(false)
		setResultTitle('')
		setResultPopupText('')
	}

	useEffect(() => {
		// 사용자 타입이 거래처일 경우만 자동 조회
		if (userInfo.infoType === 'C') getReceptionList()
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		setTestSearch({
			...testSearch,
			searchTestCd: '0',
		})
	}, [])

	return (
		<>
			{reportIsLoading && (
				<ReportGenerator>
					<CircularProgress />
				</ReportGenerator>
			)}
			{showImageOverlay && (
				<ReportSlider
					imageList={imageList}
					reportDialog={reportDialog}
					reportClose={reportClose}
				/>
			)}
			<SimplePopup
				title={resultTitle}
				message={resultPopupText}
				simplePopupOpen={resultPopupOpen}
				simplePopupClose={resultPopupClose}
				isHTML={false}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								일괄다운
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FileDownloadIcon />}
										sx={{ marginRight: '0.5rem' }}
										onClick={actionExcel}
										disabled={selectionModel.length === 0 ? true : false}
									>
										엑셀다운로드
									</Button>
								</span>
								{userInfo.isImageResult && (
									<span>
										<Button
											color="secondary"
											size="small"
											variant="contained"
											startIcon={<ImageIcon />}
											sx={{ marginRight: '0.5rem' }}
											// onClick={actionImage}
											onClick={reportImageAction}
											disabled={selectionModel.length === 0 ? true : false}
											// disabled={disabledImage}
											//disabled={imageList?.length === 0 ? true : false}
										>
											이미지다운로드
										</Button>
									</span>
								)}
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<ReceiptIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportPrintAction}
										disabled={selectionModel.length === 0 ? true : false}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										결과보고서
									</Button>
								</span>
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FontDownloadTwoToneIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportTextAction}
										disabled={selectionModel.length === 1 ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										텍스트 결과보고서
									</Button>
								</span>
								{/* <span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FontDownloadTwoToneIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportTextAction}
										disabled={selectionModel.length === 1 ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										엑셀 다운로드
									</Button>
								</span> */}
								<Menu
									id="file-positioned-menu"
									aria-labelledby="demo-positioned-button"
									anchorEl={fileAnchorEl}
									open={fileOpen}
									onClose={handleFileListClose}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{fileList?.map((item: any, index: number) => {
										return (
											<MenuItem
												key={index}
												onClick={() =>
													attachFileDownload(
														item.AttachFile,
														item.AttachFileName,
														item.AttachFileExtension
													)
												}
											>
												{item.AttachFileName}
											</MenuItem>
										)
									})}
								</Menu>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<SearchRowItem>
							<p className="labelText">조회조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label">진행단계</InputLabel>
										<Select
											labelId="status-select-label"
											id="status-simple-select"
											label="진행단계"
											value={testSearch.lastStateCode}
											onChange={(e) =>
												setTestSearch({
													...testSearch,
													lastStateCode: e.target.value,
												})
											}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											<MenuItem value={'1'}>접수</MenuItem>
											<MenuItem value={'2'}>재검</MenuItem>
											<MenuItem value={'3'}>진행</MenuItem>
											<MenuItem value={'4'}>완료</MenuItem>
										</Select>
									</FormControl>
									{/* <FormControl fullWidth>
										<InputLabel id="searchType-select-label">기간 유형</InputLabel>
										<Select
											labelId="searchType-select-label"
											id="searchTypee-simple-select"
											value={testSearch.searchType}
											label="기간 유형"
											onChange={(e) => {
												setTestSearch({
													...testSearch,
													searchType: e.target.value,
												})
											}}
											size={'small'}
										>
											<MenuItem value={'R'}>접수일자</MenuItem>
											<MenuItem value={'D'}>보고일자</MenuItem>
										</Select>
									</FormControl> */}
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="시작일"
										value={testSearch.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="종료일"
										value={testSearch.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={4}
								>
									{isLgUp ? (
										<>
											<FormControl sx={{ pt: 0.8 }}>
												<RadioGroup
													row
													aria-labelledby="date-controlled-radio-buttons-group"
													name="date-radio-buttons-group"
													value={dateRange}
													onChange={searchDateRanageOnChange}
													sx={{ ml: '0.5rem' }}
												>
													<FormControlLabel
														value="1d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="1일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="3d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="3일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="7d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="7일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													{/* <FormControlLabel
														value="14d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="14일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/> */}
												</RadioGroup>
											</FormControl>
										</>
									) : (
										<>
											<FormControl fullWidth>
												<InputLabel id="date-range-select-label">조회 기간</InputLabel>
												<Select
													labelId="date-range-select-label"
													id="date-range-simple-select"
													value={dateRange}
													label="조회 기간"
													onChange={searchDateRanageOnChange}
													size={'small'}
												>
													<MenuItem value={'0'}>선택하세요.</MenuItem>
													<MenuItem value={'1d'}>1일</MenuItem>
													<MenuItem value={'3d'}>3일</MenuItem>
													<MenuItem value={'7d'}>1일</MenuItem>
												</Select>
											</FormControl>
										</>
									)}
								</Grid>
							</Grid>
						</SearchRowItem>
						<SearchRowItem>
							<p className="labelText">검색조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="차트번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.chartNo}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												chartNo: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="수진자명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.patientName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												patientName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									{/* <TextField
										label="접수번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.receptionRegNum}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												receptionRegNum: e.target.value,
											})
										}}
									/> */}
									<TextField
										label="주민번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.socailNumber}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												socailNumber: e.target.value,
											})
										}}
									/>
								</Grid>
								{isSmDown && (
									<Grid
										item
										xs={6}
									>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={testSearch.isPDC}
														onChange={(e) => {
															setTestSearch({
																...testSearch,
																isPDC: e.target.checked,
															})
														}}
													/>
												}
												label="이상결과만 보기"
											/>
										</FormGroup>
									</Grid>
								)}
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: isSmDown ? 'flex-end' : 'flex-start',
										}}
									>
										{isSmUp && (
											<div>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																checked={testSearch.isPDC}
																onChange={(e) => {
																	setTestSearch({
																		...testSearch,
																		isPDC: e.target.checked,
																	})
																}}
															/>
														}
														label="이상결과만 보기"
													/>
												</FormGroup>
											</div>
										)}
										<div>
											<Button
												variant="contained"
												color="success"
												sx={{ mx: '0.3rem' }}
												onClick={actionSearchExpand}
											>
												검색조건확장
											</Button>
										</div>
										<div>
											<Button
												color="primary"
												variant="contained"
												startIcon={<ContentPasteSearchTwoToneIcon />}
												onClick={actionSearch}
												disabled={disabledSearch}
											>
												조회
											</Button>
										</div>
									</div>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={1}
								sx={{ mt: 0.2, display: isSearchExpanded ? 'flex' : 'none' }}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="date-range-select-label">학부</InputLabel>
										<Select
											labelId="date-range-select-label"
											id="date-range-simple-select"
											label="학부"
											value={testSearch.searchTestCd}
											onChange={searchTestCdOnChange}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											{testCdList &&
												testCdList.length > 0 &&
												testCdList.map(
													(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
														<MenuItem
															key={item.id}
															value={item?.cmmnCd}
														>
															{item?.cmmnNm}
														</MenuItem>
													)
												)}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<TextField
										label="진료과/병동"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.departName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												departName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<TextField
										label="담당의"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.doctorName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												doctorName: e.target.value,
											})
										}}
									/>
								</Grid>
								{userInfo.infoType === 'S' ||
									(userInfo.infoType === 'W' && (
										<Grid
											item
											xs={6}
											sm={3}
											md={2}
										>
											<TextField
												label="거래처"
												variant="outlined"
												size="small"
												type="text"
												fullWidth
												aria-readonly={true}
												value={searchClientName}
												onClick={clientPopupOpenAction}
											/>
										</Grid>
									))}
							</Grid>
						</SearchRowItem>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				id="back-to-top"
				elevation={0}
				sx={{
					width: '100%',
					// overflow: 'hidden',
					backgroundColor: 'transparent',
					// overflow: 'auto',
				}}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<PageBg style={{ marginTop: '1rem' }}>
							<Typography
								variant="subtitle1"
								component="h3"
								sx={{ lineHeight: '1rem' }}
							>
								수진자 리스트
							</Typography>
							<Box
								sx={{
									marginTop: '1rem',
									height: 813,
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<DataGridPremium
									apiRef={grid1Ref}
									page={page1}
									onPageChange={(newPage) => setPage1(newPage)}
									rows={receptionListData}
									getRowId={(row) => row.rowNum}
									columns={columns}
									pageSize={size1}
									onPageSizeChange={(newPageSize) => setSize1(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
									pagination
									autoHeight={false}
									// autoHeight={isLgUp ? true : false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										LoadingOverlay: LinearProgress,
									}}
									loading={grid1isLoading}
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										setSelectionModel(newSelection)
									}}
									density={(env.desnse as GridDensity) || 'compact'}
									checkboxSelection
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
									disableMultipleSelection={false}
									rowHeight={40}
									// initialState={{
									// 	columns: {
									// 		columnVisibilityModel: {
									// 			rowNum: false,
									// 		},
									// 	},
									// }}
								/>
							</Box>
						</PageBg>
					</Grid>
				</Grid>
			</Paper>
			<div style={{ display: 'none' }}>
				<DataGridPremium
					page={1}
					apiRef={excelDownloadRef}
					rows={excelDataGridRows}
					getRowId={(row) => row.rowNum}
					pagination
					localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
					components={{
						Toolbar: GridToolbar,
						LoadingOverlay: LinearProgress,
					}}
					componentsProps={{
						toolbar: {
							csvOptions: { disableToolbarButton: true },
							excelOptions: { disableToolbarButton: true },
							printOptions: { disableToolbarButton: true },
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 250 },
						},
					}}
					getDetailPanelHeight={({ row }) => 'auto'}
					density={(env.desnse as GridDensity) || 'compact'}
					getRowHeight={() => 'auto'}
					initialState={{
						columns: {
							columnVisibilityModel: {
								rowNum: true,
								ReceptionDate:
									userInfo.clientID === 2080 ||
									userInfo.clientID === 1792 ||
									userInfo.clientID === 1635
										? true
										: false,
								D90:
									userInfo.clientID === 2080 ||
									userInfo.clientID === 1792 ||
									userInfo.clientID === 1635
										? true
										: false,
								ReceptionDate2:
									userInfo.clientID === 2080 ||
									userInfo.clientID === 1792 ||
									userInfo.clientID === 1635
										? false
										: true,
							},
						},
					}}
					columns={[
						{
							field: 'rowNum',
							headerName: 'rowNum',
							width: 100,
						},
						{
							field: 'ReceptionDate',
							headerName: '접수일자',
							width: 100,
						},
						{
							field: 'ReceptionDate2',
							headerName: '접수일자',
							width: 100,
						},
						{
							field: 'ReceptionRegNum',
							headerName: '접수번호',
							width: 100,
						},
						{
							field: 'PatientName',
							headerName: '수진자명',
							width: 100,
						},
						{
							field: 'ChartNo',
							headerName: '챠트번호',
							width: 100,
						},
						{
							field: 'PatientInfo',
							headerName: '성별/나이',
							type: 'string',
							width: 130,
							editable: false,
							align: 'center',
						},
						{
							field: 'SocialNumber',
							headerName: '주민번호',
							width: 100,
						},
						{
							field: 'DepartName',
							headerName: '진료과',
							type: 'string',
							width: 130,
							editable: false,
							align: 'center',
						},
						{
							field: 'PartName',
							headerName: '학부',
							type: 'string',
							width: 130,
							editable: false,
							align: 'center',
							renderCell: (params) => {
								const { PartName } = params?.row
								return <>{PartName === 'STD/HPV' ? '분자진단' : PartName}</>
							},
						},
						{
							field: 'StateCategory',
							headerName: '진행단계',
							type: 'string',
							width: 130,
							editable: false,
							align: 'center',
							renderCell: (params) => {
								if (params?.row?.StateCategory === '접수') {
									return (
										<span
											style={{
												background: '#8d8d8d',
												padding: '1px 3px',
												border: '1px solid #8d8d8d',
												color: '#fff',
												width: '100%',
												display: 'block',
												textAlign: 'center',
												borderRadius: '10px',
											}}
										>
											접수
										</span>
									)
								} else if (params?.row?.StateCategory === '재검') {
									return (
										<span
											style={{
												background: '#e67e22',
												padding: '1px 3px',
												border: '1px solid #e67e22',
												color: '#fff',
												width: '100%',
												display: 'block',
												textAlign: 'center',
												borderRadius: '10px',
											}}
										>
											재검
										</span>
									)
								} else if (
									params?.row?.StateCategory === '완료' &&
									params?.row?.IsOver
								) {
									return (
										<span
											style={{
												color: '#000',
												background: '#74acdb',
												padding: '1px 3px',
												border: '1px solid #74acdb',
												width: '100%',
												display: 'block',
												textAlign: 'center',
												borderRadius: '10px',
											}}
										>
											완료
										</span>
									)
								} else if (
									params?.row?.StateCategory === '완료' &&
									!params?.row?.IsOver
								) {
									return (
										<span
											style={{
												color: '#000',
												background: '#99d696',
												padding: '1px 3px',
												border: '1px solid #99d696',
												width: '100%',
												display: 'block',
												textAlign: 'center',
												borderRadius: '10px',
											}}
										>
											진행
										</span>
									)
								} else if (params?.row?.StateCategory === '진행') {
									return (
										<span
											style={{
												color: '#000',
												background: '#99d696',
												padding: '1px 3px',
												border: '1px solid #99d696',
												width: '100%',
												display: 'block',
												textAlign: 'center',
												borderRadius: '10px',
											}}
										>
											진행
										</span>
									)
								}
							},
						},
						{
							field: 'ClientCode',
							headerName: '병원코드',
							width: 100,
						},
						{
							field: 'TestCode',
							headerName: '검사코드',
							width: 100,
						},
						{
							field: 'ResultData',
							headerName: '검사결과',
							width: 100,
						},
						{
							field: 'QualityAddCode',
							headerName: '보험코드',
							width: 100,
						},
						{
							field: 'Reference',
							headerName: '참고치',
							width: 100,
						},
						{
							field: 'Unit',
							headerName: '단위',
							width: 100,
						},
						{
							field: 'SCode',
							headerName: '검체코드',
							width: 100,
						},
						{
							field: 'TestName',
							headerName: '검사명',
							width: 300,
						},
						{
							field: 'Decision',
							headerName: '판정',
							width: 100,
						},
						{
							field: 'D90',
							headerName: '최종보고일',
							width: 100,
						},
					]}
					pageSize={10}
					autoHeight={true}
				/>
			</div>
		</>
	)
}

export default ResultDownload
