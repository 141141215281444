export const headerOcsEmc = [
	{
		field: 'SEQ',
		headerName: '번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'EXTRNO',
		headerName: '검체번호',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMCD',
		headerName: '검사코드1',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'EXAMNM',
		headerName: '검사명',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'SPCCD',
		headerName: '검체코드',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'SPCNM',
		headerName: '검체명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'CHARTNO',
		headerName: '차트번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'NM',
		headerName: '이름',
		type: 'string',
		width: 100,
		editable: false,
	},
	{
		field: 'RGSTNO',
		headerName: '주민번호',
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'SEX',
		headerName: '성별',
		type: 'string',
		width: 40,
		editable: false,
	},
	{
		field: 'DEPT',
		headerName: '진료과',
		type: 'string',
		width: 100,
		editable: false,
	},

	{
		field: 'EXAMCD2',
		headerName: '검사코드2',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'BARCODE',
		headerName: '바코드번호',
		type: 'string',
		width: 110,
		editable: false,
	},
	{
		field: 'cmmnNm',
		headerName: '상태',
		type: 'string',
		width: 70,
		editable: false,
	},
]
