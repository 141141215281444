import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Box,
	Breadcrumbs,
	Tabs,
	Tab,
	useMediaQuery,
	SvgIconProps,
	SvgIcon,
	Collapse,
	Grid,
	TextField,
	Fab,
	Divider,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	DialogContent,
	MenuItem,
	Button,
	LinearProgress,
	DialogActions,
	Alert,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	FormControlLabel,
	Switch,
} from '@mui/material'
import { alpha, styled, useTheme } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import {
	TreeItem,
	TreeItemProps,
	treeItemClasses,
} from '@mui/x-tree-view/TreeItem'

import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import {
	alertModal,
	autoCellPhone,
	compareDate,
	confirmAlert,
	hexToRgba,
	isValidDateType,
	validCellPhone,
} from 'util/util'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import allLocales from '@fullcalendar/core/locales-all'

import TaskAltIcon from '@mui/icons-material/TaskAlt'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import SendToMobileIcon from '@mui/icons-material/SendToMobile'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import CloseIcon from '@mui/icons-material/Close'
import SmsIcon from '@mui/icons-material/Sms'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import DeleteIcon from '@mui/icons-material/Delete'

import {
	apiTemplateGroupList,
	apiSmsTemplateMstList,
	apiSmsTemplateSlvList,
	apiSMSGroupList,
	apiClientSmsMstList,
	apiSMSSlvList,
	apiSmsSend,
	apiSmsReservationList,
	apiSmsResultList,
	apiSmsGroupTreeList,
} from 'api/api'
import moment from 'moment'
import 'moment/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { useNavigate } from 'react-router-dom'
import { getSettings } from 'helpers/storage'
import PhoneImg from 'assets/img/phone.png'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import { TransitionProps } from '@mui/material/transitions'

import { useSpring, animated } from '@react-spring/web'
import React from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { replaceBrTag } from 'util/stringUtil'
import {
	SmsInfoType,
	SmsMessageFormTypes,
	SmsSlavePersonTypes,
} from 'constants/types'
import {
	DataGridPremium,
	GridColDef,
	GridDensity,
	GridRenderEditCellParams,
	GridRowModel,
	GridSelectionModel,
	GridToolbar,
	koKR,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import TransitionEffect from 'components/TransitionEffect'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const hourList = Array.from(Array(24).keys()).map((item) => {
	return item * 1 < 10 ? `0${item * 1}` : `${item * 1}`
})

// minList 매 5분 간격으로 생성
const minList = Array.from(Array(12).keys()).map((item) => {
	return item * 5 < 10 ? `0${item * 5}` : `${item * 5}`
})

function TransitionComponent(props: TransitionProps) {
	const style = useSpring({
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	})

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	)
}

const CustomTreeItem = React.forwardRef(
	(props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
		<TreeItem
			{...props}
			TransitionComponent={TransitionComponent}
			ref={ref}
		/>
	)
)
const locales = ['ko'] as const

const ContentWrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	height: 'calc(100vh - 150px)',
}))

const PhoneWrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '0',
	left: '320px',
	bottom: '15px',
	width: '310px',
	minHeight: '600px',
	overflowX: 'hidden',
	'& > .pageBg': {
		height: '100%',
		overflowY: 'auto',
	},
}))

const DestWrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '0',
	left: '0',
	bottom: '15px',
	width: '310px',
	minHeight: '600px',
	'& > .pageBg': {
		height: '100%',
		overflowY: 'auto',
	},
}))

const InfoWrapper = styled('div')(({ theme }) => ({
	paddingLeft: '640px',
}))

const PhoneContainer = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '278px',
	height: '600px',
	background: `url(${PhoneImg}) no-repeat center center`,
	padding: '40px 10px 0 8px',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const cmpColumns: GridColDef[] = [
	{
		field: 'RowNum',
		headerName: '순번',
		width: 60,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'MsgID',
		headerName: '메시지ID',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ScheduleType',
		headerName: '구분',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { ScheduleType } = params.row
			return (
				<>
					{ScheduleType === 1 ? (
						<span
							style={{
								color: '#000',
								background: '#99d696',
								padding: '1px 3px',
								border: '1px solid #99d696',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							예약
						</span>
					) : (
						<span
							style={{
								background: '#f1c40f',
								padding: '1px 3px',
								border: '1px solid #f1c40f',
								color: '#000',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							즉시전송
						</span>
					)}
				</>
			)
		},
	},
	{
		field: 'SendDate',
		headerName: '발송일',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { SendDate } = params.row
			if (SendDate.length > 8) {
				return <>{SendDate.substr(0, 10)}</>
			}
		},
	},
	{
		field: 'resvTime',
		headerName: '발송시간',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { SendDate } = params.row
			if (SendDate.length > 8) {
				return <>{SendDate.substr(10, 12)}</>
			}
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientName',
		headerName: '고객명',
		width: 100,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'CellNumber',
		headerName: '전화번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { CellNumber } = params.row
			return <>{autoCellPhone(CellNumber)}</>
		},
	},
	{
		field: 'MmsMsg',
		headerName: '발송문자',
		width: 120,
		flex: 1,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'SuccCount',
		headerName: '발송결과',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { SuccCount } = params.row
			return (
				<>
					{SuccCount === 1 ? (
						<span
							style={{
								color: '#000',
								background: '#74acdb',
								padding: '1px 3px',
								border: '1px solid #74acdb',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							완료
						</span>
					) : (
						<span
							style={{
								background: '#c0392b',
								padding: '1px 3px',
								border: '1px solid #c0392b',
								color: '#fff',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							실패
						</span>
					)}
				</>
			)
		},
	},
	{
		field: 'RegUserID',
		headerName: '등록자',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'RegDate',
		headerName: '등록일',
		width: 130,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
]

const resvColumns: GridColDef[] = [
	{
		field: 'RowNum',
		headerName: '순번',
		width: 60,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'MsgID',
		headerName: '메시지ID',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ScheduleType',
		headerName: '구분',
		width: 80,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { ScheduleType } = params.row
			return <>{ScheduleType === 1 ? '예약' : ''}</>
		},
	},
	{
		field: 'SendDate',
		headerName: '예약일',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'SendTime',
		headerName: '예약시간',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'PatientName',
		headerName: '고객명',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'CellNumber',
		headerName: '전화번호',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: (params) => {
			const { CellNumber } = params.row
			return <>{autoCellPhone(CellNumber)}</>
		},
	},
	{
		field: 'MmsMsg',
		headerName: '발송문자',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'RegUserID',
		headerName: '등록자',
		width: 120,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'RegDate',
		headerName: '등록일',
		width: 130,
		editable: false,
		headerAlign: 'center',
		align: 'center',
	},
]

const SpecialCharacterList = [
	'☆',
	'★',
	'○',
	'●',
	'◎',
	'◇',
	'◆',
	'□',
	'■',
	'▲',
	'◁',
	'◀',
	'♤',
	'♠',
	'♡',
	'♥',
	'♧',
	'♣',
	'▣',
	'◐',
	'▩',
	'▨',
	'♨',
	'☎',
	'☜',
	'☞',
	'♪',
	'♬',
	'▽',
	'▼',
	'∞',
	'∴',
	'∽',
	'※',
	'㉿',
	'…',
	'？',
	'》',
	'♂',
	'♀',
	'∬',
	'≠',
	'￠',
	'￥',
	'∪',
	'∧',
	'⇒',
	'∀',
	'∃',
	'→',
	'←',
	'↑',
	'↓',
	'△',
	'⊙',
	'♩',
	'￣',
	'⊃',
	'↔',
]

const EmoticonCategoryList = ['웃음', '사랑', '놀람', '슬픔', '기타']
const EmoticonList = [
	{ Category: '웃음', Emoji: '^.^' },
	{ Category: '웃음', Emoji: '^_^' },
	{ Category: '웃음', Emoji: '^0^' },
	{ Category: '웃음', Emoji: '^L^' },
	{ Category: '웃음', Emoji: '⌒⌒' },
	{ Category: '웃음', Emoji: '^ε^' },
	{ Category: '웃음', Emoji: 'n.n' },
	{ Category: '웃음', Emoji: '^.~' },
	{ Category: '웃음', Emoji: '^Δ^' },
	{ Category: '웃음', Emoji: '^v^' },
	{ Category: '웃음', Emoji: '^o^~~♬' },
	{ Category: '웃음', Emoji: '(ノ^O^)ノ' },
	{ Category: '웃음', Emoji: 'S(*^___^*)S' },
	{ Category: '웃음', Emoji: '_(≥∇≤)ノミ☆' },
	{ Category: '사랑', Emoji: '♡.♡' },
	{ Category: '사랑', Emoji: '♥.♥' },
	{ Category: '사랑', Emoji: '(*_*)' },
	{ Category: '사랑', Emoji: '*♥o♥*' },
	{ Category: '사랑', Emoji: '(^*^)kiss' },
	{ Category: '사랑', Emoji: '(*^^)σ(^_^)' },
	{ Category: '사랑', Emoji: '(*^}{^*)' },
	{ Category: '사랑', Emoji: '＼(*^▽^*)ノ' },
	{ Category: '놀람', Emoji: '(@.@)' },
	{ Category: '놀람', Emoji: '★.★' },
	{ Category: '놀람', Emoji: '☆_☆' },
	{ Category: '놀람', Emoji: '⊙.⊙' },
	{ Category: '놀람', Emoji: '(=_=;)' },
	{ Category: '놀람', Emoji: '⊙⊙ㆀ' },
	{ Category: '놀람', Emoji: '-_-ㆀ' },
	{ Category: '놀람', Emoji: '-_-a' },
	{ Category: '놀람', Emoji: 'O_O' },
	{ Category: '놀람', Emoji: '+_+' },
	{ Category: '놀람', Emoji: '☞_☜' },
	{ Category: '놀람', Emoji: '(↗∇↖)' },
	{ Category: '놀람', Emoji: '!.!' },
	{ Category: '놀람', Emoji: '┏(ㆀㅡ,.ㅡ)┛' },
	{ Category: '슬픔', Emoji: '~_~' },
	{ Category: '슬픔', Emoji: 'ㅠ.ㅠ' },
	{ Category: '슬픔', Emoji: 'ㅜ.ㅜ' },
	{ Category: '슬픔', Emoji: 'T.T' },
	{ Category: '슬픔', Emoji: 'Y.Y' },
	{ Category: '슬픔', Emoji: '(>_<)' },
	{ Category: '슬픔', Emoji: '(v.v)' },
	{ Category: '슬픔', Emoji: '(X_X)' },
	{ Category: '슬픔', Emoji: 'ご..ご' },
	{ Category: '슬픔', Emoji: 'TmT' },
	{ Category: '슬픔', Emoji: '¿.¿' },
	{ Category: '슬픔', Emoji: ';_;' },
	{ Category: '슬픔', Emoji: 'l . l' },
	{ Category: '슬픔', Emoji: 'Θ_Θ' },
	{ Category: '기타', Emoji: '∋.∈' },
	{ Category: '기타', Emoji: '⊇.⊆' },
	{ Category: '기타', Emoji: '(Z_Z)' },
	{ Category: '기타', Emoji: '=.=' },
	{ Category: '기타', Emoji: 'θ.θ' },
	{ Category: '기타', Emoji: 'Φ.Φ' },
	{ Category: '기타', Emoji: '-o-' },
	{ Category: '기타', Emoji: '(u_u)' },
	{ Category: '기타', Emoji: '(g_g)' },
	{ Category: '기타', Emoji: '~O~' },
	{ Category: '기타', Emoji: '@L@' },
	{ Category: '기타', Emoji: '(-.-)Zzz..' },
	{ Category: '기타', Emoji: '●⊙' },
	{ Category: '기타', Emoji: '@_O' },
	{ Category: '기타', Emoji: '(#_-)' },
	{ Category: '기타', Emoji: '(-.￥)' },
	{ Category: '기타', Emoji: '(-_★)' },
	{ Category: '기타', Emoji: '{a_6}' },
	{ Category: '기타', Emoji: '(o_Φ)' },
	{ Category: '기타', Emoji: '((+)).(-)' },
	{ Category: '기타', Emoji: '`(-_-メ)' },
]

const Message = () => {
	// locale 설정
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const navigate = useNavigate()

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const [isSmsUser, setIsSmsUser] = useState(false)

	const [tabValue, setTabValue] = useState(0)

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue)
	}

	const [search, setSearch] = useState({
		ClientID: userInfo.clientID,
		ChartNo: '',
		PatientName: '',
	})

	const actionSearch = () => {
		apiSmsGroupTreeList({ ClientID: userInfo.clientID }).then((res) => {
			console.log(res)
			setPatientGroupList(res)
		})
	}

	// 발송완료 조회
	const actionSearch1 = () => {
		console.log(cmpSearch)
		setGridCmpLoading(true)
		const request = {
			...cmpSearch,
			Status: cmpSearch.Status === 'A' ? '' : cmpSearch.Status,
			StartDate: cmpSearch.StartDate,
			EndDate: cmpSearch.EndDate,
			ScheduleType: cmpSearch.ScheduleType === 'A' ? '' : cmpSearch.ScheduleType,
		}
		console.log(request)
		apiSmsResultList(request)
			.then((res) => {
				console.log(res)
				setCmpRows(res)
			})
			.finally(() => {
				setGridCmpLoading(false)
			})
	}

	// 발송예약 조회
	const actionSearch2 = () => {
		setGridResvLoading(true)
		apiSmsReservationList(resvSearch)
			.then((res) => {
				console.log(res)
				setResvRows(res)
			})
			.finally(() => {
				setGridResvLoading(false)
			})
	}

	const [emoticonTabValue, setEmoticonTabValue] = useState('웃음')

	const handleEmoticonTabChange = (
		event: React.SyntheticEvent,
		newValue: string
	) => {
		setEmoticonTabValue(newValue)
	}

	// 기본 메시지 그룹 및 탭 처리
	const [templateCategoryList, setTemplateCategoryList] = useState<any[]>([])
	const [templateMessageList, setTemplateMessageList] = useState<any[]>([])
	const [orgTemplateMessageList, setOrgTemplateMessageList] = useState<any[]>([])

	const [tabName, setTabName] = useState<string>('만남/연락')
	const tabChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabName(newValue)
		const arr: any[] = orgTemplateMessageList.filter(
			(item: any) => item.Category === newValue
		)
		setTemplateMessageList(arr)
	}

	// 메시지
	const [messageForm, setMessageForm] = useState<SmsMessageFormTypes>({
		Message: '',
		PatientList: [],
		SendDate: moment().format('YYYY-MM-DD'),
		SendHour: '00',
		SendMinute: '00',
		IsReserved: false,
		SMSNumber: '',
	})

	const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
		MstID: 0,
		ClientID: userInfo.clientID,
		IsActive: false,
		ClientName: '',
		IsNumberActive: false,
		SMSNumber: '',
	})

	// 문자샘플함 팝업
	const [templateSearch, setTemplateSearch] = useState({
		MstID: 0,
		SlvID: 0,
		ClientID: userInfo.clientID,
		Category: '',
	})
	const [templateMstList, setTemplateMstList] = useState([])
	const [templateSlvList, setTemplateSlvList] = useState([])
	const [templatePopupOpen, setTemplatePopupOpen] = useState(false)

	const templateRef = useGridApiRef()
	const [templateRows, setTemplateRows] = useState<GridRowModel[]>([])
	const [templateSelectedModel, setTemplateSelectedModel] =
		useState<GridSelectionModel>([])
	const [templateIsLoading, setTemplateIsLoading] = useState(false)
	const [templatePageSize, setTemplatePageSize] = useState(
		env.row ? env.row : 100
	)

	const templatePopupCose = () => {
		setTemplatePopupOpen(false)
	}
	const templatePopupOpenAction = () => {
		const request = {
			ClientID: '',
		}
		apiSmsTemplateMstList(request)
			.then((res) => {
				console.log(res)
				setTemplateMstList(res)
			})
			.finally(() => {
				setTemplatePopupOpen(true)
			})
	}

	const onTemplateMstChange = (e: any) => {
		const { value } = e.target
		setTemplateSearch({
			...templateSearch,
			MstID: parseInt(value) || 0,
			Category: '',
		})
		const request = {
			MstID: parseInt(value) || 0,
			ClientID: '',
		}
		apiTemplateGroupList(request).then((res) => {
			console.log(res)
			setTemplateSlvList(res)
		})
	}

	const onTemplateSlvChange = (e: any) => {
		const { value } = e.target
		setTemplateSearch({
			...templateSearch,
			Category: value,
		})
		const request = {
			Category: value,
			MstID: templateSearch.MstID,
		}
		apiSmsTemplateSlvList(request).then((res) => {
			console.log(res)
			setTemplateRows(res)
		})
	}

	// 문자 샘플함에서 선택
	const onTemplateSelectAction = (row: any) => {
		console.log(row)
		if (messageForm.Message.length > 1000) {
			return
		}
		setMessageForm({
			...messageForm,
			Message: row.Message,
		})
		templatePopupCose()
	}

	const [addToGroup, setAddToGroup] = useState(false)

	// 메시지 전송
	const [isSending, setIsSending] = useState(false)
	// 메시지 전송
	const actionSendMessage = () => {
		console.log(messageForm)
		if (smsInfo.IsActive && smsInfo.IsNumberActive) {
			if (messageForm.Message.length === 0) {
				alertModal('메시지를 입력해주세요.', 'error', () => {})
				return
			}
			if (messageForm.PatientList.length === 0) {
				alertModal('발송대상을 추가해주세요.', 'error', () => {})
				return
			}
			if (messageForm.IsReserved) {
				if (messageForm.SendDate === '') {
					alertModal('발송일자를 선택해주세요.', 'error', () => {})
					return
				}
				if (messageForm.SendHour === '') {
					alertModal('발송시간을 선택해주세요.', 'error', () => {})
					return
				}
				if (messageForm.SendMinute === '') {
					alertModal('발송시간을 선택해주세요.', 'error', () => {})
					return
				}
			}
			confirmAlert(
				'문자를 발송하시겠습니까?',
				() => {
					console.log(messageForm)
					setIsSending(true)
					apiSmsSend(messageForm)
						.then((res) => {
							console.log(res)
							alertModal(res.result, 'success', () => {
								setMessageForm({
									Message: '',
									PatientList: [],
									SendDate: moment().format('YYYY-MM-DD'),
									SendHour: '00',
									SendMinute: '00',
									IsReserved: false,
									SMSNumber: '',
								})
								setMessageTargetList([])
								actionSearch()
							})
						})
						.finally(() => {
							setIsSending(false)
						})
				},
				() => {}
			)
		} else {
			alertModal('설정된 SMS 서비스가 없습니다.', 'error', () => {})
		}
	}

	// 전송대상
	const [person, setPerson] = useState<SmsSlavePersonTypes>({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		GroupName: '',
		MstID: 0,
		SlvID: 0,
		RowCrud: 'C',
		RowNum: 0,
		IsNew: true,
	})
	const [messageTargetList, setMessageTargetList] = useState<
		SmsSlavePersonTypes[]
	>([])

	const removeFromMessageTargetList = (slvID: number) => {
		const newList = messageTargetList.filter((item: any) => item.SlvID !== slvID)
		setMessageTargetList(newList)
		setMessageForm({
			...messageForm,
			PatientList: newList,
		})
	}

	const personOnKeyUp = (e: any) => {
		if (e.keyCode === 13) actionAddToMessageTargetList()
	}

	// 전송 대상자 개별 추가
	const actionAddToMessageTargetList = () => {
		if (person.PatientName?.trim() === '') {
			alertModal('성명을 입력해주세요.', 'error', () => {})
			return
		}
		if (person.CellNumber?.trim() === '') {
			alertModal('핸드폰 번호를 입력해주세요.', 'error', () => {})
			return
		}
		if (validCellPhone(person.CellNumber) === false) {
			alertModal('핸드폰 번호를 정확히 입력해주세요.', 'error', () => {})
			return
		}
		if (!person.CellNumber.includes('-')) {
			alertModal('핸드폰 번호를 정확히 입력해주세요.', 'error', () => {})
		}
		const personRow = {
			...person,
			RowNum: messageTargetList.length + 1,
			MstID: 0,
			SlvID: Math.floor(Math.random() * 1000000000),
		}
		// 중복 체크
		let isDuplicate = false
		messageTargetList.forEach((item: any) => {
			if (item.CellNumber === person.CellNumber) isDuplicate = true
		})
		if (isDuplicate) {
			alertModal('이미 추가된 핸드폰 번호입니다.', 'error', () => {})
			return
		} else {
			setMessageTargetList([...messageTargetList, personRow])
			setPerson({
				ChartNo: '',
				PatientName: '',
				CellNumber: '',
				GroupName: '',
				MstID: 0,
				SlvID: Math.floor(Math.random() * 1000000000),
				RowCrud: 'C',
				RowNum: 0,
				IsNew: true,
			})
			setMessageForm({
				...messageForm,
				PatientList: [...messageTargetList, personRow],
			})
		}
	}

	const [personPopupIsOpen, setPersonPopupIsOpen] = useState(false)
	const personPopupOnClose = () => {
		setPersonPopupIsOpen(false)
	}

	const personSearchAction = () => {
		const request = {
			ClientID: userInfo.clientID,
			Search: '',
		}
		apiSMSSlvList(request).then((res) => {
			console.log(res)
			// setSlvRows(res)
			setPersonPopupIsOpen(true)
		})
	}

	// 발송대상자 조건
	const [sendCondition, setSendCondition] = useState({
		date: moment().format('YYYY-MM-DD'),
	})

	// 발송완료 조회 조건
	const [cmpSearch, setCmpSearch] = useState({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		EndDate: moment().format('YYYY-MM-DD'),
		Status: 'A',
		SMSNumber: '',
		ScheduleType: 'A',
	})

	// grid state
	const cmpRef = useGridApiRef()
	const [cmpRows, setCmpRows] = useState<GridRowModel[]>([])
	const [selectionCmpModel, setSelectionCmpModel] = useState<GridSelectionModel>(
		[]
	)
	const [gridCmpLoading, setGridCmpLoading] = useState(false)
	const [gridCmpPageSize, setGridCmpPageSize] = useState(env.row ? env.row : 100)

	const resvRef = useGridApiRef()
	const [resvRows, setResvRows] = useState<GridRowModel[]>([])
	const [selectionResvModel, setSelectionResvModel] =
		useState<GridSelectionModel>([])
	const [gridResvLoading, setGridResvLoading] = useState(false)
	const [gridResvPageSize, setGridResvPageSize] = useState(
		env.row ? env.row : 100
	)

	const [disabledSearch, setDisabledSearch] = useState(true)

	const [dateRange, setDateRange] = useState('7d')
	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setCmpSearch({
			...cmpSearch,
			StartDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setCmpSearch({
			...cmpSearch,
			StartDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setCmpSearch({
			...cmpSearch,
			StartDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setCmpSearch({
				...cmpSearch,
				StartDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setCmpSearch(() => {
					return {
						...cmpSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// 발송예약의 날짜 변경 이벤트
	const dateRanageOnChange2 = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setResvSearch({
				...resvSearch,
				StartDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, cmpSearch.EndDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setResvSearch(() => {
					return {
						...resvSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// 발송예약 조회 조건
	const [resvSearch, setResvSearch] = useState({
		ChartNo: '',
		PatientName: '',
		CellNumber: '',
		StartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		EndDate: moment().format('YYYY-MM-DD'),
		Message: '',
		Status: '1',
	})

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch1()
	}

	const onKeyUp2 = (e: any) => {
		if (e.keyCode === 13) actionSearch2()
	}

	const [patientGroupList, setPatientGroupList] = useState<any[]>([])

	useEffect(() => {
		if (!userInfo.isSmsActive) {
			alertModal('권한이 없습니다', 'error', () => navigate('/admin/test/result'))
		}
		if (userInfo?.infoType === 'C') {
			const request = {
				ClientID: userInfo.clientID,
			}
			apiClientSmsMstList(request).then((res) => {
				console.log(res)
				setSmsInfo(res[0])
				const result = res[0]
				console.log(result.IsActive)
				setIsSmsUser(result.IsActive === 1 ? true : false)
				actionSearch()
			})
			// 공통 문자 그룹 리스트
			apiSmsTemplateMstList({ ClientID: '' }).then((res) => {
				let MstID = 0
				res.forEach((element: any) => {
					if (element.DepartName.indexOf('기본') > -1) {
						MstID = element.MstID
					}
				})
				if (MstID > 0) {
					// 카테고리 리스트
					apiTemplateGroupList({ MstID: MstID }).then((res) => {
						setTemplateCategoryList(res)
						apiSmsTemplateSlvList({ MstID: MstID }).then((res) => {
							console.log(res)
							// 만남/연락 기본으로 세팅
							const arr: any[] = res.filter(
								(item: any) => item.Category === '만남/연락'
							)
							console.log(arr)
							setOrgTemplateMessageList(res)
							setTemplateMessageList(arr)
						})
					})
				}
			})
			actionSearch1()
			actionSearch2()
		}
	}, [])

	return (
		<>
			<Dialog
				open={templatePopupOpen}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							문자 샘플함
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={templatePopupCose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={6}
						>
							<TextField
								fullWidth
								select
								size="small"
								label="그룹"
								defaultValue="0"
								value={templateSearch.MstID}
								onChange={(e) => {
									onTemplateMstChange(e)
								}}
							>
								<MenuItem value="0">선택하세요.</MenuItem>
								{templateMstList.map((option: any) => (
									<MenuItem
										key={option.MstID}
										value={option.MstID}
									>
										{option.DepartName}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<TextField
								fullWidth
								select
								size="small"
								label="그룹"
								defaultValue="0"
								value={templateSearch.Category}
								onChange={(e) => {
									onTemplateSlvChange(e)
								}}
							>
								<MenuItem value="0">선택하세요.</MenuItem>
								{templateSlvList.map((option: any) => (
									<MenuItem
										key={option.RowNum}
										value={option.Category}
									>
										{option.Category}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					<Box sx={{ height: '400px', mt: 1 }}>
						<DataGridPremium
							rows={templateRows}
							columns={[
								{
									field: 'RowCrud',
									headerName: 'CRUD',
									type: 'string',
									minWidth: 50,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'SlvID',
									headerName: 'ID',
									type: 'number',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Category',
									headerName: '구분',
									type: 'string',
									width: 80,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Message',
									headerName: '메시지',
									type: 'string',
									width: 80,
									flex: 1,
									editable: false,
									headerAlign: 'center',
								},
								{
									field: 'Button',
									headerName: '선택',
									type: 'string',
									width: 80,
									editable: false,
									headerAlign: 'center',
									renderCell: (params: GridRenderEditCellParams) => {
										return (
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={(e) => {
													onTemplateSelectAction(params.row)
												}}
											>
												선택
											</Button>
										)
									},
								},
							]}
							getRowId={(row) => row.SlvID}
							apiRef={templateRef}
							pageSize={templatePageSize}
							onPageSizeChange={(newPageSize) => setTemplatePageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							experimentalFeatures={{ newEditingApi: true }}
							selectionModel={templateSelectedModel}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							loading={templateIsLoading}
							density={(env.desnse as GridDensity) || 'compact'}
							initialState={{
								columns: {
									columnVisibilityModel: {
										RowCrud: false,
										SlvID: false,
										RowNum: false,
									},
								},
							}}
							hideFooterSelectedRowCount
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="secondary"
						onClick={templatePopupCose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<PageWrap style={{ marginBottom: '0.5rem' }}>
				<PageBg2>
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
						sx={{
							'@media (max-width: 900px': {
								display: 'none',
							},
						}}
					>
						<TitleText
							key="3"
							color="text.primary"
							sx={{ fontWeight: '500', fontSize: 17 }}
						>
							SMS 메시지함
						</TitleText>
					</Breadcrumbs>
					<BtnBox>
						<Tabs
							value={tabValue}
							onChange={handleChange}
							aria-label="메뉴 버튼"
							sx={{ p: 0, m: 0, minHeight: '1px' }}
						>
							<Tab
								label="문자보내기"
								sx={{
									fontSize: '0.85rem',
									background: `${hexToRgba(theme.palette.primary.main, 0.15)}`,
									'&.Mui-selected': {
										background: `${hexToRgba(theme.palette.primary.main, 0.3)}`,
									},
									p: 1,
									'&.MuiButtonBase-root': {
										minHeight: '1px',
									},
								}}
							/>
							<Tab
								label="보낸문자함"
								sx={{
									fontSize: '0.85rem',
									background: `${hexToRgba(theme.palette.primary.main, 0.15)}`,
									'&.Mui-selected': {
										background: `${hexToRgba(theme.palette.primary.main, 0.3)}`,
									},
									p: 1,
									'&.MuiButtonBase-root': {
										minHeight: '1px',
									},
								}}
							/>
							<Tab
								label="예약문자함"
								sx={{
									fontSize: '0.85rem',
									background: `${hexToRgba(theme.palette.primary.main, 0.15)}`,
									'&.Mui-selected': {
										background: `${hexToRgba(theme.palette.primary.main, 0.3)}`,
									},
									p: 1,
									'&.MuiButtonBase-root': {
										minHeight: '1px',
									},
								}}
							/>
						</Tabs>
					</BtnBox>
				</PageBg2>
			</PageWrap>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				adapterLocale={locale}
			>
				{tabValue === 0 ? (
					<ContentWrapper>
						<DestWrapper>
							<PageBg className="pageBg">
								{patientGroupList.map((item: any, index: number) => {
									return (
										<Accordion key={item.MstID}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id={`panel${index + 1}a-header`}
												sx={{ background: 'rgb(229, 246, 253)' }}
											>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														width: '100%',
													}}
												>
													<Typography>{item.GroupName}</Typography>
													<Button
														variant="contained"
														size="small"
														color="primary"
														sx={{ mr: 1 }}
														onClick={(e) => {
															e.stopPropagation()
															const newList = messageTargetList.concat(item.SlvList)
															const uniqueList = newList.filter(
																(v, i, a) => a.findIndex((t) => t.SlvID === v.SlvID) === i
															)
															setMessageTargetList(uniqueList)
															setMessageForm({
																...messageForm,
																PatientList: [...uniqueList],
															})
														}}
													>
														그룹 추가
													</Button>
												</div>
											</AccordionSummary>
											<AccordionDetails>
												<List dense>
													<Divider />
													{item.SlvList.map((item2: any, index2: number) => {
														return (
															<React.Fragment key={item2.SlvID}>
																<ListItem
																	onClick={(e) => {
																		const newList = messageTargetList.concat(item2)
																		const uniqueList = newList.filter(
																			(v, i, a) => a.findIndex((t) => t.SlvID === v.SlvID) === i
																		)
																		setMessageTargetList(uniqueList)
																		setMessageForm({
																			...messageForm,
																			PatientList: [...uniqueList],
																		})
																	}}
																	sx={{ cursor: 'pointer' }}
																>
																	{item2.PatientName}
																</ListItem>
																<Divider />
															</React.Fragment>
														)
													})}
												</List>
											</AccordionDetails>
										</Accordion>
									)
								})}
							</PageBg>
						</DestWrapper>
						<PhoneWrapper>
							<PageBg className="pageBg">
								<PhoneContainer>
									<Typography
										variant="h6"
										component="h2"
										sx={{ px: 2, display: 'flex', alignItems: 'center', mb: 1 }}
									>
										{smsInfo?.SMSNumber && (
											<>
												<PermPhoneMsgIcon
													fontSize="small"
													sx={{ mr: 1 }}
												/>{' '}
												{smsInfo?.SMSNumber}
											</>
										)}
									</Typography>
									<TextField
										id="message"
										size="small"
										fullWidth
										multiline
										rows={22}
										label="메시지 내용"
										value={messageForm.Message}
										onChange={(e) => {
											if (e.target.value.length > 1000) {
												return
											}
											setMessageForm({
												...messageForm,
												Message: e.target.value,
											})
											e.target.focus()
										}}
										sx={{ backgroundColor: '#fff' }}
									/>
									<Divider />
									<div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
										<Button
											variant="contained"
											color="primary"
											fullWidth
											startIcon={<SmsIcon />}
											sx={{ mt: 1 }}
											onClick={actionSendMessage}
											disabled={
												messageForm.Message.length === 0 ||
												messageForm.PatientList.length === 0 ||
												smsInfo.IsNumberActive === false ||
												smsInfo.IsActive === false ||
												isSending
											}
										>
											문자보내기
										</Button>
									</div>
								</PhoneContainer>
								<Alert
									severity="info"
									sx={{ my: 1 }}
								>
									{messageForm.Message.length} byte / 1000 byte
									<br />
									(한글 1000자 / 영어 1000자 이내)
								</Alert>
								<Button
									variant="contained"
									size="small"
									color="secondary"
									startIcon={<ManageSearchIcon />}
									fullWidth
									onClick={templatePopupOpenAction}
								>
									문자샘플함
								</Button>
								<List
									dense
									sx={{
										border: '5px solid #b0cad7',
										height: '300px',
										overflowY: 'auto',
										px: '1rem',
										backgroundColor: '#fff',
										borderRadius: '5px',
										marginTop: '1rem',
									}}
								>
									{messageTargetList.map((item: any) => {
										return (
											<React.Fragment key={item.SlvID}>
												<ListItem
													disablePadding
													secondaryAction={
														<IconButton
															edge="end"
															aria-label="delete"
															color="error"
															onClick={() => {
																removeFromMessageTargetList(item.SlvID)
															}}
														>
															<DeleteIcon />
														</IconButton>
													}
												>
													<ListItemButton>
														<ListItemText
															primary={item.PatientName}
															secondary={`${item.GroupName} ${item.CellNumber}`}
														/>
													</ListItemButton>
												</ListItem>
												<Divider />
											</React.Fragment>
										)
									})}
								</List>
								<Divider />
								<Box
									component="form"
									noValidate
									autoComplete="off"
									onKeyUp={personOnKeyUp}
									sx={{ mt: 1 }}
								>
									<Grid
										container
										spacing={2}
									>
										<Grid
											item
											md={5}
										>
											<TextField
												label="성명"
												size="small"
												value={person.PatientName}
												fullWidth
												onChange={(e) =>
													setPerson({ ...person, PatientName: e.target.value })
												}
												sx={{ backgroundColor: '#fff' }}
											/>
										</Grid>
										<Grid
											item
											md={7}
										>
											<TextField
												label="핸드폰 번호"
												size="small"
												value={person.CellNumber}
												fullWidth
												onChange={(e) =>
													setPerson({ ...person, CellNumber: autoCellPhone(e.target.value) })
												}
												sx={{ backgroundColor: '#fff' }}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															{/* <Button
														variant="contained"
														color="primary"
														sx={{ transform: 'translateX(0.8rem)' }}
														onClick={actionAddToMessageTargetList}
													>
														<AddCircleTwoToneIcon sx={{ mr: 1 }} />
														추가
													</Button> */}
															<IconButton
																aria-label="add"
																color="primary"
																onClick={actionAddToMessageTargetList}
																sx={{ transform: 'translateX(1rem)' }}
															>
																<AddCircleTwoToneIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
									</Grid>
								</Box>
								<Box sx={{ mb: 1 }}>
									<FormControlLabel
										control={
											<Switch
												onChange={(e) => {
													setMessageForm({
														...messageForm,
														IsReserved: e.target.checked,
													})
												}}
											/>
										}
										label="예약발송시 체크해주세요."
									/>
								</Box>
								<Typography
									variant="h6"
									component="h3"
									sx={{ mt: 2, mb: 1 }}
								>
									발송일자
								</Typography>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale={locale}
								>
									<DatePicker
										label="발송일자"
										value={sendCondition.date}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										disabled={messageForm.IsReserved === false}
										onChange={(newValue: any) => {
											setMessageForm({
												...messageForm,
												SendDate: moment(new Date(newValue)).format('YYYY-MM-DD'),
											})
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</LocalizationProvider>
								<Typography
									variant="h6"
									component="h3"
									sx={{ mt: 2, mb: 1 }}
								>
									발송시간
								</Typography>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										md={6}
									>
										<TextField
											id="hour"
											select
											defaultValue="00"
											size="small"
											fullWidth
											disabled={messageForm.IsReserved === false}
											onChange={(e) => {
												setMessageForm({
													...messageForm,
													SendHour: e.target.value,
												})
											}}
										>
											{hourList.map((item) => {
												return (
													<MenuItem
														key={item}
														value={item}
													>
														{item}시
													</MenuItem>
												)
											})}
										</TextField>
									</Grid>
									<Grid
										item
										md={6}
									>
										<TextField
											id="min"
											select
											defaultValue="00"
											size="small"
											fullWidth
											disabled={messageForm.IsReserved === false}
											onChange={(e) => {
												setMessageForm({
													...messageForm,
													SendMinute: e.target.value,
												})
											}}
										>
											{minList.map((item) => {
												return (
													<MenuItem
														key={item}
														value={item}
													>
														{item}분
													</MenuItem>
												)
											})}
										</TextField>
									</Grid>
								</Grid>
							</PageBg>
						</PhoneWrapper>
						<InfoWrapper>
							<PageBg
								style={{ marginBottom: '0.5rem', padding: '0.3rem 1rem 0.5rem 1rem' }}
							>
								<Grid
									container
									spacing={1}
									sx={{ my: 1 }}
								>
									<Grid
										item
										md={4}
									>
										<Box sx={{ backgroundColor: '#efefef', p: 1, minHeight: '213px' }}>
											<Typography
												variant="body1"
												color="text.secondary"
												sx={{
													fontSize: '12px',
													minHeight: '20px',
													textAlign: 'center',
													backgroundColor:
														theme.palette.mode === 'dark' ? '#333' : '#EEF9FD',
												}}
											>
												특수문자
											</Typography>
											<Box sx={{ backgroundColor: '#fff' }}>
												{SpecialCharacterList.map((item: any, index: number) => {
													return (
														<Box
															key={index}
															sx={{
																width: '20px',
																height: '20px',
																display: 'inline-flex',
																justifyContent: 'center',
																alignItems: 'center',
																cursor: 'pointer',
																borderRadius: '5px',
																mb: '5px',
															}}
															onClick={() => {
																setMessageForm({
																	...messageForm,
																	Message: messageForm.Message + item,
																})
															}}
														>
															{item}
														</Box>
													)
												})}
											</Box>
										</Box>
									</Grid>
									<Grid
										item
										md={8}
									>
										<Box sx={{ backgroundColor: '#efefef', p: 1, minHeight: '213px' }}>
											<TabContext value={emoticonTabValue}>
												<Box
													sx={{
														borderBottom: 1,
														borderColor: 'divider',
														mb: 0,
														backgroundColor:
															theme.palette.mode === 'dark' ? '#333' : '#EEF9FD',
													}}
												>
													<TabList
														onChange={handleEmoticonTabChange}
														aria-label="이모티콘 카테고리"
														sx={{
															minHeight: '20px',
														}}
													>
														{EmoticonCategoryList.map((item: any, index: number) => {
															return (
																<Tab
																	label={item}
																	value={item}
																	key={index}
																	sx={{
																		p: 0,
																		m: 0,
																		minHeight: '15px',
																		minWidth: '70px',
																	}}
																/>
															)
														})}
													</TabList>
												</Box>
											</TabContext>
											<Grid
												container
												sx={{ backgroundColor: '#fff', height: '176px' }}
											>
												{EmoticonList.filter(
													(item: any) => item.Category === emoticonTabValue
												).map((item: any, index: number) => {
													return (
														<Grid
															item
															xs={6}
															md={3}
															lg={3}
															key={index}
															sx={{ p: 0.3 }}
														>
															<div
																onClick={() => {
																	setMessageForm({
																		...messageForm,
																		Message: messageForm.Message + item.Emoji,
																	})
																}}
																style={{
																	border: '1px solid #ccc',
																	textAlign: 'center',
																	cursor: 'pointer',
																}}
															>
																{item.Emoji}
															</div>
														</Grid>
													)
												})}
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</PageBg>
							<PageBg>
								<TabContext value={tabName}>
									<Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
										<TabList
											onChange={tabChange}
											aria-label="기본 메시지 그룹"
											sx={{
												minHeight: '20px',
											}}
										>
											{templateCategoryList.map((item: any, index: number) => {
												return (
													<Tab
														label={item.Category}
														value={item.Category}
														key={item.RowNum}
														sx={{
															p: 0,
															m: 0,
															minHeight: '15px',
															minWidth: '70px',
														}}
													/>
												)
											})}
										</TabList>
									</Box>
								</TabContext>
								<Grid
									container
									spacing={1}
								>
									{templateMessageList.map((item: any, index: number) => {
										return (
											<Grid
												item
												xs={2}
												md={3}
												lg={2}
												key={item.SlvID}
											>
												<Box
													sx={{
														backgroundColor:
															theme.palette.mode === 'dark' ? '#333' : '#EEF9FD',
													}}
												>
													<Typography
														variant="body2"
														color="text.secondary"
														sx={{ height: 90, overflow: 'auto', cursor: 'pointer' }}
														dangerouslySetInnerHTML={{ __html: replaceBrTag(item.Message) }}
														onClick={(e) => {
															console.log(e)
															setMessageForm({
																...messageForm,
																Message: item.Message,
															})
														}}
													/>
												</Box>
											</Grid>
										)
									})}
								</Grid>
							</PageBg>
						</InfoWrapper>
					</ContentWrapper>
				) : tabValue === 1 ? (
					<PageBg>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="차트번호"
										size="small"
										value={cmpSearch.ChartNo}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, ChartNo: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="고객명"
										size="small"
										value={cmpSearch.PatientName}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, PatientName: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="전화번호"
										size="small"
										value={cmpSearch.CellNumber}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, CellNumber: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="발송구분"
										id="ScheduleType-select"
										select
										defaultValue="A"
										size="small"
										fullWidth
										onChange={(e) => {
											setCmpSearch({ ...cmpSearch, ScheduleType: e.target.value })
										}}
									>
										<MenuItem value="A">전체</MenuItem>
										<MenuItem value="1">예약</MenuItem>
										<MenuItem value="0">즉시전송</MenuItem>
									</TextField>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="SMS문장"
										size="small"
										value={cmpSearch.ChartNo}
										fullWidth
										onChange={(e) =>
											setCmpSearch({ ...cmpSearch, ChartNo: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="발송결과"
										id="status-select"
										select
										defaultValue="A"
										size="small"
										fullWidth
										onChange={(e) => {
											setCmpSearch({ ...cmpSearch, Status: e.target.value })
										}}
									>
										<MenuItem value="A">전체</MenuItem>
										<MenuItem value="1">성공</MenuItem>
										<MenuItem value="0">실패</MenuItem>
									</TextField>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="시작일"
										value={cmpSearch.StartDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="종료일"
										value={cmpSearch.EndDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Box sx={{ height: 'calc(100vh - 375px)', mt: 1 }}>
								<DataGridPremium
									apiRef={cmpRef}
									rows={cmpRows}
									getRowId={(row) => row.MsgID}
									columns={cmpColumns}
									pageSize={gridCmpPageSize}
									onPageSizeChange={(newPageSize) => setGridCmpPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									loading={gridCmpLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												MsgID: false,
												RowNum: false,
											},
										},
									}}
									hideFooterSelectedRowCount
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</Box>
					</PageBg>
				) : (
					<PageBg>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							onKeyUp={onKeyUp2}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="차트번호"
										size="small"
										value={resvSearch.ChartNo}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, ChartNo: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="고객명"
										size="small"
										value={resvSearch.PatientName}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, PatientName: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="전화번호"
										size="small"
										value={resvSearch.CellNumber}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, CellNumber: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<TextField
										label="SMS문장"
										size="small"
										value={resvSearch.Message}
										fullWidth
										onChange={(e) =>
											setResvSearch({ ...resvSearch, Message: e.target.value })
										}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="시작일"
										value={resvSearch.StartDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange2(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									sm={6}
									md={3}
								>
									<DatePicker
										label="종료일"
										value={resvSearch.EndDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange2(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Box sx={{ height: 'calc(100vh - 350px)', mt: 1 }}>
								<DataGridPremium
									apiRef={resvRef}
									rows={resvRows}
									getRowId={(row) => row.MsgID}
									columns={resvColumns}
									pageSize={gridResvPageSize}
									onPageSizeChange={(newPageSize) => setGridResvPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									experimentalFeatures={{ newEditingApi: true }}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									selectionModel={selectionResvModel}
									onSelectionModelChange={(newSelection) => {
										setSelectionResvModel(newSelection)
									}}
									loading={gridResvLoading}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												MsgID: false,
											},
										},
									}}
									hideFooterSelectedRowCount
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</Box>
					</PageBg>
				)}
			</LocalizationProvider>
		</>
	)
}

export default Message
