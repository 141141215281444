import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	Alert,
	Grid,
	Box,
	Button,
	TextField,
	Typography,
	Dialog,
	DialogContent,
	DialogActions,
	LinearProgress,
	AlertTitle,
	AppBar,
	Divider,
	IconButton,
	Toolbar,
	Stack,
	List,
	ListItem,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import FlagCircleIcon from '@mui/icons-material/FlagCircle'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'
import TransitionEffect from 'components/TransitionEffect'
import CloseIcon from '@mui/icons-material/Close'
import { alertModal, validPhoneNumber } from 'util/util'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Check from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'

import FileUploaderComponent from 'components/FileUploaderComponent'
import {
	apiClientSMSSlvSave,
	apiClientSmsSlvList,
	apiClientSmsMstList,
} from 'api/api'
import Img1 from '../../assets/img/sms_info.png'
import Img2 from '../../assets/img/sms_dooli.jpeg'
import DownloadingIcon from '@mui/icons-material/Downloading'

const MessageNumber = () => {
	// grid columns
	/**
	 * 미결과 항목 결과 컬럼
	 */
	const columns: GridColDef[] = [
		{
			field: 'RowNum',
			headerName: '순번',
			type: 'string',
			width: 80,
			editable: false,
		},
		{
			field: 'SmsName',
			headerName: '발신번호 별칭',
			type: 'string',
			width: 150,
			editable: false,
		},
		{
			field: 'SmsNumber',
			headerName: '발신번호',
			type: 'string',
			width: 130,
			editable: false,
		},
		{
			field: 'RegDTTM',
			headerName: '등록시간',
			type: 'string',
			width: 130,
			editable: false,
		},
		{
			field: 'RegID',
			headerName: '등록ID',
			type: 'string',
			width: 100,
			editable: false,
		},
		{
			field: 'IsNumberActive',
			headerName: '인증상태',
			type: 'string',
			width: 80,
			editable: false,
			renderCell: (params) => {
				const { IsNumberActive } = params.row
				return IsNumberActive ? '인증완료' : '인증대기'
			},
		},
		{
			field: 'AuthDTTM',
			headerName: '인증시간',
			type: 'string',
			width: 130,
			editable: false,
			renderCell: (params) => {
				const { AuthDTTM } = params.row
				return AuthDTTM ? AuthDTTM : '인증대기'
			},
		},
		// {
		// 	field: 'AttachFileID',
		// 	headerName: '통신이용증명원',
		// 	type: 'string',
		// 	width: 130,
		// 	editable: false,
		// },
		// {
		// 	field: 'OrgFileName',
		// 	headerName: '원본파일명',
		// 	type: 'string',
		// 	width: 130,
		// 	editable: false,
		// },
		// {
		// 	field: 'FileID',
		// 	headerName: '통신이용증명원',
		// 	type: 'string',
		// 	width: 110,
		// 	editable: false,
		// 	renderCell: (params) => {
		// 		const { FileID, OrgFileName, AttachFileID } = params.row
		// 		return FileID ? (
		// 			<Button
		// 				variant="contained"
		// 				color="primary"
		// 				size="small"
		// 				fullWidth
		// 				onClick={() => {
		// 					fileDownloadByFileId(FileID, OrgFileName)
		// 				}}
		// 			>
		// 				파일 다운로드
		// 			</Button>
		// 		) : (
		// 			<>
		// 				<Button
		// 					variant="contained"
		// 					color="secondary"
		// 					size="small"
		// 					fullWidth
		// 					onClick={() => {
		// 						setAttachFileId(AttachFileID)
		// 						setFileDialogOpen(true)
		// 					}}
		// 				>
		// 					파일 업로드
		// 				</Button>
		// 			</>
		// 		)
		// 	},
		// },
	]

	const theme = useTheme()
	// redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	// ui state
	const [disabledAdd, setDisabledAdd] = useState(false)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	// file upload state
	const [fileDest] = useState('smsClient')
	const [attachFileId, setAttachFileId] = useState('')
	const [fileType] = useState({
		'application/pdf': ['.pdf'],
		'application/image': ['.jpg', '.jpeg', '.png', '.gif'],
	})

	const actionAdd = () => {
		if (gridRows.length >= 1) {
			alertModal('발신번호는 1개만 등록 가능합니다.', 'error', () => {})
			return
		} else {
			// fileMasterId().then((res) => {
			// 	setAttachFileId(res.attachFileId)
			// 	setAddForm({
			// 		...addForm,
			// 		AttachFileID: res.attachFileId,
			// 	})
			// 	setDialogOpen(true)
			// })
			setDialogOpen(true)
		}
	}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		const request = {
			ClientID: userInfo.clientID,
			InfoType: userInfo.infoType,
			SmsName: search.SmsName,
			SmsNumber: search.SmsNumber,
		}
		apiClientSmsSlvList(request).then((res) => {
			console.log(res)
			setGridRows(res)
		})
	}
	const actionReset = () => {}

	// grid state
	const gridRef = useGridApiRef()
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)

	// grid row click event
	const onRowClick = (e: GridRowModel) => {}

	// 조회조건
	const [search, setSearch] = useState({
		ClientID: userInfo.clientID,
		SmsNumber: '',
		SmsName: '',
	})

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const [dialogOpen, setDialogOpen] = useState(false)

	const onDialogClose = () => {
		setAttachFileId('')
		actionSearch()
		setDialogOpen(false)
	}

	const [addForm, setAddForm] = useState({
		ClientID: userInfo.clientID,
		MstID: 0,
		SmsNumber: '',
		SmsName: '',
		AttachFileID: '',
	})

	const onAddNumber = () => {
		if (addForm.SmsNumber.trim() === '') {
			alertModal('발신번호를 입력하세요.', 'error', () => {})
			return
		}
		if (addForm.SmsName.trim() === '') {
			alertModal('발신번호명을 입력하세요.', 'error', () => {})
			return
		}
		if (addForm.MstID === 0) {
			alertModal('계정으로 조회되는 SMS 승인 여부가 없습니다.', 'error', () => {})
			return
		}

		// addForm.SmsNumber 가 010으로 시작할 경우 - 기호는 2개가 포함되는지 체크
		const telNumPattern =
			/^(0[2-8][0-5]?|01[01346-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})$/
		if (
			telNumPattern.test(addForm.SmsNumber) &&
			addForm.SmsNumber.split('-').length > 3
		) {
			alertModal(
				'전화번호에 - 기호는 2개까지 포함할 수 있습니다.',
				'error',
				() => {}
			)
			return
		}

		if (!addForm.SmsNumber.includes('-')) {
			alertModal('발신번호는 - 을(를) 포함하여 입력하세요.', 'error', () => {})
			return
		}

		if (!validPhoneNumber(addForm.SmsNumber)) {
			alertModal('발신번호 형식이 올바르지 않습니다.', 'error', () => {})
			return
		}
		const request = {
			json: [
				{
					ClientID: userInfo.clientID,
					MstID: addForm.MstID,
					SmsNumber: addForm.SmsNumber,
					SmsName: addForm.SmsName,
					AttachFileID: addForm.AttachFileID,
					RowCrud: 'C',
				},
			],
		}
		apiClientSMSSlvSave(request).then((res) => {
			alertModal('발신번호가 등록되었습니다.', 'success', () => {
				setDialogOpen(false)
				actionSearch()
				setAttachFileId('')
				setAddForm({
					ClientID: userInfo.clientID,
					MstID: 0,
					SmsNumber: '',
					SmsName: '',
					AttachFileID: '',
				})
			})
		})
	}

	// 첨부파일을 나중에 등록할 경우 코드들
	const [fileDialogOpen, setFileDialogOpen] = useState(false)
	const onFileDialogClose = () => {
		setFileDialogOpen(false)
		actionSearch()
	}

	const defaultSearchAction = () => {
		const request = {
			ClientID: userInfo.clientID,
		}
		apiClientSmsMstList(request).then((res) => {
			const data = res[0]
			if (data.IsActive) {
				setAddForm({
					...addForm,
					MstID: data.MstID,
				})
				actionSearch()
			} else {
				alertModal('계정으로 조회되는 SMS 승인 여부가 없습니다.', 'error', () => {})
				setDisabledAdd(true)
				setDisabledSearch(true)
				setDisabledReset(true)
			}
		})
	}

	const [noticeIsOpen, setNoticeIsOpen] = useState(false)
	const noticeOnClose = () => {
		setNoticeIsOpen(false)
	}

	const [Img2Show, setImg2Show] = useState(false)

	useEffect(() => {
		defaultSearchAction()
	}, [])

	return (
		<>
			<Dialog
				open={noticeIsOpen}
				onClose={noticeOnClose}
				maxWidth="md"
				fullWidth
				TransitionComponent={TransitionEffect}
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							발신번호 사전등록 신청 안내
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={noticeOnClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent sx={{ position: 'relative' }}>
					<Box sx={{ width: '100%', position: 'relative' }}>
						<img
							src={Img1}
							alt=""
							style={{
								maxWidth: '100%',
								display: 'block',
								margin: '0 auto',
							}}
						/>
						{/* <div
							style={{
								position: 'relative',
								display: 'inline-block',
								marginLeft: '10px',
							}}
						>
							<Button
								variant="contained"
								color="secondary"
								size="small"
								onClick={() => {
									// show Img2
									setImg2Show(!Img2Show)
								}}
							>
								신분증 사본
							</Button>
							{Img2Show && (
								<div
									style={{
										position: 'absolute',
										bottom: '20px',
										left: '100%',
										width: '400px',
										background: '#fff',
										padding: '10px',
										borderRadius: '10px',
										boxShadow: '0 0 10px rgba(0,0,0,0.5)',
									}}
								>
									<img
										src={Img2}
										alt=""
										style={{ maxWidth: '100%', display: 'block', margin: '0 auto' }}
									/>
								</div>
							)}
						</div> */}
						{/* <Stepper
							activeStep={4}
							alternativeLabel
						>
							<Step>
								<StepLabel>
									<Typography variant="h6">고객사</Typography>
									<List
										dense
										sx={{ background: 'rgb(249,208,190)', minHeight: '140px', mt: 1 }}
									>
										<ListItem>
											<Typography variant="body1">
												<a
													href="/위임장(발신번호사전등록).xlsx"
													style={{ color: 'blue' }}
												>
													1. 발신번호 사전등록 위임장
												</a>
											</Typography>
										</ListItem>
										<ListItem>
											<Typography variant="body1">2. 대리인 신분증 사본</Typography>
										</ListItem>
										<ListItem>
											<Typography variant="body1">3. 사업자 등록증</Typography>
										</ListItem>
										<ListItem>
											<Typography variant="body1">4. 업무협약서</Typography>
										</ListItem>
									</List>
								</StepLabel>
							</Step>
							<Step>
								<StepLabel>
									<Typography variant="h6">담당자</Typography>
									<List
										dense
										sx={{ background: 'rgb(253,234,175)', minHeight: '140px', mt: 1 }}
									>
										<ListItem>
											<Typography variant="body1">
												필요 서류 수거 후 서업본부로 접수
											</Typography>
										</ListItem>
									</List>
								</StepLabel>
							</Step>
							<Step>
								<StepLabel>
									<Typography variant="h6">접수진행</Typography>
									<List
										dense
										sx={{ background: 'rgb(220,240,204)', minHeight: '140px', mt: 1 }}
									>
										<ListItem>
											<Typography variant="body1">
												필요 서류 검토 및 통신사에 발신번호 사전등록 신청
											</Typography>
										</ListItem>
									</List>
								</StepLabel>
							</Step>
							<Step>
								<StepLabel>
									<Typography variant="h6">승인</Typography>
									<List
										dense
										sx={{ background: 'rgb(208,236,254)', minHeight: '140px', mt: 1 }}
									>
										<ListItem>
											<Typography variant="body1">SMS 서비스 제공 시작</Typography>
										</ListItem>
									</List>
								</StepLabel>
							</Step>
						</Stepper> */}
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="info"
						onClick={() => {
							window.open('/sms_files.zip', '_blank')
						}}
						startIcon={<DownloadingIcon />}
					>
						위임장 다운로드
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={dialogOpen}
				onClose={onDialogClose}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							발신번호 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={onDialogClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid
						container
						spacing={2}
						sx={{ mb: 1 }}
					>
						<Grid
							item
							xs={6}
						>
							<TextField
								label="발신번호"
								variant="outlined"
								size="small"
								type="text"
								fullWidth
								value={addForm.SmsNumber}
								onChange={(e) => {
									setAddForm({
										...addForm,
										SmsNumber: e.target.value,
									})
								}}
							/>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<TextField
								label="발신번호명"
								variant="outlined"
								size="small"
								type="text"
								fullWidth
								value={addForm.SmsName}
								onChange={(e) => {
									setAddForm({
										...addForm,
										SmsName: e.target.value,
									})
								}}
							/>
						</Grid>
					</Grid>
					{/* <FileUploaderComponent
						fileDest={fileDest}
						attachFileId={attachFileId}
						fileType={fileType}
						multiple={false}
					/> */}
					<Alert severity="info">
						<AlertTitle sx={{ mt: 0 }}>
							신규 발신번호 등록을 위해 통신서비스 이용증명원이 필요합니다.
							<br />
							통신서비스 이용증명원은 유선번호가 등록된 통신사로 요청하여 받으시면
							됩니다.
						</AlertTitle>
					</Alert>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="success"
						onClick={onAddNumber}
					>
						확인
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={onDialogClose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={fileDialogOpen}
				onClose={onFileDialogClose}
				TransitionComponent={TransitionEffect}
				fullWidth
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							통신이용증명원 등록
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={onFileDialogClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<FileUploaderComponent
						fileDest={fileDest}
						attachFileId={attachFileId}
						fileType={fileType}
						multiple={false}
					/>
					<Alert severity="info">
						<AlertTitle sx={{ mt: 0 }}>
							신규 발신번호 등록을 위해 통신서비스 이용증명원이 필요합니다.
							<br />
							통신서비스 이용증명원은 유선번호가 등록된 통신사로 요청하여 받으시면
							됩니다.
						</AlertTitle>
					</Alert>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						variant="contained"
						color="success"
						onClick={onFileDialogClose}
					>
						확인
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={onFileDialogClose}
					>
						닫기
					</Button>
				</DialogActions>
			</Dialog>
			<PagePositon
				actionAdd={actionAdd}
				actionSave={actionSave}
				actionDel={actionDel}
				actionSearch={actionSearch}
				actionReset={actionReset}
				disabledAdd={disabledAdd}
				disabledSave={disabledSave}
				disabledDel={disabledDel}
				disabledSearch={disabledSearch}
				disabledReset={disabledReset}
				hideAdd={false}
				hideSave={false}
				hideDel={false}
				hideReset={false}
				hideSearch={true}
			/>
			<PageBg>
				<Button
					variant="contained"
					color="info"
					startIcon={<FlagCircleIcon />}
					onClick={() => {
						setNoticeIsOpen(true)
					}}
				>
					발신번호 사전등록 신청 안내
				</Button>
				<Alert
					severity="info"
					sx={{ my: 2 }}
				>
					<AlertTitle sx={{ m: 0 }}>
						전기통신사업법 제 84조의 2(전화번호의 거짓표시 금지 및 이용자 보호)에 의거
						발신번호 사전등록제가 2015년 10월 16일부터 시행됩니다.
						<br />
						이에 따라 아래와 같이 발신번호에 대한 사전등록을 꼭 이용해주시기 바랍니다.
						<br />
						거짓으로 표시된 전화번호로 인한 이용자의 피해 예방 등에 관한 고시(2022년
						03월 02일)에 의거하여{' '}
						<span style={{ color: 'red' }}>
							개인 핸드폰번호는 발신번호로 신규 등록이 제한됩니다.
						</span>
					</AlertTitle>
				</Alert>
				<Box sx={{ height: '300px' }}>
					<DataGridPremium
						apiRef={gridRef}
						rows={gridRows}
						getRowId={(row) => row.RowNum}
						columns={columns}
						pageSize={gridPageSize}
						onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
						rowsPerPageOptions={[5, 10, 20, 50, 100]}
						pagination
						autoHeight={false}
						localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
						experimentalFeatures={{ newEditingApi: true }}
						components={{
							Toolbar: GridToolbar,
							LoadingOverlay: LinearProgress,
						}}
						loading={gridLoading}
						density={(env.desnse as GridDensity) || 'compact'}
						initialState={{
							columns: {
								columnVisibilityModel: {
									RowCrud: false,
									MstID: false,
									AttachFileID: false,
									OrgFileName: false,
								},
							},
						}}
						sx={{
							'& .MuiDataGrid-columnHeaderTitleContainer': {
								fontSize: '13px',
							},
							'& .MuiDataGrid-cell': {
								fontSize: '13px !important',
								borderRight:
									theme.palette.mode === 'dark'
										? '1px solid rgba(81, 81, 81, 1)'
										: '1px solid rgba(224, 224, 224, 1)',
							},
						}}
					/>
				</Box>
				<Alert
					severity="warning"
					sx={{ mt: 1 }}
				>
					<AlertTitle sx={{ m: 0 }}>
						<span style={{ color: 'red' }}>인증불가 :</span> 아래의 규칙을 지키지 못한
						발신번호.
						<br />
						1) 발신번호는 수신자가 실제 발신(통화)이 가능한 번호만 허용
						<br />
						예시 : 1544-0000 (O) / 0000 (X)
						<br />
						<br />
						2) 일반번호의 경우 지역번호(02, 031등)를 앞자리에 포함한 번호만 허용
						<br />
						예시 : 02-111-1111 (O) / 031-111-1111 (O) / 111-1111 (X)
						<br />
						<br />
						3) 대표번호는 8자리만 입력 허용되며, 내선번호 포함불가
						<br />
						예시 : 1544-0001 (O) / 1544-0001-001 (X)
						<br />
						<br />
						4) 030 번호의 경우 12자리까지 허용
						<br />
						예시 : 030-0000-00001 (O) / 030-0000-000001 (X)
					</AlertTitle>
				</Alert>
			</PageBg>
		</>
	)
}

export default MessageNumber
