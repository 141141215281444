/**
 *
 *
 * 공통코드 로직
 *
 *
 *
 */

/**
 * cmmnCode 에서 cmmnCd값만 리턴
 * column 파라미터로 cmmnCode 목록에서 일치하는 키를 찾는다.
 * @param {*} params
 * @param {*} column
 * @param {*} cmmnCodeList
 * @param {*} returnType
 */
export const extractCmmnValue = (
	row: any,
	cmmnCodeList: any,
	column: any,
	returnType: any
) => {
	let searchValue: any = ''
	Object.keys(row).forEach((key) => {
		if (key === column) {
			searchValue = row[key]
		}
	})
	searchValue = parseInt(searchValue)
	searchValue = cmmnCodeList.find((item: any) => {
		return parseInt(item.cmmnCd) === searchValue ? item : null
	})
	if (returnType === 'cmmnCd') {
		return `${parseInt(searchValue?.cmmnCd) || ''}`
	} else if (returnType === 'cmmnNm') {
		return `${searchValue?.cmmnNm || ''}`
	} else {
		// alert('공통코드의 반환 타입이 올바르지 않습니다.')
	}
}

/**
 * cmmnCd의 키값과 params.value 값이 일치하는 행을 찾아서 반환
 * @param {*} params
 * @param {*} cmmnCodeList
 * @param {*} column
 * @returns
 */
export const setCmmnName = (params: any, cmmnCodeList: any, column: any) => {
	const { value, row } = params
	const columnType = typeof column
	let searchValue: any = ''
	Object.keys(row).forEach((key) => {
		if (key === column) {
			searchValue = row[key]
		}
	})
	searchValue = cmmnCodeList.find((item: any) => {
		return item.cmmnNm === value ? item : null
	})
	if (searchValue?.cmmnCd) {
		params.row[column] =
			columnType === 'string'
				? searchValue.cmmnCd.toString()
				: parseInt(searchValue.cmmnCd)
	} else {
		params.row[column] = 0
	}

	return { ...params.row }
}

/**
 * 공통코드가 select 형태일 경우 value, label 형태로 넘어오는데
 * 선택한 값을 row에 넣어서 리턴한다.
 * 추가로 xxxxNm 과 같은 형태의 컬럼을 생성해서 label 값을 넣어준다.
 * @param params
 * @param optionList
 * @param column
 * @returns
 */
export const singleSelectSetter = (
	params: any,
	optionList: any,
	column: any
) => {
	const { value, row } = params
	const columnType = typeof column
	let searchValue: any = ''
	Object.keys(row).forEach((key) => {
		if (key === column) {
			searchValue = row[key]
		}
	})
	searchValue = optionList.find((item: any) => {
		return item.value === value ? item : ''
	})
	if (searchValue?.value) {
		params.row[column] =
			columnType === 'string'
				? searchValue.value.toString()
				: parseInt(searchValue.value)
		params.row[column + 'Nm'] =
			columnType === 'string'
				? searchValue.label.toString()
				: parseInt(searchValue.label)
	} else {
		params.row[column] = ''
		params.row[column + 'Nm'] = ''
	}

	return { ...params.row }
}

/**
 * singleSelectSetter 메서드와 쌍을 이루어 사용해야 한다.
 * @param params
 * @param optionList
 * @param column
 * @returns
 */
export const singleSelectGetter = (
	params: any,
	optionList: any,
	column: any
) => {
	const { value, row } = params
	const columnType = typeof column
	let searchValue: any = ''
	Object.keys(row).forEach((key) => {
		if (key === column) {
			searchValue = row[key]
		}
	})
	searchValue = optionList.find((item: any) => {
		return item.value === value ? item : ''
	})
	if (searchValue?.value) {
		params.row[column + 'Nm'] =
			columnType === 'string'
				? searchValue.value.toString()
				: parseInt(searchValue.label)
	}

	return params.row[column + 'Nm'] || ''
}

/**
 * cmmnCd가 boolean 타입일 경우
 * @param row
 * @param cmmnCodeList
 * @param column
 * @param returnType
 * @returns
 */
export const extractCmmnBoolean = (
	row: any,
	cmmnCodeList: any,
	column: any,
	returnType: any
) => {
	let searchValue: any = ''
	Object.keys(row).forEach((key) => {
		if (key === column) {
			searchValue = row[key]
		}
	})
	searchValue = Boolean(searchValue)
	searchValue = cmmnCodeList.find((item: any) => {
		return Boolean(Number(item.cmmnCd)) === searchValue ? item : ''
	})
	if (returnType === 'cmmnCd') {
		return `${Boolean(Number(searchValue?.cmmnCd)) || ''}`
	} else if (returnType === 'cmmnNm') {
		return `${searchValue?.cmmnNm || ''}`
	} else {
		// alert('공통코드의 반환 타입이 올바르지 않습니다.')
	}
}

/**
 * cmmnCd가 boolean 타입일 경우
 * @param params
 * @param cmmnCodeList
 * @param column
 * @returns
 */
export const setCmmnNameBoolean = (
	params: any,
	cmmnCodeList: any,
	column: any
) => {
	console.log(params)
	const { value, row } = params
	let searchValue: any = ''
	Object.keys(row).forEach((key) => {
		if (key === column) {
			searchValue = row[key]
		}
	})
	searchValue = cmmnCodeList.find((item: any) => {
		return item.cmmnNm === value ? item : null
	})
	if (searchValue?.cmmnCd) {
		params.row[column] = Boolean(Number(searchValue?.cmmnCd))
	} else {
		params.row[column] = false
	}
	console.log(params.row)

	return { ...params.row }
}

/**
 * cmmnCode로 target row값 변경 후 리턴
 * @param {*} row
 * @param {*} cmmnCodeList
 * @param {*} target
 */
export const setValueAndReturnRow = (
	row: any,
	cmmnCodeList: any,
	target: any
) => {}

/**
 * 공통코드 축출
 */
type Nullable<T> = T | null
export const extractCmmnCode = (
	cmmnCodeList: any,
	cmmnCd: Nullable<string> = null,
	level: Nullable<number> = 1
) => {
	return cmmnCodeList.find((item: any) => {
		return item.cmmnCd === cmmnCd && item.level === level
	})
}
