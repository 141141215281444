import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
	AppBar,
	Box,
	Chip,
	IconButton,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import TransitionComponent from 'components/TransitionComponent'
import React, { useEffect } from 'react'
import { replaceBrTag } from 'util/stringUtil'
import Slick from 'components/Slick'
import ImageDownloader from 'components/ImageDownloader'

const ReportSlide = styled('div')(({ theme }) => ({
	width: '1152px',
	table: {
		width: '100%',
		borderCollapse: 'collapse',
		marginBottom: '1rem',
		'th, td': {
			padding: '0.2rem 0.3rem',
			fontSize: '12px',
			border:
				theme.palette.mode === 'dark'
					? '1px solid rgba(224, 224, 224, 0.5)'
					: '1px solid rgba(224, 224, 224, 0.5)',
		},
		th: {
			backgroundColor: theme.palette.mode === 'dark' ? '#29b6f6' : '#835FED',
			color: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
			textAlign: 'center',
		},
		td: {
			backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
		},
	},
}))

const SliderImage = styled('div')({
	img: {
		maxWidth: '100%',
		height: 'auto',
		display: 'block',
		margin: '0 auto',
	},
})

const ReportSlider = ({
	imageList,
	reportDialog,
	reportClose,
}: {
	imageList: any
	reportDialog: boolean
	reportClose: () => void
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
	const [htmlData, setHtmlData] = React.useState('')
	const [title, setTitle] = React.useState('')

	return (
		<Dialog
			open={reportDialog}
			TransitionComponent={TransitionComponent}
			keepMounted
			// fullScreen={fullScreen}
			onClose={reportClose}
			maxWidth="sm"
			fullWidth
			aria-describedby="검사결과 팝업"
		>
			<AppBar
				sx={{ position: 'sticky', backgroundColor: '#323036' }}
				className="noPrint"
			>
				<Toolbar>검사 이미지</Toolbar>
			</AppBar>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent sx={{ p: 5 }}>
				{/* <ReportSlide dangerouslySetInnerHTML={{ __html: htmlData }}></ReportSlide> */}
				<ImageDownloader base64DataArray={imageList} />
				<Slick>
					{imageList.map((item: any, index: number) => {
						return (
							<SliderImage key={index}>
								<img
									src={`data:image/${item.AttachFileExtension};base64,${item.AttachFile}`}
									alt={item.AttachFileName}
								/>
								<Typography
									variant="body2"
									component="p"
									sx={{ textAlign: 'center', mt: 2, display: 'block' }}
								>
									{item.TestName}
								</Typography>
							</SliderImage>
						)
					})}
				</Slick>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					onClick={reportClose}
				>
					확인
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ReportSlider
